import React from 'react';

import PropTypes from 'prop-types';
import {useEffect} from 'react';
import ActionLink from '../ActionLink';
import InputTextComponent from './InputTextComponent';
import {LinkComponent} from './LinkComponent';

export const LinksComponent = ({links, viewMode, validator, onLinksChange}) => {
    useEffect(() => {}, [links, viewMode]);

    const renderLink = (link, index) => {
        return (
            <div className='row '>
                <LinkComponent
                    id={index}
                    viewMode={viewMode}
                    link={link}
                    validator={validator}
                    onLinkChange={(type, arr, event) => {
                        const updatedArray = links.map((item, i) => (i === index ? {...item, value: event.currentTarget.value} : item));
                        onLinksChange(updatedArray);
                    }}
                />
                <InputTextComponent
                    id={`description-${index}`}
                    name='description'
                    label={'Opis'}
                    colClass={'col-xl-5 col-lg-5 col-md-5 col-sm-5'}
                    value={link?.description}
                    stateField={''}
                    validators='not_required'
                    validator={validator}
                    onChange={(type, arr, event) => {
                        const updatedArray = links.map((item, i) =>
                            i === index ? {...item, description: event.currentTarget.value} : item
                        );
                        onLinksChange(updatedArray);
                    }}
                    rendered={true}
                    viewMode={viewMode}
                />
                <ActionLink
                    className={'mt-5 col-xl-1 col-lg-1 col-md-1 col-sm-2'}
                    label='Usuń'
                    rendered={viewMode !== 'VIEW'}
                    handleClick={() => {
                        const updatedArray = links.filter((item, i) => i !== index);
                        onLinksChange(updatedArray);
                    }}
                />
            </div>
        );
    };

    const addLink = () => {
        const LinkModel = {
            description: '',
            value: '',
        };
        const newLinks = [...links, {...LinkModel}];
        onLinksChange(newLinks);
    };

    const renderLinks = () => {
        return links.map((link, index) => {
            return <div key={`links-${index}`}>{renderLink(link, index)}</div>;
        });
    };

    const renderView = () => {
        return (
            <React.Fragment>
                <div className='col-12'>
                    {viewMode !== 'VIEW' ? (
                        <ActionLink
                            rendered={viewMode !== 'VIEW'}
                            label={'Dodaj link'}
                            handleClick={addLink}
                            className='p-link float-right'
                            iconSide='left'
                            iconName='mdi mdi-plus'
                        />
                    ) : (
                        <label className='mb-4'>Linki</label>
                    )}
                </div>
                <div className='row'>{renderLinks()}</div>
            </React.Fragment>
        );
    };

    return renderView();
};
LinksComponent.defaultProps = {
    links: [],
    viewMode: 'VIEW',
};

LinksComponent.propTypes = {
    links: PropTypes.array,
    viewMode: PropTypes.string,
    validator: PropTypes.object,
    onLinksChange: PropTypes.func.isRequired,
};
