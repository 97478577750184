import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import EmployeeService from '../../services/EmployeeService';
import ActionButton from '../../components/ActionButton';
import DivContainer from '../../components/DivContainer';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Card } from 'primereact/card';
import { t } from 'i18next';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import { classNames } from 'primereact/utils';
import LogEntryService from '../../services/LogEntryService';
import moment from 'moment';
import Constants from '../../utils/constants';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';


class DocumentExpirationStatusDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new EmployeeService());
        this.state = {
            elementId: props.elementId,       
            employeeId: props.employeeId,
            document: [props.element],
            logEntry: [],
            showWarning: false,
            handled: props.handled,
            employee:{

            }
        };
        this.handleExpirationStatus=this.handleExpirationStatus.bind(this);
        this.renderWarning=this.renderWarning.bind(this);
        this.hideWarning=this.hideWarning.bind(this);
        this.logEntryService=new LogEntryService();
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    initBeforeSetElement() {
        this.service
            .get(this.state.employeeId)
            .then((data) => {
                this.setState({
                    employee: data
                })
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000);
                this.unblockUi();
            });

            if(this.state.handled){
                this.blockUi();
                this.logEntryService
                .getLogEntryByAffectedObjectId(this.state.employeeId)
                .then((data)=>{
                        this.setState({
                            logEntry: [data]
                        })
                })
                .catch((err)=>{
                    this.showErrorMessage(err.message, 10000);
                    this.unblockUi();
                });
           }
    }

    handleExpirationStatus() {
        const { elementId } = this.state;
        this.service
            .handleExpiration(elementId)
            .then((response) => {
                this.persistMessage('success', '', this.getAddSucces(response));
                if (this.props.afterConfirmation) {
                    this.props.afterConfirmation();
                }
            })
            .catch((err) => {
                this.showErrorMessage(err.message, 10000);
                this.unblockUi();
            });

    }

    renderWarning(){
        this.setState({
            showWarning: true
        })
    }
    hideWarning(){
        this.setState({
            showWarning: false
        });
    }

    prepareFooter() {
        const { t } = this.props;
        const { handled } = this.props;
        const { showWarning } = this.state;
        return (
            showWarning ?
                (
                    <div className='footer-dialog-button-container'>
                        <div className='left-footer-dialog-button-container'></div>
                        <ActionButton
                            label={'Nie'}
                            variant='button save-button blue'
                            handleClick={this.hideWarning}
                            rendered={true}
                        />
                        <ActionButton
                            label={'Tak'}
                            variant='button save-button blue'
                            handleClick={this.handleExpirationStatus}
                            rendered={true}
                        />
                    </div>
                ) :
                handled ? null : (
                    <div className='footer-dialog-button-container'>
                        <div className='left-footer-dialog-button-container'></div>
                        <ActionButton
                            label={'Anuluj'}
                            variant='button save-button blue'
                            handleClick={this.props.onHide}
                            rendered={true}
                        />
                        <ActionButton
                            label={'Wyłącz'}
                            variant='button save-button blue'
                            handleClick={this.renderWarning}
                            rendered={true}
                        />
                    </div>
                )
        );
    }

    renderFooter() {
       return this.prepareFooter();
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {'Obsłużenie alarmu'}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    updateElement(data) {
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    setElement() {
        if (this.props.element) {
            this.updateElement(this.props.element);
        }
    }
    getContainerListName() {
        return 'car-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('car.addSuccess');
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('car.updateSuccess');
    }
    documentTypeTemplate(rowData, props){
        const rowIndex = props.rowIndex;
        const { element }= this.state;
        let options = this.state.documentTypes;

        if (element && element.documents) {
            let document = element.documents[rowIndex];
            if (document.documentType) {
                let type = document.documentType;
                if (type.status.enumValue==='REMOVED') {
                    let copy = [...this.state.documentTypes];
                    copy.push(type);
                    options = copy;
                }
            }
        }
        return (
            <div style={{minWidth: '150px'}}>
                <InputDropdownComponent
                    id={`documentType${rowIndex}`}
                    name='documentType'
                    label={t('employee.document.documentType')}
                    value={rowData.documentType}
                    stateField={`element.documents[${rowIndex}]`}
                    showLabel={false}
                    options={options}
                    optionLabel='name'
                    onChange={this.handleChange}
                    insideTable
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    colClass='p-2'
                />
            </div>
        );
    }
    documentTypeNumberTemplate(rowData, props){
        const rowIndex = props.rowIndex;
        return (
        <div style={{minWidth: '120px'}}>
          <InputTextComponent
                id={`docNumber${rowIndex}`}
                name='docNumber'
                label={t('employee.document.number')}
                colClass='col-md-3'
                stateField={`element.documents[${rowIndex}]`}
                value={rowData.docNumber}
                showLabel={false}
                onChange={this.handleChange}
                viewMode={this.props.viewMode}
                insideTable
                validator={this.validator}
                    validators="required|max:30"
                />
            </div>
        );
    }
    documentDateToTemplate(rowData, props){
        const rowIndex = props.rowIndex;
        return (
            <div style={{minWidth: '120px', maxWidth: '200px'}}>
                <InputCalendarComponent
                    id={`absenceDateTo${rowIndex}`}
                    label={t('employee.document.dateTo')}
                    name='dateTo'
                    value={rowData.dateTo}
                    stateField={`element.documents[${rowIndex}]`}
                    showLabel={false}
                    onChange={this.handleChange}
                    insideTable
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators="required"
                    showIcon
                />
            </div>
        );
    }

    documentStatusTemplate(rowData,props){
        const rowIndex = props.rowIndex;
        let expirationStatus=rowData?.expirationStatus;
        let statusLabel = expirationStatus?.label;
        return (
        <div style={{minWidth: '120px'}}>
          <InputTextComponent
                id={`docNumber${rowIndex}`}
                name='docNumber'
                label={t('employee.document.number')}
                colClass='col-md-3'
                stateField={`element.documents[${rowIndex}]`}
                value={statusLabel}
                showLabel={false}
                onChange={this.handleChange}
                viewMode={'VIEW'}
                insideTable
                validator={this.validator}
                    validators="required|max:30"
                />
            </div>
        );
    }

    logEntryUserTemplate(rowData, props) {
        return rowData?.user?.fullName;
    }

    dateTemplate(field, format, rowData) {
        if (rowData[field] && moment(rowData[field]).isValid()) {
            const inputDateString=rowData[field];
            const [datePart, timePart] = inputDateString.split('T');
            const [time, milliseconds] = timePart.split('.');
            const [year, month, day] = datePart.split('-');
            const formattedDateTime = `${year}-${month}-${day} ${time}`;
            return formattedDateTime;
        } else {
            return '';
        }
    }

    renderDetails() {
        const {employee}=this.state;
        return (
            this.state.showWarning ?
                (
                    <div>
                        {this.state.showWarning && (
                            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                                {this.state.showWarning && (
                                    <div className={classNames('p-inline', 'p-text-bold', 'p-text-center')} style={{ textAlign: 'justify' }}>
                                        <i className={classNames('pi', 'pi-exclamation-triangle', 'p-mr-2')} style={{ fontSize: '1.5rem', color: 'orange' }}></i>
                                        <br />Czy jesteś pewien?<br />
                                        Zatwierdzenie spowoduje wyłączenie alarmu!
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )

                :

                !this.props.handled ? (
                    (
                        <div className='footer-dialog-button-container'>
                            <DataTable id='absences' value={this.state.document} responsiveLayout='scroll' emptyMessage={t('list.empty')}>
                                <Column
                                    field='documentType'
                                    header={t('employee.document.documentType')}
                                    body={this.documentTypeTemplate.bind(this)}
                                ></Column>
                                <Column
                                    field='docNumber'
                                    header={t('employee.document.number')}
                                    body={this.documentTypeNumberTemplate.bind(this)}
                                ></Column>
                                  <Column
                                    field='dateTo'
                                    header={t('employee.document.dateTo')}
                                    body={this.documentDateToTemplate.bind(this)}
                                ></Column>
                               <Column
                                    field='dateTo'
                                    header={t('employee.document.status')}
                                    body={this.documentStatusTemplate.bind(this)}
                                ></Column>
                                <Column
                                    field='reason'
                                    header={t('employee.details.firstName')}
                                    body={employee.firstName}
                                ></Column>
                                <Column
                                    field='reason'
                                    header={t('employee.details.lastName')}
                                    body={employee.lastName}
                                ></Column>
                            </DataTable>
                        </div>
                    )
                ) : (
                    <Card
                        footer={() => (
                            <div className='footer-dialog-button-container'>
                                <DataTable id='absences' value={this.state.logEntry} responsiveLayout='scroll' emptyMessage={t('list.empty')}>
                                    <Column
                                        field='createDate'
                                        header={t('employee.document.expiration-handled.date')}
                                        body={this.dateTemplate.bind(this, 'createDate', Constants.DATE_TIME_FORMAT)}
                                    ></Column>
                                    <Column
                                        field='user'
                                        header={t('employee.document.expiration-handled.user')}
                                        body={this.logEntryUserTemplate.bind(this)}
                                    ></Column>
                                    {/* <Column
                                    field='reason'
                                    header={t('employee.absence.reason')}
                                    //body={this.absenceReasonTemplate.bind(this)}
                                ></Column> */}
                                </DataTable>
                            </div>
                        )}
                    >
                    </Card>
                )
        );
    }

}

DocumentExpirationStatusDialog.defaultProps = {
    visible: false,
    handled: false
};

DocumentExpirationStatusDialog.propTypes = {
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    element: PropTypes.object
};

export default withTranslation()(DocumentExpirationStatusDialog);
