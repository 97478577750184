import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {ActionButton} from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import {Dialog} from 'primereact/dialog';
import {Card} from 'primereact/card';
import {t} from 'i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import NumberUtils from '../utils/NumberUtils';

import { TabView, TabPanel } from 'primereact/tabview';
import moment from 'moment';
import Constants from '../utils/constants';

class EmployeeStatisticDialog extends React.Component {
    constructor(props) {
        super(props);
       
        this.state = {
            closable: true,
        };
        this.generateHeader = this.generateHeader.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.user !== this.props.user) {
            this.setState({activeIndex: null});
        }
      }
      

    generateHeader() {
        let header = '';
        if (this.props?.user?.firstName) {
            header = this.props.user.firstName + ' ' + this.props?.user?.lastName;
        } else {
            header = this.props?.user?.fullName;
        }
        if (this.props.dateStart) {
            header += ': ' + moment(this.props.dateStart).format(Constants.DATE_FORMAT);
        }
        if (this.props.dateEnd) {
            header += ' - ' + moment(this.props.dateEnd).format(Constants.DATE_FORMAT);
        }
        return header;
    }

    renderFooter() {
        
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide(undefined);
                    }}
                />
            </div>
        );
    }

    renderHeader() {
        const header = this.generateHeader();
        
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>{header}</label>                    
                </DivContainer>
            </DivContainer>
        );
    }

    render() {
        const {className, id, key, name, onHide, visible} = this.props;        
        return (
            <Dialog
                ariaCloseIconLabel={t('dialog.closeDialog')}
                header={this.renderHeader()}
                visible={visible}
                resizable={false}
                className={className}
                key={`${key ? key : id ? id : name}-dialog`}
                onHide={() => (onHide ? onHide() : null)}
                closable={this.state.closable}
            >
                <Card footer={this.renderFooter()}>
                    <form noValidate onKeyDown={(e) => this.onKeyDown(e)}>
                        {this.renderDetails()}
                    </form>
                </Card>
            </Dialog>
        );
    }

    renderDetails() {        
        let activeIndex = this.state.activeIndex;
        if (activeIndex === null || activeIndex === undefined) {
            if (this.props?.stats?.absences?.length > 0) {
                activeIndex = 0;
            } else if (this.props?.stats?.documents?.length > 0) {
                activeIndex = 1;
            } else if (this.props?.stats?.projectAssigment?.length > 0) {
                activeIndex = 2;
            } else {
                activeIndex = 0;
            }
        }
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                <TabView activeIndex={activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
                    <TabPanel header="Nieobecności" >
                        <DataTable value={this.props.stats?.absences} responsiveLayout="scroll" emptyMessage={t('list.empty')}>
                            <Column field="absenceType" header="Rodzaj nieobecności"></Column>
                            <Column field="dateStart" header="Data od"></Column>
                            <Column field="dateEnd" header="Data powrotu"></Column>
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Dokumenty">
                        <DataTable value={this.props.stats?.documents} responsiveLayout="scroll" emptyMessage={t('list.empty')}>
                            <Column field="documentType" header="Rodzaj dokumentu"></Column>
                            {/* <Column field="validFrom" header="Od"></Column> */}
                            <Column field="validTo" header="Do"></Column>
                        </DataTable>
                    </TabPanel>
                    <TabPanel header="Przypisania do projektów" responsiveLayout="scroll" >
                        <DataTable value={this.props.stats?.projectAssigment} emptyMessage={t('list.empty')}>
                            <Column field="project.name" header="Projekt"></Column>
                            <Column field="dateStart" header="Od"></Column>
                            <Column field="dateEnd" header="Do"></Column>
                        </DataTable>
                    </TabPanel>
                </TabView>                    
                </DivContainer>
            </React.Fragment>
        );
    }
}

EmployeeStatisticDialog.defaultProps = {};

EmployeeStatisticDialog.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    user: PropTypes.object,
    stats: PropTypes.object,
    dateStart: PropTypes.object,
    dateEnd: PropTypes.object,
};

export default withTranslation()(EmployeeStatisticDialog);
