import React from 'react';
import BaseListContainer from '../baseContainers/BaseListContainer';
import ActionLink from '../components/ActionLink';

class BaseUserListContainer extends BaseListContainer {
    updateSearchCriteria(criteria) {
        return {
            email: criteria.email,
            firstName: criteria.firstName,
            lastName: criteria.lastName,
            roleType: criteria.roleType,
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResult: criteria.maxResult,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            email: '',
            firstName: '',
            lastName: '',
            roleType: undefined,
            status: undefined,
            id: null,
            firstResult: 0,
            maxResult: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('UserActivityStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
        this.enumService
            .getEnumList('RoleType')
            .then((roleTypeOptions) =>
                this.setState({
                    roleTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ról');
            });
    }

    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <ActionLink
                iconName='mdi-eye'
                iconSide='left'
                iconSize='icon-large'
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
        );
    }
    // to ovveride
    render() {}
}

BaseUserListContainer.defaultProps = {};

BaseUserListContainer.propTypes = {};

export default BaseUserListContainer;
