import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import DivContainer from '../../components/DivContainer';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import {t} from 'i18next';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';

import CountryService from '../../services/CountryService';
import AccommodationService from '../../services/AccommodationService';
import InputCalendarRangeComponent from '../../components/inputs/InputCalendarRangeComponent';
import Constants from '../../utils/constants';
import moment from 'moment';
import AccommodationCategoryService from '../../services/AccommodationCategoryService';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';

class AccommodationContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new AccommodationService());
        this.state = {
            elementId: props.elementId,
            categories: [],
            element: this.initNewElement(),
            statusOptions: [],
            viewMode: this.props.viewMode,
            countries: [],
        };
        this.countryService = new CountryService();
        this.accommodationCategory=new AccommodationCategoryService();
    }
    initNewElement() {
        return {
            name: '',
            accommodationNumber: '',
            dateFrom: null,
            dateTo: null,
            status: {
                enumValue: 'ACTIVE',
                label: t('AccommodationStatus.ACTIVE'),
            },
            address: {
                country: {},
                city: '',
                postalCode: '',
                streetName: '',
                streetNumber: '',
                houseNumber: '',
            }
        };
    }

    getContainerListName() {
        return 'accommodation-list-container';
    }

    renderPdfDownloadButton() {}
    getAddSucces() {
        const {t} = this.props;
        return t('accommodation.addSuccess');
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('accommodation.updateSuccess');
    }

    updateElement(data) {
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    initBeforeSetElement() {
        this.enumService
            .getEnumList('AccommodationStatus')
            .then((statusOptions) => {
                statusOptions.forEach((o) => (o.label = t('ClientStatus.' + o.enumValue)));
                this.setState({
                    statusOptions,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });

        this.countryService
            .getAll()
            .then((countries) => {
                this.setState({
                    countries,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy krajów');
            });

        this.accommodationCategory.getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
            .then(categories => this.setState({ categories: categories.content }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });
    }

    prepareFooterItems() {
        const {editUrl, elementId} = this.props;

        const result = super.prepareFooterItems();

        if (this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_CTO','ROLE_USER') && this.props.viewMode === 'VIEW') {
            result.push({label: 'Edytuj', className: 'float-right', href: `${editUrl}/${elementId}`});
        }

        return result;
    }
    updateElement(data) {

        if (data.dateFrom) {
            data.dateFrom = moment(data.dateFrom).toDate();
        }
        if (data.dateTo) {
            data.dateTo = moment(data.dateTo).toDate();
        }

        this.setState(
            {
                element: data
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    selectedItemTemplate = (option) => {
        console.log(option);
        if (option != null) {
            return (
                <div className='p-multiselect-token' style={{ background: option.color.value }}>
                    <div className="p-multiselect-token-label" >
                        {option.name}
                    </div>
                    <div className="p-multiselect-token-icon pi pi-times-circle" onClick={(el) => {
                        const categories = this.state.element.categories.filter(c => c.id !== option.id);
                        this.setState((prevState) => ({
                            element: {
                                ...prevState.element,
                                categories: categories
                            }
                        }));
                    }
                    }>
                    </div>
                </div>
            );
        }
        return;
    };
    
    renderDetails() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={t('accommodation.details.name')}
                        colClass={this.props.viewMode === 'NEW' ? 'col-md-12' : 'col-md-10'}
                        value={this.state.element.name}
                        validator={this.validator}
                        validators='required|max:250'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputDropdownComponent
                        id='status'
                        name='status'
                        label={t('internaluser.details.status')}
                        colClass='col-md-3'
                        value={this.state.element.status}
                        validator={this.validator}
                        options={this.state.statusOptions}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        optionLabel='label'
                        dataKey='enumValue'
                        validators='required'
                        valueView={this.state.element.status ? this.state.element.status.label : ''}
                    />
                    <InputCalendarRangeComponent
                        id='date'
                        idFrom='dateFrom'
                        idTo='dateTo'
                        nameFrom='dateFrom'
                        nameTo='dateTo'
                        labelFrom={t('project.details.dateStart')}
                        labelTo={t('project.details.dateEnd')}
                        dateFormatMoment={Constants.DATE_FORMAT}
                        colClass='col-md-6'
                        from={this.state.element.dateFrom}
                        to={this.state.element.dateTo}
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        showIcon={true}
                        validators={"required"}
                        validatorsTo={"not_required"}
                    />
    
                    <InputMultiSelectComponent
                        id='categories'
                        name='categories'
                        label={t('project.details.categories')}
                        colClass='col-md-8'
                        onChange={this.handleChange}
                        options={this.state.categories}
                        optionLabel='name'
                        value={this.state.element.categories}
                        viewMode={this.props.viewMode}
                        dataKey='id'
                        selectedItemTemplate={this.selectedItemTemplate}
                        filter
                        filterBy='name'

                    />

                    <InputTextComponent
                        id='accommodationNumber'
                        name='accommodationNumber'
                        label={t('accommodation.number')}
                        colClass='col-md-3'
                        value={this.state.element?.accommodationNumber}
                        validator={this.validator}
                        validators='required|numeric'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                </DivContainer>
                <DivContainer colClass='row mt-4'>
                    <InputDropdownComponent
                        id='addressCountry'
                        name='country'
                        label={t('accommodation.details.countryName')}
                        colClass='col-md-3'
                        value={this.state.element?.address?.country}
                        validator={this.validator}
                        options={this.state.countries}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        optionLabel='name'
                        dataKey='id'
                        validators='required'
                        valueView={this.state.element?.address?.country?.name}
                        stateField='element.address'
                        filter
                        filterBy='name'
                    />
                    <InputTextComponent
                        id='addressCity'
                        name='postalCode'
                        label={t('accommodation.details.postalCode')}
                        colClass='col-md-3'
                        value={this.state.element?.address?.postalCode}
                        validator={this.validator}
                        validators='max:10'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField='element.address'
                    />
                    <InputTextComponent
                        id='addressCity'
                        name='city'
                        label={t('accommodation.details.city')}
                        colClass='col-md-6'
                        value={this.state.element?.address?.city}
                        validator={this.validator}
                        validators='max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField='element.address'
                    />
                </DivContainer>
                <DivContainer colClass='row mt-4'>
                    <InputTextComponent
                        id='streetName'
                        name='streetName'
                        label={t('accommodation.details.streetName')}
                        colClass='col-md-6'
                        value={this.state.element?.address?.streetName}
                        validator={this.validator}
                        validators='max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField='element.address'
                    />
                    <InputTextComponent
                        id='streetNumber'
                        name='streetNumber'
                        label={t('accommodation.details.streetNumber')}
                        colClass='col-md-3'
                        value={this.state.element?.address?.streetNumber}
                        validator={this.validator}
                        validators='max:20'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField='element.address'
                    />
                    <InputTextComponent
                        id='houseNumber'
                        name='houseNumber'
                        label={t('accommodation.details.houseNumber')}
                        colClass='col-md-3'
                        value={this.state.element?.address?.houseNumber}
                        validator={this.validator}
                        validators='max:20'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField='element.address'
                    />
                    <InputTextComponent
                        id='phoneNumber'
                        name='phoneNumber'
                        label={t('employee.details.phoneNumber')}
                        colClass='col-xl-4 col-lg-4 col-md-4 col-sm-4'
                        value={this.state.element.phoneNumber}
                        validator={this.validator}
                        validators='not_required|phone|max:20'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

AccommodationContainer.defaultProps = {
    backUrl: '#/accommodation-list',
    cancelUrl: '/#/accommodation/details',
    editUrl: '/#/accommodation/edit',
    viewUrl: '/#/accommodation/details/',
    lazy: true,
};

AccommodationContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};
export default withTranslation()(AccommodationContainer);
