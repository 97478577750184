import BaseServiceCrud from "./BaseServiceCrud";

const path = 'country';

export default class CountryService extends BaseServiceCrud  {
    // Initializing important variables
    constructor() {
        super();
        this.path = path;
    }

    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getAll() {
        return this.fetch(`${this.domain}/${this.path}/list-all`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
