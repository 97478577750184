import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';

export const IconColorComponent = ({icon, componentStyle, color, fontSize, tootltipText, componentClassName}) => {
    return   <div style={{componentStyle}} className={componentClassName}>
               {tootltipText &&  <Tooltip id="tooltipIcon" target=".tooltipIcon" /> } 
                <i 
                    key={icon}
                    className={"cursor-pointer tooltipIcon mdi " + icon}
                    data-pr-tooltip={`${tootltipText}`}
                    style={{
                    color:color,
                    fontSize:fontSize}}/> 
            </div>
};

IconColorComponent.defaultProps = {
    color: "#00000",
    icon: "mdi-account",
    fontSize: "30px",
    componentClassName: "",
    tootltipText: undefined,
    componentStyle: undefined,
};

IconColorComponent.propTypes = {
    value:PropTypes.string.isRequired, 
    icon:PropTypes.string.isRequired, 
    fontSize:PropTypes.string, 
    componentStyle:PropTypes.object, 
    componentClassName:PropTypes.string, 
    tootltipText:PropTypes.string, 
};

export default IconColorComponent;
