import PropTypes from 'prop-types';
import React, { createContext } from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import ProjectService from '../../services/ProjectService';
import {t} from 'i18next';
import moment from 'moment';
import ActionLink from '../../components/ActionLink';
import CriteriaCalendarRangeComponent from '../../components/criteria/CriteriaCalendarRangeComponent';
import ClientService from '../../services/ClientService';
import NumberUtils from '../../utils/NumberUtils';
import { Chip } from 'primereact/chip';
import ProjectCategoryService from '../../services/ProjectCategoryService';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import InternalUserService from '../../services/InternalUserService';
import ExternalSupervisorService from '../../services/ExternalSupervisorService';
import { PrintButtonComponent } from '../../report/ReportUtils';
import AccommodationService from '../../services/AccommodationService'

class ProjectListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ProjectService());



        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleTypeOptions: [],
            categories: [],
            element: {                
                name: '',                
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            },
        };
        this.clientService = new ClientService();
        this.projectCategoryService = new ProjectCategoryService();
        this.internalUserService = new InternalUserService();
        this.externalSupervisorService = new ExternalSupervisorService();
        this.accommodationService = new AccommodationService();
    }

    updateSearchCriteria(criteria) {
        return {
            name: criteria.name,
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            categories: criteria.categories,
            cliebtName: criteria.clientName,
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            externalSupervisor: criteria.externalSupervisor,
            projectManager: criteria.projectManager,
            supervisor: criteria.supervisor,
            accommodation: criteria.accommodation
        };
    }

    getCleanSearchCriteria() {        
        return {
            name: '',
            status:  {enumValue: 'ACTIVE', label: 'Aktywny'},
            id: null,
            dateFrom: null,
            dateTo: null,
            categories: [],
            clientName: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'dateStart',
            sortAsc: false,
            projectManagerId: null,
            accommodation: undefined
        };
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('ProjectStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
        this.enumService
            .getEnumList('RoleType')
            .then((roleTypeOptions) =>
                this.setState({
                    roleTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ról');
            });
        this.projectCategoryService.getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
            .then(categories => this.setState({ categories: categories.content }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });

            //projectManagerOptions
        this.internalUserService.getSimpleList('ROLE_USER')
            .then(users => this.setState({ projectManagerOptions: users}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy menedżerów');
            });
           
        this.externalSupervisorService
            .getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'id', sortAsc: true })
            .then(data => this.setState({ externalSupervisorOptions: data.content }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy opiekunów');
            });

        this.internalUserService.getSimpleList(['ROLE_SUPERVISOR','ROLE_USER','ROLE_ADMIN','ROLE_CTO'])
            .then(users => this.setState({ supervisorOptions: users }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy menedżerów');
            });

        this.accommodationService
            .getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'id', sortAsc: true })
            .then((data) => {
                this.setState({ accommodationOptions: data.content });
            }).catch((err) => {
                this.showErrorMessage('Nie udało się pobrać listy noclegów');
            });
    }

    getCriteriaName() {
        return 'project-list-sc';
    }

    getContainerListName() {
        return 'project-list-container';
    }

    handleGoToDetail(href, e) {
        e.preventDefault();
        this.blockUi();
        const {criteria} = this.state;
        if (!this.props.hideFilters) {
            this.saveCookie(this.getCriteriaName(), JSON.stringify(criteria));
        }
        window.location.href = href;
    }

    renderPrintButton() {
        return (
            <PrintButtonComponent
                key={`print-button-pay-report`}
                reportFileNameBase={t('project.listName')}
                xml={false}
                pdf={false}
                csv={false}
                ods={false}
                xlsx={false}
                globalFormat='xlsx'
                noMenuFlag={true}
                generateReport={this.generateReport.bind(this)}
                disabled={!this.state.list.length > 0}
            />
        );
    }
    generateReport(format) {
        const criteria = this.state.criteria;
        switch (format) {
            case 'xlsx':
                return this.service.exportList(criteria, 'xlsx');
            default:
                return Promise.reject('Nieznany format');
        }
    }
    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='name'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.name}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('project.name')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                  <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    label={t('project.date')}
                    labelFrom={'od'}
                    labelTo={'do'}
                    name='date'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    colClass='col-xl-8 col-lg-8 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    showButtonBar
                    sharedLabel
                    maxDateInfinite
                    placeholderFrom={''}
                    placeholderTo={''}
                    inputsClassName='col-6'
                />
                <CriteriaTextComponent
                    id='clientName-sc'
                    name='clientName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.clientName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('project.clientName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                    <CriteriaMultiSelectComponent
                    id='categories'
                    name='categories'
                    label={t('project.categories')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.categories}
                    optionLabel='name'
                    value={this.state.criteria.categories}                                            
                    dataKey='id' 
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={t('project.status')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />

                <CriteriaDropdownComponent
                    id='pm-sc'
                    name='projectManager'
                    showClear
                    label={t('project.details.projectManager')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.projectManager}
                    options={this.state.projectManagerOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='fullName'
                    filterOptionValue='fullName'
                    refreshFromBackend
                    filter={true}
                    filterBy='fullName'
                />
               <CriteriaDropdownComponent
                    id='supervisor-sc'
                    name='supervisor'
                    showClear
                    label={t('project.details.supervisor')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.supervisor}
                    options={this.state.supervisorOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='fullName'
                    filterOptionValue='fullName'
                    refreshFromBackend
                    filter={true}
                    filterBy='fullName'
                />
              <CriteriaDropdownComponent
                    id='externalSupervisor-sc'
                    name='externalSupervisor'
                    showClear
                    label={t('project.details.externalSupervisor')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.externalSupervisor}
                    options={this.state.externalSupervisorOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='fullName'
                    filterOptionValue='fullName'
                    refreshFromBackend
                    filter={true}
                    filterBy='fullName'
                />
               <CriteriaDropdownComponent
                    id='accommodation-sc'
                    name='accommodation'
                    showClear
                    label={t('project.details.accommodation')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.accommodation}
                    options={this.state.accommodationOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='name'
                    filterOptionValue='name'
                    refreshFromBackend
                    filter={true}
                    filterBy='name'
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;       

        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <ActionLink
            iconName='mdi-eye'
            iconSide='left'
            iconSize='icon-large'
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
        );
    }

    prepareColumns() {
        return [
            {field: 'name', header: t('project.name'), sortable: true},
            {field: 'client.name', header: t('project.clientName'), sortable: true},
            {field: 'categories', header: t('project.categories'), sortable: false, body: this.categoriesTemplate.bind(this)},
            {field: 'dateStart', header: t('project.dateStart'), sortable: true},
            {field: 'dateEnd', header: t('project.dateEnd'), sortable: true},
            {field: 'panelRatio', header: t('project.panelRatio'), sortable: true, sortField: 'summary.panelRatio', body: this.panelRationTemplate.bind(this)},
            {field: 'status', header: t('project.status'), sortable: true, body: this.enumTemplate.bind(this, 'status')},
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    categoriesTemplate(rowData) {
        if (rowData?.categories) {
            return (
                rowData.categories.map(c => {
                    return (
                        <Chip style={{backgroundColor:c?.color?.value, color:"black"}} label={c.name} />
                    )
                })
            )
        } else {
            return '';
        }
    }

    panelRationTemplate(rowData) {
        const panelRatio = rowData['panelRatio'];
        if (panelRatio) {
            return (
                <span>{NumberUtils.formatNumber(panelRatio, 6, 6)}</span>
            )
        } else {
            return '';
        }

    }

    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER')
            ? [
                  {
                      label: t('project.newProject'),
                      type: 'BUTTON',
                      className: 'float-right',
                      variant: 'blue',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                      iconColor: 'white',
                      onClick: this.handleGoToNew,
                  },
                  {
                    type: 'BUTTON',
                    className: 'right-side',
                    variant: 'white',
                    disabled: !this.state.list || this.state.list.length < 1,
                    customRenderFunction: this.renderPrintButton,
                },
              ]
            : [];
    }



    render() {
        this.removeCookie("projectDashboardBackPath");
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/project-list'>{'Użytkownicy'}</BreadcrumbsItem>
                {this.renderView()}                
            </div>
        );
    }
}

ProjectListContainer.defaultProps = {
    detailUrl: '/#/project/details',
    newUrl: '/#/project/create',
    //disableSelection: true,
    lazy: true,
};

ProjectListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default ProjectListContainer;
