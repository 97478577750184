import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import { t } from 'i18next';
import ActionLink from '../components/ActionLink';
import ToolService from '../services/ToolService';
import ToolTypesService from '../services/ToolTypesService';
import ToolProducersService from '../services/ToolProducersService'
import ToolModelService from '../services/ToolModelService';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent'
import moment from 'moment/moment';

class ToolListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ToolService());
        this.state = {
            list: [],
            statusOptions: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dialogVisibile: false,
            element: this.initNewElement(),
        };
        this.handleIsAssigned = this.handleIsAssigned.bind(this);
        this.toolTypesService = new ToolTypesService();
        this.toolProducersService = new ToolProducersService();
        this.toolModelsService = new ToolModelService();
    }




    initializeFromBackend() {
        this.enumService
            .getEnumList('ToolStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });

        this.getToolTypes()
            .then((types) =>
                this.setState({
                    typeOptions: types.length > 0 ? types : null,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać typów narzędzi');
            });

        this.getProducers()
            .then((producers) =>
                this.setState({
                    producerOptions: producers.length > 0 ? producers : null,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać producentów');
            });

        this.getToolsModels()
            .then((models) =>
                this.setState({
                    modelOptions: models.length > 0 ? models : null
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać modeli');
            });
    }


    getToolTypes() {
        return this.toolTypesService.getList();
    }
    getProducers() {
        return this.toolProducersService.getList();
    }

    getToolsModels() {
        return this.toolModelsService.getList();
    }


    initNewElement() {
        return {
            model: {
                id: null,
                modelIdentifier: ''
            },
            producer:{
                id: null,
                name: ''
            },
            type: {
                id: null,
                typeName: ''
            },
            serialNumber: '',
            graver: '',
            notes: '',
            status: { enumValue: 'ACTIVE', label: 'Aktywny' }
        };
    }

    updateSearchCriteria(criteria) {
        return {
            producer: criteria.producer,
            model: criteria.model,
            type: criteria.type,
            status: criteria.status,
            serialNumber: criteria.serialNumber,
            graver: criteria.graver,
            notes: criteria.notes,
            isAssigned: criteria.isAssigned,


            firstResult: criteria.firstResult,
            maxResult: criteria.maxResult,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            id: null,
            firstResult: 0,
            maxResult: 10,
            sortField: 'id',
            sortAsc: true,

            producer: null,
            model: null,
            type: null,
            status: { enumValue: "ACTIVE", label: "Aktywne" },
            serialNumber: null,
            graver: null,
            notes: null,
            isAssigned: null,
        };
    }

    getCriteriaName() {
        return 'tool-list-sc';
    }

    getContainerListName() {
        return 'tool-list-container';
    }

    renderCriteria() {
        //filtry: serialNumber, type.name, producer.name, model.name, status; czy przypisany do zestawu;
        const statusOptions = this.state.statusOptions;
        const producerOptions = this.state.producerOptions;
        const typeOptions = this.state.typeOptions;
        const modelOptions = this.state.modelOptions;
        const assignedOptions = [
            {
                label: 'Tak',
                value: true
            },
            {
                label: 'Nie',
                value: false
            },
        ];

        return (
            <div className='row'>
                <CriteriaDropdownComponent
                    id='type-sc'
                    name='type'
                    showClear
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.type}
                    options={typeOptions}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Typ'}
                    refreshFromBackend
                    optionLabel='typeName'
                    timeoutHandler={this.refreshTable}
                    filter={true}
                    filterBy={'typeName'}
                    emptyMessage={'Brak dostępnych opcji'}
                />
                <CriteriaDropdownComponent
                    id='producer-sc'
                    name='producer'
                    showClear
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.producer}
                    options={producerOptions}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Producent'}
                    refreshFromBackend
                    optionLabel='name'
                    timeoutHandler={this.refreshTable}
                    filter={true}
                    filterBy={'name'}
                    emptyMessage={'Brak dostępnych opcji'}
                />
                <CriteriaDropdownComponent
                    id='model-sc'
                    name='model'
                    showClear
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.model}
                    options={modelOptions}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Model'}
                    refreshFromBackend
                    optionLabel='modelIdentifier'
                    timeoutHandler={this.refreshTable}
                    filter={true}
                    filterBy={'modelIdentifier'}
                    emptyMessage={'Brak dostępnych opcji'}
                />
                <CriteriaTextComponent
                    id='serialNumber-sc'
                    name='serialNumber'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.serialNumber}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Numer Seryjny'}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='graver-sc'
                    name='graver'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.graver}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Grawer'}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={statusOptions}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Status'}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                    filter={true}
                    filterBy={'label'}
                    emptyMessage={'Brak dostępnych opcji'}
                />
                {/* <CriteriaCheckboxComponent
                    id='isAssigned-sc'
                    name='isAssigned'
                    showClear
                    value={this.state.criteria.isAssigned}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Przypisany Do Zestawu'}
                    refreshFromBackend
                    style={{
                        a: '109p'
                    }}
                    timeoutHandler={this.refreshTable}
                /> */}                
                <CriteriaDropdownComponent
                    id='isAssigned-sc'
                    name='isAssigned'
                    showClear
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.isAssigned}
                    options={assignedOptions}
                    onChange={this.handleIsAssigned}
                    validator={this.validator}
                    label={'Przypisany Do Zestawu'}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                    filter={true}
                    filterBy={'label'}
                    emptyMessage={'Brak dostępnych opcji'}
                />
                <CriteriaTextComponent
                    id='notes-sc'
                    name='notes'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.notes}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Notatka'}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />            
            </div>
        );
    }


    handleIsAssigned(inputType, parameters, event, matchMode, refreshFromBackend, onAfterStateChange, timeoutHandler, delay) {
        this.setState((prevState) => ({
            criteria: {
                ...prevState.criteria,
                isAssigned: event.value,
            },
        }
        ), () => {
            this.refreshTable();
        });

    }

    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }


    statusTemplate(e) {
        if (e.status === 'ACTIVE') {
            return 'Aktywne'
        } else if (e.status === 'DELETED') {
            return 'Usunięte'
        }
    }
    nextInspTemplate(e) {
        if (e.nextInsp) {
            return e.nextInspDate;
        }
        return '';
    }

    updateListElements(data) {
        data?.forEach(d => {
            if (d.nextInsp) {
                d.nextInspDate = moment(new Date(d.nextInsp)).format('MM-YYYY');
            }
        });
        return data;
    }

    prepareColumns() {
        // serialNumber, type.name, producer.name, model.name, status, tools.name;
        return [
            { field: 'serialNumber', header: 'Numer Seryjny', sortable: true },
            { field: 'graver', header: 'Grawer', sortable: true },
            { field: 'type.typeName', header: 'Typ', sortable: true },
            { field: 'producer.name', header: 'Producent', sortable: true },
            { field: 'model.modelIdentifier', header: 'Model', sortable: true },
            { field: 'status', header: 'Status', sortable: true, body: this.statusTemplate.bind(this) },
            { field: 'notes', header: 'Notatka', sortable: true },
            { field: 'nextInsp', header: 'Data przeglądu', body: this.nextInspTemplate.bind(this), sortable: true },
            { field: 'toolkit.name', header: 'Zestaw', sortable: true },
            
            {
                key: 'actions',
                header: t('tool.details'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO')
            ? [
                {
                    label: t('tool.newButton'),
                    type: 'BUTTON',
                    className: 'float-right',
                    variant: 'blue',
                    iconName: 'mdi-plus',
                    iconSide: 'left',
                    iconColor: 'white',
                    onClick: this.handleGoToNew,
                },
            ]
            : [];
    }

    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    refreshTable() {
        this.getList();
        this.initializeFromBackend();
    }
    actionTemplate(rowData) {
        const { detailUrl } = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <ActionLink
                iconName='mdi-eye'
                iconSide='left'
                iconSize='icon-large'
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
        );
    }
    
    render() {
        return (
            <div className='container-fluid tool-list'>
                <BreadcrumbsItem to='/tool-list'>{'Narzędzia'}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }
}

ToolListContainer.defaultProps = {
    detailUrl: '/#/tool/details',
    newUrl: '/#/tool/create',
    lazy: true,
};

ToolListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,

};

export default ToolListContainer;
