import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {ActionButton} from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import {Dialog} from 'primereact/dialog';
import {Card} from 'primereact/card';
import {t} from 'i18next';
import CriteriaMultiSelectComponent from '../components/criteria/CriteriaMultiSelectComponent';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import { Accordion, AccordionTab } from 'primereact/accordion';
import InputAutocompleteComponent from '../components/inputs/InputAutocompleteComponent';
import ProjectService from '../services/ProjectService';
import ProjectCategoryService from '../services/ProjectCategoryService';
import BaseContainer from '../baseContainers/BaseContainer';
import Constants from '../utils/constants';
import InputCalendarComponent from '../components/inputs/InputCalendarComponent';
import { Tooltip } from 'primereact/tooltip';
import EmployeeProjectPlaningStatistic from './EmployeeProjectPlaningStatistic';
import EmployeeStatisticDialog from './EmployeeStatisticDialog';
import { CustomMessages } from '../components/CustomMessages';

class SelectProjectDialog extends BaseContainer {
    constructor(props) {
        super(props, new ProjectService());
        this.projectCategoryService = new ProjectCategoryService();
        this.state = {
            closable: true,
            criteria: {
                categories: [],

            },
            element: {},
            filters: {},
            renderStatistic: false,
            loadingStatistic: false,
        };
        this.assignStatuses = [
            {code: "ALL", label: "Wszystkie projekty"},
            {code: "NO_LEADER", label: "Tylko projekty bez liderów"},
        ];

        this.renderFilters = this.renderFilters.bind(this);
        this.fetchUserStatistic = this.fetchUserStatistic.bind(this);
        this.clean = this.clean.bind(this);

        this.validator.addCustomRule('date_range', 'Data startu musi być wcześniejsza niż Data zakończenia', value => {
            const { dateStart, dateEnd } = this.state.element;
            return dateStart?.getTime() <= dateEnd?.getTime();
        });
    }

    componentDidMount(){
        super.componentDidMount();
        this.projectCategoryService.getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
            .then(categories => this.setState({ categories: categories.content }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });
    }

    fetchUserStatistic() {
            const { project, dateStart, dateEnd } = this.state.element;
            const userId = this.props?.userId;

            if (project && dateStart && dateEnd) {

                this.setState({renderStatistic: true, loadingStatistic: true},
                    () => {
                        this.service.getEmployeeProjectPlaningStatistics(userId, project?.id, dateStart, dateEnd, false)
                        .then(stats => {
                            this.setState({
                                loadingStatistic: false,
                                stats})
                        })
                        .catch(err => {
                            this.setState({renderStatistic: false},
                                () => this,this.showErrorMessage('Nie udało się pobrać statystyk użytkownika!')
                            )
                        });
                })
            } else {
                this.setState({renderStatistic: false, loadingStatistic: false});
            }

    }


    renderFooter() {
        const { onSelect } = this.props;
        const  { project, dateStart, dateEnd }  = this.state.element;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.clean();
                        this.setState({element: {}, filters: {}});
                        this.props.onHide();
                    }}
                />
                <ActionButton
                    label='Wybierz'
                    variant={'button float-right'}
                    handleClick={(e) => {                        
                        if (this.validator.allValid()) {
                            this.props.onHide();
                            this.props.onSelect(project, dateStart, dateEnd);
                            this.clean();

                        } else {
                            this.validator.showMessages();
                            this.scrollToError = true;
                            this.forceUpdate();
                        }
                        
                    }}
                />
            </div>
        );
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    {this.props.leader ?
                        <label>Wybierz projekt dla lidera</label>
                        :     <label>Wybierz projekt dla pracownika</label>}
                </DivContainer>
            </DivContainer>
        );
    }


    getProjectList(query){
        const { categories, status } = this.state.criteria;
        const { projectsAfterDate } = this.props;

        const date = projectsAfterDate ? projectsAfterDate : new Date();
        
        this.service
        .search(query, "ACTIVE", categories, status?.code === 'NO_LEADER', date)
        .then((projects) => {
            this.setState({projects})
        })            
        .catch(() => {
            this.showErrorMessage('Nie udało się pobrać listy dostępnych projektów');
        });
    }

    render() {
        const {className, id, key, name, onHide, visible, children} = this.props;
        const employeeLabel= this.props.leader ? "Lider: " : "Pracownik: ";
        return (
            <Dialog
                ariaCloseIconLabel={t('dialog.closeDialog')}
                header={this.renderHeader()}
                visible={visible}
                className={className}
                resizable={false}
                // style={style}
                key={`${key ? key : id ? id : name}-dialog`}
                onHide={() => {
                    this.clean();
                    this.setState({element: {}, filters: {}});
                    if (onHide) {
                        onHide();
                    }
                }}
                closable={this.state.closable}
                style={{width: '800px'}}
                onShow={() => {
                    this.setState({element: {}, filters: {}});
                }}
            >
                <Card footer={this.renderFooter()}>
                    {this.renderFilters()}
                    <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />

                    <EmployeeStatisticDialog
                        visible={this.state.visibleEmployeeStatistic}
                        onHide={() => this.setState({visibleEmployeeStatistic: false})}
                        user={{id: this.props.userId, fullName: this.props.userName}}
                        stats={this.state.stats}
                        dateStart={this.state.element.dateStart}
                        dateEnd={this.state.element.dateEnd}
                    />

                    <form noValidate>
                    <Tooltip id="tooltipEmployeeStatusInfo" target=".employeeStatusInfo" />


                    <DivContainer colClass="row">
                        <DivContainer colClass="col-6">
                            <label style={{
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '14px',
                                        color: '#8e92a3'}}>
                                {employeeLabel + this.props.userName}
                            </label>
                        </DivContainer>
                        <DivContainer colClass='col-6'>
                            <EmployeeProjectPlaningStatistic 
                                loading={this.state.loadingStatistic}
                                render={this.state.renderStatistic}
                                stats={this.state.stats}
                                showDetails={() => this.setState({visibleEmployeeStatistic: true})}
                            />
                        </DivContainer>                        
                    </DivContainer>
                        
                        
                        <DivContainer colClass='row'>
                            <InputAutocompleteComponent
                                    id={`project`}
                                    name='project'
                                    label='Projekt'
                                    value={this.state.element.project}
                                    dropdown
                                    colClass='col-md-6'
                                    forceSelection
                                    viewMode='EDIT'
                                    validator={this.validator}
                                    validators='required'
                                    showLabel={true}
                                    onChange={this.handleChange}
                                    insideTable
                                    filteredList={this.state.projects}
                                    filterList={(query) => this.getProjectList(query)}
                                    field='name'                                        
                                    minLength={3}
                                    placeholder={t('search.project.placeholder')}
                                    onAfterStateChange={() => {
                                        const project = this.state.element.project;
                                        let start = project?.dateStart ? new Date(project?.dateStart) : null;
                                        if (this.props.minDate) {
                                            if (!start || this.props.minDate.getTime() > start.getTime()) {
                                                start = this.props.minDate;
                                            }
                                        }
                                        const end = project?.dateEnd ? new Date(project?.dateEnd) : null
                                        this.setState((prevState => ({
                                            dateMin: start,
                                            dateMax: end,
                                            element: {
                                                ...prevState.element,
                                                dateStart: start,                                                
                                                dateEnd: end,
                                            }
                                        })),
                                            () => this.fetchUserStatistic()
                                        )
                                    }}
                                />
                        </DivContainer>
                        <DivContainer colClass='row'>
                            <InputCalendarComponent
                                id='dateStart'
                                name='dateStart'
                                label={t('project.details.dateStart')}
                                dateFormatMoment={Constants.DATE_FORMAT}
                                colClass='col-md-6'
                                value={this.state.element.dateStart}
                                validator={this.validator}
                                validators='required|date_range'                                    
                                onChange={this.handleChange}
                                viewMode={'EDIT'}
                                showIcon={true}
                                minDate={this.state.dateMin}
                                maxDate={this.state.dateMax}
                                onAfterStateChange={this.fetchUserStatistic}
                            />
                            <InputCalendarComponent
                                id='dateEnd'
                                name='dateEnd'
                                label={t('project.details.dateEnd')}
                                dateFormatMoment={Constants.DATE_FORMAT}
                                colClass='col-md-6'
                                value={this.state.element.dateEnd}
                                validator={this.validator}
                                validators='required|date_range'                                    
                                onChange={this.handleChange}
                                viewMode={'EDIT'}
                                showIcon={true}
                                minDate={this.state.dateMin}
                                maxDate={this.state.dateMax}
                                onAfterStateChange={this.fetchUserStatistic}
                            />
                        </DivContainer>                        
                    </form> 
                </Card>
            </Dialog>
        );
    }

    clean() {
        this.setState((prevState) => ({
            dateMin: undefined,
            dateMax: undefined,
            renderStatistic: false,
            element: {
                ...prevState.element,
                project: null,
            },
        }));
    }

    renderFilters() {
        return (
            <Accordion className='filter-accordion'>
                <AccordionTab
                    header={
                        <React.Fragment>
                            <img className='card-icon' alt={t('list.filter')} src={`/images/ico_filter.svg`} />
                            <span>{t('list.filter')}</span>
                        </React.Fragment>
                    }
                >
                    
                        <DivContainer colClass='col-12 form-search-criteria'>
                        <div className='row'>
                
                            <CriteriaMultiSelectComponent
                                id='categories'
                                name='categories'
                                label={t('project.categories')}
                                colClass='col-md-6'
                                onChange={this.handleChangeSc}
                                options={this.state.categories}
                                optionLabel='name'
                                value={this.state.criteria.categories}                                            
                                dataKey='id'
                                onAfterStateChange={this.clean} 
                            />
                            {this.props.leader ?
                            <CriteriaDropdownComponent
                                id='status-sc'
                                name='status'
                                showClear
                                label={'Status przypisania lidera'}
                                colClass='col-md-6'
                                value={this.state.criteria.status}
                                options={this.assignStatuses}
                                placeholder='Wszystkie'
                                onChange={this.handleChangeSc}
                                validator={this.validator}
                                optionLabel='label'
                                dataKey='code'
                                onAfterStateChange={this.clean}
                                
                            />
                             : null}
                        </div>
                        </DivContainer>
                    
                </AccordionTab>
            </Accordion>
        )
    }


}


SelectProjectDialog.defaultProps = {
    leader: true
};

SelectProjectDialog.propTypes = {
    minDate: PropTypes.instanceOf(Date),
    onHide: PropTypes.func,
    onSelect: PropTypes.func,
    userId: PropTypes.number,
    userName: PropTypes.string,
    leader: PropTypes.bool
};

export default withTranslation()(SelectProjectDialog);
