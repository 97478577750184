import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {ActionButton} from '../../components/ActionButton';
import DivContainer from '../../components/DivContainer';
import {Dialog} from 'primereact/dialog';
import {Card} from 'primereact/card';
import {t} from 'i18next';
import { Accordion, AccordionTab } from 'primereact/accordion';
import InputAutocompleteComponent from '../../components/inputs/InputAutocompleteComponent';
import BaseContainer from '../../baseContainers/BaseContainer';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';
import { CustomMessages } from '../../components/CustomMessages';
import AccommodationService from '../../services/AccommodationService';
import ProjectService from '../../services/ProjectService';
import moment, { unix } from 'moment';
import EmployeeService from '../../services/EmployeeService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import ActionLink from '../../components/ActionLink';
import EmployeeCategoryService from '../../services/EmployeeCategoryService';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import ColorPickerButton from '../ColorPickerButton';
import CriteriaAutocompleteComponent from '../../components/criteria/CriteriaAutocompleteComponent';

class SelectEmployeeDialog extends BaseContainer {
    constructor(props) {
        super(props, new AccommodationService());
        this.state = {
            acomId: props?.acomId,
            dateStart: props?.dateStart,
            closable: true,
            project: undefined,
            criteria: {
                categories: [],
                project: undefined
            },
            element: {
                users: [],
                color: ''
            },
            filters: {},
            color:''
        };

        this.renderFilters = this.renderFilters.bind(this);
        this.clean = this.clean.bind(this);

        this.validator.addCustomRule('date_range', 'Data startu musi być wcześniejsza niż Data zakończenia', (value, params) => {
            const index = parseInt(params[0]);
            let user = this.state.element?.users[index];
            return moment(user?.dateStart).toDate().getTime() < moment(user?.dateEnd).toDate().getTime();
        },
            (message, params) => message
        );

        this.validator.addCustomRule('assingment_duplicates', 'Powielona data przypisania do noclegu', (value, params) => {
            const index = parseInt(params[0]);
    
            const list = this.state.element.users;
            const user = list[index];
            
            const duplicates = list.filter(u => u.dateStart && u.dateEnd && u?.employee?.id === user?.employee?.id
                && u !== user &&
                 (user.employeeAccommodationId !== u.employeeAccommodationId
                  || u.employeeAccommodationId===undefined
                ))
                .filter(u => {
                    return (moment(user.dateStart).toDate().getTime() <= moment(u.dateStart).toDate().getTime() && moment(user.dateEnd).toDate().getTime() >=moment(u.dateEnd).toDate().getTime())
                        || (moment(user.dateStart).toDate().getTime() >= moment(u.dateStart).toDate() && moment(user.dateEnd).toDate().getTime() <= moment(u.dateEnd).toDate().getTime())
                        || (moment(user.dateStart).toDate().getTime() <= moment(u.dateStart).toDate() && moment(user.dateEnd).toDate().getTime() >= moment(u.dateStart).toDate().getTime())
                        || (moment(user.dateStart).toDate().getTime() <= moment(u.dateEnd).toDate() && moment(user.dateEnd).toDate().getTime() >= moment(u.dateEnd).toDate().getTime());
                });
            return duplicates.length == 0;
        },
            (message, params) => message,
        );
        this.empCatService = new EmployeeCategoryService()
        this.empService = new EmployeeService();
        this.projectService = new ProjectService();
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.searchProjects = this.searchProjects.bind(this);

    }

    componentDidMount(){
        super.componentDidMount();
        this.empCatService
            .getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
            .then((data) => {
                this.setState({
                    categories: data.content
                })
            }).catch((err) => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });

        this.service
            .getAssignedEmployees(this.state.acomId, this.state?.dateStart)
            .then(data =>{
                this.setState((prev) => ({
                    element: {
                        ...prev.element,
                        users: data.users,
                        accommodationId: data.accommodationId,
                        day: this.state?.dateStart
                    }
                }))
            }
            ).catch(() => {
                this.showErrorMessage('Nie udało się pobrać przypisanych pracowników');
            });
        this.projectService
            .getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
            .then((data) => {
                this.setState((prev) => ({
                    projects: data.content
                }))
            }).catch((err) => {
                this.showErrorMessage('Nie udało się pobrać listy projektów');
            })
    }

    componentDidUpdate() {
        this.refreshJwtToken();
        if (this.scrollToError) {
            this.scrollToError = false;
            this.scrollToFirstError();
        }
    }

    searchProjects(query){
        return this.projectService.search(query);
    }

    renderFooter() {
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.clean();
                        this.setState({element: {}, filters: {}});
                        this.props.onHide();
                    }}
                />
                <ActionButton
                    label='Wybierz'
                    variant={'button float-right'}
                    handleClick={(e) => {                        
                        if (this.validator.allValid()) {
                            //this.props.onHide();                        
                            this.props.onSelect(this.state.element);
                            this.clean();

                        } else {
                            this.validator.showMessages();
                            this.scrollToError = true;
                            this.forceUpdate();
                        }
                        
                    }}
                />
            </div>
        );
    }

    renderHeader() {
        const {accommodationName} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    {<label>Wybierz pracownika - {accommodationName}</label>}
                </DivContainer>
            </DivContainer>
        );
    }

    
    getEmployees(query, dateStart, dateEnd) {
        const { categories, project } = this.state.criteria;
        this.empService
            .search(query, null, null, categories, project, dateStart, dateEnd)
            .then((employees) => {
                this.setState({
                     employees
                })
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy dostępnych pracowników');
            });
    }
    
    userTemplate(rowData, props) {
        const { rowIndex } = props;
        return (
            <div>
                <div>
                    <InputAutocompleteComponent
                        id={`employee${rowIndex}`}
                        name="employee"
                        colClass='col-md-7'
                        label={t('project.details.employee')}
                        value={rowData.employee}
                        dropdown
                        forceSelection
                        viewMode={'EDIT'}
                        validator={this.validator}
                        //validators={`required|employee_local_assigment_duplicates:${rowIndex},user`}
                        showLabel={false}
                        onChange={this.handleChange}
                        insideTable={true}
                        filteredList={this.state.employees}
                        filterList={query => this.getEmployees(query, rowData.dateStart, rowData?.dateEnd)}
                        field="fullName"
                        stateField={`element.users[${rowIndex}]`}
                        minLength={3}
                        placeholder={t('search.users.placeholder')}
                    />
                </div>
            </div>
        )
    }
    userStartTemplate(rowData, props) {
        const { rowIndex } = props;
        rowData.dateStart = moment(rowData.dateStart).toDate();
        return (
            <InputCalendarComponent
                id={`employeeDateStart${rowIndex}`}
                colClass='col-md-2'
                name="dateStart"
                label='Od'
                value={rowData.dateStart}
                onChange={this.handleChange}
                viewMode={'EDIT'}
                validator={this.validator}
                validators={`required|date_range:${rowIndex}|assingment_duplicates:${rowIndex}`}
                stateField={`element.users[${rowIndex}]`}
                insideTable
                showLabel={false}
                showIcon
            />
        )
    }

    userEndTemplate(rowData, props) {
        const { rowIndex } = props;
        return (
            <InputCalendarComponent
                id={`employeeDateEnd${rowIndex}`}
                colClass='col-md-2'
                name="dateEnd"
                label='Do'
                value={moment(rowData.dateEnd).toDate()}
                onChange={this.handleChange}
                viewMode={'EDIT'}
                validator={this.validator}
                validators={`required|date_range:${rowIndex}|assingment_duplicates:${rowIndex}`}
                stateField={`element.users[${rowIndex}]`}
                insideTable
                showLabel={false}
                showIcon
            />
        )
    }
    handleAddNewUser() {
        const { users } = this.state.element;
        const { dateStart } = this.state;
        
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                users: users.concat({ dateStart, dateEnd: moment(dateStart).add(7,'days') }),
            },
        }));
    }

    userActionsTemplate(rowData, props) {
        const { rowIndex } = props;
        const user = rowData;
        return (
            <div className="col-md-1">
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    rendered={this.props.viewMode != 'VIEW'}
                    handleClick={(e) => {
                        let users = this.state.element.users.filter(u => u != user);
                        this.setState((prevState) => ({
                            element: {
                                ...prevState.element,
                                users,
                            },
                        }));
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                />
            </div>
        )
    }
    renderProjects() {
   
    }    
    expandAll = () => {
     
    };
    allowExpansion = (rowData) => {

    };

    getProjects(userId, rowData) {
        let users = this.state.element.users;
        this.projectService
            .fetchUsersProjects(userId,rowData.dateStart,rowData.dateEnd)
            .then((data) => {
                users.filter((x) => x.employee.id === userId)[0].projects = data;
                this.setState((prev) => ({
                    element: {
                        ...prev.element,
                        users: users
                    }
                }));
            }).catch((err) => {
                this.showErrorMessage('Nie udało się pobrać listy projektów');
            });
    }

    rowExpansionTemplate(data) {
        const projects = this.state.element?.users.filter((x) => x?.employee?.id === data?.employee?.id)[0].projects;
        return (
            <DivContainer colClass='row'>
                <DataTable value={projects} header={'Projekty'} emptyMessage={t('list.empty')}>
                    <Column field="name" header={t('project.name')} sortable></Column>
                    <Column field="dateStart" header={t('project.dateStart')} sortable></Column>
                    <Column field="dateEnd" header={t('project.dateEnd')} sortable></Column>
                </DataTable>
            </DivContainer>
        );
    };

    renderColorPicker(rowData, props) {
        //TODO 
        const { rowIndex } = props;
        return (
            <div>
                <ColorPickerButton
                    color={this.state.element.users[rowIndex]?.color?.value}
                    onChange={(e) => {
                        let users = [...this.state.element.users];
                        let updatedUser = {
                            ...users[rowIndex],
                            color: { value: e }
                        };
                        users[rowIndex] = updatedUser;
                        this.setState(prevState => ({
                            element: {
                                ...prevState.element,
                                users: users
                            }
                        }));
                    }}
                // color={this.state.element.color}
                // onChange={(e) => {
                //     this.setState(prevState => ({
                //         element: {
                //                 ...prevState.element,
                //                 color: e
                //             }
                //         }));
                //     }}
                />
           </div>
         );
    }

    render() {
        const { className, id, key, name, onHide, visible } = this.props
        return (
            <Dialog
                ariaCloseIconLabel={t('dialog.closeDialog')}
                header={this.renderHeader()}
                visible={visible}
                className={className}
                resizable={false}
                // style={style}
                key={`${key ? key : id ? id : name}-dialog`}
                onHide={() => {
                    this.clean();
                    this.setState({ element: {}, filters: {} });
                    if (onHide) {
                        onHide();
                    }
                }}
                closable={this.state.closable}
                style={{ width: '1800px' }}
                onShow={() => {
                    this.setState({ filters: {} });
                }}
            >
                <Card footer={this.renderFooter()}>
                    {this.renderFilters()}
                    <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                    <DivContainer colClass='row'>
                        <Card
                        >
                            <DivContainer colClass='row'>
                                <DataTable value={this.state.element?.users} responsiveLayout="scroll" emptyMessage={t('list.empty')}
                                    expandedRows={this.state.expandedRows}
                                    onRowToggle={(e) => {
                                        this.setState({ expandedRows: e.data });
                                    }
                                    }
                                    onRowExpand={(e) => {
                                        if (e.data?.employee && e.data?.employee?.id) {
                                            this.getProjects(e.data?.employee?.id, e.data);
                                        }
                                    }}
                                    rowExpansionTemplate={this.rowExpansionTemplate}
                                >
                                    <Column expander={this.allowExpansion} style={{ width: '5rem' }} />
                                    <Column field="employee" header="Pracownik" body={this.userTemplate.bind(this)}></Column>
                                    <Column field="dateStart" header="Od" body={this.userStartTemplate.bind(this)}></Column>
                                    <Column field="dateTo" header="Do" body={this.userEndTemplate.bind(this)}></Column>
                                    <Column field="color" header="Kolor" body={this.renderColorPicker.bind(this)}></Column>
                                    {this.props.viewMode != 'VIEW' ? (
                                        <Column header="" body={this.userActionsTemplate.bind(this)}
                                        ></Column>
                                    ) : null}
                                </DataTable>
                            </DivContainer>
                            <DivContainer rendered={this.props.viewMode != 'VIEW'}>
                                <ActionLink
                                    label={t('project.details.addEmployeeButton')}
                                    iconName='mdi-plus'
                                    iconSide='left'
                                    iconSize='icon-large'
                                    variant='blue'
                                    className='float-right'
                                    key={'add-activity-button'}
                                    handleClick={this.handleAddNewUser.bind(this)}
                                />
                            </DivContainer>
                        </Card>
                    </DivContainer>
                </Card>
            </Dialog>
        );
    }

    clean() {
        this.setState((prevState) => ({
            dateMin: undefined,
            dateMax: undefined,
            renderStatistic: false,
            element: {
                ...prevState.element,
                project: null,
            },
        }));
    }

    renderFilters() {
        return (
            <Accordion className='filter-accordion'>
                <AccordionTab
                    header={
                        <React.Fragment>
                            <img className='card-icon' alt={t('list.filter')} src={`/images/ico_filter.svg`} />
                            <span>{t('list.filter')}</span>
                        </React.Fragment>
                    }
                >
                    
                        <DivContainer colClass='col-12 form-search-criteria'>
                        <div className='row'>
                            <CriteriaMultiSelectComponent
                                id='categories'
                                name='categories'
                                label={t('employeeCategory.listName')}
                                colClass='col-md-4'
                                onChange={this.handleChangeSc}
                                options={this.state.categories}
                                optionLabel='name'
                                value={this.state.criteria.categories}
                                // dataKey='id'
                            // filter
                            // filterBy={'name'}
                            />
                            <CriteriaAutocompleteComponent
                                id='project-sc'
                                name='project'
                                colClass='col-lg-8 col-sm-8'
                                label={'Projekt'}
                                value={this.state.criteria.project}
                                completeMethod={this.searchProjects.bind(this)}
                                onChange={this.handleChangeSc}
                                //itemTemplate={this.searchLocationItemTemplate.bind(this)}
                                refreshFromBackend
                                timeoutHandler={()=>{}}
                                showClear
                            />
                        </div>
                    </DivContainer>

                </AccordionTab>
            </Accordion>
        )
    }


}
SelectEmployeeDialog.defaultProps = {
    leader: true
};

SelectEmployeeDialog.propTypes = {
    minDate: PropTypes.instanceOf(Date),
    onHide: PropTypes.func,
    onSelect: PropTypes.func,
    userId: PropTypes.number,
    userName: PropTypes.string,
    leader: PropTypes.bool
};

export default withTranslation()(SelectEmployeeDialog);
