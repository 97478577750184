import { t } from 'i18next';
import { Card } from 'primereact/card';
import PropTypes from 'prop-types';
import React from 'react';
import BaseListContainer from '../../../baseContainers/BaseListContainer';
import ActionLink from '../../../components/ActionLink';
import { CustomMessages } from '../../../components/CustomMessages';
import DivContainer from '../../../components/DivContainer';
import YesNoDialog from '../../../components/YesNoDialog';
import InputNumberComponent from '../../../components/inputs/InputNumberComponent';
import BlockUi from '../../../components/waitPanel/BlockUi';
import ExtraEventService from '../../../services/ExtraEventService';
import ProjectService from '../../../services/ProjectService';
import NumberUtils from '../../../utils/NumberUtils';
import InputTextComponent from '../../../components/inputs/InputTextComponent';
import InputMaskComponent from '../../../components/inputs/InputMaskComponent';
import CriteriaCalendarRangeComponent from '../../../components/criteria/CriteriaCalendarRangeComponent';
import CriteriaDropdownComponent from '../../../components/criteria/CriteriaDropdownComponent';
import { PrintButtonComponent } from '../../../report/ReportUtils';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';

class ProjectExtraEventListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ExtraEventService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            approvalStates: [],
            element: {
                id: undefined,
                approvedHour: undefined,
            },
            dialogVisibile: false,
            deleteConfirmDialogVisible: false,
        };
        this.projectService = new ProjectService();
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('ExtraEventApprovalStateFilter')
            .then((approvalStates) => this.setState({approvalStates})
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy stanów akceptacji');
            });
    }
    updateSearchCriteria(criteria) {
        return {
            projectId: this.props.projectId,
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            approvalState: criteria.approvalState,
            approvalRdState: criteria.approvalRdState,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            projectId: this.props.projectId,
            dateFrom: undefined,
            dateTo: undefined,
            approvalState: undefined,
            approvalRdState: 'APPROVED',
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'reportedDay.date',
            sortAsc: true,
        };
    }

    getCriteriaName() {
        return 'extra-event-list-sc';
    }

    getContainerListName() {
        return 'extra-event-list-container';
    }

    renderCriteria() {
        return (
            <div>
                <div className='row'>
                    <CriteriaCalendarRangeComponent
                        id='date-sc'
                        idFrom='dateFrom-sc'
                        idTo='dateTo-sc'
                        label={t('extraEvent.search.date')}
                        labelFrom={'od'}
                        labelTo={'do'}
                        name='createDate'
                        nameFrom='dateFrom'
                        nameTo='dateTo'
                        colClass='col-xl-8 col-lg-8 col-sm-12'
                        from={this.state.criteria.dateFrom}
                        to={this.state.criteria.dateTo}
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                        showButtonBar
                        sharedLabel
                        maxDateInfinite
                        placeholderFrom={''}
                        placeholderTo={''}
                        inputsClassName='col-6'
                        refreshFromBackend
                    />
                    <CriteriaDropdownComponent
                        id='approval-sc'
                        name='approvalState'
                        showClear
                        label={t('extraEvent.search.approvalState')}
                        colClass='col-lg-4 col-sm-6'
                        value={this.state.criteria.approvalState}
                        options={this.state.approvalStates}
                        placeholder={t('list.all')}
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                        optionLabel='label'
                        filterOptionValue='label'
                        refreshFromBackend
                        timeoutHandler={this.refreshTable}
                    />
                </div>            
            </div>
        );
    }
    renderPrintButton() {
        return (
            <PrintButtonComponent
                key={`print-button-pay-report`}
                reportFileNameBase={t('extraEvent.label')}
                xml={false}
                pdf={false}
                csv={false}
                ods={false}
                xlsx={false}
                globalFormat='xlsx'
                noMenuFlag={true}
                generateReport={this.generateReport.bind(this)}
                disabled={!this.state.list.length > 0}
            />
        );
    }


    handleApproved() {
        if (this.validator.allValid()) {
            this.blockUi();
            this.service
                .approve(this.state.element)
                .then((project) => {
                    this.showSuccessMessage('Zaakceptowano');
                    this.closeDeleteDialog();
                    this.refreshTable();
                    this.props.onProjectChanged(project);
                })
                .catch((err) => {
                    this.showErrorMessage(err.message, 10000);
                })
                .finally(() => {
                    this.unblockUi();
                });
        } else {
            this.validator.showMessages();
            this.scrollToError = true;
            this.forceUpdate();
        }
    }

    actionTemplate(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi mdi-check-bold'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => this.handleOpenApproveDialog(rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                    rendered={rowData.approved === false && this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER')}
                />
            </span>
        );
    }

    handleOpenApproveDialog(rowData) {
        this.setState((prevState) => ({
            openApporveDialog: true,
            element: {
                ...prevState.element,
                id: rowData.id,
                approvedHour: NumberUtils.formatTimeIfPossibleToMaskComponent(rowData.approvedHour ? rowData.approvedHour : rowData.totalHour),
                totalHour: rowData.totalHour,
                date: rowData.reportedDay.date,
                activityName: rowData.activity.name
            },
        }));
    }

    closeDeleteDialog() {
        this.setState({openApporveDialog: false});
    }
    prepareColumns() {
        const {summary} = this.state;
        return [
            {field: 'activity.name', header: t('extraEvent.activityName'), sortable: false},
            {field: 'employeeCount', header: t('extraEvent.employeeCount'), sortable: true},
            {field: 'totalHour', header: t('extraEvent.totalHour'), sortable: false, body: this.totalHour},
            {field: 'approvedHour', header: t('extraEvent.approvedHour'), sortable: false, body: this.apporveHour},
            {field: 'description', header: t('extraEvent.description'), sortable: false, width: '30em'},
            {field: 'reportedDay.date', header: t('extraEvent.data'), sortable: true},
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    prepareHeaderItems() {
        return [

            {
                type: 'BUTTON',
                className: 'right-side',
                variant: 'white',
                disabled: !this.state.list || this.state.list.length < 1,
                customRenderFunction: this.renderPrintButton,
            },
        ];
    }


    generateReport(format) {
        const criteria = this.state.criteria;
        const listSize = this.state.size;
        //const documentLimit = this.state.documentLimit;
        // if (listSize > documentLimit) {
        //     //return this.showErrorAboutLimitExceeded(documentLimit, listSize);
        // }
        switch (format) {
            case 'xlsx':
                return this.service.exportList(criteria, 'xlsx');
            default:
                return Promise.reject('Nieznany format');
        }
    }


    totalHour(rowData) {
        if (rowData.totalHour) {
            return NumberUtils.formatTimeIfPossible(rowData.totalHour);
        } else {
            return '-';
        }
    }
    apporveHour(rowData) {
        if (rowData.approvedHour) {
            return NumberUtils.formatTimeIfPossible(rowData.approvedHour);
        } else {
            return '-';
        }
    }

    footerGroup() {
        const {summary} = this.state;
        return (
            <ColumnGroup>
                <Row>
                    <Column footer={t('list.summary')} />
                    <Column footer='' colSpan={1} footerStyle={{textAlign: 'right'}} />
                    <Column footer={NumberUtils.formatTimeIfPossible(summary.totalHourSum)} footerStyle={{textAlign: 'left'}} />
                    <Column footer={NumberUtils.formatTimeIfPossible(summary.approvedHourSum)} footerStyle={{textAlign: 'left'}} />
                    <Column footer='' colSpan={3} footerStyle={{textAlign: 'right'}} />
                </Row>
            </ColumnGroup>
        );
    }
    render() {
        return (
            <div className='container-fluid activity-list'>
                {this.renderView()}
                {this.state.openApporveDialog && (
                    <YesNoDialog
                        visible={this.state.openApporveDialog}
                        header={t('extraEvent.approve')}
                        name='visible'
                        yesButtonLabel={t('extraEvent.confirmButton')}
                        noButtonLabel={t('extraEvent.closeButton')}
                        noButtonVariant='white'                        
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.handleApproved();
                            } else {
                                this.closeDeleteDialog();
                            }
                        }}
                        onHide={() => {
                            this.closeDeleteDialog();
                        }}
                    >
                        <InputTextComponent 
                            id='approveActivityName'
                            label={t('extraEvent.activityName')}
                            name='activityName'
                            value={this.state.element.activityName}
                            viewMode={'VIEW'}
                        />
                        <InputTextComponent 
                            id='approveDate'
                            label={t('extraEvent.data')}
                            name='activityName'
                            value={this.state.element.date}
                            viewMode={'VIEW'}
                        />
                        <InputTextComponent 
                            id='approveTotalHour'
                            label={t('extraEvent.totalHour')}
                            name='activityName'
                            value={NumberUtils.formatTimeIfPossible(this.state.element.totalHour)}
                            viewMode={'VIEW'}
                        />

                        <InputMaskComponent
                            id='approvedHour'
                            name='approvedHour'
                            label={t('extraEvent.approvedByClientHours')}
                            value={this.state.element.approvedHour}
                            onChange={this.handleChange}
                            viewMode={'EDIT'}
                            validator={this.validator}
                            validators={`required|number_max:${this.state.element.totalHour}`}
                            colClass='col-12'
                            mask='99:99' 
                            placeholder='HH:mm'                               
                        />                        
                    </YesNoDialog>
                )}
            </div>
        );
    }
}

ProjectExtraEventListContainer.defaultProps = {
    detailUrl: '/#/activity/details',
    newUrl: '/#/activity/create',
    lazy: true,
    expandFilters: false,
    renderHeader: true,
};

ProjectExtraEventListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
    onProjectChange: PropTypes.func,
};

export default ProjectExtraEventListContainer;
