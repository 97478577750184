import SettingService from '../../services/SettingService';
import AccountService from '../../services/AccountService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import AbstractConfirmPasswordContainer from './AbstractConfirmPasswordContainer';
import {withTranslation} from 'react-i18next';

class ConfirmPasswordContainer extends AbstractConfirmPasswordContainer {
    constructor(props) {
        super(props);
        this.service = new AccountService();
    }

    // @Override
    getPasswordParams = () => {
        const settingService = new SettingService();
        return settingService
            .getPasswordParamsPublic()
            .then((resp) => {
                this.setState(
                    {
                        maxLength: resp['PASSWORD_MAX_LENGTH'],
                        minLength: resp['PASSWORD_MIN_LENGTH'],
                        lowerCaseCount: resp['PASSWORD_LOWERCASE_COUNT'],
                        upperCaseCount: resp['PASSWORD_UPPERCASE_COUNT'],
                        numbersCount: resp['PASSWORD_NUMBERS_COUNT'],
                        specialCharactersCount: resp['PASSWORD_SPECIAL_CHARS_COUNT'],
                    },
                    () => this.unblockUi()
                );
            })
            .catch((err) => this.unblockUi());
    };

    getService = () => {
        return this.service;
    };

    handleCorrectPasswordToken = (wsRes) => {
        if (wsRes.login) {
            this.setState({email: wsRes.login});
            this.unblockUi();
        } else {
            this.handleErrorPasswordToken();
        }
    };

    handleErrorPasswordToken = () => {
        const {t} = this.props;
        this.saveCookie(
            this.getResetPasswordContainerName(),
            JSON.stringify({
                severity: 'error',
                summary: '',
                detail: t('account.incorrectToken'),
            })
        );
        // window.location.href = "#/reset-password"
    };

    handleCorrectPasswordChange = () => {
        this.blockUi();
        this.persistMessage(
            'success',
            '',
            this.props.type === 'CREATE' ? 'Hasło zostało nadane pomyślnie' : 'Hasło zostało zmienione pomyślnie'
        );
        window.location.href = AppPrefixUtils.locationHrefUrl('/#/login');
    };

    renderDetail = () => {
        return this.renderForm();
    };

    getResetPasswordContainerName() {
        return 'reset-password';
    }

    getContainerListName() {
        return 'login-page';
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    persistMessage(severity, summary, detail) {
        this.saveCookie(
            this.getContainerListName(),
            JSON.stringify({
                severity,
                summary,
                detail,
            })
        );
    }
}
export default withTranslation()(ConfirmPasswordContainer);
