import BaseServiceCrud from './BaseServiceCrud';

export default class AccommodationCategoryService extends BaseServiceCrud {
      constructor() {
        super();
        this.path = 'accommodation/category';
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,            
            status: criteria.status?.enumValue,            
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
}
