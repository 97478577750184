import moment from 'moment';
import BaseUserService from './BaseUserService';
import Constants from '../utils/constants';

const path = 'employee';

export default class EmployeeService extends BaseUserService {
    // Initializing important variables
    constructor() {
        super(path);
        this.path = path;
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            login: criteria.login,
            first_name: criteria.firstName,
            subcontractor_name: criteria.subcontractorName,
            last_name: criteria.lastName,
            email: criteria.email,
            status: criteria.status?.enumValue,
            active: criteria.active,
            current_price_from: criteria.currentPriceFrom,
            current_price_to: criteria.currentPriceTo,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            categories: criteria.categories ? criteria.categories.map((category) => category.id).join(',') : null,
            expirationAlarmStatus: criteria.expirationAlarmStatus?.enumValue
        });
    }

    update(element) {
        return super.update(this.createObjectToSave(element));
    }
    add(element) {
        return super.add(this.createObjectToSave(element));
    }

    createObjectToSave(element) {
        const e = this.cloneElement(element);
        e.contracts.forEach((contract) => {
            if (contract.dateFrom) {
                contract.dateFrom = moment(contract.dateFrom).startOf('month').format(Constants.DATE_JSON_FORMAT);
            }
            if (contract.dateTo) {
                contract.dateTo = moment(contract.dateTo).startOf('month').format(Constants.DATE_JSON_FORMAT);
            }
        });
        e.absences.forEach((absence)=>{
            if (absence.dateFrom) {
                absence.dateFrom = moment(absence.dateFrom).format(Constants.DATE_JSON_FORMAT);
            }
            if (absence.dateTo) {
                absence.dateTo = moment(absence.dateTo).format(Constants.DATE_JSON_FORMAT);
            }
        });
        e.documents.forEach((doc)=>{
            if (doc.dateTo) {
                doc.dateTo = moment(doc.dateTo).format(Constants.DATE_JSON_FORMAT);
            }
        })
        return e;
    }

    handleExpiration(id){
        let url = `${this.domain}/${this.path}/document/expiration-status/handled/${id}`;
        return this.fetch(url, {
            method: 'PATCH',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    search(query, loginAllowed, status, categories, project, dateStart, dateEnd) {
        const paramArrays = [];
        if (!!query) {
            paramArrays.push(`query=${query}`);
        }
        if (!!loginAllowed) {
            paramArrays.push(`loginAllowed=${loginAllowed}`);
        }
        if (!!status) {
            paramArrays.push(`status=${status}`);
        }
        if (!!categories) {
            paramArrays.push(`categories=${categories.map((x) => x.id).join(',')}`);
        }
        if (!!project) {
            paramArrays.push(`project=${project.id}`);
        }
        if (!!dateStart) {
            paramArrays.push(`dateStart=${moment(dateStart).format(Constants.DATE_GET_REQUEST_FORMAT)}`);
        }
        if (!!dateEnd) {
            paramArrays.push(`dateEnd=${moment(dateEnd).format(Constants.DATE_GET_REQUEST_FORMAT)}`);
        }
        const parameters = paramArrays.length > 0 ? '?' + paramArrays.join('&') : '';
        return this.fetch(`${this.domain}/${this.path}/search${parameters}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    } 
    
    searchEmployeeContractOnDate(query,  date, projectId) { 
        let url = `${this.domain}/${this.path}/search/employee-contract-on-date`;
        url += `?date=${moment(date).startOf('month').format(Constants.DATE_JSON_FORMAT)}`;
        if (query) {
            url += `&query=${query}`;
        }
        if (projectId) {
            url += `&projectId=${projectId}`;
        }
        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

}
