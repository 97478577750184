import {Password} from 'primereact/password';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseInputComponent from './BaseInputComponent';

class InputPasswordComponent extends BaseInputComponent {
    renderView() {
        const {
            colClass,
            formGroup,
            iconColor,
            iconName,
            iconSide,
            iconSize,
            id,
            insideTable,
            label,
            objectId,
            publicMode,
            showLabel,
            titleElement,
            titleElementClass,
            titleElementShowId,
            value,
            validateViewMode,
            validator,
            validators,
        } = this.props;
        return publicMode ? (
            <div className='input_easy_label row pl-0'>
                {label !== undefined && showLabel ? (
                    <label id={`${id}-label-id`} className='easy_label col-lg-2 col-md-3' htmlFor={id}>
                        {label}
                    </label>
                ) : null}
                <div className='col-md-5'>
                    <span
                        aria-label={label}
                        aria-labelledby={`${id}-label-id`}
                        className={`p-inputtext-view icon_text ${titleElement ? ' title-element' : ''} ${
                            iconName !== undefined ? iconColor : ''
                        }`}
                    >
                        {iconSide === 'left' && iconName !== undefined ? <i className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                        {'********'}
                        {iconSide === 'right' && iconName !== undefined ? <i className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                    </span>
                    {titleElement && titleElementShowId ? <span className='p-inputtext-view'>{` ID: ${objectId}`}</span> : null}
                    {validateViewMode && validator ? validator.message(id, label, value, validators) : null}
                </div>
            </div>
        ) : (
            <div className={insideTable ? '' : titleElement ? titleElementClass : colClass}>
                <div className={insideTable ? '' : 'row'}>
                    <div className={insideTable ? '' : `col-md-12 ${formGroup ? 'form-group' : ''}`}>
                        {label !== undefined && showLabel && !titleElement ? (
                            <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                                {label}
                            </label>
                        ) : null}
                        <span
                            aria-label={label}
                            aria-labelledby={`${id}-label-id`}
                            className={`p-inputtext-view icon_text ${titleElement ? ' title-element' : ''} ${
                                iconName !== undefined ? iconColor : ''
                            }`}
                        >
                            {iconSide === 'left' && iconName !== undefined ? <i className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                            {'********'}
                            {iconSide === 'right' && iconName !== undefined ? <i className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                        </span>
                        {titleElement && titleElementShowId ? <span className='p-inputtext-view'>{` ID: ${objectId}`}</span> : null}
                        {validateViewMode && validator ? validator.message(id, label, value, validators) : null}
                    </div>
                </div>
            </div>
        );
    }

    renderEdit() {
        return this.renderNew();
    }

    renderNew() {
        const {
            colClass,
            disabled,
            feedback,
            id,
            insideTable,
            label,
            labelForValidator,
            mediumLabel,
            name,
            onAfterStateChange,
            onChange,
            placeholder,
            publicMode,
            promptLabel,
            showLabel,
            stateField,
            strongLabel,
            validator,
            validators,
            value,
            weakLabel,
            t,
        } = this.props;
        const required = validators !== undefined && validators.includes('required') && !validators.includes('not_required');
        return publicMode ? (
            <div className='input_easy_label row pl-0'>
                {label !== undefined && showLabel ? (
                    <label id={`${id}-label-id`} className='easy_label col-lg-2 col-md-3' htmlFor={id}>
                        {label}
                    </label>
                ) : null}
                <div className='col-md-5'>
                    <Password
                        aria-describedby={`${id}-error`}
                        aria-label={label}
                        aria-labelledby={label === undefined && showLabel ? `${id}-label-id` : undefined}
                        key={id}
                        id={id}
                        name={name}
                        placeholder={placeholder}
                        style={{width: '100%'}}
                        value={value}
                        onChange={(e) => (onChange ? onChange('TEXT', undefined, e, onAfterStateChange, stateField) : null)}
                        disabled={disabled}
                        promptLabel={promptLabel !== promptLabel.defaultProps ? t(promptLabel) : promptLabel}
                        weakLabel={weakLabel !== weakLabel.defaultProps ? t(weakLabel) : weakLabel}
                        mediumLabel={mediumLabel !== mediumLabel.defaultProps ? t(mediumLabel) : mediumLabel}
                        strongLabel={strongLabel !== strongLabel.defaultProps ? t(strongLabel) : strongLabel}
                        feedback={feedback}
                        required={required}
                    />
                    {validator ? validator.message(id, labelForValidator ? labelForValidator : label, value, validators) : null}
                </div>
            </div>
        ) : (
            <div className={insideTable ? '' : colClass}>
                <div className={insideTable ? '' : 'row'}>
                    <div className={insideTable ? '' : 'col-md-12 form-group'}>
                        {label !== undefined && showLabel ? (
                            <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                                {label}
                            </label>
                        ) : null}
                        <Password
                            aria-describedby={`${id}-error`}
                            aria-label={label}
                            aria-labelledby={label === undefined && showLabel ? `${id}-label-id` : undefined}
                            key={id}
                            id={id}
                            name={name}
                            placeholder={placeholder}
                            style={{width: '100%'}}
                            value={value}
                            onChange={(e) => (onChange ? onChange('TEXT', undefined, e, onAfterStateChange, stateField) : null)}
                            disabled={disabled}
                            promptLabel={promptLabel !== promptLabel.defaultProps ? t(promptLabel) : promptLabel}
                            weakLabel={weakLabel !== weakLabel.defaultProps ? t(weakLabel) : weakLabel}
                            mediumLabel={mediumLabel !== mediumLabel.defaultProps ? t(mediumLabel) : mediumLabel}
                            strongLabel={strongLabel !== strongLabel.defaultProps ? t(strongLabel) : strongLabel}
                            feedback={feedback}
                            required={required}
                        />
                        {validator ? validator.message(id, labelForValidator ? labelForValidator : label, value, validators) : null}
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {rendered, viewMode} = this.props;
        if (rendered) {
            switch (viewMode) {
                case 'NEW':
                    return this.renderNew();
                case 'EDIT':
                    return this.renderEdit();
                case 'VIEW':
                default:
                    return this.renderView();
            }
        } else {
            return null;
        }
    }
}

InputPasswordComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    disabled: false,
    feedback: true,
    insideTable: false,
    mediumLabel: 'password.medium',
    placeholder: '',
    promptLabel: 'password.enterPassword',
    publicMode: false,
    rendered: true,
    showLabel: true,
    showPasswordVisible: false,
    stateField: 'element',
    strongLabel: 'password.strong',
    validators: 'required',
    viewMode: 'VIEW',
    weakLabel: 'password.weak',
};

InputPasswordComponent.propTypes = {
    colClass: PropTypes.string,
    disabled: PropTypes.bool,
    feedback: PropTypes.bool,
    id: PropTypes.string.isRequired,
    insideTable: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelForValidator: PropTypes.string,
    mediumLabel: PropTypes.string,
    name: PropTypes.string.isRequired,
    onAfterStateChange: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    promptLabel: PropTypes.string,
    publicMode: PropTypes.bool,
    rendered: PropTypes.bool,
    showLabel: PropTypes.bool,
    showPasswordVisible: PropTypes.bool,
    stateField: PropTypes.string,
    strongLabel: PropTypes.string,
    validator: PropTypes.object,
    validators: PropTypes.string,
    value: PropTypes.string,
    viewMode: PropTypes.string,
    weakLabel: PropTypes.string,
};

export default withTranslation()(InputPasswordComponent);
