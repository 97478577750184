import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import ProjectService from '../services/ProjectService';
import {t} from 'i18next';
import moment from 'moment';
import ActionLink from '../components/ActionLink';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import ClientService from '../services/ClientService';
import NumberUtils from '../utils/NumberUtils';
import { Chip } from 'primereact/chip';
import ProjectCategoryService from '../services/ProjectCategoryService';
import CriteriaMultiSelectComponent from '../components/criteria/CriteriaMultiSelectComponent';
import LogEntryService from '../services/LogEntryService';
import Constants from '../utils/constants';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import InputTextComponent from '../components/inputs/InputTextComponent';
import IconColorComponent from '../components/inputs/IconColorComponent';
import SquareColorComponent from '../components/inputs/SquareColorComponent';

class LogEntryListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new LogEntryService());
        const criteria = this.getCleanSearchCriteria();        
        this.state = {
            logEntryCodes: [],
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: criteria,
        };
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.handleOnRowToggle = this.handleOnRowToggle.bind(this);
    }


    getCriteriaName() {
        return 'logentry-list-sc';
    }

    getContainerListName() {
        return 'logentry-list-container';
    }

    getListTitle() {
        return t('logEntry.list.title');
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('LogEntryCode')
            .then((logEntryCodes) =>
                this.setState({
                    logEntryCodes,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów zdarzeń');
            });
    }

    updateSearchCriteria(criteria) {
        return {
            code: criteria.code,
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            objectId: criteria.objectId,
            objectType: criteria.objectType,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {           
        return {
            code: null,         
            dateFrom: null,
            dateTo: null,
            objectId: this.props.objectId ? this.props.objectId : null,
            objectType: this.props.objectType ? {enumValue: this.props.objectType} : null,
            query: '',            
            firstResult: 0,
            maxResults: 10,
            sortField: 'createDate',
            sortAsc: false,
        };
    }


    renderCriteria() {
        let codesOptions=this.state.logEntryCodes;
        if(this.props.filterOptions){
            codesOptions=this.props.filteredOptionsList;
        }
        return (
            <div>
            <div className='row'>
                <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    label={t('logEntry.search.date')}
                    labelFrom={'od'}
                    labelTo={'do'}
                    name='createDate'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    colClass='col-xl-8 col-lg-8 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}                    
                    showButtonBar
                    sharedLabel
                    maxDateInfinite
                    placeholderFrom={''}
                    placeholderTo={''}
                    inputsClassName='col-6'
                    refreshFromBackend
                    showTime
                />
            </div>
            <div className='row'>
                <CriteriaDropdownComponent
                    id='code-sc'
                    name='code'
                    showClear
                    label={t('logEntry.search.code')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.code}
                    options={codesOptions}
                    placeholder={t('list.all')}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                    filter
                    filterBy='label'
                />
                <CriteriaTextComponent
                    id='query-sc'
                    name='query'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.query}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('logEntry.search.query')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
            </div>
            </div>
        );
    }
    
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <ActionLink
            iconName='mdi-eye'
            iconSide='left'
            iconSize='icon-large'
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
        );
    }

    prepareColumns() {
        const result = [
            {field: 'createDate', header: t('logEntry.list.date'), sortable: true, body: this.dateTemplate.bind(this, 'createDate', Constants.DATE_TIME_FORMAT)},
            {field: 'code', header: t('logEntry.list.code'), sortable: true, body: this.enumTemplate.bind(this, 'code')},
            {field: 'user.fullName', header: t('logEntry.list.createdBy'), sortable: false}
        ];
        if (this.props.objectId === undefined) {
            result.push({field: 'affectedObjectLabel', header: t('logEntry.list.affectedObjectLabel'), body: this.affectedObjectLabelTemplate.bind(this), sortable: true});
        }
        return result;
    }
    dateTemplate(field, format, rowData) {
        if (rowData[field] && moment(rowData[field]).isValid()) {
            const inputDateString=rowData[field];
            const [datePart, timePart] = inputDateString.split('T');
            const [time, milliseconds] = timePart.split('.');
            const [year, month, day] = datePart.split('-');
            const formattedDateTime = `${year}-${month}-${day} ${time}`;
            return formattedDateTime;
        } else {
            return '';
        }
    }

    affectedObjectLabelTemplate(rowData) {
        let result = rowData.affectedObjectLabel;
        if (rowData.affectedObjectParentLabel) {
            result += ` (${rowData.affectedObjectParentLabel})`;
        }

        return result;
    }


    render() {
        return (
            <div className='container-fluid'>
                {this.renderView()}                
            </div>
        );
    }

    logUserTemplate(rowData) {
        if (rowData.createdBySystem) {
            return 'SYSTEM'
        } else {
            return rowData.user?.fullName;
        }
    }

    newValueTemplate(rowData) {
       
        if (rowData.newValue) {
            if(rowData.label.toUpperCase()==='IKONA'){
                return this.iconTemplate(rowData.newValue);
            }  
            if(rowData.label.toUpperCase()==='KOLOR'){
                return this.colorTemplate(rowData.newValue);
            }
            return rowData.newValue;
        } else if (rowData.changes && Array.isArray(rowData.changes) && rowData.changes.length > 0) {
            return this.rowExpansionTemplate(rowData, false);
        } else {
            return '-';
        }
    }

    iconTemplate(data){
        const icon = data.split(',')[0];
        const color = data.split(',')[1];
        return <div style={{marginTop:"1px", marginBottom:"-6px"}}><IconColorComponent   icon={icon} color={color} /></div>
    }
    colorTemplate(color){
        return <SquareColorComponent value={color} />
    }


    oldValueTemplate(rowData){
        if(rowData.oldValue){
            if(rowData.label.toUpperCase()==='IKONA'){
                return this.iconTemplate(rowData.oldValue);
            }  
            if(rowData.label.toUpperCase()==='KOLOR'){
                return this.colorTemplate(rowData.oldValue);
            }  
            return rowData.oldValue
        }
    }

    handleOnRowToggle(e) {
        this.setState({ expandedRows: e.data });
    }
    

    rowExpansionTemplate(data, showHeader = true) {
        const showMessage = !!data.message;
        const showChanges = data.changes && Array.isArray(data.changes) && data.changes.length > 0;
        return (
            <div>
                <InputTextComponent id={`message_${data.id}`} colClass='col-12' viewMode={'VIEW'} label={t('logEntry.list.changesHeader')} value={data.message} rendered={showMessage}/>
                <div className="orders-subtable">
                    {showHeader && showChanges ? <label className="p-label">{t('logEntry.list.changesHeader')}</label> : null}
                    {showChanges ? 
                    <DataTable value={data.changes} responsiveLayout="scroll">
                        <Column field="type" header={t('logEntry.list.changesOperation')} body={this.enumTemplate.bind(this, 'type')}></Column>
                        <Column field="label" header="Pole"></Column>
                        <Column field="oldValue" header={t('logEntry.list.changesOldValue')} body={this.oldValueTemplate.bind(this)}></Column>
                        <Column field="newValue" header={t('logEntry.list.changesNewValue')} body={this.newValueTemplate.bind(this)}></Column>                    
                        <Column headerStyle={{ width: '4rem'}} ></Column>
                    </DataTable> : null}
                </div>
            </div>
        );
    }
}

LogEntryListContainer.defaultProps = {
    detailUrl: '/#/project/details',
    newUrl: '/#/project/create',
    lazy: true,
    rowExpansionTemplate: true,
};

LogEntryListContainer.propTypes = {        
    objectId: PropTypes.string,
    objectType: PropTypes.string,
    expandFilters: PropTypes.bool,
    renderHeader: PropTypes.bool,
};

export default LogEntryListContainer;
