import BaseServiceCrud from './BaseServiceCrud';


export default class CarService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'car';
      //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            licensePlate: criteria.licensePlate,            
            brand: criteria.brand,            
            model: criteria.model,            
            status: criteria.status?.enumValue,            
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
    getSearchCriteria(query,status,ids) {
        return this.objToQueryString({
            query: query,
            status: status,
            ids: ids
        });
    }

    searchCars(query,status,ids) {
        const queryString = this.getSearchCriteria(query, status, ids);
        let url = `${this.domain}/${this.path}/search${queryString}`;
        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
