import moment from 'moment/moment';
import NumberUtils from '../utils/NumberUtils';
import BaseServiceCrud from './BaseServiceCrud';
import Constants from '../utils/constants';


export default class ExtraEventService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'extra-event';
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            projectId: criteria.projectId,
            dateFrom: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateTo: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            approvalState: criteria.approvalState?.enumValue,
            approvalRdState: criteria.approvalRdState,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
    exportList(criteria, format){
        const params=this.objToQueryString({
            projectId: criteria.projectId,
            dateFrom: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateTo: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            approvalState: criteria.approvalState?.enumValue,
            approvalRdState: criteria.approvalRdState,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            exportType: format
        });
        
		return this.fetch(`${this.domain}/${this.path}/export${params}`,
        {
            method: 'POST'
        },
        {
            'Content-Type': 'application/json',
            Accept: 'application/octet-stream'
        }
		).then((res) => {
			return Promise.resolve(res);
		}).catch((err) => {
			throw err;
		});




    }
    approve(element) {
        const e = this.cloneElement(element);
        e.approvedHour = NumberUtils.formatTimeFromMaskToMinutes(e.approvedHour);
        return this.fetch(`${this.domain}/${this.path}/approve`, {
            method: 'PATCH',
            body: JSON.stringify(e),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
