/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-handler-names */
import {t} from 'i18next';
import {Accordion, AccordionTab} from 'primereact/accordion';
import {Card} from 'primereact/card';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {Dropdown} from 'primereact/dropdown';
import {Ripple} from 'primereact/ripple';
import {classNames} from 'primereact/utils';
import PropTypes from 'prop-types';
import React from 'react';
import ActionButton from '../components/ActionButton';
import ActionLink from '../components/ActionLink';
import {CustomMessages} from '../components/CustomMessages';
import DivContainer from '../components/DivContainer';
import PdfDownloadSpinner from '../components/spinner/PdfDownloadSpinner';
import BlockUi from '../components/waitPanel/BlockUi';
import {PrintButtonComponent} from '../report/ReportUtils';
import BaseContainer from './BaseContainer';
import {ColumnGroup} from 'primereact/columngroup';

class BaseListContainer extends BaseContainer {
    constructor(props, service) {
        super(props);
        this.service = service;
        this.actionTemplate = this.actionTemplate.bind(this);
        this.booleanTemplate = this.booleanTemplate.bind(this);
        this.handleGoToDetail = this.handleGoToDetail.bind(this);
        this.handleGoToNew = this.handleGoToNew.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handlePage = this.handlePage.bind(this);
        this.readCriteriaFromCookie = this.readCriteriaFromCookie.bind(this);
        this.readMessage = this.readMessage.bind(this);
        this.refreshTable = this.refreshTable.bind(this);
        this.renderDataTable = this.renderDataTable.bind(this);
        this.renderCriteria = this.renderCriteria.bind(this);
        this.renderHeader = this.renderHeader.bind(this);
        this.prepareColumns = this.prepareColumns.bind(this);
        this.getList = this.getList.bind(this);
        this.renderView = this.renderView.bind(this);
        this.getCleanSearchCriteria = this.getCleanSearchCriteria.bind(this);
        this.getCleanSearchCriteriaPrototype = this.getCleanSearchCriteriaPrototype.bind(this);
        this.updateSearchCriteria = this.updateSearchCriteria.bind(this);
        this.updateSearchCriteriaPrototype = this.updateSearchCriteriaPrototype.bind(this);
        this.onAfterGetList = this.onAfterGetList.bind(this);
        this.handleChangeLimitObj = this.handleChangeLimitObj.bind(this);
        this.cleanSearchCriteria = this.cleanSearchCriteria.bind(this);
        this.handleOnSelectionChange = this.handleOnSelectionChange.bind(this);
        this.handleOnSelectAllChange = this.handleOnSelectAllChange.bind(this);
        this.getPageLinkSize = this.getPageLinkSize.bind(this);
        this.updateListElements = this.updateListElements.bind(this);
        this.defaultLoading = true;
        this.dataKey = this.getDataKey();
        if (service !== undefined && service !== null) {
            this.service.setUiMethods(this.blockUi, this.unblockUi, this.props.updateApp);
        }
        this.labelHeader = this.labelHeader.bind(this);
        this.renderPrintButton = this.renderPrintButton.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        this.initializeFromBackend();
        if (this.defaultLoading) {
            this.readCriteriaFromCookie();
        }
        this.readMessage();
    }

    readCriteriaFromCookie() {
        const cookie = this.readCookie(this.getCriteriaName());
        const criteria =
            cookie !== undefined && cookie != null && !this.props.hideFilters
                ? this.updateSearchCriteriaPrototype(JSON.parse(cookie))
                : this.getCleanSearchCriteriaPrototype();
        this.removeCookie(this.getCriteriaName());
        if (this._isMounted) {
            criteria.maxResults = criteria.maxResults ? criteria.maxResults : 10;
            this.setState(
                {
                    loading: this.props.lazy,
                    criteria,
                    first: criteria.firstResult,
                },
                () => (!this.props.list ? this.refreshTable(false) : null)
            );
        }
    }

    refreshTable(fromFirstPage = true) {
    
        if (fromFirstPage) {
            const { criteria } = this.state;
            this.setState(
                (prevState) => ({
                    first: 0,
                    criteria: {
                        ...prevState.criteria,
                        firstResult: 0,
                    },
                }),
                () => this.getList()
            );
        } else {
            this.getList();
        }
    }

    onAfterGetList(res) {
        const selectAllMode = this.state.selectAllMode;
        const unselectedList = this.state.unselectedList;

        if (selectAllMode) {
            this.setState({
                selectedList: this.state.list.filter((item) => !unselectedList.map((unselected) => unselected.id).includes(item.id)),
            });
        }
    }

    updateListElements(list) {
        return list;
    }

    renderPrintButton() {
        const {t} = this.props;
        return (
            <PrintButtonComponent
                key={`print-button${this.getListTitle()}`}
                reportFileNameBase={t(this.getListTitle())}
                generateReport={this.generateReport.bind(this)}
                disabled={!this.state.selectedList.length > 0}
            />
        );
    }

    generateReport() {}
    getList() {
        const {criteria} = this.state;
        const activeElement = document.activeElement;
        this.setState(
            {
                loading: true,
            },
            () => {
                this.service
                    .getList(criteria)
                    .then((data) => {
                        if (this._isMounted) {
                            this.setState(
                                {
                                    list: this.updateListElements(data.content),
                                    loading: false,
                                    summary: data?.summary,
                                    size: data.totalElements,
                                },
                                () => {
                                    this.onAfterGetList(data);
                                    this.setTimeout(() => activeElement?.focus(), 50);
                                }
                            );
                        }
                    })
                    .catch((err) => {
                        if (this._isMounted) {
                            this.setState(
                                {
                                    list: [],
                                    loading: false,
                                    size: 0,
                                },
                                () => {
                                    this.afterGetListError();
                                }
                            );
                        }
                    });
            }
        );
    }

    afterGetListError() {
        this.showErrorMessage('Wystąpił błąd systemu. Nie udało się pobrać listy.', 10000);
    }

    getCleanSearchCriteria() {
        return {
            firstResult: 0,
            maxResults: 10,
            maxResultsObj: {value: 10},
            sortField: 'id',
            sortAsc: true,
        };
    }

    getCleanSearchCriteriaPrototype() {
        const criteria = this.getCleanSearchCriteria();
        criteria.maxResultsObj = {value: criteria.maxResults};
        return criteria;
    }

    cleanSearchCriteria() {
        this.setState({criteria: this.getCleanSearchCriteriaPrototype()}, () => this.handleFormSubmit());
    }

    updateSearchCriteria(criteria) {
        return {
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            maxResultsObj: {value: criteria.maxResults},
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    updateSearchCriteriaPrototype(criteria) {
        criteria = this.updateSearchCriteria(criteria);
        criteria.maxResultsObj = {value: criteria.maxResults};
        return criteria;
    }

    getCriteriaName() {
        return 'list-sc';
    }

    initializeFromBackend() {}

    handleSort(event) {
        if (this._isMounted) {
            this.setState(
                (prevState) => ({
                    loading: this.props.lazy,
                    criteria: {
                        ...prevState.criteria,
                        sortField: event.sortField,
                        sortAsc: event.sortOrder > 0,
                    },
                }),
                () => (this.props.lazy ? this.refreshTable(false) : null)
            );
        }
    }

    handlePage(event) {
        if (this._isMounted) {
            this.setState(
                (prevState) => ({
                    loading: this.props.lazy,
                    first: event.first,
                    criteria: {
                        ...prevState.criteria,
                        firstResult: event.first,
                        maxResults: event.rows,
                        maxResultsObj: {value: event.rows},
                    },
                }),
                () => (this.props.lazy ? this.refreshTable(false) : null)
            );
        }
    }

    handleValidForm() {
        this.setState(
            (prevState) => ({
                blocking: false,
                loading: true,
                first: 0,
                criteria: {
                    ...prevState.criteria,
                    firstResult: 0,
                },
            }),
            () => (this.props.lazy ? this.refreshTable() : null)
        );
    }

    getContainerListName() {
        return 'list-container';
    }
    // to overide
    footerGroup() {
        return <ColumnGroup></ColumnGroup>;
    }
    generateCustomFooterIfPossible() {
        if (this.state.summary) {
            return this.footerGroup();
        }
        return <ColumnGroup></ColumnGroup>;
    }
    readMessage() {
        const messageFromStorage = this.readCookie(this.getContainerListName());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getContainerListName());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }

    handleGoToDetail(href, e) {
        e.preventDefault();
        this.blockUi();
        const {criteria} = this.state;
        if (!this.props.hideFilters) {
            this.saveCookie(this.getCriteriaName(), JSON.stringify(criteria));
        }
        window.location.href = href;
    }

    handleGoToNew(e) {
        e.preventDefault();
        this.blockUi();
        const {newUrl} = this.props;
        const {criteria} = this.state;
        if (!this.props.hideFilters) {
            this.saveCookie(this.getCriteriaName(), JSON.stringify(criteria));
        }
        window.location.href = newUrl;
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <ActionLink
                label={'Szczegóły'}
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='green'
                className='p-link hover-underline'
                key={'view-button'}
                iconSize='xs'
                iconName='mdi-arrow-right'
                iconColor='green'
            />
        );
    }

    booleanTemplate(field, rowData) {
        if (rowData && rowData[field] !== null && rowData[field] !== undefined && rowData[field] === true) {
            return 'TAK';
        } else return 'NIE';
    }

    addButton() {
        const {newUrl} = this.props;
        return <ActionButton label={'Dodaj'} handleClick={this.handleGoToDetail.bind(this, newUrl)} key={'add-button'} />;
    }

    handleChangeLimitObj(v) {
        this.setState(
            (prevState) => ({
                first: 0,
                criteria: {
                    ...prevState.criteria,
                    firstResult: 0,
                    maxResults: v.value,
                    maxResultsObj: {value: v.value},
                },
            }),
            () => (this.props.lazy ? this.refreshTable(false) : null)
        );
    }

    handleOnSelectionChange(e) {
        const selectedList = e.value;
        const currentUnselectedList = this.state.list.filter((item) => !selectedList.includes(item));
        let unselectedList = this.state.unselectedList || [];

        currentUnselectedList.forEach((item) => {
            if (!unselectedList.map((selectedItem) => selectedItem.id).includes(item.id)) {
                unselectedList.push(item);
            }
        });

        unselectedList = unselectedList.filter((item) => !selectedList.map((selectedItem) => selectedItem.id).includes(item.id));

        this.setState({
            selectAll: e.value.length === this.getTotalElements(),
            selectedList,
            unselectedList,
        });
    }

    handleOnSelectAllChange(e) {
        const selectAll = e.checked;
        if (selectAll) {
            if (this.props.lazy) {
                this.setState({
                    selectAll,
                    selectAllMode: true,
                    selectedList: this.state.list,
                    unselectedList: [],
                });
            } else {
                this.setState({
                    selectAll: true,
                    selectAllMode: true,
                    selectedList: this.state.list,
                    unselectedList: [],
                });
            }
        } else {
            this.setState({
                selectAll: false,
                selectAllMode: false,
                selectedList: [],
                unselectedList: this.state.list,
                loading: false,
            });
        }
    }

    getContentFromResponse(response) {
        return response.content;
    }

    getTotalElements() {
        return this.state.size;
    }

    getListTitle() {
        return 'list.title';
    }

    getPageLinkSize() {
        let totalPages = this.state.size / this.state.criteria.maxResults;
        let currentPage = this.state.first / this.state.criteria.maxResults;
        if (totalPages <= 5) {
            return 5;
        } else if (totalPages === 6 || currentPage < 3 || currentPage >= totalPages - 3) {
            return 6;
        } else {
            return 7;
        }
    }

    getDataKey() {
        return 'id';
    }

    prepareHeaderItems() {
        return [{type: 'LABEL', className: 'float-right'}];
    }

    labelHeader(item, index) {
        return (
            <label id={'list-title'} className='list-title header-item' key={`list-title-${item}`}>
                {this.translate(this.getListTitle())}
            </label>
        );
    }

    globalFilterFields() {}

    renderDataTable(columns, list, size) {
        const {criteria, first, loading} = this.state;
        if (list === null || list === undefined) {
            list = this.props.list ? this.props.list : this.state.list;
        }
        if (size === null || size === undefined) {
            size = this.props.list ? this.props.size : this.state.size;
        }
        const dynamicColumns = columns.map((col) => {
            return (
                <Column
                    selectionMode={col.selectionMode}
                    key={col.key ? col.key : col.field}
                    field={col.field}
                    filter
                    filterField={col.field}
                    showFilterMenu={false}
                    header={col.header}
                    body={col.body}
                    bodyClassName={col.bodyClassName}
                    headerClassName={col.headerClassName}
                    className={col.className}
                    sortable={col.sortable}
                    sortField={col.sortField !== undefined ? col.sortField : col.field}
                    style={col.width !== undefined ? {width: col.width} : null}
                />
            );
        });
        const rowsPerPageOptions = [5, 10, 20, 50, 100];
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <div className='datatable-responsive'>
                        <DataTable
                            id='dataTable'
                            key='data-table'
                            emptyMessage={t('list.empty')}
                            className='p-datatable-responsive'
                            breakpoint='10px'
                            value={list}
                            paginator
                            footerColumnGroup={this.generateCustomFooterIfPossible()}
                            globalFilterFields={this.globalFilterFields()}
                            filters={this.state.filters}
                            // filterDisplay='row'
                            rows={criteria.maxResults}
                            totalRecords={size}
                            first={first}
                            rowsPerPageOptions={rowsPerPageOptions}
                            sortField={criteria.sortField}
                            sortOrder={criteria.sortAsc ? 1 : -1}
                            loading={loading}
                            paginatorPosition='bottom'
                            currentPageReportTemplate={
                                size !== 0
                                    ? `Pozycje od ${first + 1} do ${
                                          first + criteria.maxResults > size ? size : first + criteria.maxResults
                                      } z ${size} łącznie`
                                    : '0 pozycji'
                            }
                            paginatorTemplate={{
                                layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
                                PrevPageLink: (options) => {
                                    if (criteria.firstResult === 0) {
                                        return null;
                                    } else {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={options.onClick}
                                                disabled={options.disabled}
                                            >
                                                <span className='p-paginator-icon pi pi-angle-left'></span>
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                                NextPageLink: (options) => {
                                    if (criteria.firstResult + criteria.maxResults >= size) {
                                        return null;
                                    } else {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={options.onClick}
                                                disabled={options.disabled}
                                            >
                                                <span className='p-paginator-icon pi pi-angle-right'></span>
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                                RowsPerPageDropdown: (options) => {
                                    return this.renderPagination(rowsPerPageOptions, options);
                                },
                                CurrentPageReport: (options) => {
                                    return <div className='p-paginator-dummy' />;
                                },
                                PageLinks: (options) => {
                                    if (options.view.startPage === options.page && options.view.startPage !== 0) {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={() => {
                                                    this.handlePage({first: 0, rows: this.state.criteria.maxResults});
                                                }}
                                            >
                                                {1}
                                                <Ripple />
                                            </button>
                                        );
                                    } else if (options.view.endPage === options.page && options.view.endPage !== options.totalPages - 1) {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={() => {
                                                    this.handlePage({
                                                        first: (options.totalPages - 1) * this.state.criteria.maxResults,
                                                        rows: this.state.criteria.maxResults,
                                                    });
                                                }}
                                            >
                                                {options.totalPages}
                                                <Ripple />
                                            </button>
                                        );
                                    } else if (
                                        (options.view.startPage + 1 === options.page && options.view.startPage !== 0) ||
                                        (options.view.endPage - 1 === options.page &&
                                            options.view.endPage !== options.totalPages - 1 &&
                                            options.page !== options.totalPages - 1)
                                    ) {
                                        const className = classNames(options.className, {'p-disabled': true});
                                        return (
                                            <span className={className} style={{userSelect: 'none'}}>
                                                ...
                                            </span>
                                        );
                                    } else {
                                        return (
                                            <button type='button' className={options.className} onClick={options.onClick}>
                                                {options.page + 1}
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                            }}
                            pageLinkSize={this.getPageLinkSize()}
                            lazy={this.props.lazy}
                            onPage={this.handlePage}
                            onSort={this.handleSort}
                            selectionMode={'checkbox'}
                            onSelectionChange={this.handleOnSelectionChange}
                            selection={this.state.selectedList}
                            selectAll={this.state.selectAll}
                            onSelectAllChange={this.handleOnSelectAllChange}
                            dataKey={this.dataKey}
                            expandedRows={this.state.expandedRows}
                            onRowToggle={this.handleOnRowToggle}
                            onRowExpand={this.handleOnRowExpand}
                            onRowCollapse={this.handleOnRowCollapse}
                            rowExpansionTemplate={this.rowExpansionTemplate}
                            rowClassName={this.props.rowClassName}
                            selectionPageOnly={false}
                        >
                            {this.props.rowExpansionTemplate ? <Column expander headerStyle={{width: '3em'}}></Column> : null}
                            {this.props.disableSelection === true || !this.props.selectionMode ? null : (
                                <Column selectionMode={this.props.selectionMode} headerStyle={{width: '3em'}}></Column>
                            )}
                            {dynamicColumns}
                        </DataTable>
                    </div>
                </DivContainer>
            </React.Fragment>
        );
    }

    renderPagination(rowsPerPageOptions, options) {
        const start = options.currentPage * options.value + 1;
        let end = start + options.value -1;
        if (end > options.totalRecords) {
            end = options.totalRecords;
        }
        return (
            <div className='p-paginator-rows-per-page'>
                <Dropdown
                    aria-label={'Pokaż na stronie'}
                    key={'maxResults'}
                    id={'maxResults'}
                    inputId={'maxResults-input'}
                    name={'maxResults'}
                    emptyMessage={this.translate('list.emptyDropdown')}
                    //style={{ width: '100%' }}
                    value={this.state.criteria.maxResults}
                    options={rowsPerPageOptions}
                    onChange={(e) =>
                        this.setState(
                            (prevState) => ({
                                criteria: {
                                    ...prevState.criteria,
                                    maxResults: e.target.value,
                                },
                            }),
                            () => this.handleChangeLimitObj(e)
                        )
                    }
                    filter={false}
                    showClear={false}
                />
                <label id={'limit-label-id'} className='p-paginator-rows-per-page-label' htmlFor={'limit-input'}>
                    {t('list.paginator')}
                </label>
                <span className='p-paginator-rows-per-current'>
                    {start} - {end} z {options.totalRecords}
                </span>
            </div>
        );
    }

    prepareColumns() {
        return [];
    }

    renderCriteria() {
        return <DivContainer colClass='row' />;
    }

    onKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleFormSubmit();
        }
    }

    renderFilter() {
        const expandFilters = this.props.expandFilters === null || this.props.expandFilters === undefined || this.props.expandFilters === true;
        return !this.props.hideFilters ? (
            <Accordion className='filter-accordion' activeIndex={expandFilters ? 0 : -1}>
                <AccordionTab
                    header={
                        <React.Fragment>
                            <img className='card-icon' alt={t('list.filter')} src={`/images/ico_filter.svg`} />
                            <span>{t('list.filter')}</span>
                        </React.Fragment>
                    }
                >
                    <form
                        className='form-search-criteria'
                        onSubmit={(e) => {
                            this.handleFormSubmit(e);
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        //avoid setting red border just after the page has loaded (red border because of undefined required value in FF)
                        noValidate
                    >
                        <DivContainer colClass='col-12'>
                            {this.renderCriteria()}
                            {/* <DivContainer colClass='row'>
                                                <DivContainer colClass='col-md-12'>
                                                    <ActionButton
                                                        className='float-right'
                                                        label='Filtruj'
                                                        handleClick={this.handleFormSubmit}
                                                    />
                                                    <ActionButton
                                                        className='float-right mr-2'
                                                        label='Wyczyść'
                                                        handleClick={this.cleanSearchCriteria}
                                                    />
                                                </DivContainer>
                                            </DivContainer> */}
                        </DivContainer>
                    </form>
                </AccordionTab>
            </Accordion>
        ) : null;
    }

    renderView() {
        const renderHeader = this.props.renderHeader === undefined || this.props.renderHeader === true;
        return (
            <DivContainer colClass=''>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <BlockUi
                    tag='div'
                    blocking={this.state.blocking || this.state.loading}
                    loader={this.loader}
                    rendered={!this.props.embedded}
                >
                    <div style={{minHeight:"900px"}}>
                    <Card
                        className='list-card'
                        // header={this.renderHeader()}
                    >
                        <DivContainer colClass='row' rendered={this.props.hideFilters !== true}>
                            {this.renderFilter()}
                        </DivContainer>
                        <DivContainer colClass='row' rendered={renderHeader}>{this.renderHeader()}</DivContainer>
                        {this.renderDataTable(this.prepareColumns())}
                    </Card>
                    {this.state.downloadingPdf && <PdfDownloadSpinner />}
                    </div>
                    
                </BlockUi>
            </DivContainer>
        );
    }
}

BaseListContainer.defaultProps = {
    roles: '',
    expandFilters: true,
    selectionMode: 'multiple',
    hideFilters: false,
    renderHeader: true,
    lazy: true,
};

BaseListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
    roles: PropTypes.string,
    hideFilters: PropTypes.bool,
    renderHeader: PropTypes.bool,
    expandFilters: PropTypes.bool,
};

export default BaseListContainer;
