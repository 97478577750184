import { saveAs } from 'file-saver';
import moment from 'moment';
import Constants from '../utils/constants';
import BaseService from './BaseService';

export default class BaseServiceRead extends BaseService {
    // Initializing important variables
    constructor(domain) {
        super(domain);
        this.getList = this.getList.bind(this);
        this.get = this.get.bind(this);
        this.exportList = this.exportList.bind(this);
    }

    get(id) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getList(criteria) {
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/list${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getItems() {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/items`, {
            method: 'GET',
        })
            .then((res) => {
                return Promise.resolve(res);
            })
            .catch((err) => {
                throw err;
            });
    }

    getCriteriaForDownloading(format, criteria) {
        return this.objToQueryString({
            format: format?.enumValue,
            from_time: criteria.fromTime ? moment(criteria.fromTime).format(Constants.DATE_TIME_FORMAT) : undefined,
            to_time: criteria.toTime ? moment(criteria.toTime).format(Constants.DATE_TIME_FORMAT) : undefined
        });
    }

    startDownloading(format, criteria) {
        // Get a token from api server using the fetch api
        const query = this.getCriteriaForDownloading(format, criteria);
        return this.fetch(`${this.domain}/${this.path}/export${query}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getReportResult(uuid) {
        return this.fetch(`${this.domain}/${this.path}/result/${uuid}`, {
            method: 'GET'
        }).then(res => {
            return Promise.resolve(res);
        });
    }

    export(token, fileLabel, handleError, format, uuid) {
        const url = new URL(`${this.domain}/${this.path}/download/${uuid}/${format}`);
        const headers = {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cahce',
        };
        headers['Authorization'] = token;

        this.fetchGet(url, headers)
            .then(response => {
                return response.blob();
            })
            .then(blob => {
                saveAs(blob, fileLabel);
            })
            .catch(err => {
                if (handleError) {
                    handleError('Nie można pobrać pliku!')
                }
            });

    }
    exportList(params){
		return this.fetch(`${this.domain}/${this.path}/export${params}`,
        {
            method: 'POST'
        },
        {
            'Content-Type': 'application/json',
            Accept: 'application/octet-stream'
        }
		).then((res) => {
			return Promise.resolve(res);
		}).catch((err) => {
			throw err;
		});
    }
    fetchGet(url, headers) {
        // const method = options !== undefined ? options.method : undefined;
        const options = {method: 'GET'};
        this.counter += 1;
        return new Promise((resolve, reject) => {
            fetch(url, {
                headers,
                ...options,
            })
                .then(response => {
                    if (response.ok) {
                        return resolve(response);
                    }
                    return reject(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    }
}
