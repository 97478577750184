import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import { t } from 'i18next';
import ActionLink from '../components/ActionLink';
import EmailService from '../services/EmailService';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';


class EmailListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new EmailService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            messageTypesOptions: [],
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dialogVisibile: false,
        };
    }

    updateSearchCriteria(criteria) {
        return {
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            msgType: criteria.msgType,
            emailDesc: criteria.emailDesc
        };
    }

    getCleanSearchCriteria() {
        return {
            fullName: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: false,
            msgType: null,
            emailDesc: null
        };
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('EmailType')
            .then((data) =>
                this.setState({
                    messageTypesOptions: data.filter(x=>x.enumValue!='LINK_USER_REGISTERED_BY_REGISTER_PANEL')
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać szablonów email');
            });

    }

    getListTitle() {
        return '';
    }
    
    renderCriteria() {
        return (
            <div className='row'>

                <CriteriaDropdownComponent
                    id='msg-type'
                    name='msgType'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.msgType}
                    options={this.state.messageTypesOptions}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('email.msgType')}
                    placeholder={t('email.dropdownCriteria')}
                    optionLabel={'label'}
                    global={true}
                    refreshFromBackend
                    showClear
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='email-desc'
                    name='emailDesc'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.emailDesc}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('email.desc')}
                    placeholder={t('email.textCriteria')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

            </div>
        );
    }

    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }


    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <span>
                <ActionLink
                    iconName='mdi-eye'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={this.handleGoToDetail.bind(this, href)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                    rendered={true}
                />
            </span>
        );
    }

    prepareColumns() {
        return [{
            field: 'emailType.label',
            header: t('email.msgType'),
            sortable: true,
            sortField: 'emailType'
        },
        {
            field: 'description',
            header: t('email.desc'),
            sortable: true,
            sortField: 'description',
        },
        {
            key: 'actions',
            header: t('list.actions'),
            body: this.actionTemplate,
            className: 'table-column-action',
        },
        ];
    }

  
    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    render() {
        return (
            <div className='container-fluid projectcategory-list'>
                <BreadcrumbsItem to='/email-list'>{''}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }
}

EmailListContainer.defaultProps = {
    detailUrl: '/#/email/details',
    editUrl: '/#/email/edit',
    lazy: true,
};

EmailListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default EmailListContainer;