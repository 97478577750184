import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import {t} from 'i18next';
import ActionLink from '../../components/ActionLink';
import EmployeeService from '../../services/EmployeeService';
import BaseUserListContainer from '../../baseContainers/BaseUserListContainer';
import EmployeeModel from '../../model/EmployeeModel';
import { Chip } from 'primereact/chip';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import EmployeeCategoryService from '../../services/EmployeeCategoryService';
import IconColorComponent from '../../components/inputs/IconColorComponent';
import Constants from '../../utils/constants';
import CriteriaNumberRangeComponent from '../../components/criteria/CriteriaNumberRangeComponent';

class EmployeeListContainer extends BaseUserListContainer {
    constructor(props) {
        super(props, new EmployeeService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleTypeOptions: [],
            dialogVisibile: false,
            element: new EmployeeModel(),
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.catService=new EmployeeCategoryService();
    }

    updateSearchCriteria(criteria) {
        let baseUpdateSearchCriteria = {
            email: criteria.email,
            firstName: criteria.firstName,
            lastName: criteria.lastName,
            roleType: criteria.roleType,
            status: criteria.status,
            currentPriceFrom: criteria.currentPriceFrom,
            currentPriceTo: criteria.currentPriceTo,
            firstResult: criteria.firstResult,
            maxResult: criteria.maxResult,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            categories: criteria.categories
        };
        baseUpdateSearchCriteria.subcontractorName = '';
        baseUpdateSearchCriteria.expirationAlarmStatus = criteria.expirationAlarmStatus;
        return baseUpdateSearchCriteria;
    }
    getCleanSearchCriteria() {
        let baseSearchCriteria = {
            email: '',
            firstName: '',
            lastName: '',
            roleType: undefined,
            currentPriceFrom: undefined,
            currentPriceTo: undefined,
            status: {enumValue: "ACTIVE", label: "Aktywny" },
            id: null,
            firstResult: 0,
            maxResult: 10,
            sortField: 'id',
            sortAsc: true,
            categories: []
        };
        baseSearchCriteria.subcontractorName = '';
        baseSearchCriteria.expirationAlarmStatus = null;
        return baseSearchCriteria;
    }

    initializeFromBackend() {
        super.initializeFromBackend();
        this.catService
        .getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true, status: "ACTIVE" })
        .then((data)=>{
            this.setState({
                categories: data.content 
            });
        }).catch((ex)=>{
            this.showErrorMessage('Nie udało się pobrać listy kategorii');
        });
        this.enumService
        .getEnumList('ExpirationAlarmStatus')
        .then((data)=>{
            this.setState({
                expirationAlarmStatusOptions: data.filter((x)=>x?.enumValue!=='HANDLED')
            })
        }).catch((ex)=>{
            this.showErrorMessage('Nie udało się pobrać listy statusów dokumentów');
        });
    }

    getCriteriaName() {
        return 'employee-list-sc';
    }

    getContainerListName() {
        return 'employee-list-container';
    }

    renderCriteria() {
        const showPriceFilter = this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_CTO', 'ROLE_FINANCIAL_MANAGER'); 
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='email-sc'
                    name='email'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.email}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('employee.email')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />                
                <CriteriaTextComponent
                    id='lastName-sc'
                    name='lastName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.lastName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('employee.lastName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='firstName-sc'
                    name='firstName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.firstName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('employee.firstName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={t('employee.status')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
             <CriteriaMultiSelectComponent
                    id='categories'
                    name='categories'
                    label={t('project.categories')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.categories}
                    optionLabel='name'
                    value={this.state.criteria.categories}                                            
                    dataKey='id' 
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='subcontractorName-sc'
                    name='subcontractorName'
                    showClear
                    label={t('employee.subcontractorName')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.subcontractorName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaDropdownComponent
                    id='status-sc'
                    name='expirationAlarmStatus'
                    showClear
                    label={t('employee.documentStatus')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.expirationAlarmStatus}
                    options={this.state.expirationAlarmStatusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
                {showPriceFilter ? (
                    <CriteriaNumberRangeComponent 
                    idFrom='currentPriceFrom-sc'
                    idTo='currentPriceTo-sc'
                    nameFrom='currentPriceFrom'
                    nameTo='currentPriceTo'
                    label={t('employee.currentPrice')}
                    placeholderFrom='Od'
                    placeholderTo='Do'
                    colClass='col-lg-4 col-sm-6'
                    from={this.state.criteria.currentPriceFrom}
                    to={this.state.criteria.currentPriceTo}
                    onChange={this.handleChangeSc}
                    allowEmpty
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}                    
                />) : null}
                
            </div>
        );
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;

        // newUrl: '/#/project/create',
        return (
            <ActionLink
                iconName='mdi-eye'
                iconSide='left'
                iconSize='icon-large'
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
        );
    }
    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER') || (this.authService.isUserInRole('ROLE_USER') && this.authService.isUserInRole('ROLE_FINANCIAL_MANAGER'))
            ? [
                  {
                      label: t('employee.addButton'),
                      type: 'BUTTON',
                      className: 'float-right',
                      variant: 'blue',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                      iconColor: 'white',
                      onClick: this.handleGoToNew,
                  },
              ]
            : [];
    }

    categoriesTemplate(rowData) {
        if (rowData?.categories) {
            return (
                rowData.categories.map(c => {
                    return (
                        <Chip style={{backgroundColor:c?.color?.value, color:"black"}} label={c.name} />
                    )
                })
            )
        } else {
            return '';
        }
    }
    inputColorTemplate(rowData){
        if(rowData?.categories)
        return <div className="row" >
              {rowData.categories.map(category=>{
                  return <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={category.icon.value} color={category.color.value} tootltipText={category.name} />
           })}
          </div>
      }
      getPreparedExpirationStatusIcon(documentStatus){
        switch(documentStatus.enumValue){
            case 'EXPIRED':
                return(
                    <div className='row' style={{ display: 'flex', justifyContent: 'center',width:'100px'}}>
                     {
                         <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={Constants.ALERT_ICON} color={Constants.RED} tootltipText={documentStatus.label} />
                     }
                 </div>
                )
            case 'SOON_EXPIRED':
                return(
                    <div className='row' style={{ display: 'flex', justifyContent: 'center',width:'100px'}}>
                     {
                         <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={Constants.ALERT_ICON} color={Constants.ORANGE} tootltipText={documentStatus.label} />
                     }
                 </div>
                )
            case 'HANDLED':
                return(
                    <div className='row' style={{ display: 'flex', justifyContent: 'center',width:'100px'}}>
                     {
                         <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={Constants.HANDLED_ICON} color={Constants.GREEN} tootltipText={documentStatus.label} />
                     }
                 </div>
                )
            default:
                return null;
        }
    }
    documentStatusTemplate(rowData){
        if(rowData?.expirationStatus){
        let documentStatus=rowData.expirationStatus;
        return documentStatus.enumValue ? (
                this.getPreparedExpirationStatusIcon(documentStatus)
             ) : null
         }
    }
    currentPriceTemplate(rowData, props) {
        //rowData.currentPrice
        const rowIndex = props.rowIndex;
        return (
            <InputNumberComponent
                id={`currentPrice${rowIndex}`}
                name='value'
                label={t('employee.details.price')}
                value={rowData.currentPrice?.value}
                showLabel={false}
                insideTable
                viewMode={'VIEW'}
                mode='currency'
                currency='EUR'
                locale='pl-PL'
            />
        )
    }

    prepareColumns() {
        const showPrice = this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_CTO', 'ROLE_FINANCIAL_MANAGER'); 
        const result = [
            {field: 'email', header: t('employee.email'), sortable: true},            
            {field: 'lastName', header: t('employee.lastName'), sortable: true},
            {field: 'firstName', header: t('employee.firstName'), sortable: true},
            {field: 'subcontractor.name', header: t('employee.subcontractorName'), sortable: true},
            {field: 'status', header: t('employee.status'), sortable: true, body: this.enumTemplate.bind(this, 'status')},
            {field: 'categories', header: t('project.categories'), sortable: false, body: this.inputColorTemplate.bind(this)},
            {field: 'expirationStatus', header: t('employee.documentStatus'), sortable: false, body: this.documentStatusTemplate.bind(this)}
        ];
        if (showPrice) {
            result.push({field: 'currentPrice', filterFiled: 'currentPrice.value', header: t('employee.currentPrice'), sortable: true, body: this.currentPriceTemplate.bind(this)});
        }
        result.push({
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
        });        
        return result;
    }

    handleOpenNewDialog(e) {
        let basicElement = new EmployeeModel();
        let roleTypes = this.state.roleTypeOptions;
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW', enumRoleTypes: roleTypes});
    }
    
    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/employee-list'>{'Pracownicy'}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }
}

EmployeeListContainer.defaultProps = {
    detailUrl: '/#/employee/details',
    newUrl: '/#/employee/create',
    lazy: true,
};

EmployeeListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default EmployeeListContainer;
