import { withTranslation } from "react-i18next";
import BaseDetailsContainer from "../baseContainers/BaseDetailsContainer";
import React from "react";
import PropTypes from 'prop-types';
import InputTextComponent from "../components/inputs/InputTextComponent";
import InputTextareaComponent from "../components/inputs/InputTextareaComponent";
import EmailService from "../services/EmailService";
import InputDropdownComponent from "../components/inputs/InputDropdownComponent";
import { Button } from 'primereact/button';
import ActionButton from "../components/ActionButton";
import DivContainer from "../components/DivContainer";

class EmailDetailsContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new EmailService());
        this.state = {
            activeIndex: 0,
            loading: true,
            downloadingPdf: false,
            elementId: props.elementId,
            typeOptions: [],
            element: {

            },
        };

        this.renderDetails = this.renderDetails.bind(this);
        this.handleContentChange = this.handleContentChange.bind(this);
        this.handleParamChange = this.handleParamChange.bind(this);
        this.textareaRef = React.createRef();
        this.insertTextAtCursor=this.insertTextAtCursor.bind(this);
        this.updateCursorPosition=this.updateCursorPosition.bind(this);
    }

    updateElement(data) {

        this.setState(
            {
                element: data,
            },
            () => {

                this.initAfterSetElement();
            }
        );
    }

    initBeforeSetElement() {
        this.enumService
            .getEnumList('EmailType')
            .then((data) =>
                this.setState({
                    typeOptions: data,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać typów szablonu');
            });

        this.service
            .getParameters(this.state.elementId)
            .then((data) =>
                this.setState({
                    paramOptions: data,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać parametrów');
            });
    }

    prepareFooterItems() {
        const result = super.prepareFooterItems();
        const { backUrl, cancelUrl, viewMode, editUrl } = this.props;
        const { element } = this.state;
        let goBackUrl;
        if (viewMode === 'NEW') {
            goBackUrl = backUrl;
        } else {
            goBackUrl = `${cancelUrl}/${element.id}`;
        }

        switch (viewMode) {
            case 'EDIT':
            case 'NEW':
                break;
            case 'VIEW':
                if (element.status !== 'DELETED') {
                    result.push(
                        { label: 'Edytuj', className: 'float-right', href: `${editUrl}/${element.id}` }
                    )
                }
                break;
            default:
                break;


        }
        return result;
    }

    isPdfButtonRendered() {
        return false;
    }

    getPdfDownloadLabel() {
        return null;
    }

    handleDownloadPdf() {
        // this.setState({ downloadingPdf: true},
        //     () => {
        //         setTimeout(() => {
        //             if (this.state.downloadingPdf) {
        //                 document.getElementById('pdf-download-spinner').style.display = 'block';
        //             }
        //         }, 500);

        //         this.service.exportInvoice(this.state.elementId)
        //             .then((blob) => {
        //                 this.setState({ downloadingPdf: false });
        //                 const now = moment().format('YYYYMMDD_HHmmss');
        //                 const fileName = `Faktura_${now}.pdf`;
        //                 saveAs(blob, fileName);
        //             })
        //             .catch(() => {
        //                 this.setState({ downloadingPdf: false });
        //                 this.showErrorAboutMissingPdf('faktury');
        //             });
        //     });

    }

    getUpdateSucces() {
        const { t } = this.props;
        return t('email.updateSuccess');
    }

    createOrUpdate() {
        const { element } = this.state;
        this.scrollToTop();
        if (this._isMounted) {
            if (element.id === undefined || element.id === null) {
                return;
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.afterUpdateSuccess(response);
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                        this.unblockUi();
                    });
            }
        }
    }

    isBlank(value) {
        let areSpacesOnly = false;
        if (value !== undefined && value !== null && typeof value === 'string') {
            if (value.trim().length === 0) {
                areSpacesOnly = true;
            }
        }
        return typeof value === 'undefined' || value === null || value === '' || areSpacesOnly;
    }
    insertTextAtCursor() {
        const { element, cursorPosition } = this.state;
        const {choosenParam} = this.state;
        let string = element.content != undefined ? element.content.join('\n') : null;

        if((cursorPosition!=undefined || cursorPosition!=null) 
        && !this.isBlank(choosenParam)){

            const newTextValue = 
             string.slice(0, cursorPosition)
            + choosenParam 
            + string.slice(cursorPosition);
      
           this.setState((prevState) => ({
               element: {
                    ...prevState.element,
                     content: newTextValue.split('\n')
                  },
              }));
        }
    };
    renderDetails() {
        const { t } = this.props;
        const { element } = this.state;
        const { typeOptions } = this.state;
        const { paramOptions } = this.state;
        const content = this.state.element.content;
        let string = content != undefined ? content.join('\n') : '';

        return (
            <React.Fragment>
                <div className='row profile'>

                    <InputDropdownComponent
                        id='email-msgType'
                        name='msgType'
                        label={t('email.msgType')}
                        colClass='col-md-10'
                        value={element.emailType}
                        options={typeOptions}
                        optionLabel='label'
                        //validators='required|max:150'
                        //validator={this.validator}
                        onChange={this.handleChange}
                        disabled={this.props.viewMode === 'EDIT'}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='email-desc'
                        name='description'
                        label={t('email.desc')}
                        colClass='col-md-10'
                        value={element.description}
                        validators='required|max:150'
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='email-subject'
                        name='subject'
                        label={t('email.subject')}
                        colClass='col-md-10'
                        value={element.subject}
                        validators='required|max:150'
                        validator={this.validator}
                        onChange={this.insertTextAtCursor}
                        viewMode={this.props.viewMode}
                    />
                    
                    {this.props.viewMode==='VIEW' ? null :
                    <div className='row'>
                        <InputDropdownComponent
                            id='email-parameters'
                            name='msgType'
                            colClass='col-md-4'
                            label={t('email.parameters')}
                            value={this.state.choosenParam}
                            options={paramOptions}
                            optionLabel=''
                            onChange={this.handleParamChange}
                            viewMode={this.props.viewMode}
                        />
                        <div         
                        className='col-md-2'
                        style={{ marginLeft: '20px', marginTop: '30px' }}
                        >
                            <ActionButton
                                label={'Wstaw parametr'}
                                className=''
                                handleClick={this.insertTextAtCursor}
                            />
                        </div>
                        </div>
                        }

                    <InputTextareaComponent
                        id='email-content'
                        name='content'
                        label={t('email.content')}
                        colClass='col-md-10'
                        value={string}
                        validators={'required'}
                        validator={this.validator}
                        handleClick={this.updateCursorPosition}
                        onChange={this.handleContentChange}
                        viewMode={this.props.viewMode}
                    />
                </div>
            </React.Fragment>
        )
    }
    updateCursorPosition(event){
        const { selectionStart } = event.target;
        this.setState(({
            cursorPosition: selectionStart
        }));
    }
    handleParamChange(inputType, parameters, event, onAfterStateChange, stateField) {
        this.setState(({
            choosenParam: event.target.value
        }));
    }
    handleContentChange(inputType, parameters, event, onAfterStateChange, stateField) {
        const { selectionStart } = event.target;
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                content: event.target.value.split('\n')
            },
            cursorPosition: selectionStart
        }));
    }
}

EmailDetailsContainer.defaultProps = {
    backUrl: '/#/email-list',
    cancelUrl: '/#/email/details',
    editUrl: '/#/email/edit',
};

EmailDetailsContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(EmailDetailsContainer);
