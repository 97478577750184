import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {ActionButton} from '../../components/ActionButton';
import DivContainer from '../../components/DivContainer';
import {Dialog} from 'primereact/dialog';
import {Card} from 'primereact/card';
import {t} from 'i18next';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import { Accordion, AccordionTab } from 'primereact/accordion';
import InputAutocompleteComponent from '../../components/inputs/InputAutocompleteComponent';
import BaseContainer from '../../baseContainers/BaseContainer';
import Constants from '../../utils/constants';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';
import { CustomMessages } from '../../components/CustomMessages';
import AccommodationService from '../../services/AccommodationService';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import CountryService from '../../services/CountryService';

class UpdateAccommodationDialog extends BaseContainer {
    constructor(props) {
        super(props, new AccommodationService());
        this.state = {
            closable: true,
            eaId: props?.eaId,
            criteria: {
                countries: [],
            },
            element: {},
            filters: {},
        };

        this.renderFilters = this.renderFilters.bind(this);
        this.clean = this.clean.bind(this);

        this.validator.addCustomRule('date_range', 'Data startu musi być wcześniejsza niż Data zakończenia', value => {
             const { dateStart, dateEnd } = this.state.element;
             return dateStart?.getTime() < dateEnd?.getTime();
        });
        this.countryService=new CountryService();
    }

    componentDidMount() {
        super.componentDidMount();
        this.countryService
            .getAll()
            .then((data) => {
                this.setState({
                    countryOptions: data
                })
            }).catch((err) => {
                this.showErrorMessage("Nie udało się pobrać listy krajów");
            })
        this.setState({
            element: this.props?.updateElement ? this.props?.updateElement : {}
        })

    }
    componentDidUpdate() {
        this.refreshJwtToken();
        if (this.scrollToError) {
            this.scrollToError = false;
            this.scrollToFirstError();
        }
    }


    renderFooter() {
        const  {accomodation, dateStart, dateEnd }  = this.state.element;

        const {eaId}=this.state;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.clean();
                        this.setState({element: {}, filters: {}});
                        this.props.onHide();
                    }}
                />
                <ActionButton
                    label='Zapisz'
                    variant={'button float-right'}
                    handleClick={(e) => {                        
                        if (this.validator.allValid()) {
                            this.props.onSelect(eaId, accomodation, dateStart, dateEnd);
                            this.clean();
                        } else {
                            this.validator.showMessages();
                            this.scrollToError = true;
                            this.forceUpdate();
                        }
                    }}
                />
            </div>
        );
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    {<label>Edytuj nocleg</label>}
                </DivContainer>
            </DivContainer>
        );
    }
    
    getAcommodationList(query, dateStart){
        const { accommodationAddress, countries } = this.state.criteria;

        this.service
            .search(query, dateStart, accommodationAddress, countries)
            .then((accomodations) => {
                this.setState({ accomodations })
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy dostępnych noclegów');
            });
    }

    render() {
        const { className, id, key, name, onHide, visible } = this.props;
        const employeeLabel= "Pracownik: ";
        const fullName = this.state.element.fullName;
        return (
            <Dialog
                ariaCloseIconLabel={t('dialog.closeDialog')}
                header={this.renderHeader()}
                visible={visible}
                className={className}
                resizable={false}
                // style={style}
                key={`${key ? key : id ? id : name}-dialog`}
                onHide={() => {
                    this.clean();
                    this.setState({element: {}, filters: {}});
                    if (onHide) {
                        onHide();
                    }
                }}
                closable={this.state.closable}
                style={{width: '800px'}}
                onShow={() => {
                    this.setState({filters: {}});
                }}
            >
                <Card footer={this.renderFooter()}>
                    {this.renderFilters()}
                    <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />

                    <DivContainer colClass="row">
                        <DivContainer colClass="col-6">
                            <label style={{
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        fontSize: '14px',
                                        lineHeight: '14px',
                                        color: '#8e92a3'}}>
                                {employeeLabel + fullName}
                            </label>
                        </DivContainer>               
                    </DivContainer>
                        
                        
                        <DivContainer colClass='row'>
                            <InputAutocompleteComponent
                                    id={`accomodation`}
                                    name='accomodation'
                                    label='Nocleg'
                                    value={this.state.element.accomodation}
                                    dropdown
                                    colClass='col-md-6'
                                    forceSelection
                                    viewMode='EDIT'
                                    validator={this.validator}
                                    validators='required'
                                    showLabel={true}
                                    onChange={this.handleChange}
                                    insideTable
                                    filteredList={this.state.accomodations}
                                    filterList={(query) => this.getAcommodationList(query,this.state.element.dateStart)}
                                    //field='name'                                     
                                    onAfterStateChange={(e)=>{
                                        this.setState({

                                        })
                                    }}   
                                    minLength={3}
                                    placeholder={t('search.accommodation.placeholder')}
                                />
                        </DivContainer>
                        <DivContainer colClass='row'>
                            <InputCalendarComponent
                                id='dateStart'
                                name='dateStart'
                                label={t('project.details.dateStart')}
                                dateFormatMoment={Constants.DATE_FORMAT}
                                colClass='col-md-6'
                                value={this.state.element.dateStart}
                                validator={this.validator}
                                validators='required|date_range'                                    
                                onChange={this.handleChange}
                                viewMode={'EDIT'}
                                showIcon={true}
                                minDate={this.props.dateMin}
                                maxDate={this.state.dateMax}
                            />
                            <InputCalendarComponent
                                id='dateEnd'
                                name='dateEnd'
                                label={t('project.details.dateEnd')}
                                dateFormatMoment={Constants.DATE_FORMAT}
                                colClass='col-md-6'
                                value={this.state.element.dateEnd}
                                //value={moment('2024-01-01').toDate()}
                                validator={this.validator}
                                validators='required|date_range'                                    
                                onChange={this.handleChange}
                                viewMode={'EDIT'}
                                showIcon={true}
                                minDate={this.state.dateMin}
                                maxDate={this.state.dateMax}
                            />
                        </DivContainer>                        
                </Card>
            </Dialog>
        );
    }

    clean() {
        this.setState((prevState) => ({
            dateMin: undefined,
            dateMax: undefined,
            renderStatistic: false,
            element: {
                ...prevState.element,
                project: null,
            },
        }));
    }

    renderFilters() {
        return (
            <Accordion className='filter-accordion'>
                <AccordionTab
                    header={
                        <React.Fragment>
                            <img className='card-icon' alt={t('list.filter')} src={`/images/ico_filter.svg`} />
                            <span>{t('list.filter')}</span>
                        </React.Fragment>
                    }
                >
                        <DivContainer colClass='col-12 form-search-criteria'>
                        <div className='row'>
                
                        <CriteriaTextComponent
                                id='accommodationAddress'
                                name='accommodationAddress'
                                colClass='col-lg-4 col-sm-6'
                                value={this.state.criteria.accommodationAddress}
                                onChange={this.handleChangeSc}
                                //validator={this.validator}
                                label={t('accommodation.details.address')}
                                global={true}

                            />

                            <CriteriaMultiSelectComponent
                                id='countries'
                                name='countries'
                                label={t('accommodation.details.countryName')}
                                colClass='col-md-4'
                                onChange={this.handleChangeSc}
                                options={this.state.countryOptions}
                                optionLabel='name'
                                value={this.state.criteria.countries}
                                dataKey='id'
                                filter
                                filterBy={'name'}
                            />
                        </div>
                        </DivContainer>
                    
                </AccordionTab>
            </Accordion>
        )
    }


}


UpdateAccommodationDialog.defaultProps = {
    leader: true
};

UpdateAccommodationDialog.propTypes = {
    minDate: PropTypes.instanceOf(Date),
    onHide: PropTypes.func,
    onSelect: PropTypes.func,
    userId: PropTypes.number,
    userName: PropTypes.string,
    leader: PropTypes.bool
};

export default withTranslation()(UpdateAccommodationDialog);
