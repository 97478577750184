import BaseServiceCrud from './BaseServiceCrud';

export default class ToolsService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'tools';
        //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        let status = criteria.status;
        return this.objToQueryString({
            owner_email: criteria.ownerEmail,
            name: criteria.name,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            status: status != null ? status.enumValue : null,
        });
    }
    getProjectsRelatedToTools(toolId) {
        return this.fetch(`${this.domain}/${this.path}/${toolId}/projects`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    normalizeTools(e) {
        if (e.toolList) {
            e.toolList.forEach(tool => {
                this.keepOnlyId(tool);
            })
        }
        return e;
    }

    add(element) {
        const e = this.normalizeTools(this.cloneElement(element));
        return super.add(e);
    }

    update(element) {
        const e = this.normalizeTools(this.cloneElement(element));
        return super.update(e);
    }
}
