import React from 'react';
import PropTypes from 'prop-types';
import {MultiSelect} from 'primereact/multiselect';
import {withTranslation} from 'react-i18next';
import {FilterMatchMode} from 'primereact/api';

class CriteriaMultiSelectComponent extends React.Component {
    componentDidMount() {}

    render() {
        const {
            colClass,
            dataKey,
            disabled,
            filter,
            filterBy,
            id,
            label,
            name,
            onChange,
            onAfterStateChange,
            optionLabel,
            options,
            placeholder,
            selectedItemsLabel,
            showClear,
            validator,
            validators,
            value,
            refreshFromBackend,
            timeoutHandler,
            delay,
        } = this.props;
        const required = validators !== undefined && validators.includes('required') && !validators.includes('not_required');
        return (
            <div className={colClass}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        {label !== undefined ? (
                            // eslint-disable-next-line react/jsx-max-props-per-line
                            <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                                {label}
                            </label>
                        ) : null}
                        <MultiSelect
                            aria-describedby={`${id}-error`}
                            aria-label={label}
                            aria-labelledby={label === undefined ? `${id}-label-id` : undefined}
                            key={id}
                            id={id}
                            name={name}
                            style={{width: '100%'}}
                            value={value}
                            options={options}
                            optionLabel={optionLabel}
                            dataKey={dataKey}
                            onChange={(e) =>
                                onChange
                                    ? onChange(
                                          'MULTI_SELECT',
                                          undefined,
                                          e,
                                          FilterMatchMode.IN,
                                          refreshFromBackend,
                                          onAfterStateChange,
                                          timeoutHandler,
                                          delay
                                      )
                                    : null
                            }
                            placeholder={placeholder}
                            filter={filter}
                            filterBy={filterBy}
                            showClear={showClear}
                            validator={validator}
                            validators={validators}
                            disabled={disabled}
                            maxSelectedLabels={4}
                            selectedItemsLabel={selectedItemsLabel}
                            fixedPlaceholder={value !== undefined && options !== undefined ? value.length >= options.length : false}
                            required={required}
                            display='chip'
                        />
                        <div aria-live='assertive'>{validator ? validator.message(id, label, value, validators) : null}</div>
                    </div>
                </div>
            </div>
        );
    }
}

CriteriaMultiSelectComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    disabled: false,
    filter: false,
    filterBy: 'label',
    placeholder: 'Wybierz',
    selectedItemsLabel: 'Wybrano {0} pozycji',
    showClear: false,
    validators: 'not_required',
    refreshFromBackend: false,
};

CriteriaMultiSelectComponent.propTypes = {
    colClass: PropTypes.string,
    dataKey: PropTypes.string,
    disabled: PropTypes.bool,
    filter: PropTypes.bool,
    filterBy: PropTypes.string,
    id: PropTypes.string.isRequired,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onAfterStateChange: PropTypes.func,
    optionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    options: PropTypes.array.isRequired,
    placeholder: PropTypes.string,
    selectedItemsLabel: PropTypes.string,
    showClear: PropTypes.bool,
    validator: PropTypes.object,
    validators: PropTypes.string,
    value: PropTypes.any,
    refreshFromBackend: PropTypes.bool,
    delay: PropTypes.number,
    timeoutHandler: PropTypes.func,
};

export default withTranslation()(CriteriaMultiSelectComponent);
