import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';
export default class PayReportService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'pay-report';
        //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {    
        return this.objToQueryString({
            date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            user_full_name: criteria.fullName,
            subcontractor: criteria.subcontractor
        });
    }
    exportList(criteria, exportType,summary) {
		const params = this.objToQueryString({
			date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
			sort_field: criteria.sortField,
			sort_asc: criteria.sortAsc,
            export_type: exportType,
            user_full_name: criteria.fullName,
            subcontractor: criteria.subcontractor
		});

        
		return this.fetch(`${this.domain}/${this.path}/export${params}`,
        {
            method: 'POST',
            body: JSON.stringify(summary)
        },
        {
            'Content-Type': 'application/json',
            Accept: 'application/octet-stream'
        }
		).then((res) => {
			return Promise.resolve(res);
		}).catch((err) => {
			throw err;
		});
	}
   
}