import PropTypes from 'prop-types';
import { Card, Tooltip } from 'react-bootstrap';
import DivContainer from '../../components/DivContainer';

export const DashboardTeilComponent = ({viewMode, element, users, managers, categories, additional}) => {
    const header = <img alt='Card' src='https://primefaces.org/cdn/primereact/images/usercard.png' />;
    return (
        <Card title={element.name} subTitle={element.name} header={header} className='dashboard-teil'>
            <DivContainer colClass='row' style={{width:'200px'}}></DivContainer>
            {managers?.map((m) => {
                return (
                    <DivContainer colClass='row'>
                        <div className='leader-name'>
                            <div className='p-inputgroup'>
                                <span className='tttt'>{m.fullName} </span>
                                {m.categoryList?.map(cat => {
                               
                               return(
                                   <span className='projects-icons p-inputgroup-addon'>
                                   <i className={ `mdi ${cat.icon.value}` } style={{color: `${cat.color.value}`}} title={cat.name}></i>
                                   </span>
       
                               );
                           })}

                             { additional[0]?.employeeWithExpiredDocuments?.filter(e => e.employeeId === m.employeeId).length !==0 ? additional[0]?.employeeWithExpiredDocuments?.filter(e => e.employeeId === m.employeeId).length === 1 ?
                            additional[0]?.employeeWithExpiredDocuments?.filter(e => e.employeeId === m.employeeId).map(doc => {
                               return(
                                   <span className='p-inputgroup-addon'> 
                                   <i className={ `mdi mdi-file-document` } style={{color: 'red'}} title={doc.documentType +  ' (ważny do: ' + doc.validTo + ') '}></i>
                                   </span>
       
                               );
                           }) : 
                             (
                           <span className='p-inputgroup-addon'> 
                           <i className={ `mdi mdi-file-document` } style={{color: 'red'}} title={additional[0]?.employeeWithExpiredDocuments?.filter(e => e.employeeId === m.employeeId).map(d => (d.documentType + ' (ważny do: ' + d.validTo + ') '))}></i>
                           </span>) : null}
                            </div>
                        </div>
                    </DivContainer>
                );
            })}


            {users?.map((u) => {
                return (
                    <DivContainer colClass='row' style={{width:'200px'}}>
                      
                        <div className='employee-name'>
                            <div className='p-inputgroup'>
                                <span className='tttt'>{u.fullName} </span>
                            
                            {u.categoryList?.map(cat => {
                               
                                return(
                                    <span className='projects-icons p-inputgroup-addon'>
                                    <i className={ `mdi ${cat.icon.value}` } style={{color: `${cat.color.value}`}} title={cat.name}></i>
                                    </span>
        
                                );
                            })}
                           
                            { additional[0]?.employeeWithExpiredDocuments?.filter(e => e.employeeId === u.employeeId).length !==0 ? additional[0]?.employeeWithExpiredDocuments?.filter(e => e.employeeId === u.employeeId).length === 1 ?
                            
                            additional[0]?.employeeWithExpiredDocuments?.filter(e => e.employeeId === u.employeeId).map(doc => {
                               return(
                                   <span className=' p-inputgroup-addon'> 
                                   <i className={ `mdi mdi-file-document` } style={{color: 'red'}} title={doc.documentType + ' (ważny do: ' + doc.validTo + ') '}></i>
                                   </span>
       
                               );
                           }) : 
                             (
                           <span className='tooltipDocumentEmpl p-inputgroup-addon'> 
                           <i className={ `mdi mdi-file-document` } style={{color: 'red'}} title={additional[0]?.employeeWithExpiredDocuments?.filter(e => e.employeeId === u.employeeId).map(d => (d.documentType + ' (ważny do: ' + d.validTo + ') '))}></i>
                           </span>) : null}
                          
                       
                            </div>
                            </div>
                       
                    </DivContainer>
                );
            })}
        </Card>
    );
};

DashboardTeilComponent.defaultProps = {
    value: undefined,
    rendered: true,
    viewMode: 'VIEW',
    pickerClassName: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    componentClassName: 'col-12',
    users:[],
    leaders:[],
    additional:[],
};

DashboardTeilComponent.propTypes = {
    viewMode: PropTypes.string,
    rendered: PropTypes.bool,
    value: PropTypes.string,
    element: PropTypes.array,
    users: PropTypes.array,
    leaders: PropTypes.array,
    categories: PropTypes.array,
    additional: PropTypes.array,
};

export default DashboardTeilComponent;
