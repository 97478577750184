import BaseServiceCrud from './BaseServiceCrud';


export default class ActivityService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'activity';
      //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,            
            status: criteria.status?.enumValue,            
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
    getCriteriaWithStatus(criteria) {
        return this.objToQueryString({
            name: criteria.name,            
            status: criteria.status,            
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
    getListWithInternalActivities(criteria) {
        const queryString = this.getCriteriaWithStatus(criteria);
        return this.fetch(`${this.domain}/${this.path}/list${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    
}
