export default class RDModel {
    constructor() {
        this.id = undefined;
        this.date = undefined;
        this.verifiedGps = undefined;
        this.carEvents = [];
        this.extraEvents = [];
        this.level = [];
        this.project = [];
        this.status = undefined;
        this.userDays = [];
        this.alarms = [];
        this.waitForAcceptation = [];
        this.reportedEmpQuantity = 0;
        this.empInProjectQuantity = 0;
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id ? from.id : '';
            to.date = from.date ? from.date : '';
            to.carEvents = from.carEvents ? from.carEvents : [];
            to.extraEvents = from.extraEvents ? from.extraEvents : [];
            to.project = from.project ? from.project : [];
            to.userDays = from.userDays ? from.userDays : [];
            to.alarms = from.alarms ? from.alarms : [];
            to.waitForAcceptation = from.waitForAcceptation ? from.waitForAcceptation : [];
            to.level = from.level;
            to.status = from.status;
            to.verifiedGps = from.verifiedGps;
            to.reportedEmpQuantity = from.reportedEmpQuantity;
            to.empInProjectQuantity = from.empInProjectQuantity;
        } else {
            to = new RDModel();
        }
    }

    static copy(from) {
        const to = new RDModel();
        RDModel.assign(from, to);
        return to;
    }
}
