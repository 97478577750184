import { Badge } from 'primereact/badge';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import ActionLink from '../components/ActionLink';


function EmployeeProjectPlaningStatistic(props){
    let { loading, stats, render, showDetails } = props;
    if (loading === null || loading === undefined) {
        loading = false;
    }
    const absenceCount = stats?.absenceInDays;
    const invalidDocumentCount = stats?.documents?.length;
    const otherProjectAssigmentCount = stats?.projectAssigment?.length;
    
    if (!render) {
        return null;
    }
    return (
        <div className="p-inputtext-view" style={{width: "150px", textAlign: 'center'}}>
            {loading ? (
                <Skeleton height="2rem" width="100%" className="mb-2" borderRadius="16px"></Skeleton>
            ) : (
                <div>
                <div className="col-12" style={{paddingTop: '10px', whiteSpace: 'nowrap', textAlign: 'center'}}>
                    <Tooltip id="tooltipEmployeeStatusInfo" target=".employeeStatusInfo" />
                    <i className="employeeStatusInfo icon mdi mdi-beach p-overlay-badge" style={{ fontSize: '20px', marginRight: '14px', color: '#6c757d'}} data-pr-tooltip="Nieobecności w dniach">
                        <Badge value={absenceCount} severity={absenceCount == 0 ? 'success' : 'warning'}></Badge>
                    </i>
                    <i className="employeeStatusInfo icon mdi mdi-file-document p-overlay-badge" style={{ fontSize: '20px', marginRight: '14px', color: '#6c757d' }} data-pr-tooltip="Ilość braków w dokumentach">
                        <Badge value={invalidDocumentCount} severity={invalidDocumentCount == 0 ? 'success' : 'danger'}></Badge>

                    </i>
                    <i className="employeeStatusInfo icon mdi mdi-checkbox-marked-outline p-overlay-badge" style={{ fontSize: '20px', color: '#6c757d' }} data-pr-tooltip="Ilość przypisań do innch projektów">
                        <Badge value={otherProjectAssigmentCount} severity={otherProjectAssigmentCount == 0 ? 'success' : 'warning'}></Badge>
                        {/* <Skeleton shape="circle" size="1.5rem" className="p-badge p-component p-badge-no-gutter"></Skeleton> */}
                    </i>
                     {(stats?.alarm !== null  && stats.alarm !==undefined) && <i className="employeeStatusInfo icon mdi mdi-alarm-light p-overlay-badge" style={{ fontSize: '20px', color: 'red', marginLeft:"20px" }} data-pr-tooltip={`Przekroczono limit dni: ${stats.alarm.employeeDaysInCountry}`  } />} 
                    

                </div>
                <div className="col-12">
                    <ActionLink label="Szczegóły" className="p-label" handleClick={() => showDetails ? showDetails() : null}/>
                </div>
                </div>
            )}
        </div>
    )
};

EmployeeProjectPlaningStatistic.defaultProps = {
    
};

EmployeeProjectPlaningStatistic.propTypes = {
    loading: PropTypes.bool,
    stats: PropTypes.object,
    render: PropTypes.bool,
    showDetails: PropTypes.func,
};

export default withRouter(withTranslation()(EmployeeProjectPlaningStatistic));

