import moment from 'moment/moment';
import BaseServiceCrud from './BaseServiceCrud';
import Constants from '../utils/constants';

export default class ProjectExpenseService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'project-expense';
    }

    normalizeElement(e) {
        if (e.date) {
            e.date = moment(e.date).format(Constants.DATE_JSON_FORMAT);
        }
        return e;
    }

    add(element) {
        const e = this.normalizeElement(this.cloneElement(element));
        return super.add(e);
    }

    update(element) {
        const e = this.normalizeElement(this.cloneElement(element));
        return super.update(e);
    }
}
