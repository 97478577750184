import BaseService from './BaseService';

export default class EnumService extends BaseService {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'enum';
        this.getEnumList = this.getEnumList.bind(this);
        this.getEnumLabel = this.getEnumLabel.bind(this);
    }

	getEnumList(enumName) {
		return this.fetch(`${this.domain}/${this.path}/list/${enumName}`, {
			method: 'GET',
		}).then(res => {
			return Promise.resolve(res);
		});
	}
    getEnumListWithDescription(enumName, translate) {
        return this.fetch(`${this.domain}/${this.path}/list/${enumName}`, {
            method: 'GET',
        }).then((res) => {
			const result = [];
            if (res && translate) {
                res.forEach((element) => {
					result.push({label: translate(`${enumName}.${element}`), enumValue: element, description: translate(`${enumName}.${element}descr`)});
                });
            }
            return Promise.resolve(result);
        });
    }

    getEnumLabel(enumName, enumValue) {
        return this.fetch(`${this.domain}/${this.path}/label/${enumName}/${enumValue}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

}
