import BaseUserService from './BaseUserService';

const path = 'client';

export default class ClientService extends BaseUserService {
    // Initializing important variables
    constructor() {
        super(path);
        this.path = path;
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,
            address: criteria.address,            
            status: criteria.status?.enumValue,            
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    list(query) {
		let path = `${this.domain}/${this.path}/list-ac`;
		if (query) {
			path += `?query=${query}`;
		}
    	return this.fetch(path, {
    		method: 'GET',
    	})
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
    		throw err;
    	});
    }
}
