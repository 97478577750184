import { t } from 'i18next';
import { Chip } from 'primereact/chip';
import { DataView } from 'primereact/dataview';
import { Tag } from 'primereact/tag';
import PropTypes from 'prop-types';
import { default as React } from 'react';
import '../../assets/css/dataviewfull.scss';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import ActionButton from '../../components/ActionButton';
import ActionLink from '../../components/ActionLink';
import { CustomMessages } from '../../components/CustomMessages';
import DivContainer from '../../components/DivContainer';
import YesNoDialog from '../../components/YesNoDialog';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import DashboardTeilComponent from '../../components/inputs/DashboardTeilComponent';
import BlockUi from '../../components/waitPanel/BlockUi';
import ClientService from '../../services/ClientService';
import CriteriaTemplateService from '../../services/CriteriaTemplateService';
import ProjectCategoryService from '../../services/ProjectCategoryService';
import ProjectDashboardService from '../../services/ProjectDashboardService';
import NumberUtils from '../../utils/NumberUtils';
import CriteriaTemplateAddingDialog from '../CriteriaTemplateAddingDialog';

class ProjectDashboardContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ProjectDashboardService());
        this.state = {
            list: [],
            loading: false,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            criteriaTemplate: undefined,
            statusOptions: [],
            roleTypeOptions: [],
            categories: [],
            projects: [],
            layout: 'grid',
            //
            holidays:[],
            holidaysPlanned:[],
            usersJoin:[],
            usersLeave:[],
            users: [],
            usersCategories: [],
            absences: [],
            projectCars: [],
            projectAccommodations: [],

            //
            saveCriteriaDialogVisible: false,
            deleteConfirmDialogVisible: false,
            element: {
                name: '',
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            },
            projectsByDateOptions:[
                { enumValue: "ACTIVE_NOW", label: "Bieżące"}, {enumValue: "ALL", label: "Wszystkie"} ],
        };
        this.clientService = new ClientService();
        this.projectCategoryService = new ProjectCategoryService();
        this.criteriaTemplateService = new CriteriaTemplateService();
        this.projectDashboardService = new ProjectDashboardService();
        this.service = new ProjectDashboardService();
        this.itemTemplate = this.itemTemplate.bind(this);
        this.onSortChange = this.onSortChange.bind(this);
        this.handleOpenSaveCriteriaTemplateDialog = this.handleOpenSaveCriteriaTemplateDialog.bind(this);
    }

    updateSearchCriteria(criteria) {
        return {
            name: criteria.name,
            projectsByDate: criteria.projectsByDate,
            futureProjects : criteria.projectsByDate.enumValue === 'ALL' ? true : false,
            categories: criteria.categories,
            clientName: criteria.clientName,
            countryName: criteria.countryName,
            supervisor: criteria.supervisor,
            status: criteria.status,
            maxResultsPerPage : null,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            maxResultsObj: {value: criteria.maxResults},
        };
    }

    getCleanSearchCriteria() {
        return {
            name: '',
            status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            id: null,
            projectsByDate: { enumValue: "ACTIVE_NOW", label: "Bieżące"},
            futureProjects: false,
            categories: [],
            countryName: '',
            clientName: null,
            supervisor: null,
            maxResultsPerPage : null,
            firstResult: 0,
            maxResults: 9,
            sortField: 'dateStart',
            sortAsc: false,
            maxResultsObj: {value: 9},
        };
    }

    initBeforeSetElement() {
        this.removeCookie("projectDashboardBackPath");
        this.projectDashboardService
        .getProjects()
        .then((projects) => {
            this.setState({
                projects,
            });
        })
        .catch(() => {
            this.showErrorMessage('Nie udało się pobrać listy projektów dla tablicy');
        });

    }

    renderBackLink() {
        return null;
    }

    getList() {
        const {criteria, criteriaTemplate} = this.state;
        const activeElement = document.activeElement;
        this.setState(
            {
                loading: true,
            },
            () => {
                this.service
                    .getProjectDashboard(criteriaTemplate !== null && criteriaTemplate !== undefined && criteriaTemplate !== '' ? criteriaTemplate : criteria)
                    .then((data) => {
                        if (this._isMounted) {
                            this.setState(
                                {
                                    projects: this.updateListElements(data.content),
                                    loading: false,
                                    size: data.totalElements,
                                },
                                () => {
                                    //this.onAfterGetList(data);
                                    //this.setTimeout(() => activeElement?.focus(), 50);
                                }
                            );
                        }
                    })
                    .catch((err) => {
                        if (this._isMounted) {
                            this.setState(
                                {
                                    list: [],
                                    loading: false,
                                    size: 0,
                                },
                                () => {
                                    //this.afterGetListError();
                                }
                            );
                        }
                    });
            }
        );
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('ProjectStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
        this.enumService
            .getEnumList('RoleType')
            .then((roleTypeOptions) =>
                this.setState({
                    roleTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ról');
            });
        this.projectCategoryService
            .getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
            .then((categories) => this.setState({ categories: categories.content }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });

        this.criteriaTemplateService
            .getCriteriaTemplateList('PROJECT_DASHBOARD')
            .then((response) => this.setState({ criteriaTemplates: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy szablonów filtrów');
            });
        this.projectDashboardService
            .getProjectHolidays()
            .then((response) => this.setState({ absences: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy uzytkownikow na urlopie');
            });
        this.projectDashboardService
            .getProjectUsers()
            .then((response) => this.setState({ users: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy uzytkownikow przypisanych do projektu');
            });
        this.projectDashboardService
            .getProjectManagers()
            .then((response) => this.setState({ managers: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy liderów projektu');
            });
        this.projectDashboardService
            .getCategoriesIcons()
            .then((response) => this.setState({ usersCategories: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ikon kategorii');
            });
        this.projectDashboardService
            .getProjectCars()
            .then((response) => this.setState({ projectCars: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy samochodów');
            });
                    this.projectDashboardService
                        .getAccommodationsByProjectId()
                        .then((response) => this.setState({ projectAccommodations: response }))
                        .catch(() => {
                            this.showErrorMessage('Nie udało się pobrać listy noclegów');
                        });
    }

    getCriteriaName() {
        return 'project-dashboard-sc';
    }

    getContainerListName() {
        return 'project-dashboard-list-container';
    }
    getPdfDownloadLabel() {}
    renderPdfDownloadButton() {
        return null;
    }
    renderFullscreenButton() {
        let {fullscreenUrl} = this.props;
        const href = `${fullscreenUrl}`;
        return (
            <ActionButton
                className='pdf-download-button'
                handleClick={this.handleGoToFullScreenView.bind(this, href)}
                iconName='mdi-fullscreen'
            />
        );
    }

    handleGoToFullScreenView(href, e) {
        const {criteria} = this.state;
        e.preventDefault();
        criteria.maxResults = 100;
        let criteriaToPath = this.projectDashboardService.getCriteriaPath(criteria);
        this.service.setCriteria(this.getCriteriaName(), JSON.stringify(criteria));
        window.location.href = href + criteriaToPath;
    }

    handleGoToEditProject(editUrl, elementId, e) {
        const href = `${editUrl}/${elementId}`;
        const {criteria} = this.state;
        this.saveCookie("projectDashboardBackPath", this.props.backListUrl )
        if (!this.props.hideFilters) {
            this.saveCookie(this.getCriteriaName(), JSON.stringify(criteria));
        }
        e.preventDefault();
        window.location.href = href;
    }

    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <ActionLink
                iconName='mdi-eye'
                iconSide='left'
                iconSize='icon-large'
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
        );
    }

    categoriesTemplate(rowData) {
        if (rowData?.categories) {
            return rowData.categories.map((c) => {
                return <Chip style={{backgroundColor: c?.color?.value, color: 'black'}} label={c.name} />;
            });
        } else {
            return '';
        }
    }

    panelRationTemplate(rowData) {
        const panelRatio = rowData['panelRatio'];
        if (panelRatio) {
            return <span>{NumberUtils.formatNumber(panelRatio, 6, 6)}</span>;
        } else {
            return '';
        }
    }

    //start new
    onSortChange(event) {
        const value = event.value;

        if (value.indexOf('!') === 0) {
            this.setState({
                sortOrder: -1,
                sortField: value.substring(1, value.length),
                sortKey: value,
            });
        } else {
            this.setState({
                sortOrder: 1,
                sortField: value,
                sortKey: value,
            });
        }
    }

    
    renderGridItem(data) {
        const {editUrl} = this.props;
        return (
            /*   <div className="col-4 md:col-4" > */
            <div className="'col-xl-4 col-lg-4 col-md-4 col-sm-6'">
                <div className='product-grid-item card'>
                    <div className='product-grid-item-top-country'>
                    <Tag value={data.country.code !== null && data.country.code !== "" ? data.country.code : data.country.name} 
                    style={{backgroundColor:data.country.color,

                    //textShadow:'-1px 1px 0 #000,1px 1px 0 #000, 1px -1px 0 #000,-1px -1px 0 #000'
                 
                    }} ></Tag>
                    </div>
                    <div className='product-grid-item-top'>
                        <div>
                            <i className='pi pi-tag product-category-icon'></i>
                            <span className='product-category'>{data.name}</span>
                        </div>
                        {/* <span className={`product-badge status-${data.status}`}>{data.status}</span> */}
                       
                        <Tag value={data.dateEnd} severity={'success'}></Tag>
                    </div>
                    <div className='product-grid-item-content'>
                        <DashboardTeilComponent element={data} categories={this.state.usersCategories} users={this.state.users?.filter(u => u.projectId === data.id)} managers={this.state.managers?.filter(m => m.projectId === data.id)} viewMode={'VIEW'} rendered={true} additional={this.state.absences?.filter(a => a.projectId === data.id)}></DashboardTeilComponent>

                        <div className='product-name'>{data.clientName}</div>
                        <ActionLink
                    iconName='mdi-pencil'
                    iconSide='left'
                    iconSize='icon-large'
                    iconColor='yellow'
                    handleClick={this.handleGoToEditProject.bind(this, editUrl, data.id)}
                    rendered={this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO')}
                />
                    </div>

                    <div className='product-grid-item-bottom'>

                        {/* noclegi */}
                        {this.state.projectAccommodations?.filter(a => a.projectId === data.id).map((a) => {
                            let employees = a.employees.map((x) => {
                                return x.fullName + " " + x.startDate + '-' + x.endDate;
                            }).join(',\n');

                            return (
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-home' title={a.name + '\n' + employees}></i>
                                    </span>
                                </div>
                            );
                        })}
                         {/* pojazdy */}
                         {this.state.projectCars?.filter(projectCar => projectCar?.project && projectCar.project.id === data.id).map((pc) => pc?.car).map((c) => {
                            return (
                                <div className='p-inputgroup'>
                                    <span className='p-inputgroup-addon'>
                                        <i className='pi pi-car' title = {c.brand + ' ' + c.model + ' ' + c.licensePlate}></i>
                                    </span>
                                </div>
                            );
                        })}
       

                    </div>
                    <div className='product-grid-item-bottom'>      
                    </div>
                </div>
            </div>
        );
    }

    itemTemplate(product, layout) {
        if (!product) {
            return;
        }
        return this.renderGridItem(product);
    }

    renderHeader() {
        return (
            <div className='grid grid-nogutter'>
                <div className='col-6' style={{textAlign: 'right'}}></div>
                <div className='row' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    {
                        <div className='col-12' style={{textAlign: 'right', fontWeight: '600'}}>
                            {this.renderFullscreenButton()}
                        </div>
                    }
                </div>
            </div>
        );
    }
    handlePage(event) {
        if (this._isMounted) {
            this.setState(
                (prevState) => ({
                    loading: this.props.lazy,
                    first: event.first,
                    criteria: {
                        ...prevState.criteria,
                        firstResult: event.first,
                        maxResults: event.rows,
                        maxResultsObj: {value: event.rows},
                    },
                }),
                () => (this.props.lazy ? this.refreshTable(false) : null)
            );
        }
    }

    render() {
        const header = this.renderHeader();
        const {criteria} = this.state;
        let cel = this.state.criteriaElement;
        return (
            <div className='container-fluid'>
          {/*   <BreadcrumbsItem to='/project-list'>{'Użytkownicy'}</BreadcrumbsItem> */}
        
            {this.renderView()} 
            <div className='dataview-demo'>
                <div className='card-m'>
                    {this.state.saveCriteriaDialogVisible && <CriteriaTemplateAddingDialog
                        key={`db-dialog-`}
                       // name='sssss-ss-dialog'
                        criteria={this.state.criteriaElement}
                        element={this.state.myElemTest}
                        elementId={this.state.criteriaElementId}
                        className='confirm-dialog-width'
                        onHide={(e) => this.setState({saveCriteriaDialogVisible: false})}
                        visible={this.state.saveCriteriaDialogVisible}
                        viewMode={this.state.dialogViewMode}
                        afterSave={(e) => {
                            this.setState({saveCriteriaDialogVisible: false, criteriaTemp: cel, criteriaTemplateName: ''});
                            this.afterObjectUpdate();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        criteriaElementId={this.state.criteriaElementId}
                        templateName={this.state.criteriaTemplateName}

                    />}

                    {this.state.deleteConfirmDialogVisible ? (
                <YesNoDialog
                    visible={this.state.deleteConfirmDialogVisible}
                    header={t('criteriaTemplate.deleteHeader')}
                    name='visible'
                    onChange={(type, x, target) => {
                        if (target.value) {
                        
                            this.handleDelete();
                        } else {
                            this.setState({deleteConfirmDialogVisible: false})
                        }
                    }}
                    onHide={() => this.setState({deleteConfirmDialogVisible: false})}
                     >
                        {t('criteriaTemplate.deleteConfirmation')+'\n' + this.state.element.name}
                    </YesNoDialog> 
                ) : null}
                 
                    <DataView
                        value={this.state.projects}
                        layout={this.state.layout}
                        header={header}
                        itemTemplate={this.itemTemplate}
                        paginator
                       // paginatorPosition={'both'}
                        lazy
                        totalRecords={this.state.size} 
                        first={this.state.criteria.firstResult}
                        rows={9}
                        sortOrder={this.state.sortOrder}
                        sortField={this.state.sortField}
                        onPage={this.handlePage}
                    />
                </div>
            </div>               
        </div>
          
        );
    }

    handleDelete(e){
        if (e) {
            e.preventDefault();
        }            
        this.criteriaTemplateService
            .remove(this.state.criteriaTemp)
            .then((response) => {
                this.blockUi();
                this.afterObjectUpdate();
            })
            .catch((err) => {
                this.afterObjectUpdate();             
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({deleteConfirmDialogVisible: false});
            });
        
    }

    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.criteriaTemplateService
        .getCriteriaTemplateList('PROJECT_DASHBOARD')
        .then((response) => {
            let currentCriteriaElement = JSON.stringify(this.state.criteriaElement);
            let currentCriteriaTemp = response.filter(c => c.criteria === currentCriteriaElement)[0];
            this.setState({criteriaTemplates: response, criteriaTemp: currentCriteriaTemp})})
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy szablonów filtrów');
            });
        this.unblockUi();
    }
    renderCriteria() {
        return (
            <div>
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='name'
                    colClass='col-lg-3 col-sm-6'
                    value={this.state.criteria.name}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('project.name')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='country-sc'
                    name='countryName'
                    colClass='col-lg-3 col-sm-6'
                    value={this.state.criteria.countryName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('project.country')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='clientName-sc'
                    name='clientName'
                    colClass='col-lg-3 col-sm-6'
                    value={this.state.criteria.clientName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('project.clientName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='supervisor-sc'
                    name='supervisor'
                    colClass='col-lg-3 col-sm-6'
                    value={this.state.criteria.supervisor}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('project.supervisorSc')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaMultiSelectComponent
                    id='categories'
                    name='categories'
                    label={t('project.categories')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.categories}
                    optionLabel='name'
                    value={this.state.criteria.categories}
                    dataKey='id'
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={t('project.status')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
                  <CriteriaDropdownComponent
                    id='projectsByDate-sc'
                    name='projectsByDate'
                   // showClear
                    label={t('project.projectsByDate')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.projectsByDate}
                    options={this.state.projectsByDateOptions}
                   /// placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
                </div>
                 <div className='row criteria-template-buttons' style={{display: 'flex', flexFlow:'wrap-reverse'}}>
                    <CriteriaDropdownComponent
                    id='criteriaTemplate-sc'
                    name='criteriaTemplate'
                    showClear
                    label={t('project.criteriaTemplate')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteriaTemp}
                    options={this.state.criteriaTemplates}
                    placeholder='Brak'
                    onChange={(a,b,c,d,e) => {  
                        if(c.target.value !== undefined){
                            this.handleTakeCriteriaFromTemplate(c.target.value.criteria);
                            this.setState({criteriaTemp: c.target.value})
                        } else{
                            this.handleTakeCriteriaFromTemplate(undefined);
                            this.setState({criteriaTemp: undefined})
                        }

                    }
                        
                    }
                    validator={this.validator}
                    optionLabel='name'
                    filterOptionValue='name'
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                {this.state.criteriaTemp !== null &&this.state.criteriaTemp !== undefined && this.state.criteriaTemp !== '' ?
                   <ActionButton
                   con='pi pi-pencil'
                   label='Edytuj szablon'
                   style={{backgroundColor:'green', justifyContent: 'flex-end'}}
                   className={'pdf-download-button'}
                   colClass='col-12'
                   handleClick={this.handleOpenEditCriteriaTemplateDialog.bind(this)}
               />
                 :
                <ActionButton
                con='pi pi-pencil'
                label='Zapisz jako szablon'
                style={{backgroundColor:'green', justifyContent: 'flex-end'}}
                className={'pdf-download-button'}
                colClass='col-12'
                handleClick={this.handleOpenSaveCriteriaTemplateDialog.bind(this)}
            />

            }

                     <ActionButton
                            con='pi pi-pencil'
                            label='Usuń szablon'
                            style={{backgroundColor:'green', justifyContent: 'flex-end'}}
                            className={'pdf-download-button'}
                            colClass='col-12'
                            handleClick={this.handleDeleteTemplateDialog.bind(this)}
                            rendered={this.state.criteriaTemp !== undefined}
                        />     
                </div>
            </div>
           
        );
    }
    queryStringToCriteria(str) {
        let decodedString = (str !== null && str !== '' && str !== undefined ) ? str.replace('?', '') : null;
        return decodedString;
    }

    handleTakeCriteriaFromTemplate(targetCriteria){
        if(targetCriteria !== undefined){
            let newCriteria = JSON.parse(this.queryStringToCriteria(targetCriteria));
            if(newCriteria !== null ){
                this.setState({criteria:newCriteria}, () => {
                    this.refreshTable()})     
            }
            return newCriteria;
        } else {
            this.setState({criteria:this.getCleanSearchCriteria()}, () => {
                this.refreshTable()}) 

            return this.getCleanSearchCriteria();
        }
        

    }
    handleOpenSaveCriteriaTemplateDialog() {
        this.setState({saveCriteriaDialogVisible: true, criteriaElement: this.cloneElement(this.state.criteria), criteriaTemplateName: undefined, dialogViewMode: 'NEW', });
    }

    handleOpenEditCriteriaTemplateDialog() {
        let criteriaElementId = this.state.criteriaTemp.id;
        let criteriaElementName = this.state.criteriaTemp.name;
        let elementAll = {templateName: criteriaElementName, id: criteriaElementId, criteria: this.cloneElement(this.state.criteria)  }

        this.setState({saveCriteriaDialogVisible: true, criteriaElement: this.cloneElement(this.state.criteria), dialogViewMode: 'EDIT', criteriaElementId: criteriaElementId, criteriaTemplateName: criteriaElementName, myElemTest: elementAll});
    }


    unsetSaveCriteriaDialogVisible(){
        this.setState({saveCriteriaDialogVisible: false, criteriaElement: []});
    }

    handleDeleteTemplateDialog() { 
        this.setState({deleteConfirmDialogVisible: true, criteriaTempToRemove: this.cloneElement(this.state.criteriaTemp), dialogViewMode: 'DELETE'});
    }
    renderView() {
        return (
            <DivContainer colClass='details-main'>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <BlockUi
                    tag='div'
                    blocking={this.state.blocking || this.state.loading}
                    loader={this.loader}
                    //rendered={!this.props.embedded}
                >
                    <DivContainer colClass='row' /* rendered={this.props.hideFilters !== true} */>{this.renderFilter()}</DivContainer>
                    <DivContainer colClass='col-12 details-main-link-container'>
                        <DivContainer colClass='row'></DivContainer>
                    </DivContainer>
                </BlockUi>
            </DivContainer>
        );
    }
}

ProjectDashboardContainer.defaultProps = {
    detailUrl: '/#/project/details',
    newUrl: '/#/project/create',
    editUrl: '/#/project/edit',
    backListUrl:'/#/project-dashboard',
    fullscreenUrl: '/#/project-dashboard-fullscreen',
    lazy: true,
};

ProjectDashboardContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default ProjectDashboardContainer;
