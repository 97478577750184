class StringUtils {
    static getReplacedString(original, toRemove, toInsert) {
        return String(original.replace(new RegExp(this.escapeRegExp(toRemove), 'g'), toInsert));
    }
    static escapeRegExp(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    static isBlank(value) {
        let areSpacesOnly = false;
        if (value !== undefined && value !== null && typeof value === 'string') {
            if (value.trim().length === 0) {
                areSpacesOnly = true;
            }
        }
        return typeof value === 'undefined' || value === null || value === '' || areSpacesOnly;
    }
    static isEmpty(value) {
        return value.trim() === '';
    }
    static convert2Number(value) {
        let num = Number(value);
        return isNaN(num) ? null : num;
    }
}
export default StringUtils;
