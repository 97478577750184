import { Card } from 'primereact/card';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabPanel, TabView } from 'primereact/tabview';
import PropTypes from 'prop-types';
import React from "react";
import { withTranslation } from "react-i18next";
import BaseDetailsContainer from "../baseContainers/BaseDetailsContainer";
import ActionLink from "../components/ActionLink";
import DivContainer from "../components/DivContainer";
import YesNoDialog from "../components/YesNoDialog";
import InputAutocompleteComponent from "../components/inputs/InputAutocompleteComponent";
import InputTextComponent from "../components/inputs/InputTextComponent";
import InputTextareaComponent from "../components/inputs/InputTextareaComponent";
import EmployeeService from "../services/EmployeeService";
import ToolService from "../services/ToolService";
import ToolsService from "../services/ToolsService";
import LogEntryListContainer from "./LogEntryListContainer";

class ToolsDetailsContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new ToolsService());
        this.employeeService = new EmployeeService();
        this.state = {
            activeIndex: 0,
            loading: true,
            downloadingPdf: false,
            elementId: props.elementId,
            leaderRoles: [],
            clients: [],
            categories: [],
            activities: [],
            toolsToDelete: [],
            filteredTools: [],
            assignedProjects: [],
            logEntryCodes: [],
            status: null,
            cars: [],
            users: [],
            tools: [],
            priceTypes: [],
            statusOptions: [],
            element: {
                toolList: []
            },
        };

        this.toolLabel = this.toolLabel.bind(this);
        this.renderDetails = this.renderDetails.bind(this);
        this.handleAddNewTools = this.handleAddNewTools.bind(this);
        this.searchTools = this.searchTools.bind(this);
        this.toolService = new ToolService();

        this.deleteToolkit = this.deleteToolkit.bind(this);
        this.hideDeleteDialog=this.hideDeleteDialog.bind(this);
        this.showDeleteDialog = this.showDeleteDialog.bind(this);
    }

    showDeleteDialog(){
        this.setState({showDeleteDialog: true});
    }

    hideDeleteDialog() {
        this.setState({showDeleteDialog: false});
    }




    formatNumber(number) {
        if (!number) {
            return 0;
        }
        const formatter = new Intl.NumberFormat('pl-PL', {
            style: 'decimal',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const formattedNumber = formatter.format(number);
        return formattedNumber;
    }

    deleteToolkit() {
        const { element } = this.state;
        const { t } = this.props;

        this.blockUi();
        this.service
            .remove(element)
            .then((result) => {
                this.showSuccessMessage(t('tools.deleteSuccess'));
                this.updateElement(result);
                this.hideDeleteDialog();
                this.unblockUi();
                this.afterDeleteSuccess(result);
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
                this.hideDeleteDialog();
                this.unblockUi();
            });
   }

    afterDeleteSuccess(response) {
        const {backUrl} = this.props;
        this.blockUi();
        this.persistMessage('success', '', this.getRemoveSucces());
        window.location.href = backUrl;
    }

    updateElement(data) {

        this.setState(
            {
                element: data,
                status: data.status
            },
            () => {

                this.initAfterSetElement();
            }
        );
    }

    initBeforeSetElement() {
        if (this.props.viewMode !== 'NEW') {
            this.service
                .getProjectsRelatedToTools(this.state.elementId)
                .then((data) => {
                    this.setState({
                        assignedProjects: data
                    });
                }).catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy projektów');
                })
        }
        this.enumService
        .getEnumList('LogEntryCode')
        .then((logEntryCodes) =>
            this.setState({
                logEntryCodes,
            })
        )
        .catch(() => {
            this.showErrorMessage('Nie udało się pobrać listy typów zdarzeń');
        });
    }


    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            this.blockUi(this.handleValidForm);
        } else {
            this.validator.showMessages();
            this.scrollToError = true;
            this.forceUpdate();
        }

    };

    prepareFooterItems() {
        const result = super.prepareFooterItems();
        const { backUrl, cancelUrl, viewMode, editUrl } = this.props;
        const { element } = this.state;
        let goBackUrl;
        if (viewMode === 'NEW') {
            goBackUrl = backUrl;
        } else {
            goBackUrl = `${cancelUrl}/${element.id}`;
        }

        switch (viewMode) {
            case 'EDIT':
            case 'NEW':
                break;
            case 'VIEW':
                    if(element.status !== 'DELETED'){
                    result.push(
                        {label: 'Usuń', className: 'fullred float-right', onClick: this.showDeleteDialog},
                        { label: 'Edytuj', className: 'float-right', href: `${editUrl}/${element.id}` }
                      )
                    }
                break;
            default:
                break;


        }
        return result;
    }

    createOrUpdate() {
        const { element } = this.state;
        this.scrollToTop();
        if (this._isMounted) {

            if (element.id === undefined || element.id === null) {
                //element.toolList = this.state.toolList;
                this.service
                    .add(element)
                    .then((response) => {
                        this.afterAddSuccess(response);
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                    });
            } else {
                element.toolList = element.toolList.map(tool => {
                    if (tool.toolkit == null) {
                        tool.toolkit = {
                            id: element.id
                        }
                    }
                    return tool;
                });

                this.service
                    .update(element)
                    .then((response) => {
                        this.state.toolsToDelete = [];
                        this.afterUpdateSuccess(response);
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                        this.unblockUi();
                    });
            }
        }
    }

    isPdfButtonRendered() {
        return false;
    }

    getPdfDownloadLabel() {
        return null;
    }

    handleDownloadPdf() {
        // this.setState({ downloadingPdf: true},
        //     () => {
        //         setTimeout(() => {
        //             if (this.state.downloadingPdf) {
        //                 document.getElementById('pdf-download-spinner').style.display = 'block';
        //             }
        //         }, 500);

        //         this.service.exportInvoice(this.state.elementId)
        //             .then((blob) => {
        //                 this.setState({ downloadingPdf: false });
        //                 const now = moment().format('YYYYMMDD_HHmmss');
        //                 const fileName = `Faktura_${now}.pdf`;
        //                 saveAs(blob, fileName);
        //             })
        //             .catch(() => {
        //                 this.setState({ downloadingPdf: false });
        //                 this.showErrorAboutMissingPdf('faktury');
        //             });
        //     });

    }



    toolLabel(tool) {
        let label = '';

        if (tool.type) {
            label += tool.type.typeName + ' ';
        }
        if (tool.producer) {
            label += tool.producer.name + ' ';
        }

        if (tool.serialNumber) {
            label += tool.serialNumber + ' ';
        }

        return label;
    }

    searchTools(query, toolId) {
        //removing already defined
        let toolIds = this.state.element?.toolList.filter(x => x != undefined).map(t => t.id).filter(id => toolId != id);
        this.toolService
            .searchTools(query)
            .then(filteredTools => this.setState({ filteredTools: filteredTools.filter(e => !toolIds.includes(e.id)) }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy narzędzi');
            });
    }
    renderVal(e){
        return e;
    }

    renderDetails() {
        const { t } = this.props;
        const { element } = this.state;
        const toolList=element.toolList;
        let codeOptions=this.state.logEntryCodes.filter(
            (x)=>
             x.enumValue.includes('CREATE_TOOLS')   
         ||  x.enumValue.includes('UPDATE_TOOLS')
         ||  x.enumValue.includes('DELETE_TOOLS')
         ||  x.enumValue.includes('TOOLS_OWNER_ASSIGNED')
         ||  x.enumValue.includes('TOOLS_OWNER_UNASSIGNED'));
        
        return (
            <React.Fragment>

                <div className='row profile'>

                    <InputTextComponent
                        id='name'
                        name='name'
                        label={t('tools.name')}
                        colClass='col-md-4'
                        value={element.name}
                        validators='required|max:150'
                        validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />

                    <InputTextareaComponent
                        id='note'
                        name='note'
                        label={t('tools.note')}
                        colClass='col-md-4'
                        value={element.note}
                        //validators='required|max:150'
                        //validator={this.validator}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='status'
                        name='status'
                        label={t('tools.status')}
                        colClass='col-md-2'
                        value={element.status}
                        onChange={this.handleChange}
                        renderValue={(e)=>this.statusTemplate(element.status)}
                        disabled={this.props.viewMode==='EDIT' ||  this.props.viewMode==='VIEW'}
                        rendered={this.props.viewMode==='EDIT' ||  this.props.viewMode==='VIEW'}
                        viewMode={this.props.viewMode}
                    />

                </div>


                <DivContainer colClass='row'>
                    <DivContainer colClass='col-md-12'>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })} >
                            <TabPanel header="Lista narzędzi">
                            <Card style={{ border: "none" }}>
                                <Card header='Narzędzia'>
                                    <DivContainer colClass='row'
                                        rendered={true}
                                    >

                                        {toolList==undefined  ? null :
                                        this.props.viewMode !== 'VIEW' ?
                                            toolList.map((tool, rowIndex) => {
                                                return (

                                                    <div>
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <InputAutocompleteComponent
                                                                    id={`tool${rowIndex}`}
                                                                    label={t('project.details.tool')}
                                                                    value={tool}
                                                                    name="array"
                                                                    dropdown
                                                                    forceSelection
                                                                    viewMode={this.props.viewMode}
                                                                    validator={this.validator}
                                                                    validators='required'
                                                                    showLabel={false}
                                                                    onChange={this.handleChange}
                                                                    insideTable
                                                                    filteredList={this.state.filteredTools}
                                                                    filterList={(query) => this.searchTools(query, tool?.id)}
                                                                    field="name"
                                                                    stateField={`element.toolList[${rowIndex}]`}
                                                                    minLength={3}
                                                                    placeholder={t('search.tool')}
                                                                />
                                                            </div>
                                                            <div className="col-md-2">
                                                                <ActionLink
                                                                    iconName='mdi-delete'
                                                                    iconSide='left'
                                                                    iconSize='icon-large'
                                                                    rendered={this.props.viewMode != 'VIEW'}
                                                                    handleClick={(e) => {

                                                                        let tools = this.state.element.toolList.filter(t => t != tool);

                                                                        this.setState((prevState) => ({
                                                                            element: {
                                                                                ...prevState.element,
                                                                                toolList: tools,
                                                                            },
                                                                        }));
                                                                    }}
                                                                    variant='blue'
                                                                    className='edit-blue-link'
                                                                    key={'delete-button'}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                );
                                            })
                                            :
                                            <DivContainer colClass='row'>
                                                <DataTable
                                                 value={toolList==undefined ? []  : toolList}
                                                 responsiveLayout="scroll"
                                                 emptyMessage={t('tools.emptyToolTable')}
                                                 >
                                                    <Column
                                                     field="serialNumber"
                                                     header="Numer seryjny"
                                                     body={(e) => e.serialNumber}
                                                     style={{
                                                        width: '150px'
                                                    }}
                                                     >
                                                     </Column>
                                                     <Column
                                                     field="graverserialNumber"
                                                     header="Grawer"
                                                     body={(e) => e.graver}
                                                     style={{
                                                        width: '150px'
                                                    }}
                                                     >
                                                     </Column>
                                                    <Column
                                                      field="type"
                                                      header="Typ"
                                                      body={(e) => {
                                                        return e.type ? e.type.typeName : null;
                                                       }}
                                                      >
                                                      </Column>
                                                    <Column
                                                     field="producer"
                                                     header="Producent"
                                                     body={(e) => {
                                                        return e.producer ? e.producer.name : null;
                                                       }}>
                                                    </Column>
                                                    <Column
                                                     field="model"
                                                     header="Model"
                                                     body={(e) => {
                                                        return e.model ? e.model.modelIdentifier : null;
                                                    }}
                                                     >
                                                     </Column>
                                                    <Column
                                                     field="status"
                                                     header="Status"
                                                     body={(e) => this.statusTemplate(e.status)}>
                                                     </Column>
                                                    {/* {this.props.viewMode != 'VIEW' ? (
                                                    <Column header="" body={(e) => this.projectTemplate(e)}></Column>
                                                ) : null} */}
                                                </DataTable>
                                            </DivContainer>
                                        }
                                    </DivContainer>
                                    <DivContainer
                                        rendered={this.props.viewMode != 'VIEW'}
                                    >
                                        <ActionLink
                                            label="Dodaj narzędzie"
                                            iconName='mdi-plus'
                                            iconSide='left'
                                            iconSize='icon-large'
                                            variant='blue'
                                            className='float-right'
                                            key={'add-activity-button'}
                                            handleClick={this.handleAddNewTools.bind(this)}
                                        />
                                    </DivContainer>

                                </Card>
                            </Card>
                            </TabPanel>
                            {this.props.viewMode === 'NEW' ? null :
                                <TabPanel header="Lista projektów">

                                    <Card style={{ border: "none" }}>
                                        <Card header='Projekty'>
                                            <DivContainer colClass='row'>
                                                <DataTable
                                                value={this.state.assignedProjects}
                                                responsiveLayout="scroll"
                                                emptyMessage={t('tools.emptyProjectTable')}
                                                >
                                                    <Column field="name" header="Nazwa projektu" body={(e) => e.name}></Column>
                                                    <Column field="startDate" header="Data rozpoczęcia projektu" body={(e) => e.startDate}></Column>
                                                    <Column field="endDate" header="Data zakończenia projektu" body={(e) => e.endDate}></Column>
                                                    {/* {this.props.viewMode != 'VIEW' ? (
                                                    <Column header="" body={(e) => this.projectTemplate(e)}></Column>
                                                ) : null} */}
                                                </DataTable>
                                            </DivContainer>
                                            <DivContainer>
                                            </DivContainer>
                                        </Card>
                                    </Card>
                                </TabPanel>
                            }
                            {this.props.viewMode != 'NEW' ? (
                                <TabPanel header={t('project.details.logsTabHeader')}>
                                    <LogEntryListContainer
                                        objectType='TOOLS'
                                        objectId={this.props.elementId}
                                        expandFilters={false}
                                        renderHeader={false}
                                        filter={true}
                                        filterBy={'TOOL'}
                                        filterOptions={true}
                                        filteredOptionsList={codeOptions}
                                    />
                                </TabPanel>
                            ) : null}
                        </TabView>
                    </DivContainer>
                </DivContainer>
                {this.state.showDeleteDialog ?
                    <YesNoDialog
                    visible={this.state.showDeleteDialog}
                    header={t('tools.deleteHeader')}
                    name='visible'
                    onChange={(type, x, target) => {
                        if (target.value) {
                        
                            this.deleteToolkit();
                        } else {
                            this.setState({deleteConfirmDialogVisible: false})
                        }
                    }}
                    onHide={() => {
                        this.hideDeleteDialog();
                    }}
                     >
                        {t('tools.deleteConfirmation') + '\n' + this.state.element.name + " ?"}
                    </YesNoDialog>
                 :
                 null}
            </React.Fragment>
        )
    }
        statusTemplate(field) {
            if (field === 'ACTIVE') {
                return 'Aktywny'
            } else if (field === 'DELETED') {
                return 'Usunięty'
            }
        }


    handleAddNewTools() {
        const tools = this.state.element.toolList;
        tools.push(null);
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                toolList: tools
            },
        }));
    }


}

ToolsDetailsContainer.defaultProps = {
    backUrl: '/#/tools-list',
    cancelUrl: '/#/tools/details',
    editUrl: '/#/tools/edit',
};

ToolsDetailsContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(ToolsDetailsContainer);
