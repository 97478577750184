import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import ActionLink from '../components/ActionLink';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import SettingService from '../services/SettingService';
import ApplicationSettingsEditDialog from './ApplicationSettingsEditDialog';

class ApplicationSettingsListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new SettingService());
        this.state = {
            dialogVisibile: false,
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            settingParameterTypeOptions: [],
        };
        this.handleOpenEditDialog = this.handleOpenEditDialog.bind(this);
    }

    updateSearchCriteria(criteria) {
        return {
            type: criteria.type,
            active: criteria.active,
            id: criteria.id,
            entityUuid: criteria.entityUuid,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCriteriaName() {
        return 'setting-list-sc';
    }

    getContainerListName() {
        return 'setting-list-container';
    }
    getCleanSearchCriteria() {
        return {
            type: null,
            active: true,
            id: null,
            entityUuid: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        this.service
            .getSettingTypes()
            .then((settingParameterTypeOptions) =>
                this.setState({
                    settingParameterTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy parametrów');
            });
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaDropdownComponent
                    id='type-sc'
                    name='type'
                    showClear
                    label={'Nazwa'}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.type}
                    options={this.state.settingParameterTypeOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
            </div>
        );
    }
    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;

        return (
            <ActionLink
                iconName='mdi-pencil'
                iconSide='left'
                iconSize='icon-large'                
                handleClick={(e) => this.handleOpenEditDialog(e, this.cloneElement(rowData))}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
        );
    }

    handleOpenEditDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: rowData, dialogViewMode: 'EDIT'});
    }

    prepareColumns() {
        return [
            {field: 'id', header: 'ID', sortable: true, width: '90px'},
            {field: 'type', header: 'Nazwa', sortable: false, body: this.enumTemplate.bind(this, 'type')},
            {field: 'value', header: 'Wartość', sortable: false},
            {
                key: 'actions',
                header: 'Szczegóły',
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    //override
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        }
        return '';
    }

    prepareHeaderItems() {
        return [];
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/setting-list'>{'Parametry aplikacji'}</BreadcrumbsItem>
                {this.renderView()}
                {this.state.dialogVisibile ? (
                    <ApplicationSettingsEditDialog
                        key={`settings-dialog-${this.state.element?.id}`}
                        element={this.state.element}
                        onHide={(e) => this.setState({dialogVisibile: false})}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        //roleTypes={this.state.roleTypeOptions}
                        afterSave={(e) => {
                            this.setState({dialogVisibile: false});
                            this.readMessage();
                            this.refreshTable();
                            this.unblockUi();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
            </div>
        );
    }
}

ApplicationSettingsListContainer.defaultProps = {
    detailUrl: '/#/setting/details',
    newUrl: '/#/setting/create',
    lazy: true,
};

ApplicationSettingsListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default ApplicationSettingsListContainer;
