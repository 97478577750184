import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class ProjectService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'project';
    }
    getCriteria(criteria) {
        
        return this.objToQueryString({
            name: criteria.name,
            clientName: criteria.clientName,
            dateFrom: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateTo: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            status: criteria.status?.enumValue,
            countryName: criteria.countryName,
            categories: criteria.categories ? criteria.categories.map((category) => category.id).join(',') : null,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            externalSupervisor: criteria.externalSupervisor?.id,
            projectManager: criteria.projectManager?.id,
            supervisor: criteria.supervisor?.id,
            accommodation: criteria.accommodation?.id
        });
    }

    normalizeProject(e) {
        if (e.dateStart) {
            e.dateStart = moment(e.dateStart).format(Constants.DATE_JSON_FORMAT);
        }
        if (e.dateEnd) {
            e.dateEnd = moment(e.dateEnd).format(Constants.DATE_JSON_FORMAT);
        }
        if (e.client) {
            this.keepOnlyId(e.client);
        }
        if (e.users) {
            e.users.forEach(user => {
                this.keepOnlyId(user.employee);
                if (user.dateStart) {
                    user.dateStart = moment(user.dateStart).format(Constants.DATE_JSON_FORMAT);
                }
                if (user.dateEnd) {
                    user.dateEnd = moment(user.dateEnd).format(Constants.DATE_JSON_FORMAT);
                }
            });
        }
        if(e.projectCars){
            e.projectCars.forEach(pc => {
                pc.project={
                    id: e.id
                };
                if (pc.dateStart) {
                    pc.dateStart = moment(pc.dateStart).format(Constants.DATE_JSON_FORMAT);
                }
                if (pc.dateEnd) {
                    pc.dateEnd = moment(pc.dateEnd).format(Constants.DATE_JSON_FORMAT);
                }
            });
        }
        if (e.managers) {
            e.managers.forEach(manager => {
                this.keepOnlyId(manager.employee);
                if (manager.dateStart) {
                    manager.dateStart = moment(manager.dateStart).format(Constants.DATE_JSON_FORMAT);
                }
                if (manager.dateEnd) {
                    manager.dateEnd = moment(manager.dateEnd).format(Constants.DATE_JSON_FORMAT);
                }
            });
        }
        if (e.categories) {
            e.categories.forEach((category) => this.keepOnlyId(category));
        }
        if (e.activities) {
            e.activities.forEach((activity) => this.keepOnlyId(activity.activity));
        }
        if (e.cars) {
            e.cars.forEach((car) => {
                this.keepOnlyId(car);
            });
        }
        if (e.tools) {
            e.tools.forEach((tools) => {
                this.keepOnlyId(tools.tools);
                this.keepOnlyId(tools.car);
                this.keepOnlyId(tools.owner);
            });
        }
        if (e.projectManager) {
            this.keepOnlyId(e.projectManager);
        }
        if (e.externalSupervisor) {
            this.keepOnlyId(e.externalSupervisor);
        }
        if (e.supervisor) {
            this.keepOnlyId(e.supervisor);
        }
        return e;
    }

    add(element) {
        const e = this.normalizeProject(this.cloneElement(element));
        return super.add(e);
    }

    update(element) {
        const e = this.normalizeProject(this.cloneElement(element));
        return super.update(e);
    }

    closeProject(projectId) {
        return this.fetch(`${this.domain}/${this.path}/${projectId}/close`, {
            method: 'PATCH',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    calculateProjectSummary(projectId) {
        return this.fetch(`${this.domain}/${this.path}/${projectId}/summary/calculate`, {
            method: 'PATCH',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getExtraWorkAggregation(projectId) {
        return this.fetch(`${this.domain}/${this.path}/${projectId}/extraWorkAggregation`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getWorkCostAggregation(projectId) {
        return this.fetch(`${this.domain}/${this.path}/${projectId}/workCostAggregation`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    } 
    getCars(projectId) {
        return this.fetch(`${this.domain}/${this.path}/${projectId}/projectCars`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }  
    getActivities(projectId) {
        return this.fetch(`${this.domain}/${this.path}/${projectId}/projectActivities`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    } 
    getDrivers(projectId, date) {
        return this.fetch(`${this.domain}/${this.path}/${projectId}/possibleDrivers/${moment(date).format(Constants.DATE_GET_REQUEST_FORMAT)}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }  
    getEmployeesOnReportedDayDate(projectId, reportedDayDate) {
        return this.fetch(`${this.domain}/${this.path}/${projectId}/${moment(reportedDayDate).format(Constants.DATE_JSON_FORMAT)}/projectEmployees/on-reported-day-date`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    } 
    getDriveCostAggregation(projectId) {
        return this.fetch(`${this.domain}/${this.path}/${projectId}/driveCostAggregation`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getCriteriaWithStatus(criteria) {
        return this.objToQueryString({
            name: criteria.name,            
            status: criteria.status,            
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
    search(query, status, categories, withoutLeder, date) {
        let url = `${this.domain}/${this.path}/search`;
        let nextCharset = "?";
        const params = [];
        if (query) {
            params.push('query=' + encodeURIComponent(query));
        }
        if (status) {
            params.push('status=' + status)
        }
        if (categories && categories.length > 0) {
            params.push('categories=' + categories.map(c => c.id).join(","));
        }
        if (withoutLeder === true) {
            params.push('withoutLeader=true');
        }        
        if (date) {
            params.push('date=' + moment(date).format(Constants.DATE_GET_REQUEST_FORMAT));
        }
        if (params.length > 0) {
            url += '?' + params.join('&');
        }

        return this.fetch(`${url}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getProjects() {
        return this.fetch(`${this.domain}/${this.path}/dashboard-list`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getProjectDashboard(criteria) {
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/dashboard${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getEmployeeProjectPlaningStatistics(employeeId, projectId, dateStart, dateEnd, userType) {
        //userType: true: user, false: manager

        let url = `${this.domain}/${this.path}/employee/statistics/${employeeId}`;
        const params = {};
        if (projectId) {
            params.projectId = projectId;
        }
        if (dateStart) {
            params.dateStart = moment(dateStart).format(Constants.DATE_GET_REQUEST_FORMAT);
        }
        if (dateEnd) {
            params.dateEnd = moment(dateEnd).format(Constants.DATE_GET_REQUEST_FORMAT);
        }

        let paramUrl;
        params.userType = userType;
        for (const paramName in params) {
            const paramValue = params[paramName];
            if (paramUrl) {
                paramUrl += '&';
            } else {
                paramUrl = '?';
            }
            paramUrl += paramName + '=' + paramValue;
        }
        url += paramUrl;
        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getUsersTimeline(userIsManager, projectQuery, userQuery, projectCategoryIds, countryIds) {
        let url = `${this.domain}/${this.path}/users-timeline?userIsManager=`;
        url += userIsManager === true ? 'true' : 'false';
        if (projectQuery && projectQuery.length >= 3) {
            url += '&project=' + projectQuery;
        }
        if (userQuery && userQuery.length >= 3) {
            url += '&user=' + userQuery;
        }
        if (projectCategoryIds) {
            url += '&projectCategoryIds=' + projectCategoryIds;
        }
        if (countryIds) {
            url += '&countryIds=' + countryIds;
        }
        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    unassignManagerFromProject(employeeId, projectId, assigmentId) {
        const url = `${this.domain}/${this.path}/unassign-manager/${projectId}/${employeeId}/${assigmentId}`;
        return this.fetch(url, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    assignManagerFromProject(employeeId, projectId, dateStart, dateEnd) {
        const url = `${this.domain}/${this.path}/assign-manager/${projectId}/${employeeId}`;
        return this.fetch(url, {
            method: 'PUT',
            body: JSON.stringify({
                dateStart: moment(dateStart).format(Constants.DATE_GET_REQUEST_FORMAT),
                dateEnd: moment(dateEnd).format(Constants.DATE_GET_REQUEST_FORMAT),
            })
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    assignEmployeeToProject(employeeId, projectId, dateStart, dateEnd){
        const url = `${this.domain}/${this.path}/assign-employee/${projectId}/${employeeId}`;
        return this.fetch(url, {
            method: 'PUT',
            body: JSON.stringify({
                dateStart: moment(dateStart).format(Constants.DATE_GET_REQUEST_FORMAT),
                dateEnd: moment(dateEnd).format(Constants.DATE_GET_REQUEST_FORMAT),
            })
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    fetchUsersProjects(id,dateStart,dateEnd) {
        const url = `${this.domain}/${this.path}/employees/${id}/projects?dateStart=${moment(dateStart).format(Constants.DATE_GET_REQUEST_FORMAT)}
        &dateEnd=${moment(dateEnd).format(Constants.DATE_GET_REQUEST_FORMAT)}`;
        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    exportList(criteria, format){
        const params=this.objToQueryString({
            name: criteria.name,
            clientName: criteria.clientName,
            dateFrom: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateTo: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            status: criteria.status?.enumValue,
            countryName: criteria.countryName,
            categories: criteria.categories ? criteria.categories.map((category) => category.id).join(',') : null,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            externalSupervisor: criteria.externalSupervisor?.id,
            projectManager: criteria.projectManager?.id,
            supervisor: criteria.supervisor?.id,
            export_type: format
        });

		return this.fetch(`${this.domain}/${this.path}/export${params}`,
        {
            method: 'POST'
        },
        {
            'Content-Type': 'application/json',
            Accept: 'application/octet-stream'
        }
		).then((res) => {
			return Promise.resolve(res);
		}).catch((err) => {
			throw err;
		});
    }
}
