import {Card} from 'primereact/card';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import DivContainer from '../../components/DivContainer';
import BaseDialogContainer from '../../baseContainers/BaseDialogContainer';
import SimpleReactValidator from '../../components/validator';
import SettingService from '../../services/SettingService';
import ActionButton from '../../components/ActionButton';
import InputPasswordComponent from '../../components/inputs/InputPasswordComponent';
import AccountService from '../../services/AccountService';

class ProfileChangePasswordDialog extends BaseDialogContainer {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            oldPassword: '',
            repeatPassword: '',
            maxLength: 15,
            minLength: 5,
            lowerCaseCount: 3,
            upperCaseCount: 4,
            numbersCount: 0,
            specialCharactersCount: 0,
            showConfirmDialog: false,
            viewMode: this.props.viewMode,
            activeIndex: 0,
        };
        this.validator = new SimpleReactValidator({}, this.translate);
        this.service = new AccountService();
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    renderFooter() {
        return this.prepareFooter();
    }

    initBeforeSetElement() {
        const service = new SettingService();
        service.getPasswordParamsPublic().then((resp) => {
            this.setState({
                maxLength: resp['PASSWORD_MAX_LENGTH'],
                minLength: resp['PASSWORD_MIN_LENGTH'],
                lowerCaseCount: resp['PASSWORD_LOWERCASE_COUNT'],
                upperCaseCount: resp['PASSWORD_UPPERCASE_COUNT'],
                numbersCount: resp['PASSWORD_NUMBERS_COUNT'],
                specialCharactersCount: resp['PASSWORD_SPECIAL_CHARS_COUNT'],
            });
        });
    }
    handleChange(e) {
        console.log(e);
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleFormSubmit() {
        if (this.validator.allValid()) {
            this.blockUi();
            this.service
                .changePassword({userId: this.props.userId, newPassword: this.state.newPassword, oldPassword: this.state.oldPassword})
                .then(() => {
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                })
                .catch((err) => {
                    this.showErrorMessage(err.message);
                    this.unblockUi();
                });
        } else {
            this.validator.showMessages();
            this.setState({
                valid: false,
            });
        }
    }

    prepareFooter() {
        return (
            <React.Fragment>
                <hr className='line-footer' />
                <div className='footer-dialog-button-container'>
                    <div className='float-right'>
                        <ActionButton label={'Zmień hasło'} variant='button save-button blue' handleClick={this.handleFormSubmit} />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>Zmiana hasła</label>
                </DivContainer>
            </DivContainer>
        );
    }

    getContainerListName() {
        return 'manage-account';
    }

    getAddSucces() {
        return 'Zmieniono hasło';
    }

    renderDetails() {
        return this.renderView();
    }

    renderView() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputPasswordComponent
                        id='oldPassword'
                        label={'Stare hasło'}
                        labelForValidator='Stare hasło'
                        name='oldPassword'
                        value={this.state.oldPassword}
                        validator={this.validator}
                        onChange={(a, b, c) => this.handleChange(c)}
                        viewMode='EDIT'
                        validators='required'
                        colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12'
                        feedback={false}
                    />
                    <InputPasswordComponent
                        id='newPassword'
                        label={this.getPasswordLabel()}
                        labelForValidator='Nowe hasło'
                        name='newPassword'
                        value={this.state.newPassword}
                        validator={this.validator}
                        onChange={(a, b, c) => this.handleChange(c)}
                        viewMode='EDIT'
                        validators={`required|extended_password:${this.state.minLength}:${this.state.maxLength}:${this.state.lowerCaseCount}:${this.state.upperCaseCount}:${this.state.numbersCount}:${this.state.specialCharactersCount}`}
                        colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12'
                    />
                    <InputPasswordComponent
                        id='repeatPassword'
                        label='Powtórz nowe hasło:'
                        name='repeatPassword'
                        value={this.state.repeatPassword}
                        validator={this.validator}
                        onChange={(a, b, c) => this.handleChange(c)}
                        viewMode='EDIT'
                        validators={`required|equals:${this.state.newPassword}:Nowe hasło`}
                        colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12'
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
    getPasswordLabel() {
        let message =
            'Pole nowe hasło musi się składać z od :min do :max znaków, zawierać co najmniej :lower i :upper, :number oraz :special spośród =!@#$%^&*(){}[]|:";\'<>?,./';
        message = message.replace(':min', this.state.minLength);
        message = message.replace(':max', this.state.maxLength);
        message = message.replace(':lower', this.validator.declination(this.state.lowerCaseCount, 2));
        message = message.replace(':upper', this.validator.declination(this.state.upperCaseCount, 3));
        message = message.replace(':number', this.validator.declination(this.state.numbersCount, 4));
        message = message.replace(':special', this.validator.declination(this.state.specialCharactersCount, 5));
        return message;
    }
}

ProfileChangePasswordDialog.defaultProps = {
    currentUser: undefined,
    visible: false,
    stationId: undefined,
    backUrl: '/#/manage-account/details',
    manageUrl: '/#/manage-account',
};

ProfileChangePasswordDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
};

export default withTranslation()(ProfileChangePasswordDialog);
