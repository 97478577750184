import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../../../baseContainers/BaseDialogContainer';
import {ActionButton} from '../../../components/ActionButton';
import DivContainer from '../../../components/DivContainer';
import InputNumberComponent from '../../../components/inputs/InputNumberComponent';
import InputCalendarComponent from '../../../components/inputs/InputCalendarComponent';
import InputDropdownComponent from '../../../components/inputs/InputDropdownComponent';
import InputTextareaComponent from '../../../components/inputs/InputTextareaComponent';
import ProjectExpenseService from '../../../services/ProjectExpenseService';
import {t} from 'i18next';
import moment from 'moment';
import Constants from '../../../utils/constants';
import ProjectService from '../../../services/ProjectService';

class ProjectExpensesDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new ProjectExpenseService());
        this.state = {
            element: this.props.element,
            viewMode: this.props.viewMode,
            typeOptions: undefined,
            loading: false,
        };
        this.projectService =  new ProjectService();
    }
    translate(key) {
        return t(key);
    }

    initBeforeSetElement() {
        this.enumService
            .getEnumList('ExpenseType')
            .then((typeOptions) => {
                this.setState({
                    typeOptions,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów');
            });
    }
    prepareFooter() {
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide();
                    }}
                />
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('global.addButton') : t('global.saveButton')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.viewMode !== 'DELETE'}
                />
            </div>
        );
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('expense.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('expense.editHeader')}`
                            : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    prepareObjectToSave(element) {
        const modifiedElement = structuredClone(element);
        modifiedElement.date = moment(modifiedElement.date).format(Constants.DATE_GET_REQUEST_FORMAT_TIME);
        return modifiedElement;
    }

    handleValidForm() {
        const {element} = this.state;
        const modifiedElement = this.prepareObjectToSave(element);
        this.scrollToTop();
        this.blockUi();
        if (this._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(modifiedElement)
                    .then((project) => { 
                        this.showSuccessMessage(t('expense.modifySuccess'));
                        this.props.onProjectChanged(project);
                        this.unblockUi();
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                        this.unblockUi();
                    });
            } else {
                this.service
                    .update(modifiedElement)
                    .then((project) => { 
                        this.showSuccessMessage(t('expense.addSuccess'));
                        this.props.onProjectChanged(project);
                        this.unblockUi();
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000); 
                        this.unblockUi();
                    });
            }
        }
    }


    renderDetails() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputCalendarComponent
                        id={'date'}
                        name={'date'}
                        label={t('expense.date')}
                        value={this.state.element.date}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                        showLabel
                        showIcon
                        rendered
                        validator={this.validator}
                        validators={'required'}
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                    />
                    <InputNumberComponent
                        id='value'
                        name='value'
                        label={t('expense.value')}
                        value={this.state.element.value.value}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                        stateField='element.value'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                        validator={this.validator}
                        validators='required'
                        mode='currency'
                        currency='EUR'
                        locale='pl-PL'
                    />
                    <InputDropdownComponent
                        id='type'
                        name='type'
                        label={t('expense.type')}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                        value={this.state.element.type}
                        validator={this.validator}
                        options={this.state.typeOptions}
                        viewMode={'EDIT'}
                        onChange={this.handleChange}
                        optionLabel='label'
                        dataKey='enumValue'
                        validators='required'
                        rendered={true}
                        valueView={this.state.element.type ? this.state.element.type.label : ''}
                    />

                    <InputTextareaComponent
                        id='description'
                        name='description'
                        label={t('Opis')}
                        colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'
                        value={this.state.element.description}
                        validator={this.validator}
                        viewMode={'EDIT'}
                        validators='not_required|max:4096'
                        onChange={this.handleChange}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

ProjectExpensesDialog.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
    visible: false,
};

ProjectExpensesDialog.propTypes = {
    backUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    onAfterAdd: PropTypes.func,
    onAfterUpdate: PropTypes.func,
    onProjectChanged: PropTypes.func,
};

export default withTranslation()(ProjectExpensesDialog);
