/* eslint-disable react/jsx-max-props-per-line */
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import MenuItemIcon from './MenuItemIcon';
export const ActionLink = (props) => {
    const {
        alt,
        className,
        colClass,
        disabled,
        downloadFile,
        handleClick,
        href,
        iconSize,
        iconColor,
        iconName,
        iconSide,
        iconSvg,
        id,
        inputLikeStyle,
        label,
        openInNewTab,
        params,
        rendered,
        size,
        title,
        variant,
        t,
    } = props;
    let ariaLabel = '';
    if (openInNewTab) {
        if (!!t && t instanceof Function) {
            ariaLabel = t('aria.openInNewTab');
        } else {
            ariaLabel = 'kliknięcie linku spowoduje otworzenie nowej karty w przeglądarce';
        }
    }
    if (downloadFile) {
        if (!!t && t instanceof Function && ariaLabel) {
            ariaLabel = `${ariaLabel} ${t('aria.downloadFile')}`;
        } else if (!!t && t instanceof Function) {
            ariaLabel = t('aria.downloadFile');
        } else {
            ariaLabel = 'kliknięcie linku spowoduje pobranie pliku';
        }
    }
    if (rendered) {
        if (inputLikeStyle) {
            return (
                <div className={colClass}>
                    <div className={'row'}>
                        <div className={'col-md-12'}>
                            <label className='p-label' style={{width: '100%', margin: '0px'}} />
                            {
                                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                <a
                                    rel='noopener noreferrer'
                                    tabIndex='0'
                                    className={`${className !== undefined ? className : 'p-link'} ${
                                        iconName !== undefined ? 'link-icon' : ''
                                    } ${variant} ${size}`}
                                    href={disabled ? undefined : href ? href : undefined}
                                    role={disabled ? undefined : href ? href : 'button'}
                                    onClick={(e) => (disabled || !handleClick ? false : handleClick(e, params))}
                                    id={id}
                                    title={`${title !== undefined ? title : alt !== undefined ? alt : ''}${ariaLabel}`}
                                    key={id === undefined ? `actionLink-${label}` : id}
                                    target={openInNewTab ? '_blank' : undefined}
                                >
                                    <span
                                        className={`${iconName !== undefined ? 'icon_text' : ''} p-button-text p-c ${
                                            iconName !== undefined ? iconColor : ''
                                        }`}
                                    >
                                        {iconSide === 'left' && iconSvg !== undefined ? (
                                            <MenuItemIcon alt='' className={`icon-svg ${iconSize}`} iconSvg={iconSvg} />
                                        ) : null}
                                        {iconSide === 'left' && iconName !== undefined ? (
                                            <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                        ) : null}
                                        {label}
                                        {iconSide === 'right' && iconName !== undefined ? (
                                            <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                                        ) : null}
                                        {iconSide === 'right' && iconSvg !== undefined ? (
                                            <MenuItemIcon alt='' className={`icon-svg ${iconSize}`} iconSvg={iconSvg} />
                                        ) : null}
                                    </span>
                                </a>
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                    rel='noopener noreferrer'
                    tabIndex='0'
                    className={`${className !== undefined ? className : 'p-link'} ${
                        iconName !== undefined ? 'link-icon' : ''
                    } ${variant} ${size} ${disabled ? 'p-disabled disabled' : ''}`}
                    href={disabled ? undefined : href ? href : undefined}
                    role={disabled ? undefined : href ? href : 'button'}
                    onClick={(e) => (handleClick ? handleClick(e, params) : false)}
                    id={id}
                    title={`${title !== undefined ? title : alt !== undefined ? alt : ''}${ariaLabel}`}
                    key={id === undefined ? `actionLink-${label}` : id}
                    target={openInNewTab ? '_blank' : undefined}
                >
                    <span
                        className={`${iconName !== undefined ? 'icon_text' : ''} p-button-text p-c ${
                            iconName !== undefined ? iconColor : ''
                        }`}
                    >
                        {iconSide === 'left' && iconSvg !== undefined ? (
                            <MenuItemIcon alt='' className={`icon-svg ${iconSize}`} iconSvg={iconSvg} />
                        ) : null}
                        {iconSide === 'left' && iconName !== undefined ? <i alt='' className={`icon mdi ${iconName} ${iconSize}`} /> : null}
                        {label}
                        {iconSide === 'right' && iconName !== undefined ? (
                            <i alt='' className={`icon mdi ${iconName} ${iconSize}`} />
                        ) : null}
                        {iconSide === 'right' && iconSvg !== undefined ? (
                            <MenuItemIcon alt='' className={`icon-svg right ${iconSize}`} iconSvg={iconSvg} />
                        ) : null}
                    </span>
                </a>
            );
        }
    } else {
        return null;
    }
};

ActionLink.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    disabled: false,
    downloadFile: false,
    iconSide: 'right',
    inputLikeStyle: false,
    openInNewTab: false,
    rendered: true,
    params: {},
    size: 'none',
    variant: 'none',
};

ActionLink.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    colClass: PropTypes.string,
    disabled: PropTypes.bool,
    downloadFile: PropTypes.bool,
    handleClick: PropTypes.func,
    href: PropTypes.string,
    iconColor: PropTypes.string,
    iconLabel: PropTypes.string,
    iconName: PropTypes.string,
    iconSide: PropTypes.string,
    iconSize: PropTypes.string,
    id: PropTypes.string,
    inputLikeStyle: PropTypes.bool,
    label: PropTypes.string,
    openInNewTab: PropTypes.bool,
    params: PropTypes.object,
    rendered: PropTypes.bool,
    size: PropTypes.string,
    title: PropTypes.string,
    variant: PropTypes.string,
};

export default withTranslation()(ActionLink);
