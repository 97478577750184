import BaseServiceCrud from './BaseServiceCrud';

export default class ToolTypesService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'tool-types';
        //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
    getList(){
        return this.fetch(`${this.domain}/${this.path}/list`, {
			method: 'GET',
		}).then(res => {
			return Promise.resolve(res);
		});
    }
}