import BaseServiceCrud from './BaseServiceCrud';

export default class SubcontractorService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'subcontractor';
        //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,
            nip: criteria.nip,
            regon: criteria.regon,
            status: criteria.status?.enumValue,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
    getActiveContractors() {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/active-contractors`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    search(query) {
        let url = `${this.domain}/${this.path}/search`;
        if (query) {
            url += `?query=${query}`;
        }
        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
