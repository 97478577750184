import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import { t } from 'i18next';
import ActionLink from '../components/ActionLink';
import ToolsService from '../services/ToolsService';
import ToolsEditDialog from './ToolsEditDialog';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import EnumService from '../services/EnumService';

class ToolsListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ToolsService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dialogVisibile: false,
            element: this.initNewElement(),
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.statusTemplate=this.statusTemplate.bind(this);
        this.enumService = new EnumService();
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('ToolkitStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
    }

    initNewElement() {
        return {
            name: undefined,
        };
    }

    updateSearchCriteria(criteria) {
        return {
            onwerEmail: criteria.ownerEmail,
            name: criteria.name,
            firstResult: criteria.firstResult,
            maxResult: criteria.maxResult,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            name: '',
            onwerEmail: null,
            id: null,
            firstResult: 0,
            maxResult: 10,
            sortField: 'id',
            sortAsc: true,
            status: { enumValue: 'ACTIVE', label: 'Aktywny' }
        };
    }

    getCriteriaName() {
        return 'tools-list-sc';
    }

    getContainerListName() {
        return 'tools-list-container';
    }

    renderCriteria() {
        const {statusOptions} = this.state;
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='name'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.name}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Nazwa'}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={statusOptions}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Status'}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }
    actionTemplate(rowData) {
        const { detailUrl } = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <ActionLink
                iconName='mdi-eye'
                iconSide='left'
                iconSize='icon-large'
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
        );
    }

    handleOpenEditDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ dialogVisibile: true, element: this.cloneElement(rowData), dialogViewMode: 'EDIT' });
    }
    statusTemplate(element){
        if (element.status === 'ACTIVE') {
            return 'Aktywny'
        } else if (element.status === 'DELETED') {
            return 'Usunięty'
        }
    }

    prepareColumns() {
        return [
            { field: 'name', header: 'Nazwa', sortable: true },
            { field: 'note', header: 'Notatka', sortable: true },
            { field: 'status', header: 'Status', sortable: true, body: this.statusTemplate },
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO')
            ? [
                {
                    label: t('tools.newButton'),
                    type: 'BUTTON',
                    className: 'float-right',
                    variant: 'blue',
                    iconName: 'mdi-plus',
                    iconSide: 'left',
                    iconColor: 'white',
                    onClick: this.handleGoToNew,
                },
            ]
            : [];
    }
    handleOpenNewDialog(e) {
        let basicElement = this.initNewElement();
        if (e) {
            e.preventDefault();
        }
        this.setState({ dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW' });
    }

    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    render() {
        return (
            <div className='container-fluid tools-list'>
                <BreadcrumbsItem to='/tools-list'>{'Narzędzia'}</BreadcrumbsItem>
                {this.renderView()}
                {this.state.dialogVisibile ? (
                    <ToolsEditDialog
                        className='tools-dialog'
                        key={`tools-dialog-${this.state.element?.id}`}
                        element={this.state.element}
                        onHide={(e) => this.setState({ dialogVisibile: false })}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        afterSave={(e) => {
                            this.setState({ dialogVisibile: false });
                            this.afterObjectUpdate();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}

            </div>
        );
    }
}

ToolsListContainer.defaultProps = {
    detailUrl: '/#/tools/details',
    newUrl: '/#/tools/create',
    lazy: true,
};

ToolsListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default ToolsListContainer;
