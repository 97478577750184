import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import { t } from 'i18next';
import ActionLink from '../components/ActionLink';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import { Column } from 'primereact/column';
import NumberUtils from '../utils/NumberUtils';
import { DataTable } from 'primereact/datatable';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { Dropdown, Row } from 'react-bootstrap';
import DivContainer from '../components/DivContainer';
import { PrintButtonComponent } from '../report/ReportUtils';
import CriteriaMultiSelectComponent from '../components/criteria/CriteriaMultiSelectComponent';
import ProjectCumulativeReportService from '../services/ProjectCumulativeReportService';
import ProjectCategoryService from '../services/ProjectCategoryService';
import { Chip } from 'primereact/chip';
import { ColumnGroup } from 'primereact/columngroup';
import CountryService from '../services/CountryService';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';


class ProjectCumulativeReportListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ProjectCumulativeReportService());
        this.state = {
            list: [],
            loading: true,
            categories: [],
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dialogVisibile: false,
        };
        this.projectCategoryService = new ProjectCategoryService();
        this.countryService = new CountryService();
    }

    updateSearchCriteria(criteria) {
        return {
            projectName: criteria.projectName,
            clientName: criteria.clientName,
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            categories: criteria.categories,
            countries: criteria.countries
            //exportType: criteria.exportType,
        };
    }

    getCleanSearchCriteria() {
        return {
            clientName: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'month_',
            sortAsc: false,
            exportType: null,
            projectName: null,
            categories: [],
    
        };
    }

    getCriteriaName() {
        return 'projectCumulativeReport-list-sc';
    }

    getContainerListName() {
        return 'projectCumulativeReport-list-container';
    }
    getListTitle() {
        return 'projectCumulativeReport.listName';
    }

    initializeFromBackend(){
        this.projectCategoryService.getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
        .then(categories => this.setState({ categories: categories.content }))
        .catch(() => {
            this.showErrorMessage('Nie udało się pobrać listy kategorii');
        });

        this.countryService
        .getAll()
        .then((countries) => {
            this.setState({
                countries,
            });
        })
        .catch(() => {
            this.showErrorMessage('Nie udało się pobrać listy krajów');
        });
    }
    footerGroup() {
        const { summary } = this.state;
        return (
            <ColumnGroup>
                <Row>
                    <Column footer={t('list.summary')} />
                    <Column footer='' colSpan={4} footerStyle={{textAlign: 'right'}} />
                    <Column footer={this.formatTimeIfPossible(summary.clientTime)} footerStyle={{ textAlign: 'left', paddingRight: '16px' }} />
                </Row>
            </ColumnGroup>
        );
    }

    renderDataTable(columns, list, size) {
        const { criteria, first, loading } = this.state;
        if (list === null || list === undefined) {
            list = this.props.list ? this.props.list : this.state.list;
        }
        if (size === null || size === undefined) {
            size = this.props.list ? this.props.size : this.state.size;
        }
        const dynamicColumns = columns.map((col) => {
            return (
                <Column
                    selectionMode={col.selectionMode}
                    key={col.key ? col.key : col.field}
                    field={col.field}
                    filter
                    filterField={col.field}
                    showFilterMenu={false}
                    header={col.header}
                    body={col.body}
                    bodyClassName={col.bodyClassName}
                    headerClassName={col.headerClassName}
                    headerStyle={col.headerStyle}
                    className={col.className}
                    sortable={col.sortable}
                    sortField={col.sortField !== undefined ? col.sortField : col.field}
                    style={col.style}
                />
            );
        });
        const rowsPerPageOptions = [5, 10, 20, 50, 100];
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <div className='datatable-responsive'>
                        <DataTable
                            id='dataTable'
                            key='data-table'
                            emptyMessage={t('list.empty')}
                            className='p-datatable-responsive'
                            breakpoint='10px'
                            value={list}
                            paginator
                            footerColumnGroup={this.generateCustomFooterIfPossible()}
                            globalFilterFields={this.globalFilterFields()}
                            filters={this.state.filters}
                            // filterDisplay='row'
                            rows={criteria.maxResults}
                            totalRecords={size}
                            first={first}
                            rowsPerPageOptions={rowsPerPageOptions}
                            sortField={criteria.sortField}
                            sortOrder={criteria.sortAsc ? 1 : -1}
                            loading={loading}
                            paginatorPosition='bottom'
                            currentPageReportTemplate={
                                size !== 0
                                    ? `Pozycje od ${first + 1} do ${first + criteria.maxResults > size ? size : first + criteria.maxResults
                                    } z ${size} łącznie`
                                    : '0 pozycji'
                            }
                            paginatorTemplate={{
                                layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
                                PrevPageLink: (options) => {
                                    if (criteria.firstResult === 0) {
                                        return null;
                                    } else {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={options.onClick}
                                                disabled={options.disabled}
                                            >
                                                <span className='p-paginator-icon pi pi-angle-left'></span>
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                                NextPageLink: (options) => {
                                    if (criteria.firstResult + criteria.maxResults >= size) {
                                        return null;
                                    } else {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={options.onClick}
                                                disabled={options.disabled}
                                            >
                                                <span className='p-paginator-icon pi pi-angle-right'></span>
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                                RowsPerPageDropdown: (options) => {
                                    return this.renderPagination(rowsPerPageOptions, options);
                                },
                                CurrentPageReport: (options) => {
                                    return <div className='p-paginator-dummy' />;
                                },
                                PageLinks: (options) => {
                                    if (options.view.startPage === options.page && options.view.startPage !== 0) {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={() => {
                                                    this.handlePage({ first: 0, rows: this.state.criteria.maxResults });
                                                }}
                                            >
                                                {1}
                                                <Ripple />
                                            </button>
                                        );
                                    } else if (options.view.endPage === options.page && options.view.endPage !== options.totalPages - 1) {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={() => {
                                                    this.handlePage({
                                                        first: (options.totalPages - 1) * this.state.criteria.maxResults,
                                                        rows: this.state.criteria.maxResults,
                                                    });
                                                }}
                                            >
                                                {options.totalPages}
                                                <Ripple />
                                            </button>
                                        );
                                    } else if (
                                        (options.view.startPage + 1 === options.page && options.view.startPage !== 0) ||
                                        (options.view.endPage - 1 === options.page &&
                                            options.view.endPage !== options.totalPages - 1 &&
                                            options.page !== options.totalPages - 1)
                                    ) {
                                        const className = classNames(options.className, { 'p-disabled': true });
                                        return (
                                            <span className={className} style={{ userSelect: 'none' }}>
                                                ...
                                            </span>
                                        );
                                    } else {
                                        return (
                                            <button type='button' className={options.className} onClick={options.onClick}>
                                                {options.page + 1}
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                            }}
                            pageLinkSize={this.getPageLinkSize()}
                            lazy={this.props.lazy}
                            onPage={this.handlePage}
                            onSort={this.handleSort}
                            selectionMode={'checkbox'}
                            onSelectionChange={this.handleOnSelectionChange}
                            selection={this.state.selectedList}
                            selectAll={this.state.selectAll}
                            onSelectAllChange={this.handleOnSelectAllChange}
                            dataKey={this.dataKey}
                            expandedRows={this.state.expandedRows}
                            onRowToggle={this.handleOnRowToggle}
                            onRowExpand={this.handleOnRowExpand}
                            onRowCollapse={this.handleOnRowCollapse}
                            rowExpansionTemplate={this.rowExpansionTemplate}
                            rowClassName={this.props.rowClassName}
                            selectionPageOnly={false}
                        >
                            {this.props.rowExpansionTemplate ? <Column expander headerStyle={{ width: '3em' }}></Column> : null}
                            {this.props.disableSelection === true || !this.props.selectionMode ? null : (
                                <Column selectionMode={this.props.selectionMode} headerStyle={{ width: '3em' }}></Column>
                            )}
                            {dynamicColumns}
                        </DataTable>
                    </div>
                </DivContainer>
            </React.Fragment>
        );
    }

    renderPagination(rowsPerPageOptions, options) {
        const start = options.currentPage * options.value + 1;
        let end = start + options.value;
        if (end > options.totalRecords) {
            end = options.totalRecords;
        }
        return (
            <div className='p-paginator-rows-per-page'>
                <Dropdown
                    aria-label={'Pokaż na stronie'}
                    key={'maxResults'}
                    id={'maxResults'}
                    inputId={'maxResults-input'}
                    name={'maxResults'}
                    emptyMessage={this.translate('list.emptyDropdown')}
                    //style={{ width: '100%' }}
                    value={this.state.criteria.maxResults}
                    options={rowsPerPageOptions}
                    onChange={(e) =>
                        this.setState(
                            (prevState) => ({
                                criteria: {
                                    ...prevState.criteria,
                                    maxResults: e.target.value,
                                },
                            }),
                            () => this.handleChangeLimitObj(e)
                        )
                    }
                    filter={false}
                    showClear={false}
                />
                <label id={'limit-label-id'} className='p-paginator-rows-per-page-label' htmlFor={'limit-input'}>
                    {t('list.paginator')}
                </label>
                <span className='p-paginator-rows-per-current'>
                    {start} - {end} z {options.totalRecords}
                </span>
            </div>
        );
    }

    renderCriteria() {
        return (
            <div className='row'>

                <CriteriaTextComponent
                    id='projectName-sc'
                    name='projectName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.projectName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('projectCumulativeReport.projectName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

                <CriteriaTextComponent
                    id='client-sc'
                    name='clientName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.clientName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('projectCumulativeReport.clientName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

                <CriteriaMultiSelectComponent
                    id='categories'
                    name='categories'
                    label={t('project.details.categories')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.categories}
                    optionLabel='name'
                    value={this.state.criteria.categories}
                    dataKey='id'
                    refreshFromBackend
                    timeoutHandler={this.refreshTable} />

                <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    name='date'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    label={'Data'}
                    colClass='col-xl-6 col-lg-12 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    showButtonBar
                    sharedLabel
                    dateFormat="mm/yy"
                    renderDash
                    maxDateInfinite
                    placeholderFrom={'Data od'}
                    placeholderTo={'Data do'}
                    inputsClassName='col-6'
                    view="month"
                />
                <CriteriaMultiSelectComponent
                    id='country-sc'
                    name='countries'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.countries}
                    options={this.state.countries}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('hourlyreport.project.country')}
                    global={true}
                    optionLabel='name'
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                    filter 
                    filterBy="name"
                    placeholder="Wybierz kraj" 
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi-pencil'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => this.handleOpenEditDialog(e, rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                    rendered={false}
                />
            </span>
        );
    }

    handleOpenEditDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ dialogVisibile: true, element: this.cloneElement(rowData), dialogViewMode: 'EDIT' });
    }

    handleOpenDeleteDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ deleteConfirmDialogVisible: true, element: rowData, dialogViewMode: 'DELETE' });
    }


    formatTime(field, rowData) {
        const time = rowData[field];
        return this.formatTimeIfPossible(time);
    }
    formatTimeIfPossible(time) {
        if (NumberUtils.isNumber(time)) {
            const hours = Math.floor(time / 60);
            const mins = time % 60;
            return `${hours}:${mins.toString().padStart(2, '0')}`;
        } else {
            return '';
        }
    }

    prepareColumns() {
        return [
            {
                field: 'projectName',
                header: t('projectCumulativeReport.projectName'),
                sortable: true,
                sortField: 'project_name',
            },
            {
                field: 'country',
                header: t('projectCumulativeReport.country'),
                sortable: true,
                sortField: 'country_name',
            },
            {
                field: 'clientName',
                header: t('projectCumulativeReport.clientName'),
                sortable: true,
                sortField: 'client_name',
            },
            {
                field: 'categories',
                header: t('projectCumulativeReport.category'),
                sortable: false,
                body: this.categoriesTemplate.bind(this),
                style:{
                    width: '280px'
                }
            },
            {
                field: 'month',
                header:  t('projectCumulativeReport.month'),
                sortable: true,
                sortField: 'month_',
            }, 

        {
            field: 'clientTime',
            header: t('projectCumulativeReport.clientTime'),
            sortable: true,
            sortField: 'client_time',      
            body: this.formatTime.bind(this, 'clientTime'),
            style:{
                width: '180px'
            }
        },
       
        ];

    }
    prepareHeaderItems() {
        return [

            {
                type: 'BUTTON',
                className: 'right-side',
                variant: 'white',
                disabled: !this.state.list || this.state.list.length < 1,
                customRenderFunction: this.renderPrintButton,
            },
        ];
    }


    categoriesTemplate(rowData) {
        if (rowData?.categories) {
            return (
                rowData.categories.map(c => {
                    return (
                        <Chip style={{backgroundColor:c?.color?.value, color:"black"}} label={c.name} />
                    )
                })
            )
        } else {
            return '';
        }
    }

    renderPrintButton() {
        return (
            <PrintButtonComponent
                key={`print-button-pay-report`}
                reportFileNameBase={t('projectCumulativeReport.listName')}
                xml={false}
                pdf={false}
                csv={false}
                ods={false}
                xlsx={false}
                globalFormat='xlsx'
                noMenuFlag={true}
                generateReport={this.generateReport.bind(this)}
                disabled={!this.state.list.length > 0}
            />
        );
    }

    generateReport(format) {
        const { summary } = this.state;
        const criteria = this.state.criteria;
        const listSize = this.state.size;
        //const documentLimit = this.state.documentLimit;
        // if (listSize > documentLimit) {
        //     //return this.showErrorAboutLimitExceeded(documentLimit, listSize);
        // }
        switch (format) {
            case 'xlsx':
                return this.service.exportList(criteria, 'xlsx',summary);
            default:
                return Promise.reject('Nieznany format');
        }
    }

    handleOpenNewDialog(e) {
        let basicElement = this.initNewElement();
        if (e) {
            e.preventDefault();
        }
        this.setState({ dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW' });
    }

    handleDelete(e) {
        if (e) {
            e.preventDefault();
        }
        console.log('delete {}', this.state.element);
        this.service
            .remove(this.state.element)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('hourlyreport.deleteSuccess'));
            })
            .catch((err) => {
                this.persistMessage('error', '', t('hourlyreport.deleteError'));
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({ deleteConfirmDialogVisible: false });
            });
    }

    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    render() {
        return (
            <div className='container-fluid projectcategory-list'>
                <BreadcrumbsItem to='/pay-report-list'>{'Raport wypłat'}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }
}

ProjectCumulativeReportListContainer.defaultProps = {
    detailUrl: '/#/projectReport/details',
    newUrl: '/#/projectReport/create',
    lazy: true,
};

ProjectCumulativeReportListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default ProjectCumulativeReportListContainer;