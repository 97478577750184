import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import { ActionButton } from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import ToolService from '../services/ToolService';
import InputTextComponent from '../components/inputs/InputTextComponent';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import InputTextareaComponent from '../components/inputs/InputTextareaComponent';

class ToolDeleteDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new ToolService());
        this.state = {
            elementId: props.elementId,
            modelList: [],
            producerOptions: props.producerOptions,
            modelOptions: props.modelOptions,
            typeOptions: props.typeOptions,
            serialNumber: '',
            element: props.element != null ? props.element : {
                name: '',
                status: { enumValue: 'ACTIVE', label: 'Aktywny' }
            },
            viewMode: this.props.viewMode,
        };
        this.handleDelete = this.handleDelete.bind(this);
        this.statusTemplate = this.statusTemplate.bind(this);
    }


    translate(key) {
        const { t } = this.props;
        return t(key);
    }

    statusTemplate(field) {
        if (field === 'ACTIVE') {
            return 'Aktywne'
        } else if (field === 'DELETED') {
            return 'Usunięte'
        }
    }

    prepareFooter() {
        const { t } = this.props;
        const status = this.state.element.status;
            return (
                <div className='footer-dialog-button-container'>
                    <div className='left-footer-dialog-button-container'></div>

                    <ActionButton
                        label={t('details.cancel')}
                        variant={'button cancel-button white'}
                        handleClick={(e) => {
                            this.props.onHide(undefined);
                        }}
                        rendered={true}
                    />

                    <ActionButton
                        label={t('tool.deleteButton')}
                        variant='button save-button blue'
                        handleClick={this.handleDelete}
                        rendered={true}
                    />
                </div>
            );
        }
    

    renderHeader() {
        const { t } = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        { `${t('tool.deleteHeader')}`}                                   
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }


    updateElement(data) {
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    initAfterSetElement() {
        super.initAfterSetElement();
    }

    setElement() {
        if (this.props.element) {
            this.updateElement(this.props.element);
        }
    }

    renderDetails() {
        return this.renderDeleteView();
    }


    renderFooter() {
        return this.prepareFooter();
    }
    render() {
        const { className, id, key, name, onHide, visible, style } = this.props;
        return (
            // <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
            // <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
            <Dialog
                ariaCloseIconLabel={this.translate('dialog.closeDialog')}
                header={this.renderHeader()}
                visible={visible}
                resizable={false}
                className={className}
                style={style}
                key={`${key ? key : id ? id : name}-dialog`}
                onHide={() => (onHide ? onHide() : null)}
                closable={this.state.closable}
            >
                {/* <div style={{ marginBottom: '20px' }} className='row col-12'>
                    {this.renderHeader()}
                </div>					 */}
                {this.state.loading ? null : (
                    <Card footer={this.renderFooter()}>
                        <form onSubmit={this.handleFormSubmit} noValidate onKeyDown={(e) => this.onKeyDown(e)}>
                            {this.renderDetails()}
                        </form>
                    </Card>
                )}
            </Dialog>
            // </BlockUi>
        );
    }


    renderDeleteView() {
        if (this.state.element.deleteReason != null && this.state.element.deleteReason.length === 0) {
            this.state.element.deleteReason = null;
        }

        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    
                    <InputTextareaComponent
                        id='deleteReason'
                        name='deleteReason'
                        label={'Powód usunięcia'}
                        colClass='col-md-12'
                        value={this.state.element.deleteReason}
                        validator={this.validator}
                        validators='required'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }

    handleDelete(e) {
        const { t } = this.props;
        const { element } = this.state;
        if (this.state.element.deleteReason == null) {
            this.validator.showMessages();
            this.scrollToError = true;
            this.forceUpdate();
            return;
        }
        this.service
            .remove(element, element.deleteReason)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('tool.deleteSuccess'));
                this.afterDeleteSuccess(response);
                if (this.props.afterSave) {
                    this.props.afterSave();
                }
            })
            .catch((err) => {
                this.persistMessage('error', '', t('tool.deleteError'));
            })
            .finally(() => {
                this.setState({ deleteConfirmDialogVisible: false });
                this.unblockUi();
            });
    }

    afterDeleteSuccess(response) {
        const { backUrl } = this.props;
        const href = `${backUrl}`;
        this.blockUi();
        this.persistMessage('success', '', this.getRemoveSucces(response));
        window.location.href = href;
    }




}

ToolDeleteDialog.defaultProps = {
    currentUser: undefined,
    viewMode: 'DELETE',
    visible: false,
    backUrl: '/#/tool-list',
};

ToolDeleteDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    className: PropTypes.string,
};

export default withTranslation()(ToolDeleteDialog);
