import PropTypes from 'prop-types';
import React from 'react';
import {Card} from 'primereact/card';
import BaseContainer from '../../../baseContainers/BaseContainer';
import Constants from '../../../utils/constants';
import DivContainer from '../../../components/DivContainer';
import {t} from 'i18next';
import ProjectExpenseService from '../../../services/ProjectExpenseService';
import moment from 'moment';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import ActionLink from '../../../components/ActionLink';
import {CustomMessages} from '../../../components/CustomMessages';
import ProjectExpensesDialog from './ProjectExpensesDialog';
import YesNoDialog from '../../../components/YesNoDialog';
import ProjectService from '../../../services/ProjectService';
import NumberUtils from '../../../utils/NumberUtils';

export class ProjectExpensesContainer extends BaseContainer {
    constructor(props) {
        super(props, new ProjectExpenseService());
        this.state = {
            downloadingPdf: false,
            dialogExpenseVisible: false,
            dialogDeleteConfirmVisible: false,
            element: this.initNewElement(),
            elementId: undefined,
            dialogViewMode: 'EDIT',
        };
        this.validator = this.props.validator;
        this.expenseDialogShow = this.expenseDialogShow.bind(this);
        this.expenseDialogClose = this.expenseDialogClose.bind(this);
        this.onDeleteDialogConfirmShow = this.onDeleteDialogConfirmShow.bind(this);
        this.onDeleteDialogConfirmShow = this.onDeleteDialogConfirmShow.bind(this);
        this.projectService =  new ProjectService();
    }
    initNewElement() {
        return {
            id: undefined,
            date: undefined,
            description: undefined,
            value: {
                value: undefined,
                currency: 'EUR',
            },
            project: {
                id: this.props?.projectId,
            },
            type: undefined,
        };
    }
    prepareObjectToSave(element) {
        const modifiedElement = structuredClone(element);
        modifiedElement.date = moment(modifiedElement.date).format(Constants.DATE_FORMAT);
        modifiedElement.type = modifiedElement.type.enumValue;
        return modifiedElement;
    }
    handleDelete(element) {
        this.blockUi();
        this.service
            .remove(element)
            .then((project) => {
                this.props.onProjectChanged(project);
                this.unblockUi();
            })
            .catch((err) => {
                this.unblockUi();
                this.showErrorMessage(err.message, 10000);
            });
    }

    actionTemplate(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi-pencil'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={() => {
                        this.expenseDialogShow(rowData.id);
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                    rendered
                />
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={() => this.onDeleteDialogConfirmShow(rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                    rendered
                />
            </span>
        );
    }

    customDateTemplate = (rowData) => {
        return moment(rowData.date).format(Constants.DATE_GET_REQUEST_FORMAT);
    }

    expenseDialogClose() {
        this.setState({dialogExpenseVisible: false});
    }

    expenseDialogShow(elementId) {
        const element = elementId ? this.props.expenses.find((el) => el.id === elementId) : this.initNewElement();
        if (element?.date) {
            element.date = moment(element.date).toDate();
        }
        const dialogViewMode = elementId ? 'EDIT' : 'NEW';
        this.setState({dialogExpenseVisible: true, element, dialogViewMode});
    }

    onDeleteDialogConfirmShow(element) {
        this.setState({dialogDeleteConfirmVisible: true, element});
    }
    deleteDialogConfirmClose() {
        this.setState({dialogDeleteConfirmVisible: false, elementId: undefined});
    }

    valueTemplate(rowData) {
        return NumberUtils.formatNumber(rowData?.value?.value);
    }

    render() {
        return (
            <Card style={{border: 'none'}}>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <Card header={t('expense.cardHeader')}>
                    <DivContainer colClass='row'>
                        <DataTable value={this.props.expenses} responsiveLayout='scroll' emptyMessage={t('list.empty')}>
                            <Column field='value.value' header={t('expense.value')} body={this.valueTemplate.bind(this)}></Column>
                            <Column field='date' header={t('expense.date')} body={this.customDateTemplate}></Column>
                            <Column field='description' header={t('expense.description')}></Column>
                            <Column field='type.label' header={t('expense.type')}></Column>
                            <Column body={(rowData) => this.actionTemplate(rowData)} header=''></Column>
                        </DataTable>
                    </DivContainer>
                    <DivContainer>
                        <ActionLink
                            label={t('expense.addExpense')}
                            iconName='mdi-plus'
                            iconSide='left'
                            iconSize='icon-large'
                            variant='blue'
                            className='float-right'
                            key={'add-activity-button'}
                            handleClick={() => this.expenseDialogShow()}
                            rendered ={this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER')}
                        />
                    </DivContainer>
                </Card>
                {this.state.dialogExpenseVisible && (
                    <ProjectExpensesDialog
                        projectId = {this.props?.projectId}
                        visible={this.state.dialogExpenseVisible}
                        element={this.state.element}                        
                        viewMode={this.state.dialogViewMode}                        
                        onHide={() => {
                            this.expenseDialogClose();
                        }}
                        onProjectChanged={project => {
                            this.expenseDialogClose();
                            this.props.onProjectChanged(project);                            
                        }}
                    />
                )}
                <YesNoDialog
                    visible={this.state.dialogDeleteConfirmVisible}
                    header={t('expense.delete')}
                    name='visible'
                    onChange={(type, x, target) => {
                        this.deleteDialogConfirmClose();
                        if (target.value) {
                            this.handleDelete(this.state.element);
                        }
                    }}
                    onHide={() => {
                        this.deleteDialogConfirmClose();;
                    }}
                >
                    <div className='container'>{t('expense.deleteConfirmation')}</div>
                </YesNoDialog>
            </Card>
        );
    }
}

ProjectExpensesContainer.defaultProps = {
    expenses: [],
    projectId: undefined,
    validator: undefined,
    onSummaryChange: ()=>{},
};

ProjectExpensesContainer.propTypes = {
    expenses: PropTypes.array,
    projectId: PropTypes.number,
    validator: PropTypes.object,
    onProjectChanged: PropTypes.func,
    blockUi: PropTypes.func,
    unblockUi: PropTypes.func,
};

export default ProjectExpensesContainer;
