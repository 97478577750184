import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import {t} from 'i18next';
import ActionLink from '../components/ActionLink';
import SubcontractorService from '../services/SubcontractorService';
import ExternalSupervisorEditDialog from './ExternalSupervisorEditDialog';
import ExternalSupervisorService from '../services/ExternalSupervisorService';
import YesNoDialog from '../components/YesNoDialog';
import { contains } from 'jquery';

class ExternalSupervisorList extends BaseListContainer {
    constructor(props) {
        super(props, new ExternalSupervisorService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleTypeOptions: [],
            dialogVisibile: false,
            element: this.emptyInternalUser(),
        };
        this.subcontractorService = new SubcontractorService();
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
    }

    emptyInternalUser() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            roleType: undefined,
            subcontractor: null,
            status: {enumValue: 'ACTIVE', label: 'Aktywny'},
        };
    }

    updateSearchCriteria(criteria) {
        return {
            email: criteria.email,
            firstName: criteria.firstName,
            lastName: criteria.lastName,
            subcontractor: criteria.subcontractor,
            roleType: criteria.roleType,
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            email: '',
            firstName: '',
            lastName: '',
            subcontractor: null,
            roleType: undefined,
            status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('ExternalSupervisorStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
    }

    getCriteriaName() {
        return 'user-list-sc';
    }

    getContainerListName() {
        return 'user-list-container';
    }

    renderCriteria() {
        return (
            <div className='row'>
                  <CriteriaTextComponent
                    id='firstName-sc'
                    name='firstName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.firstName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('internaluser.firstName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='lastName-sc'
                    name='lastName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.lastName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('internaluser.lastName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='phoneNumber-sc'
                    name='phoneNumber'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.phoneNumber}
                    onChange={this.handleChangeSc}
                    label={t('employee.details.phoneNumber')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                    validator={this.validator}
                    validators='required|phone|max:20'
                />
                 <CriteriaTextComponent
                    id='subcontractor-sc'
                    name='subcontractor'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.subcontractor}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('externalSupervisor.company')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={t('internaluser.status')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }
    handleOpenEditDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: this.cloneElement(rowData), dialogViewMode: 'EDIT'});
    }


    handleOpenDeleteDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({deleteConfirmDialogVisible: true, element: rowData, dialogViewMode: 'DELETE'});
    }

    actionTemplate(rowData) {
        return (
            <span>
            <ActionLink
                iconName='mdi-pencil'
                iconSide='left'
                iconSize='icon-large'
                handleClick={(e) => this.handleOpenEditDialog(e, rowData)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
                rendered={rowData.status.enumValue ==='ACTIVE'}
            />
           <ActionLink
                 iconName='mdi-delete'
                 iconSide='left'
                 iconSize='icon-large'
                handleClick={(e) => this.handleOpenDeleteDialog(e, rowData)}
                variant='blue'
                className='edit-blue-link'
                key={'delete-button'}
                rendered={rowData.status.enumValue ==='ACTIVE'}
            />
           </span>
        );
    }

    prepareColumns() {
        return [
            {field: 'firstName', header: t('internaluser.firstName'), sortable: true},
            {field: 'lastName', header: t('internaluser.lastName'), sortable: true},
            {field: 'phoneNumber', header: t('employee.details.phoneNumber'), sortable: true},
            {field: 'subcontractor.name', header: t('externalSupervisor.company'), sortable: true},
            {field: 'status', header: t('internaluser.status'), sortable: true, body: this.enumTemplate.bind(this, 'status')},
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO')
            ? [
                  {
                      label: 'Dodaj nowego opiekuna',
                      type: 'BUTTON',
                      className: 'float-right',
                      variant: 'blue',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                      iconColor: 'white',
                      onClick: this.handleOpenNewDialog,
                  },
              ]
            : [];
    }
    handleOpenNewDialog(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: this.emptyInternalUser(), dialogViewMode: 'NEW'});
    }

    afterObjectUpdate() {       
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    handleDelete(e){
        if (e) {
            e.preventDefault();
        }
        console.log('delete {}', this.state.element)              
        this.service
            .remove(this.state.element)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('externalSupervisor.deleteSuccess'));
            })
            .catch((err) => {
                this.persistMessage('error', '', t('externalSupervisor.deleteError'));               
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({deleteConfirmDialogVisible: false});
            });
        
    }
    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/user-list'>{'Użytkownicy'}</BreadcrumbsItem>
                {this.renderView()}
                {this.state.dialogVisibile ? (
                    <ExternalSupervisorEditDialog
                        key={`driver-dialog-${this.state.element?.id}`}
                        element={this.state.element}
                        onHide={(e) => this.setState({dialogVisibile: false})}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        roleTypes={this.state.roleTypeOptions}
                        afterSave={(e) => {
                            this.setState({dialogVisibile: false});
                            this.readMessage();
                            this.refreshTable();
                            this.unblockUi();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
                {this.state.deleteConfirmDialogVisible ? (
                <YesNoDialog    
                    visible={this.state.deleteConfirmDialogVisible}
                    header={t('externalSupervisor.deleteHeader')}
                    name='visible'
                    onChange={(type, x, target) => {
                        if (target.value) {
                            this.handleDelete();
                        } else {
                            this.setState({deleteConfirmDialogVisible: false})
                        }
                    }}
                    onHide={() => this.setState({deleteConfirmDialogVisible: false})}
                     >
                        {t('details.deleteConfirmation')+'\n' + this.state.element.firstName + ' '+this.state.element.lastName}
                    </YesNoDialog> 
                ) : null}
            </div>
        );
    }
}

ExternalSupervisorList.defaultProps = {
    detailUrl: '/#/user/details',
    newUrl: '/#/user/create',
    lazy: true,
};

ExternalSupervisorList.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default ExternalSupervisorList;
