import BaseService from './BaseService';

export default class AccountService extends BaseService {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'internal-user';
    }

    checkToken(token, tokenType) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/check-token/${token}/${tokenType}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getMyAccount() {
        return this.fetch(`${this.domain}/${this.path}/my-account`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }

    setPassword(token, tokenType, body) {
        const encodedValue = encodeURIComponent(token);
        return this.fetch(`${this.domain}/${this.path}/set-password?token=${encodedValue}&&tokenType=${tokenType}`, {
            method: 'POST',
            body: JSON.stringify(body),
        }).catch((err) => {
            throw err;
        });
    }
    changePassword(body) {
        return this.fetch(`${this.domain}/${this.path}/password-change`, {
            method: 'POST',
            body: JSON.stringify(body),
        }).catch((err) => {
            throw err;
        });
    }

    sendLinkForGenerateNewPassword(email) {
        const encodedValue = encodeURIComponent(email);
        return this.fetch(`${this.domain}/${this.path}/password-reset-link?email=${encodedValue}`, {
            method: 'POST',
        }).catch((err) => {
            throw err;
        });
    }
}
