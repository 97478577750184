import PropTypes from 'prop-types';
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { DataSet } from "vis-data";
import { Timeline } from "vis-timeline";
import DivContainer from "../../components/DivContainer";

import ReactDOM from 'react-dom';

import moment from 'moment';
import 'moment/min/locales';
import { Tooltip } from "primereact/tooltip";

import { t } from 'i18next';
import { Accordion, AccordionTab } from 'primereact/accordion';
import BaseDetailsContainer from "../../baseContainers/BaseDetailsContainer";
import CriteriaCalendarRangeComponent from '../../components/criteria/CriteriaCalendarRangeComponent';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import AccommodationService from "../../services/AccommodationService";
import Constants from "../../utils/constants";
import SelectEmployeeDialog from './SelectEmployeeDialog';
import CountryService from '../../services/CountryService';
import AccommodationCategoryService from '../../services/AccommodationCategoryService'
import $ from 'jquery';
import { PrintButtonComponent } from '../../report/ReportUtils';

class AccommodationTimelineContainer extends BaseDetailsContainer {

    constructor(props) {
        super(props, new AccommodationService());        
        this.state = {
            element: {
                selectProjectDialogVisible: false,
                confirmDeleteDialogVisible: false,                
            },
            config: {
                start: moment().subtract(1, 'days').startOf('day').toDate(),
                end: moment().startOf('day').add(6, 'days').toDate(),            
                min: moment().subtract(30, 'day').startOf('day').toDate(),
                max: moment().add(30, 'day').startOf('day').toDate(),
            }, criteria: {
                fullName: '',
                firstResult: 0,
                maxResults: 10,
                sortField: 'id',
                sortAsc: false,
                accommodationName: '',
                countries: [],
                categories: [],
                dateFrom: moment().subtract(14, 'day').toDate(),
                dateTo: moment().add(14, 'day').toDate()
                            },
            printDisabled: false,
            isManagerOptions: [
                {
                    label: 'Tak',
                    value: true
                },
                {
                    label: 'Nie',
                    value: false
                },
            ]
        };        
        this.handleAssignNewProject = this.handleAssignNewProject.bind(this);
        this.handleRemoveAccommodation = this.handleRemoveAccommodation.bind(this);
                this.openDialog=this.openDialog.bind(this);
        this.refreshTimeLine=this.refreshTimeLine.bind(this);

        this.accommodations = new DataSet();
        this.bookings = new DataSet();

        this.countryService = new CountryService();
        this.categoryService = new AccommodationCategoryService();
        this.renderPrintButton=this.renderPrintButton.bind(this);
    }
        

    initAfterSetElement() {
    }

    initBeforeSetElement() {
        
        this.blockUi();

        this.countryService
            .getAll()
            .then((data) => this.setState({
                countryOptions: data
            })).catch((err) => {
                this.unblockUi();
                this.showErrorMessage('Nie udało się pobrać listy krajów');
            })

        this.categoryService
            .getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
            .then((data) => this.setState({
                categories: data.content
            })).catch((err) => {
                this.unblockUi();
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });

        this.service
            .getAccomodationTimeline()
            .then((data) => {
                if (data?.items.length === 0) {
                    this.showInfoMessage('Brak rekordów');
                    this.setState({
                        printDisabled: true
                    });
                    this.convertAccommodationToVisFormat(data);
                    this.unblockUi();
                } else {
                    const config = data.config;
                    this.setState({
                        config: {
                            min: moment(config.min).toDate(),
                            max: moment(config.max).endOf('day').toDate(),
                            start: moment(config.start).toDate(),
                            end: moment(config.end).toDate()
                        },
                        defaultConfig: {
                            min: moment(config.min).toDate(),
                            max: moment(config.max).endOf('day').toDate(),
                            start: moment(config.start).toDate(),
                            end: moment(config.end).toDate()
                        },
                        printDisabled: false
                    });
                    this.convertAccommodationToVisFormat(data);
                }
            }).catch((err) => {
                this.unblockUi();
                this.showErrorMessage('Nie udało się pobrać danych noclegów');
            });
    }
    openDialog(accommodationId,date,item) {
        const user = this.accommodations?.get(accommodationId);
                this.setState({
            visible: true,
            selectedAcommodationId: accommodationId,
            dateStart: moment(date).startOf('day').toDate(),
            accommodationName: user?.name
        })
    }
    convertItemToVisFormat(acomId, acomName, accommodationId, dateStart, dateEnd,maxCapacity,currentlyUsed){
        const today = moment().startOf('day');
        const result = {
            id: crypto.randomUUID(),
            acomId: acomId,
            start: moment(dateStart).startOf('day').toDate(),
            end: moment(dateEnd).endOf('day').toDate(),
            group: accommodationId,
            style: (currentlyUsed > maxCapacity) ? "background-color: pink;" : null,
            currentlyUsed: currentlyUsed,
            maxCapacity: maxCapacity,
            name: acomName,
            absence: false,
            editable: {
                add: false,
                remove: moment(dateEnd).isAfter(today),
                updateGroup: false,
                updateTime: false,
            },                 
        };
        TooltipHelper.generateAccommodationTooltip(result);
        return result;
    }

    generateReport(format) {
        const criteria = this.state.criteria;
        switch (format) {
            case 'xlsx':
                return this.service.exportList(criteria, 'xlsx');
            default:
                return Promise.reject('Nieznany format');
        }
    }
    renderPrintButton() {
        return (
            <div style={{
                textAlign: 'right',
                color: '#3059e8',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '14px',
            }}>        
                <PrintButtonComponent
                    key={`print-button-pay-report`}
                    reportFileNameBase={'Noclegi'}
                    xml={false}
                    pdf={false}
                    csv={false}
                    ods={false}
                    xlsx={false}
                    disabled={this.state.printDisabled}
                    globalFormat='xlsx'
                    noMenuFlag={true}
                    generateReport={this.generateReport.bind(this)}
                />
            </div>
        );
    }

    convertAccommodationToVisFormat(data) {
        this.accommodations.clear();
        this.bookings.clear();       
        
        let acoms = data?.items.map(item => {
            this.returnList(
                item,
                item.empAccommodations
            );

            return {
                id: item.id,
                name: item.name,
                address: item.address,
                dateStart: item.dateStart,
                dateEnd: item.dateEnd,
                maxCapacity: item.maxCapacity
            };
            });
            this.accommodations.add(acoms); 
    }


    returnList(item, empAccommodations) {
             let dateStart = moment(item.dateStart != null ? item.dateStart : this.state.config.max).toDate();
        let dateEnd = moment(item.dateEnd != null ? item.dateEnd : this.state.config.max).toDate();

        const startMs = dateStart.getTime();
        const endMs = dateEnd.getTime();
        const oneDay = 24 * 60 * 60 * 1000;
    
        let itemsArray=[];
        for (let ms = startMs; ms <= endMs; ms += oneDay) {
            const currentDate = moment(moment(ms).format('YYYY-MM-DD'));
            let currentlyUsed = empAccommodations.filter((x) => {
               return moment(x.dateFrom).isSameOrBefore(currentDate)
                && moment(x.dateTo).subtract(1,'days').isSameOrAfter(currentDate)
            }
            ).length;

            itemsArray.push(this.convertItemToVisFormat(
                item.id, '', item.id, currentDate, currentDate,
                item.maxCapacity, currentlyUsed, dateStart, dateEnd
            ));
        }
        this.bookings.add(itemsArray);
    }
    renderBackLink() {
       
    }

    prepareFooterItems() {
        const result = super.prepareFooterItems();        
        return result;
    }


    isPdfButtonRendered() {
        return false;
    }

    getPdfDownloadLabel() {
        return null;
    }

    handleDownloadPdf() {
    }

    
    handleAssignNewProject(userId, userName) {
        this.setState({
            selectProjectDialogVisible: true,
            selectedUserId: userId,
            selectedUserName: userName        
        });
    }

    handleRemoveAccommodation(user, project, callback) {
        this.confirmDeleteCallback = callback;
        this.setState({
            confirmDeleteDialogVisible: true,
            confirmDeleteUser: user,
            confirmDeleteProject: project,
        });
    }

    
    setTimeout(callback, delay) {
        if (callback && callback instanceof Function) {
            this.timeoutHandler = setTimeout(() => {
                callback();
            }, delay);
        } else {
            this.refreshTimeLine();
        }
    }

    convertToVisFormatAfterRefresh(data){
        this.accommodations.clear();
        this.bookings.clear();        
        data?.items.forEach(item => {
            const accommodation = {
                id: item.id,
                name: item.name,
                address: item.address
            };
            
            this.accommodations.add(accommodation);

            this.returnList(
                item,
                item.empAccommodations
            );
        }) 
    }
    
    refreshTimeLine(){
        this.blockUi();
        const { criteria } = this.state;
        const { accommodationName } = criteria;
        const { fullName } = criteria;
        const { countries } = criteria;
        const { dateFrom } = criteria;
        const { dateTo } = criteria;
        const { categories } = criteria;
        const defaultMin = this.state.defaultConfig.min;
        const defaultMax = this.state.defaultConfig.max;

        if (dateFrom) {
            this.setState((prev) => ({
                config: {
                    ...prev.config,
                    min: moment(dateFrom).startOf('day').toDate(),
                }
            }));
        } else {
            const { min } = this.state.config;
            if (min !== defaultMin) {
                this.setState((prev) => ({
                    config: {
                        ...prev.config,
                        min: defaultMin
                    }
                }));
            }
        }

        if (dateTo) {
            this.setState((prev) => ({
                config: {
                    ...prev.config,
                    max: moment(dateTo).endOf('day').toDate(),
                }
            }));
        } else {
            const { max } = this.state.config;
            if (max !== defaultMax) {
                this.setState((prev) => ({
                    config: {
                        ...prev.config,
                        max: defaultMax
                    }
                }));
            }
        }
        this.service
            .getAccomodationTimeline(accommodationName, fullName, countries?.map((x) => x.id).join(','), categories?.map((x) => x.id).join(','))
            .then(data => {
                if (data?.items.length === 0) {
                    this.showInfoMessage("Brak rekordów wyszukiwania");
                    this.setState({
                        printDisabled: true
                    })
                    this.convertToVisFormatAfterRefresh(data);
                    this.unblockUi();
                } else {
                    this.convertToVisFormatAfterRefresh(data);
                    this.setState({ visible: false, printDisabled: false });
                    this.unblockUi();
                }
            })
            .catch((e) => {
                this.unblockUi();
                this.showErrorMessage('Nie udało się pobrać danych użytkowników');
            });
    }
    handleValidForm() {
        //this.createOrUpdate();
        this.refreshTimeLine()
    }

    onKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleFormSubmit();
        }
    }

    renderCriteria() {
        return (
            <div className='row'>
                 <CriteriaTextComponent
                    id='accommodationName'
                    name='accommodationName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.accommodationName}
                    onChange={this.handleChangeSc}
                    //validator={this.validator}
                    label={t('accommodation.details.name')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                />

                 <CriteriaMultiSelectComponent
                    id='countries'
                    name='countries'
                    label={t('accommodation.details.countryName')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.countryOptions}
                    optionLabel='name'
                    value={this.state.criteria.countries}
                    dataKey='id'
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                    filter
                    filterBy={'name'}
                    />
               <CriteriaMultiSelectComponent
                    id='categories'
                    name='categories'
                    label={t('accommodation.categories')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.categories}
                    optionLabel='name'
                    value={this.state.criteria.categories}
                    dataKey='id'
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                    filter
                    filterBy={'name'}
                    />
               <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    name='date'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    label={'Data'}
                    colClass='col-xl-6 col-lg-12 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                    showButtonBar
                    sharedLabel
                    renderDash
                    maxDateInfinite
                    placeholderFrom={'Data od'}
                    placeholderTo={'Data do'}
                    inputsClassName='col-6'
                />

            </div>
        );
    }
    

    renderDetails() {
        return (
            <React.Fragment>
                {this.state.visible ?
                    <SelectEmployeeDialog
                        id="selectProjectDialog"
                        key="selectProjectDialogKey"
                        visible={this.state.visible}
                        onHide={() => this.setState({ visible: false })}
                        onSelect={(element) => {
                            this.service.assignEmployeeToAccommodation(element)
                                .then(assignment => {
                                    this.refreshTimeLine()
                                })
                                .catch(err => {
                                    this.showErrorMessage(err.message);
                                });

                        }}
                        userId={this.state?.selectedUserId}
                        userName={this.state?.userName}
                        selectedEmpId={this.state.selectedEmpId}
                        dateStart={this.state.dateStart}
                        dateEnd={this.state.dateEnd}
                        element={this.state.element}
                        acomId={this.state.selectedAcommodationId}
                        accommodationName={this.state.accommodationName}
                    />
                    : null}
                <DivContainer colClass='row'>
                <Accordion className='filter-accordion' activeIndex={-1}>
                <AccordionTab
                    header={
                        <React.Fragment>
                            <img className='card-icon' alt={t('list.filter')} src={`/images/ico_filter.svg`} />
                            <span>{t('list.filter')}</span>
                        </React.Fragment>
                    }
                >
                    <form
                        className='form-search-criteria'
                        onSubmit={(e) => {
                            this.handleFormSubmit(e);
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        noValidate
                    >
                        <DivContainer colClass='col-12'>
                            {this.renderCriteria()}

                        </DivContainer>
                    </form>
                </AccordionTab>
            </Accordion>
                </DivContainer>
                <DivContainer colClass='row'>

                    <AccommodationTimeline
                        accommodations={this.accommodations}
                        bookings={this.bookings}
                        config={this.state.config}
                        onAssignNewProject={this.handleAssignNewProject}
                        onRemoveAccommodation={this.handleRemoveAccommodation}
                        onReady={this.unblockUi}
                        openDialog={this.openDialog}
                        renderPrintButton={this.renderPrintButton}
                    />    
                </DivContainer>                    
            </React.Fragment>
        )
    }

};

function GroupTemplate(props){
    const group = props?.group;
    const model = group.address;
    let street=model?.streetName+ ' ' + model?.streetNumber;

    if(model?.houseNumber!=null && model?.houseNumber.length>0){
      street+='/'+model?.houseNumber;
    }

    return (
        <div>
            <div>
                <span className='accommodation-address-container'>
                        {group?.name}
                    <span className='accommodation-adress' style={{ whiteSpace: 'pre-wrap' }}>
                         {model?.postalCode + ' ' + model?.city}
                    </span>
                    <span className='accommodation-adress' style={{ whiteSpace: 'pre-wrap' }}>
                        {street}
                    </span>
                    <span className='accommodation-adress' style={{ whiteSpace: 'pre-wrap' }}>
                        {model?.country.name}
                    </span>
                </span>
            </div>
        </div>
    )    
}

class ItemTemplate extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div style={{
                     display: 'table-cell',
                     verticalAlign: 'middle'
            }}>
                <label style={{
                    fontFamily: 'Poppins',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '15px',
                    lineHeight: '66px',
                    padding: '0 69px',
                    color: 'black',
                    letterSpacing: '-0.01em',
                }}>
                    {`${this.props?.item.currentlyUsed}/${this.props?.item.maxCapacity}`}
                </label>
            </div>
        );
    }
}

class VisibleFramTemplate extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        return <div>
            id: {this.props.item.id}
            <button></button>
        </div>
    }
}

class AccommodationTimeline extends React.Component {

    constructor(props) {
        super(props);
        this.periods = [
            {code: 'WEEK', label: 'Tydzień'},
            {code: 'TWO_WEEKS', label: 'Dwa tygodnie'},
            {code: 'MONTH', label: 'Miesiąc'},
            {code: 'THREE_MONTHS', label: 'Kwartał'},
            {code: 'YEAR', label: 'Rok'}
        ];
        this.selectedPeriod = { code: 'WEEK', label: 'Tydzień' };
        this.addSelectedPeriod = (date) => {
            if (this.selectedPeriod) {
                if (this.selectedPeriod.code === 'WEEK') {
                    return moment(date).add(1, 'week').toDate();
                } else if (this.selectedPeriod.code === 'TWO_WEEKS') {
                    return moment(date).add(2, 'week').toDate();
                } else if (this.selectedPeriod.code === 'MONTH') {
                    return moment(date).add(1, 'month').toDate();
                } else if (this.selectedPeriod.code === 'THREE_MONTHS') {
                    return moment(date).add(3, 'month').toDate();
                } else if (this.selectedPeriod.code === 'YEAR') {
                    return moment(date).add(1, 'year').toDate();
                }
            }
            return date;
        }

        this.subtractSelectedPeriod = (date) => {
            if (this.selectedPeriod) {
                if (this.selectedPeriod.code === 'WEEK') {
                    return moment(date).subtract(1, 'week').toDate();
                } else if (this.selectedPeriod.code === 'TWO_WEEKS') {
                    return moment(date).subtract(2, 'week').toDate();
                } else if (this.selectedPeriod.code === 'MONTH') {
                    return moment(date).subtract(1, 'month').toDate();
                } else if (this.selectedPeriod.code === 'THREE_MONTHS') {
                    return moment(date).subtract(3, 'month').toDate();
                } else if (this.selectedPeriod.code === 'YEAR') {
                    return moment(date).subtract(1, 'year').toDate();
                }
            }
        }

        this.state = {
            visible: false,
            selectedEmpId: null,
            userName:''
        }
        this.render = this.render.bind(this);
        this.bookings = new DataSet();
        this.service = new AccommodationService()
    }

    componentDidMount() {
        return this.initTimeline();
    }

    componentDidUpdate() {        
        let windows = this.timeline.getWindow();
        let options = this.generateOptions();
        // start i end z obecnego widoku, zeby nie uciekalo na sam poczatek
        options.start = windows.start;
        options.end = windows.end;
        this.timeline.setOptions(options)
    }

    componentWillUnmount() {
        if (this.alignCurrentTimeIntervalId) {
            clearInterval(this.alignCurrentTimeIntervalId);
            this.alignCurrentTimeIntervalId = null;
        }
    }

    //  TODO ustawia czy ma byc rerender

    shouldComponentUpdate(nextProps){
        if (this.timeline && nextProps.config) {
            if (this.props?.config?.start !== nextProps?.config?.start) {
                this.timeline.setOptions({
                    max: nextProps.config.max,
                    min: nextProps.config.min,
                    start: nextProps.config.start,
                    //end: this.addSelectedPeriod(nextProps.config.start),
                });
                $('#projectManager span.p-dropdown-label.p-inputtext').text(this.selectedPeriod.label);
                const range = this.timeline.getWindow();
                if (nextProps?.config?.period) {
                    this.selectedPeriod = this?.periods.find(p => p.code === nextProps.config.period);
                    const end = this.addSelectedPeriod(range.start);
                    this.timeline.setWindow({
                        start: range.start,
                        end:   end,
                    });
                }
            }
        }
        return true; //jak jest true to dziala wtedy filtrowanie po dacie
    }

    

    generateOptions() {
        const options = {
            locale: 'pl',
            orientation: 'both',
            //minHeight: '500px',
            width: '100%',
            margin: {
                item: 20
            },
            start: this.props?.config?.start,
            end: this.props?.config?.end,
            editable: {
                add: true,
                remove: true,
                updateGroup: false,
                updateTime: false,
            },            
            showTooltips: true,
            horizontalScroll: true,
            verticalScroll: true,
            zoomKey: 'ctrlKey',
            type:'background',
            //ograniczenie do 1 dnia
            zoomMin: 1000 * 60 * 60 * 24 * 7, //jeden dzień w ms
            zoomMax: 1000 * 60 * 60 * 24*7, //jeden dzień w ms
            min: this.props?.config?.min,
            max: this.props?.config?.max,
            showCurrentTime: true,
            showWeekScale: true,
            format: {
                minorLabels: {day: 'DD.MM'},
                majorLabels: {day: 'w'}
            },
            tooltip:{overflowMethod: 'cap'},
            rollingMode: {
                // empty object as a fix for missing onInitialDrawComplete event
            },
            onInitialDrawComplete: () => { // event nie zawsze byl uruchamiany      
                if (this.timeline) {
                    this.timeline.setItems(this.props.bookings);
                }
                if (this.props?.onReady) {
                    this.props.onReady();
                }
            },
            template: (item, element) => {
                if (!item) { 
                    return 
                }
                return ReactDOM.createPortal( ReactDOM.render( <ItemTemplate item={item} />, element ), element, () => { 
                    if (this.timeline) {
                        this.timeline.redraw();
                    }
                });
            },
           
            groupTemplate: (user, element) => {
                if (!user || !user.name) { 
                    return 
                }
                return ReactDOM.createPortal( ReactDOM.render(
                <GroupTemplate 
                    group={user} 
                />, 
                    element ), element 
                );
            },

            visibleFrameTemplate: (item, element) => {
                if (!item || !element) { 
                    return 
                }
                if (element.className.indexOf('timeline-item-visible-frame') === -1) { 
                    return 
                }
                return ReactDOM.createPortal( ReactDOM.render( <VisibleFramTemplate item={item} />, element ), element );
                
            },
            loadingScreenTemplate: () => {
                return (
                    <div className="row">
                        <div className="col-12">
                            <h1>Loading...</h1>
                        </div>
                    </div>                    
                );
            },
            onAdd: (item,callback)=>{
                if (this.props?.accommodations.length > 0) {
                    const accommodationId = item?.group;
                    this.props?.openDialog(accommodationId, item?.start, item);
                }
            },
            onRemove: (accommodation, callback) => {
                if (this.props.onUnassignProject) {
                    const user = this.props?.accommodations.get(accommodation.group);

                    const callbackWrapper = (itemId, removed, dateStart, dateEnd) => {
                        if (removed) {
                            callback({id: itemId});
                        } else {
                            callback(null);
                            accommodation.start = moment(dateStart).startOf('day');
                            accommodation.end = moment(dateEnd).endOf('day');
                            accommodation.editable.remove = false;
                                                    

                            //jeśli usuwamy i zamiast usunąć zmniejszamy zakres dat, to nie odmalowuje się wykres;
                            //dopiero przesunięcie powoduje odmalowanie go;
                            //nie wyłącza się też opcja editable.remove
                            this.movePercentage( -0.0001);
                            this.movePercentage( 0.0001);  
                            this.timeline.setSelection([]);                          
                        }
                    }
                    if (accommodation?.editable?.remove) {
                        this.props.onRemoveAccommodation(user, accommodation, callbackWrapper);
                    }
                }                
            }
        }
        return options;

    }
    
    initTimeline() {
        const container = document.getElementById('visualization');
        this.timeline = new Timeline(container, this.props?.bookings, this.props?.accommodations, this.generateOptions());
        
        //kreska z bieżącym czasem przesuwa się; więc ją sprowadzamy interwałem do początku dnia;
        this.timeline.setCurrentTime(moment().startOf('day').toDate());
        this.alignCurrentTimeIntervalId = setInterval(() => {
            if (this.timeline) {
                this.timeline.setCurrentTime(moment().startOf('day').toDate())
            }
          }, 10000);
        
    } 

    assignNewProject(userId, userName) {
        this.props.onAssignNewProject(userId, userName);
    }

    movePercentage(percentage) {
        const range = this.timeline.getWindow();
        const interval = range.end - range.start;        
        this.timeline.setWindow({
            start: range.start.valueOf() - interval * percentage,
            end:   range.end.valueOf()   - interval * percentage
        });
    }

    moveSelectedPeriod(positiveDirection) {
        const range = this.timeline.getWindow();

        const start = positiveDirection ? this.addSelectedPeriod(range.start) : this.subtractSelectedPeriod(range.start);
        const end = positiveDirection ? this.addSelectedPeriod(range.end) : this.subtractSelectedPeriod(range.end);

        this.timeline.setWindow({
            start: start,
            end:   end,
        });

    }

    render() {
        const label = $('#projectManager span.p-dropdown-label.p-inputtext');
        label.text(this.selectedPeriod?.label); // default label
        return (
            <div> 
                <Tooltip id="timelineHeader" target=".timelineHeaderIcon" />
                <div id="visualization">
                    <div className='row' style={{ textAlign: 'center', marginBottom: '24px' }}>
                        <div className='col-3'>

                        </div>
                            <div className='col-2'>
                                <i className="timelineHeaderIcon mdi mdi-arrow-left icon-large-plus " style={{ marginRight: '14px', color: '#0d6efd'}} data-pr-tooltip="Przesuń w lewo"
                                    onClick={() => this.moveSelectedPeriod(false)}
                                />
                                <i className="timelineHeaderIcon mdi mdi-arrow-right icon-large-plus " style={{ marginRight: '14px', color: '#0d6efd'}} data-pr-tooltip="Przesuń w praco"
                                    onClick={() => this.moveSelectedPeriod(true)}
                                />
                            </div>       
                            <div>
                            {this.props.renderPrintButton()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

class TooltipHelper {
    static generateAccommodationTooltip(p){
        let tooltip = `<p>${p.name}</p>`;
        tooltip += `<p>${moment(p.start).format(Constants.DATE_FORMAT)} - ${moment(p.end).format(Constants.DATE_FORMAT)}</p>`;
        p.title = tooltip;
    }
}



AccommodationTimelineContainer.defaultProps = {
    backUrl: '/#/project-list',
    cancelUrl: '/#/project/details',
    editUrl: '/#/project/edit',
};

AccommodationTimelineContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withRouter(withTranslation()(AccommodationTimelineContainer));
