import BaseUserService from './BaseUserService';

const path = 'internal-user';

export default class InternalUserService extends BaseUserService {
    // Initializing important variables
    constructor() {
        super(path);
        this.path = path;
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            login: criteria.login,
            first_name: criteria.firstName,
            last_name: criteria.lastName,
            email: criteria.email,
            subcontractor: criteria.subcontractor?.id,
            role_types: criteria.roleTypes ? criteria.roleTypes.map((x) => x.enumValue) : [],
            status: criteria.status?.enumValue,
            active: criteria.active,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getSimpleList(role) {
        return this.fetch(`${this.domain}/${this.path}/list/simple${role ? '?role=' + role : ''}`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }
}
