import { Badge } from 'primereact/badge';
import { confirmDialog } from 'primereact/confirmdialog';
import { DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { default as React } from 'react';
import '../../assets/css/dataview-acom.scss';
import BaseContainer from '../../baseContainers/BaseContainer';
import ActionButton from '../../components/ActionButton';
import { CustomMessages } from '../../components/CustomMessages';
import Constants from '../../utils/constants';
import SettingService from '../../services/SettingService';
import AccommodationDashboardService from '../../services/AccommodationDashboardService';
import moment from 'moment';
import { Chip } from 'primereact/chip';

const screenWidth = Constants.SCREEN_WIDTH_DASHBOARD_PAGINATOR;
class AcommodationDashboardFullScreenContainer extends BaseContainer {
    constructor(props) {
        super(props, new AccommodationDashboardService());
        this.state = {
            list: [],
            loading: true,
            tooltipVisibile: false,
            legendVisibile: false,
            size: 0,
            first: 0,
            statusOptions: [],
            roleTypeOptions: [],
            categories: [],
            layout: 'grid',
            accommodations: [],
            rows: 0,
            firsts: 0,
            element: {
                name: '',
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            },
            paused: false,
            dashboardIntervalTime: 10000,
            soonIncomingEmployees: [],
            soonLeavingEmployees: []
        };
        this.settingService = new SettingService();
        this.accommodationService = new AccommodationDashboardService();
        this.onResize = this.onResize.bind(this);
    }

        componentDidMount() {
         this.getIntervalsTime();
         this.initializeFromBackend();
         if (this.props.handleHideMenu instanceof Function) {
            this.props.handleHideMenu();
        }
        window.addEventListener("resize", this.onResize);
  
        this.refreshJwtToken();
        if (this.scrollToError) {
            this.scrollToError = false;
            this.scrollToFirstError();
        }
    }

    setTimeIntervals(time){
        this.updateTimer = setInterval(() => 
        {if(this.state.paused === false){
            this.initializeFromBackend()
        }}, 60000);
        this.nextPageTimer = setInterval(() => 
        {if(this.state.paused === false){
            this.autoNextPage()
        }}, time);
        return time;
    }

    getIntervalsTime(){
        this.settingService
        .getValue('DASHBOARD_PAGE_INTERVAL_TIME')
        .then((res)=>{
            if(res.value !== undefined && res.value !== null) {
                let timeMiliseconds = res.value * 1000;
                this.setState({
                    dashboardIntervalTime: timeMiliseconds
                }, () =>{ 
                 this.setTimeIntervals(timeMiliseconds)})
            } else {
                this.setTimeIntervals(this.state.dashboardIntervalTime)
            }
            

        }).catch((ex) => {
            this.showErrorMessage('Nie udało się pobrać parametru czasu');
        });
     
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.updateTimer);
        clearInterval(this.nextPageTimer);
        window.removeEventListener("resize", this.onResize);
    }

    componentWillMount() {
        this.setState({
            width: this.getWindowWidth(),  height: this.getWindowHeight()
          });
    }

    getWindowWidth() {
        return Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        );
      }
      getWindowHeight() {
        return Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        );
      }

    onResize() {
        window.requestAnimationFrame(() => {
            if (this.props.url !== null && this.props.url !== "" && this.props.url !== undefined) {
                if (this.getWindowWidth() > screenWidth) {
                    let newCriteria = this.prepareCriteriaFromUrl(this.props.url, null);

                    this.service
                        .getDashboardWithPathCriteria(newCriteria)
                        .then((accommodations) => {
                            this.setState({
                                accommodations: accommodations.content,
                                accommodationsTotalElements: accommodations.totalElements,
                            });
                        })
                        .catch(() => {
                            this.showErrorMessage('Nie udało się pobrać listy noclegów dla dashboardu');
                        });
                }
            }
        });
    }

    renderBackLink() {
        return null;
    }

    renderFullscreenButton() {
        let { fullscreenExitUrl } = this.props;
        const href = `${fullscreenExitUrl}`;

        return (
            <ActionButton
                className='pdf-download-button'
                handleClick={this.handleGoToFullScreenView.bind(this, href)}
                iconName='mdi-fullscreen-exit'
            />
        );
    }
    renderPaginator() {
        const { criteria, firsts, rows } = this.state;
        return (
            <div>
                <Paginator className='dashboard-paginator' first={firsts} rows={this.countMaxElementsPerPage()} totalRecords={this.state.accommodationsTotalElements} onPageChange={(e) => { this.onPaginatorChange(e) }} />
                {this.state.paused === false ?
                    <ActionButton
                        className='pdf-download-button'
                        handleClick={() => this.setState({ paused: true })}
                        iconName='mdi-pause'
                    /> :
                    <ActionButton
                        className='pdf-download-button'
                        handleClick={() => this.setState({ paused: false })}
                        iconName='mdi-play'
                    />}

            </div>
        );
    }

    handleGoToFullScreenView(href, e) {
        e.preventDefault();
        window.location.href = href;
    }

    getCriteriaName() {
        return 'project-dashboard-sc';
    }

    updateSearchCriteria(criteria) {
        return {
            maxResultsPerPage: null,
            name: criteria.name,
            categories: criteria.categories,
            countries: criteria.countries,
            project: criteria.project,
            firstResult: criteria.firstResult,
            maxResults: this.countMaxElementsPerPage(),
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            employeesAssigned: criteria.employeesAssigned
        };
    }

    getCleanSearchCriteria() {
        return {
            maxResultsPerPage: null,
            name: '',
            categories: [],
            countries: [],
            project: undefined,
            firstResult: 0,
            maxResults: this.countMaxElementsPerPage(),
            sortField: 'dateStart',
            sortAsc: false,
            employeesAssigned: undefined
        };
    }

    readCriteriaFromCookie() {
        const cookie = this.service.readCriteria(this.getCriteriaName());
        const criteria =
            cookie !== undefined && cookie != null ? this.updateSearchCriteria(JSON.parse(cookie)) : this.getCleanSearchCriteria();
        this.removeCookie(this.getCriteriaName());
        this.setState({
            criteria,
        });
        return criteria;
    }

    autoNextPage() {
        let currentClickedBtn = document.getElementsByClassName('p-highlight');
        let length = currentClickedBtn.length;
        let nextPageBtn = null;
        if (this.getWindowWidth() > screenWidth) {
            if (length > 0) {
                if (currentClickedBtn[0] !== nextPageBtn) {
                    nextPageBtn = currentClickedBtn[0].nextSibling;
                }
            }
            if (nextPageBtn !== null) {
                nextPageBtn.click();
                console.log(" next page ");
            } else {
                let firstPage = document.getElementsByClassName('p-paginator-icon pi pi-angle-double-left');
                console.log(" back to first page")
                if (firstPage.length > 0) {
                    firstPage[0].click();
                }
            }
        }
    }

    prepareCriteriaFromUrl(urlCriteria, event) {
        let newMaxRes = this.countMaxElementsPerPage();
        let maxResVal = urlCriteria.split("&").filter(e => e.includes("max_result"))[0];
        let firstResVal = urlCriteria.split("&").filter(e => e.includes("first_result"))[0];
        let newMaxResult = "max_result=" + newMaxRes.toString();
        let newFirstResult = "first_result=";
        if (event !== null) {
            if (event.first !== null) {
                newFirstResult += event?.first.toString()
            }
            else {
                newFirstResult += '0';
            }
        }
        else { newFirstResult += '0'; }
        let newCriteria = urlCriteria.replace(maxResVal, newMaxResult).replace(firstResVal, newFirstResult);
        return newCriteria;
    }

    initializeFromBackend() {
        console.log(" data refreshed ")
        let criteria = this.readCriteriaFromCookie();

        if (this.props.url !== null && this.props.url !== "" && this.props.url !== undefined) {
            if (this.getWindowWidth() > screenWidth) {
                let newCriteria = this.prepareCriteriaFromUrl(this.props.url, null);
                this.service.getDashboardWithPathCriteria(newCriteria)
                    .then((accommodations) => {
                        this.setState({
                            accommodations: accommodations.content,
                            accommodationsTotalElements: accommodations.totalElements,
                        });
                    }).catch(() => {
                        this.showErrorMessage('Nie udało się pobrać listy noclegów dla dashboardu');
                    });
            } else {
                this.service.getDashboardWithPathCriteria(this.props.url)
                    .then((accommodations) => {
                        this.setState({
                            accommodations: accommodations.content,
                            accommodationsTotalElements: accommodations.totalElements,
                        });
                    }).catch(() => {
                        this.showErrorMessage('Nie udało się pobrać listy noclegów dla dashboardu');
                    });
            }
        }
        else {
            this.accommodationService
                .getAccommodationDashboard(criteria)
                .then((data) => {
                    this.setState({
                        accommodations: data.content,
                        accommodationsTotalElements: data.totalElements
                    })
                }).catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy noclegów dla dashboardu');
                });
        }

        this.accommodationService
            .getSoonIncomingEmployees().then((data) => {
                this.setState({
                    soonIncomingEmployees: data
                });
            }).catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy pracowników, którzy niedługo przyjadą do noclegu')
            })

        this.accommodationService
            .getSoonLeavingEmployees().then((data) => {
                this.setState({
                    soonLeavingEmployees: data
                });
            }).catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy pracowników, którzy niedługo opuszczą nocleg')
            })
    }


  
    textToTooltip(id, type) {   
        const soonIncoming = this.state.soonIncomingEmployees.filter(
            (ea) => ea.accommodationId === id
        );
        const soonLeaving = this.state.soonLeavingEmployees.filter(
            (ea) => ea.accommodationId === id
        );
        const today = moment(new Date());
        let textToDisplay = '';
        if (type === 'INCOMING_USERS_7DAYS') {
            soonIncoming
                .filter((x) => moment(x.dateStart).isSameOrBefore(moment(today).add(7, 'days')))
                .sort((x, y) => moment(x.dateStart) - moment(y.dateStart))
                .forEach((x) => {
                    textToDisplay += x.lastName + ' ' + x.firstName + ' ' + x.dateStart + '\n';
                });
            return "Pracownicy, którzy przyjadą w ciągu 7 dni:\n" + textToDisplay;
        }
        if (type === 'INCOMING_USERS_14DAYS') {
            soonIncoming
                .filter((x) => moment(x.dateStart).isSameOrBefore(moment(today).add(14, 'days')))
                .sort((x, y) => moment(x.dateStart) - moment(y.dateStart))
                .forEach((x) => {
                    textToDisplay += x.lastName + ' ' + x.firstName + ' ' + x.dateStart + '\n';
                });
            return "Pracownicy, którzy przyjadą w ciągu 14 dni:\n" + textToDisplay;
        }
        if (type === 'INCOMING_USERS_21DAYS') {
            soonIncoming
                .filter((x) => moment(x.dateStart).isSameOrBefore(moment(today).add(21, 'days')))
                .sort((x, y) => moment(x.dateStart) - moment(y.dateStart))
                .forEach((x) => {
                    textToDisplay += x.lastName + ' ' + x.firstName + ' ' + x.dateStart + '\n';
                });
            return "Pracownicy, którzy przyjadą w ciągu 21 dni:\n" + textToDisplay;
        }
    
        if (type === 'LEAVING_USERS_7DAYS') {
            soonLeaving
                .filter((x) => moment(x.dateEnd).isSameOrBefore(moment(today).add(7, 'days')))
                .sort((x, y) => moment(x.dateEnd) - moment(y.dateEnd))
                .forEach((x) => {
                    textToDisplay += x.lastName + ' ' + x.firstName + ' ' + x.dateEnd + '\n';
                });
            return "Pracownicy, którzy wyjadą w ciągu 7 dni:\n" + textToDisplay;
        }

        if (type === 'LEAVING_USERS_14DAYS') {
            soonLeaving
                .filter((x) => moment(x.dateEnd).isSameOrBefore(moment(today).add(14, 'days')))
                .sort((x, y) => moment(x.dateEnd) - moment(y.dateEnd))
                .forEach((x) => {
                    textToDisplay += x.lastName + ' ' + x.firstName + ' ' + x.dateEnd + '\n';
                });
            return "Pracownicy, którzy wyjadą w ciągu 14 dni:\n" + textToDisplay;
        }
        if (type === 'LEAVING_USERS_21DAYS') {
            soonLeaving
                .filter((x) => moment(x.dateEnd).isSameOrBefore(moment(today).add(21, 'days')))
                .sort((x, y) => moment(x.dateEnd) - moment(y.dateEnd))
                .forEach((x) => {
                    textToDisplay += x.lastName + ' ' + x.firstName + ' ' + x.dateEnd + '\n';
                });
            return "Pracownicy, którzy wyjadą w ciągu 21 dni:\n" + textToDisplay;
        }
        else{        
            return `Kategoria ${type}:\n` + textToDisplay;
        }
    }


    countMaxRowsPerPage() {
        let x = (this.getWindowHeight() - 60) / 318;
        return Math.floor(x);
    }

    countMaxElementsPerPage() {
        return this.countMaxRowsPerPage() * 12;
    }


    countUsersToTooltip(id, type) {
        const soonIncoming = this.state?.soonIncomingEmployees.filter(
            (ea) => ea.accommodationId === id
        );
        const soonLeaving = this.state?.soonLeavingEmployees.filter(
            (ea) => ea.accommodationId === id
        );
        const today = moment(new Date());
        switch (type) {
            case "INCOMING_USERS_7DAYS":
                return soonIncoming ? soonIncoming
                    .filter((x) => moment(x.dateStart).isSameOrBefore(moment(today).add(7, 'days')))
                    .length : 0;
            case "INCOMING_USERS_14DAYS":
                return soonIncoming ? soonIncoming
                    .filter((x) => moment(x.dateStart).isSameOrBefore(moment(today).add(14, 'days')))
                    .length : 0;
            case "INCOMING_USERS_21DAYS":
                return soonIncoming ? soonIncoming
                    .filter((x) => moment(x.dateStart).isSameOrBefore(moment(today).add(21, 'days')))
                    .length : 0;
            case "LEAVING_USERS_7DAYS":
                return soonLeaving ? soonLeaving
                    .filter((x) => moment(x.dateEnd).isSameOrBefore(moment(today).add(7, 'days')))
                    .length : 0;
            case "LEAVING_USERS_14DAYS":
                return soonLeaving ? soonLeaving
                    .filter((x) => moment(x.dateEnd).isSameOrBefore(moment(today).add(14, 'days')))
                    .length : 0;
            case "LEAVING_USERS_21DAYS":
                return soonLeaving ? soonLeaving
                    .filter((x) => moment(x.dateEnd).isSameOrBefore(moment(today).add(21, 'days')))
                    .length : 0;
        }
    }

    getIcon(tooltipType, allUsersCount, data) {
        const iconType = this.getIconByType(tooltipType);
        return (
            <i
                className={'employeeStatusInfo  icon mdi ' + iconType + ' p-overlay-badge'}
                style={{ fontSize: '20px', marginRight: '17px' }}
                data-pr-position="left"
                data-pr-tooltip={this.textToTooltip(data.id, tooltipType)}
            >
                {allUsersCount !== 0 && allUsersCount !== undefined ?
                    <Badge value={allUsersCount} size='normal' severity='success'></Badge> : null
                }
            </i>
        );
    }

    getIconByType(type) {
        switch (type) {
            case 'ALL_USERS':
                return "mdi-account-plus";
            case "INCOMING_USERS_7DAYS":
                return "mdi-account-plus"
            case "INCOMING_USERS_14DAYS":
                return "mdi-account-multiple-plus";
            case "INCOMING_USERS_21DAYS":
                return "mdi-account-multiple-plus-outline";
            case "LEAVING_USERS_7DAYS":
                return "mdi-account-minus";
            case "LEAVING_USERS_14DAYS":
                return "mdi-account-multiple-minus";
            case "LEAVING_USERS_21DAYS":
                return "mdi-account-multiple-minus-outline";
            case "PROJECTS_SUMMARY":
                    return "mdi-folder-star-outline"    
            case "NO_PROJECT_SUMMARY":
                return "mdi-folder-off-outline"
        }
    }

    getAllUsersStr(data) {
        let str = '';
        data?.allUsers
            .forEach((x) => {
                str += `${x.lastName}  ${x.firstName} \n`;
            })
        return str;
    }

    getProjectTooltipStr(project) {
        let str = `${project.name} \n`;
        project.employees.forEach((x) => {
            str += `${x.lastName}  ${x.firstName} \n`;
        });
        return str;
    }
    
    renderGridItem(data) {
        const incoming7daysCount = this.countUsersToTooltip(data.id, 'INCOMING_USERS_7DAYS');
        const incoming14daysCount = this.countUsersToTooltip(data.id, 'INCOMING_USERS_14DAYS');
        const incoming21daysCount = this.countUsersToTooltip(data.id, 'INCOMING_USERS_21DAYS');

        const leaving7daysCount = this.countUsersToTooltip(data.id, 'LEAVING_USERS_7DAYS');
        const leaving14daysCount = this.countUsersToTooltip(data.id, 'LEAVING_USERS_14DAYS');
        const leaving21daysCount = this.countUsersToTooltip(data.id, 'LEAVING_USERS_21DAYS');

        const reservedCount = data?.currentlyOccupied;
        const currentOccupancyString = `${reservedCount}/${data.maxSize}`;
        
        return (
            <div className='col-xxl-2 col-xl-6 col-lg-4 col-lg-6 col-md-6 col-sm-6'>
                <div className='product-grid-item card'>
                    <div className='product-grid-item-top-date-country'>
                        <div className='product-grid-item-top-date'>
                            {data.dateEnd ? <Tag value={data?.dateEnd} style={{ backgroundColor: '#689F38', marginLeft: '20px' }}></Tag> : null}
                        </div>
                        <div className='product-grid-item-top-country'>
                            <Tag value={data.country.code !== null && data.country.code !== "" ? data.country.code : data.country.name}
                                style={{
                                    backgroundColor: data.country.color,
                                    color: 'black'
                                }} >
                            </Tag>
                        </div>
                    </div>
                    <div className='product-name'>
                        <span className='product-category' style={{ paddingRight: '10px', marginTop: '3px' }}>
                            {data.name}
                        </span>
                    </div>
                    <div className='phone-number'>
                        <span>
                            <i
                                className='occupancy icon mdi mdi-bunk-bed'
                                style={{ marginLeft: '13px', fontSize: '14px' }}
                                data-pr-tooltip={this.getAllUsersStr(data)}
                            ></i>
                            <Tooltip target=".occupancy" />
                            <span style={{
                                marginRight: '10px'
                            }}>
                                {currentOccupancyString}
                            </span>
                            {data.phoneNumber ?
                            (
                                <i className='mdi mdi-phone phone-icon' style={{
                                    marginTop: '-2px',
                                    marginLeft: '5px'
                                }} ></i>
                            ) : null
                        }
                        <span>
                            {data?.phoneNumber}
                        </span>
                        </span>
                    </div>
                    {this.addressTemplate(data)}
                    {/* KATEGORIE */}
                    <div className='leader-div'>
                        {data.categories?.map((c) => {
                            return (
                                <Chip
                                    label={c.name}
                                    style={{
                                        backgroundColor: c.color.value,
                                        color: 'black',
                                        height: 'fit-content',
                                        marginTop: '10px',
                                        marginRight: '5px',
                                        fontSize:'12px'
                                    }}
                                ></Chip>
                            );
                        })}
                    </div>
                    <div className='product-grid-item-content-minimised'>
                        {/* IKONY W KAFELKU */}
                        <div className='dashboard-teil-c' style={{ height: '150px' }}>
                            <Tooltip id='asasas' target='.employeeStatusInfo' placement="left" />
                            <div style={{ fontSize: '10px', marginRight: '2px', marginBottom: '15px', marginTop: '5px', color: '#6c757d' }}>
                                {this.getIcon('INCOMING_USERS_7DAYS', incoming7daysCount, data)}
                                {this.getIcon('INCOMING_USERS_14DAYS', incoming14daysCount, data)}
                                {this.getIcon('INCOMING_USERS_21DAYS', incoming21daysCount, data)}
                                {this.getIcon('LEAVING_USERS_7DAYS', leaving7daysCount, data)}
                                {this.getIcon('LEAVING_USERS_14DAYS', leaving14daysCount, data)}
                                {this.getIcon('LEAVING_USERS_21DAYS', leaving21daysCount, data)}
                            </div>
                        </div>
                    </div>
                    <div style={{
                        position: 'absolute',
                        bottom: '0',
                    }}>
                        {data.projects?.map((p) => {
                            const icon = p.name.toUpperCase() === 'BEZ PROJEKTU' ? 'mdi-folder-off-outline' : 'mdi-folder-star-outline'
                            let usersCount = p?.employees.length;
                            return (
                                <i
                                    className={`employeeStatusInfo mdi  ${icon} p-overlay-badge`}
                                    style={{ fontSize: '20px', marginRight: '17px', color: '#6c757d'}}
                                    data-pr-position="left"
                                    data-pr-tooltip={this.getProjectTooltipStr(p)}
                                >
                                    {(usersCount && usersCount !== 0) ?
                                        <Badge value={usersCount} size='normal' severity='success'></Badge>
                                        : null
                                    }
                                </i>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }


    getAddressString(data) {
        const addressModel = data.address;
        let city = addressModel?.postalCode + ' ' + addressModel?.city;
        let street = addressModel?.streetName + ' ' + addressModel?.streetNumber;
        if (addressModel?.houseNumber != null && addressModel?.houseNumber.length > 0) {
            street += '/' + addressModel?.houseNumber;
        }
        const country = data?.country;
        const accommodationAddress = street + '\n ' +
            city + '\n ' +
            country?.name + '\n'
        return accommodationAddress;
    }

    addressTemplate(data) {
        return (
            <div className='address'>
                <span>
                    {this.getAddressString(data)}
                </span>
            </div>
        );
    }

    getLegendIcon(tooltipType){
        const iconType = this.getIconByType(tooltipType);
        return (
            <i
                className={'employeeStatusInfo  icon mdi ' + iconType + ' p-overlay-badge'}
                style={{ fontSize: '20px', marginRight: '17px' }}
            >
            </i>
        );
    }

    // LEGENDA
    legendDescription() {
        let legend = (
            <td key={'wn' + 1} className='p-datepicker-weeknumber'>
                <tr>
                    {this.getLegendIcon('INCOMING_USERS_7DAYS', null, null)}
                    <span className='p-disabled'>Liczba pracowników, którzy przyjadą w ciągu 7 dni</span>
                </tr>
                <tr>
                    {this.getLegendIcon('INCOMING_USERS_14DAYS', null, null)}
                    <span className='p-disabled'>Liczba pracowników, którzy przyjadą w ciągu 14 dni</span>
                </tr>
                <tr>
                    {this.getLegendIcon('INCOMING_USERS_21DAYS', null, null)}
                    <span className='p-disabled'>Liczba pracowników, którzy przyjadą w ciągu 21 dni</span>
                </tr>
                <tr>
                    {this.getLegendIcon('LEAVING_USERS_7DAYS', null, null)}
                    <span className='p-disabled'>Liczba pracowników, którzy wyjadą w ciągu 7 dni</span>
                </tr>
                <tr>
                    {this.getLegendIcon('LEAVING_USERS_14DAYS', null, null)}
                    <span className='p-disabled'>Liczba pracowników, którzy wyjadą w ciągu 14 dni</span>
                </tr>
                <tr>
                    {this.getLegendIcon('LEAVING_USERS_21DAYS', null, null)}
                    <span className='p-disabled'>Liczba pracowników, którzy wyjadą w ciągu 21 dni</span>
                </tr>
                <tr>
                    {this.getLegendIcon('PROJECTS_SUMMARY', null, null)}
                    <span className='p-disabled'>Liczba pracowników na poszczególnym projekcie</span>
                </tr>
                <tr>
                    {this.getLegendIcon('NO_PROJECT_SUMMARY', null, null)}
                    <span className='p-disabled'>Liczba pracowników bez projektu</span>
                </tr>
            </td>
        );
        return legend;
    }


    showLegendDialog(position) {
        confirmDialog({
            message: this.legendDescription(),
            header: 'Legenda',
            position,
            footer: [],
        });
    }

    renderHeader() {
        return (
            <div className='grid grid-nogutter'>
                <div className='col-6' style={{textAlign: 'left'}}>
                    <Dropdown
                        options={this.sortOptions}
                        value={this.state.sortKey}
                        optionLabel='label'
                        placeholder='Sort By Price'
                        onChange={this.onSortChange}
                    />
                </div>
                <div className='col-6' style={{textAlign: 'right'}}>
                    <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({layout: e.value})} />
                </div>
            </div>
        );
    }


    onPaginatorChange(event) {
        let maxres = this.countMaxElementsPerPage();

        this.setState(
            (prevState) => ({
                criteria: {
                    ...prevState.criteria,
                    firstResult: event.first,
                    maxResults: maxres,

                },
                firsts: event.first,
            }), () => {
                let newCriteria = this.prepareCriteriaFromUrl(this.props.url, event);

                this.service
                    .getDashboardWithPathCriteria(newCriteria)
                    .then((data) => {
                        this.setState({
                            accommodations: data.content,
                        });
                    })
                    .catch(() => {
                        this.showErrorMessage('Nie udało się pobrać listy noclegów dla dashboardu');
                    });
            }
        );
    };


    render() {
        return (
            <div>
             <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <div className='dashboard-fullscreen-container'>
                    <div className='row col-12'>
                        <div className='xd'>
                            {this.state.accommodations.length > 0
                                ? this.state.accommodations.map((p) => {
                                      return this.renderGridItem(p);
                                  })
                                : null}
                        </div>
                    </div>
                </div>
                <div className='justify-content-center d-flex flex-end'>
                    <ActionButton
                        handleClick={() => this.showLegendDialog('bottom-right')}
                        label='Legenda'
                        className='pdf-download-button'
                    ></ActionButton>
                    {this.renderFullscreenButton()}
                    {this.getWindowWidth() > screenWidth  ? this.renderPaginator() : null}
                </div>
            </div>
        );
    }
}

AcommodationDashboardFullScreenContainer.defaultProps = {
    fullscreenExitUrl: '/#/accommodation-dashboard',
    lazy: true,
    data: [],
};

AcommodationDashboardFullScreenContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
    data: PropTypes.array,
};

export default AcommodationDashboardFullScreenContainer;
