import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import EmployeeAbsenceReasonService from '../../services/EmployeeAbsenceReasonService';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import ActionLink from '../../components/ActionLink';
import SquareColorComponent from '../../components/inputs/SquareColorComponent';
import EmployeeAbsenceReasonEditDialog from './EmployeeAbsenceReasonEditDialog';
import YesNoDialog from '../../components/YesNoDialog';

class EmployeeAbsenceReasonList extends BaseListContainer {
    constructor(props) {
        super(props, new EmployeeAbsenceReasonService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleTypeOptions: [],
            dialogVisibile: false,
            deleteConfirmDialogVisible: false,
            element: this.initNewElement(),
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
    }

    initNewElement(){
        return {
            reason: '',                
            status: {enumValue: 'ACTIVE', label: 'Aktywny'},
        }
    }

    updateSearchCriteria(criteria) {
        return {
            reason: criteria.reason,          
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            reason: '',            
            status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('EmployeeAbsenceReasonStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
    }

    getCriteriaName() {
        return 'employeeAbsence-list-sc';
    }

    getContainerListName() {
        return 'employeeAbsence-list-container';
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='reason'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.reason}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Nazwa'}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />                
               
                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={'Status'}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        return (
            <span>
            <ActionLink
                iconName='mdi-pencil'
                iconSide='left'
                iconSize='icon-large'
                handleClick={(e) => this.handleOpenEditDialog(e, rowData)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
                rendered={rowData.status.enumValue ==='ACTIVE'}
            />
           <ActionLink
                 iconName='mdi-delete'
                 iconSide='left'
                 iconSize='icon-large'
                handleClick={(e) => this.handleOpenDeleteDialog(e, rowData)}
                variant='blue'
                className='edit-blue-link'
                key={'delete-button'}
                rendered={rowData.status.enumValue ==='ACTIVE'}
            />
           </span>
        );
    }

    handleOpenEditDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: this.cloneElement(rowData), dialogViewMode: 'EDIT'});
    }

    handleOpenDeleteDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({deleteConfirmDialogVisible: true, element: rowData, dialogViewMode: 'DELETE'});
    }

    prepareColumns() {
        return [
            {field: 'reason', header: 'Powód', sortable: true},                        
            {field: 'status', header: 'Status', sortable: true, body: this.enumTemplate.bind(this, 'status')},         
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];

    }
    squareComponent(rowData){
        return <SquareColorComponent value={rowData.color.value} />
    }

    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO')
            ? [
                  {
                      label: t('employee.absence.newButton'),
                      type: 'BUTTON',
                      className: 'float-right',
                      variant: 'blue',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                      iconColor: 'white',
                      onClick: this.handleOpenNewDialog,
                  },
              ]
            : [];
    }
    handleOpenNewDialog(e) {
        let basicElement = this.initNewElement();       
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW'});
    }

    handleDelete(e){
        if (e) {
            e.preventDefault();
        }
        console.log('delete {}', this.state.element)              
        this.service
            .remove(this.state.element)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('employee.absence.deleteSuccess'));
            })
            .catch((err) => {
                this.persistMessage('error', '', t('employee.absence.deleteError'));               
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({deleteConfirmDialogVisible: false});
            });
        
    }

    afterObjectUpdate() {       
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    render() {
        return (
            <div className='container-fluid employeeAbsenceReason-list'>
                <BreadcrumbsItem to='/employeeAbsenceReason-list'>{'Powody nieobecności'}</BreadcrumbsItem>
                {this.renderView()}
                {this.state.dialogVisibile ? (
                    <EmployeeAbsenceReasonEditDialog
                        className='employeeAbsenceReason-dialog'
                        key={`employeeAbsenceReason-dialog-${this.state.element?.id}`}
                        element={this.state.element}
                        onHide={(e) => this.setState({dialogVisibile: false})}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}                        
                        afterSave={(e) => {
                            this.setState({dialogVisibile: false});
                           this.afterObjectUpdate();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
                {this.state.deleteConfirmDialogVisible ? (
                <YesNoDialog
                    visible={this.state.deleteConfirmDialogVisible}
                    header={t('employee.absence.deleteHeader')}
                    name='visible'
                    onChange={(type, x, target) => {
                        if (target.value) {
                        
                            this.handleDelete();
                        } else {
                            this.setState({deleteConfirmDialogVisible: false})
                        }
                    }}
                    onHide={() => this.setState({deleteConfirmDialogVisible: false})}
                     >
                        {t('details.deleteConfirmation')+'\n' + this.state.element.reason}
                    </YesNoDialog> 
                ) : null}
            </div>
        );
    }
}

EmployeeAbsenceReasonList.defaultProps = {
    detailUrl: '/#/employee-absence-reason/details',
    newUrl: '/#/employee-absence-reason/create',
    lazy: true,
};

EmployeeAbsenceReasonList.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default EmployeeAbsenceReasonList;