/* eslint-disable react/jsx-max-props-per-line */
import PropTypes from 'prop-types';
import React from 'react';
import {Card} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import DivContainer from '../components/DivContainer';
import InputTextComponent from './inputs/InputTextComponent';
import ActionLink from './ActionLink';
import InputDropdownComponent from './inputs/InputDropdownComponent';
import InputMaskComponent from './inputs/InputMaskComponent';

export const ReportedDayCarsComponent = (props) => {
    const {
        element,
        viewMode,
        handleChange,
        optionCars,
        carEvents,
        stateField,
        optionDrivers,
        handleDelete,
        validator,
    } = props;
    return  (
        <DivContainer colClass={'row reported-day-extra-ev'}>
            <div colClass='row' className={viewMode === 'EDIT' || viewMode === 'NEW' ? 'col-md-11' : 'col-md-12'}>
                <Card header='Przejazdy' className='reported-day-cars'>
                    <span>
                        {element.direction.enumValue === 'TO' ? 'TAM' : element.direction.enumValue === 'FROM' ? 'POWRÓT' : 'Przejazd'}
                    </span>

                    {viewMode === 'VIEW' ? (
                        <div>
                        <DivContainer colClass='row'>
                            <InputTextComponent
                                id={`brand`}
                                name='car.brand'
                                label='Marka'
                                colClass='col-md-3'
                                value={element.car?.brand}
                                showLabel={true}
                                onChange={handleChange}
                                viewMode={viewMode}
                            />
                            <InputTextComponent
                                id={`licensePlate`}
                                name='licensePlate'
                                label='Nr rej'
                                colClass='col-md-3'
                                value={element.car?.licensePlate}
                                valueView={element.car?.licensePlate}
                                dataKey='id'
                                showLabel={true}
                                onChange={handleChange}
                                viewMode={viewMode}
                            />
                            <InputTextComponent
                                id={`odometer`}
                                name='odometer'
                                label='Licznik'
                                colClass='col-md-3'
                                value={element.odometer}
                                valueView={element.odometer}
                                dataKey='id'
                                showLabel={true}
                                onChange={handleChange}
                                viewMode={viewMode}
                            />
                        </DivContainer>
                        <DivContainer colClass='row'>                            
                            <InputTextComponent
                                id={`lastName`}
                                name='lastName'
                                label='Nazwisko kierowcy'
                                colClass='col-md-3'
                                value={element.driver?.lastName}
                                valueView={element.driver?.lastName}
                                dataKey='id'
                                showLabel={true}
                                onChange={handleChange}
                                viewMode={viewMode}
                            />
                            <InputTextComponent
                                id={`firstName`}
                                name='firstName'
                                label='Imię kierowcy'
                                colClass='col-md-3'
                                value={element.driver?.firstName}
                                valueView={element.driver?.firstName}
                                dataKey='id'
                                showLabel={true}
                                onChange={handleChange}
                                viewMode={viewMode}
                            />                            
                            <InputTextComponent
                                id={`time`}
                                name='time'
                                label='Czas (h)'
                                colClass='col-md-3'
                                value={element.time}
                                valueView={element.time}
                                dataKey='id'
                                showLabel={true}
                                onChange={handleChange}
                                viewMode={viewMode}
                            />                            
                        </DivContainer>
                        </div>
                    ) : (
                        <DivContainer colClass='row'>
                            <InputDropdownComponent
                                id={`direction`}
                                name='direction'
                                label={'Kierunek'}
                                value={element?.direction}
                                stateField={stateField}
                                options={[
                                    {enumValue: 'TO', label: 'TAM'},
                                    {enumValue: 'FROM', label: 'POWRÓT'},
                                ]}
                                optionLabel={'label'}
                                dataKey='enumValue'
                                onChange={handleChange}
                                insideTable
                                viewMode={viewMode}
                                validator={validator}
                                validators='required'
                            />
                          
                            <InputDropdownComponent
                                id={`car`}
                                name='car'
                                label={'Samochód'}
                                value={element?.car}
                                stateField={stateField}
                                options={optionCars}
                                optionLabel={'fullName'}
                                dataKey='fullName'
                                onChange={handleChange}
                                insideTable
                                viewMode={viewMode}
                                validator={validator}
                                validators='required'
                            />
                            <InputDropdownComponent
                                id={`driver`}
                                name='driver'
                                label={'Kierowca'}
                                value={element?.driver}
                                stateField={stateField}
                                options={optionDrivers}
                                optionLabel={'fullName'}
                                dataKey='fullName'
                                onChange={handleChange}
                                insideTable
                                viewMode={viewMode}
                                validator={validator}
                                validators='required'
                            />
                            <InputMaskComponent
                                id={`time`}
                                name='time'
                                label='Czas [HH:mm]'
                                value={element?.time}
                                showLabel={true}
                                onChange={(a, b, c, d, e) => {
                                    handleChange(a, b, c, d, e);
                                }}
                                mask='99:99'
                                stateField={stateField}
                                placeholder='00:00'
                                colClass='col-md-3'
                                viewMode={viewMode}
                                validator={validator}
                                validators='required'
                            />

                            <InputTextComponent
                                id={`odometer`}
                                name='odometer'
                                label='Licznik'
                                colClass='col-md-3'
                                value={element.odometer}
                                showLabel={true}
                                onChange={handleChange}
                                viewMode={viewMode}
                                stateField={stateField}
                                keyfilter={/^[0-9]*$/i}
                                validator={validator}
                                validators='required'
                            />
                        </DivContainer>
                    )}
                </Card>
            </div>
            {viewMode === 'EDIT' || viewMode === 'NEW' ? (
                <div className='col-md-1 bin-icon'>
                    <ActionLink
                        iconName='mdi-delete'
                        iconSide='left'
                        iconSize='icon-large'
                        handleClick={(e) => {
                            let carEv = carEvents.filter((ce) => ce !== element);
                            handleDelete(carEv);
                        }}
                        variant='blue'
                        className='edit-blue-link'
                    />
                </div>
            ) : null}
        </DivContainer>
    );
};

ReportedDayCarsComponent.defaultProps = {};

ReportedDayCarsComponent.propTypes = {
    element: PropTypes.array,
    optionCars: PropTypes.array,
    optionDrivers: PropTypes.array,
    rendered: PropTypes.bool,
    viewMode: PropTypes.string,
    stateField: PropTypes.string,
    carEvents: PropTypes.array,
    handleDelete: PropTypes.func,
    validator: PropTypes.func,
};

export default withTranslation()(ReportedDayCarsComponent);
