import moment from 'moment';
import BaseServiceCrud from './BaseServiceCrud';
import Constants from '../utils/constants';


export default class AccommodationService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'accommodation';
    }
    getCriteria(criteria) {
        return this.objToQueryString({    
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,

            status: criteria.status?.enumValue,        
            name: criteria.name,
            address: criteria.address,
            categories: criteria.categories?.map((x)=>x.id).join(',')
        });
    }

    add(element) {
        const e = this.normalizeAccommodation(this.cloneElement(element));
        return super.add(e);
    }
    update(element) {
        const e = this.normalizeAccommodation(this.cloneElement(element));
        return super.update(e);
    }

    normalizeAccommodation(e) {
        if (e.dateFrom) {
            e.dateFrom = moment(e.dateFrom).format(Constants.DATE_JSON_FORMAT);
        }
        if (e.dateTo) {
            e.dateTo = moment(e.dateTo).format(Constants.DATE_JSON_FORMAT);
        }
        return e;
    }
    getAccomodationTimeline(accommodationName, fullName, countries, categories){
        let url = `${this.domain}/${this.path}/timeline`;
        const params = [];
        if (accommodationName) {
            params.push('accommodationName=' + accommodationName);
        }
        if (fullName) {
            params.push('fullName=' + fullName);
        }
        if (countries) {
            params.push('countries=' + countries);
        }
        if (categories) {
            params.push('categories=' + categories)
        }
        if (params.length > 0) {
            url += '?' + params.join('&');
        }
        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getEmployeeAccomodationTimeline(accommodationName, fullName, countries, containsAccommodation, dateFrom, dateTo, address, projects,categories) {
        let url = `${this.domain}/${this.path}/employee-timeline`;
        const params = [];
        if (accommodationName) {
            params.push('accommodationName=' + accommodationName);
        }
        if (fullName) {
            params.push('fullName=' + fullName);
        }
        if (countries) {
            params.push('countries=' + countries);
        }
        if (containsAccommodation) {
            params.push('containsAccommodation=' + containsAccommodation)
        }
        if (dateFrom) {
            params.push('dateFrom=' + moment(dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT));
        }
        if (dateTo) {
            params.push('dateTo=' + moment(dateTo).format(Constants.DATE_GET_REQUEST_FORMAT));
        }
        if (address) {
            params.push('address=' + address);
        }
        if (projects) {
            params.push('projects=' + projects);
        }
        if(categories){
            params.push('categories=' + categories)
        }
        if (params.length > 0) {
            url += '?' + params.join('&');
        }
        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    search(query, dateStart, accommodationAddress, countries) {
        let url = `${this.domain}/${this.path}/search`;
        const params = [];
        if (query) {
            params.push('query=' + query);
        }
        if(dateStart){
            params.push('dateStart=' + moment(dateStart).format(Constants.DATE_GET_REQUEST_FORMAT));
        }
        if(accommodationAddress){
            params.push('address=' + accommodationAddress);
        }
        if(countries){
            params.push('countries=' + countries.map((x) => x.id).join(","))
        }
        if (params.length > 0) {
            url += '?' + params.join('&');
        }
        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    addAcommodation(empId,accommodation,dateStart,dateEnd){
        return this.fetch(`${this.domain}/${this.path}/employee-timeline`, {
            method: 'POST',
            body:  JSON.stringify({
                employeeId: empId,
                accomodation: {
                    id: accommodation.id,
                    maxCapacity: accommodation.accommodationNumber
                },
                dateStart: moment(dateStart).format(Constants.DATE_GET_REQUEST_FORMAT),
                dateEnd: moment(dateEnd).format(Constants.DATE_GET_REQUEST_FORMAT),
            })
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    assignEmployeeToAccommodation(element){
        element.users.forEach(user => {
            user.dateStart = moment(user.dateStart).format(Constants.DATE_GET_REQUEST_FORMAT)
            user.dateEnd = moment(user.dateEnd).format(Constants.DATE_GET_REQUEST_FORMAT)
        });
        return this.fetch(`${this.domain}/${this.path}/timeline`, {
            method: 'POST',
            body: JSON.stringify({
                users: element.users,
                accommodationId: element.accommodationId,
                day: moment(element.day).format(Constants.DATE_GET_REQUEST_FORMAT)
            })
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    deleteEmployeeAcommodation(empAccommodationId){
        return this.fetch(`${this.domain}/${this.path}/employee-accommodation/${empAccommodationId}`, {
            method: 'DELETE',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getEmployeeAccomodation(id){
        return this.fetch(`${this.domain}/${this.path}/employee-accommodation/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    updateEmployeeAccommodation(eaId,empId,accommodation,dateStart,dateEnd){
        return this.fetch(`${this.domain}/${this.path}/employee-accommodation/`, {
            method: 'POST',
            body: JSON.stringify({
                employeeId: empId,
                accomodation: {
                    id: accommodation.id,
                    empAccommodationId: eaId,
                    name: accommodation.name,
                    maxCapacity: accommodation.accommodationNumber
                },
                dateStart: moment(dateStart).format(Constants.DATE_GET_REQUEST_FORMAT),
                dateEnd: moment(dateEnd).format(Constants.DATE_GET_REQUEST_FORMAT),
            })
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getAssignedEmployees(id,day){
        let url = `${this.domain}/${this.path}/${id}/employees`;
        const params = [];
        if (day) {
            params.push('day=' + moment(day).format(Constants.DATE_GET_REQUEST_FORMAT));
        }
        if (params.length > 0) {
            url += '?' + params.join('&');
        }
        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });   
    }
    exportList(criteria, format) {
        const params = this.objToQueryString({
            fullName: criteria.fullName,
            accommodationName: criteria.accommodationName,
            countries: criteria.countries?.map((x) => x.id).join(','),
            dateFrom: moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT),
            dateTo: moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT),
            categories: criteria.categories?.map((x)=>x.id).join(','),
            containsAccommodation: criteria.containsAccommodation,
            project: criteria.project?.id,
            address: criteria.address
        });

        return this.fetch(`${this.domain}/${this.path}/export${params}`,
            {
                method: 'POST'
            },
            {
                'Content-Type': 'application/json',
                Accept: 'application/octet-stream'
            }
        ).then((res) => {
            return Promise.resolve(res);
        }).catch((err) => {
            throw err;
        });
    }
}
