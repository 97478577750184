import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class ReportedDayService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'reported-day';
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,
            date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            status: criteria.status?.enumValue,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    update(element) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/${element.id}`, {
            method: 'PUT',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    add(element) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/add`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    remove(element) {
        
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/${element.id}`, {
            method: 'DELETE',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    approve(elementId) {
        return this.fetch(`${this.domain}/${this.path}/approve/${elementId}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    reject(elementId) {
        return this.fetch(`${this.domain}/${this.path}/reject/${elementId}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    reopen(elementId) {
        return this.fetch(`${this.domain}/${this.path}/reopen/${elementId}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
