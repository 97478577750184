import {withTranslation} from 'react-i18next';

class CurrencyUtils {
    static currency(amount, minimumFractionDigits = 0) {
        try {
            if (amount !== undefined && amount !== null) {
               // return amount.toLocaleString('pl-PL', {style: 'currency', currency: 'PLN'});
              const formatter = new Intl.NumberFormat('pl-PL', {
                minimumFractionDigits: minimumFractionDigits,
                maximumFractionDigits: 20,
                style: 'currency', currency: 'PLN'
              })
              return CurrencyUtils.trauncateFractionAndFormat(formatter.formatToParts(amount), 2)
            } else {
                return '-';
            }
        } catch (ex) {
            console.err('CurrencyUtils error ->', ex);
            return 'Błąd formatowania kwoty';
        }
    }
    //bez tego zaokrąglał zawsze do całej zł :/
   static trauncateFractionAndFormat = (parts, digits) => {
        return parts.map(({ type, value }) => {
          if (type !== 'fraction' || !value || value.length < digits) {
            return value;
          }
          
          let retVal = "";
          for (let idx = 0, counter = 0; idx < value.length && counter < digits; idx++) {
            if (value[idx] !== '0') {
              counter++;
            }
            retVal += value[idx];
          }
          return retVal;
        }).reduce((string, part) => string + part);
      };
}

export default withTranslation()(CurrencyUtils);
export const currency = CurrencyUtils.currency;

