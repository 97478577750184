import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import InternalUserService from '../services/InternalUserService';
import {t} from 'i18next';
import ActionLink from '../components/ActionLink';
import UserEditDialog from './UserEditDialog';
import SubcontractorService from '../services/SubcontractorService';
import CriteriaMultiSelectComponent from '../components/criteria/CriteriaMultiSelectComponent';

class InternalUserListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleTypeOptions: [],
            dialogVisibile: false,
            element: this.emptyInternalUser(),
        };
        this.subcontractorService = new SubcontractorService();
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
    }

    emptyInternalUser() {
        return {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            roleType: undefined,
            subcontractor: null,
            status: {enumValue: 'ACTIVE', label: 'Aktywny'},
        };
    }

    updateSearchCriteria(criteria) {
        return {
            email: criteria.email,
            firstName: criteria.firstName,
            lastName: criteria.lastName,
            subcontractor: criteria.subcontractor,
            roleTypes: criteria.roleTypes,
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            email: '',
            firstName: '',
            lastName: '',
            subcontractor: undefined,
            roleTypes: [],
            status: {enumValue: "ACTIVE", label: "Aktywny" },
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('UserActivityStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
        this.enumService
            .getEnumList('RoleType')
            .then((roleTypeOptions) => {
                roleTypeOptions.forEach(r => r.enumLabel = r.label);
                this.setState({
                    roleTypeOptions,
                })
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ról');
            });
    }

    getCriteriaName() {
        return 'user-list-sc';
    }

    getContainerListName() {
        return 'user-list-container';
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='email-sc'
                    name='email'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.email}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('internaluser.email')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='firstName-sc'
                    name='firstName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.firstName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('internaluser.firstName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='lastName-sc'
                    name='lastName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.lastName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('internaluser.lastName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaMultiSelectComponent
                    id='roleTypes-sc'
                    name='roleTypes'
                    showClear
                    label={t('internaluser.role')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.roleTypes}
                    options={this.state.roleTypeOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
                {/*  TODO: dośc problematyczne czy to jest wymagane???? */}
                {/* <CriteriaDropdownComponent
                    id='subcontractor-sc'
                    name='subcontractor'
                    showClear
                    label={t('internaluser.subcontractorName')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.subcontractor}
                    options={this.state.subcontractors}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='name'
                    filterOptionValue='name'
                    refreshFromBackend
                /> */}
                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={t('internaluser.status')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }
    roleTemplate(field,rowData){
        return rowData.roleTypes.map((x)=>x.enumLabel).sort().join(', ');
    }


    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <ActionLink
                iconName='mdi-eye'
                iconSide='left'
                iconSize='icon-large'
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
        );
    }

    prepareColumns() {
        return [
            {field: 'email', header: t('internaluser.email'), sortable: true},
            {field: 'firstName', header: t('internaluser.firstName'), sortable: true},
            {field: 'lastName', header: t('internaluser.lastName'), sortable: true},
            {field: 'roleTypes', header: t('internaluser.role'), sortable: true, body: this.roleTemplate.bind(this, 'roleTypes')},
            {field: 'subcontractor.name', header: t('internaluser.subcontractorName'), sortable: true},
            {field: 'status', header: t('internaluser.status'), sortable: true, body: this.enumTemplate.bind(this, 'status')},
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_CTO')
            ? [
                  {
                      label: 'Nowe konto użytkownika',
                      type: 'BUTTON',
                      className: 'float-right',
                      variant: 'blue',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                      iconColor: 'white',
                      onClick: this.handleOpenNewDialog,
                  },
              ]
            : [];
    }
    handleOpenNewDialog(e) {
        let roleTypes = this.state.roleTypeOptions;
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: this.emptyInternalUser(), dialogViewMode: 'NEW', enumRoleTypes: roleTypes});
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/user-list'>{'Użytkownicy'}</BreadcrumbsItem>
                {this.renderView()}
                {this.state.dialogVisibile ? (
                    <UserEditDialog
                        key={`driver-dialog-${this.state.element?.id}`}
                        element={this.state.element}
                        onHide={(e) => this.setState({dialogVisibile: false})}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        roleTypes={this.state.roleTypeOptions}
                        afterSave={(e) => {
                            this.setState({dialogVisibile: false});
                            this.readMessage();
                            this.refreshTable();
                            this.unblockUi();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
            </div>
        );
    }
}

InternalUserListContainer.defaultProps = {
    detailUrl: '/#/user/details',
    newUrl: '/#/user/create',
    lazy: true,
};

InternalUserListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default InternalUserListContainer;
