import BaseServiceCrud from './BaseServiceCrud';


export default class ProjectCategoryService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'project-category';
      //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,            
            status: criteria.status?.enumValue,            
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
}
