import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import {ActionButton} from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import InputTextComponent from '../components/inputs/InputTextComponent';
import CarService from '../services/CarService';
import InputNumberComponent from '../components/inputs/InputNumberComponent';
import InputAutocompleteComponent from '../components/inputs/InputAutocompleteComponent';
import EmployeeService from '../services/EmployeeService';
import ActionLink from '../components/ActionLink';

class CarEditDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new CarService());
        this.state = {
            elementId: props.elementId,
            element: {
                name: '',
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
                mainUser: undefined,
            },

            filteredEmployees: [],
            viewMode: this.props.viewMode,
        };
        this.employeeService = new EmployeeService();
        this.searchEmployees = this.searchEmployees.bind(this);
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide(undefined);
                    }}
                />
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('car.addButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.viewMode !== 'DELETE'}
                />
            </div>
        );
    }

    searchEmployees(query) {
        this.employeeService
            .search(query, null, 'ACTIVE')
            .then((filteredEmployees) => this.setState({filteredEmployees: filteredEmployees}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy pracowników');
            });
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('car.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('car.editHeader')}`
                            : this.state.viewMode === 'DELETE'
                            ? `${t('car.deleteHeader')}`
                            : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    updateElement(data) {
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    setElement() {
        if (this.props.element) {
            this.updateElement(this.props.element);
        }
    }
    getContainerListName() {
        return 'car-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('car.addSuccess');
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('car.updateSuccess');
    }
    createOrUpdate() {
        const {element} = this.state;
        this.scrollToTop();
        if (this.props._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getAddSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getUpdateSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            }
        }
    }

    renderDetails() {
        const {t} = this.props;
        const {element} = this.state;
        if(element.mainUser
            && !this.state.filteredEmployees.map(x=>x.id).includes(element.mainUser.id)){
            this.state.filteredEmployees.push(element.mainUser);
        }            
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='licensePlate'
                        name='licensePlate'
                        label={t('car.licensePlate')}
                        colClass='col-md-6'
                        value={this.state.element.licensePlate}
                        validator={this.validator}
                        validators='required|max:10'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                    />
                    <InputTextComponent
                        id='brand'
                        name='brand'
                        label={t('car.brand')}
                        colClass='col-md-6'
                        value={this.state.element.brand}
                        validator={this.validator}
                        validators='required|max:100'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                    />
                    <InputTextComponent
                        id='model'
                        name='model'
                        label={t('car.model')}
                        colClass='col-md-6'
                        value={this.state.element.model}
                        validator={this.validator}
                        validators='required|max:100'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                    />
                    <InputNumberComponent
                        id='odometer'
                        name='odometer'
                        label={t('car.odometer')}
                        colClass='col-md-6'
                        value={this.state.element.odometer}
                        validator={this.validator}
                        min={0}
                        max={10000000}
                        minFractionDigits={0}
                        maxFractionDigits={0}
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                    />
                    <div className='row'>
                        <div className='col-md-10 mt-3'>
                            <InputAutocompleteComponent
                                id={`mainUser`}
                                name='mainUser'
                                value={this.state.element.mainUser}
                                dropdown
                                forceSelection
                                viewMode={this.props.viewMode}
                                validator={this.validator}
                                validators='not_required'
                                showLabel={false}
                                onChange={this.handleChange}
                                insideTable
                                filteredList={this.state.filteredEmployees}
                                filterList={(query) => this.searchEmployees(query)}
                                field='fullName'
                                minLength={3}
                                placeholder={t('search.users.placeholder')}
                            />
                        </div>
                        <div className='col-md-2 mt-4'>
                            <ActionLink
                                iconName='mdi-delete'
                                iconSide='left'
                                iconSize='icon-large'
                                rendered={this.props.viewMode !== 'VIEW'}
                                handleClick={() => {
                                    this.setState((prevState) => ({
                                        element: {
                                            ...prevState.element,
                                            mainUser: undefined,
                                        },
                                    }));
                                }}
                                variant='blue'
                                className='edit-blue-link'
                                key={'delete-button'}
                            />
                        </div>
                    </div>
                </DivContainer>
            </React.Fragment>
        );
    }
}

CarEditDialog.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
    visible: false,
    stationId: undefined,
};

CarEditDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
    roleTypes: PropTypes.object,
    className: PropTypes.string,
};

export default withTranslation()(CarEditDialog);
