import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Calendar} from '../base/Calendar';
import {addLocale} from 'primereact/api';
import {withTranslation} from 'react-i18next';
import {FilterMatchMode} from 'primereact/api';

class CriteriaCalendarRangeComponent extends React.Component {
    componentDidMount() {}

    render() {
        const {
            appendTo,
            colClass,
            dateFormat,
            dateFormatMoment,
            disabled,
            from,
            idFrom,
            idTo,
            label,
            labelFrom,
            labelTo,
            nameFrom,
            nameTo,
            name,
            onChange,
            onAfterStateChange,
            placeholderFrom,
            placeholderTo,
            readOnlyInput,
            showIcon,
            to,
            validator,
            validators,
            validatorsFromExt,
            monthNavigator,
            yearNavigator,
            yearRange,
            timeOnly,
            showTime,
            validateOnChange,
            showSeconds,
            maxDateInfinite,
            refreshFromBackend,
            showButtonBar,
            sharedLabel,
            renderDash,
            inputsClassName,
            inclusiveDate,
            view
        } = this.props;
        let validatorsTo = validators;
        let validatorsFrom = validatorsFromExt ? validatorsFromExt : validators;
        let fromDate = undefined;
        let toDate = undefined;
        addLocale('pl', {
            today: 'Dzisiaj',
            clear: 'Wyczyść',
            monthNames: [
                'Styczeń',
                'Luty',
                'Marzec',
                'Kwiecień',
                'Maj',
                'Czerwiec',
                'Lipiec',
                'Sierpień',
                'Wrzesień',
                'Październik',
                'Listopad',
                'Grudzień',
            ],
            monthNamesShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
            dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
            dayNamesShort: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
            dayNamesMin: ['N', 'P', 'W', 'Ś', 'Cz', 'Pt', 'S'],
            firstDayOfWeek: 1,
        });
        const timeFormat = showSeconds ? 'HH:mm:ss' : 'HH:mm';

        if (from && moment.isDate(from) && moment(from).isValid()) {
            fromDate = new Date(from);
            if (!timeOnly) {
                validatorsTo = `${validatorsTo}|after_or_equal:${moment(fromDate).format(dateFormatMoment)},${dateFormatMoment}`;
            } else {
                validatorsTo = `${validatorsTo}|after_or_equal_time~${moment(fromDate).format(timeFormat).toString()}`;
            }
        }
        if (to && moment.isDate(to) && moment(to).isValid()) {
            toDate = new Date(to);
            if (!timeOnly) {
                validatorsFrom = `${validatorsFrom}|before_or_equal:${moment(toDate).format(dateFormatMoment)},${dateFormatMoment}`;
            } else {
                validatorsFrom = `${validatorsFrom}|before_or_equal_time~${moment(toDate).format(timeFormat).toString()}`;
            }
        }
        const requiredFrom =
            validatorsFrom !== undefined && validatorsFrom.includes('required') && !validatorsFrom.includes('not_required');
        const requiredTo = validatorsTo !== undefined && validatorsTo.includes('required') && !validatorsTo.includes('not_required');
        const info = '. Aby przejść do edycji kolejnego pola kliknij Esc następnie Tab';
        return (
            <div className={colClass}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        {!sharedLabel ? null : (
                            <label id={`${idFrom}-label-id`} className='p-label' htmlFor={idFrom}>
                                {label}
                            </label>
                        )}
                        <div className='row'>
                            <div className={inputsClassName}>
                                {!sharedLabel ? (
                                    <label id={`${idFrom}-label-id`} className='p-label' htmlFor={idFrom} style={{width: '50%'}}>
                                        {label} {labelFrom}
                                    </label>
                                ) : null}
                                <Calendar
                                    className={'criteria-calendar-filter'}
                                    appendTo={appendTo}
                                    aria-describedby={`${idFrom}-error`}
                                    aria-label={`${label} ${labelFrom} - data w formacie ${placeholderFrom}${info}`}
                                    key={idFrom}
                                    id={idFrom}
                                    inputId={`${idFrom}-input`}
                                    name={nameFrom}
                                    style={{width: '100%'}}
                                    value={from}
                                    onChange={(e) =>
                                        onChange
                                            ? onChange(
                                                  'CALENDAR_RANGE',
                                                  [name, inclusiveDate],
                                                  e,
                                                  FilterMatchMode.DATE_AFTER,
                                                  refreshFromBackend,
                                                  onAfterStateChange
                                              )
                                            : null
                                    }
                                    readOnlyInput={readOnlyInput}
                                    locale='pl'
                                    dateFormat={dateFormat}
                                    placeholder={placeholderFrom}
                                    showIcon={showIcon}
                                    disabled={disabled}
                                    monthNavigator={monthNavigator}
                                    yearNavigator={yearNavigator}
                                    yearRange={yearRange}
                                    maxDate={validateOnChange ? toDate : maxDateInfinite ? undefined : !timeOnly ? new Date() : undefined}
                                    required={requiredFrom}
                                    timeOnly={timeOnly}
                                    showTime={showTime}
                                    showSeconds={showSeconds}
                                    showButtonBar={showButtonBar}
                                    view={view}
                                />
                                <div aria-live='assertive'>
                                    {validator ? validator.message(idFrom, labelFrom, from, validatorsFrom) : null}
                                </div>
                            </div>
                            {renderDash ? <div className='range-dash'></div> : null}
                            <div className={inputsClassName}>
                                {!sharedLabel ? (
                                    <label id={`${idTo}-label-id`} className='p-label' htmlFor={idTo} style={{width: '50%'}}>
                                        {label} {labelTo}
                                    </label>
                                ) : null}
                                <Calendar
                                    className={'criteria-calendar-filter'}
                                    appendTo={appendTo}
                                    aria-describedby={`${idTo}-error`}
                                    aria-label={`${label} ${labelTo} - data w formacie ${placeholderTo}${info}`}
                                    key={idTo}
                                    id={idTo}
                                    inputId={`${idTo}-input`}
                                    name={nameTo}
                                    style={{width: '100%'}}
                                    value={to}
                                    onChange={(e) =>
                                        onChange
                                            ? onChange(
                                                  'CALENDAR_RANGE',
                                                  [name, inclusiveDate],
                                                  e,
                                                  FilterMatchMode.DATE_BEFORE,
                                                  refreshFromBackend,
                                                  onAfterStateChange
                                              )
                                            : null
                                    }
                                    readOnlyInput={readOnlyInput}
                                    minDate={validateOnChange ? fromDate : undefined}
                                    locale='pl'
                                    dateFormat={dateFormat}
                                    placeholder={placeholderTo}
                                    showIcon={showIcon}
                                    disabled={disabled}
                                    monthNavigator={monthNavigator}
                                    yearNavigator={yearNavigator}
                                    yearRange={yearRange}
                                    maxDate={maxDateInfinite ? undefined : !timeOnly ? new Date() : undefined}
                                    required={requiredTo}
                                    timeOnly={timeOnly}
                                    showTime={showTime}
                                    showSeconds={showSeconds}
                                    showButtonBar={showButtonBar}
                                    view={view}
                                />
                                <div aria-live='assertive'>{validator ? validator.message(idTo, labelTo, to, validatorsTo) : null}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

CriteriaCalendarRangeComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    dateFormat: 'dd-mm-yy',
    dateFormatMoment: 'DD-MM-YYYY',
    disabled: false,
    labelFrom: 'od',
    labelTo: 'do',
    placeholderFrom: 'dd-mm-rrrr',
    placeholderTo: 'dd-mm-rrrr',
    readOnlyInput: true,
    showIcon: true,
    validators: 'not_required|date|date_format:DD-MM-YYYY,dd-mm-rrrr',
    monthNavigator: true,
    yearNavigator: true,
    yearRange: '1900:2030',
    timeOnly: false,
    showTime: false,
    validateOnChange: true,
    showSeconds: true,
    maxDateInfinite: false,
    refreshFromBackend: false,
    showButtonBar: false,
    sharedLabel: false,
    renderDash: false,
    inputsClassName: 'col-md-6',
    inclusiveDate: false,
};

CriteriaCalendarRangeComponent.propTypes = {
    appendTo: PropTypes.any,
    colClass: PropTypes.string,
    dateFormat: PropTypes.string,
    dateFormatMoment: PropTypes.string,
    disabled: PropTypes.bool,
    from: PropTypes.instanceOf(Date),
    idFrom: PropTypes.string.isRequired,
    idTo: PropTypes.string.isRequired,
    label: PropTypes.string,
    labelFrom: PropTypes.string,
    labelTo: PropTypes.string,
    monthNavigator: PropTypes.bool,
    nameFrom: PropTypes.string.isRequired,
    nameTo: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onAfterStateChange: PropTypes.func,
    placeholderFrom: PropTypes.string,
    placeholderTo: PropTypes.string,
    readOnlyInput: PropTypes.bool,
    showIcon: PropTypes.bool,
    to: PropTypes.instanceOf(Date),
    validator: PropTypes.object,
    validators: PropTypes.string,
    validatorsFromExt: PropTypes.string,
    yearNavigator: PropTypes.bool,
    yearRange: PropTypes.string,
    timeOnly: PropTypes.bool,
    showTime: PropTypes.bool,
    maxDateToday: PropTypes.bool,
    showSeconds: PropTypes.bool,
    maxDateInfinite: PropTypes.bool,
    refreshFromBackend: PropTypes.bool,
    showButtonBar: PropTypes.bool,
    sharedLabel: PropTypes.bool,
    renderDash: PropTypes.bool,
    inputsClassName: PropTypes.string,
    inclusiveDate: PropTypes.bool, // only with days
    view: PropTypes.string
};

export default withTranslation()(CriteriaCalendarRangeComponent);
