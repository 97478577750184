import PropTypes from 'prop-types';
import {OverlayPanel} from 'primereact/overlaypanel';
import React, {useState, useRef} from 'react';

const icons = [
    'mdi-account',
    'mdi-account-tie-voice',
    'mdi-account-tie-voice-off',
    'mdi-account-wrench',
    'mdi-account-alert',
    'mdi-account-badge',
    'mdi-account-hard-hat',
    'mdi-lightning-bolt',
    'mdi-book-account',
    'mdi-arm-flex',
    'mdi-book-alphabet',
    'mdi-book-check',
    'mdi-book-education',
    'mdi-bookmark-box-multiple-outline',
    'mdi-bookmark-box-outline',
    'mdi-brain',
    'mdi-bus',
    'mdi-car',
    'mdi-paperclip',
    'mdi-key-variant',
    'mdi-engine',
    'mdi-voice',
    'mdi-flash',
    'mdi-flash-auto',
    'mdi-lightning-bolt-circle',
    'mdi-wrench',
    'mdi-excavator',
    'mdi-shovel',
    'mdi-tractor',
    'mdi-tools',
    'mdi-hammer',
    'mdi-solar-power-variant'

];

export const IconPickerComponent = ({onChange, value, rendered, outsideComponentClassName, label}) => {

    const [selectedIcon, setSelectedItem] = useState(value);

    const op = useRef(null);

    const renderItems = () => {
        return icons.map((icon) => {
            const style = selectedIcon === icon ? {fontSize: '22px', color: 'grey'} : {fontSize: '22px'};
            return <i className={`mdi ${icon} cursor-pointer m-1`} style={style} onClick={(e) => handleOnChange(e, icon)}></i>;
        });
    };

    const handleOnChange = (e, icon) => {
        e.value = icon;
        setSelectedItem(icon);
        op.current.hide();
        // co by bylo jednolicie jak w Picker kolor 
        onChange(e);
    };
    // style lokalnie co by nie mieszac w css za duzo, a są one indywidualne dla tego konkretnego komponentu 
    return (
        rendered && (
            <div className={`${outsideComponentClassName}`}>
                <div>
                    <label id={`-label-id`} className='p-label' style={{marginBottom: '-6px', marginTop:"12px"}}>
                        {label}
                    </label>
                </div>
                <div style={{marginTop:"-6px"}}>
                <i
                    className={`mdi ${selectedIcon} cursor-pointer`}
                    style={{fontSize: '33px', marginLeft: '0px'}}
                    onClick={(e) => op.current.toggle(e)}
                ></i>
                </div>
                <OverlayPanel  ref={op} style={{width: '255px', height: 'auto', overflowY: 'auto', overflowX: 'hidden'}}>
                    {renderItems()}
                </OverlayPanel>
            </div>
        )
    );
};

IconPickerComponent.defaultProps = {
    rendered: true,
    viewMode: 'VIEW',
    outsideComponentClassName: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    componentClassName: 'col-12',
    value: 'mdi-account',
    label: 'Wybór ikony',
    onChange: () => {},
};

IconPickerComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    viewMode: PropTypes.string,
    rendered: PropTypes.bool,
    value: PropTypes.string,
    outsideComponentClassName: PropTypes.string,
    componentClassName: PropTypes.string,
    format: PropTypes.string,
    label: PropTypes.string,
};

export default IconPickerComponent;
