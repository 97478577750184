import BaseServiceCrud from './BaseServiceCrud';

export default class BaseUserService extends BaseServiceCrud {
    // Initializing important variables
    constructor(path) {
        super();
        this.path = path;
    }

    sendLinkForGenerateNewPassword(email) {
        const encodedValue = encodeURIComponent(email);
        return this.fetch(`${this.domain}/password/reset-link?email=${encodedValue}`, {
            method: 'POST',
        }).catch((err) => {
            throw err;
        });
    }

    verifyTokenAndSendNewPassword(token, body) {
        const encodedValue = encodeURIComponent(token);
        return this.fetch(`${this.domain}/${this.path}/password/verify-reset-link?token=${encodedValue}`, {
            method: 'POST',
            body: JSON.stringify(body),
        }).catch((err) => {
            throw err;
        });
    }

    changeRoleType(sapRoleId) {
        return this.fetch(`${this.domain}/${this.path}/change-role-type?sapRoleId=${sapRoleId}`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }

    checkStatusPassword(login, password) {
        return this.fetch(`${this.domain}/${this.path}/password/check-status-password`, {
            method: 'POST',
            body: JSON.stringify({login, password}),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    blockAccount(id) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/block/${id}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    unblockAccount(id) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/${this.path}/unblock/${id}`, {
            method: 'PUT',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
}
