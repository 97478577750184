import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import ActionLink from '../components/ActionLink';
import YesNoDialog from '../components/YesNoDialog';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import DriverRateService from '../services/DriverRateService';
import DriverRateEditDialog from './DriverRateEditDialog';

class DriverRateListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new DriverRateService());
        this.state = {
            element: null,
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            dialogVisibile: false,
            deleteConfirmDialogVisible: false,           
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
    }

    initNewElement() {
        return {
            dateFrom: null,
            employeeRate : {
                currency: {
                    enumValue: 'EUR'
                },
                value: null
            },
            leaderRate : {
                currency: {
                    enumValue: 'EUR'
                },
                value: null
            },
            extraRate : {
                currency: {
                    enumValue: 'EUR'
                },
                value: null
            }
        };
    }


    updateSearchCriteria(criteria) {
        return {
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            dateFrom: null,
            dateTo: null,            
            firstResult: 0,
            maxResults: 10,
            sortField: 'dateFrom',
            sortAsc: false,
        };
    }

    initializeFromBackend() {
    }

    getCriteriaName() {
        return 'driver-rate-list-sc';
    }

    getContainerListName() {
        return 'driver-rate-list-container';
    }

    renderCriteria() {
        return (
            <div className='row'>
               <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    name='date'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    label={'Data'}
                    colClass='col-xl-6 col-lg-12 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    showButtonBar
                    sharedLabel
                    renderDash
                    maxDateInfinite
                    placeholderFrom={'Data od'}
                    placeholderTo={'Data do'}
                    inputsClassName='col-6'
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi-pencil'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => this.handleOpenEditDialog(e, rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                    
                />
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => this.handleOpenDeleteDialog(e, rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                    
                />
            </span>
        );
    }

    handleOpenEditDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: this.cloneElement(rowData), dialogViewMode: 'EDIT'});
    }

    handleOpenNewDialog(e) {
        let basicElement = this.initNewElement();
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW'});
    }
    handleOpenDeleteDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({deleteConfirmDialogVisible: true, element: rowData, dialogViewMode: 'DELETE'});
    }

    prepareColumns() {
        return [
            {field: 'dateFrom', header: t('driver-rate.dateFrom'), sortable: true},
            {field: 'dateTo', header: t('driver-rate.dateTo'), sortable: true},
            {field: 'employeeRate.value', header: t('driver-rate.employee-rate'), sortable: true},
            {field: 'leaderRate.value', header: t('driver-rate.leader-rate'), sortable: true},          
            {field: 'extraRate.value', header: t('driver-rate.extra-rate'), sortable: true},     
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO')
            ? [
                  {
                      label: t('driver-rate.newButton'),
                      type: 'BUTTON',
                      className: 'float-right',
                      variant: 'blue',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                      iconColor: 'white',
                      onClick: this.handleOpenNewDialog,
                  },
              ]
            : [];
    }
   

    handleDelete(e) {
        if (e) {
            e.preventDefault();
        }
        console.log('delete {}', this.state.element);
        this.service
            .remove(this.state.element)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('driver-rate.deleteSuccess'));
            })
            .catch((err) => {
                this.persistMessage('error', '',  err.message);
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({deleteConfirmDialogVisible: false});
            });
    }

    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    render() {
        return (
            <div className='container-fluid car-list'>
                <BreadcrumbsItem to='/car-list'>{'Podwykonawcy'}</BreadcrumbsItem>
                {this.renderView()}
                {this.state.dialogVisibile ? (
                    <DriverRateEditDialog
                        className='car-dialog'
                        key={`car-dialog-${this.state.element?.id}`}
                        element={this.state.element}
                        onHide={(e) => this.setState({dialogVisibile: false})}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        afterSave={(e) => {
                            this.setState({dialogVisibile: false});
                            this.afterObjectUpdate();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
                {this.state.deleteConfirmDialogVisible ? (
                    <YesNoDialog
                    visible={this.state.deleteConfirmDialogVisible}
                    header={t('driver-rate.deleteHeader')}
                    name='visible'
                    onChange={(type, x, target) => {
                        if (target.value) {
                        
                            this.handleDelete();
                        } else {
                            this.setState({deleteConfirmDialogVisible: false})
                        }
                    }}
                    onHide={() => this.setState({deleteConfirmDialogVisible: false})}
                     >
                        {t('driver-rate.deleteConfirmation')}
                    </YesNoDialog> 
                ) : null}
            </div>
        );
    }
}

DriverRateListContainer.defaultProps = {
    detailUrl: '/#/driver-rate/details',
    newUrl: '/#/driver-rate/create',
    lazy: true,
};

DriverRateListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
    lazy: PropTypes.bool,
};

export default DriverRateListContainer;
