import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {ActionButton} from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import {Dialog} from 'primereact/dialog';
import {Card} from 'primereact/card';
import {t} from 'i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import NumberUtils from '../utils/NumberUtils';

class ExtraWorkProjectAggregationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closable: true,
        };
    }

    renderFooter() {
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide(undefined);
                    }}
                />
            </div>
        );
    }

    renderHeader() {
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>Zatwierdzone prace dodatkowe</label>
                </DivContainer>
            </DivContainer>
        );
    }

    render() {
        const {className, id, key, name, onHide, visible, style} = this.props;
        return (
            <Dialog
                ariaCloseIconLabel={t('dialog.closeDialog')}
                header={this.renderHeader()}
                visible={visible}
                resizable={false}
                className={className}
                style={style}
                key={`${key ? key : id ? id : name}-dialog`}
                onHide={() => (onHide ? onHide() : null)}
                closable={this.state.closable}
            >
                <Card footer={this.renderFooter()}>
                    <form noValidate onKeyDown={(e) => this.onKeyDown(e)}>
                        {this.renderDetails()}
                    </form>
                </Card>
            </Dialog>
        );
    }

    renderDetails() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <DataTable value={this.props.extras} responsiveLayout='scroll'>
                        <Column field='activityName' header={t('project.details.extraActivityName')}></Column>
                        <Column
                            body={this.extraActivityAggregationTotalHour.bind(this)}
                            className='number'
                            header={t('project.details.extraActivityHours')}
                            footer={this.extraActivityAggregationTotalHourFooter()}
                        ></Column>
                        <Column
                            body={this.extraActivityAggregationActivityPrice.bind(this)}
                            className='number'
                            header={t('project.details.extraActivityPrice')}
                            style={{minWidth: '150px'}}
                        />
                        <Column
                            body={this.extraActivityAggregationTotalActivityPrice.bind(this)}
                            className='number'
                            header={t('project.details.extraActivityPriceSum')}
                            style={{minWidth: '150px'}}
                            footer={this.extraActivityAggregationTotalActivityPriceFooter()}
                        />
                    </DataTable>
                </DivContainer>
            </React.Fragment>
        );
    }

    extraActivityAggregationTotalHourFooter() {
        let total = 0;
        this.props.extras?.forEach((e) => (total += parseFloat(e.totalHour)));
        return <span className='extras-footer number'>{NumberUtils.formatTimeIfPossible(total)}</span>;
    }

    extraActivityAggregationTotalHour(rowData) {
        if (rowData.totalHour) {
            return NumberUtils.formatTimeIfPossible(rowData.totalHour);
        } else {
            return '-';
        }
    }
    extraActivityAggregationActivityPrice(rowData) {
        if (rowData.activityPrice) {
            return NumberUtils.formatNumber(rowData.activityPrice);
        } else {
            return '-';
        }
    }
    extraActivityAggregationTotalActivityPrice(rowData) {
        if (rowData.totalActivityPrice) {
            const totalHours = rowData.totalActivityPrice / 60 ;
            return NumberUtils.formatNumber(totalHours);
        } else {
            return '-';
        }
    }

    extraActivityAggregationTotalActivityPriceFooter() {
        let total = 0;
        this.props?.extras.forEach((e) => (total += parseFloat(e.totalActivityPrice)));
        const totalHours = total / 60 ;
        return <span className='extras-footer number'>{NumberUtils.formatNumber(totalHours)}</span>;
    }
}

ExtraWorkProjectAggregationDialog.defaultProps = {};

ExtraWorkProjectAggregationDialog.propTypes = {
    extras: PropTypes.array.isRequired,
    onHide: PropTypes.func,
};

export default withTranslation()(ExtraWorkProjectAggregationDialog);
