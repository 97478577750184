import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import { ActionButton } from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import InputTextComponent from '../components/inputs/InputTextComponent';
import CriteriaTemplateService from '../services/CriteriaTemplateService';
class CriteriaTemplateAddingDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new CriteriaTemplateService());
        this.state = {
            elementId: props.elementId,
            element:{
                criteria: '',
                id: undefined,
                templateName: '',
            },
            viewMode: this.props.viewMode,
        };
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide(undefined);
                    }}
                />
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('settings.addButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.viewMode !== 'DELETE'}
                />
            </div>
        );
    }

    renderHeader() {
        const {t, viewMode} = this.props;
        return (
            <DivContainer colClass='col-12 transaction-dialog-header'>
                <DivContainer colClass='col-12'>
                    <label>{viewMode === 'EDIT' ? `${t('criteriaTemplate.editHeader')}` : `${t('criteriaTemplate.addHeader')}`}</label>
                </DivContainer>
            </DivContainer>
        );
    }
    getContainerListName() {
        return 'project-dashboard-list-container';
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('criteriaTemplate.updateSuccess');
    }
    getAddSucces() {
        const {t} = this.props;
        return t('criteriaTemplate.addSuccess');
    }
    updateElement(data) {
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    setElement() {
        let {element, viewMode, criteriaElementId} = this.props;
        if(criteriaElementId && (viewMode === 'EDIT' || viewMode === 'VIEW')){
            if (element) {
                this.updateElement(this.props.element);
            }
        }
        else
        {
            this.setState({loading: false}, this.initAfterSetElement());}
       
    }

    createOrUpdate() {
        const {element} = this.state;
        const {criteriaElementId} = this.props;
        let el = {templateName: element.templateName, criteria: JSON.stringify(this.props.criteria), id: element.id};
        this.scrollToTop();
        if (this.props._isMounted) {
            if (criteriaElementId === undefined || criteriaElementId === null) {
                this.service
                    .add(el)
                    .then((response) => {
                        
                        //this.blockUi();
                        
                        this.persistMessage('success', '', this.getAddSucces(response));
                        if (this.props.afterSave) {
                            
                            this.props.afterSave();
                            
                            this.unblockUi();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            } else {
                this.service
                    .update(el)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getUpdateSucces(response));
                        if (this.props.afterSave) {
                            
                            this.props.afterSave();
                            
                            this.unblockUi();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            }
        }
    }
    renderDetails() {
        const { viewMode} = this.props;

        return  (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='templateName'
                        name='templateName'
                        label={'Nazwa szablonu'}
                        colClass='col-md-6'
                        value={this.state.element.templateName}
                        validator={this.validator}
                        validators='required|max:50'
                        onChange={this.handleChange}
                        viewMode={viewMode}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

CriteriaTemplateAddingDialog.defaultProps = {
    currentUser: undefined,
    //viewMode: 'VIEW',
    visible: false,
    stationId: undefined,
    criteria: [],
    criteriaElementId: undefined,
    templateName: undefined,

};

CriteriaTemplateAddingDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    userId: PropTypes.number,
    criteria: PropTypes.array,
    criteriaElementId: PropTypes.number,
    templateName: PropTypes.string,
    

};

export default withTranslation()(CriteriaTemplateAddingDialog);
