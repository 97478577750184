import {InputText} from 'primereact/inputtext';
import queryString from 'query-string';
import {Messages} from 'primereact/messages';
import {Password} from 'primereact/password';
import PropTypes from 'prop-types';
import React from 'react';
import {Redirect} from 'react-router-dom';
import BaseContainer from '../baseContainers/BaseContainer';
import ActionButton from '../components/ActionButton';
import SimpleReactValidator from '../components/validator';
import AuthService from '../services/AuthService';
import InternalUserService from '../services/InternalUserService';
import BlockUi from './../components/waitPanel/BlockUi';
import {t} from 'i18next';
import ActionLink from '../components/ActionLink';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';

class LoginContainer extends BaseContainer {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.authService = new AuthService();
        this.internalUserService = new InternalUserService();
        this.demoMode = process.env.REACT_APP_DEMO_MODE === "true";
        this.state = {            
            showAppDownloadDialog: false,
            username: this.demoMode ? 'demo' : '',
            password: this.demoMode ? 'demo' : '',
            redirectToReferrer: true,
            authValid: true,
        };
        this.downloadApp = this.downloadApp.bind(this);
        this.authValidValidator = new SimpleReactValidator(
            {
                validators: {
                    auth: {
                        message: ':attribute',
                        rule: (val, params, validator) => {
                            return this.state.authValid;
                        }, // optional
                        required: true,
                    },
                },
            },
            t
        );
    }
    getContainerListName() {
        return 'login-page';
    }
    componentDidMount() {
        super.componentDidMount();
        const values = queryString.parse(this.props.location.search);
        this.targetLocation = values.location;
    }
    componentDidUpdate(){
        super.componentDidUpdate();
    }

    async downloadApp() {        
        if (this.demoMode) {            
            try {
                const response = await fetch('/static/app-release.apk', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
                });
            
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
            
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'app-release.apk';
                document.body.appendChild(a);
                a.click();
                a.remove();
            } catch (error) {
                console.error('Error downloading the file:', error);
            }
        }
    }

    handleFormSubmit(e) {
        if (e !== undefined) {
            e.preventDefault();
        }
        if (this.validator.allValid()) {
            this.blockUi();
            this.authService
                .login(this.state.username, this.state.password)
                .then((res) => {
                    localStorage.removeItem("log_out");
                    if (this.props.onAfterLogin) {
                        this.props.onAfterLogin();
                    }
                })
                .catch((err) => {
                    if (err.response != null) {
                        if (err.response.status === 401 || err.response.status === 403) {
                            this.setState((state) => ({
                                authValid: false,
                            }));
                            this.validator.showMessages();
                            this.forceUpdate();
                        } else {
                            this.showErrorMessage('Błąd logowania', 10000, true, 'Błąd ' + err.response.status);
                        }
                    } else {
                        this.showErrorMessage("Nie istnieje aktywny użytkownik o podanym loginie lub haśle", 10000);
                    }
                })
                .finally(() => {
                    this.unblockUi();
                });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.scrollToError = true;
            this.forceUpdate();
        }
    }

    render() {
        if (this.authService.loggedIn()) {
            return this.renderAfterAuth();
        } else {
            return this.renderBeforeAuth();
        }
    }

    renderAfterAuth() {
        const {redirectToReferrer} = this.state;
        if (redirectToReferrer === true) {
            return <Redirect to={this.targetLocation ? this.targetLocation : '/project-list'} />;
        }
        return <Redirect to={'/project-list'} />;
    }

    renderBeforeAuth() {
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <div id='main-login'>
                        <Messages id='custom-messages' ref={(el) => (this.messages = el)}></Messages>
                        <form
                            className='login-pane'
                            onSubmit={(e) => {
                                this.handleFormSubmit(e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    this.handleFormSubmit();
                                }
                            }}
                            noValidate
                        >
                            <div className='row'>
                                <div className='col-md-6 login-pane-left'>
                                    <div className='logo mt-4' alt='logo' />
                                    <div className='welcome-text-header'></div>
                                    <div className='welcome-text'>
                                        <div className='mt-4'>Zarządzanie projektami</div>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='login-label'>Nazwa użytkownika</div>
                                    <div location='user_field'>
                                        <InputText
                                            ariaLabel={'Nazwa użytkownika'}
                                            key={'username'}
                                            id={'username'}
                                            name={'username'}
                                            placeholder={''}
                                            style={{width: '100%'}}
                                            value={this.state.username}
                                            onChange={(e) => this.handleChange('TEXT', undefined, e, undefined, '')}
                                            required={true}
                                            validator={this.validator}
                                            validators='required|max:50'
                                        />
                                        <div className='login-label'>Hasło</div>
                                        <div location='pass_field'>
                                            <Password
                                                ariaLabel={'Hasło'}
                                                key={'password'}
                                                id={'password'}
                                                name={'password'}
                                                placeholder={''}
                                                style={{width: '100%'}}
                                                value={this.state.password}
                                                onChange={(e) => this.handleChange('TEXT', undefined, e, undefined, '')}
                                                promptLabel={'Hasło'}
                                                feedback={false}
                                                required={true}
                                                validator={this.authValidValidator}
                                                validators='not_required'
                                            />
                                        </div>
                                        <div className='row login-links'>
                                            <ActionLink
                                                label={t('password.didYouRemind')}
                                                variant='forgot-password-button '
                                                href={AppPrefixUtils.locationHrefUrl('/#/forgot-password')}
                                            />
                                        </div>
                                        <ActionButton
                                            label='Zaloguj'
                                            variant='login-button'
                                            handleClick={this.handleFormSubmit}
                                            disabled={this.state.captchaPreventAction}
                                        />
                                        
                                    </div>

                                    
                                </div>                                                                    
                            </div>
                            {this.demoMode ? (
                            <div className='row'>
                                <div className='col-md-12' style={{textAlign: 'right'}}>
                                    <ActionLink 
                                        label="Pobierz aplikację mobilną"
                                        variant='forgot-password-button'
                                        handleClick={() => this.setState({showAppDownloadDialog: true})}
                                    />
                                </div>
                            </div>) : null }

                            {this.demoMode ? (
                            <Dialog
                                ariaCloseIconLabel={t('dialog.closeDialog')}
                                header="Pobierz aplikację mobilną TimeHero!"
                                visible={this.state.showAppDownloadDialog}
                                resizable={false}
                                key="app-download-dialog"
                                onHide={() => this.setState({showAppDownloadDialog: false})}
                                closable={this.state.closable}
                            >
                                <Card footer={this.renderFooter()}>
                                    <form noValidate onKeyDown={(e) => this.onKeyDown(e)}>
                                        <span>
Aplikacja mobilna <b>TimeHero Demo</b> dostępna jest dla systemu Android. 
<br/>Kliknij poniżej, aby pobrać plik APK i zainstalować aplikację na swoim urządzeniu.
<ul>
    <li>Upewnij się, że masz włączoną opcję instalacji aplikacji z nieznanych źródeł w ustawieniach swojego telefonu.</li>
    <li>Po pobraniu otwórz plik APK, aby rozpocząć instalację.</li>
</ul>

Do logowania użyj następujących danych:
<ul>
    <li>Adres e-mail: <b>demo@demo.org</b></li>
    <li>Hasło: <b>demo</b></li>
</ul> 
                                        </span>
                                        <ActionLink 
                                            label="Pobierz aplikację mobilną"
                                            handleClick={() => this.downloadApp()}
                                        />

                                    </form>
                                </Card>
                            </Dialog>) : null}
                        </form>
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    }

}
LoginContainer.propTypes = {
    onAfterLogin: PropTypes.func,
};
export default LoginContainer;
