import BaseServiceCrud from './BaseServiceCrud';


export default class ExternalSupervisorService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'external-supervisor';
      //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            firstName: criteria.firstName,  
            lastName: criteria.lastName,     
            subcontractor: criteria.subcontractor,
            phoneNumber: criteria.phoneNumber,     
            status: criteria.status?.enumValue,            
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc
        });
    }
}
