import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class ProjectCumulativeReportService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'project-cumulative-report';
    }
    getCriteria(criteria) {    
        return this.objToQueryString({
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,

            dateFrom: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateTo: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,

            client_name: criteria.clientName,
            categories: criteria.categories ? criteria.categories.map(category => category.id).join(",") : null,
            project_name: criteria.projectName,
            exportType: criteria.exportType,
            countries: criteria.countries ? criteria.countries.map(c => c?.id).join(",") : null
        });
    }
    exportList(criteria, format, summary){
        const params=this.objToQueryString({
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,

            dateFrom: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateTo: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,

            client_name: criteria.clientName,
            categories: criteria.categories ? criteria.categories.map(category => category.id).join(",") : null,
            project_name: criteria.projectName,
            exportType: format,
            countries: criteria.countries ? criteria.countries.map(c => c?.id).join(",") : null
        });
        
		return this.fetch(`${this.domain}/${this.path}/export${params}`,
        {
            method: 'POST',
            body: JSON.stringify(summary)
        },
        {
            'Content-Type': 'application/json',
            Accept: 'application/octet-stream'
        }
		).then((res) => {
			return Promise.resolve(res);
		}).catch((err) => {
			throw err;
		});




    }
}