import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';
export default class UserDayService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'hourly-report';
        //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            full_name: criteria.fullName,
            date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            client_name: criteria.clientName,
            project_name: criteria.projectName,
            subcontractor: criteria.subcontractor,
            categories: criteria.categories ? criteria.categories.map(category => category.id).join(",") : null,
            countries: criteria.countries ? criteria.countries.map(country => country.id).join(",") : null ,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    exportList(criteria, format, clientTime){
        const params=this.objToQueryString({
            full_name: criteria.fullName,
            date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            client_name: criteria.clientName,
            project_name: criteria.projectName,
            subcontractor: criteria.subcontractor,
            categories: criteria.categories ? criteria.categories.map(category => category.id).join(",") : null,
            countries: criteria.countries ? criteria.countries.map(country => country.id).join(",") : null ,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            export_type: format,
            clientTime: clientTime
        });
        
		return this.fetch(`${this.domain}/${this.path}/export${params}`,
        {
            method: 'POST'
        },
        {
            'Content-Type': 'application/json',
            Accept: 'application/octet-stream'
        }
		).then((res) => {
			return Promise.resolve(res);
		}).catch((err) => {
			throw err;
		});

    }
}
