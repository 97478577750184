import React, {Component} from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import HeaderComponent from '../components/layouts/HeaderComponent';
import AuthService from '../services/AuthService';
import FooterComponent from '../components/layouts/FooterComponent';

export default function withAuth(
    AuthComponent,
    viewMode,
    roles,
    logoutFunc,
    label,
    whiteBackgroundStyle = 'default-white-background',
    backUrl,
    hideHeader = false,
    afterRenderComponent,
    setActiveMenu,
    updateApp,
    menuName,
    customPadding,
    customProps
) {
    const authService = new AuthService();
    return class AuthWrapped extends Component {
        constructor() {
            super();
            this.state = {
                user: null,
            };
            this.mainRef = React.createRef();
        }
        componentDidMount() {
            if (afterRenderComponent) {
                afterRenderComponent(true);
            }
            if (setActiveMenu) {
                setActiveMenu(menuName);
            }
            if (!authService.loggedIn()) {
                if (!!logoutFunc) {
                    logoutFunc(this.props.location.pathname);
                } else {
                    this.props.history.replace('/login?location=' + this.props.location.pathname);
                }
            } else {
                const userRoles = authService.getRoles();
                let authorized = false;
                if (roles !== undefined) {
                    roles.forEach((role) => {
                        if (userRoles.includes(role)) {
                            authorized = true;
                        }
                    });
                } else {
                    authorized = true;
                }
                if (authorized) {
                    try {
                        const profile = authService.getProfile();
                        this.setState({
                            user: profile,
                            viewMode: viewMode,
                            elementId: this.props.match.params.id,
                            params: this.props.match.params,
                        });
                    } catch (err) {
                        authService.logout();
                        this.props.history.replace('/login');
                    }
                } else {
                    this.props.history.replace('/403');
                }
            }
        }

        render() {
            if (this.state.user) {
                return (
                    <React.Fragment>
                        <div
                            className={`${authService.loggedIn() ? `header-white-background ${whiteBackgroundStyle}` : ''}`}
                            // style={{minHeight: whiteBackgroundHeight}}
                        />
                        <div
                            // ref={(el) => {
                            //     this.mainRef = el;
                            // }}
                            ref={this.mainRef}
                            className={`${
                                authService.loggedIn()
                                    ? customPadding
                                        ? `container ${customPadding} ${whiteBackgroundStyle}`
                                        : `container-fluid ${whiteBackgroundStyle}`
                                    : ''
                            }`}
                            style={{position: 'relative'}}
                        >
                            <BreadcrumbsItem icon='account-box' to='/start' className='p-link'>
                                Strona główna
                            </BreadcrumbsItem>
                            {!hideHeader ? <HeaderComponent logout={()=>{
                                localStorage.setItem('log_out', 'log_out');
                                logoutFunc()
                                }} label={label} /> : null}
                            {/* <BreadcrumbsComponent /> */}
                            <AuthComponent
                                mainRef={this.mainRef}
                                history={this.props.history}
                                currentUser={this.state.user}
                                viewMode={this.state.viewMode}
                                elementId={this.state.elementId}
                                backUrl={backUrl}
                                {...this.state}
                                {...customProps}
                                updateApp={updateApp}
                            />
                            <FooterComponent />
                        </div>
                    </React.Fragment>
                );
            } else {
                return null;
            }
        }
    };
}
