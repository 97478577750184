import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import DivContainer from '../../components/DivContainer';
import { DataTable } from 'primereact/datatable';
import { Card as PrimeCard } from 'primereact/card';
import { Column } from 'jspdf-autotable';
import {t} from 'i18next';
import { Chip } from 'primereact/chip';
import { Tooltip } from 'primereact/tooltip';

export const AccommodationDashboardTeilComponent = ({element, projects}) => {

    
    const returnHeaderTitle = (title) => {
        return (
            <div style={{
                fontWeight: 500,
                fontSize: '11px',
                float: 'left',
                marginLeft: '20px'
            }}>
                <i className='pi pi-tag product-category-icon' ></i>
                <span>{title}</span>
                
            </div>
        );
    }
    const getTooltipText = (absences) => {
        let textToDisplay = '';
        absences.forEach((a) => {
            return (textToDisplay += `${a.dateFrom}-${a.dateTo}` + '  ' + a.reason + '\n');
        });
        return `Planowane urlopy: \n ${textToDisplay}`;
    }

    return (
        <Card className='dashboard-teil2'>
            <DivContainer colClass='row' style={{width:'100px'}}>
            </DivContainer>
            {projects?.map((p) => {
                return (
                    <PrimeCard header={returnHeaderTitle(p?.name)}
                        style={{
                            marginTop: '10px'
                        }}>
                        <DivContainer colClass='row' style={{
                            minWidth: '100px',
                            width: '100%'                            
                        }}>
                      <DataTable responsiveLayout="scroll" emptyMessage={t('list.empty')} value={p.employees} className="accDashboardUsersTable" 
                            >
                                <Column field="lastName" header="Nazwisko i imię"
                                    style={{                                        
                                        width: 'auto',                                        
                            
                                    }}
                                    body={(e) => {
                                        const color = e?.color?.value;
                                        return (
                                            <Chip
                                                style={{
                                                    backgroundColor: color,
                                                    color: "black",
                                                }}
                                                template={() => {
                                                    return (
                                                        <div style={{
                                                            fontSize: "11px",
                                                            alignContent: 'right',
                                                            wordBreak: 'break-word'
                                                        }}>
                                                            {e?.lastName + ' ' + e?.firstName}
                                                        </div>
                                                    )
                                                }}

                                            />
                                        )
                                    }}></Column>
                                <Column field="accommodationDateEnd" header="Do"
                                    style={{
                                        width: '20px'                                        
                                    }}
                                    body={(e) => {
                                        return (
                                            <div style={{                                                
                                                alignContent: 'right',
                                                width: '70px',
                                                marginRight: '10px',
                                            }}>
                                                {e.accommodationDateEnd}
                                            </div>
                                        );
                                    }}
                                    ></Column>
                                <Column field="dateTo" header="Data zjazdu"
                                    style={{
                                        
                                    }}
                                    body={(e) => {
                                        return (                           
                                            <div style={{
                                            }}>
                                                <Tooltip target=".employeeAbsences" style={{fontSize: '11px'}}/>
                                                <div
                                                    className='employeeAbsences'
                                                    style={{ width: '70px' }}
                                                    data-pr-tooltip={getTooltipText(e.absences)}
                                                >
                                                 {e?.absences[0]?.dateFrom} 
                                                </div>
                                            </div>
                                            );
                                    }}></Column>
                            </DataTable> 

                         </DivContainer> 
                    </PrimeCard>
                );
            })}
        </Card>
    );
};

AccommodationDashboardTeilComponent.defaultProps = {
    value: undefined,
    rendered: true,
    viewMode: 'VIEW',
    pickerClassName: 'col-xl-12 col-lg-12 col-md-12 col-sm-12',
    componentClassName: 'col-12',
    users:[],
    leaders:[],
    additional:[],
};

AccommodationDashboardTeilComponent.propTypes = {
    viewMode: PropTypes.string,
    rendered: PropTypes.bool,
    value: PropTypes.string,
    element: PropTypes.array,
    users: PropTypes.array,
    leaders: PropTypes.array,
    categories: PropTypes.array,
    additional: PropTypes.array,
};

export default AccommodationDashboardTeilComponent;
