import {t} from 'i18next';
import {Messages} from 'primereact/messages';
import queryString from 'query-string';
import React from 'react';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import InputPasswordComponent from '../../components/inputs/InputPasswordComponent';
import SimpleReactValidator from '../../components/validator';
import BlockUi from '../../components/waitPanel/BlockUi';
import AccountService from '../../services/AccountService';
import ActionButton from '../../components/ActionButton';
import SettingService from '../../services/SettingService';

class AbstractConfirmPasswordContainer extends BaseDetailsContainer {
    // ABSTRACT METHODS
    // getPasswordParams = () => {};
    getService = () => new AccountService();
    handleCorrectPasswordToken = (wsRes) => {};
    handleErrorPasswordToken = (wsErr) => {};
    handleCorrectPasswordChange = (wsRes) => {};
    renderDetail = () => {};

    constructor(props) {
        super(props);
        this.handleChangeOpt = this.handleChangeOpt.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.state = {
            element: {
                approval: [],
            },
            newPassword: '',
            repeatPassword: '',
            maxLength: 15,
            minLength: 5,
            lowerCaseCount: 3,
            upperCaseCount: 4,
            numbersCount: 0,
            specialCharactersCount: 0,
            status: '',
        };

        this.validator = new SimpleReactValidator({}, this.translate);
        this.afterPreLogoutUser = this.afterPreLogoutUser.bind(this);
        this.scrollToTopOnMount = false;
    }
    getPasswordParams = () => {
        const settingService = new SettingService();
        return settingService
            .getPasswordParamsPublic()
            .then((resp) => {
                this.setState(
                    {
                        maxLength: resp['PASSWORD_MAX_LENGTH'],
                        minLength: resp['PASSWORD_MIN_LENGTH'],
                        lowerCaseCount: resp['PASSWORD_LOWERCASE_COUNT'],
                        upperCaseCount: resp['PASSWORD_UPPERCASE_COUNT'],
                        numbersCount: resp['PASSWORD_NUMBERS_COUNT'],
                        specialCharactersCount: resp['PASSWORD_SPECIAL_CHARS_COUNT'],
                    },
                    () => this.unblockUi()
                );
            })
            .catch((err) => this.unblockUi());
    };
    afterPreLogoutUser() {
        this.blockUi();
        this.getPasswordParams();
        const token = queryString.parse(this.props.location?.search)?.token;
        const tokenType = this.props.type === 'CREATE' ? 'USER_REGISTER' : 'PASSWORD_RECOVERY';
        this.blockUi();
        this.getService().checkToken(token, tokenType).then(this.handleCorrectPasswordToken).catch(this.handleCorrectPasswordToken);
    }

    componentDidMount() {
        super.componentDidMount();
        this.getPasswordParams();
        //  this.props.handlePreLogoutUser(this.afterPreLogoutUser);
        this.initBeforeSetElement();
    }

    handleChangeOpt(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    handleFormSubmit(e) {
        if (e) {
            e.preventDefault();
        }

        if (this.validator.allValid()) {
            const values = queryString.parse(this.props.location.search);

            const body = {newPassword: this.state.newPassword};
            const token = values.token;
            const tokenType = this.props.type === 'CREATE' ? 'USER_REGISTER' : 'PASSWORD_RECOVERY';
            this.getService()
                .setPassword(token, tokenType, body)
                .then(this.handleCorrectPasswordChange)
                .catch((err) => {
                    this.messages.show({
                        closable: false,
                        life: 30000,
                        severity: 'error',
                        summary: 'Błąd',
                        detail: err.message,
                    });
                });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.setState({
                valid: false,
            });
            this.forceUpdate();
        }
    }

    render() {
        return this.renderForm();
    }
    translate(key) {
        return t(key);
    }
    renderForm = () => {
        console.log()
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <div id='main-login'>
                        <Messages id='custom-messages' ref={(el) => (this.messages = el)}></Messages>

                        <form
                            className='login-pane'
                            onSubmit={(e) => {
                                this.handleFormSubmit(e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    this.handleFormSubmit();
                                }
                            }}
                            noValidate
                        >
                            <div className='row'>
                                <div className='col-md-6'>
                                   <div className='logo' alt='logo' />
                                    <div className='welcome-text-header'></div>
                                    <div className='welcome-text'>
                                        <div>Zarządzanie projektami</div>
                                    </div>
                                </div>
                                <div className='col-md-6 form-div'>
                                    <div className='welcome-text'>
                                        <h6 className='card-title text-align-left'>
                                            {this.props.type && this.props.type === 'CREATE'
                                                ? this.translate('password.setPasswordInstruction')
                                                : this.translate('password.setPasswordInstruction')}
                                        </h6>
                                    </div>
                                    <div location='user_field'>
                                        <InputPasswordComponent
                                            id='newPassword'
                                            // eslint-disable-next-line max-len
                                            // label={this.getPasswordLabel()}
                                            label={this.translate('password.newPassword')}
                                            labelForValidator={this.translate('password.newPassword')}
                                            name='newPassword'
                                            value={this.state.newPassword}
                                            validator={this.validator}
                                            onChange={(a, b, c) => this.handleChangeOpt(c)}
                                            viewMode='EDIT'
                                            validators={`required|extended_password:${this.state.minLength}:${this.state.maxLength}:${this.state.lowerCaseCount}:${this.state.upperCaseCount}:${this.state.numbersCount}:${this.state.specialCharactersCount}`}
                                            colClass='col-12'
                                        />
                                        <InputPasswordComponent
                                            id='repeatPassword'
                                            label={this.translate('password.repeatNewPassword')}
                                            name='repeatPassword'
                                            value={this.state.repeatPassword}
                                            feedback={false}
                                            validator={this.validator}
                                            onChange={(a, b, c) => this.handleChangeOpt(c)}
                                            viewMode='EDIT'
                                            validators={`required|equals:${this.state.newPassword},${this.translate(
                                                'password.newPassword'
                                            )}`}
                                            colClass='col-12'
                                        />
                                        <div class='col-12'>
                                            {/* <Button className='btn btn-lg btn-primary btn-block text-uppercase' label={this.translate('details.save')} /> */}
                                            <ActionButton
                                                label='Ustaw hasło'
                                                variant='login-button'
                                                handleClick={this.handleFormSubmit}
                                                disabled={this.state.captchaPreventAction}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </BlockUi>
            </React.Fragment>
        );
    };

    getPasswordLabel() {
        let message = this.translate('validator.extended_password');
        message = message.replace(':attribute', this.translate('password.newPassword'));
        message = message.replace(':min', this.state.minLength);
        message = message.replace(':max', this.state.maxLength);
        message = message.replace(':lower', this.validator.declination(this.state.lowerCaseCount, 2));
        message = message.replace(':upper', this.validator.declination(this.state.upperCaseCount, 3));
        message = message.replace(':number', this.validator.declination(this.state.numbersCount, 4));
        message = message.replace(':special', this.validator.declination(this.state.specialCharactersCount, 5));
        return message;
    }
}
export default AbstractConfirmPasswordContainer;
