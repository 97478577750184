import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import DivContainer from '../components/DivContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import InternalUserService from '../services/InternalUserService';
import UserEditDialog from './UserEditDialog';
import YesNoDialog from '../components/YesNoDialog';
import {t} from 'i18next';
import InputMultiSelectComponent from '../components/inputs/InputMultiSelectComponent';

class InternalUserContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new InternalUserService());
        this.state = {
            dialogVisibile: false,
            showConfirmBlockDialog: false,
            showConfirmUnblockDialog: false,
            elementId: this.props.elementId,
            element: {
                firstName: '',
                lastName: '',
                email: '',
                roleTypes: undefined,
                status: {enumValue: 'ACTIVE', enumLabel: 'Aktywny'},
                subcontractor: undefined,
            },
            editElement: null,
            loading: true,
            size: 0,
            first: 0,
            statusOptions: [],
            roleTypeOptions: [],
        };
        this.handleGoToDetail = this.handleGoToDetail.bind(this);
        this.prepareFooter = this.prepareFooter.bind(this);
        this.renderFooter = this.renderFooter.bind(this);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.blockAccount = this.blockAccount.bind(this);
        this.unblockAccount = this.unblockAccount.bind(this);
        this.showBlockConfirmation = this.showBlockConfirmation.bind(this);
        this.hideBlockConfirmation = this.hideBlockConfirmation.bind(this);
        this.showUnblockConfirmation = this.showUnblockConfirmation.bind(this);
        this.hideUnblockConfirmation = this.hideUnblockConfirmation.bind(this);
    }

    updateElement(data) {
        this.setState(
            {
                element: {
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    roleTypes: data.roleTypes,
                    status: data.status,
                    subcontractor: data.subcontractor,
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    initBeforeSetElement() {
        this.enumService
            .getEnumList('UserActivityStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
        this.enumService
            .getEnumList('RoleType')
            .then((data) => {
                data.forEach(r => r.enumLabel = r.label);
                this.setState({
                    roleTypeOptions: data
                })
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ról');
            });
    }

    prepareFooterItems() {
        const result = super.prepareFooterItems();
        if (this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_CTO') && this.props.viewMode === 'VIEW') {
            result.push({label: 'Edytuj', className: 'float-right', onClick: this.handleOpenDialog.bind(this)});
            if (this.state.element.status.enumValue === 'ACTIVE') {
                result.push({label: t('internaluser.blockButton'), className: 'fullred float-right', onClick: this.showBlockConfirmation});
            } else {
                result.push({
                    label: t('internaluser.unblockButton'),
                    className: 'fullred float-right',
                    onClick: this.showUnblockConfirmation,
                });
            }
        }
        return result;
    }

    handleOpenDialog(e) {
        this.setState({dialogVisibile: true, editElement: this.cloneElement(this.state.element), dialogViewMode: 'EDIT'});
    }

    showBlockConfirmation() {
        this.setState({showConfirmBlockDialog: true});
    }

    hideBlockConfirmation() {
        this.setState({showConfirmBlockDialog: false});
    }

    showUnblockConfirmation() {
        this.setState({showConfirmUnblockDialog: true});
    }

    hideUnblockConfirmation() {
        this.setState({showConfirmUnblockDialog: false});
    }
    blockAccount() {
        this.blockUi();
        this.service
            .blockAccount(this.state.element.id)
            .then((result) => {
                this.showSuccessMessage(t('internaluser.messages.accountBlockedSuccess'));
                this.updateElement(result);
                this.hideBlockConfirmation();
                this.unblockUi();
            })
            .catch((err) => {
                this.showErrorMessage(err.message);
                this.hideBlockConfirmation();
                this.unblockUi();
            });
    }

    unblockAccount() {
        this.blockUi();
        this.service
            .unblockAccount(this.state.element.id)
            .then((result) => {
                this.showSuccessMessage(t('internaluser.messages.accountUnblockedSuccess'));
                this.updateElement(result);
                this.hideUnblockConfirmation();
                this.unblockUi();
            })
            .catch((err) => {
                this.showErrorMessage(err.messages);
                this.hideUnblockConfirmation();
                this.unblockUi();
            });
    }

    isPdfButtonRendered() {
        return false;
    }
    selectedItemTemplate(e){
        return e.id;
    }
    
    renderDetails() {
        let backUrl = this.props.backUrl;
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <DivContainer colClass='col-12'>
                        <div className='row profile'>
                            <InputTextComponent
                                id='firstName'
                                name='firstName'
                                label={t('internaluser.details.firstName')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.firstName}
                                validator={this.validator}
                                validators='required|alpha|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />

                            <InputTextComponent
                                id='lastName'
                                name='lastName'
                                label={t('internaluser.details.lastName')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.lastName}
                                validator={this.validator}
                                validators='required|alpha|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                        </div>
                        <div className='row profile'>
                            <InputTextComponent
                                id='email'
                                name='email'
                                label={t('internaluser.details.email')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.email}
                                validator={this.validator}
                                validators='required|email|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                            <InputMultiSelectComponent
                                id='roleTypes'
                                name='roleTypes'
                                label={t('internaluser.details.role')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.roleTypes}
                                validator={this.validator}
                                options={this.state.roleTypeOptions}
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                                dataKey='enumValue' 
                                validators='required'
                                optionLabel='enumLabel'
                                selectedItemTemplate={this.selectedItemTemplate}
                            />
                        </div>
                        <div className='row profile'>
                            <InputDropdownComponent
                                id='subcontractor'
                                name='subcontractor'
                                label={t('internaluser.details.subcontractorName')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.subcontractor}
                                validator={this.validator}
                                options={this.state.subcontractors}
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                                dataKey='id'
                                filter={true}
                                showClear={true}
                                validators='not_required'
                                optionLabel='name'
                                valueView={this.state.element?.subcontractor?.name}
                            />
                            {this.props.viewMode !== 'NEW' ? (
                                <InputDropdownComponent
                                    id='status'
                                    name='status'
                                    label={t('internaluser.details.status')}
                                    colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                    value={this.state.element.status}
                                    validator={this.validator}
                                    options={this.state.statusOptions}
                                    onChange={null}
                                    viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode}
                                    optionLabel='label'
                                    dataKey='enumValue'
                                    validators='not_required'
                                    rendered={this.props.viewMode !== 'NEW'}
                                    valueView={this.state.element.status ? this.state.element.status.label : ''}
                                />
                            ) : null}
                        </div>
                    </DivContainer>
                </DivContainer>
                {this.state.dialogVisibile ? (
                    <UserEditDialog
                        key={`driver-dialog-${this.state.element?.id}`}
                        element={this.state.editElement}
                        onHide={(e) => this.setState({dialogVisibile: false})}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        roleTypes={this.state.roleTypeOptions}
                        afterSave={(e) => {
                            this.setState({dialogVisibile: false});
                            window.location.href = backUrl;
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
                {this.state.showConfirmBlockDialog && this.state.element.status.enumValue === 'ACTIVE' ? (
                    <YesNoDialog
                        visible={this.state.showConfirmBlockDialog}
                        header={t('internaluser.details.dialogBlockHeader')}
                        name='visible'
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.blockAccount();
                            } else {
                                this.hideBlockConfirmation();
                            }
                        }}
                        onHide={() => {
                            this.hideBlockConfirmation();
                        }}
                    >
                        {t('internaluser.details.dialogBlockQuestion')}
                    </YesNoDialog>
                ) : this.state.showConfirmUnblockDialog && this.state.element.status.enumValue !== 'ACTIVE' ? (
                    <YesNoDialog
                        visible={this.state.showConfirmUnblockDialog}
                        header={t('internaluser.details.dialogUnblockHeader')}
                        name='visible'
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.unblockAccount();
                            } else {
                                this.hideUnblockConfirmation();
                            }
                        }}
                        onHide={() => {
                            this.hideUnblockConfirmation();
                        }}
                    >
                        {t('internaluser.details.dialogUnblockQuestion')}
                    </YesNoDialog>
                ) : null}
            </React.Fragment>
        );
    }

    handleCloseDialog(e, callback) {
        this.setState({dialogVisibile: false, element: undefined}, () => {
            if (callback) callback();
        });
    }

    handleGoToDetail(e, rowData, x) {
        if (e) {
            e.preventDefault();
        }
        this.setState({stepView: 'DETAILS', element: rowData});
    }
}
InternalUserContainer.defaultProps = {
    backUrl: '#/user-list',
    cancelUrl: '/#/user-list',
    editUrl: '/#/user/edit',
};

InternalUserContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};
export default withTranslation()(InternalUserContainer);
