/* eslint-disable react/jsx-max-props-per-line */
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import ActionLink from '../components/ActionLink';
import DivContainer from './DivContainer';
import InputDropdownComponent from './inputs/InputDropdownComponent';
import InputMaskComponent from './inputs/InputMaskComponent';
import InputTextComponent from './inputs/InputTextComponent';
import NumberUtils from '../utils/NumberUtils';

export const ReportedDayExtraEventsComponent = (props) => {
    const {element, viewMode, handleChange, activityTypes, index, stateField, handleDelete, state, validator, countTotalTimeOnBlur} = props;
    return (
        <DivContainer colClass={'row reported-day-extra-ev'}>
            <div className={viewMode === 'EDIT' || viewMode === 'NEW' ? 'col-md-11' : 'col-md-12'}>
                <Card header='Przejazdy' className='reported-day-cars'>
                    <span>{element.activityModel.name}</span>

                    <DivContainer colClass='row'>
                        <InputDropdownComponent
                            id={`activityModel${index}`}
                            name='activityModel'
                            label='Nazwa'
                            value={element.activityModel}
                            stateField={`element.extraEvents[${index}]`}
                            options={activityTypes}
                            optionLabel={'name'}
                            dataKey='id'
                            onChange={handleChange}
                            viewMode={viewMode}
                            validator={validator}
                            validators='required'
                            colClass='col-md-4'
                        />
                        <InputTextComponent
                            id={`description`}
                            name='description'
                            label='Opis'
                            colClass='col-md-3'
                            value={element?.description}
                            stateField={stateField}
                            onChange={handleChange}
                            showLabel={true}
                            viewMode={viewMode}
                            validator={validator}
                            validators='required'
                        />
                        <InputTextComponent
                            id={`employeeCount`}
                            name='employeeCount'
                            label='Liczba pracowników'
                            colClass='col-md-3'
                            value={element?.employeeCount}
                            onChange={handleChange}
                            showLabel={true}
                            viewMode={viewMode}
                            stateField={stateField}
                            keyfilter={/^[0-9]*$/i}
                            validator={validator}
                            validators='required'
                            onBlur= { (e) => {
                                let newTimeInMinutes = NumberUtils.formatTimeFromMaskToMinutes(element?.time);                      
                                let totalTimeInMinutes = parseInt(e.target.value) * newTimeInMinutes;                           
                                let newTotalTime = NumberUtils.formatTimeIfPossibleToMaskComponent(totalTimeInMinutes);
                                element.totalHour = newTotalTime;

                            }}
                        />

                        <InputMaskComponent
                            id={`time`}
                            name='time'
                            label='Czas [HH:mm]'
                            value={element?.time} 
                            showLabel={true}
                            onChange={(a,b,c,d,e) => {
                                handleChange(a,b,c,d,e)}} 
                            mask='99:99' 
                            stateField={stateField}
                            placeholder='00:00'
                            colClass='col-md-4'
                            viewMode={viewMode}
                            validator={validator}
                            validators='required'
                            onBlur= { (e) => {
                                let newTimeInMinutes = NumberUtils.formatTimeFromMaskToMinutes(e.target.value);
                                let totalTimeInMinutes = element?.employeeCount * newTimeInMinutes;
                                let newTotalTime = NumberUtils.formatTimeIfPossibleToMaskComponent(totalTimeInMinutes);
                                    element.totalHour = newTotalTime;
                            }}

                        />
                         <InputMaskComponent
                            id={`totalHour`}
                            name='totalHour'
                            label='Całkowity czas [HH:mm]'
                            value={element?.totalHour} 
                            showLabel={true}
                            onChange={(a,b,c,d,e) => {
                                handleChange(a,b,c,d,e)}} 
                            mask='99:99' 
                            stateField={stateField}
                            placeholder='00:00'
                            colClass='col-md-3'
                            viewMode={viewMode}
                            disabled
                            
                        />
                    </DivContainer>
                </Card>
            </div>
            {viewMode === 'EDIT' || viewMode === 'NEW' ? (
                <div className='col-md-1 bin-icon'>
                    <ActionLink
                        iconName='mdi-delete'
                        iconSide='left'
                        iconSize='icon-large'
                        rendered={viewMode !== 'VIEW'}
                        handleClick={(e) => {
                            const ee = state.element.extraEvents?.filter((ee) => ee !== element);
                            handleDelete(ee);
                        }}
                        variant='blue'
                        className='edit-blue-link'
                        key={'delete-button'}
                    />
                </div>
            ) : null}
        </DivContainer>
    );
};

ReportedDayExtraEventsComponent.defaultProps = {};

ReportedDayExtraEventsComponent.propTypes = {
    element: PropTypes.object,
    rendered: PropTypes.bool,
    viewMode: PropTypes.string,
    activityTypes: PropTypes.array,
    index: PropTypes.any,
    stateField: PropTypes.string,
    handleDelete: PropTypes.func,
    validator: PropTypes.func,
    state: PropTypes.object,
};

export default withTranslation()(ReportedDayExtraEventsComponent);
