import InputTextComponent from './InputTextComponent';
import PropTypes from 'prop-types';

export const LinkComponent = ({id, link, viewMode, validator, onLinkChange, className}) => {
    return (
        <InputTextComponent
            id={`value-${id}`}
            name='value'
            label={'Link'}
            colClass={'col-xl-5 col-lg-5 col-md-5 col-sm-5'}
            value={link?.value}
            stateField={''}
            validators='required'
            validator={validator}
            onChange={(type, arr, event) => {
                onLinkChange(type, arr, event);
            }}
            rendered={true}
            viewMode={viewMode}
            renderValue={(value) => {
                return (
                    <a href={value} style={{ display: 'block', wordWrap: 'break-word', maxWidth: '300px' }}>{value}</a>
                )
            }}
        />
    )
      
};
LinkComponent.defaultProps = {
    links: [],
    viewMode: 'VIEW',
    className: 'col-6',
};

LinkComponent.propTypes = {
    link: PropTypes.string.isRequired,
    viewMode: PropTypes.string,
    className: PropTypes.string,
    validator: PropTypes.object,
    onLinkChange: PropTypes.func.isRequired,
};
