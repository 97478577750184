import moment from 'moment/moment';

export default class EmployeeModel {
    constructor() {
        this.id = undefined;
        this.firstName = '';
        this.lastName = '';
        this.subcontractor = undefined;
        this.loginAllowed = false;
        this.email = '';
        this.phoneNumber = '';
        this.phoneNumber = '';
        this.status = {enumValue: 'ACTIVE', label: 'Aktywny'};
        this.contract = {price: {}, cost: {}};
        this.contracts = [];
        this.absences = [];
        this.alarms = [];
        this.documents = [];
        this.categories = [];
        this.link = '';
        this.createDate = undefined;
        this.externalManager = undefined;
    }

    static assign(from, to) {
        if (from) {
            to.id = from.id ? from.id : undefined;
            to.loginAllowed = from?.loginAllowed ? from.loginAllowed : false;
            to.firstName = from.firstName ? from.firstName : '';
            to.lastName = from.lastName ? from.lastName : '';
            to.subcontractor = from.subcontractor ? from.subcontractor : null;
            to.email = from.email ? from.email : '';
            to.link = from.link ? from.link : '';
            to.phoneNumber = from.phoneNumber ? from.phoneNumber : '';
            to.externalManager = from.externalManager;
            const status = {enumValue: 'ACTIVE', label: 'Aktywny'};
            to.status = from.status ? from.status : status;
            to.contracts = from.contracts;
            to.categories = from.categories;
            to.absences = from.absences;
            to.alarms = from.alarms;
            to.documents = from.documents;
            if(to.absences){
                to.absences.forEach((c)=>{
                    if (c.dateFrom) {
                        c.dateFrom = new Date(c.dateFrom);
                    }
                    if (c.dateTo) {
                        c.dateTo = new Date(c.dateTo);
                    }
                })
            }
            if(to.documents){
                to.documents.forEach((d)=>{
                    if (d.dateTo) {
                        d.dateTo = new Date(d.dateTo);
                    }
                })
            }
            if (to.contracts) {
                to.contracts.forEach((c) => {
                    if (c.dateFrom) {
                        c.dateFrom = new Date(c.dateFrom);
                    }
                    if (c.dateTo) {
                        c.dateTo = new Date(c.dateTo);
                    }
                });
            }
            to.contract = from.contract;
            to.createDate = from.createDate;
            if (to.contracts) {
                to.contracts.forEach((c) => {
                    if (c.dateFrom) {
                        c.dateFrom = moment(c.dateFrom).toDate();
                    }
                });
            }
        } else {
            to = new EmployeeModel();
        }
    }

    static copy(from) {
        const to = new EmployeeModel();
        EmployeeModel.assign(from, to);
        return to;
    }
}
