import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import YesNoDialog from '../components/YesNoDialog';
import { ProgressBar } from 'primereact/progressbar';
import { counter } from '@fortawesome/fontawesome-svg-core';
import moment from 'moment/moment';

class UpdateAppDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
        }

        // this.handleClose = this.handleClose.bind(this);
        this.updateApp = this.updateApp.bind(this);
        this.persistUpdate = this.persistUpdate.bind(this);
        this.uiVersion = {
            appVersion: process.env.REACT_APP_VERSION,
            appName: process.env.REACT_APP_NAME,
            buildNumber: process.env.REACT_APP_BUILD_NUMBER,
            buildTime: process.env.REACT_APP_BUILD_TIME,
        };
    }

    persistUpdate() {
        const { oldVersion, newVersion } = this.props;
        localStorage.setItem(`appUpdate_${oldVersion}_${newVersion}`, '1');
    }

    updateApp() {
        const currentUrl = window.location.href;
        const originUrl = window.location.origin + '/#/';
        this.persistUpdate();
        if (window.caches) {
            try {
                caches.open(currentUrl).then(function (cache) {
                    cache.delete(currentUrl).then(() => {
                        caches.open(originUrl).then(function (cache) {
                            cache.delete(originUrl).then(() => {
                                window.location.reload(true);
                            });
                        });
                    });
                });
            } catch (ex) {
                // 99% imposible
                window.location.reload(true);
            }
        } else {
            window.location.reload(true);
        }
    }

    render() {
        const { visible } = this.props;
        let remains = Math.floor((100 - this.state.counter) / 10);
        if (remains < 0) {
            remains = 0;
        }
        return (            
                <YesNoDialog
                    visible={visible}
                    name=''
                    noButtonLabel='Zainstaluj'
                    singleButton={true}
                    header={'Wykryto nową wersję aplikacji'}
                    onChange={() => {
                        this.updateApp();
                    }}
                    onHide={this.updateApp}
                    closable={false}
                    onShow={() => {
                        this.start = moment();
                        this.setState({counter: 0},
                            () => {
                                this.timer = setInterval(() => {
                                    const difference = moment().diff(this.start, 'millisecond');
                                    const newCounter = Math.floor(difference / 100);
                                    if (newCounter !== this.state.counter) {
                                        this.setState({counter: newCounter},
                                            () => {
                                                if (this.state.counter >= 100) {
                                                    this.updateApp();
                                                }
                                            });
                                    }

                            }, 1000);
                        });                        
                    }}
                >
                    <div className='p-4'>
                        <div style={{marginRight: '20px'}}>
                            <b>{`Aplikacja zostanie zaktualizowana za ${remains} sekund`}</b>
                            {/* {appInfo.buildNumber} */}
                        </div>
                        <div style={{marginRight: '20px'}}>
                            <b>Nowa wersja: {this.uiVersion?.appVersion} ({this.props?.newVersion})</b>
                            {/* {appInfo.version} */}
                        </div>

                        <div className='mt-3' style={{marginRight: '20px'}}>
                            {/* {appInfo.lastVersionReleaseNote} */}
                            <ProgressBar displayValueTemplate={this.displayValueTemplate.bind(this)} value={this.state.counter} showValue={false}/>
                        </div>
                        <div>                        
                            
                        </div>
                    </div>
                </YesNoDialog>
        );
    }

    displayValueTemplate(value) {
        return (
            <React.Fragment>
                {value}
            </React.Fragment>
        );
    }

}

export default withTranslation()(UpdateAppDialog);