import { Badge } from 'primereact/badge';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { DataViewLayoutOptions } from 'primereact/dataview';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import PropTypes from 'prop-types';
import { default as React } from 'react';
import '../../assets/css/dataview.scss';
import BaseContainer from '../../baseContainers/BaseContainer';
import ActionButton from '../../components/ActionButton';
import { CustomMessages } from '../../components/CustomMessages';
import DivContainer from '../../components/DivContainer';
import BlockUi from '../../components/waitPanel/BlockUi';
import ClientService from '../../services/ClientService';
import ProjectCategoryService from '../../services/ProjectCategoryService';
import ProjectDashboardService from '../../services/ProjectDashboardService';
import ProjectService from '../../services/ProjectService';
import Constants from '../../utils/constants';
import SettingService from '../../services/SettingService';

const screenWidth = Constants.SCREEN_WIDTH_DASHBOARD_PAGINATOR;

class ProjectDashboardFullScreenContainer extends BaseContainer {
    constructor(props) {
        super(props, new ProjectDashboardService());
        this.state = {
            list: [],
            loading: true,
            tooltipVisibile: false,
            legendVisibile: false,
            size: 0,
            first: 0,
            statusOptions: [],
            roleTypeOptions: [],
            categories: [],
            projects: [],
            layout: 'grid',
            //
            allEmployees : [],
            absences:[],
            //holidaysPlanned:[],
            usersJoin:[],
            usersLeave:[],
            users: [],
            managers: [],
            documentsExpire: [],
            limosa:[],
            days180:[],
            usersCategories: [],
            categoriesIcons: [],
            projectCars: [],
            rows: 0,
            firsts: 0,
            //
            element: {
                name: '',
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            },
            paused: false,
            dashboardIntervalTime: 10000,
        };
        this.clientService = new ClientService();
        this.projectCategoryService = new ProjectCategoryService();
        this.service = new ProjectService();
        this.projectDashboardService = new ProjectDashboardService();
        this.settingService = new SettingService();
        this.onResize = this.onResize.bind(this);
    }

        componentDidMount() {
         this.getIntervalsTime();
         this.initializeFromBackend();
         if (this.props.handleHideMenu instanceof Function) {
            this.props.handleHideMenu();
        }
        window.addEventListener("resize", this.onResize);
  
        this.refreshJwtToken();
        if (this.scrollToError) {
            this.scrollToError = false;
            this.scrollToFirstError();
        }
    }

    setTimeIntervals(time){
        this.updateTimer = setInterval(() => 
        {if(this.state.paused === false){
            this.initializeFromBackend()
        }}, 60000);
        this.nextPageTimer = setInterval(() => 
        {if(this.state.paused === false){
            this.autoNextPage()
        }}, time);
        return time;
    }
    getIntervalsTime(){
        this.settingService
        .getValue('DASHBOARD_PAGE_INTERVAL_TIME')
        .then((res)=>{
            if(res.value !== undefined && res.value !== null) {
                let timeMiliseconds = res.value * 1000;
                this.setState({
                    dashboardIntervalTime: timeMiliseconds
                }, () =>{ 
                 this.setTimeIntervals(timeMiliseconds)})
            } else {
                this.setTimeIntervals(this.state.dashboardIntervalTime)
            }
            

        }).catch((ex) => {
            this.showErrorMessage('Nie udało się parametru czasu');
        });
     
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.updateTimer);
        clearInterval(this.nextPageTimer);
        window.removeEventListener("resize", this.onResize);
    }

    componentWillMount() {
        this.setState({
            width: this.getWindowWidth(),  height: this.getWindowHeight()
          });
    }

    getWindowWidth() {
        return Math.max(
          document.documentElement.clientWidth,
          window.innerWidth || 0
        );
      }
      getWindowHeight() {
        return Math.max(
          document.documentElement.clientHeight,
          window.innerHeight || 0
        );
      }
      onResize() {
        window.requestAnimationFrame(() => {
            if(this.props.url !== null && this.props.url !== "" && this.props.url !== undefined){
                if(this.getWindowWidth() > screenWidth){
                    let newCriteria = this.prepareCriteriaFromUrl(this.props.url, null);
                    this.projectDashboardService
                    .getProjectDashboardWithPathCriteria( newCriteria)
                    .then((projects) => {
                        this.setState({
                            projects: projects.content,
                            projectsTotalElements: projects.totalElements,
                        });
                    })
                    .catch(() => {
                        this.showErrorMessage('Nie udało się pobrać listy projektów dla dashboardu');
                    });
                }

            }

        });
      }

    setAllEmployees(){
        let allEmployees = [];
        allEmployees = [...this.state.users, ...this.state.managers];
       let unique = allEmployees.filter((e, index, self) => index === self.findIndex((t) => (t.employeeId === e.employeeId && t.projectId === e.projectId)));
       this.setState({allEmployees: unique});

    }



    renderBackLink() {
        return null;
    }

    renderFullscreenButton() {
        let {fullscreenExitUrl} = this.props;
        const href = `${fullscreenExitUrl}`;

        return (
            <ActionButton
                className='pdf-download-button'
                handleClick={this.handleGoToFullScreenView.bind(this, href)}
                iconName='mdi-fullscreen-exit'
            />
        );
    }
    renderPaginator(){
        const {criteria, firsts, rows} = this.state;
        return(
            <div>
        <Paginator className='dashboard-paginator' first={firsts} rows={this.countMaxElementsPerPage()} totalRecords={this.state.projectsTotalElements} onPageChange={(e) => { this.onPaginatorChange(e)}} />
        {this.state.paused === false ?
        <ActionButton
        className='pdf-download-button'
        handleClick={() => this.setState({paused:true})}
        iconName='mdi-pause'
         /> : 
        <ActionButton
        className='pdf-download-button'
        handleClick={() => this.setState({paused:false})}
        iconName='mdi-play'
        />  }
      
            </div>
       
       
       );

    }
    handleGoToFullScreenView(href, e) {
        e.preventDefault();
        window.location.href = href;
    }

    getCriteriaName() {
        return 'project-dashboard-sc';
    }

    updateSearchCriteria(criteria) {
        return {
            name: criteria.name,
            projectsByDate: criteria.projectsByDate,
            futureProjects : criteria.projectsByDate.enumValue === 'ACTIVE_IN_FUTURE' ? true : false,
            categories: criteria.categories,
            clientName: criteria.clientName,
            countryName: criteria.countryName,
            status: criteria.status,
            maxResultsPerPage : null,
            firstResult: 0,
            maxResults: this.countMaxElementsPerPage(),
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,

          
        };
    }

    getCleanSearchCriteria() {
        return {
            name: '',
            status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            id: null,
            projectsByDate: { enumValue: "ACTIVE_NOW", label: "Aktywny teraz"},
            futureProjects: false,
            categories: [],
            countryName: '',
            clientName: null,
            maxResultsPerPage : null,
            firstResult: 0,
            maxResults: this.countMaxElementsPerPage(),
            sortField: 'dateStart',
            sortAsc: false,
        };
    }

    readCriteriaFromCookie() {
        const cookie = this.projectDashboardService.readCriteria(this.getCriteriaName());
        const criteria =
            cookie !== undefined && cookie != null ? this.updateSearchCriteria(JSON.parse(cookie)) : this.getCleanSearchCriteria();
        this.removeCookie(this.getCriteriaName());

        this.setState({
            criteria,
        });
        return criteria;
    }

    autoNextPage(){
        let currentClickedBtn = document.getElementsByClassName('p-highlight');
        let length  = currentClickedBtn.length;
        let nextPageBtn = null;
        if(this.getWindowWidth() > screenWidth ){
        if( length > 0 ){
            if(currentClickedBtn[0] !== nextPageBtn){
                nextPageBtn = currentClickedBtn[0].nextSibling;
            }
        }
        if(nextPageBtn !== null){
            nextPageBtn.click();
            console.log(" next page ")
        } else {
            let firstPage = document.getElementsByClassName('p-paginator-icon pi pi-angle-double-left');
            console.log(" back to first page")
            if(firstPage.length >0 ){
                firstPage[0].click();
            }
        }
    }
    }

    prepareCriteriaFromUrl(urlCriteria , event){
        let newMaxRes = this.countMaxElementsPerPage();
        let maxResVal = urlCriteria.split("&").filter(e => e.includes("max_result"))[0];
        let firstResVal = urlCriteria.split("&").filter(e => e.includes("first_result"))[0];
        let newMaxResult = "max_result=" + newMaxRes.toString();
        let newFirstResult = "first_result=";
        if(event !== null){
            if(event.first !== null){
                newFirstResult += event?.first.toString()
            }
            else{
                newFirstResult += '0';
            }
        }
        else{ newFirstResult += '0';}
        let newCriteria = urlCriteria.replace(maxResVal, newMaxResult).replace(firstResVal, newFirstResult);
        return newCriteria;
    }

    initializeFromBackend() {
        console.log(" data refreshed ")     
        let criteria = this.readCriteriaFromCookie();
        this.enumService
            .getEnumList('ProjectStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
        this.enumService
            .getEnumList('RoleType')
            .then((roleTypeOptions) =>
                this.setState({
                    roleTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ról');
            });
        this.projectCategoryService
            .getList({firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true})
            .then((categories) => this.setState({categories: categories.content}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });
/////////////////// pobranie projektów
            if(this.props.url !== null && this.props.url !== "" && this.props.url !== undefined){
                if(this.getWindowWidth() > screenWidth){
                    let newCriteria = this.prepareCriteriaFromUrl(this.props.url, null);
                    this.projectDashboardService
                    .getProjectDashboardWithPathCriteria( newCriteria)
                    .then((projects) => {
                        this.setState({
                            projects: projects.content,
                            projectsTotalElements: projects.totalElements,
                        });
                    })
                    .catch(() => {
                        this.showErrorMessage('Nie udało się pobrać listy projektów dla dashboardu');
                    });
                } else {
                    this.projectDashboardService
                    .getProjectDashboardWithPathCriteria( this.props.url)
                    .then((projects) => {
                        this.setState({
                            projects: projects.content,
                            projectsTotalElements: projects.totalElements,
                        });
                    })
                    .catch(() => {
                        this.showErrorMessage('Nie udało się pobrać listy projektów dla dashboardu');
                    });
                }

            }
            else{

                this.projectDashboardService
                .getProjectDashboard(criteria)
                .then((projects) => {

                    this.setState({
                        projects: projects.content,
                        projectsTotalElements: projects.totalElements,
                    });
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy projektów dla dashboardu');
                });
            }
        this.projectDashboardService
            .getProjectHolidays()
            .then((response) => this.setState({ absences: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy uzytkownikow na urlopie');
            });
        this.projectDashboardService
            .getProjectUsersJoin()
            .then((response) => this.setState({ usersJoin: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy uzytkownikow, którzy dołączą do projektu');
            });
        this.projectDashboardService
            .getProjectUsers()
            .then((response) => this.setState({ users: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy uzytkownikow przypisanych do projektu');
            });
        this.projectDashboardService
            .getProjectManagers()
            .then((response) => this.setState({ managers: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy liderów projektu');
            });
        this.projectDashboardService
            .getCategoriesIcons()
            .then((response) => this.setState({ categoriesIcons: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ikon kategorii');
            });

        this.projectDashboardService
            .getProjectCars()
            .then((response) => this.setState({ projectCars: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy samochodów');
            });
        this.projectDashboardService
            .getAccommodationsByProjectId()
            .then((response) => this.setState({ projectAccommodations: response }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy noclegów');
            });


    }

    countMaxRowsPerPage(){
            let x = (this.getWindowHeight() - 60) / 318 ;
            return Math.floor(x);
    }

    countMaxElementsPerPage(){
    return this.countMaxRowsPerPage() * 12;
    }

    textToTooltip(projectId, type) {    
        let textToDisplay = '';
        if (type === 'ALL_USERS') {
            let allEmployees = [];
            allEmployees = [...this.state.users, ...this.state.managers];
           let unique = allEmployees.filter((e, index, self) => index === self.findIndex((t) => (t.employeeId === e.employeeId && t.projectId === e.projectId)));
           unique?.filter(u => u.projectId === projectId)?.map((u) => {
                return (textToDisplay += u.lastName + ' ' + u.firstName + '\n');
            });
            return "Pracownicy:\n" + textToDisplay;
        }
        if (type === 'HOLIDAYS') {
            this.state.absences?.filter(u => u.projectId === projectId)?.forEach((u) => {
                u.employeeAbsences?.map(ea => {
                    return (textToDisplay += ea.lastName + ' ' + ea.firstName + '\n');
                })
               
            });
            return "Pracownicy na urlopie:\n" + textToDisplay;
        }
        if (type === 'PLANNED_HOLIDAYS') {
            this.state.absences?.filter(u => u.projectId === projectId)?.forEach((u) => {
                u.employeePlannedAbsences?.map(ea => {
                    return (textToDisplay += ea.lastName + ' ' + ea.firstName + '\n');
                })
               
            });
            return "Pracownicy planujący urlop:\n" + textToDisplay;
        }
        if (type === 'WILL_JOIN_PROJECT') {
            this.state.absences?.filter(u => u.projectId === projectId)?.forEach((u) => {
                u.employeeWillJoin?.map(ewj => {
                    return (textToDisplay += ewj.lastName + ' ' + ewj.firstName + '(' + ewj.dateStart + ')\n');
                })
               
            });
            return "Pracownicy, którzy dołączą:\n" + textToDisplay;
        }
        if (type === 'WILL_LEAVE_PROJECT') {
            this.state.usersJoin?.filter(u => u.projectId === projectId)?.map((u) => {
                return (textToDisplay += u.lastName + ' ' + u.firstName + '\n');
            });
            return "Pracownicy, którzy opuszczą projekt:\n" + textToDisplay;
        }
        if (type === 'CARS_NUMBER') {
            this.state.projectCars?.filter(p => p?.project && p.project.id === projectId)
                .map((projectCar) => projectCar.car)
                .map((car) => {
                    return (textToDisplay += car.brand + ' ' + car.model + ' ' + car.licensePlate + '\n');
                });
            return "Pojazdy:\n" + textToDisplay;
        }
        if (type === 'ACCOMMODATION') {
                this.state.projectAccommodations?.filter(u => u.projectId === projectId)?.forEach((pa) => {
                    textToDisplay += pa.name + "(";
                    let emplCount = pa.employees.length;
                     pa.employees?.map((ea, index) => {
                        let comma = index + 1 === emplCount ? "" : ", "
                        return (textToDisplay += ea.fullName  + comma);
                    })        
                   textToDisplay += ")" + '\n'
                });
            return "Noclegi:\n" + textToDisplay;
        }
        if (type === 'DOCUMENT_EXPIRE') {
            this.state.absences?.filter(u => u.projectId === projectId)?.forEach((u) => {
                u.employeeWithExpiredDocuments?.map(ea => {
                    return (textToDisplay += ea.lastName + ' ' + ea.firstName + ' (' + ea.documentType +', ' + ea.validTo +')\n');
                })
               
            });
            return "Dokumenty z krótką ważnością:\n" + textToDisplay;
        }
        if (type === 'LIMOSA_EXPIRE') {
            this.state.usersJoin?.filter(u => u.projectId === projectId)?.map((u) => {
                return (textToDisplay += u.lastName + ' ' + u.firstName + '\n');
            });
            return "Limosa z krótką ważnością:\n" + textToDisplay;
        }   
        if (type === '180_DAYS_ABROAD') {
            this.state.absences?.filter(u => u.projectId === projectId)?.forEach((u) => {
                u.employee180DaysAbroad?.map(ea => {            
                    return (textToDisplay += ea.lastName + ' ' + ea.firstName + ' (' + ea.countryName + ' - ' + ea.daysCounter + 'dni)\n');
                })
               
            });
            return "Pracownicy przebywający zbyt długo w danym kraju:\n" + textToDisplay;
        }
        else{        
            this.state.users?.filter(u=> u.projectId === projectId && u.categoryList?.filter(cl => cl.name === type).length >0)?.map(u=>{
                return (textToDisplay += u.lastName + ' ' + u.firstName + '\n');

            })
            return `Kategoria ${type}:\n` + textToDisplay;
        }
    }
    countUsersToTooltip(projectId, type) {
        if (type === 'ALL_USERS') {
            let allEmployees = [];
            allEmployees = [...this.state.users, ...this.state.managers];
            let unique = allEmployees.filter((e, index, self) => index === self.findIndex((t) => (t.employeeId === e.employeeId && t.projectId === e.projectId)));
            return unique?.filter(u => u.projectId === projectId)?.length;
        }
        if (type === 'HOLIDAYS') {
            return this.state.absences?.filter(u => u.projectId === projectId)[0]?.employeeAbsences?.length;
        }
        if (type === 'PLANNED_HOLIDAYS') {
            return this.state.absences?.filter(u => u.projectId === projectId)[0]?.employeePlannedAbsences?.length;
        }
        if (type === 'WILL_JOIN_PROJECT') {
            return this.state.absences?.filter(u => u.projectId === projectId)[0]?.employeeWillJoin?.length;
        }
        if (type === 'WILL_LEAVE_PROJECT') {
            return this.state.usersLeave?.filter(u => u.projectId === projectId)?.length;
        }
        if (type === 'CARS_NUMBER') {
           return this.state.projectCars?.filter(p => p?.project && p.project.id === projectId).length;
        }
        if (type === 'DOCUMENT_EXPIRE') {
            return this.state.absences?.filter(u => u.projectId === projectId)[0]?.employeeWithExpiredDocuments?.length;
        }
        if (type === 'LIMOSA_EXPIRE') {
            return this.state.limosa?.filter(u => u.projectId === projectId)?.length;
        }
        if (type === '180_DAYS_ABROAD') {
            return this.state.absences?.filter(u => u.projectId === projectId)[0]?.employee180DaysAbroad?.length;
        }
        if(type === 'ACCOMMODATION') {
            return this.state.projectAccommodations?.filter(pa => pa.projectId === projectId).length;
        }
        else{
            return this.state.users?.filter(u=> u.projectId === projectId && u.categoryList?.filter(cl => cl.name === type).length >0)?.length
        }
    }

    renderGridItem(data) {
        let allAccommodationCount = this.countUsersToTooltip(data.id, 'ACCOMMODATION');
        let allUsersCount = this.countUsersToTooltip(data.id, 'ALL_USERS');
        let usersOnHolidaysCount = this.countUsersToTooltip(data.id, 'HOLIDAYS');
        let usersPlanningHolidaysCount = this.countUsersToTooltip(data.id, 'PLANNED_HOLIDAYS');
        let usersWillJoinCount = this.countUsersToTooltip(data.id, 'WILL_JOIN_PROJECT');
        let carsCount = this.countUsersToTooltip(data.id, 'CARS_NUMBER');
        let documentExpiredCount = this.countUsersToTooltip(data.id, 'DOCUMENT_EXPIRE');
        let daysAbroad = this.countUsersToTooltip(data.id, '180_DAYS_ABROAD');
        return (
            <div className='col-xxl-2 col-xl-6 col-lg-4 col-lg-6 col-md-6 col-sm-6'>
                <div className='product-grid-item card'>
                    <div className='product-grid-item-top-date-country'>
                    <div className='product-grid-item-top-date'>
                            <Tag value={data.dateStart} style={{backgroundColor: '#689F38', margin: 'auto'}}></Tag>
                            <Tag value={data.dateEnd} style={{backgroundColor: '#689F38', marginLeft: '20px'}}></Tag>
                        </div>
                 
                    <div className='product-grid-item-top-country'>
                    <Tag value={ data.country.code !== null && data.country.code !== "" ? data.country.code : data.country.name} 
                    style={{
                        backgroundColor:data.country.color,  
                        //textShadow:'-1px 1px 0 #000,1px 1px 0 #000, 1px -1px 0 #000,-1px -1px 0 #000', 
                        color: 'black'}} ></Tag>
                    </div>             
                    </div>
                    <div className='product-name'>
                        {/* <i className="pi pi-bolt product-category-icon"></i> */}
                        <span className='product-category'>{data.name}</span>
                    </div>
                    <div className='leader-div'>
                        {this.state.managers?.filter(m=> m.projectId === data.id)?.map((m) => {
                            return (
                                <Tag
                                    value={m.fullName}
                                    style={{
                                        backgroundColor: 'rgb(203, 206, 52)',
                                        color: 'black',
                                        height: 'auto',
                                        marginTop: '10px',
                                        marginRight: '5px',
                                    }}
                                ></Tag>
                            );
                        })}{' '}
                    </div>
                    <div className='product-grid-item-content'>
                        <div className='dashboard-teil-c' style={{height: '150px'}}>
                            <Tooltip id='asasas' target='.employeeStatusInfo' style={{width:'300px'}} />

                            <div style={{fontSize: '10px', marginRight: '2px', marginBottom: '15px', marginTop: '5px', color: '#6c757d'}}>
                                {/* ogolnia liczba pracownikow na projekcie - liczyc liderow do tego?? */}
                                 <i
                                 className='employeeStatusInfo  icon mdi mdi-account-multiple-outline p-overlay-badge'
                                 style={{fontSize: '20px', marginRight: '17px'}}
                                 data-pr-tooltip={this.textToTooltip(data.id, 'ALL_USERS')}
                             >
                                 {allUsersCount !== 0 && allUsersCount !== undefined ? 
                                 <Badge value={allUsersCount} size='normal' severity='success'></Badge> : null
                                 }
                                 
                             </i>
                                {/* to do w poniższych data-pr-tooltip i Badge value*/}
                                {/* liczba pracownikow przebywajacych na urlopie */}
                                <i
                                    className='employeeStatusInfo mdi mdi-beach p-overlay-badge'
                                    style={{fontSize: '20px', marginRight: '17px'}}
                                    data-pr-tooltip={this.textToTooltip(data.id, 'HOLIDAYS')}
                                >
                                     {usersOnHolidaysCount !== 0 && usersOnHolidaysCount !== undefined ? 
                                    <Badge value={usersOnHolidaysCount} size='medium' severity='success'></Badge> : null }
                                </i>
                                {/* liczba pracownikow planujacych isc na urlop w najblizszym czasie - jaki to czas?  */}
                                <i
                                    className='employeeStatusInfo  mdi mdi-airplane-takeoff p-overlay-badge'
                                    style={{fontSize: '20px', marginRight: '17px'}}
                                    data-pr-tooltip={this.textToTooltip(data.id, 'PLANNED_HOLIDAYS')}
                                >
                                    {usersPlanningHolidaysCount !== 0 && usersPlanningHolidaysCount !== undefined ? 
                                    <Badge value={usersPlanningHolidaysCount} size='medium' severity='success'></Badge> : null}
                                </i>
                                {/* liczba pracownikow ktorzy niebawem dołączą do projektu */}
                                <i
                                    className='employeeStatusInfo  mdi mdi-human-greeting p-overlay-badge'
                                    style={{fontSize: '20px', marginRight: '17px'}}
                                    data-pr-tooltip={this.textToTooltip(data.id, 'WILL_JOIN_PROJECT')}
                                >
                                    {usersWillJoinCount !== 0 && usersWillJoinCount !== undefined ? 
                                    <Badge value={usersWillJoinCount} size='medium' severity='success'></Badge> : null}
                                </i>
                                  {/* liczba pracownikow ktorzy niebawem opuszczą projekt */}
                                 {/*  <i
                                    className='employeeStatusInfo  mdi mdi-human-greeting p-overlay-badge'
                                    style={{fontSize: '20px', marginRight: '17px'}}
                                    data-pr-tooltip={this.textToTooltip(data.id, 'WILL_LEAVE_PROJECT')}
                                >
                                    {usersWillLeaveCount !== 0 && usersWillLeaveCount !== undefined ? 
                                    <Badge value={usersWillLeaveCount} style= {{display:'none'}} size='medium' severity='success'></Badge> : null}
                                </i> */}
                                {/* liczba aut na projekcie */}
                                <i
                                    className='employeeStatusInfo  icon mdi mdi-car p-overlay-badge'
                                    style={{fontSize: '20px', marginRight: '17px'}}
                                    data-pr-tooltip={this.textToTooltip(data.id, 'CARS_NUMBER')}
                                >
                                    {carsCount !== 0 && carsCount !== undefined ? 
                                    <Badge value={carsCount} size='medium' severity='success'></Badge> : null}
                                </i>
                                 {/* liczba roznych noclegow na tym projekcie  */}
                                 <i
                                    className='employeeStatusInfo  icon mdi mdi-home p-overlay-badge'
                                    style={{fontSize: '20px', marginRight: '17px'}}
                                    data-pr-tooltip={this.textToTooltip(data.id, 'ACCOMMODATION')}
                                >
                                    {allAccommodationCount !== 0 && allAccommodationCount !== undefined ? 
                                    <Badge value={allAccommodationCount} size='normal' severity='success'></Badge> : null
                                    }
                                    
                                </i>
                            </div>

                            <div style={{fontSize: '10px', marginRight: '2px', marginBottom: '15px', marginTop: '5px', color: '#6c757d'}}>
                                {/* wygasajace dokumenty  */}
                                <i
                                    className='employeeStatusInfo mdi mdi-bullhorn p-overlay-badge'
                                    style={{fontSize: '20px', marginRight: '17px'}}
                                    data-pr-tooltip={this.textToTooltip(data.id, 'DOCUMENT_EXPIRE')}
                                >
                                    {documentExpiredCount !== 0 && documentExpiredCount !== undefined ? 
                                    <Badge value={documentExpiredCount} size='medium' severity='info'></Badge> : null}
                                </i>
                                {/* wygasajace limozy  */}
                               {/*  <i
                                    className='employeeStatusInfo  mdi mdi-file-document p-overlay-badge'
                                    style={{fontSize: '20px', marginRight: '17px'}}
                                    data-pr-tooltip={this.textToTooltip(data.id, 'LIMOSA_EXPIRE')}
                                >
                                    {limosaExpiredCount !== 0 && limosaExpiredCount !== undefined ? 
                                    <Badge value={limosaExpiredCount} size='medium' severity='info'></Badge> : null}
                                </i> */}
                                {/* ilość pracownikow którzy niebawem przekrocza 180 dni w danym kraju */}
                                <i
                                    className='employeeStatusInfo  mdi mdi-clock-alert p-overlay-badge'
                                    style={{fontSize: '20px', marginRight: '17px'}}
                                    data-pr-tooltip={this.textToTooltip(data.id, '180_DAYS_ABROAD')}
                                >
                                    {daysAbroad !== 0 && daysAbroad !== undefined ? 
                                    <Badge value={daysAbroad} size='normal' severity='info'></Badge> : null}
                                </i>
                                {this.state.categoriesIcons?.map((i) => {
                                       let id = i.id;
                                       let countForBadge = this.countUsersToTooltip(data.id, i.name);
                                     
                                            return(
                                                countForBadge > 0 ?
                                                    <i
                                              /*   key={`${id}-key-categor`}
                                                id={`${id}-categor-id`} */
                                                className={`employeeStatusInfo mdi ${i.icon.value} p-overlay-badge`}
                                                style={{fontSize: '20px', marginRight: '17px', color: `${i.color.value}`}}
                                                data-pr-tooltip={this.textToTooltip(data.id, i.name)}
                                            >
                                                {this.countUsersToTooltip(data.id, i.name) !== 0 && this.countUsersToTooltip(data.id, i.name) !== undefined ? 
                                                <Badge value={this.countUsersToTooltip(data.id, i.name)} size='medium' severity='success'></Badge> : null}
                                            </i> : null
                                                 
                                            );
                                        
                                   
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
        // })
    }

    legendDescription() {
        let legend = (
            <td key={'wn' + 1} className='p-datepicker-weeknumber'>
                <tr>
                    <i
                        className='employeeStatusInfo  icon mdi mdi-account-multiple-outline p-overlay-badge'
                        style={{fontSize: '20px', marginRight: '17px'}}
                    ></i>
                    <span className='p-disabled'> Łączna liczba pracowników w projekcie</span>
                </tr>

                <tr>
                    <i
                        className='employeeStatusInfo  icon mdi mdi-beach p-overlay-badge'
                        style={{fontSize: '20px', marginRight: '17px'}}
                    ></i>
                    <span className='p-disabled'> Liczba pracowników dziś nieobecnych </span>
                </tr>

                <tr>
                    <i
                        className='employeeStatusInfo  icon mdi mdi-airplane-takeoff p-overlay-badge'
                        style={{fontSize: '20px', marginRight: '17px'}}
                    ></i>
                    <span className='p-disabled'> Liczba pracowników planujących nieobecność w najbliższym czasie</span>
                </tr>

                <tr>
                    <i
                        className='employeeStatusInfo  icon mdi mdi-human-greeting p-overlay-badge'
                        style={{fontSize: '20px', marginRight: '17px'}}
                    ></i>
                    <span className='p-disabled'> Liczba pracowników, którzy dołączą do projektu w najbliższym czasie</span>
                </tr>

                <tr>
                    <i className='employeeStatusInfo  icon mdi mdi-car p-overlay-badge' style={{fontSize: '20px', marginRight: '17px'}}></i>
                    <span className='p-disabled'> Liczba aut na projekcie</span>
                </tr>

                <tr>
                    <i
                        className='employeeStatusInfo  icon mdi mdi-bullhorn p-overlay-badge'
                        style={{fontSize: '20px', marginRight: '17px'}}
                    ></i>
                    <span className='p-disabled'> Wygasające dokumenty w najbliższym czasie</span>
                </tr>

              {/*   <tr>
                    <i
                        className='employeeStatusInfo  icon mdi mdi-file-document p-overlay-badge'
                        style={{fontSize: '20px', marginRight: '17px'}}
                    ></i>
                    <span className='p-disabled'> Wygasajace limozy w najbliższym czasie </span>
                </tr> */}

                <tr>
                    <i
                        className='employeeStatusInfo  icon mdi mdi-clock-alert p-overlay-badge'
                        style={{fontSize: '20px', marginRight: '17px'}}
                    ></i>
                    <span className='p-disabled'> Liczba pracowników, którzy niebawem przekroczą 180 dni w danym kraju </span>
                </tr>

              {/*   <tr>
                    <i
                        className='employeeStatusInfo  icon mdi mdi-trophy p-overlay-badge'
                        style={{fontSize: '20px', marginRight: '17px'}}
                    ></i>
                    <span className='p-disabled'> Liczba pracownikow wyróżniających się umiejętnościami </span>
                </tr>

                <tr>
                    <i
                        className='employeeStatusInfo  icon mdi  mdi-truck-delivery p-overlay-badge'
                        style={{fontSize: '20px', marginRight: '17px'}}
                    ></i>
                    <span className='p-disabled'> Liczba pracownikow posiadajacych prawo jazdy</span>
                </tr> */}
                {this.state.categoriesIcons.map(i => {               
                                        return(
                                            <tr>
                                            <i
                                             className={`employeeStatusInfo mdi ${i.icon.value} p-overlay-badge`}
                                             style={{fontSize: '20px', marginRight: '17px', color: `${i.color.value}`}}
                                            ></i>
                                            <span className='p-disabled'> Liczba pracowników z kategorią: {i.name}</span>
                                        </tr>
                                        );
                                    })}

            </td>
        );

       

        return legend;
    }

    itemTemplate(product, layout) {
        if (!product) {
            return;
        }

        if (layout === 'list') return this.renderListItem(product);
        else if (layout === 'grid') return this.renderGridItem(product);
    }
    renderHeader() {
        return (
            <div className='grid grid-nogutter'>
                <div className='col-6' style={{textAlign: 'left'}}>
                    <Dropdown
                        options={this.sortOptions}
                        value={this.state.sortKey}
                        optionLabel='label'
                        placeholder='Sort By Price'
                        onChange={this.onSortChange}
                    />
                </div>
                <div className='col-6' style={{textAlign: 'right'}}>
                    <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({layout: e.value})} />
                </div>
            </div>
        );
    }

    showLegendDialog(position) {
        confirmDialog({
            message: this.legendDescription(),
            header: 'Legenda',
            position,
            footer: [],
        });
    }

    onPaginatorChange(event){
        let maxres = this.countMaxElementsPerPage();

            this.setState(
                (prevState) => ({
                    criteria: {
                        ...prevState.criteria,
                        firstResult: event.first,
                        maxResults: maxres,

                    },
                    firsts:event.first,
                }), ()=>{
                let newCriteria = this.prepareCriteriaFromUrl(this.props.url, event);
                this.projectDashboardService
                .getProjectDashboardWithPathCriteria( newCriteria)
                .then((projects) => {
                    this.setState({
                        projects: projects.content,
                    });
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy projektów dla dashboardu');
                });
                }
            );


        };


    render() {
        return (
            <div>
              <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <div className='dashboard-fullscreen-container'>
                    {/* <div colClass='row col-12'> */}
                    <div className='row col-12'>
                        <div className='xd'>
                            {this.state.projects.length > 0
                                ? this.state.projects.map((p) => {
                                      return this.renderGridItem(p);
                                  })
                                : null}
                        </div>
                    </div>
                </div>
                <div className='justify-content-center d-flex flex-end'>
                    <ActionButton
                        handleClick={() => this.showLegendDialog('bottom-right')}
                        label='Legenda'
                        className='pdf-download-button'
                    ></ActionButton>
                    {this.renderFullscreenButton()}
                    {this.getWindowWidth() > screenWidth  ? this.renderPaginator() : null}
                   {/*  {this.getWindowWidth() > screenWidth  ? this.renderPauseButton() : null} */}

                </div>
            </div>
        );
    }
    renderView() {
        return (
            <DivContainer colClass='details-main'>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <ConfirmDialog
                        visible={this.state.confirmDialog?.visible}
                        onHide={() => this.setConfirmDialogVisibility(false)}
                        message={this.translate(`${this.state.confirmDialog?.message}`)}
                        header={this.translate(`${this.state.confirmDialog?.header}`)}
                        icon={`pi ${this.state.confirmDialog?.header}`}
                        accept={() => this.setConfirmDialogVisibility(false)}
                        rejectClassName='display-none'
                        acceptClassName='confirm-dialog-accept'
                    />
                    <div className='row' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div className='col-6 backlink'>{this.renderBackLink()}</div>
                        {this.isPdfButtonRendered() ? (
                            <div className='col-6' style={{textAlign: 'right'}}>
                                {this.renderPdfDownloadButton()}
                            </div>
                        ) : null}
                    </div>
                </BlockUi>
            </DivContainer>
        );
    }
}

ProjectDashboardFullScreenContainer.defaultProps = {
    detailUrl: '/#/project/details',
    newUrl: '/#/project/create',
    editUrl: '/#/project/edit',
    fullscreenExitUrl: '/#/project-dashboard',
    lazy: true,
    data: [],
};

ProjectDashboardFullScreenContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
    data: PropTypes.array,
};

export default ProjectDashboardFullScreenContainer;
