import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import i18n from '../../i18n';
import AuthService from '../../services/AuthService';
import InternalUserService from '../../services/InternalUserService';
import ActionLink from '../ActionLink';
import I18nUtils from '../utils/I18nUtils';

class HeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.authService = new AuthService();
        this.internalUserService = new InternalUserService();
        const {t} = props;
        this.state = {
            timer: this.authService.expirationTimer(),
            loggedIn: this.authService.loggedIn(),
            sapRoleId: this.authService.getProfile().agreementSapRoleId,
            translations: {
                'header.notification.clear': t('header.notification.clear'),
                'header.notification.title.video.INITIALIZED': t('header.notification.title.video.INITIALIZED'),
                'header.notification.title.video.DOWNLOADING': t('header.notification.title.video.DOWNLOADING'),
                'header.notification.title.video.AWAIT_STACKING': t('header.notification.title.video.AWAIT_STACKING'),
                'header.notification.title.video.STACKING': t('header.notification.title.video.STACKING'),
                'header.notification.title.video.ERROR': t('header.notification.title.video.ERROR'),
                'header.notification.title.video.READY': t('header.notification.title.video.READY'),
                'header.notification.content.video.INITIALIZED': t('header.notification.content.video.INITIALIZED'),
                'header.notification.content.video.DOWNLOADING': t('header.notification.content.video.DOWNLOADING'),
                'header.notification.content.video.AWAIT_STACKING': t('header.notification.content.video.AWAIT_STACKING'),
                'header.notification.content.video.STACKING': t('header.notification.content.video.STACKING'),
                'header.notification.content.video.ERROR': t('header.notification.content.video.ERROR'),
                'header.notification.content.video.READY': t('header.notification.content.video.READY'),
            },
        };
    }

    componentDidMount() {
        const {t} = this.props;
        if (!sessionStorage.getItem('i18nextLng')) {
            this.authService.refresh().then((res) => {
                sessionStorage.setItem('i18nextLng', res.language);
                i18n.changeLanguage(res.language);
            });
        }
        this.timer = setInterval(() => {
            this.setState({
                timer: this.authService.expirationTimer(),
            });
            if (!this.authService.loggedIn()) {
                if (this.props.logout) {
                    this.props.logout();
                } else {
                    this.authService.logout();
                }
            }
        }, 1000);

        
        let translations = {
            'header.notification.clear': t('header.notification.clear'),
            'header.notification.title.video.INITIALIZED': t('header.notification.title.video.INITIALIZED'),
            'header.notification.title.video.DOWNLOADING': t('header.notification.title.video.DOWNLOADING'),
            'header.notification.title.video.AWAIT_STACKING': t('header.notification.title.video.AWAIT_STACKING'),
            'header.notification.title.video.STACKING': t('header.notification.title.video.STACKING'),
            'header.notification.title.video.ERROR': t('header.notification.title.video.ERROR'),
            'header.notification.title.video.READY': t('header.notification.title.video.READY'),
            'header.notification.content.video.INITIALIZED': t('header.notification.content.video.INITIALIZED'),
            'header.notification.content.video.DOWNLOADING': t('header.notification.content.video.DOWNLOADING'),
            'header.notification.content.video.AWAIT_STACKING': t('header.notification.content.video.AWAIT_STACKING'),
            'header.notification.content.video.STACKING': t('header.notification.content.video.STACKING'),
            'header.notification.content.video.ERROR': t('header.notification.content.video.ERROR'),
            'header.notification.content.video.READY': t('header.notification.content.video.READY'),
        };
        this.setState({
            loggedIn: this.authService.loggedIn(),
            timer: this.authService.expirationTimer(),
            translations: translations,
        });
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
        clearTimeout(this.appVersionTimer);
        i18n.changeLanguage();
    }

    changeRoleType(sapRoleId) {
        this.internalUserService.changeRoleType(sapRoleId).then((res) => {
            this.authService.setToken(res.token);
            window.location.href = '/#/start';
            window.location.reload();
        });
    }

    changeLanguage(language) {
        this.internalUserService.changeLanguage(language).then(() => {
            sessionStorage.setItem('i18nextLng', language);
            i18n.changeLanguage(language);
        });
    }

    render() {
        const {t} = this.props;
        const supportedLanguages = I18nUtils.getSupportedLanguages(2);
        const languages = [];
        supportedLanguages.forEach((l) => {
            languages.push({value: l, label: t(`header.${l}`)});
        });
        return (
            <React.Fragment>
                {this.authService.loggedIn() ? (
                    <header className='container-fluid'>
                        <div className='session-div'>
                            <span className='session-label'>
                                {t('header.sessionTimer')}: <span className='session-time'>{this.state.timer}</span>
                            </span>
                        </div>
                        <div className='header-content'>
                            {this.props.label ? (
                                this.props.backUrl ? (
                                    <ActionLink
                                        label={t(this.props.label)}
                                        className='back-blue-link header-welcome-link'
                                        href={this.props.backUrl}
                                        variant='blue'
                                        iconName='mdi-arrow-left left-arrow-style'
                                        iconSide='left'
                                        iconSize='xs'
                                        iconColor='blue'
                                    />
                                ) : (
                                    <span className='header-welcome header-welcome-label'>{t(this.props.label)}</span>
                                )
                            ) : (
                                <span className='header-welcome'>
                                    {t('header.welcome')},
                                    <b>
                                        {`${
                                            this.authService.getProfile().name && this.authService.getProfile().name.trim().length > 0
                                                ? ' ' + this.authService.getProfile().name
                                                : ''
                                        } `}
                                    </b>
                                </span>
                            )}

                            <div className='header-links'>
                                <ActionLink
                                    label={t('header.contact')}
                                    href={'/#/contact'}
                                    variant='blue'
                                    className='p-link header-link'
                                    key={'contact-button'}
                                />
                                {/* <ActionLink
                                    label={t('header.faq')}
                                    href={'/#/faq'}
                                    variant='blue'
                                    className='p-link header-link'
                                    key={'faq-button'}
                                /> */}
                                <div onClick={this.props.logout} className='header-link' rel='noopener noreferrer'>
                                    <span>{t('header.logout')}</span>
                                </div>
                            </div>
                        </div>
                    </header>
                ) : null}
            </React.Fragment>
        );
    }
}

export default withTranslation()(HeaderComponent);
