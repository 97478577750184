import { t, use } from "i18next";
import moment from "moment";
import { Card } from 'primereact/card';
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabPanel, TabView } from 'primereact/tabview';
import PropTypes from 'prop-types'; 
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import BaseDetailsContainer from "../baseContainers/BaseDetailsContainer";
import ActionLink from "../components/ActionLink";
import { ProjectAddress } from "../components/Address";
import DivContainer from "../components/DivContainer";
import YesNoDialog from "../components/YesNoDialog";
import InputAutocompleteComponent from "../components/inputs/InputAutocompleteComponent";
import InputCalendarComponent from "../components/inputs/InputCalendarComponent";
import InputCalendarRangeComponent from "../components/inputs/InputCalendarRangeComponent";
import InputDropdownComponent from "../components/inputs/InputDropdownComponent";
import InputMultiSelectComponent from "../components/inputs/InputMultiSelectComponent";
import InputNumberComponent from "../components/inputs/InputNumberComponent";
import InputTextComponent from "../components/inputs/InputTextComponent";
import { UserActivityStatus } from "../model/enum/UserActivityStatus";
import ActivityService from "../services/ActivityService";
import CarService from "../services/CarService";
import ClientService from "../services/ClientService";
import CountryService from "../services/CountryService";
import EmployeeService from "../services/EmployeeService";
import InternalUserService from "../services/InternalUserService";
import ProjectCategoryService from "../services/ProjectCategoryService";
import ProjectService from "../services/ProjectService";
import ToolsService from "../services/ToolsService";
import NumberUtils from "../utils/NumberUtils";
import Constants from "../utils/constants";
import EmployeeStatisticDialog from "./EmployeeStatisticDialog";
import ExtraWorkProjectAggregationDialog from "./ExtraWorkProjectAggregationDialog";
import LogEntryListContainer from "./LogEntryListContainer";
import ProjectDriveCostAggregationDialog from "./ProjectDriveCostAggregationDialog";
import ProjectWorkCostAggregationDialog from "./ProjectWorkCostAggregationDialog";
import ProjectExpensesContainer from './project/expenses/ProjectExpensesContainer';
import ProjectExtraEventListContainer from "./project/extraEvents/ProjectExtraEventListContainer";
import EmployeeProjectPlaningStatistic from "./EmployeeProjectPlaningStatistic";
import IconColorComponent from "../components/inputs/IconColorComponent";
import ExternalSupervisorService from "../services/ExternalSupervisorService";



class ProjectDetailsContainer extends BaseDetailsContainer {

    constructor(props) {
        super(props, new ProjectService());
        this.clientService = new ClientService();
        this.projectCategoryService = new ProjectCategoryService();
        this.countryService = new CountryService();
        this.activityService = new ActivityService();
        this.carService = new CarService();
        this.toolsService = new ToolsService();
        this.employeeService = new EmployeeService();
        this.internalUserService = new InternalUserService();

        const queryParams = new URLSearchParams(this.props.location.search);
        let activeIndexParam = parseInt(queryParams.get('activeIndex'));

        const roleAdmin = this.authService.isUserInRole('ROLE_ADMIN');
        const roleCto = this.authService.isUserInRole('ROLE_CTO');
        const rolePm = this.authService.isUserInRole('ROLE_USER');
        const roleEm = this.authService.isUserInRole('ROLE_EXTERNAL_MANAGER');
        const currentUser = this.authService.getProfile();

        this.state = {
            activeIndex: activeIndexParam ? activeIndexParam : 0,
            loading: true,
            downloadingPdf: false,
            elementId: props.elementId,
            leaderRoles: [],
            clients: [],
            categories: [],
            activities: [],
            cars: [],
            users: [],
            tools: [],
            priceTypes: [],
            filteredEmployees: [],
            filteredCars: [],
            projectManagers: [],
            supervisors: [],
            externalSupervisors: [],
            externalManagers: [],
            extraWorkDialogVisible: false,
            extras: [],
            countries: [],
            workCostDialogVisible: false,
            workCosts: [],
            driveCostDialogVisible: false,
            driveCosts: [],
            visibleEmployeeStatistic: false,
            element: {
                name: undefined,
                dateStart: undefined,
                dateEnd: undefined,
                client: undefined,
                categories: [],
                activities: [{}],
                cars: [],
                users: [{}, {}, {}],
                managers: [{}],
                externalSupervisor: undefined,
                projectManager: undefined,
                externalManager: roleEm && !roleAdmin && !roleCto && !rolePm ? {id: currentUser.subId, fullName: currentUser.name} : undefined,
                supervisor: undefined,
                tools: [],
                projectAddress: this.projectAddressInitial(),
                projectCars: [],
                summary: {
                    unitPrice: { currency: { enumValue: "PLN", label: "Currency.PLN" } },
                    revenueEmployees: {},
                    revenueExtra: {},
                    revenueTotal: {},
                    costEmployees: {},
                    costDrivers: {},
                    costExpenses: {},
                    costTotal: {},
                    income: {},
                }
            },
        };

        this.userDateEndSameAsProject = [];
        this.userDateStartSameAsProject = [];
        this.managerDateEndSameAsProject = [];
        this.managerDateStartSameAsProject = [];


        this.calculateRevenue = this.calculateRevenue.bind(this);
        this.calculateTotalCost = this.calculateTotalCost.bind(this);
        this.calculateIncome = this.calculateIncome.bind(this);
        this.searchEmployees = this.searchEmployees.bind(this);
        this.toolsValueTemplate = this.toolsValueTemplate.bind(this);
        this.alignEmployeesDatesToProject = this.alignEmployeesDatesToProject.bind(this);
        this.persistUsersWithDatesSameAsProject = this.persistUsersWithDatesSameAsProject.bind(this);
        this.fetchUserStatistic = this.fetchUserStatistic.bind(this);
        this.externalSupervisorService = new ExternalSupervisorService();

        this.validator.addCustomRule('at_least_one_field_required', t('validator.at_least_one_field_required'), (value, params) => {
            const tools = this.state.element.tools;
            const index = parseInt(params[0]);            
            const tool = tools[index];            
            const { car, owner } = tool;            
            if (!car && !owner) {
                return false;
            }
            return true;
        },
            (message, params) => message,
            true    
        );
        

        this.validator.addCustomRule('project_date_range', 'Data Od musi być wcześniejsza niż Data Do', value => {
            const { dateStart, dateEnd } = this.state.element;
            return dateStart?.getTime() <= dateEnd?.getTime();
        });

        this.validator.addCustomRule('same_external_manager_for_user_and_project', 'Pracownik musi mieć przypisanego External Managera takiego jak projekt', (value, params) => {
            const { externalManager, users, managers } = this.state.element;            
            const index = parseInt(params[0]);
            const userMode = params[1] === 'user';
            

            if (externalManager) {
                if (userMode) {
                    if (users[index]?.employee?.externalManager?.id !== externalManager.id) {
                        return false;
                    }
                }
                else {
                    if (managers[index]?.employee?.externalManager?.id !== externalManager.id) {
                        return false;
                    }
                }
                //const m = managers.find(m => m?.employee?.externalManager?.id !== externalManager.id);
                //const result = !u && !m;
                return true;
            }
            return true;
        },
            (message, params) => message,
        );
    

        this.validator.addCustomRule('user_dates_in_project_range', 'Przypisanie użytkownika musi mieścić się w zakresie dat projektu', (value, params) => {
            const index = parseInt(params[0]);
            const usertype = params[1];
            const datetype = params[2];            
            if (index === undefined || index === null || index === NaN) {
                return true;
            }
            if (usertype !== 'user' && usertype !== 'manager'){
                throw new Error('Niepoprawny typ użytkownika!');
            }
            if (datetype !== 'start' && datetype !== 'end'){
                throw new Error('Niepoprawny typ daty!');
            }
            const {dateStart, dateEnd} = this.state.element;
            if (!dateStart || !dateEnd) {
                return true;
            }
            const list = usertype === 'user' ? this.state.element.users : this.state.element.managers;
            const user = list[index];
            if (!user.dateStart || !user.dateEnd) {
                return true;
            }
            if (datetype === 'start') {                
                if (user.dateStart.getTime() < dateStart.getTime() || user.dateStart.getTime() > dateEnd.getTime()) {
                    return false;
                }
            }
            if (datetype === 'end') {
                if (user.dateEnd.getTime() > dateEnd.getTime() || user.dateEnd.getTime() < dateStart.getTime()) {
                    return false;
                }
            }
            return true;
        },
            (message, params) => message,
        );



        this.validator.addCustomRule('employee_local_assigment_duplicates', 'Powielona data przypisania do projektu', (value, params) => {
            const userId = value?.id;
            const index = parseInt(params[0]);
            if (!userId || index === undefined || index === null || index === NaN) {
                return true;
            }
            const usertype = params[1];
            if (usertype !== 'user' && usertype !== 'manager'){
                throw new Error('Niepoprawny typ użytkownika!');
            }
            const list = usertype === 'user' ? this.state.element.users : this.state.element.managers;
            const user = list[index];
            
            if (!user.dateStart || !user.dateEnd) {
                return true;
            }
            const duplicates = list.filter(u => u.dateStart && u.dateEnd && u.employee && u !== user && u?.employee?.id === user?.employee.id)
                .filter(u => {
                    return (user.dateStart.getTime() <= u.dateStart.getTime() && user.dateEnd.getTime() >= u.dateEnd.getTime())
                        || (user.dateStart.getTime() >= u.dateStart.getTime() && user.dateEnd.getTime() <= u.dateEnd.getTime())
                        || (user.dateStart.getTime() <= u.dateStart.getTime() && user.dateEnd.getTime() >= u.dateStart.getTime())
                        || (user.dateStart.getTime() <= u.dateEnd.getTime() && user.dateEnd.getTime() >= u.dateEnd.getTime())
                });
            return duplicates.length == 0;
        },
            (message, params) => message,
        );

        this.validator.addCustomRule('employee_assigment_date_range', 'Data Od nie może być późniejsza niż Data Do', (value, params) => {            
            const index = parseInt(params[0]);
            const type = params[1];            
            if (index === undefined || index === null || index === NaN) {
                return true;
            }
            if (type !== 'user' && type !== 'manager'){
                throw new Error('Niepoprawny typ!');
            }
            const list = type === 'user' ? this.state.element.users : this.state.element.managers;
            const user = list[index];            
            const result = user?.dateStart?.getTime() <= user?.dateEnd?.getTime();
            return result;
        },
            (message, params) => message,
        );

        this.validator.addCustomRule('car_date_start_not_bigger_than_date_to', 'Data Od nie może być późniejsza niż Data Do', (value, params) => {
            const index = parseInt(params[0]);
            const car = this.state.element.projectCars[index];
            return car?.dateStart?.getTime() <= car?.dateEnd?.getTime();
        },
            (message, params) => message,
        );


        this.validator.addCustomRule('car_dates_in_project_range', 'Przypisanie pojazdu musi mieścić się w zakresie dat projektu', (value, params) => {
            const index = parseInt(params[0]);

            const currentProject = this.state.element;
            const car = this.state.element.projectCars[index];

            return currentProject?.dateStart <= car?.dateStart && currentProject?.dateEnd >= car?.dateEnd;
        },
            (message, params) => message,
        );
    }

    getContainerListName() {
        return 'project-list-container';
    }

    getAddSucces() {
        const { t } = this.props;
        return t('project.addUserSuccess');
    }

    getUpdateSucces() {
        const { t } = this.props;
        return t('project.updateUserSuccess');
    }

    fetchUserStatisticAndHandleError(user, userType) {
        this.fetchUserStatistic(user, userType)
        .catch((err) => this.showErrorMessage(err));
    }

    fetchUserStatistic(user, userType) {
        return new Promise((resolve, reject) => {
            const project = this.state.element;
            const list = userType ? project.users : project.managers;
            const listName = userType ? 'users' : 'managers';
            user = list.find(u => u === user);
            if (user.employee && user.dateStart && user.dateEnd) {
                user.renderStats = true;
                user.loadingStats = true;

                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        [listName]: list,
                    },
                }),
                    () => {
                        this.service.getEmployeeProjectPlaningStatistics(user.employee.id, project.id, user.dateStart, user.dateEnd, userType)
                        .then(stats => {
                            const list = userType ? project.users : project.managers;
                            user = list.find(u => u === user);
                            if (user) {
                                user.stats = stats;
                                user.loadingStats = false;
                                this.setState((prevState) => ({
                                    element: {
                                        ...prevState.element,
                                        [listName]: list,
                                    },
                                }),
                                () => {
                                    return resolve();
                                })
                            } else {
                                return resolve();
                            }
                        }).catch((err) => {
                            return reject('Nie udało się pobrać statystyk użytkownika!');
                        });
                    }
                );
            } else {
                user.renderStats = false;
                this.setState((prevState) => ({
                    element: {
                        ...prevState.element,
                        [listName]: list,
                    },
                }),
                () => {
                    return resolve();
                });
            }
        });
    }

    persistUsersWithDatesSameAsProject(callback) {
        const { dateEnd, dateStart, users, managers } = this.state.element;
        const processList = (list, sameDateStartList, sameDateEndList) => {
            sameDateStartList.length = 0;
            sameDateEndList.length = 0;
            list.forEach(u => {
                if (dateEnd) {
                    if (u?.dateEnd?.getTime() == dateEnd?.getTime()) {
                        sameDateEndList.push(u);
                    }
                }
                if (dateStart) {
                    if (u?.dateStart?.getTime() == dateStart?.getTime()) {
                        sameDateStartList.push(u);
                    }
                }
            });
        }
        processList(users, this.userDateStartSameAsProject, this.userDateEndSameAsProject);
        processList(managers, this.managerDateStartSameAsProject, this.managerDateEndSameAsProject);
        callback();
    }

    alignEmployeesDatesToProject() {
        const { dateStart, dateEnd, users, managers } = this.state.element;

        const processList = (list, sameDateStartList, sameDateEndList) => {
            const changes = [];
            list?.forEach(u => {
                if (dateStart && (!u?.dateStart || u?.dateStart?.getTime() < dateStart?.getTime() || sameDateStartList.includes(u))) {
                    u.dateStart = dateStart;
                    if (!changes.find(u2 => u2?.employee?.id == u?.employee?.id)) {
                        changes.push(u);
                    }
                }
                if (dateEnd && (!u?.dateEnd || u?.dateEnd?.getTime() > dateEnd?.getTime() || sameDateEndList.includes(u))) {
                    u.dateEnd = dateEnd;
                    if (!changes.find(u2 => u2?.employee?.id == u?.employee?.id)) {
                        changes.push(u);
                    }
                }
            })
            return changes;
        }
        let errorHandled = false;
        processList(users, this.userDateStartSameAsProject, this.userDateEndSameAsProject)
            .forEach(user => this.fetchUserStatistic(user, true).catch(err => {                
                if (!errorHandled) {
                    this.showErrorMessage(err);
                }
                errorHandled = true;
                
            }))            

        processList(managers, this.managerDateStartSameAsProject, this.managerDateEndSameAsProject)
            .forEach(manager => this.fetchUserStatistic(manager, false).catch(err => {                
                if (!errorHandled) {
                    this.showErrorMessage(err);
                }
                errorHandled = true;                
            }))
        

        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                users,
                managers
            },
        }));
    }


    projectAddressInitial() {

        return {
            id: undefined,
            project: {
                id: this.props.elementId,
            },
            address: {
                streetNumber: '',
                houseNumber: '',
                streetName: '',
                postalCode: '',
                city: '',
                country: undefined,
            }
        }
    }

    searchEmployees(query, userId, mode) {
        //removing already defined
        let loginAllowed = null;
        if (mode === 'managers') {
            loginAllowed = true;
        }
        this.employeeService.search(query, loginAllowed, UserActivityStatus.ACTIVE)
            .then(filteredEmployees =>{
                this.setState({ filteredEmployees})
            }
            ).catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy pracowników');
            });


    }

    searchCars(query) {
        const list = [];
        this.carService
            .searchCars(query, 'ACTIVE', null).then(data => {
                this.setState({
                    filteredCars: list.concat(data)
                })
            })
    }


    updateElement(data) {

        if (data.dateStart) {
            data.dateStart = moment(data.dateStart).toDate();
        }
        if (data.dateEnd) {
            data.dateEnd = moment(data.dateEnd).toDate();
        }
        if (!data.summary) {
            data.summary = {};
        }
        if (!data.summary.unitPrice) {
            data.summary.unitPrice = {};
        }
        if (!data.summary.revenueEmployees) {
            data.summary.revenueEmployees = {};
        }
        if (!data.summary.revenueExtra) {
            data.summary.revenueExtra = {};
        }
        if (!data.summary.revenueTotal) {
            data.summary.revenueTotal = {};
        }
        if (!data.summary.costEmployees) {
            data.summary.costEmployees = {};
        }
        if (!data.summary.costDrivers) {
            data.summary.costDrivers = {};
        }
        if (!data.summary.costExpenses) {
            data.summary.costExpenses = {};
        }
        if (!data.summary.costTotal) {
            data.summary.costTotal = {};
        }
        if (!data.summary.income) {
            data.summary.income = {};
        }
        if (!data.projectAddress) {
            data.projectAddress = this.projectAddressInitial();
        }
        if (data.users) {
            data.users.sort((u1, u2) => {
                if (u1?.employee?.fullName > u2?.employee?.fullName) return 1;
                if (u1?.employee?.fullName < u2?.employee?.fullName) return -1;
                return 0;
            });
            data.users.forEach(u => {
                if (u.dateStart) {
                    u.dateStart = moment(u.dateStart).toDate();
                }
                if (u.dateEnd) {
                    u.dateEnd = moment(u.dateEnd).toDate();
                }
            })
        }
        if (data.projectCars){
            data.projectCars.forEach(pc=>{
                if (pc.dateStart) {
                    pc.dateStart = moment(pc.dateStart).toDate();
                }
                if (pc.dateEnd) {
                    pc.dateEnd = moment(pc.dateEnd).toDate();
                }
            })
        }
        if (data.managers) {
            data.managers.sort((m1, m2) => {
                if (m1?.employee?.fullName > m2?.employee?.fullName) return 1;
                if (m1?.employee?.fullName < m2?.employee?.fullName) return -1;
                return 0;
            });
            data.managers.forEach(m => {
                if (m.dateStart) {
                    m.dateStart = moment(m.dateStart).toDate();
                }
                if (m.dateEnd) {
                    m.dateEnd = moment(m.dateEnd).toDate();
                }
            })
        }
        if (data.activities) {
            data.activities.sort((a1, a2) => {
                if (a1?.name > a2?.name) return 1;
                if (a1?.name < a2?.name) return -1;
                return 0;
            })
        }

        if (data.expenses) {
            data.expenses.sort((e1, e2) => {
                if (e1?.date > e2?.date) return 1;
                if (e1?.date < e2?.date) return -1;
                return 0;
            })
        }

        this.setState(
            {
                element: data
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }


    initAfterSetElement() {
        super.initAfterSetElement();

        const { users, managers } = this.state.element;
        let errorHandled = false;

        const roleAdmin = this.authService.isUserInRole('ROLE_ADMIN');
        const rolePm = this.authService.isUserInRole('ROLE_USER');
        const roleSupervisor = this.authService.isUserInRole('ROLE_SUPERVISOR');
        const roleCto = this.authService.isUserInRole('ROLE_CTO');
        const roleFm = this.authService.isUserInRole('ROLE_FINANCIAL_MANAGER');

        this.state.element.activities.forEach(a => {
            if (!this.state.activities.map((x) => x.id).includes(a?.activity?.id)) {
                if (a.activity == null || a.activity == {}) {
                    return;
                }
                this.state.activities.push({
                    ...a.activity,
                    className: ''
                });
            }
        })
        this.state.element.categories.forEach(c => {
            if (!this.state.categories.map((x) => x.id).includes(c.id)) {
                this.state.categories.push(c);
            }
        });

        if (this.state.element.client != null && (this.state.clients == null || !this.state.clients?.map(c => c.id).includes(this.state.element.client.id))) {
            this.state.clients.push(this.state.element.client);
        }

        //Brak potrzeby fetchowania statystyk jesli user jest tylko financial managerem, ma tylko dostep do zakladki rozliczenie
        if (roleAdmin || roleCto || rolePm || roleSupervisor) {
            users.forEach(user => {
                this.fetchUserStatistic(user, true).catch(err => {
                    if (!errorHandled) {
                        this.showErrorMessage(err);
                    }
                    errorHandled = true;
                })
            })
            managers.forEach(manager => {
                this.fetchUserStatistic(manager, false).catch(err => {
                    if (!errorHandled) {
                        this.showErrorMessage(err);
                    }
                    errorHandled = true;
                })
            })
        }

    }



    initBeforeSetElement() {

        if (this.props.viewMode === 'VIEW') {
            return;
        }
        const roleAdmin = this.authService.isUserInRole('ROLE_ADMIN');
        const rolePm = this.authService.isUserInRole('ROLE_USER');
        const roleSupervisor = this.authService.isUserInRole('ROLE_SUPERVISOR');
        const roleCto = this.authService.isUserInRole('ROLE_CTO');
        const roleFm = this.authService.isUserInRole('ROLE_FINANCIAL_MANAGER');
        const roleEm = this.authService.isUserInRole('ROLE_EXTERNAL_MANAGER');
        
        //Dla filtrow 
        this.projectCategoryService.getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
            .then(categories => this.setState({ categories: categories.content }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });
        this.enumService.getEnumList('ProjectManagerRoleType')
            .then(leaderRoles => {
                this.setState({ leaderRoles });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ról liderów');
            });
        this.internalUserService.getSimpleList('ROLE_USER')
            .then(users => this.setState({ projectManagers: users }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy menedżerów');
            });
        this.internalUserService.getSimpleList(['ROLE_SUPERVISOR', 'ROLE_USER', 'ROLE_ADMIN', 'ROLE_CTO'])
            .then(users => this.setState({ supervisors: users }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy menedżerów');
            });
        this.internalUserService.getSimpleList(['ROLE_EXTERNAL_MANAGER'])
            .then(users => this.setState({ externalManagers: users }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy menedżerów zewnętrznych');
            });    
        this.externalSupervisorService
            .getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'id', sortAsc: true })
            .then(data => this.setState({ externalSupervisors: data.content }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy opiekunów');
            });

        if (roleAdmin || roleCto || roleFm || roleEm) { // Dla zakladki Rozliczenie
            this.enumService
                .getEnumList('Currency')
                .then(currencies => this.setState({ currencies }))
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy walut');
                });
            this.enumService
                .getEnumList('PriceType')
                .then(priceTypes => {
                    priceTypes.forEach(pt => pt.label = t(pt.label));
                    this.setState({ priceTypes });
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy rodzajów rozliczenia');
                });
        }
        if (roleAdmin || roleCto || rolePm || roleSupervisor || roleEm) { //Do pozostalych zakladek do ktorych maja dostep inni userzy
            this.countryService.getAll()
                .then((countries) => {
                    this.setState({ countries });
                })
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy krajów');
                });
            this.clientService.getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
                .then(clients => this.setState({ clients: clients.content }))
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy klientów');
                });

            this.activityService.getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
                .then(activities => this.setState({ activities: activities.content }))
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy aktywności');
                });
            this.carService.getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'licensePlate', sortAsc: true })
                .then(cars => this.setState({ cars: cars.content }))
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy pojazdów');
                });
            this.toolsService.getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
                .then(tools => this.setState({ tools: tools.content }))
                .catch(() => {
                    this.showErrorMessage('Nie udało się pobrać listy narzędzi');
                });
        }

    }

    handleGetDetailsError(err) {
        this.showErrorMessage('Błąd podczas pobrania szczegółów');
        if (this.props.backUrl) {
                this.blockUi();
                this.persistMessage('error', '', err.message);
                window.location.href = this.props.backUrl;
        } else {
            this.setState({ loading: false }, () => this.unblockUi());
        }
    }

    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        const initActiveIndex = this.state.activeIndex;
        this.setState({ activeIndex: 0 }, () => {
            if (this.validator.allValid()) {
                this.setState({ activeIndex: 1 }, () => {
                    if (this.validator.allValid()) {
                        this.setState({ activeIndex: 2 }, () => {
                            if (this.validator.allValid()) {
                                this.setState({ activeIndex: 5 }, () => {
                                    if (this.validator.allValid()) {
                                        this.setState({activeIndex: initActiveIndex}, () => this.blockUi(this.handleValidForm));
                                    } else {
                                        this.validator.showMessages();
                                        this.scrollToError = true;
                                        this.forceUpdate();
                                    }
                                })

                            } else {
                                this.validator.showMessages();
                                this.scrollToError = true;
                                this.forceUpdate();
                            }
                        });

                    } else {
                        this.validator.showMessages();
                        this.scrollToError = true;
                        this.forceUpdate();
                    }
                })

            } else {
                this.validator.showMessages();
                this.scrollToError = true;
                this.forceUpdate();
            }

        });
    }

    backUrlParams() {
        return `?activeIndex=${this.state.activeIndex}`;
    }

    prepareFooterItems() {
        const result = super.prepareFooterItems();
        const { viewMode, editUrl } = this.props;
        const { element } = this.state;

        switch (viewMode) {
            case 'EDIT':
            case 'NEW':
                break;
            case 'VIEW':
                if (this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO','ROLE_EXTERNAL_MANAGER')) {
                    if (element?.status?.enumValue !== 'CLOSED') {
                        result.push({ label: 'Edytuj', className: 'float-right', href: `${editUrl}/${element.id}?activeIndex=${this.state.activeIndex}` })
                    }
                    if (element?.status?.enumValue === 'ACTIVE') {
                        result.push(this.prepareCloseProjectButton());
                    }
                }

                break;
            default:
                break;


        }
        return result;
    }


    prepareCloseProjectButton() {
        return {
            label: t('project.details.closeProjectButton'),
            type: 'BUTTON',
            variant: '',
            className: 'float-right fullred ',
            onClick: () => {
                this.setState({
                    showConfirmCloseProject: true,
                });
            },
            rendered: true,
            iconSide: 'left',
            iconSize: 'm',
        };
    }


    isPdfButtonRendered() {
        return false;
    }

    getPdfDownloadLabel() {
        return null;
    }

    handleDownloadPdf() {
    }


    createQueryString(queryObject = {}) {
        let queryString = Object.keys(queryObject)
            .filter((key) => queryObject[key] && !(Array.isArray(queryObject[key]) && !queryObject[key].length))
            .map((key) => {
                return Array.isArray(queryObject[key]) ? queryObject[key].map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&') : `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`;
            }).join('&');
        return queryString ? `?${queryString}` : "";
    };


    carTemplate(rowData, props){
        const { rowIndex } = props;
        return (
                <div>
                    <div style={{marginTop:"14px"}}>
                        <InputAutocompleteComponent
                            id={`employee${rowIndex}`}
                            name="car"
                            colClass='col-md-7'
                            label={t('project.details.car')}
                            value={rowData.car}
                            dropdown
                            forceSelection
                            viewMode={this.props.viewMode}
                            validator={this.validator}
                            validators={`required`}
                            showLabel={false}
                            onChange={this.handleChange}
                            insideTable={true}
                            filteredList={this.state.filteredCars}
                            filterList={query => this.searchCars(query)}
                            field="licensePlate"
                            stateField={`element.projectCars[${rowIndex}]`}
                            minLength={3}
                            placeholder={t('search.cars.placeholder')}
                        />
                    </div>    
                </div>
        )
    }
    carStartDateTemplate(rowData, props) {
        const { rowIndex } = props;
        return (
            <InputCalendarComponent
                id={`employeeDateStart${rowIndex}`}
                colClass='col-md-2'
                name="dateStart"
                label='Od'
                value={rowData.dateStart}
                onChange={(x, xx, event) => {
                    rowData.dateStart = event.value;
                }}
                viewMode={this.props.viewMode}
                validator={this.validator}
                validators={`required|car_date_start_not_bigger_than_date_to:${rowIndex}|car_dates_in_project_range:${rowIndex}`}
                stateField={`element.projectCars[${rowIndex}]`}
                insideTable
                showLabel={false}
                showIcon
            />
        )
    }
    carEndDateTemplate(rowData, props) {
        const { rowIndex } = props;
        return (
            <InputCalendarComponent
                id={`employeeDateEnd${rowIndex}`}
                colClass='col-md-2'
                name="dateEnd"
                label='Do'
                value={rowData.dateEnd}
                onChange={(x, xx, event) => {
                    rowData.dateEnd = event.value;
                }}
                viewMode={this.props.viewMode}
                validator={this.validator}
                validators={`required|car_date_start_not_bigger_than_date_to:${rowIndex}|car_dates_in_project_range:${rowIndex}`}
                stateField={`element.projectCfars[${rowIndex}]`}
                insideTable
                showLabel={false}
                showIcon
            />
        )
    }
    carActionsTemplate(rowData, props) {
        const { rowIndex } = props;
        const projectCar = rowData;
        return (
            <div className="col-md-1">
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    rendered={this.props.viewMode != 'VIEW'}
                    handleClick={(e) => {
                        const projectCars = this.state.element.projectCars.filter(pc => pc != projectCar);
                        this.setState((prevState) => ({
                            element: {
                                ...prevState.element,
                                projectCars,
                            },
                        }));
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                />
            </div>
        )
    }
    handleAddNewCar(){
        const { projectCars, dateStart, dateEnd } = this.state.element;
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                projectCars: projectCars.concat({ dateStart, dateEnd }),
            },
        }));
    }
    
    renderDetails() {
        const extraDetailsRendered = this.props.viewMode !== 'NEW' && this.state.element?.summary?.revenueExtra?.value > 0;
        const workCostRendered = this.props.viewMode !== 'NEW' && this.state.element?.summary?.costEmployees?.value > 0;
        const driveCostRendered = this.props.viewMode !== 'NEW' && this.state.element?.summary?.costDrivers?.value > 0;;
        const roleAdmin = this.authService.isUserInRole('ROLE_ADMIN');
        const rolePm = this.authService.isUserInRole('ROLE_USER');
        const roleSupervisor = this.authService.isUserInRole('ROLE_SUPERVISOR');
        const roleCto = this.authService.isUserInRole('ROLE_CTO');
        const roleFm = this.authService.isUserInRole('ROLE_FINANCIAL_MANAGER');
        const roleEm = this.authService.isUserInRole('ROLE_EXTERNAL_MANAGER');
        return (
            <React.Fragment>

                <DivContainer colClass='row'>
                    <EmployeeStatisticDialog
                        visible={this.state.visibleEmployeeStatistic}
                        onHide={() => this.setState({visibleEmployeeStatistic: false})}
                        user={this.state.selectedEmployee}
                        stats={this.state.selectedEmployeeStats}
                        dateStart={this.state.selectedEmployeeDateStart}
                        dateEnd={this.state.selectedEmployeeDateEnd}
                    />

                    <DivContainer colClass='col-md-12'>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => {
                            this.setState({ activeIndex: e.index }, () => {
                                this.props.history.push({
                                    search: this.createQueryString({ activeIndex: e.index })
                                });
                            });
                        }} >
                            <TabPanel header={t('project.details.mainTabHeader')}>
                                <Card style={{ border: "none" }}>
                                    <Card header={t('project.details.baseInformation')}>
                                        <DivContainer colClass='row'>
                                            <InputTextComponent
                                                id='name'
                                                name='name'
                                                label={t('project.details.name')}
                                                colClass='col-md-10'
                                                value={this.state.element.name}
                                                validators='required|max:150'
                                                validator={this.validator}
                                                onChange={this.handleChange}
                                                viewMode={this.props.viewMode}
                                            />
                                            <InputCalendarRangeComponent
                                                id='date'
                                                idFrom='projectDateStart'
                                                idTo='projectDateEnd'
                                                nameFrom='dateStart'
                                                nameTo='dateEnd'
                                                labelFrom={t('project.details.dateStart')}
                                                labelTo={t('project.details.dateEnd')}
                                                dateFormatMoment={Constants.DATE_FORMAT}
                                                colClass='col-md-6'
                                                from={this.state.element.dateStart}
                                                to={this.state.element.dateEnd}
                                                validator={this.validator}
                                                validators='required|project_date_range'
                                                validatorsTo='required'
                                                onChange={this.handleChange}
                                                viewMode={this.props.viewMode}
                                                showIcon={true}
                                                onAfterStateChange={this.alignEmployeesDatesToProject}
                                                onBeforeStateChange={callback => this.persistUsersWithDatesSameAsProject(callback)}
                                            />
                                        </DivContainer>


                                        <DivContainer colClass='row'>
                                            <InputDropdownComponent
                                                id='client'
                                                name='client'
                                                label={t('project.details.client')}
                                                colClass='col-md-6'
                                                value={this.state.element.client}
                                                valueView={this.state.element?.client?.name}
                                                dataKey='id'
                                                options={this.state.clients}
                                                optionLabel='name'
                                                viewMode={this.props.viewMode}
                                                validator={this.validator}
                                                validators='required'
                                                onChange={this.handleChange}
                                                filter
                                                filterBy='name'
                                            />


                                            <div className='col-md-6'>
                                                <div className='row'>
                                                    <div className='col-md-12'>
                                                        <label id='clientAddress-label-id' className='p-label' htmlFor='clientAddress' style={{ width: '100%' }}>
                                                            {t('project.details.clientAddress')}
                                                        </label>
                                                        <div className='row'>
                                                            <div className='col-md-4'><span className='p-inputtext-view icon_text'>Kraj</span></div>
                                                            <div className='col-md-8'><span className='p-inputtext-view icon_text'>{this.state.element?.client?.address?.country?.name}</span></div>
                                                            <div className='col-md-4'><span className='p-inputtext-view icon_text'>Miasto</span></div>
                                                            <div className='col-md-8'><span className='p-inputtext-view icon_text'>{this.state.element?.client?.address?.postalCode} {this.state.element?.client?.address?.city}</span></div>
                                                            <div className='col-md-4'><span className='p-inputtext-view icon_text'>Ulica</span></div>
                                                            <div className='col-md-8'><span className='p-inputtext-view icon_text'>{this.state.element?.client?.address?.streetName} {this.state.element?.client?.address?.streetNumber}</span></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <InputMultiSelectComponent
                                                id='categories'
                                                name='categories'
                                                label={t('project.details.categories')}
                                                colClass='col-md-8'
                                                onChange={this.handleChange}
                                                options={this.state.categories}
                                                optionLabel='name'
                                                value={this.state.element.categories}
                                                viewMode={this.props.viewMode}
                                                dataKey='id'
                                                selectedItemTemplate={this.selectedItemTemplate}
                                                filter
                                                filterBy='name'

                                            />
                                        </DivContainer>
                                    </Card>
                                    <Card header={t('project.details.addressInformation')}>
                                        <ProjectAddress
                                            element={this.state.element}
                                            countries={this.state.countries}
                                            validator={this.validator}
                                            viewMode={this.props.viewMode}
                                            handleChange={this.handleChange}
                                        />
                                    </Card>
                                </Card>
                            </TabPanel>

                            
                            <TabPanel header={t('project.details.detailsTabHeader')}>
                                <Card style={{ border: "none" }}>
                                    <Card header={t('project.details.activities')}>
                                        <DivContainer colClass='row'>
                                            <DataTable value={this.state.element?.activities} responsiveLayout="scroll" emptyMessage={t('list.empty')}>
                                                <Column field="activity" header="Aktywność" body={this.projectActivityActivityTemplate.bind(this)}></Column>
                                                <Column field="price" header="Cena/h" body={this.projectActivityPriceTemplate.bind(this)}></Column>
                                                {this.props.viewMode != 'VIEW' ? (
                                                    <Column header="" body={this.projectActivityActionsTemplate.bind(this)}></Column>
                                                ) : null}
                                            </DataTable>
                                        </DivContainer>
                                        <DivContainer>
                                            <ActionLink
                                                label={t('project.details.addActivityButton')}
                                                iconName='mdi-plus'
                                                iconSide='left'
                                                iconSize='icon-large'
                                                variant='blue'
                                                className='float-right'
                                                key={'add-activity-button'}
                                                handleClick={this.handleAddNewProjectActivity.bind(this)}
                                                rendered={this.props.viewMode !== 'VIEW'}
                                            />
                                        </DivContainer>
                                    </Card>

                                    {/* <Card header='Pojazdy' style={{ marginTop: '20px' }}>
                                        <DivContainer colClass='row'>
                                            <InputMultiSelectComponent
                                                id='cars'
                                                name='cars'
                                                label={t('project.details.cars')}
                                                showLabel={false}
                                                colClass='col-md-8'
                                                onChange={this.handleChange}
                                                options={this.state.cars}
                                                optionLabel='licensePlate'
                                                value={this.state.element.cars}
                                                dataKey='id'
                                                viewMode={this.props.viewMode}
                                                filter
                                                filterBy='licensePlate'
                                            />
                                        </DivContainer>
                                    </Card> */}

                                    <Card header={t('project.details.cars')}>
                                        <DivContainer colClass='row'>
                                            <DataTable value={this.state.element?.projectCars} responsiveLayout="scroll" emptyMessage={t('list.empty')}>
                                                <Column field="car" header="Pojazd" body={this.carTemplate.bind(this)}></Column>
                                                <Column field="dateStart" header="Od" body={this.carStartDateTemplate.bind(this)}></Column>
                                                <Column field="dateEnd" header="Do" body={this.carEndDateTemplate.bind(this)}></Column>
                                                {this.props.viewMode != 'VIEW' ? (
                                                    <Column header="" body={this.carActionsTemplate.bind(this)}></Column>
                                                ) : null}
                                            </DataTable>
                                        </DivContainer>
                                        <DivContainer rendered={this.props.viewMode != 'VIEW'}>
                                            <ActionLink
                                                label={t('project.details.addCarButton')}
                                                iconName='mdi-plus'
                                                iconSide='left'
                                                iconSize='icon-large'
                                                variant='blue'
                                                className='float-right'
                                                key={'add-activity-button'}
                                                handleClick={this.handleAddNewCar.bind(this)}
                                            />
                                        </DivContainer>
                                    </Card>

                                    <Card header={t('project.details.tools')} style={{ marginTop: '20px' }}>
                                        <DivContainer colClass='row'>
                                            <DataTable value={this.state.element.tools} responsiveLayout="scroll" emptyMessage={t('list.empty')}>
                                                <Column field="activity" header="Zestaw" body={this.projectToolsToolsTemplate.bind(this)}></Column>
                                                <Column field="owner" header="Właściciel" body={this.projectToolsOwnerTemplate.bind(this)}></Column>
                                                <Column field="car" header="Pojazd" body={this.projectToolsCarTemplate.bind(this)}></Column>
                                                {this.props.viewMode != 'VIEW' ? (
                                                    <Column header="" body={this.projectToolsActionsTemplate.bind(this)}></Column>
                                                ) : null}

                                            </DataTable>
                                        </DivContainer>
                                        <DivContainer rendered={this.props.viewMode != 'VIEW'}>
                                            <ActionLink
                                                label={t('project.details.addToolsButton')}
                                                iconName='mdi-plus'
                                                iconSide='left'
                                                iconSize='icon-large'
                                                variant='blue'
                                                className='float-right'
                                                key={'add-activity-button'}
                                                handleClick={this.handleAddNewTools.bind(this)}
                                            />
                                        </DivContainer>
                                    </Card>
                                </Card>
                            </TabPanel>

                            
                            <TabPanel header={t('project.details.teamTabHeader')}>
                                <Card style={{ border: "none" }}>
                                    <Card header={t('project.details.employees')}>
                                        <DivContainer colClass='row'>
                                            <DataTable value={this.state.element?.users} responsiveLayout="scroll" emptyMessage={t('list.empty')}>
                                                <Column field="employee" header="Pracownik" body={this.projectUserTemplate.bind(this)}></Column>
                                                <Column field="dateStart" header="Od" body={this.projectUserStartTemplate.bind(this)}></Column>
                                                <Column field="dateTo" header="Do" body={this.projectUserEndTemplate.bind(this)}></Column>
                                                {roleAdmin || roleCto || rolePm || roleSupervisor || roleEm ? (
                                                    <Column field="info" header="Status" body={this.projectUserStatisticTemplate.bind(this)}></Column>
                                                ) : null}
                                                {this.props.viewMode != 'VIEW' ? (
                                                    <Column header="" body={this.projectUserActionsTemplate.bind(this)}></Column>
                                                ) : null}
                                            </DataTable>
                                        </DivContainer>
                                        <DivContainer rendered={this.props.viewMode != 'VIEW'}>
                                            <ActionLink
                                                label={t('project.details.addEmployeeButton')}
                                                iconName='mdi-plus'
                                                iconSide='left'
                                                iconSize='icon-large'
                                                variant='blue'
                                                className='float-right'
                                                key={'add-activity-button'}
                                                handleClick={this.handleAddNewProjectUser.bind(this)}
                                            />
                                        </DivContainer>
                                    </Card>
                                    <Card header={t('project.details.leaders')} style={{ marginTop: '20px' }}>
                                        <DivContainer colClass='row'>
                                            <DataTable value={this.state.element?.managers} responsiveLayout="scroll" emptyMessage={t('list.empty')}>
                                                <Column field="employee" header="Lider" body={this.projectManagerTemplate.bind(this)}></Column>
                                                <Column field="from" header="Od" body={this.projectManagerStartTemplate.bind(this)}></Column>
                                                <Column field="price" header="Do" body={this.projectManagerEndTemplate.bind(this)}></Column>
                                                <Column field="leaderType" header="Typ" body={this.projectManagerTypeTemplate.bind(this)}></Column>
                                                <Column field="info" header="Status" body={this.projectManagerStatisticTemplate.bind(this)}></Column>
                                                {this.props.viewMode != 'VIEW' ? (
                                                    <Column header="" body={this.projectManagerActionsTemplate.bind(this)}></Column>
                                                ) : null}
                                            </DataTable>
                                        </DivContainer>
                                        <DivContainer rendered={this.props.viewMode != 'VIEW'}>
                                            <ActionLink
                                                label={t('project.details.addLeaderButton')}
                                                iconName='mdi-plus'
                                                iconSide='left'
                                                iconSize='icon-large'
                                                variant='blue'
                                                className='float-right'
                                                key={'add-activity-button'}
                                                handleClick={this.handleAddNewProjectManager.bind(this)}
                                            />
                                        </DivContainer>
                                    </Card>

                                    <Card header={t('project.details.managers')} style={{ marginTop: '20px' }}>
                                        <DivContainer colClass='row'>
                                            <div className="col-md-8">
                                                <InputDropdownComponent
                                                    id={`projectManager`}
                                                    name="projectManager"
                                                    label={t('project.details.projectManager')}
                                                    value={this.state.element.projectManager}
                                                    valueView={this.state.element?.projectManager?.fullName}
                                                    dataKey='id'
                                                    options={this.state.projectManagers}
                                                    optionLabel='fullName'
                                                    viewMode={this.props.viewMode}
                                                    validator={this.validator}
                                                    validators='not_required'
                                                    onChange={this.handleChange}
                                                    insideTable
                                                    filter
                                                    filterBy="fullName"
                                                    showClear
                                                />
                                            </div>
                                        </DivContainer>
                                        <DivContainer colClass='row'>
                                            <div className="col-md-8">
                                                <InputDropdownComponent
                                                    id={`externalSupervisor`}
                                                    name="externalSupervisor"
                                                    label={t('project.details.externalSupervisor')}
                                                    value={this.state.element.externalSupervisor}
                                                    valueView={this.state.element?.externalSupervisor?.fullName}
                                                    dataKey='id'
                                                    options={this.state.externalSupervisors}
                                                    optionLabel='fullName'
                                                    viewMode={this.props.viewMode}
                                                    validator={this.validator}
                                                    validators='not_required'
                                                    onChange={this.handleChange}
                                                    insideTable
                                                    filter
                                                    filterBy="fullName"
                                                    showClear
                                                />
                                            </div>
                                        </DivContainer>
                                        <DivContainer colClass='row'>
                                            <div className="col-md-8">
                                                <InputDropdownComponent
                                                    id={`supervisor`}
                                                    name="supervisor"
                                                    label={t('project.details.supervisor')}
                                                    value={this.state.element.supervisor}
                                                    valueView={this.state.element?.supervisor?.fullName}
                                                    dataKey='id'
                                                    options={this.state.supervisors}
                                                    optionLabel='fullName'
                                                    viewMode={this.props.viewMode}
                                                    validator={this.validator}
                                                    validators='not_required'
                                                    onChange={this.handleChange}
                                                    insideTable
                                                    filter
                                                    filterBy="fullName"
                                                    showClear
                                                />
                                            </div>
                                        </DivContainer>
                                        <DivContainer colClass='row'>
                                            <div className="col-md-8">
                                                <InputDropdownComponent
                                                    id={`externalManager`}
                                                    name="externalManager"
                                                    label={t('project.details.externalManager')}
                                                    value={this.state.element.externalManager}
                                                    valueView={this.state.element?.externalManager?.fullName}
                                                    dataKey='id'
                                                    options={this.state.externalManagers}
                                                    optionLabel='fullName'
                                                    viewMode={this.props.viewMode}
                                                    validator={this.validator}
                                                    validators='not_required'
                                                    onChange={this.handleChange}
                                                    insideTable
                                                    filter
                                                    filterBy="fullName"
                                                    showClear
                                                    disabled={roleEm && !roleAdmin && !roleCto && !rolePm}
                                                />
                                            </div>
                                        </DivContainer>
                                    </Card>
                                </Card>
                            </TabPanel>

                            <TabPanel header={t('project.details.expansesTabHeader')}>
                                {this.state.element.id ? (
                                    <ProjectExpensesContainer
                                        validator={this.validator}
                                        expenses={this.state.element.expenses}
                                        projectId={this.state.element.id}
                                        onProjectChanged={(project) => {
                                            this.updateElement(project)
                                        }}
                                        blockUi={this.blockUi}
                                        unblockUi={this.unblockUi}
                                    />
                                ) : (
                                    <Card>{t('global.availabilityAfterSaveProject')}</Card>
                                )}
                            </TabPanel>
                            
                            {roleAdmin || roleCto || rolePm || roleSupervisor || roleEm ? (
                                <TabPanel header={t('project.details.extraEventTabHeader')}>
                                    {this.state.element.id ? (
                                        <ProjectExtraEventListContainer
                                            validator={this.validator}
                                            projectId={this.state.element.id}
                                            onProjectChanged={(project) => {
                                                this.updateElement(project)
                                            }}
                                        />
                                    ) : (
                                        <Card>{t('global.availabilityAfterSaveProject')}</Card>
                                    )}
                                </TabPanel>
                            ) : null}

                            
                            <TabPanel header={t('project.details.summaryTabHeader')}>
                                <Card style={{ border: "none" }}>
                                    <Card header={t('project.details.parameters')}>
                                        <DivContainer colClass='row'>
                                            <InputDropdownComponent
                                                id='priceType'
                                                name='priceType'
                                                label={t('project.details.priceType')}
                                                value={this.state.element.summary.priceType}
                                                stateField='element.summary'
                                                dataKey='enumValue'
                                                options={this.state.priceTypes}
                                                optionLabel='label'
                                                viewMode={this.props.viewMode}
                                                validator={this.validator}
                                                validators='not_required'
                                                onChange={this.handleChange}
                                                onAfterStateChange={this.calculateRevenue}
                                            />
                                            <InputTextComponent
                                                id='panelRatio'
                                                name='panelRatio'
                                                value={NumberUtils.formatNumber(this.state.element?.summary?.panelRatio, 6, 6)}
                                                viewMode={'VIEW'}
                                                label={t('project.details.panelRatio')}
                                            />
                                        </DivContainer>
                                        <DivContainer colClass='row'>
                                            <InputNumberComponent
                                                id='panelNumber'
                                                name='panelNumber'
                                                label={t('project.details.panelNumber')}
                                                value={this.state.element.panelNumber}
                                                onChange={this.handleChange}
                                                viewMode={this.props.viewMode}
                                                validator={this.validator}
                                                validators='not_required'
                                                onAfterStateChange={this.calculateRevenue}
                                                minFractionDigits={0}
                                                maxFractionDigits={2}
                                                mode='decimal'
                                            />
                                            <InputNumberComponent
                                                id='kwpNumber'
                                                name='kwpNumber'
                                                label={t('project.details.kwpNumber')}
                                                value={this.state.element.kwpNumber}
                                                onChange={this.handleChange}
                                                viewMode={this.props.viewMode}
                                                validator={this.validator}
                                                validators='not_required'
                                                onAfterStateChange={this.calculateRevenue}
                                                minFractionDigits={0}
                                                maxFractionDigits={2}
                                                mode='decimal'
                                            />

                                            <InputNumberComponent
                                                id='unitPriceValue'
                                                name='value'
                                                label={t('project.details.unitPriceValue')}
                                                value={this.state.element.summary.unitPrice.value}
                                                stateField='element.summary.unitPrice'
                                                onChange={this.handleChange}
                                                viewMode={this.props.viewMode}
                                                validator={this.validator}
                                                validators='not_required'
                                                onAfterStateChange={this.calculateRevenue}
                                                mode='currency'
                                                currency='EUR'
                                                locale='pl-PL'
                                                rendered={roleAdmin || roleCto || roleFm || roleEm}
                                            />
                                        </DivContainer>
                                    </Card>
                                    {!roleFm && !roleAdmin && !roleCto && !roleEm ? (
                                    <Card header='Zestawienie godzin'  style={{ marginTop: "10px" }}>
                                        <DivContainer colClass='row'>
                                            <DivContainer colClass='col-md-10'>
                                                <DivContainer colClass="row">                                                    
                                                    <InputTextComponent
                                                        colClass="col-md-4"
                                                        label='Zaraportowane godziny pracy'
                                                        viewMode='EDIT'
                                                        disabled
                                                        value={NumberUtils.formatTimeIfPossible(this.state.element?.summary?.hoursEmployees)}
                                                        format='number'
                                                    />
                                                    <InputTextComponent
                                                        colClass="col-md-4"
                                                        label='Zaraportowane godziny pracy kierowcy'
                                                        viewMode='EDIT'
                                                        disabled
                                                        value={NumberUtils.formatTimeIfPossible(this.state.element?.summary?.hoursDrivers)}
                                                        format='number'
                                                    />
                                                    <InputTextComponent
                                                        colClass="col-md-4"
                                                        label='Zatwierdzone godziny pracy dodatkowej'
                                                        viewMode='EDIT'
                                                        disabled
                                                        value={NumberUtils.formatTimeIfPossible(this.state.element?.summary?.hoursExtra)}
                                                        format='number'
                                                    />
                                                </DivContainer>
                                            </DivContainer>
                                        </DivContainer>
                                    </Card>
                                    ) : null}
                                    {roleAdmin || roleCto || roleFm || roleEm ? (
                                    <Card header={t('project.details.revenue')} style={{ marginTop: "10px" }}>
                                        <DivContainer colClass='row'>
                                            <DivContainer colClass='col-md-2 p-label-bold'>
                                                {t('project.details.mainRevenue')}
                                            </DivContainer>
                                            <DivContainer colClass='col-md-10'>
                                                <DivContainer colClass="row">
                                                    <InputNumberComponent
                                                        colClass="col-md-4"
                                                        id='revenueValue'
                                                        name='value'
                                                        label='Wartość'
                                                        value={this.state.element.summary?.revenueEmployees?.value}
                                                        viewMode={'EDIT'}
                                                        disabled
                                                        mode='currency'
                                                        currency='EUR'
                                                        locale='pl-PL'
                                                    />
                                                </DivContainer>
                                            </DivContainer>
                                            <DivContainer colClass='col-md-2 p-label-bold'>
                                                {t('project.details.extraRevenue')}
                                            </DivContainer>
                                            <DivContainer colClass='col-md-10'>
                                                <DivContainer colClass="row">
                                                    <InputNumberComponent
                                                        colClass="col-md-4"
                                                        label={t('project.details.revenueExtraPriceTotal')}
                                                        viewMode='EDIT'
                                                        value={this.state.element?.summary?.revenueExtra?.value}
                                                        disabled
                                                        mode='currency'
                                                        currency='EUR'
                                                        locale='pl-PL'
                                                    />
                                                    <InputTextComponent
                                                        colClass="col-md-4"
                                                        label={t('project.details.revenueExtraHours')}
                                                        viewMode='EDIT'
                                                        disabled
                                                        value={NumberUtils.formatTimeIfPossible(this.state.element?.summary?.hoursExtra)}
                                                        format='number'
                                                    />
                                                    <DivContainer colClass='col-md-2'>
                                                        <DivContainer colClass="row">
                                                            <label className="p-label" />
                                                            <DivContainer colClass="col-md-12 form-group" rendered={extraDetailsRendered}>
                                                                <ActionLink label={t('project.details.extraDetails')} className="p-label" handleClick={this.showExtraDetails.bind(this)} />
                                                            </DivContainer>
                                                        </DivContainer>

                                                    </DivContainer>
                                                </DivContainer>
                                            </DivContainer>
                                            <DivContainer colClass='col-md-2 p-label-bold'>
                                                {t('project.details.totalRevenue')}
                                            </DivContainer>
                                            <DivContainer colClass='col-md-10'>
                                                <DivContainer colClass="row">
                                                    <InputNumberComponent
                                                        colClass="col-md-4"
                                                        label=""
                                                        showLabel={true}
                                                        viewMode='EDIT'
                                                        value={this.state.element.summary?.revenueTotal?.value}
                                                        disabled
                                                        mode='currency'
                                                        currency='EUR'
                                                        locale='pl-PL'
                                                    />
                                                </DivContainer>
                                            </DivContainer>
                                        </DivContainer>
                                    </Card>
                                    ) : null}
                                    {roleAdmin || roleCto || roleFm || roleEm ? (
                                    <Card header={t('project.details.cost')} style={{ marginTop: "10px" }}>
                                        <DivContainer colClass='row'>
                                            <DivContainer colClass='col-md-2 p-label-bold'>
                                                {t('project.details.costEmployees')}
                                            </DivContainer>
                                            <DivContainer colClass='col-md-10'>
                                                <DivContainer colClass="row">
                                                    <InputNumberComponent
                                                        colClass="col-md-4"
                                                        label={t('project.details.costEmployeesPriceTotal')}
                                                        viewMode='EDIT'
                                                        value={this.state.element?.summary?.costEmployees?.value}
                                                        disabled
                                                        mode='currency'
                                                        currency='EUR'
                                                        locale='pl-PL'
                                                    />
                                                    <InputTextComponent
                                                        colClass="col-md-4"
                                                        label={t('project.details.costEmployeesHours')}
                                                        viewMode='EDIT'
                                                        disabled
                                                        value={NumberUtils.formatTimeIfPossible(this.state.element?.summary?.hoursEmployees)}
                                                        format='number'
                                                    />
                                                    <DivContainer colClass='col-md-2'>
                                                        <DivContainer colClass="row">
                                                            <label className="p-label" />
                                                            <DivContainer colClass="col-md-12 form-group" rendered={workCostRendered}>
                                                                <ActionLink label={t('project.details.workCostDetails')} className="p-label" handleClick={this.showWorkCosts.bind(this)} />
                                                            </DivContainer>
                                                        </DivContainer>

                                                    </DivContainer>
                                                </DivContainer>
                                                <DivContainer colClass="row">
                                                    <InputNumberComponent
                                                        colClass="col-md-4"
                                                        label={t('project.details.costDrivePriceTotal')}
                                                        viewMode='EDIT'
                                                        value={this.state.element?.summary?.costDrivers?.value}
                                                        disabled
                                                        mode='currency'
                                                        currency='EUR'
                                                        locale='pl-PL'
                                                    />
                                                    <InputTextComponent
                                                        colClass="col-md-4"
                                                        label={t('project.details.costDriveHours')}
                                                        viewMode='EDIT'
                                                        disabled
                                                        value={NumberUtils.formatTimeIfPossible(this.state.element?.summary?.hoursDrivers)}
                                                        format='number'
                                                    />
                                                    <DivContainer colClass='col-md-2'>
                                                        <DivContainer colClass="row">
                                                            <label className="p-label" />
                                                            <DivContainer colClass="col-md-12 form-group" rendered={driveCostRendered}>
                                                                <ActionLink label={t('project.details.driveCostDetails')} className="p-label" handleClick={this.showDriveCosts.bind(this)} />
                                                            </DivContainer>
                                                        </DivContainer>

                                                    </DivContainer>

                                                </DivContainer>
                                            </DivContainer>
                                            <DivContainer colClass='col-md-2 p-label-bold'>
                                                {t('project.details.expences')}
                                            </DivContainer>
                                            <DivContainer colClass='col-md-10'>
                                                <DivContainer colClass="row">
                                                    <InputNumberComponent
                                                        colClass="col-md-4"
                                                        label={t('project.details.expencesCost')}
                                                        name='value'
                                                        showLabel={true}
                                                        viewMode='EDIT'
                                                        value={this.state.element.summary?.costExpenses?.value}
                                                        disabled
                                                        mode='currency'
                                                        currency='EUR'
                                                        locale='pl-PL'
                                                    />
                                                </DivContainer>
                                            </DivContainer>
                                            <DivContainer colClass='col-md-2 p-label-bold'>
                                                {t('project.details.totalCost')}
                                            </DivContainer>
                                            <DivContainer colClass='col-md-10'>
                                                <DivContainer colClass="row">
                                                    <InputNumberComponent
                                                        colClass="col-md-4"
                                                        label=""
                                                        showLabel={true}
                                                        viewMode='EDIT'
                                                        value={this.state.element.summary?.costTotal?.value}
                                                        disabled
                                                        mode='currency'
                                                        currency='EUR'
                                                        locale='pl-PL'
                                                    />
                                                </DivContainer>
                                            </DivContainer>
                                        </DivContainer>

                                    </Card>
                                    ) : null}
                                    {roleAdmin || roleCto || roleFm || roleEm ? (          
                                    <Card header={t('project.details.income')} style={{ marginTop: "10px" }}>
                                        <DivContainer colClass="row">
                                            <DivContainer colClass='col-md-2 p-label-bold'>
                                                {t('project.details.totalIncome')}
                                            </DivContainer>
                                            <DivContainer colClass='col-md-10'>
                                                <DivContainer colClass="row">
                                                    <InputNumberComponent
                                                        colClass="col-md-4"
                                                        label=""
                                                        showLabel={true}
                                                        viewMode='EDIT'
                                                        value={this.state.element.summary?.income?.value}
                                                        disabled
                                                        mode='currency'
                                                        currency='EUR'
                                                        locale='pl-PL'
                                                    />
                                                </DivContainer>
                                            </DivContainer>
                                        </DivContainer>

                                    </Card>
                                    ) : null}
                                </Card>
                            </TabPanel>
                            

                            {(this.props.viewMode !== 'NEW') ? (
                                <TabPanel header={t('project.details.logsTabHeader')}>
                                    <LogEntryListContainer
                                        objectType='PROJECT'
                                        objectId={this.props.elementId}
                                        expandFilters={false}
                                        renderHeader={false}
                                    />
                                </TabPanel>
                            ) : null}
                        </TabView>
                    </DivContainer>
                    {this.props.viewMode !== 'NEW' ? (
                        <div>
                            <ExtraWorkProjectAggregationDialog
                                visible={this.state.extraWorkDialogVisible}
                                extras={this.state.extras}
                                onHide={() => { this.setState({ extraWorkDialogVisible: false }) }}
                            />
                            <ProjectWorkCostAggregationDialog
                                visible={this.state.workCostDialogVisible}
                                costs={this.state.workCosts}
                                onHide={() => { this.setState({ workCostDialogVisible: false }) }}
                            />
                            <ProjectDriveCostAggregationDialog
                                visible={this.state.driveCostDialogVisible}
                                costs={this.state.driveCosts}
                                onHide={() => { this.setState({ driveCostDialogVisible: false }) }}
                            />
                        </div>
                    ) : null}
                </DivContainer>
                {this.state.showConfirmCloseProject && this.state.element.id && this.renderClosePojectComponent()}

            </React.Fragment>
        )
    }
    renderClosePojectComponent() {
        return (
            <YesNoDialog
                visible={this.state.showConfirmCloseProject}
                header={t('project.closeConfirm')}
                name='visible'
                childrenHide
                onChange={(a, b, target) => {
                    if (target.value) {
                        this.blockUi();
                        this.service
                            .closeProject(this.state.element.id)
                            .then(() => {
                                window.location.href = this.props.backUrl;
                            })
                            .catch((error) => {
                                this.showErrorMessage(error.message);
                            })
                            .finally(() => {
                                this.unblockUi();
                                this.setState({
                                    showConfirmCloseProject: false,
                                });
                            });
                    } else {
                        this.setState({ showConfirmCloseProject: false });
                    }
                }}
                onHide={() => {
                    this.setState({ showConfirmCloseProject: false });
                }}
            ></YesNoDialog>
        );
    }

    showExtraDetails() {
        if (this.props.viewMode === 'NEW') {
            return;
        }
        this.service.getExtraWorkAggregation(this.state.element.id)
            .then(extras => this.setState({ extras, extraWorkDialogVisible: true }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać agregacji zatwierdzonych prac dodatkowych');
            });
    }

    showWorkCosts() {
        if (this.props.viewMode === 'NEW') {
            return;
        }
        this.service.getWorkCostAggregation(this.state.element.id)
            .then(workCosts => this.setState({ workCosts, workCostDialogVisible: true }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kosztów pracowników w podziale na stawki');
            });
    }

    showDriveCosts() {
        if (this.props.viewMode === 'NEW') {
            return;
        }
        this.service.getDriveCostAggregation(this.state.element.id)
            .then(driveCosts => this.setState({ driveCosts, driveCostDialogVisible: true }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kosztów kierowców w podziale na stawki');
            });
    }

    calculateTotalRevenue(data) {
        let total = 0;
        if (data?.summary?.revenueExtra?.value) {
            total += parseFloat(data?.summary?.revenueExtra?.value);
        }

        if (data?.summary?.revenueEmployees?.value) {
            total += parseFloat(data.summary.revenueEmployees.value);
        }
        return total;
    }

    calculateIncome() {
        let income = 0;
        if (this.state.element?.summary?.revenueTotal?.value) {
            income = parseFloat(this.state.element.summary.revenueTotal?.value);
        }
        if (this.state.element?.summary?.costTotal?.value) {
            income = income - parseFloat(this.state.element.summary.costTotal.value);
        }
        const summary = this.state.element.summary;
        summary.income.value = income;
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                summary,
            },
        }));
        return income;
    }

    calculateTotalCost(data) {
        let totalCost = 0;
        if (data.summary.employeesCost.totalPrice) {
            totalCost += parseFloat(data.summary.employeesCost.totalPrice);
        }
        if (data.summary.expensesTotal && data.summary.expensesTotal.value) {
            totalCost += parseFloat(data.summary.expensesTotal.value);
        }
        return totalCost;
    }

    calculateRevenue() {
        let unitPrice = this.state.element?.summary?.unitPrice?.value;
        let hours = this.state.element?.summary?.hoursEmployees;
        const priceType = this.state.element?.summary?.priceType;
        const { panelNumber, kwpNumber } = this.state.element;
        let revenue = null;
        if (unitPrice && priceType) {
            if (priceType?.enumValue === 'KWP' && kwpNumber) {
                revenue = kwpNumber.toString().replaceAll(',', '.').replaceAll(' ', '') * unitPrice.toString().replaceAll(',', '.');
            } else if (priceType?.enumValue === 'PANEL' && panelNumber) {
                revenue = panelNumber.toString().replaceAll(',', '.').replaceAll(' ', '') * unitPrice.toString().replaceAll(',', '.');
            } else if (priceType?.enumValue === 'FIXPRICE') {
                revenue = unitPrice.toString().replaceAll(',', '.');
            } else if (priceType?.enumValue === 'PER_HOUR') {
                revenue = NumberUtils.formatTimeAndMultiplyBy(hours, unitPrice.toString().replaceAll(',', '.').replaceAll(' ', ''));

            }
            revenue = (Math.round(revenue * 100) / 100).toFixed(2);
        }
        const summary = this.state.element.summary;
        summary.revenueEmployees.value = revenue;
        summary.revenueTotal.value = this.calculateTotalRevenue(this.state.element);
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                summary,
            },
        }),
            () => this.calculateIncome()
        );
    }

    //NARZĘDZIA
    projectToolsToolsTemplate(rowData, props) {
        const { rowIndex } = props;
        const ids = this.state.element.tools.map((x) => {
            if (x != null && x.tools != null) {
                return x.tools.id;
            }
        });
        const filteredList = this.state.tools.filter((x) => !ids.includes(x.id));
        return (
            <div style={{ minWidth: '300px' }}>
                <InputDropdownComponent
                    id={`tools${rowIndex}`}
                    name="tools"
                    value={rowData.tools}
                    stateField={`element.tools[${rowIndex}]`}
                    showLabel={false}
                    options={filteredList}
                    optionLabel='name'
                    onChange={this.handleChange}
                    insideTable
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    showClear
                    label={t('project.details.toolsPack')}
                    valueTemplate={this.toolsValueTemplate(rowData.tools)}
                    filter
                    filterBy={'name'}
                />
            </div>
        )
    }



    toolsValueTemplate(e) {
        if (e != null && e.name) {
            return <span style={{ color: 'black' }}>{e.name}</span>
        }
    }


    projectToolsOwnerTemplate(rowData, props) {
        const rowIndex = props.rowIndex;

        return (
            <div style={{ minWidth: '300px' }}>
                <InputAutocompleteComponent
                    id={`owner${rowIndex}`}
                    name="owner"
                    label={t('project.details.employee')}
                    value={rowData.owner}
                    dropdown
                    forceSelection
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators={`at_least_one_field_required:${rowIndex}`}
                    showLabel={false}
                    onChange={this.handleChange}
                    insideTable
                    filteredList={this.state.filteredEmployees}
                    filterList={query => this.searchEmployees(query)}
                    field="fullName"
                    minLength={3}
                    placeholder={t('search.users.placeholder')}
                    stateField={`element.tools[${rowIndex}]`}
                />
            </div>
        )
    }

    projectToolsCarTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        return (
            <div style={{ minWidth: '300px' }}>
                <InputAutocompleteComponent
                    id={`car${rowIndex}`}
                    name="car"
                    label={t('project.details.car')}
                    value={rowData?.car}
                    dropdown
                    forceSelection
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators={`at_least_one_field_required:${rowIndex}`}
                    showLabel={false}
                    onChange={this.handleChange}
                    insideTable
                    showClear
                    filteredList={this.state.filteredCars}
                    filterList={query => this.searchCars(query)}
                    field="licensePlate"
                    minLength={3}
                    placeholder={t('search.cars.placeholder')}
                    stateField={`element.tools[${rowIndex}]`}
                />
            </div>
        )
    }

    projectToolsActionsTemplate(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => {
                        let tools = this.state.element.tools.filter(t => t != rowData);
                        this.setState((prevState) => ({
                            element: {
                                ...prevState.element,
                                tools,
                            },
                        }));
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                />
            </span>
        );

    }

    handleAddNewTools() {
        const tools = this.state.element.tools;
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                tools: tools.concat({}),
            },
        }));
    }

    //NARZĘDZIA END

    //PRACOWNICY
    projectUserTemplate(rowData, props) {
        const { rowIndex } = props;
        const user = rowData;
        const categoriesEmpty = rowData?.stats?.categories === undefined || rowData?.stats?.categories === null || rowData?.stats?.categories.length === 0
        const style = categoriesEmpty ? {} : { marginTop: '15px' };
        return (
                <div>
                    <div style={style}>
                        <InputAutocompleteComponent
                            id={`employee${rowIndex}`}
                            name="employee"
                            colClass='col-md-7'
                            label={t('project.details.employee')}
                            value={user.employee}
                            dropdown
                            forceSelection
                            viewMode={this.props.viewMode}
                            validator={this.validator}
                            validators={`required|same_external_manager_for_user_and_project:${rowIndex},user|employee_local_assigment_duplicates:${rowIndex},user`}
                            showLabel={false}
                            onChange={this.handleChange}
                            insideTable={true}
                            filteredList={this.state.filteredEmployees}
                            filterList={query => this.searchEmployees(query, user?.employee?.id, 'users')}
                            field="fullName"
                            stateField={`element.users[${rowIndex}]`}
                            minLength={3}
                            placeholder={t('search.users.placeholder')}
                            onAfterStateChange={() => this.fetchUserStatisticAndHandleError(user, true)}
                        />
                    </div>
                    {this.inputColorComponent(rowData?.stats?.categories)}
                </div>
        )
    }



    projectUserStartTemplate(rowData, props) {
        const { rowIndex } = props;
        const user = rowData;
        const { dateStart, dateEnd } = this.state.element;

        //min, max nie działa; gdy najpierw ustawiamy datę od na przyszły miesiąc, to nie można potem ustawić daty do (kontrolki są disabled); trzeba wtedy najpierw miesiąc zmienić i wrócić spowtotem
        //let minDate = dateStart;
        //let maxDate = dateEnd;
        //if (user.dateEnd && dateEnd && user.dateEnd.getTime() < dateEnd.getTime()) {
        //    maxDate = user.dateEnd;
        //} else if (user.dateEnd && (!minDate || minDate.getTime() > user.dateEnd.getTime())) {
        //    maxDate = user.dateEnd;
        //}

        return (
            <InputCalendarComponent
                id={`employeeDateStart${rowIndex}`}
                colClass='col-md-2'
                name="dateStart"
                label='Od'
                value={user.dateStart}
                onChange={this.handleChange}
                viewMode={this.props.viewMode}
                validator={this.validator}
                validators={`required|employee_assigment_date_range:${rowIndex},user|user_dates_in_project_range:${rowIndex},user,start`}
                stateField={`element.users[${rowIndex}]`}
                insideTable
                showLabel={false}
                showIcon
                //minDate={minDate}
                //maxDate={maxDate}
                onAfterStateChange={() => this.fetchUserStatisticAndHandleError(user, true)}
            />
        )
    }

    projectUserEndTemplate(rowData, props) {
        const { rowIndex } = props;
        const user = rowData;
        const { dateStart, dateEnd } = this.state.element;

        //min, max nie działa; gdy najpierw ustawiamy datę od na przyszły miesiąc, to nie można potem ustawić daty do (kontrolki są disabled); trzeba wtedy najpierw miesiąc zmienić i wrócić spowtotem
        // let minDate = dateStart;
        // let maxDate = dateEnd;
        // if (user.dateStart && dateStart && user.dateStart.getTime() > dateStart.getTime()) {
        //     minDate = user.dateStart;
        // } else if (user.dateStart && (!maxDate || maxDate.getTime() < user.dateStart.getTime())) {
        //     minDate = user.dateStart;
        // }
        return (
            <InputCalendarComponent
                id={`employeeDateEnd${rowIndex}`}
                colClass='col-md-2'
                name="dateEnd"
                label='Do'
                value={user.dateEnd}
                onChange={this.handleChange}
                viewMode={this.props.viewMode}
                validator={this.validator}
                validators={`required|employee_assigment_date_range:${rowIndex},user|user_dates_in_project_range:${rowIndex},user,end`}
                stateField={`element.users[${rowIndex}]`}
                insideTable
                showLabel={false}
                showIcon
                // minDate={minDate}
                // maxDate={maxDate}
                onAfterStateChange={() => this.fetchUserStatisticAndHandleError(user, true)}
            />
        )
    }

    handleGoBack(e) {
        e.preventDefault();
        let backLinkFromCookie = this.readCookie('projectDashboardBackPath');
        this.blockUi();
        const {backUrl} = this.props;
        window.location.href = (backLinkFromCookie === null || backLinkFromCookie === undefined || backLinkFromCookie === '') ? backUrl : backLinkFromCookie;
    }
 
    afterUpdateSuccess(response) {
        const {backUrl} = this.props;
        let backLinkFromCookie = this.readCookie('projectDashboardBackPath');
        this.blockUi();
        this.persistMessage('success', '', this.getUpdateSucces(response));
        window.location.href = (backLinkFromCookie === null || backLinkFromCookie === undefined || backLinkFromCookie === '') ? backUrl : backLinkFromCookie;
    }

    projectUserStatisticTemplate(rowData, props) {
        const { rowIndex } = props;
        const user = rowData;
        const loading = user ? user.loadingStats : false;
        const render = user ? user.renderStats : false;
        return (
                <EmployeeProjectPlaningStatistic
                    loading={loading}
                    render={render}
                    stats={user?.stats}
                    user={user?.employee}
                    showDetails={() => {
                        this.fetchUserStatistic(user, true)
                            .then(() => {
                                this.setState({
                                    visibleEmployeeStatistic: true,
                                    selectedEmployee: user?.employee,
                                    selectedEmployeeStats: user?.stats,
                                    selectedEmployeeDateStart: user?.dateStart,
                                    selectedEmployeeDateEnd: user?.dateEnd
                                })
                            })
                            .catch(err => this.showErrorMessage(err))
                    }
                    }
                />
        )
    }



    projectUserActionsTemplate(rowData, props) {
        const { rowIndex } = props;
        const user = rowData;
        return (
            <div className="col-md-1">
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    rendered={this.props.viewMode != 'VIEW'}
                    handleClick={(e) => {
                        let users = this.state.element.users.filter(u => u != user);
                        this.setState((prevState) => ({
                            element: {
                                ...prevState.element,
                                users,
                            },
                        }));
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                />
            </div>
        )
    }

    handleAddNewProjectUser() {
        const { users, dateStart, dateEnd } = this.state.element;
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                users: users.concat({ dateStart, dateEnd }),
            },
        }));
    }

    handleAddNewProjectManager() {
        const { managers, dateStart, dateEnd } = this.state.element;
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                managers: managers.concat({dateStart, dateEnd}),
            },
        }));
    }


    projectManagerTemplate(rowData, props) {
        const { rowIndex } = props;
        const manager = rowData;
        const categoriesEmpty = rowData?.stats?.categories === undefined || rowData?.stats?.categories === null || rowData?.stats?.categories.length === 0
        const style = categoriesEmpty ? {} : { marginTop: '15px' };
        return (
            <div>  
                    <div style={style}>
                        <InputAutocompleteComponent
                            id={`manager${rowIndex}`}
                            name="employee"
                            label={t('project.details.leader')}
                            value={manager.employee}
                            dropdown
                            forceSelection
                            viewMode={this.props.viewMode}
                            validator={this.validator}
                            validators={`required|same_external_manager_for_user_and_project:${rowIndex},manager|employee_local_assigment_duplicates:${rowIndex},manager`}
                            showLabel={false}
                            onChange={this.handleChange}
                            insideTable={true}
                            filteredList={this.state.filteredEmployees}
                            filterList={query => this.searchEmployees(query, manager?.employee?.id, 'managers')}
                            field="fullName"
                            stateField={`element.managers[${rowIndex}]`}
                            minLength={3}
                            placeholder={t('search.managers.placeholder')}
                            onAfterStateChange={() => this.fetchUserStatisticAndHandleError(manager, false)}
                        />
                    </div>
                {rowData?.stats && this.inputColorComponent(rowData?.stats.categories)  }
            </div>
        )
    }
   
    projectManagerStartTemplate(rowData, props) {
        const { rowIndex } = props;
        const manager = rowData;
        const { dateStart, dateEnd } = this.state.element;

        //min, max nie działa; gdy najpierw ustawiamy datę od na przyszły miesiąc, to nie można potem ustawić daty do (kontrolki są disabled); trzeba wtedy najpierw miesiąc zmienić i wrócić spowtotem
        //let minDate = dateStart;
        //let maxDate = dateEnd;
        //if (manager.dateEnd && dateEnd && manager.dateEnd.getTime() < dateEnd.getTime()) {
        //   maxDate = manager.dateEnd;
        //}
        return (
            <InputCalendarComponent
                id={`managerDateStart${rowIndex}`}
                colClass='col-md-2'
                name="dateStart"
                label='Od'
                value={manager.dateStart}
                onChange={this.handleChange}
                viewMode={this.props.viewMode}
                validator={this.validator}
                validators={`required|employee_assigment_date_range:${rowIndex},manager|user_dates_in_project_range:${rowIndex},manager,start`}
                stateField={`element.managers[${rowIndex}]`}
                insideTable
                showLabel={false}
                showIcon
                //minDate={minDate}
                //maxDate={maxDate}
                onAfterStateChange={() => this.fetchUserStatisticAndHandleError(manager, false)}
            />
        )
    }

    projectManagerEndTemplate(rowData, props) {
        const { rowIndex } = props;
        const manager = rowData;
        const { dateStart, dateEnd } = this.state.element;
        //min, max nie działa; gdy najpierw ustawiamy datę od na przyszły miesiąc, to nie można potem ustawić daty do (kontrolki są disabled); trzeba wtedy najpierw miesiąc zmienić i wrócić spowtotem
        // let minDate = dateStart;
        // let maxDate = dateEnd;
        // if (manager.dateStart && dateStart && manager.dateStart.getTime() > dateStart.getTime()) {
        //     minDate = manager.dateStart;
        // }
        return (
            <InputCalendarComponent
                id={`managerDateEnd${rowIndex}`}
                colClass='col-md-2'
                name="dateEnd"
                label='Do'
                value={manager.dateEnd}
                onChange={this.handleChange}
                viewMode={this.props.viewMode}
                validator={this.validator}
                validators={`required|employee_assigment_date_range:${rowIndex},manager|user_dates_in_project_range:${rowIndex},manager,end`}
                stateField={`element.managers[${rowIndex}]`}
                insideTable
                showLabel={false}
                showIcon
                //minDate={minDate}
                //maxDate={maxDate}
                onAfterStateChange={() => this.fetchUserStatisticAndHandleError(manager, false)}

            />
        )
    }

    projectManagerTypeTemplate(rowData, props) {
        const { rowIndex } = props;
        const manager = rowData;
        return (
            <InputDropdownComponent
                id={`managerRoleType${rowIndex}`}
                name="roleType"
                label={t('project.details.leaderRole')}
                value={manager.roleType}
                stateField={`element.managers[${rowIndex}]`}
                showLabel={false}
                options={this.state.leaderRoles}
                optionLabel='label'
                onChange={this.handleChange}
                insideTable
                viewMode={this.props.viewMode}
                validator={this.validator}
                validators='required'
                showClear
            />
        )
    }

    projectManagerStatisticTemplate(rowData, props) {
        const { rowIndex } = props;
        const manager = rowData;


        const loading = manager ? manager.loadingStats : false;
        const render = manager ? manager.renderStats : false;
        return (
            <EmployeeProjectPlaningStatistic
                loading={loading}
                render={render}
                stats={manager?.stats}
                user={manager?.employee}
                showDetails={() => {
                    this.fetchUserStatistic(manager, false)
                    .then(() => {
                        this.setState({
                            visibleEmployeeStatistic: true,
                            selectedEmployee: manager?.employee,
                            selectedEmployeeStats: manager?.stats,
                            selectedEmployeeDateStart: manager?.dateStart,
                            selectedEmployeeDateEnd: manager?.dateEnd
                        })
                    })
                    .catch(err => this.showErrorMessage(err))
                }
            }
            />
        );
    }

    projectManagerActionsTemplate(rowData, props) {
        const { rowIndex } = props;
        const manager = rowData;
        return (
            <div className="col-md-1">
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => {
                        let managers = this.state.element.managers.filter(m => m != manager);
                        this.setState((prevState) => ({
                            element: {
                                ...prevState.element,
                                managers,
                            },
                        }));
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                    rendered={this.props.viewMode != 'VIEW'}
                />
            </div>
        )
    }


    //PRACOWNICY END


    // AKTYWNOSCI
    handleAddNewProjectActivity() {
        const activities = this.state.element.activities;
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                activities: activities.concat({}),
            },
        }));
    }

    projectActivityActionsTemplate(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => {
                        let activities = this.state.element.activities.filter(pa => pa != rowData);
                        this.setState((prevState) => ({
                            element: {
                                ...prevState.element,
                                activities,
                            },
                        }));
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                />
            </span>
        );

    }

    projectActivityPriceTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        return (
            <div style={{ minWidth: '150px' }}>
                <InputNumberComponent
                    id={`activityPrice${rowIndex}`}
                    name="price"
                    label={t('project.details.activityPricePerH')}
                    value={rowData.price}
                    stateField={`element.activities[${rowIndex}]`}
                    showLabel={false}
                    onChange={this.handleChange}
                    insideTable
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    mode="currency"
                    currency="EUR"
                    locale="pl-PL"
                />

            </div>

        );
    }

    selectedItemTemplate = (option) => {
        if (option != null) {
            return (
                <div className='p-multiselect-token' style={{ background: option.color.value }}>
                    <div className="p-multiselect-token-label" >
                        {option.name}
                    </div>
                    <div className="p-multiselect-token-icon pi pi-times-circle" onClick={(el) => {
                        const categories = this.state.element.categories.filter(c => c.id !== option.id);
                        this.setState((prevState) => ({
                            element: {
                                ...prevState.element,
                                categories: categories
                            }
                        }));
                    }
                    }>
                    </div>
                </div>
            );
        }
        return;
    };
    projectActivityActivityTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        return (
            <div style={{ minWidth: '250px' }}>
                <InputDropdownComponent
                    id={`activity${rowIndex}`}
                    name="activity"
                    label={t('project.details.activityName')}
                    value={rowData.activity}
                    dataKey='id'
                    stateField={`element.activities[${rowIndex}]`}
                    showLabel={false}
                    options={this.state.activities}
                    optionLabel='name'
                    onChange={this.handleChange}
                    insideTable
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    showClear
                />
            </div>
        )
    }

    inputColorComponent(categories) {
        return (
            (categories && categories.length >= 1)
                ? (
                    <div className="row" style={{ marginTop: "7px", marginBottom: "-12px" }}>
                        {categories.map(category => {
                            return <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={category.icon.value} color={category.color.value} tootltipText={category.name} />
                        })}
                    </div>
                ) : null)
    }


    // AKTYWNOSCI END
}


ProjectDetailsContainer.defaultProps = {
    backUrl: '/#/project-list',
    backDashboardUrl: '/#/project-dashboard',
    cancelUrl: '/#/project/details',
    editUrl: '/#/project/edit',
};

ProjectDetailsContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withRouter(withTranslation()(ProjectDetailsContainer));

