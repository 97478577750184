import BaseServiceCrud from './BaseServiceCrud';

export default class ProjectDashboardService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'project-dashboard';
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,
            clientName: criteria.clientName,
            allProjects : criteria.projectsByDate.enumValue === 'ALL' ? true : false,
            status: criteria.status?.enumValue,
            countryName: criteria.countryName,
            supervisor: criteria.supervisor,
            categories: criteria.categories ? criteria.categories.map((category) => category.id).join(',') : null,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
    getCriteriaPath(criteria) {
        return this.objToQueryStringPath({
            name: criteria.name,
            clientName: criteria.clientName,
            allProjects : criteria.projectsByDate.enumValue === 'ALL' ? true : false,
            status: criteria.status?.enumValue,
            countryName: criteria.countryName,
            supervisor: criteria.supervisor,
            categories: criteria.categories ? criteria.categories.map((category) => category.id).join(',') : null,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getProjectDashboard(criteria) {
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/dashboard${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    
    getProjectDashboardWithPathCriteria(criteriaString) {
        const criteriaStringFromPath =  "?"+ criteriaString;
        return this.fetch(`${this.domain}/${this.path}/dashboard${criteriaStringFromPath}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    
    getProjects() {
        return this.fetch(`${this.domain}/${this.path}/list`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getProjectUsers() {
        return this.fetch(`${this.domain}/${this.path}/users`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getProjectManagers() {
        return this.fetch(`${this.domain}/${this.path}/managers`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getProjectHolidays() {
        return this.fetch(`${this.domain}/${this.path}/holidays`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getProjectPlannedHolidays() {
        return this.fetch(`${this.domain}/${this.path}/holidays-planned`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getProjectUsersJoin() {
        return this.fetch(`${this.domain}/${this.path}/users-join`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getProjectUsersLeave() {
        return this.fetch(`${this.domain}/${this.path}/users-leave`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }    
    getCategoriesIcons () {
        return this.fetch(`${this.domain}/${this.path}/icons`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }  
    getProjectCars() {
        return this.fetch(`${this.domain}/${this.path}/project-cars`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }  

    setCriteria(criteriaName, criteriaValue) {
        
        // Saves criteria to localStorage
        localStorage.setItem(criteriaName, criteriaValue);
        
    }
    readCriteria(criteriaName) {
        
        // Read criteria from localStorage
      return  localStorage.getItem(criteriaName);
        
    }

    getAccommodationsByProjectId() {
        return this.fetch(`${this.domain}/${this.path}/accommodation`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

}
