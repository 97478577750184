import BaseServiceCrud from './BaseServiceCrud';

export default class AccommodationDashboardService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'accommodation';
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,
            categories: criteria.categories ? criteria.categories.map((category) => category.id).join(',') : null,
            countries: criteria.countries ? criteria.countries.map((country) => country.id).join(',') : null,
            project: criteria.project ? criteria.project?.id : null,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            employeesAssigned: criteria.employeesAssigned
        });
    }

    getAccommodationDashboard(criteria) {
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/dashboard${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getCriteriaPath(criteria) {
        return this.objToQueryStringPath({
            name: criteria.name,
            categories: criteria.categories ? criteria.categories.map((category) => category.id).join(',') : null,
            countries: criteria.countries ? criteria.countries.map((country) => country.id).join(',') : null,
            project: criteria.project ? criteria.project?.id : null,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            employeesAssigned: criteria.employeesAssigned
        });
    }

    getDashboardWithPathCriteria(criteriaString) {
        const criteriaStringFromPath =  "?"+ criteriaString;
        return this.fetch(`${this.domain}/${this.path}/dashboard${criteriaStringFromPath}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    readCriteria(criteriaName) {
        // Read criteria from localStorage
      return  localStorage.getItem(criteriaName);
    }
    getSoonLeavingEmployees(){
        return this.fetch(`${this.domain}/${this.path}/soonLeaving`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getSoonIncomingEmployees(){
        return this.fetch(`${this.domain}/${this.path}/soonIncoming`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }


    setCriteria(criteriaName, criteriaValue) {
        localStorage.setItem(criteriaName, criteriaValue);
    }
}
