import { t } from "i18next";
import PropTypes from 'prop-types';
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { DataSet } from "vis-data"; ///peer/esm/vis-data";
import { Timeline } from "vis-timeline";
import BaseDetailsContainer from "../baseContainers/BaseDetailsContainer";
import DivContainer from "../components/DivContainer";
import ProjectService from "../services/ProjectService";

import ReactDOM from 'react-dom';

import moment from 'moment';
import 'moment/min/locales';
import { Tooltip } from "primereact/tooltip";
import ActionLink from "../components/ActionLink";
import YesNoDialog from "../components/YesNoDialog";
import InputDropdownComponent from "../components/inputs/InputDropdownComponent";
import Constants from "../utils/constants";
import SelectProjectDialog from "./SelectProjectDialog";

import $ from 'jquery';
import {Accordion, AccordionTab} from 'primereact/accordion';
import CriteriaTextComponent from "../components/criteria/CriteriaTextComponent";
import CriteriaCalendarRangeComponent from "../components/criteria/CriteriaCalendarRangeComponent";
import CriteriaMultiSelectComponent from "../components/criteria/CriteriaMultiSelectComponent";
import CriteriaDropdownComponent from "../components/criteria/CriteriaDropdownComponent";
import ProjectCategoryService from "../services/ProjectCategoryService";
import CountryService from "../services/CountryService";


class ManagerTimelineContainer extends BaseDetailsContainer {

    constructor(props) {
        super(props, new ProjectService());        
        this.state = {
            element: {
                selectProjectDialogVisible: false,
                confirmDeleteDialogVisible: false,
                countries: [],
            },
            config: {
                start: moment().subtract(1, 'days').startOf('day').toDate(),
                end: moment().startOf('day').add(6, 'days').toDate(),            
                min: moment().subtract(360, 'day').startOf('day').toDate(),
                max: moment().add(360, 'day').startOf('day').toDate(),
            },
            criteria: {
                fullName: null,
                firstResult: 0,
                maxResults: 10,
                sortField: 'month_',
                sortAsc: false,
                exportType: null,
                project: '',
                categories: [],
                isManager: true,
                countries: [],
            },
        };        
        this.handleAssignNewProject = this.handleAssignNewProject.bind(this);
        this.handleUnassignProject = this.handleUnassignProject.bind(this);
        this.unassignUser = this.unassignUser.bind(this);
        this.refreshTimeLine=this.refreshTimeLine.bind(this);
       /*  this.handleIsManager=this.handleIsManager.bind(this); */
        this.users = new DataSet();
        this.projects = new DataSet();
        this.projectCatService = new ProjectCategoryService();
        this.countryService = new CountryService();
    }

    initAfterSetElement() {
    }

    initBeforeSetElement() {
      

        this.blockUi();
        this.projectCatService.getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
        .then(categories => this.setState({ categories: categories.content }))
        .catch(() => {
            this.showErrorMessage('Nie udało się pobrać listy kategorii');
        });
        this.countryService.getAll()
        .then(countries=>{
            this.setState({countries: countries})
        })
        .catch(()=>{
            this.showErrorMessage('Nie udało się pobrać listy krajów');
        })
        this.service.getUsersTimeline(true)
            .then(data => {
                this.convertToVisFormat(data);
                if(data.items?.length === 0){
                    this.showInfoMessage('Brak rekordów wyszukiwania');
                }
                //this.unblockUi();
            })
            .catch(() => {
                this.unblockUi();
                this.showErrorMessage('Nie udało się pobrać danych użytkowników');
            });
    }

    setTimeout(callback, delay) {
        if (callback && callback instanceof Function) {
            this.timeoutHandler = setTimeout(() => {
                callback();
            }, delay);
        } else {
            this.refreshTimeLine();
        }
    }

    convertToVisFormatAfterRefresh(data){
        this.users.clear();
        this.projects.clear();


        // const start = moment(data.config.start).startOf('day').toDate();
        // const config = {
        //     start: start,
        //     period: data.config.period,
        //     min: moment(data.config.min).startOf('day').toDate(),
        //     max: moment(data.config.max).endOf('day').toDate(),
        // };
        // this.setState({config});

        
        data?.items.forEach(item => {
            const user = {
                id: item.user.id,
                firstName: item.user.firstName,
                lastName: item.user.lastName
            };
            
            this.users.add(user);
            item?.projects?.map(p => {                
                return this.convertProjectToVisFormat(
                    p.projectId,
                    p.name,
                    p.color,
                    p.address,
                    p?.client?.id,
                    p?.client?.name,                    
                    p.assigmentId,
                    user.id,
                    moment(p.dateStart).startOf('day').toDate(),
                    moment(p.dateEnd).endOf('day').toDate()
                );
                
            }).forEach(p => {
                //p.absence = true;
                this.projects.add(p);
            });

            item?.absences?.map(a => {
                let tooltip = `<p>Nieobecność</p><p>Typ: ${a.name}</p>`;
                tooltip += `<p>${a.dateStart} - ${a.dateEnd}</p>`;
                tooltip += `<p>Powrót ${a.confirmed ? 'potwierdzony' : 'niepotwierdzony'}</p>`;

                return {
                    id: crypto.randomUUID(),
                    absenceId: a.absenceId,
                    start: moment(a.dateStart).startOf('day').toDate(),
                    end: moment(a.dateEnd).endOf('day').toDate(),
                    group: user.id,
                    absence: true,
                    editable: false,
                    name: a.name,
                    confirmed: a.confirmed,
                    className: 'absence',
                    title: tooltip,
                }
            }).forEach(a => {
                this.projects.add(a);
            })
        })
    }
    
    refreshTimeLine(){
        this.blockUi();

        const {criteria} = this.state;
        const {isManager, project, fullName, categories, countries} = criteria;        

        this.service.getUsersTimeline(isManager,project,fullName, categories.map((category) => category.id).join(','), countries.map(c => c.id).join(','))
            .then(data => {
                this.convertToVisFormatAfterRefresh(data);
                if(data.items?.length === 0){
                    this.showInfoMessage('Brak rekordów wyszukiwania');
                }
                this.unblockUi();
            })
            .catch(() => {
                this.unblockUi();
                this.showErrorMessage('Nie udało się pobrać danych użytkowników');
            });
    }
    handleValidForm() {
        //this.createOrUpdate();
        this.refreshTimeLine()
    }

    onKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleFormSubmit();
        }
    }

    renderCriteria() {
        return (
            <div className='row'>
                 <CriteriaTextComponent
                    id='project'
                    name='project'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.project}
                    onChange={this.handleChangeSc}
                    //validator={this.validator}
                    label={t('projectSettleReport.projectName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                />

                <CriteriaMultiSelectComponent
                    id='categories'
                    name='categories'
                    label={t('project.details.categories')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.categories}
                    optionLabel='name'
                    value={this.state.criteria.categories}
                    dataKey='id'
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                    />

              <CriteriaTextComponent
                    id='fullName-sc'
                    name='fullName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.fullName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('timeline.fullName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                />
                <CriteriaMultiSelectComponent
                    id='country-sc'
                    name='countries'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.countries}
                    options={this.state.countries}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('timeline.country')}
                    global={true}
                    optionLabel='name'
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                    filter 
                    filterBy="name"
                    placeholder="Wybierz kraj" 
                /> 
                {/*  <CriteriaDropdownComponent
                    id='isManager-sc'
                    name='isManager'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.isManager}
                    options={this.state.isManagerOptions}
                    onChange={this.handleIsManager}
                    validator={this.validator}
                    label={t('timeline.isManager')} 
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                    optionLabel='label'
                    optionValue='value'                    
                /> */}

            </div>
        );
    }

 /*    handleIsManager(inputType, parameters, event, matchMode, refreshFromBackend, onAfterStateChange, timeoutHandler, delay) {
        this.setState((prevState) => ({
            criteria: {
                ...prevState.criteria,
                isManager: event.value,
            },
        }
        ), () => {
            this.refreshTimeLine();
        });
    } */

    prepareFooterItems() {
        const result = super.prepareFooterItems();        
        return result;
    }


    isPdfButtonRendered() {
        return false;
    }

    getPdfDownloadLabel() {
        return null;
    }

    handleDownloadPdf() {
    }

    

    handleAssignNewProject(userId, userName) {
        this.setState({
            selectProjectDialogVisible: true,
            selectedUserId: userId,
            selectedUserName: userName        
        });
    }

    handleUnassignProject(user, project, callback) {
        //userId, userName, projectId, dateStart, dateEnd
        this.confirmDeleteCallback = callback;
        this.setState({
            confirmDeleteDialogVisible: true,
            confirmDeleteUser: user,
            confirmDeleteProject: project,
        });
    }

    convertProjectToVisFormat(projectId, projectName, projectColor, projectAddress, clientId, clientName, assigmentId, userId, dateStart, dateEnd) {
        const today = moment().startOf('day');

        let style = `background-color: ${projectColor};`;
        if (projectColor === '#000000') {
            style += 'color: #FFFFFF;';
        }

        const result = {
            id: crypto.randomUUID(),
            projectId: projectId,
            assigmentId: assigmentId,
            start: moment(dateStart).startOf('day').toDate(),
            end: moment(dateEnd).endOf('day').toDate(),
            group: userId,
            projectName: projectName,
            projectColor: projectColor,
            absence: false,
            editable: {
                add: false,
                remove: moment(dateEnd).isAfter(today),
                updateGroup: false,
                updateTime: false,
            },                    
            clientId: clientId,
            clientName: clientName,
            projectAddress: projectAddress,
            style: style,
        };
        TooltipHelper.generateProjectTooltip(result);
        return result;

    }

    convertToVisFormat(data) {
        this.users.clear();
        this.projects.clear();


        const start = moment(data.config.start).startOf('day').toDate();
        const config = {
            start: start,
            period: data.config.period,
            min: moment(data.config.min).startOf('day').toDate(),
            max: moment(data.config.max).endOf('day').toDate(),
        };
        this.setState({config});

        
        data?.items.forEach(item => {
            const user = {
                id: item.user.id,
                firstName: item.user.firstName,
                lastName: item.user.lastName
            };
            
            this.users.add(user);
            item?.projects?.map(p => {                
                return this.convertProjectToVisFormat(
                    p.projectId,
                    p.name,
                    p.color,
                    p.address,
                    p?.client?.id,
                    p?.client?.name,
                    p.assigmentId,
                    user.id,
                    moment(p.dateStart).startOf('day').toDate(),
                    moment(p.dateEnd).endOf('day').toDate()
                );
                
            }).forEach(p => {
                this.projects.add(p);
            });

            item?.absences?.map(a => {
                let tooltip = `<p>Nieobecność</p><p>Typ: ${a.name}</p>`;
                tooltip += `<p>${a.dateStart} - ${a.dateEnd}</p>`;
                tooltip += `<p>Powrót ${a.confirmed ? 'potwierdzony' : 'niepotwierdzony'}</p>`;

                return {
                    id: crypto.randomUUID(),
                    absenceId: a.absenceId,
                    start: moment(a.dateStart).startOf('day').toDate(),
                    end: moment(a.dateEnd).endOf('day').toDate(),
                    group: user.id,
                    absence: true,
                    editable: false,
                    name: a.name,
                    confirmed: a.confirmed,
                    className: 'absence',
                    title: tooltip,
                }
            }).forEach(a => {
                this.projects.add(a);
            })
        })
    }

    unassignUser() {
        const employeeId = this.state?.confirmDeleteUser?.id;
        const projectId = this.state?.confirmDeleteProject?.projectId;
        const itemId = this.state?.confirmDeleteProject?.id;
        const assigmentId = this.state?.confirmDeleteProject?.assigmentId;
        this.service.unassignManagerFromProject(employeeId, projectId, assigmentId)
            .then(resp => {
                this.confirmDeleteCallback(
                    itemId,
                    resp.removed, 
                    resp.removed ? null : new Date(resp.dateStart),
                    resp.removed ? null : new Date(resp.dateEnd)
                );
            })
            .catch(err => {
                this.showErrorMessage(err.message);
            })        
    }
    


    renderDetails() {
        const itemId = this.state?.confirmDeleteProject?.id;
        const userName = this.state?.confirmDeleteUser?.lastName + ' ' + this.state?.confirmDeleteUser?.firstName;

        
        const tomorrow = moment().startOf('day').add(1, 'day');
        let start = moment(this.state?.confirmDeleteProject?.start);
        const end = moment(this.state?.confirmDeleteProject?.end);
        if (tomorrow.isSameOrAfter(start)) {
            start = tomorrow;
        }
        const period = start.format(Constants.DATE_FORMAT) + ' - ' + end.format(Constants.DATE_FORMAT);
        const projectName = this.state?.confirmDeleteProject?.projectName;
        return (
            <React.Fragment>
                <SelectProjectDialog 
                    id="selectProjectDialog"
                    key="selectProjectDialogKey"
                    visible={this.state.selectProjectDialogVisible}
                    onHide={() => this.setState({selectProjectDialogVisible: false})}
                    onSelect={(project, dateStart, dateEnd) => {
                        this.service.assignManagerFromProject(this.state?.selectedUserId, project.id, dateStart, dateEnd)
                        .then(assigment => {
                            const item = this.convertProjectToVisFormat(project.id, project.name, project.color, project.address, 
                                project?.client?.id, project?.client?.name, assigment.projectManagerId, assigment?.employeeId, 
                                moment(assigment.dateStart).toDate(), moment(assigment.dateEnd).toDate());
                            this.projects.add(item);
                        })
                        .catch(err => {
                            this.showErrorMessage(err?.message);
                        })
                    }}
                    userId={this.state?.selectedUserId}
                    userName={this.state?.selectedUserName}
                    minDate={tomorrow.toDate()}
                />                    
                <YesNoDialog
                    visible={this.state.confirmDeleteDialogVisible}
                    header='Potwierdzenie'
                    name='confirmDelete'
                    noButtonVariant='white'
                    onChange={(type, x, target) => {
                        if (target.value) {
                            this.unassignUser();
                        }                        
                        this.setState({
                            confirmDeleteUser: null,
                            confirmDeleteProject: null,
                            confirmDeleteDialogVisible: false,
                        })
                        //this.confirmDeleteCallback = null;
                    }}
                    onHide={() => this.setState({confirmDeleteDialogVisible: false})}
                >
                    {`Czy na pewno chcesz usunąć użytkownika ${userName} z projektu ${projectName}, okres: ${period} ?`}
                </YesNoDialog>
                <DivContainer colClass='row'>
                <Accordion className='filter-accordion' activeIndex={-1}>
                <AccordionTab
                    header={
                        <React.Fragment>
                            <img className='card-icon' alt={t('list.filter')} src={`/images/ico_filter.svg`} />
                            <span>{t('list.filter')}</span>
                        </React.Fragment>
                    }
                >
                    <form
                        className='form-search-criteria'
                        onSubmit={(e) => {
                            this.handleFormSubmit(e);
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        //avoid setting red border just after the page has loaded (red border because of undefined required value in FF)
                        noValidate
                    >
                        <DivContainer colClass='col-12'>
                            {this.renderCriteria()}

                        </DivContainer>
                    </form>
                </AccordionTab>
            </Accordion>
                </DivContainer>
                <DivContainer colClass='row'>
                <UserTimeline
                        users={this.users}
                        projects={this.projects}
                        config={this.state.config}
                        onAssignNewProject={this.handleAssignNewProject}
                        onUnassignProject={this.handleUnassignProject}
                        onReady={this.unblockUi}
                    />      
                </DivContainer>                    
            </React.Fragment>
        )
    }

};

function GroupTemplate(props){
    const group = props?.group;
    return (
        <div style={{marginLeft: '20px', marginRight: '20px'}}>
            <div>            
                <label>{group.lastName + ' ' + group.firstName}</label>
            </div>
            <div>            
                <ActionLink
                    label=''
                    iconName='mdi-plus'
                    iconSide='left'
                    iconSize='icon-large'
                    variant='blue'
                    className='float-right'
                    key={'add-project-accigment'}
                    handleClick={props.handleClick}

                />
            </div>            
        </div>
    )    
}

class ItemTemplate extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div>
                {this.props?.item?.absence ? (
                    <i className="icon mdi mdi-beach p-overlay-badge" style={{ fontSize: '20px', marginRight: '14px', color: '#6c757d'}} />                    
                ) : null}
                
                <label style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '12px',
                        lineHeight: '16px',
                        //color: 'black',
                        letterSpacing: '-0.01em',
                }}>
                    {this.props.item.projectName}
                </label>
            </div>
        )
    }
}

class VisibleFramTemplate extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        return <div>
            id: {this.props.item.id}
            <button></button>
        </div>
    }
}

class UserTimeline extends React.Component {

    constructor(props) {
        super(props);
        this.periods = [
            {code: 'WEEK', label: 'Tydzień'},
            {code: 'TWO_WEEKS', label: 'Dwa tygodnie'},
            {code: 'MONTH', label: 'Miesiąc'},
            {code: 'THREE_MONTHS', label: 'Kwartał'},
            {code: 'YEAR', label: 'Rok'}
        ];

        this.selectedPeriod = this.periods[0];

        this.addSelectedPeriod = (date) => {
            if (this.selectedPeriod) {
                if (this.selectedPeriod.code === 'WEEK') {
                    return moment(date).add(1, 'week').toDate();
                } else if (this.selectedPeriod.code === 'TWO_WEEKS') {
                    return moment(date).add(2, 'week').toDate();
                } else if (this.selectedPeriod.code === 'MONTH') {
                    return moment(date).add(1, 'month').toDate();
                } else if (this.selectedPeriod.code === 'THREE_MONTHS') {
                    return moment(date).add(3, 'month').toDate();
                } else if (this.selectedPeriod.code === 'YEAR') {
                    return moment(date).add(1, 'year').toDate();
                }
            }
            return date;
        }

        this.subtractSelectedPeriod = (date) => {
            if (this.selectedPeriod) {
                if (this.selectedPeriod.code === 'WEEK') {
                    return moment(date).subtract(1, 'week').toDate();
                } else if (this.selectedPeriod.code === 'TWO_WEEKS') {
                    return moment(date).subtract(2, 'week').toDate();
                } else if (this.selectedPeriod.code === 'MONTH') {
                    return moment(date).subtract(1, 'month').toDate();
                } else if (this.selectedPeriod.code === 'THREE_MONTHS') {
                    return moment(date).subtract(3, 'month').toDate();
                } else if (this.selectedPeriod.code === 'YEAR') {
                    return moment(date).subtract(1, 'year').toDate();
                }
            }
        }

        
    }
    componentDidMount() {
        return this.initTimeline();
    }

    componentWillUnmount() {
        if (this.alignCurrentTimeIntervalId) {
            clearInterval(this.alignCurrentTimeIntervalId);
            this.alignCurrentTimeIntervalId = null;
        }

    }

    shouldComponentUpdate(nextProps){
        if (this.timeline && nextProps.config) {
            if (this.props?.config?.start !== nextProps?.config?.start) {
                this.timeline.setOptions({
                    max: nextProps.config.max,
                    min: nextProps.config.min,
                    start: nextProps.config.start,
                    //end: this.addSelectedPeriod(nextProps.config.start),
                });


                $('#projectManager span.p-dropdown-label.p-inputtext').text(this.selectedPeriod.label);
                const range = this.timeline.getWindow();
                if (nextProps?.config?.period) {
                    this.selectedPeriod = this?.periods.find(p => p.code === nextProps.config.period);
                    const end = this.addSelectedPeriod(range.start);
                    this.timeline.setWindow({
                        start: range.start,
                        end:   end,
                    });
                }
            }
        }
        
        return false;
    }


    

    generateOptions() {
        const options = {
            locale: 'pl',
            orientation: 'both',
            //minHeight: '500px',
            width: '100%',
            margin: {
                item: 20
            },
            start: this.props?.config?.start,
            end: this.props?.config?.end,
            editable: {
                add: false,
                remove: true,
                updateGroup: false,
                updateTime: false,
            },            
            showTooltips: true,
            horizontalScroll: true,
            verticalScroll: true,
            zoomKey: 'ctrlKey',
            zoomMin: 1000 * 60 * 60 * 24 * 7,        // one week in milliseconds
            zoomMax: 1000 * 60 * 60 * 24 * 31 * 12,   // about 12 months in milliseconds
            min: this.props?.config?.min,
            max: this.props?.config?.max,
            showCurrentTime: true,
            showWeekScale: true,
            format: {
                minorLabels: {day: 'DD.MM'},
                majorLabels: {day: 'w'}
            },
            tooltip:{overflowMethod: 'cap'},
            rollingMode: {
                // empty object as a fix for missing onInitialDrawComplete event
            },
            onInitialDrawComplete: () => {                
                if (this.timeline) {
                    this.timeline.setItems(this.props.projects);
                }
                if (this.props?.onReady) {
                    this.props.onReady();
                }
            },
            template: (item, element) => {
                if (!item) { 
                    return 
                }
                return ReactDOM.createPortal( ReactDOM.render( <ItemTemplate item={item} />, element ), element, () => { 
                    if (this.timeline) {
                        this.timeline.redraw();
                    }
                });
            },
           
            groupTemplate: (user, element) => {
                if (!user || !user.firstName || !user.lastName) { 
                    return 
                }
                return ReactDOM.createPortal( ReactDOM.render( <GroupTemplate group={user} 
                    handleClick={this.assignNewProject.bind(this, user.id, user.lastName + ' ' + user.firstName)}/>, 
                    element ), element 
                );
            },

            visibleFrameTemplate: (item, element) => {
                if (!item || !element) { 
                    return 
                }
                if (element.className.indexOf('timeline-item-visible-frame') === -1) { 
                    return 
                }
                return ReactDOM.createPortal( ReactDOM.render( <VisibleFramTemplate item={item} />, element ), element );
                
            },
            loadingScreenTemplate: () => {
                return (
                    <div className="row">
                        <div className="col-12">
                            <h1>Loading...</h1>
                        </div>
                    </div>                    
                );
            },
            onRemove: (project, callback) => {
                if (this.props.onUnassignProject) {
                    const user = this.props?.users.get(project.group);

                    const callbackWrapper = (itemId, removed, dateStart, dateEnd) => {
                        if (removed) {
                            callback({id: itemId});
                        } else {
                            callback(null);
                            project.start = moment(dateStart).startOf('day');
                            project.end = moment(dateEnd).endOf('day');
                            project.editable.remove = false;
                            
                            TooltipHelper.generateProjectTooltip(this.props?.projects?.get({id: project.id}));
                            

                            //jeśli usuwamy i zamiast usunąć zmniejszamy zakres dat, to nie odmalowuje się wykres;
                            //dopiero przesunięcie powoduje odmalowanie go;
                            //nie wyłącza się też opcja editable.remove
                            this.movePercentage( -0.0001);
                            this.movePercentage( 0.0001);  
                            this.timeline.setSelection([]);                          
                        }
                    }
                    if (project?.editable?.remove) {
                        this.props.onUnassignProject(user, project, callbackWrapper);
                    }
                }                
            }
        }
        return options;

    }
    
    initTimeline() {
        const container = document.getElementById('visualization');        
        this.timeline = new Timeline(container, this.props?.projects, this.props?.users, this.generateOptions());
        
        const customDate = moment().startOf('day').toDate();
        
        //kreska z bieżącym czasem przesuwa się; więc ją sprowadzamy interwałem do początku dnia;
        this.timeline.setCurrentTime(moment().startOf('day').toDate());
        this.alignCurrentTimeIntervalId = setInterval(() => {
            if (this.timeline) {
                this.timeline.setCurrentTime(moment().startOf('day').toDate())
            }
          }, 10000);  
        
    } 

    assignNewProject(userId, userName) {
        this.props.onAssignNewProject(userId, userName);
    }

    movePercentage(percentage) {
        const range = this.timeline.getWindow();
        const interval = range.end - range.start;        
        this.timeline.setWindow({
            start: range.start.valueOf() - interval * percentage,
            end:   range.end.valueOf()   - interval * percentage
        });
    }

    moveSelectedPeriod(positiveDirection) {
        const range = this.timeline.getWindow();

        const start = positiveDirection ? this.addSelectedPeriod(range.start) : this.subtractSelectedPeriod(range.start);
        const end = positiveDirection ? this.addSelectedPeriod(range.end) : this.subtractSelectedPeriod(range.end);

        this.timeline.setWindow({
            start: start,
            end:   end,
        });

    }    


    render() {
        
        return (
            <div>
                <Tooltip id="timelineHeader" target=".timelineHeaderIcon"  />
                <div id="visualization">                
                    <div className='row' style={{textAlign: 'center', marginBottom: '24px'}}>
                            <div className='col-3'>
                            <InputDropdownComponent
                                colClass='col-12'
                                id={`projectManager`}
                                name="projectManager"
                                label={t('project.details.projectManager')}
                                dataKey='id'
                                options={this.periods}
                                optionLabel='label'
                                viewMode={'EDIT'}
                                onChange={(a,b,e) => {
                                    const label = $('#projectManager span.p-dropdown-label.p-inputtext');
                                    this.selectedPeriod = e?.target?.value;                                
                                    label.text(this.selectedPeriod?.label);

                                    const range = this.timeline.getWindow();
                                    this.timeline.setWindow({
                                        start: range.start,
                                        end:   this.addSelectedPeriod(range.start),
                                    });

                                }}
                                showLabel={false}                            
                            />
                            </div>
                            <div className='col-2'>
                                <i className="timelineHeaderIcon mdi mdi-arrow-left icon-large-plus " style={{ marginRight: '14px', color: '#0d6efd'}} data-pr-tooltip="Przesuń w lewo"
                                    onClick={() => this.moveSelectedPeriod(false)}
                                />
                                <i className="timelineHeaderIcon mdi mdi-arrow-right icon-large-plus " style={{ marginRight: '14px', color: '#0d6efd'}} data-pr-tooltip="Przesuń w praco"
                                    onClick={() => this.moveSelectedPeriod(true)}
                                />
                            </div>
                        
                    </div>
                </div>
            </div>
        )
    }

};

class TooltipHelper {
    static formatAddress(address) {
        let result = [];
        if (address.country) {
            result.push(address.country);
        }
        if (address.postalCode) {
            result.push(address.postalCode);
        }
        if (address.city) {
            result.push(address.city);
        }
        if (address.streetName) {
            result.push(address.streetName);
        }
        if (address.streetNumber) {
            result.push(address.streetNumber);
        }
        return result.join(', ');
    }

    static generateProjectTooltip(p) {
        let tooltip = `<p>${p.projectName}</p>`;
        tooltip += `<p>${moment(p.start).format(Constants.DATE_FORMAT)} - ${moment(p.end).format(Constants.DATE_FORMAT)}</p>`;
        if (p?.clientName) {
            tooltip += `<p>${p.clientName}</p>`;
        }
        if (p?.projectAddress) {                    
            tooltip += `<p>${this.formatAddress(p.projectAddress)}</p>`;
        }
        p.title = tooltip;
    }
}



ManagerTimelineContainer.defaultProps = {
    backUrl: '/#/project-list',
    cancelUrl: '/#/project/details',
    editUrl: '/#/project/edit',
};

ManagerTimelineContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withRouter(withTranslation()(ManagerTimelineContainer));

