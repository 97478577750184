import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import ActionLink from '../components/ActionLink';
import YesNoDialog from '../components/YesNoDialog';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import CarService from '../services/CarService';
import CarEditDialog from './CarEditDialog';

class CarListContainer extends BaseListContainer {
    constructor(props) {
        console.log('***', props);
        super(props, new CarService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            dialogVisibile: false,
            deleteConfirmDialogVisible: false,
            element: this.initNewElement(),
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
    }

    initNewElement() {
        return {
            licensePlate: '',
            brand: '',
            model: '',
            odometer: null,
            status: {enumValue: 'ACTIVE', label: 'Aktywny'},
        };
    }

    updateSearchCriteria(criteria) {
        return {
            licensePlate: criteria.licensePlate,
            brand: criteria.brand,
            model: criteria.model,
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            licensePlate: '',
            brand: '',
            model: '',
            status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('CarStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
    }

    getCriteriaName() {
        return 'car-list-sc';
    }

    getContainerListName() {
        return 'car-list-container';
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='licensePlate'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.licensePlate}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('car.licensePlate')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='name-sc'
                    name='brand'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.brand}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('car.brand')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='name-sc'
                    name='model'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.model}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('car.model')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={'Status'}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi-pencil'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => this.handleOpenEditDialog(e, rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                    rendered={rowData.status.enumValue === 'ACTIVE'}
                />
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => this.handleOpenDeleteDialog(e, rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                    rendered={rowData.status.enumValue === 'ACTIVE'}
                />
            </span>
        );
    }

    handleOpenEditDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: this.cloneElement(rowData), dialogViewMode: 'EDIT'});
    }

    handleOpenDeleteDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({deleteConfirmDialogVisible: true, element: rowData, dialogViewMode: 'DELETE'});
    }

    prepareColumns() {
        return [
            {field: 'licensePlate', header: t('car.licensePlate'), sortable: true},
            {field: 'brand', header: t('car.brand'), sortable: true},
            {field: 'model', header: t('car.model'), sortable: true},
            {field: 'odometer', header: t('car.odometer'), sortable: true},
            {field: 'status', header: 'Status', sortable: true, body: this.enumTemplate.bind(this, 'status')},
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO')
            ? [
                  {
                      label: t('car.newButton'),
                      type: 'BUTTON',
                      className: 'float-right',
                      variant: 'blue',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                      iconColor: 'white',
                      onClick: this.handleOpenNewDialog,
                  },
              ]
            : [];
    }
    handleOpenNewDialog(e) {
        let basicElement = this.initNewElement();
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW'});
    }

    handleDelete(e) {
        if (e) {
            e.preventDefault();
        }
        console.log('delete {}', this.state.element);
        this.service
            .remove(this.state.element)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('car.deleteSuccess'));
            })
            .catch((err) => {
                this.persistMessage('error', '', t('car.deleteError'));
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({deleteConfirmDialogVisible: false});
            });
    }

    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    render() {
        return (
            <div className='container-fluid car-list'>
                <BreadcrumbsItem to='/car-list'>{'Podwykonawcy'}</BreadcrumbsItem>
                {this.renderView()}
                {this.state.dialogVisibile ? (
                    <CarEditDialog
                        className='car-dialog'
                        key={`car-dialog-${this.state.element?.id}`}
                        element={this.state.element}
                        onHide={(e) => this.setState({dialogVisibile: false})}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}
                        afterSave={(e) => {
                            this.setState({dialogVisibile: false});
                            this.afterObjectUpdate();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
                {this.state.deleteConfirmDialogVisible ? (
                  
                    <YesNoDialog
                    visible={this.state.deleteConfirmDialogVisible}
                    header={t('car.deleteHeader')}
                    name='visible'
                    onChange={(type, x, target) => {
                        if (target.value) {
                        
                            this.handleDelete();
                        } else {
                            this.setState({deleteConfirmDialogVisible: false})
                        }
                    }}
                    onHide={() => this.setState({deleteConfirmDialogVisible: false})}
                     >
                        {t('details.deleteConfirmation') + '\n' + this.state.element.licensePlate}
                    </YesNoDialog> 
                ) : null}
            </div>
        );
    }
}

CarListContainer.defaultProps = {
    detailUrl: '/#/car/details',
    newUrl: '/#/car/create',
    lazy: true,
};

CarListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
    lazy: PropTypes.bool,
};

export default CarListContainer;
