import BaseServiceCrud from './BaseServiceCrud';


export default class DocumentTypeService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'document-type';
      //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,            
            status: criteria.status?.enumValue,            
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            countries: criteria.countries ? criteria.countries.map(country => country.id).join(",") : null,
        });
    }

    normalizeDocumentType(e) {
        if (!e?.country?.id) {
            e.country = null;
        }
        return e;
    }

    add(element) {
        const e = this.normalizeDocumentType(this.cloneElement(element));
        return super.add(e);
    }

    update(element) {
        const e = this.normalizeDocumentType(this.cloneElement(element));
        return super.update(e);
    }
}
