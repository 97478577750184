import BaseServiceCrud from "./BaseServiceCrud";

export default class ProjectAddressService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'project-address';
    }
  	list(query) {
		let path = `${this.domain}/${this.path}/list-sc`;
		if (query) {
			path += `?query=${query}`;
		}
    	return this.fetch(path, {
    		method: 'GET',
    	})
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
    		throw err;
    	});
    }
}
