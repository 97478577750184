import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import DivContainer from '../../components/DivContainer';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import {t} from 'i18next';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import ClientService from '../../services/ClientService';
import InputTextareaComponent from '../../components/inputs/InputTextareaComponent';
import {LinksComponent} from '../../components/inputs/LinksComponent';
import CountryService from '../../services/CountryService';

class ClientContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new ClientService());
        this.state = {
            elementId: props.elementId,
            element: this.initNewElement(),
            statusOptions: [],
            viewMode: this.props.viewMode,
            countries: [],
        };
        this.countryService = new CountryService();
    }
    initNewElement() {
        return {
            name: '',
            firstName: '',
            nip: '',
            regon: '',
            status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            links: [],
            phoneNumber: '',
            contactPerson: '',
            note: '',
            address: {
                country: {},
                city: '',
                postalCode: '',
                streetName: '',
                streetNumber: '',
                houseNumber: '',
            },
        };
    }

    getContainerListName() {
        return 'client-list-container';
    }
    //overide
    renderPdfDownloadButton() {}
    getAddSucces() {
        const {t} = this.props;
        return t('client.addUserSuccess');
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('client.updateUserSuccess');
    }

    updateElement(data) {
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    initBeforeSetElement() {
        this.enumService
            .getEnumList('ClientStatus')
            .then((statusOptions) => {
                statusOptions.forEach((o) => (o.label = t('ClientStatus.' + o.enumValue)));
                this.setState({
                    statusOptions,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });

        this.countryService
            .getAll()
            .then((countries) => {
                this.setState({
                    countries,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy krajów');
            });
    }

    prepareFooterItems() {
        const {editUrl, elementId} = this.props;

        const result = super.prepareFooterItems();

        if (this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_CTO','ROLE_USER') && this.props.viewMode === 'VIEW') {
            result.push({label: 'Edytuj', className: 'float-right', href: `${editUrl}/${elementId}`});
        }

        return result;
    }

    renderDetails() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='clientName'
                        name='name'
                        label={t('client.details.clientName')}
                        colClass={this.props.viewMode === 'NEW' ? 'col-md-12' : 'col-md-10'}
                        value={this.state.element.name}
                        validator={this.validator}
                        validators='required|max:150'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputDropdownComponent
                        id='status'
                        name='status'
                        label={'Status'}
                        colClass='col-md-2'
                        value={this.state.element.status}
                        validator={this.validator}
                        options={this.state.statusOptions}
                        onChange={null}
                        viewMode={'VIEW'}
                        optionLabel='label'
                        dataKey='enumValue'
                        validators='not_required'
                        rendered={this.props.viewMode !== 'NEW'}
                        valueView={this.state.element.status ? this.state.element.status.label : ''}
                    />
                </DivContainer>
                <DivContainer colClass='row mt-4'>
                    <InputDropdownComponent
                        id='addressCountry'
                        name='country'
                        label={t('client.details.countryName')}
                        colClass='col-md-4'
                        value={this.state.element?.address?.country}
                        validator={this.validator}
                        options={this.state.countries}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        optionLabel='name'
                        dataKey='id'
                        validators='required'
                        valueView={this.state.element?.address?.country?.name}
                        stateField='element.address'
                        filter
                        filterBy='name'
                    />
                    <InputTextComponent
                        id='addressCity'
                        name='postalCode'
                        label={t('client.details.postalCode')}
                        colClass='col-md-2'
                        value={this.state.element?.address?.postalCode}
                        validator={this.validator}
                        validators='max:10'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField='element.address'
                    />
                    <InputTextComponent
                        id='addressCity'
                        name='city'
                        label={t('client.details.city')}
                        colClass='col-md-6'
                        value={this.state.element?.address?.city}
                        validator={this.validator}
                        validators='max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField='element.address'
                    />
                </DivContainer>
                <DivContainer colClass='row mt-4'>
                    <InputTextComponent
                        id='streetName'
                        name='streetName'
                        label={t('client.details.streetName')}
                        colClass='col-md-6'
                        value={this.state.element?.address?.streetName}
                        validator={this.validator}
                        validators='max:100'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField='element.address'
                    />
                    <InputTextComponent
                        id='streetNumber'
                        name='streetNumber'
                        label={t('client.details.streetNumber')}
                        colClass='col-md-3'
                        value={this.state.element?.address?.streetNumber}
                        validator={this.validator}
                        validators='max:20'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField='element.address'
                    />
                    <InputTextComponent
                        id='houseNumber'
                        name='houseNumber'
                        label={t('client.details.houseNumber')}
                        colClass='col-md-3'
                        value={this.state.element?.address?.houseNumber}
                        validator={this.validator}
                        validators='max:20'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        stateField='element.address'
                    />
                </DivContainer>
                <DivContainer colClass='row mt-4'>
                    <InputTextComponent
                        id='nip'
                        name='nip'
                        label={'NIP'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={this.state.element.nip}
                        validator={this.validator}
                        validators='not_required'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='regon'
                        name='regon'
                        label={'Regon'}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={this.state.element.regon}
                        validator={this.validator}
                        validators='not_required'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='phoneNumber'
                        name='phoneNumber'
                        label={t('employee.details.phoneNumber')}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={this.state.element.phoneNumber}
                        validator={this.validator}
                        validators='not_required|phone|max:20'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='contactPerson'
                        name='contactPerson'
                        label={t('client.details.contactPerson')}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={this.state.element.contactPerson}
                        validator={this.validator}
                        validators='not_required|max:20'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextareaComponent
                        id='note'
                        name='note'
                        label={'Notatka'}
                        colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'
                        value={this.state.element.note}
                        validator={this.validator}
                        validators='not_required|max:4096'
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                    />
                    <LinksComponent
                        onLinksChange={(updatedLinks) => {
                            this.setState((prevState) => ({element: {...prevState.element, links: updatedLinks}}));
                        }}
                        links={this.state.element.links}
                        viewMode={this.props.viewMode}
                        validator={this.validator}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}
ClientContainer.defaultProps = {
    backUrl: '#/client-list',
    cancelUrl: '/#/client/details',
    editUrl: '/#/client/edit',
    viewUrl: '/#/client/details/',
    lazy: true,
};

ClientContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};
export default withTranslation()(ClientContainer);
