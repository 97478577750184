import decode from 'jwt-decode';
import moment from 'moment';
import {isOpera} from '../BrowserContext';

export default class AuthService {
    // Initializing important variables
    constructor(domain) {
        if (domain !== null && domain !== undefined) {
            this.domain = domain;
        } else {
            this.domain = process.env.REACT_APP_BACKEND_URL; // API server domain
        }
        this.fetch = this.fetch.bind(this);
        this.setUiMethods = this.setUiMethods.bind(this);
        // eslint-disable-next-line no-extend-native
        Date.prototype.toJSON = function () {
            return moment(this).format('YYYY-MM-DDTHH:mm:ssZ');
        };
        this.counter = 0;
        this.path = '';
        this.login = this.login.bind(this);
        this.getProfile = this.getProfile.bind(this);
    }

    setUiMethods(blockUi, unblockUi) {
        this.blockUi = blockUi;
        this.unblockUi = unblockUi;
    }

    fetch(url, options, headers) {
        const method = options !== undefined ? options.method : undefined;
        // performs api calls sending the required authentication headers
        if (headers === null || headers === undefined) {
            headers = {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                Pragma: 'no-cahce',
            };
        }
        if (this.loggedIn()) {
            headers['Authorization'] = this.getToken();
        }
        if (method === 'POST' || method === 'PUT') {
            this.counter += 1;
            if (this.blockUi !== undefined) {
                this.blockUi();
            }
        }
        return new Promise((resolve, reject) => {
            fetch(url, {
                headers,
                ...options,
            })
                .then((response) => this.parseJSON(response, headers))
                .then((response) => {
                    if (method === 'POST' || method === 'PUT') {
                        this.counter -= 1;
                        if (this.counter <= 0 && this.unblockUi !== undefined) {
                            this.unblockUi();
                        }
                    }
                    if (
                        response.ok &&
                        (headers === undefined || headers.accept === 'application/json' || headers.Accept === 'application/json')
                    ) {
                        return resolve(response.json, response.status);
                    } else if (response.ok) {
                        return resolve(response.body);
                    }
                    // extract the error from the server's json
                    return reject(response.json);
                })
                .catch((error) => {
                    if (method === 'POST' || method === 'PUT') {
                        this.counter -= 1;
                        if (this.counter <= 0 && this.unblockUi !== undefined) {
                            this.unblockUi();
                        }
                    }
                    if (
                        error !== undefined &&
                        error !== null &&
                        error.message !== undefined &&
                        error.message !== null &&
                        (error.message.includes('NetworkError when attempting to fetch resource') ||
                            error.message.includes('Failed to fetch'))
                    ) {
                        error.message = 'komunikacji z serwerem podczas pobierania danych.';
                    }
                    reject(error);
                });
        });
    }

    parseJSON(response, headers) {
        if (response.status) {
            return new Promise((resolve, reject) => {
                if (headers === undefined || headers.accept === 'application/json' || headers.Accept === 'application/json') {
                    response.json().then(
                        (json) => {
                            resolve({
                                status: response.status,
                                ok: response.ok,
                                json,
                            });
                        },
                        (reason) => {
                            reject({
                                status: response.status,
                                ok: response.ok,
                                json: {message: reason},
                            });
                        }
                    );
                } else {
                    resolve({
                        status: response.status,
                        ok: response.ok,
                        body: response.body,
                    });
                }
            });
        } else {
            return new Promise((resolve) =>
                resolve({
                    status: response.status,
                    ok: response.ok,
                    json: {message: ''},
                })
            );
        }
    }

    login(username, password) {
        // Get a token from api server using the fetch api
        return this.fetch(`${this.domain}/auth`, {
            method: 'POST',
            body: JSON.stringify({
                username,
                password,
                //cbkId: selectedUserCbkId, to bylo w eboku, a w moya cena bez cbk
            }),
        }).then((res) => {
            this.setToken(res.token); // Setting the token in localStorage
            return Promise.resolve(res);
        });
    }

    acceptTerms(contractTermsId, token) {
        // Get a token from api server using the fetch api
        return this.fetch(
            `${this.domain}/account/accept-contract-terms`,
            {
                method: 'POST',
                body: JSON.stringify({
                    contractTermsId,
                }),
            },
            {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                Pragma: 'no-cahce',
                Authorization: token,
            }
        ).then((res) => {
            this.setToken(res.token); // Setting the token in localStorage
            return Promise.resolve(res);
        });
    }

    refresh() {
        //ograniczenie, żeby za często nie pukać po token
        const now = moment();
        const tokenUpdateTime = localStorage.getItem('tokenUpdateTime');
        if (tokenUpdateTime) {
            const tokenUpdateTimeMoment = moment(tokenUpdateTime, 'YYYY-MM-DD HH:mm:ss');
            if (tokenUpdateTimeMoment.isValid()) {
                const maxTime = tokenUpdateTimeMoment.add(5, 'seconds');
                if (now.isBefore(maxTime)) {
                    this.jwtRefreshBlocked = true;
                    return Promise.resolve({});
                } else {
                    localStorage.setItem('tokenUpdateTime', now.format('YYYY-MM-DD HH:mm:ss'));
                }
            }
        }

        return this.fetch(`${this.domain}/refresh`, {
            method: 'GET',
        }).then((res) => {
            this.setToken(res.token); // Setting the token in localStorage
            return Promise.resolve(res);
        });
    }

    logout() {
        // Get a token from api server using the fetch api

        localStorage.removeItem('id_token');
        sessionStorage.removeItem('id_token');
        localStorage.removeItem('i18nextLng');
    }

    loggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken(); // GEtting token from localstorage
        return !!token && !this.isTokenExpired(token); // handwaiving here
    }

    isTokenValidForRefresh() {
        const token = this.getToken();
        try {
            const decoded = decode(token);
            let seconds = moment().diff(new Date(decoded.created), 'seconds');
            if (seconds > 30) {
                // Checking if token is expired. N
                return true;
            } else {
                return false;
            }
        } catch (err) {
            return false;
        }
    }
    isTokenExpired(token) {
        try {
            const decoded = decode(token);
            if (decoded.exp < Date.now() / 1000) {
                // Checking if token is expired. N
                return true;
            } else return false;
        } catch (err) {
            return false;
        }
    }
    setToken(idToken) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken);
        sessionStorage.setItem('id_token', idToken);
    }
    getToken() {
        if (this.canSetTokenFromSession()) {
            this.setFromSessionStorageToLocalStorageToken();
        }
        return localStorage.getItem('id_token');
    }
    canSetTokenFromSession() {
        return localStorage.getItem('id_token') === null && localStorage.getItem('log_out') !== 'log_out';
    }
    setFromSessionStorageToLocalStorageToken() {
        const idToken = sessionStorage.getItem('id_token');
        if (idToken !== null && !isOpera()) localStorage.setItem('id_token', idToken);
    }
    setFromLocalStorageToSessionStorageToken() {
        const idToken = localStorage.getItem('id_token');
        if (idToken !== null && !isOpera()) sessionStorage.setItem('id_token', idToken);
    }
    getFromSessionToken() {
        const sessionToken = sessionStorage.getItem('id_token');
        return sessionToken;
    }

    getProfile() {
        // Using jwt-decode npm package to decode the token
        if (!!this.getToken()) {
            try {
                const decoded = decode(this.getToken());
                return decoded;
            } catch (err) {
                console.log('getProfile', this.getToken(), err);
                return {};
            }
        } else {
            return {};
        }
    }

    getRoles() {
        // Using jwt-decode npm package to decode the token
        try {
            const decoded = decode(this.getToken());
            let rolesArray = [];
            if (decoded !== undefined && decoded !== null && decoded.role !== undefined && decoded.role !== null) {
                decoded.role.forEach((element) => {
                    if (element.authority !== undefined && element.authority !== null) {
                        rolesArray.push(element.authority);
                    }
                });
            }
            return rolesArray;
        } catch (err) {
            return [];
        }
    }

    isUserInRole(role) {
        const roles = this.getRoles();
        return roles.includes(role);
    }

    isUserInAnyRole(...rolesToFind) {
        const roles = this.getRoles();
        let authorized = false;
        rolesToFind.forEach((role) => {
            if (roles.includes(role)) {
                authorized = true;
            }
        });
        return authorized;
    }
    

    isUserInAnyRoleArray(rolesToFind) {
        const roles = this.getRoles();
        let authorized = false;
        rolesToFind.forEach((role) => {
            if (roles.includes(role)) {
                authorized = true;
            }
        });
        return authorized;
    }

    getUserId() {
        const user = this.getProfile();
        return user.userId ? user.userId : null;
    }
    getCbkId() {
        const user = this.getProfile();
        return user.cbkId ? user.cbkId : null;
    }

    getUserEmail() {
        const user = this.getProfile();
        return user.sub ? user.sub : null;
    }

    expirationTimer() {
        try {
            const decoded = decode(this.getToken());
            let seconds = moment().diff(new Date(decoded.exp * 1000), 'seconds');
            return moment.utc(-seconds * 1000).format('mm:ss');
        } catch (err) {
            this.logout();
        }
    }
}
