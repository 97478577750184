import React from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';
import {t} from 'i18next';

export const AlarmStatusIconTemplateComponent = ({viewMode, row, onClick}) => {
    const cursosPointerClassName = viewMode === 'EDIT' && 'cursor-pointer';

    let color;
    const label = t(`AlarmStatus.${row.status}`);
    switch (row.status) {
        case 'UNSERVED':
            color = 'red';
            break;
        case 'SERVED':
            color = 'black';
            break;
        default:
            color = 'green';
    }
    return (
        <div
            onClick={(e) => {
                if (onClick) {
                    onClick(e);
                }
            }}
        >
            <div className={` ${cursosPointerClassName}`}>
                <span
                    style={{
                        background: color,
                        borderRadius: '50px',
                        padding: '7px',
                        color: 'white',
                        width: 'aout',
                        fontSize: '12px',
                        fontWeight: '600',
                    }}
                >
                    {label}
                </span>
            </div>
        </div>
    );
};

AlarmStatusIconTemplateComponent.defaultProps = {
    viewMode: 'VIEW',
    row: undefined,
};

AlarmStatusIconTemplateComponent.propTypes = {
    viewMode: PropTypes.string.isRequired,
    row: PropTypes.object.isRequired,
};

export default withTranslation()(AlarmStatusIconTemplateComponent);
