import { withTranslation } from "react-i18next";
import BaseDetailsContainer from "../baseContainers/BaseDetailsContainer";
import React from "react";
import PropTypes from 'prop-types';
import DivContainer from "../components/DivContainer";
import { Card } from 'primereact/card';
import { TabView, TabPanel } from 'primereact/tabview';
import ToolService from "../services/ToolService";
import InputTextComponent from "../components/inputs/InputTextComponent";
import InputDropdownComponent from "../components/inputs/InputDropdownComponent";
import InputCalendarComponent from "../components/inputs/InputCalendarComponent";
import InputTextareaComponent from "../components/inputs/InputTextareaComponent";
import EnumService from "../services/EnumService";
import ToolTypesService from "../services/ToolTypesService";
import ToolProducersService from "../services/ToolProducersService";
import ToolModelService from "../services/ToolModelService";
import LogEntryListContainer from "./LogEntryListContainer";
import ToolDeleteDialog from "./ToolDeleteDialog";
import { t, use } from "i18next";


class ToolDetailsContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new ToolService());
        this.state = {
            elementId: props.elementId,
            modelList: [],
            dialogVisibile: false,
            producerOptions: props.producerOptions,
            modelOptions: props.modelOptions,
            typeOptions: props.typeOptions,
            logEntryCodes: [],
            serialNumber: '',
            status: '',
            element: {
                model: {
                    id: null,
                    modelIdentifier: ''
                },
                producer: {
                    id: null,
                    name: ''
                },
                type: {
                    id: null,
                    typeName: ''
                },
                serialNumber: '',
                graver: '',
                status: { enumValue: 'ACTIVE', label: 'Aktywny' }
            },
            viewMode: this.props.viewMode,
            isCreate: false
        };
        this.handleType = this.handleType.bind(this);
        this.handleProducer = this.handleProducer.bind(this);
        this.handleModel = this.handleModel.bind(this);
        this.statusTemplate = this.statusTemplate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.enumService = new EnumService();
        this.toolTypesService = new ToolTypesService();
        this.toolProducersService = new ToolProducersService();
        this.toolModelsService = new ToolModelService();
        this.nextInspMode = {
            view: 'month',
            dateFormat: 'mm-yy',
            placeholder: 'mm-yy',
            dateFormatMoment: 'MM-YYYY',
            yearNavigator: true,
        };

        this.validator.addCustomRule('at_least_one_field_required', t('validator.at_least_one_field_required'), value => {
                        
            const { type, producer, model, serialNumber, graver } = this.state.element;            
            const isEmpty = v => {
                if (v) {
                    return v == '';
                } else {
                    return true;
                }
            }
            if (isEmpty(type?.typeName) && isEmpty(producer?.name) && isEmpty(model?.modelIdentifier) && isEmpty(serialNumber) && isEmpty(graver)) {
                return false;
            }
            return true;
        },
            (message, params) => message,
            true    
        );
        
    }


    formatNumber(number) {
        if (!number) {
            return 0;
        }
        const formatter = new Intl.NumberFormat('pl-PL', {
            style: 'decimal',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const formattedNumber = formatter.format(number);
        return formattedNumber;
    }

    updateElement(data) {

        this.setState(
            {
                element: {
                    id: data.id,
                    type: data.type == null ? {
                        id: null,
                        typeName: ''
                    } : data.type,
                    model: data.model == null ? {
                        id: null,
                        modelIdentifier: ''
                    } : data.model,
                    producer: data.producer == null ? {
                        id: null,
                        name: ''
                    } : data.producer,
                    toolkit: data.toolkit,
                    serialNumber: data.serialNumber == null ? '' : 
                    data.serialNumber,
                    status: data.status==null ? { enumValue: 'ACTIVE', label: 'Aktywny' } : 
                    data.status,
                    deleteReason: data.deleteReason == null ? '' : 
                    data.deleteReason,
                    graver: data.graver ? data.graver : '',
                    notes: data.notes ? data.notes : '',
                    nextInsp: data.nextInsp ? new Date(data.nextInsp) : null,
                }
            },
            () => {
                this.initAfterSetElement();
            }
        );

    }

    initBeforeSetElement() {

        this.enumService
            .getEnumList('ToolStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });

        this.getToolTypes()
            .then((types) =>
                this.setState({
                    typeOptions: types.length > 0 ? types : null,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać typów narzędzi');
            });

        this.getProducers()
            .then((producers) =>
                this.setState({
                    producerOptions: producers.length > 0 ? producers : null,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać producentów');
            });

        this.getToolsModels()
            .then((models) =>
                this.setState({
                    modelOptions: models.length > 0 ? models : null
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać modeli');
            });
        this.enumService
            .getEnumList('LogEntryCode')
            .then((logEntryCodes) =>
                this.setState({
                    logEntryCodes,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów zdarzeń');
            });
    }

    getToolTypes() {
        return this.toolTypesService.getList();
    }
    getProducers() {
        return this.toolProducersService.getList();
    }

    getToolsModels() {
        return this.toolModelsService.getList();
    }

    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            this.blockUi(this.handleValidForm);
        } else {
            this.validator.showMessages();
            this.scrollToError = true;
            this.forceUpdate();
        }
    };

    prepareFooterItems() {
        const result = super.prepareFooterItems();
        const { backUrl, cancelUrl, viewMode, editUrl, deleteUrl } = this.props;
        const { element } = this.state;
        const { status } = this.state;

        let goBackUrl;
        if (viewMode === 'NEW') {
            goBackUrl = backUrl;
        } else {
            goBackUrl = `${cancelUrl}/${element.id}`;
        }
        const isNotDeleted = status == 'DELETED';
        const isAssigned = element.toolkit != null;

        switch (viewMode) {
            case 'EDIT':
            case 'NEW':
                break;
            case 'VIEW':
                if (!isNotDeleted) {
                    if (isAssigned) {
                        result.push(
                            {
                                label: 'Usuń', className: 'fullred float-right', onClick: (e) => {
                                    this.showErrorMessage('Narzędzie jest przypisane do zestawu. Aby usunąć narzędzie, najpierw należy je usunąć z zestawu.');
                                    return;
                                }
                            },
                            { label: 'Edytuj', className: 'float-right', href: `${editUrl}/${element.id}` })
                    } else {
                        result.push(
                            {
                                label: 'Usuń', className: 'fullred float-right', onClick: (e) => {
                                    this.setState({
                                        dialogVisibile: true
                                    })
                                }
                            },
                            { label: 'Edytuj', className: 'float-right', href: `${editUrl}/${element.id}` })
                    }
                }
                break;
            case 'DELETE':
                result.push(
                    { label: 'Usuń narzędzie', className: 'float-right', onClick: this.handleDelete })
                break;
            default:
                break;


        }
        return result;
    }


    isPdfButtonRendered() {
        return false;
    }

    getPdfDownloadLabel() {
        return null;
    }

    handleDownloadPdf() {
        // this.setState({ downloadingPdf: true},
        //     () => {
        //         setTimeout(() => {
        //             if (this.state.downloadingPdf) {
        //                 document.getElementById('pdf-download-spinner').style.display = 'block';
        //             }
        //         }, 500);

        //         this.service.exportInvoice(this.state.elementId)
        //             .then((blob) => {
        //                 this.setState({ downloadingPdf: false });
        //                 const now = moment().format('YYYYMMDD_HHmmss');
        //                 const fileName = `Faktura_${now}.pdf`;
        //                 saveAs(blob, fileName);
        //             })
        //             .catch(() => {
        //                 this.setState({ downloadingPdf: false });
        //                 this.showErrorAboutMissingPdf('faktury');
        //             });
        //     });

    }


    searchTools(query, toolId) {
        //removing already defined
        let toolIds = this.state.element?.toolList.filter(x => x != undefined).map(t => t.id).filter(id => toolId != id);
        this.toolService
            .searchTools(query)
            .then(filteredTools => this.setState({ filteredTools: filteredTools.filter(e => !toolIds.includes(e.id)) }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy narzędzi');
            });
    }

    renderVal(e, val) {
        return val;
    }
    renderDetails() {
        const { t } = this.props;
        const typeOptions = this.state.typeOptions;
        const producerOptions = this.state.producerOptions;
        const modelOptions = this.state.modelOptions;
        const status = this.state.element.status;

        let toolkitName = '';
        if (this.state.element.toolkit != null) {
            toolkitName = this.state.element.toolkit.name;
        }
        
        
       
        let codeOptions=this.state.logEntryCodes.filter(
            (x)=>
             x.enumValue === 'CREATE_TOOL'
         ||  x.enumValue === 'UPDATE_TOOL'
         ||  x.enumValue === 'DELETE_TOOL'
         ||  x.enumValue.includes('TOOL_ASSIGNED_TO_TOOLS')
         ||  x.enumValue.includes('TOOL_UNASSIGNED_FROM_TOOLS'));

        return (
            <React.Fragment>

                {this.props.viewMode !== 'DELETE' ?
                    <DivContainer colClass='row'>
                        <DivContainer colClass='col-md-12'>
                            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })} >
                                <TabPanel header="Szczegóły">
                                    <Card header=''>
                                        <DivContainer colClass='row' rendered={true}>
                                            <DivContainer colClass='row'>
                                                <InputDropdownComponent
                                                    id='type'
                                                    name='type'
                                                    label={'Typ'}
                                                    value={this.state.element.type.typeName}
                                                    options={typeOptions}
                                                    optionLabel='typeName'
                                                    validators='at_least_one_field_required|max:100'
                                                    editable
                                                    colClass='col-md-4'
                                                    placeholder='Wszystkie'
                                                    onChange={this.handleType}
                                                    validator={this.validator}
                                                    renderValue={(e) => this.renderVal(e, this.state.element.type.typeName)}
                                                    viewMode={this.state.viewMode}
                                                    filter={true}
                                                    filterBy={'typeName'}
                                                    showClear
                                                />


                                                <InputDropdownComponent
                                                    id={`producer`}
                                                    name='producer'
                                                    label={'Producent'}
                                                    value={this.state.element.producer.name}
                                                    optionLabel='name'
                                                    optionValue='name'
                                                    options={producerOptions}
                                                    validator={this.validator}
                                                    validators='at_least_one_field_required|max:100'
                                                    colClass='col-md-4'
                                                    editable
                                                    onChange={this.handleProducer}
                                                    stateField={``}
                                                    renderValue={(e) => this.renderVal(e, this.state.element.producer.name)}
                                                    viewMode={this.state.viewMode}
                                                    filter={true}
                                                    filterBy={'name'}
                                                    showClear
                                                />


                                                <InputDropdownComponent
                                                    id={`model`}
                                                    name='model'
                                                    label={'Model'}
                                                    value={this.state.element.model.modelIdentifier}
                                                    options={modelOptions}
                                                    validator={this.validator}
                                                    validators='at_least_one_field_required|max:100'
                                                    optionValue='modelIdentifier'
                                                    optionLabel='modelIdentifier'
                                                    colClass='col-md-4'
                                                    editable
                                                    onChange={this.handleModel}
                                                    renderValue={(e) => this.renderVal(e, this.state.element.model.modelIdentifier)}
                                                    viewMode={this.state.viewMode}
                                                    filter={true}
                                                    filterBy={'modelIdentifier'}
                                                    showClear
                                                //onFilter={this.handleFilter}
                                                />
                                                <InputTextComponent
                                                    id='serialNumber'
                                                    name='serialNumber'
                                                    label={'Numer Seryjny'}
                                                    colClass='col-md-4'
                                                    value={this.state.element.serialNumber}
                                                    validator={this.validator}
                                                    validators='at_least_one_field_required|max:50'
                                                    onChange={this.handleChange}
                                                    viewMode={this.state.viewMode}
                                                />
                                                <InputTextComponent
                                                    id='graver'
                                                    name='graver'
                                                    label={'Grawer'}
                                                    colClass='col-md-4'
                                                    value={this.state.element.graver}
                                                    validator={this.validator}
                                                    validators='at_least_one_field_required|max:50'
                                                    onChange={this.handleChange}
                                                    viewMode={this.state.viewMode}
                                                />
                                                <InputTextComponent
                                                    id='status'
                                                    name='status'
                                                    label={'Status'}
                                                    colClass='col-md-4'
                                                    value={this.statusTemplate(this.state.status)}
                                                    onChange={this.handleChange}
                                                    disabled={this.state.viewMode === 'EDIT'}
                                                    rendered={this.state.viewMode !== 'NEW'}
                                                    viewMode={this.state.viewMode}
                                                />
                                                <InputTextComponent
                                                    id='toolkit'
                                                    name='toolkit'
                                                    label={'Zestaw'}
                                                    colClass='col-md-4'
                                                    value={toolkitName}
                                                    onChange={this.handleChange}
                                                    viewMode={this.state.viewMode}
                                                    disabled={this.state.viewMode === 'EDIT'}
                                                    rendered={this.state.viewMode !== 'NEW'}
                                                />
                                                <InputTextareaComponent
                                                    id='deleteReason'
                                                    name='deleteReason'
                                                    label={'Powód usunięcia'}
                                                    colClass='col-md-4'
                                                    value={this.state.element.deleteReason}
                                                    onChange={this.handleChange}
                                                    viewMode={this.state.viewMode}
                                                    rendered={status === 'DELETED'}
                                                />
                                                <InputCalendarComponent
                                                    id='nextInsp'
                                                    name='nextInsp'
                                                    value={this.state.element.nextInsp}
                                                    label={'Data przeglądu'}
                                                    colClass='col-md-4'
                                                    onChange={this.handleChange}
                                                    viewMode={this.state.viewMode}                                                    
                                                    validator={this.validator}
                                                    validators='not_required'

                                                    dateFormatMoment={this.nextInspMode.dateFormatMoment}
                                                    dateFormat={this.nextInspMode.dateFormat}
                                                    placeholder={this.nextInspMode.placeholder}
                                                    yearNavigator={this.nextInspMode.yearNavigator}
                                                    view={this.nextInspMode.view}
                                                    showIcon
                                                    showButtonBar
                                                />
                                                <InputTextareaComponent
                                                    id='notes'
                                                    name='notes'
                                                    label={'Notatka'}
                                                    colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12'
                                                    value={this.state.element.notes}
                                                    validator={this.validator}
                                                    validators='max:500'
                                                    onChange={this.handleChange}
                                                    viewMode={this.props.viewMode}
                                                />


                                            </DivContainer>
                                        </DivContainer>


                                    </Card>
                                </TabPanel>

                                {this.props.viewMode != 'NEW' ? (
                                    <TabPanel header={t('project.details.logsTabHeader')}>
                                        <LogEntryListContainer
                                            objectType='TOOL'
                                            objectId={this.props.elementId}
                                            expandFilters={false}
                                            renderHeader={false}
                                            filterOptions={true}
                                            filteredOptionsList={codeOptions}
                                        />
                                    </TabPanel>
                                ) : null}
                            </TabView>
                        </DivContainer>
                    </DivContainer>
                    :

                    <DivContainer colClass='row'>
                        <DivContainer colClass='col-md-12'>
                            <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })} >
                                <TabPanel header="Szczegóły">
                                    <Card header=''>
                                        <DivContainer colClass='row'
                                            rendered={true}
                                        >
                                            <DivContainer colClass='row'>

                                                <InputTextareaComponent
                                                    id='deleteReason'
                                                    name='deleteReason'
                                                    label={'Powód usunięcia'}
                                                    colClass='col-md-4'
                                                    value={this.state.element.deleteReason}
                                                    validator={this.validator}
                                                    onChange={this.handleChange}
                                                    viewMode={'EDIT'}

                                                />


                                            </DivContainer>
                                        </DivContainer>


                                    </Card>
                                </TabPanel>

                                {this.props.viewMode != 'NEW' ? (
                                    <TabPanel header={t('project.details.logsTabHeader')}>
                                        <LogEntryListContainer
                                            objectType='TOOL'
                                            objectId={this.props.elementId}
                                            expandFilters={false}
                                            renderHeader={false}
                                        />
                                    </TabPanel>
                                ) : null}
                            </TabView>
                        </DivContainer>
                    </DivContainer>


                }
            </React.Fragment>
        )
    }



    handleType(inputType, parameters, event, matchMode, refreshFromBackend, onAfterStateChange, timeoutHandler, delay) {
        let isDropdown = typeof event.value === 'object';
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                type: isDropdown ? event.value : {
                    id: null,
                    typeName: event.value //else string from editable
                }
            }
        }));
    }


    handleProducer(inputType, parameters, event, matchMode, refreshFromBackend, onAfterStateChange, timeoutHandler, delay) {
        let isDropdown = typeof event.value === 'object';
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                producer: isDropdown ? event.value : {
                    id: null,
                    name: event.value //else string from editable
                }
            }
        }));
    }
    handleModel(inputType, parameters, event, matchMode, refreshFromBackend, onAfterStateChange, timeoutHandler, delay) {
        let isDropdown = typeof event.value === 'object';
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                model: isDropdown ? event.value : {
                    id: null,
                    modelIdentifier: event.value //else string from editable
                }
            }
        }));
    }

    statusTemplate(status) {
        if (status === 'ACTIVE') {
            return 'Aktywne'
        } else if (status === 'DELETED') {
            return 'Usunięte'
        }
    }
    handleDelete(e) {
        const { t } = this.props;
        const { element } = this.state;
        if (this.state.element.deleteReason == null) {
            this.validator.showMessages();
            this.scrollToError = true;
            this.forceUpdate();
            return;
        }

        this.service
            .remove(element, element.deleteReason)
            .then((response) => {
                this.blockUi();
                this.afterDeleteSuccess(response);
                if (this.props.afterSave) {
                    this.props.afterSave();
                }
            })
            .catch((err) => {
                this.persistMessage('error', '', t('tool.deleteError'));
            })
            .finally(() => {
                this.setState({ deleteConfirmDialogVisible: false });
                this.unblockUi();
            });
    }

    afterDeleteSuccess(response) {
        const { backUrl } = this.props;
        this.blockUi();
        this.persistMessage('success', '', this.getRemoveSucces(response));
        window.location.href = backUrl;
    }
    setElement() {
        const { viewMode } = this.props;
        const { elementId } = this.state;
        if (elementId && (viewMode === 'EDIT' || viewMode === 'VIEW' || viewMode === 'DELETE')) {
            this.blockUi();
            this.service
                .get(elementId, viewMode)
                .then((data) => {
                    this.setState(
                        {
                            loading: false,
                            status: data.status
                        }, () => this.updateElement(data));

                })
                .catch((err) => {
                    this.handleGetDetailsError(err);
                });
        } else {
            this.setState({ loading: false }, this.initAfterSetElement());
        }
    }

    prepareElement(element) {
        if (element.model != null) {
            if (element.model.modelIdentifier==null || element.model.modelIdentifier.length == 0) {
                element.model.modelIdentifier = null;
            }
        }
        if (element.type != null) {
            if (element.type.typeName==null || element.type.typeName.length == 0) {
                element.type.typeName = null;
            }
        }
        if (element.producer != null) {
            if (element.producer.name==null || element.producer.name.length == 0) {
                element.producer.name = null;
            }
        }
        return element;
    }

    createOrUpdate() {
        const { element } = this.state;
        this.scrollToTop();
        
        if (this._isMounted) {
            if (element.id === undefined || element.id === null) {

                const preparedElement = this.prepareElement(element);

                this.service
                    .add(preparedElement)
                    .then((response) => {
                        this.afterAddSuccess(response);
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                        this.unblockUi();
                    });
            } else {
                const preparedElement = this.prepareElement(element);

                this.service
                    .update(preparedElement)
                    .then((response) => {
                        this.afterUpdateSuccess(response);
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message, 10000);
                        this.unblockUi();
                    });
            }
        }
    }


    render() {
        //return this.renderView();
        return (
            <div className='container-fluid'>
                {/* <BreadcrumbsItem to='/project-list' className='p-link'>
                    {'Szczegóły projektu'}
                </BreadcrumbsItem>
                <BreadcrumbsItem to='/project/details'>{'Podstawowe'}</BreadcrumbsItem> */}
                {this.renderView()}
                {this.state.dialogVisibile ? (
                    <ToolDeleteDialog
                        className='tool-dialog'
                        key={`tool-dialog-${this.state.element?.id}`}
                        element={this.state.element}
                        onHide={(e) => this.setState({ dialogVisibile: false })}
                        visible={this.state.dialogVisibile}
                        typeOptions={this.state.typeOptions}
                        producerOptions={this.state.producerOptions}
                        modelOptions={this.state.modelOptions}
                        viewMode={this.state.dialogViewMode}
                        afterSave={(e) => {
                            this.setState({ dialogVisibile: false });
                            this.afterObjectUpdate();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}

            </div>
        );
    }
}


ToolDetailsContainer.defaultProps = {
    backUrl: '/#/tool-list',
    cancelUrl: '/#/tool/details',
    editUrl: '/#/tool/edit',
    deleteUrl: '/#/tool/delete'
};

ToolDetailsContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withTranslation()(ToolDetailsContainer);
