import PropTypes from 'prop-types';
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { DataSet } from "vis-data"; ///peer/esm/vis-data";
import { Timeline } from "vis-timeline";
import DivContainer from "../../components/DivContainer";

import ReactDOM from 'react-dom';

import moment from 'moment';
import 'moment/min/locales';
import { Tooltip } from "primereact/tooltip";

import { t } from 'i18next';
import AccommodationService from "../../services/AccommodationService";
import BaseDetailsContainer from "../../baseContainers/BaseDetailsContainer";
import YesNoDialog from "../../components/YesNoDialog";
import Constants from "../../utils/constants";
import SelectAccommodationDialog from "./SelectAccommodationDialog";
import { Accordion, AccordionTab } from 'primereact/accordion';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import CriteriaAutocompleteComponent from '../../components/criteria/CriteriaAutocompleteComponent';

import $ from 'jquery';
import CountryService from '../../services/CountryService';
import UpdateAccommodationDialog from './UpdateAccommodationDialog';
import CriteriaCalendarRangeComponent from '../../components/criteria/CriteriaCalendarRangeComponent';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import AccommodationCategoryService from '../../services/AccommodationCategoryService'
import ProjectService from '../../services/ProjectService';
import { PrintButtonComponent } from '../../report/ReportUtils';

class EmployeeAccommodationTimelineContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new AccommodationService());        
        this.state = {
            updateDialog: false,
            updateElement: {},
            element: {
                selectProjectDialogVisible: false,
                confirmDeleteDialogVisible: false,                
            },
            countryOptions: [],
            containsAccommodationOptions: [
                {
                    label: 'Tak',
                    value: 'true'
                },
                {
                    label: 'Nie',
                    value: 'false'
                },
            ],
            config: {
                start: moment().subtract(1, 'days').startOf('day').toDate(),
                end: moment().startOf('day').add(6, 'days').toDate(),            
                min: moment().subtract(30, 'day').startOf('day').toDate(),
                max: moment().add(30, 'day').startOf('day').toDate(),
            }, criteria: {
                fullName: '',
                firstResult: 0,
                maxResults: 10,
                sortField: 'id',
                sortAsc: false,
                accommodationName: '',
                countries: [],
                project: undefined,
                categories: [],
                printDisabled: false,
                containsAccommodation: undefined,
                dateFrom: moment().subtract(14, 'day').toDate(),
                dateTo: moment().add(14, 'day').toDate()
            },
        };        
        this.handleAssignNewProject = this.handleAssignNewProject.bind(this);
        this.handleUnassignAccomodation = this.handleUnassignAccomodation.bind(this);
        this.unassignUser = this.unassignUser.bind(this);
        this.refreshTimeLine=this.refreshTimeLine.bind(this);
        
        this.users= new DataSet();
        this.bookings=new DataSet();

        this.projectService = new ProjectService();
        this.countryService = new CountryService();
        this.categoryService = new AccommodationCategoryService();

        this.setUpdateElement = this.setUpdateElement.bind(this);
        this.cleanUpdateElement = this.cleanUpdateElement.bind(this);
        this.openDialog = this.openDialog.bind(this);
        this.openUpdateDialog = this.openUpdateDialog.bind(this);
        this.renderPrintButton=this.renderPrintButton.bind(this);
    }
        

    initAfterSetElement() {
    }

    initBeforeSetElement() {
        
        this.blockUi();

        this.categoryService
            .getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
            .then((data) => this.setState({
                categories: data.content
            })).catch((err) => {
                this.unblockUi();
                this.showErrorMessage('Nie udało się pobrać listy kategorii');
            });


        this.countryService
            .getAll()
            .then((data) => this.setState({
                countryOptions: data
            })).catch((err) => {
                this.unblockUi();
                this.showErrorMessage('Nie udało się pobrać listy krajów');
            })

        this.service
            .getEmployeeAccomodationTimeline()
            .then((data) => {
                if(data?.items.length===0){
                    this.showInfoMessage('Brak rekordów');
                    this.setState({
                        printDisabled: true
                    });
                    this.convertToVisFormatAccommodation(data);
                    this.unblockUi();
                } else {
                    const config = this.state.config;
                    this.setState({
                        config: {
                            min: moment(config.min).toDate(),
                            max: moment(config.max).toDate(),
                            start: moment(config.start).toDate(),
                            end: moment(config.end).toDate()
                        },
                        defaultConfig: {
                            min: moment(config.min).toDate(),
                            max: moment(config.max).toDate(),
                            start: moment(config.start).toDate(),
                            end: moment(config.end).toDate()
                        },
                        printDisabled: false
                    });
                    this.convertToVisFormatAccommodation(data);
                }
            }).catch((err) => {
                this.unblockUi();
                this.showErrorMessage('Nie udało się pobrać danych użytkowników');
            });
    }

    refreshTimeLine(isUpdate){
        this.blockUi();

        const { criteria } = this.state;
        const { accommodationName } = criteria;
        const { fullName } = criteria;
        const { countries } = criteria;
        const { dateFrom } = criteria;
        const { dateTo } = criteria;
        const { containsAccommodation } = criteria;
        const { address } = criteria;
        const { project } = criteria;
        const {categories} =criteria;
 

        const defaultMin = this.state.defaultConfig.min;
        const defaultMax = this.state.defaultConfig.max;

        if (dateFrom) {
            this.setState((prev) => ({
                config: {
                    ...prev.config,
                    min: moment(dateFrom).startOf('day').toDate(),
                }
            }));
        } else {
            const { min } = this.state.config;
            if (min !== defaultMin) {
                this.setState((prev) => ({
                    config: {
                        ...prev.config,
                        min: defaultMin
                    }
                }));
            }
        }

        if (dateTo) {
            this.setState((prev) => ({
                config: {
                    ...prev.config,
                   max: moment(dateTo).endOf('day').toDate(),
                }
            }));
        } else {
            const { max } = this.state.config;
            if (max !== defaultMax) {
                this.setState((prev) => ({
                    config: {
                        ...prev.config,
                        max: defaultMax
                    }
                }));
            }
        }

        this.service
            .getEmployeeAccomodationTimeline(accommodationName, fullName, countries?.map((x) => x.id).join(','), containsAccommodation, dateFrom, dateTo, address, project?.id,
                categories?.map((x) => x.id).join(','))
            .then((data) => {
                if (data?.items.length === 0) {
                    this.showInfoMessage('Brak rekordów wyszukiwania');
                    this.setState({
                        printDisabled: true
                    });
                    this.convertToVisFormatAccommodation(data);
                    this.unblockUi();
                } else {
                    this.convertToVisFormatAccommodation(data);
                    isUpdate ? this.setState({ updateDialog: false, isUpdate: false, updateElement: {}, printDisabled: false }) : this.setState({ visible: false, printDisabled: false });
                    this.unblockUi();
                }
            }).catch((err) => {
                this.unblockUi();
                this.showErrorMessage('Nie udało się pobrać danych użytkowników');
            });
    }

    openDialog(empId, date) {
        const user = this.users?.get(empId);
        const day = moment(date).format(Constants.DATE_FORMAT);
        this.setState({
            visible: true,
            selectedEmpId: user.id,
            selectedStartDay: day,
            userName: user.lastName + " " + user.firstName,
            dialogElement: {
                dateStart: moment(date).startOf('day').toDate(),
                dateEnd: moment(date).add(7, 'days').toDate()
            }
        })
    }
    generateReport(format) {
        const criteria = this.state.criteria;
        switch (format) {
            case 'xlsx':
                return this.service.exportList(criteria, 'xlsx');
            default:
                return Promise.reject('Nieznany format');
        }
    }
    renderPrintButton(disabled) {
        return (
            <div style={{
                textAlign: 'right',
                color: '#3059e8',
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '14px',
            }}>           
                <PrintButtonComponent
                    key={`print-button-pay-report`}
                    reportFileNameBase={'Noclegi'}
                    xml={false}
                    pdf={false}
                    csv={false}
                    ods={false}
                    xlsx={false}
                    disabled={this.state.printDisabled}
                    globalFormat='xlsx'
                    noMenuFlag={true}
                    generateReport={this.generateReport.bind(this)}
                />
            </div>
        );
    }
    openUpdateDialog(empId) {
        const user = this.users?.get(empId);
        this.setState({
            updateDialog: true,
            selectedEmpId: user.id
        });
    }

    setUpdateElement(id) {
        const item = this.bookings.get(id);
        let empId=(typeof item.id === 'string') ?  item.group : item.id;

        const user = this.users.get({
            filter: function (item) {
                return item.id === empId
            }
        })[0];

        const fullName = user.lastName + ' ' + user?.firstName;
        this.setState({
            updateElement: {
                fullName: fullName,
                employeeId: empId,
                accomodation: {
                    empAccommodationId: item.empAccommodationId,
                    id: item.acomId,
                    name: item.label,
                    accommodationNumber: item.maxCapacity
                },
                dateStart: moment(item.start).toDate(),
                dateEnd: moment(item.end).toDate(), 
            },
            eaId: item.empAccommodationId,
            isUpdate: true
            //updateDialog: true,
        });
    }

    cleanUpdateElement(){
        this.setState({
            updateElement: {},
            updateDialog: false,
            isUpdate: false
        })
    }

    convertAcommodationToVisFormat(acomId, empAccommodationId, acomName, empId, dateStart, dateEnd,address) {
        const result = {
            id: crypto.randomUUID(),
            empAccommodationId: empAccommodationId,
            acomId: acomId,
            address: address,
            start: moment(dateStart),
            end: moment(dateEnd).startOf('day'),
            group: empId,
            label: acomName,
            editable: {
                add: false,
                remove: true,
                updateGroup: false,
                updateTime: false,
            },     
        };
        TooltipHelper.generateAccommodationTooltip(result);
        return result;
    }
    employeeAbsenceVisFormatValue(acomId, reason, empId, dateStart, dateEnd){
        const result = {
            id: crypto.randomUUID(),
            empAccommodationId: acomId,
            start: dateStart,
            end: moment(dateEnd).endOf('day'),
            group: empId,
            reason: reason ,
            label: 'Nieobecność 🌴',
            editable: {
                add: false,
                remove: false,
                updateGroup: false,
                updateTime: false,
            },          
            selectable: false,
            style: 'background-color: #ebd534;'
        };
        TooltipHelper.generateAbsenceTooltip(result);
        return result;
    }


    convertToVisFormatAccommodation(data, config) {
        this.users.clear();
        this.bookings.clear();


       data?.items.forEach(item => {
           const user = {
               id: item.id,
               firstName: item.firstName,
               lastName: item.lastName
           };
           this.users.add(user);
           let absences = item?.absences.map((absence) => {
               return this.employeeAbsenceVisFormatValue(
                   absence.id,
                   absence.reason,
                   absence.empId,
                   absence.dateFrom,
                   absence.dateTo
               );
           });
           this.bookings.add(absences);

           let acoms = item?.accommodations.map((a) => {
               let label = a.name;
               if (a?.projects && a.projects.length > 0) {
                   label += ` - ${a.projects.map((x) => x.name).join(' , ')}`
               }
               return this.convertAcommodationToVisFormat(
                   a.accommodationId,
                   a.employeeAccommodationId,
                   label,
                   a.employeeId,
                   a.dateFrom,
                   a.dateTo,
                   a.address
               );
           });
           this.bookings.add(acoms);
        })
    }

    renderBackLink() {
       
    }

    prepareFooterItems() {
        const result = super.prepareFooterItems();        
        return result;
    }


    isPdfButtonRendered() {
        return false;
    }

    getPdfDownloadLabel() {
        return null;
    }

    handleDownloadPdf() {
    }

    

    handleAssignNewProject(userId, userName) {
        this.setState({
            selectProjectDialogVisible: true,
            selectedUserId: userId,
            selectedUserName: userName        
        });
    }

    handleUnassignAccomodation(user, accommodation, callback) {
        this.confirmDeleteCallback = callback;
        this.setState({
            confirmDeleteDialogVisible: true,
            confirmDeleteUser: user,
            confirmDeleteAcommodation: accommodation,
        });
    }


    unassignUser() {
        this.blockUi();
        const empAccommodationId = this.state?.confirmDeleteAcommodation?.empAccommodationId;

        this.service
            .deleteEmployeeAcommodation(empAccommodationId)
            .then(resp => {
                    this.unblockUi();
                    this.refreshTimeLine();
            }).catch(err => {
                this.unblockUi();
                this.showErrorMessage(err.message);
            });
    }
    

    setTimeout(callback, delay) {
        if (callback && callback instanceof Function) {
            this.timeoutHandler = setTimeout(() => {
                callback();
            }, delay);
        } else {
            this.refreshTimeLine();
        }
    }
    

    handleValidForm() {
        //this.createOrUpdate();
        this.refreshTimeLine()
    }

    onKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleFormSubmit();
        }
    }
    searchProjects(query){
       return this.projectService.search(query);
    }

    renderCriteria() {
        return (
            <div className='row'>
                 <CriteriaTextComponent
                    id='accommodationName'
                    name='accommodationName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.accommodationName}
                    onChange={this.handleChangeSc}
                    //validator={this.validator}
                    label={t('accommodation.details.name')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                />
                <CriteriaTextComponent
                    id='address-sc'
                    name='address'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.address}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('accommodation.address')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                /> 

                <CriteriaMultiSelectComponent
                    id='countries'
                    name='countries'
                    label={t('accommodation.details.countryName')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.countryOptions}
                    optionLabel='name'
                    value={this.state.criteria.countries}
                    dataKey='id'
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                    filter
                    filterBy={'name'}
                    />
                 <CriteriaMultiSelectComponent
                    id='categories'
                    name='categories'
                    label={t('accommodation.categories')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.categories}
                    optionLabel='name'
                    value={this.state.criteria.categories}
                    dataKey='id'
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                    filter
                    filterBy={'name'}
                />
                <CriteriaAutocompleteComponent
                    id='project-sc'
                    name='project'
                    colClass='col-lg-4 col-sm-6'
                    label={'Projekt'}
                    value={this.state.criteria.project}
                    completeMethod={this.searchProjects.bind(this)}
                    onChange={this.handleChangeSc}
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                />
                <CriteriaTextComponent
                    id='fullName-sc'
                    name='fullName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.fullName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('accommodation.employee')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                /> 
                <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    name='date'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    label={'Data'}
                    colClass='col-xl-6 col-lg-12 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                    showButtonBar
                    sharedLabel
                    renderDash
                    maxDateInfinite
                    placeholderFrom={'Data od'}
                    placeholderTo={'Data do'}
                    inputsClassName='col-6'
                />
            <CriteriaDropdownComponent
                    id='containsAccommodations-sc'
                    name='containsAccommodation'
                    showClear
                    label={'Przypisany do noclegu'}
                    colClass='col-lg-2 col-sm-2'
                    value={this.state.criteria.containsAccommodation}
                    options={this.state.containsAccommodationOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    refreshFromBackend
                    timeoutHandler={this.refreshTimeLine}
                />
            </div>
        );
    }

    renderDetails() {
        const userName = this.state?.confirmDeleteUser?.firstName + ' ' + this.state?.confirmDeleteUser?.lastName;

        
        const tomorrow = moment().startOf('day').add(1, 'day');
        let start = moment(this.state?.confirmDeleteAcommodation?.start);
        const end = moment(this.state?.confirmDeleteAcommodation?.end);
        if (tomorrow.isSameOrAfter(start)) {
            start = tomorrow;
        }
        const period = start.format(Constants.DATE_FORMAT) + ' - ' + end.format(Constants.DATE_FORMAT);
        const accomodationName = this.state?.confirmDeleteAcommodation?.label;
        return (
            <React.Fragment>
                {this.state.updateDialog ?
                    <UpdateAccommodationDialog
                        id="selectProjectDialog"
                        key="selectProjectDialogKey"
                        visible={this.state.updateDialog}
                        onHide={() => this.setState({
                            updateDialog: false
                        })}
                        onSelect={(eaId,accommodation, dateStart, dateEnd) => {
                            // TODO
                            this.service.updateEmployeeAccommodation(eaId,this.state.selectedEmpId, accommodation, dateStart, dateEnd)
                                .then(assignment => {
                                    this.refreshTimeLine(this.state.updateDialog);
                                    this.showSuccessMessage("Pomyślnie zaktualizowano przypisany nocleg")
                                })
                                .catch(err => {
                                    this.showErrorMessage(err.message)
                                });
                        }}
                        userId={this.state?.selectedUserId}
                        eaId={this.state.eaId}
                        userName={this.state?.userName}
                        selectedEmpId={this.state.selectedEmpId}
                        selectedStartDay={this.state.selectedStartDay}
                        updateElement={this.state.updateElement}
                        element={this.state.element}
                        refresh={this.refreshTimeLine}
                    /> : this.state.visible ? 
                    <SelectAccommodationDialog
                        id="selectProjectDialog"
                        key="selectProjectDialogKey"
                        visible={this.state.visible}
                        onHide={() => this.setState({
                            visible: false,
                            updateDialog: false
                        })}
                        onSelect={(accommodation, dateStart, dateEnd) => {
                            this.service.addAcommodation(this.state.selectedEmpId, accommodation, dateStart, dateEnd)
                                .then(assignment => {
                                    this.refreshTimeLine();
                                    this.showSuccessMessage("Pomyślnie przypisano nocleg pracownikowi");
                                })
                                .catch(err => {
                                    this.showErrorMessage(err.message)
                                });
                        }}
                        userId={this.state?.selectedUserId}
                        userName={this.state?.userName}
                        selectedEmpId={this.state.selectedEmpId}
                        selectedStartDay={this.state.selectedStartDay}
                        dialogElement={this.state.dialogElement}
                        element={this.state.element}
                        refresh={this.refreshTimeLine}
                    /> : null
                }
                <YesNoDialog
                    visible={this.state.confirmDeleteDialogVisible}
                    header='Potwierdzenie'
                    name='confirmDelete'
                    noButtonVariant='white'
                    onChange={(type, x, target) => {
                        if (target.value) {
                            this.unassignUser();
                            this.showSuccessMessage("Pomyślnie usunięto przypisany nocleg")
                        }                        
                        this.setState({
                            confirmDeleteUser: null,
                            confirmDeleteProject: null,
                            confirmDeleteDialogVisible: false,
                        })
                        //this.confirmDeleteCallback = null;
                    }}
                    onHide={() => this.setState({confirmDeleteDialogVisible: false})}
                >
                    {`Czy na pewno chcesz usunąć pracownika ${userName} z noclegu ${accomodationName}, okres: ${period} ?`}
                </YesNoDialog>
                
                <DivContainer colClass='row'>
                <Accordion className='filter-accordion' activeIndex={-1}>
                <AccordionTab
                    header={
                        <React.Fragment>
                            <img className='card-icon' alt={t('list.filter')} src={`/images/ico_filter.svg`} />
                            <span>{t('list.filter')}</span>
                        </React.Fragment>
                    }
                >
                    <form
                        className='form-search-criteria'
                        onSubmit={(e) => {
                            this.handleFormSubmit(e);
                        }}
                        onKeyDown={(e) => this.onKeyDown(e)}
                        //avoid setting red border just after the page has loaded (red border because of undefined required value in FF)
                        noValidate
                    >
                        <DivContainer colClass='col-12'>
                            {this.renderCriteria()}

                        </DivContainer>
                    </form>
                </AccordionTab>
            </Accordion>
                </DivContainer>
                <DivContainer colClass='row'>

                    <AccommodationTimeline
                        users={this.users}
                        bookings={this.bookings}
                        config={this.state.config}
                        accommodations={this.accommodations}
                        onAssignNewProject={this.handleAssignNewProject}
                        onUnassignAccomodation={this.handleUnassignAccomodation}
                        onReady={this.unblockUi}
                        openDialog={this.state.isUpdate ? this.openUpdateDialog : this.openDialog}
                        isUpdateDialog={this.state.isUpdate}
                        setUpdateElement={this.setUpdateElement}
                        cleanUpdateElement={this.cleanUpdateElement}
                        renderPrintButton={this.renderPrintButton}
                    />    
                </DivContainer>                    
            </React.Fragment>
        )
    }

};

function GroupTemplate(props) {
    const group = props?.group;
    return (
        <div >
            <div style={{width:'190px'}}>
                <label style={{ wordBreak: 'break-word', display: 'inline-block' }}>
                    {group?.lastName + ' ' + group?.firstName}
                </label>
            </div>
        </div>
    )
}

class ItemTemplate extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        return (
            <div>
                {this.props?.item?.absence ? (
                    <i className="icon mdi mdi-beach p-overlay-badge" style={{ fontSize: '20px', marginRight: '14px', color: '#6c757d'}} />                    
                ) : null}
                
                <label style={{
                        fontFamily: 'Poppins',
                        fontStyle: 'normal',
                        fontWeight: 'normal',
                        fontSize: '12px',
                        lineHeight: '16px',
                        color: 'black',
                        letterSpacing: '-0.01em',
                }}>
                    {this.props.item.label}
                </label>
            </div>
        )
    }
}

class VisibleFramTemplate extends React.Component {
    constructor(props){
        super(props);
    }
    render() {
        return <div>
            id: {this.props.item.id}
            <button></button>
        </div>
    }
}

class AccommodationTimeline extends React.Component {

    constructor(props) {
        super(props);
        this.periods = [
            {code: 'WEEK', label: 'Tydzień'},
            {code: 'TWO_WEEKS', label: 'Dwa tygodnie'},
            {code: 'MONTH', label: 'Miesiąc'},
            {code: 'THREE_MONTHS', label: 'Kwartał'},
            {code: 'YEAR', label: 'Rok'}
        ];
        this.selectedPeriod = { code: 'WEEK', label: 'Tydzień' };

        this.addSelectedPeriod = (date) => {
            if (this.selectedPeriod) {
                if (this.selectedPeriod.code === 'WEEK') {
                    return moment(date).add(1, 'week').toDate();
                } else if (this.selectedPeriod.code === 'TWO_WEEKS') {
                    return moment(date).add(2, 'week').toDate();
                } else if (this.selectedPeriod.code === 'MONTH') {
                    return moment(date).add(1, 'month').toDate();
                } else if (this.selectedPeriod.code === 'THREE_MONTHS') {
                    return moment(date).add(3, 'month').toDate();
                } else if (this.selectedPeriod.code === 'YEAR') {
                    return moment(date).add(1, 'year').toDate();
                }
            }
            return date;
        }

        this.subtractSelectedPeriod = (date) => {
            if (this.selectedPeriod) {
                if (this.selectedPeriod.code === 'WEEK') {
                    return moment(date).subtract(1, 'week').toDate();
                } else if (this.selectedPeriod.code === 'TWO_WEEKS') {
                    return moment(date).subtract(2, 'week').toDate();
                } else if (this.selectedPeriod.code === 'MONTH') {
                    return moment(date).subtract(1, 'month').toDate();
                } else if (this.selectedPeriod.code === 'THREE_MONTHS') {
                    return moment(date).subtract(3, 'month').toDate();
                } else if (this.selectedPeriod.code === 'YEAR') {
                    return moment(date).subtract(1, 'year').toDate();
                }
            }
        }

        this.state = {
            visible: false,
            selectedEmpId: null,
            selectedStartDay: null,
            userName:''
        }
        this.render = this.render.bind(this);
        this.bookings = new DataSet();
        this.service = new AccommodationService();
    }
    convertAcommodationToVisFormat(acomId,empAccommodationId, acomName, empId, dateStart, dateEnd){
        const today = moment().startOf('day');
        
        const result = {
            id: crypto.randomUUID(),
            empAccommodationId: empAccommodationId,
            acomId: acomId,
            start: moment(dateStart).startOf('day').toDate(),
            end: moment(dateEnd).startOf('day'),
            group: empId,
            projectName: acomName,
            absence: false,
            editable: {
                add: false,
                remove: true,
                //moment(dateEnd).isAfter(today),
                updateGroup: false,
                updateTime: false,
            },
        };
        TooltipHelper.generateAccommodationTooltip(result);
        return result;
    }


    refreshData(data) {
        this.users.clear();
        this.bookings.clear();

        let users = data?.items.map(item => {
            let absences = item?.absences.map((absence) => {
                return this.employeeAbsenceVisFormatValue(
                    absence.id,
                    absence.reason,
                    absence.empId,
                    absence.dateFrom,
                    absence.dateTo
                );
            });
            this.bookings.add(absences);

            let acoms = item?.accommodations.map((a) => {
                return this.convertAcommodationToVisFormat(
                    a.accommodationId,
                    a.employeeAccommodationId,
                    a.name,
                    a.employeeId,
                    a.dateFrom,
                    a.dateTo
                );
            });
            this.bookings.add(acoms);

            return {
                id: item.id,
                firstName: item.firstName,
                lastName: item.lastName
            };
        });
        this.users.add(users);
    }

    componentDidMount() {
        return this.initTimeline();
    }
    
    componentDidUpdate() {
        let windows = this.timeline.getWindow();
        let options = this.generateOptions();
        // start i end z obecnego widoku, zeby nie uciekalo na sam poczatek
        options.start = windows.start;
        options.end = windows.end;
        this.timeline.setOptions(options)
    }

    componentWillUnmount() {
        if (this.alignCurrentTimeIntervalId) {
            clearInterval(this.alignCurrentTimeIntervalId);
            this.alignCurrentTimeIntervalId = null;
        }
    }

    //  TODO ustawia czy ma byc rerender

    shouldComponentUpdate(nextProps){
        if (this.timeline && nextProps.config) {
            if (this.props?.config?.start !== nextProps?.config?.start) {
                this.timeline.setOptions({
                    max: nextProps.config.max,
                    min: nextProps.config.min,
                    start: nextProps.config.start,
                });
                $('#projectManager span.p-dropdown-label.p-inputtext').text(this.selectedPeriod.label);
                const range = this.timeline.getWindow();
                if (nextProps?.config?.period) {
                    this.selectedPeriod = this?.periods.find(p => p.code === nextProps.config.period);
                    const end = this.addSelectedPeriod(range.start);
                    this.timeline.setWindow({
                        start: range.start,
                        end:   end,
                    });
                }
            }
        }
        return true; //jak jest true to dziala wtedy filtrowanie po dacie
    }
    

    generateOptions() {
        const options = {
            locale: 'pl',
            orientation: 'both',
            width: '100%',
            margin: {
                item: 20
            },
            start: this.props?.config?.start,
            end: this.props?.config?.end,
            editable: {
                add: true,
                remove: true,
                updateGroup: false,
                updateTime: false,
            },            
            showTooltips: true,
            horizontalScroll: true,
            verticalScroll: true,
            zoomKey: 'ctrlKey',
            //ograniczenie do 1 dnia
            zoomMin: 1000 * 60 * 60 * 24 * 7, //jeden dzień w ms
            zoomMax: 1000 * 60 * 60 * 24 * 7, //jeden dzień w ms
            min: this.props?.config?.min,
            max: this.props?.config?.max,
            showCurrentTime: true,
            showWeekScale: true,
            format: {
                minorLabels: {day: 'DD.MM'},
                majorLabels: {day: 'w'}
            },
            tooltip:{overflowMethod: 'cap'},
            rollingMode: {
                // empty object as a fix for missing onInitialDrawComplete event
            },
            onInitialDrawComplete: () => { // event nie zawsze byl uruchamiany      
                if (this.timeline) {
                    this.timeline.setItems(this.props.bookings);
                }
                if (this.props?.onReady) {
                    this.props.onReady();
                }
            },
            template: (item, element) => {
                if (!item) { 
                    return 
                }
                return ReactDOM.createPortal( ReactDOM.render( <ItemTemplate item={item} />, element ), element, () => { 
                    if (this.timeline) {
                        this.timeline.redraw();
                    }
                });
            },
           
            groupTemplate: (user, element) => {
                if (!user || !user.firstName) { 
                    return 
                }
                return ReactDOM.createPortal( ReactDOM.render(
                <GroupTemplate 
                    group={user} 
                    handleClick={this.assignNewProject.bind(this, user.id, user.name)}
                />, 
                    element ), element 
                );
            },

            visibleFrameTemplate: (item, element) => {
                if (!item || !element) { 
                    return 
                }
                if (element.className.indexOf('timeline-item-visible-frame') === -1) { 
                    return 
                }
                return ReactDOM.createPortal( ReactDOM.render( <VisibleFramTemplate item={item} />, element ), element );
                
            },
            loadingScreenTemplate: () => {
                return (
                    <div className="row">
                        <div className="col-12">
                            <h1>Loading...</h1>
                        </div>
                    </div>                    
                );
            },
            onAdd: (item, callback) => {
                if (this.props?.users.length > 0) {
                    const empId = item?.group
                    this.props?.openDialog(empId, item?.start);
                }
            },
            onUpdate: (item,callback)=>{
              
                this.openDialog()
            },
            onRemove: (item, callback) => {
                this.props.cleanUpdateElement();              
                if (this.props.onUnassignAccomodation) {
                    const user = this.props?.users.get(item.group);

                    const callbackWrapper = (itemId, removed, dateStart, dateEnd) => {
                        if (removed) {
                            callback({id: itemId});
                        } else {
                            callback(null);
                            item.start = moment(dateStart).startOf('day');
                            item.end = moment(dateEnd).endOf('day');
                            item.editable.remove = false;
                        }
                    }
                    if (item?.editable?.remove) {
                        this.props.onUnassignAccomodation(user, item, callbackWrapper);
                    }
                }                
            }
        }
        return options;

    }

    initTimeline() {
        const container = document.getElementById('visualization');        

        this.timeline = new Timeline(container, this.props?.bookings, this.props?.users, this.generateOptions()); 
        this.timeline.on('select', (properties) => {     
            if (properties.items.length > 0) {
                const itemId = properties.items[0];
                this.props.setUpdateElement(itemId);
            } else {
                this.props.cleanUpdateElement();
            }
        });

        //kreska z bieżącym czasem przesuwa się; więc ją sprowadzamy interwałem do początku dnia;
        this.timeline.setCurrentTime(moment().startOf('day').toDate());
        this.alignCurrentTimeIntervalId = setInterval(() => {
            if (this.timeline) {
                this.timeline.setCurrentTime(moment().startOf('day').toDate())
            }
          }, 10000);
        
    } 

    assignNewProject(userId, userName) {
        this.props.onAssignNewProject(userId, userName);
    }

    movePercentage(percentage) {
        const range = this.timeline.getWindow();
        const interval = range.end - range.start;        
        this.timeline.setWindow({
            start: range.start.valueOf() - interval * percentage,
            end:   range.end.valueOf()   - interval * percentage
        });
    }

    moveSelectedPeriod(positiveDirection) {
        const range = this.timeline.getWindow();

        const start = positiveDirection ? this.addSelectedPeriod(range.start) : this.subtractSelectedPeriod(range.start);
        const end = positiveDirection ? this.addSelectedPeriod(range.end) : this.subtractSelectedPeriod(range.end);

        this.timeline.setWindow({
            start: start,
            end: end,
        });

    }    
    

    render() {
        const label = $('#projectManager span.p-dropdown-label.p-inputtext');
        label.text(this.selectedPeriod?.label); // default label
        return (
            <div>
                <Tooltip id="timelineHeader" target=".timelineHeaderIcon" />
                <div id="visualization">
                    <div className='row' style={{ textAlign: 'center', marginBottom: '24px' }}>
                        <div className='col-3'>
                        </div>
                            <div className='col-2'>
                                <i className="timelineHeaderIcon mdi mdi-arrow-left icon-large-plus " style={{ marginRight: '14px', color: '#0d6efd'}} data-pr-tooltip="Przesuń w lewo"
                                    onClick={() => this.moveSelectedPeriod(false)}
                                />
                                <i className="timelineHeaderIcon mdi mdi-arrow-right icon-large-plus " style={{ marginRight: '14px', color: '#0d6efd'}} data-pr-tooltip="Przesuń w prawo"
                                    onClick={() => this.moveSelectedPeriod(true)}
                                />
                            </div>
                            <div>
                            {this.props.renderPrintButton()}
                        </div>       
                    </div>
                </div>
            </div>
        )
    }
};

class TooltipHelper {
    static formatAddress(address) {
        let result = [];
        if (address.country) {
            result.push(address.country?.name);
        }
        if (address.postalCode) {
            result.push(address.postalCode);
        }
        if (address.city) {
            result.push(address.city);
        }
        if (address.streetName) {
            result.push(address.streetName);
        }
        if (address.streetNumber) {
            result.push(address.streetNumber);
        }
        return result.join(', ');
    }

    static generateAccommodationTooltip(p){
        let tooltip = `<p>${p.label}</p>`;
        tooltip += `<p>${moment(p.start).format(Constants.DATE_FORMAT)} - ${moment(p.end).format(Constants.DATE_FORMAT)}</p>`;
        if (p?.address) {                    
            tooltip += `<p>${this.formatAddress(p.address)}</p>`;
        }
        p.title = tooltip;
    }
    static generateAbsenceTooltip(p){
        let tooltip = `<p>${p.reason}</p>`;
        tooltip += `<p>${moment(p.start).format(Constants.DATE_FORMAT)} - ${moment(p.end).format(Constants.DATE_FORMAT)}</p>`;
        p.title = tooltip;
    }
}



EmployeeAccommodationTimelineContainer.defaultProps = {
    backUrl: '/#/project-list',
    cancelUrl: '/#/project/details',
    editUrl: '/#/project/edit',
};

EmployeeAccommodationTimelineContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withRouter(withTranslation()(EmployeeAccommodationTimelineContainer));
