import PropTypes from 'prop-types';
import {ColorPicker} from 'primereact/colorpicker';

const formats = {
    RGB: 'rgb',
    HSB: 'hsb',
    HEX: 'hex',
};

export const ColorPickerComponent = ({
    viewMode,
    onChange,
    value,
    format,
    rendered,
    pickerClassName,
    componentClassName,
    outsideComponentClassName,
    label,
}) => {
    return (
        rendered && (
            <div className={`${outsideComponentClassName} form-group cpc-form-group`}>
                <div className='input_easy_label row pl-0'>
                    {label !== '' ? (
                        <label id={`-label-id`} class='p-label  cpc'>
                            {label}
                        </label>
                    ) : null}{' '}
                </div>
                <div className={componentClassName}>
                    <ColorPicker
                        defaultColor='#808080'
                        className={pickerClassName}
                        value={value}
                        onChange={(e) => {
                            e.value = '#' + e.value;
                            onChange(e);
                        }}
                        format={format}
                        disabled={viewMode === 'VIEW'}
                    />
                </div>
            </div>
        )
    );
};

ColorPickerComponent.defaultProps = {
    value: undefined,
    format: formats.HEX,
    rendered: true,
    viewMode: 'VIEW',
    outsidePickerClassName: 'col-md-12',
    pickerClassName: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    componentClassName: 'col-12',
    onChange: () => {},
};

ColorPickerComponent.propTypes = {
    onChange: PropTypes.func.isRequired,
    viewMode: PropTypes.string,
    rendered: PropTypes.bool,
    value: PropTypes.string,
    pickerClassName: PropTypes.string,
    componentClassName: PropTypes.string,
    format: PropTypes.string,
    label: PropTypes.string,
};

export default ColorPickerComponent;
