/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-max-props-per-line */
import PropTypes from 'prop-types';
import React from 'react';

export const MenuItemIcon = (props) => {
    const {alt, className, iconSvg, id, handleClick} = props;
    React.useEffect(() => {
        const {eventListeners} = props;
        if (eventListeners) {
            eventListeners.forEach((e) => {
                const element = document.getElementById(e.eventElementId);
                if (element) {
                    element.addEventListener(e.eventName, () => e.fnc());
                }
            });
            return () => {
                eventListeners.forEach((e) => {
                    const element = document.getElementById(e.eventElementId);
                    if (element) {
                        element.removeEventListener(e.eventName, () => e.fnc());
                    }
                });
            };
        }
    }, []);
    return <img id={id} className={className} alt={alt} src={`/images/${iconSvg}.svg`} onClick={handleClick} />;
};

MenuItemIcon.defaultProps = {};

MenuItemIcon.propTypes = {
    id: PropTypes.string,
    alt: PropTypes.string.isRequired,
    className: PropTypes.string,
    iconSvg: PropTypes.string.isRequired,
    handleClick: PropTypes.func,
    eventListeners: PropTypes.array,
};

export default MenuItemIcon;
