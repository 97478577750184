import FileSaver, { saveAs } from 'file-saver';
import $ from 'jquery';
import { parse } from 'json2csv';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import { Menu } from 'primereact/menu';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import XLSX from 'xlsx';
import ActionLink from '../components/ActionLink';
import { CommonReportUtils } from './CommonReportUtils';
import { poppinsBold } from './Poppins-Regular-bold';
import { poppinsRegular } from './Poppins-Regular-normal.js';
/**
 * @param report.validations Array with validations
 * @param report.id Id of a document
 * @param raport.verificationDate Date of autoverification
 */

export class PrintButtonComponent extends Component {
    constructor(props) {
        super(props);
        const items = [];
        if (props.pdf === true) {
            items.push({
                label: 'PDF',
                icon: 'pi',
                key: 'pdf',
                command: (e) => this.handlePrint('pdf', e),
            });
        }
        if (props.xlsx === true) {
            items.push({
                label: 'XLSX',
                icon: 'pi',
                key: 'xlsx',
                command: (e) => this.handlePrint('xlsx', e),
            });
        }
        if (props.csv === true) {
            items.push({
                label: 'CSV',
                icon: 'pi',
                key: 'csv',
                command: (e) => this.handlePrint('csv', e),
            });
        }
        if (props.ods === true) {
            items.push({
                label: 'ODS',
                icon: 'pi',
                key: 'ods',
                command: (e) => this.handlePrint('ods', e),
            });
        }
        this.state = {
            items,
        };
        this.menuRef = React.createRef();
        this.handlePrint = this.handlePrint.bind(this);
    }

    componentDidMount() {
        // eslint-disable-next-line no-undef
        $('a.p-menuitem-link').removeAttr('href');
    }

    onButtonClick(event) {
        if (event) {
            event.preventDefault();
        }
        if(this.props.noMenuFlag){
            this.handlePrint(this.props.globalFormat, event.value);
            return;
        }
        this.menuRef.current.toggle(event);
    }

    handlePrint(format, event) {
        if (event) {
            event.originalEvent.preventDefault();
        }
        const {reportFileNameBase, generateReport} = this.props;
        if (generateReport) {
            generateReport(format).then((blob) => {
                const now = moment().format('YYYYMMDD_HHmmss');
                const fileName = `${reportFileNameBase}_${now}.${format}`;
                saveAs(blob, fileName);
            });
        }
    }

    render() {
        return (
            <div className=''>
                {this.props.noMenuFlag ? null : 
                <Menu model={this.state.items} popup ref={this.menuRef} appendTo={document.body} />
                }

                <ActionLink
                    label={this.props.label ? this.props.label : 'Pobierz'}
                    className={`${this.props.className} header-item download-header-link`}
                    handleClick={this.props.onButtonClick ? 
                    this.props.onButtonClick.bind(this) : this.onButtonClick.bind(this)}
                    disabled={this.props.disabled}
                    iconSvg='ico_download_link_icon'
                    iconSide='right'
                    key={this.props.id}
                />
            </div>
        );
    }
}

PrintButtonComponent.defaultProps = {
    csv: true,
    pdf: true,
    xlsx: true,
    ods: true,
    reportFileNameBase: 'raport',
};

PrintButtonComponent.propTypes = {
    csv: PropTypes.bool,
    generateReport: PropTypes.func.isRequired,
    label: PropTypes.string,
    pdf: PropTypes.bool,
    reportFileNameBase: PropTypes.string,
    xml: PropTypes.bool,
};

function footer(doc, t) {
    for (let i = 0; i < doc.internal.getNumberOfPages(); i++) {
        doc.setPage(i);
        doc.setFontSize(10);

        doc.setFont('poppins');
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;
        const footerY = pageHeight - CommonReportUtils.footerHeigth;
        const footerText = t('report.footer');
        const width = doc.getTextWidth(footerText);
        doc.text(
            footerText,
            // margin / 2,
            pageWidth / 2 - width / 2,
            footerY
            // 'center'
        );
    }
}
function callAddFontToDoc(fontName, fontType, font) {
    let callAddFont = function () {
        this.addFileToVFS(`${fontName}-${fontType}.ttf`, font);
        this.addFont(`${fontName}-${fontType}.ttf`, fontName, fontType);
    };
    jsPDF.API.events.push(['addFonts', callAddFont]);
}
export const baseReportCsv = (list, fields) => {
    const opts = {fields};
    const csv = parse(list, opts);
    return new Blob([csv], {type: 'application/csv'});
};
export const baseXLSXReport = (request, format = 'xlsx') => {
    let wb = XLSX.utils.book_new();
    const table = [];
    table.push(request.columns.map((c) => c.header));
    request.list.forEach((item, index) => {
        const currentItemList = [];
        request.columns.forEach((column, colIndex) => {
            if (column.getColFunction) {
                currentItemList.push(column.getColFunction(item));
            } else {
                currentItemList.push(item[`${column.field}`]);
            }
        });

        table.push(currentItemList);
    });
    let ws = XLSX.utils.aoa_to_sheet(table);

    XLSX.utils.book_append_sheet(wb, ws, request.sheetName);

    return new Promise((resolve) => {
        resolve(new Blob([XLSX.write(wb, {type: 'array', bookType: `${format}`})], {type: `application/${format}`}));
    });
};
export const currencyFormat = (fieldName, t, rowData) => {
    if (rowData && fieldName && rowData[`${fieldName}`]) {
        let result = rowData[`${fieldName}`].toFixed(2);
        return `${result} ${t('currency.pln')}`;
    } else return `0,00 ${t('currency.pln')}`;
};
export const amountNet = (withCurrency, t, rowData) => {
    let result = '';
    if (rowData.sapDocument) {
        result = rowData.amountNetto?.toFixed(2);
    } else {
        result = (rowData.amount - rowData.amountVat)?.toFixed(2);
    }
    if (isNaN(result)) {
        result = 0;
        result = result.toFixed(2);
    }
    return !!withCurrency ? `${result} ${t('currency.pln')}` : parseFloat(result);
};
export const baseReportPdf = (request, t) => {
    callAddFontToDoc('poppins', 'normal', poppinsRegular);

    const doc = !request.landscape
        ? new jsPDF()
        : new jsPDF({
              orientation: 'landscape',
          });
    const margin = 15;
    const lineHeight = 5;
    let currentY = 15;

    doc.setFont('poppins', 'normal');
    doc.setFontSize(14);
    const title = request.title;
    currentY += CommonReportUtils.createRectWithText(
        title,
        margin / 2,
        margin / 2 + 25,
        !request.landscape ? 195 : 283,
        12,
        doc,
        CommonReportUtils.COLORS.BASIC,
        CommonReportUtils.COLORS.WHITE
    );
    doc.setFontSize(12);
    currentY += lineHeight * 1;

    const colStyles = {};
    request.columns.forEach((col, index) => {
        colStyles.index = {cellWidth: col.width};
    });
    const table = [];

    request.list.forEach((item, index) => {
        const currentItemList = [];
        request.columns.forEach((column, colIndex) => {
            if (column.getColFunction) {
                currentItemList.push(column.getColFunction(item));
            } else {
                currentItemList.push(item[`${column.field}`]);
            }
        });

        table.push(currentItemList);
    });

    doc.autoTable({
        startY: currentY,
        rowPageBreak: 'avoid',
        head: [request.columns.map((c) => c.header)],
        margin: {left: margin / 2, right: margin / 2, bottom: 40},
        body: table,
        styles: {font: 'poppins', halign: 'left'},
        headStyles: {fontStyle: 'poppins', fillColor: CommonReportUtils.COLORS.BASIC},
    });
    currentY = doc.autoTable.previous.finalY + 10;

    footer(doc, t);
    return new Promise((resolve) => {
        resolve(new Blob([doc.output('blob')], {type: 'application/pdf'}));
    });
};

const pinCardPdf = (request, t) => {
    callAddFontToDoc('poppins', 'normal', poppinsRegular);
    callAddFontToDoc('poppins', 'bold', poppinsBold);
    const doc = new jsPDF();
    const margin = 15;
    const lineHeight = 5;
    let currentY = 15;
    const pageWidth = 195;

    doc.setFont('poppins', 'bold');

    doc.setFontSize(14);

    const title = t('cards.contentListReport.title');
    currentY += CommonReportUtils.createCenteredText(title, margin / 2, margin / 2 + 25, pageWidth, 12, doc);
    doc.setFont('poppins', 'normal');
    const fontSize = 12;
    doc.setFontSize(fontSize);
    currentY += lineHeight * 1;

    const colStyles = {};
    request.columns.forEach((col, index) => {
        colStyles.index = {cellWidth: col.width};
    });
    const table = [];

    request.list.forEach((item, index) => {
        const currentItemList = [];
        request.columns.forEach((column, colIndex) => {
            if (column.getColFunction) {
                currentItemList.push(column.getColFunction(item));
            } else {
                currentItemList.push(item[`${column.field}`]);
            }
        });

        table.push(currentItemList);
    });

    doc.autoTable({
        startY: currentY,
        rowPageBreak: 'avoid',
        head: [request.columns.map((c) => c.header)],
        margin: {left: margin / 2, right: margin / 2, bottom: 40},
        body: table,
        styles: {font: 'poppins', halign: 'left', lineWidth: 0.1, lineColor: CommonReportUtils.COLORS.BLACK},
        headStyles: {
            fontStyle: 'poppins',
            fontType: 'bold',
            textColor: CommonReportUtils.COLORS.BLACK,
            fillColor: CommonReportUtils.COLORS.LIGHT,
        },
        theme: 'grid',
    });
    currentY = doc.autoTable.previous.finalY + 50;

    const exportingPerson = t('cards.contentListReport.exportingPerson');
    const importingPerson = t('cards.contentListReport.importingPerson');
    const importingPersonWidth = doc.getTextWidth(importingPerson);
    const dateAndSign = t('cards.contentListReport.dateAndSign');
    const dateAndSignWidth = doc.getTextWidth(dateAndSign);

    const rightTextsOffset = importingPersonWidth > dateAndSignWidth ? importingPersonWidth : dateAndSignWidth;

    doc.text(exportingPerson, margin, currentY, 'left');
    doc.text(importingPerson, pageWidth - margin - rightTextsOffset, currentY, 'left');
    currentY += fontSize;
    doc.text(`(${dateAndSign})`, pageWidth - margin - rightTextsOffset, currentY, 'left');

    return new Promise((resolve) => {
        resolve(new Blob([doc.output('blob')], {type: 'application/pdf'}));
    });
};

export const createPinCardPdf = (list, columns, t, callback) => {
    try {
        const title = `${t('cards.contentListReport.title')}`;
        pinCardPdf({list: list, columns, title}, t).then((blob) => {
            FileSaver.saveAs(blob, title);
            if (callback) callback();
        });
    } catch (err) {
        console.log(err);
        if (callback) callback();
    }
};

/* export const createZipFromReportList = (list, columns, t, callback) => {
    try {
        const blobs = [];
        list.forEach((item) => {
            const title = `${t('cards.contentListReport.title')}-${item.cardNumber}`;
            blobs.push({blob: pinCardPdf({list: [item], columns, title}, t), fileName: title});
        });
        exportZip(blobs, t('cards.contentListReport.zipTitle'), callback);
    } catch (err) {
        console.log(err);
        if (callback) callback();
    }
}; */

/* const exportZip = (blobs, zipFileName, callback) => {
    const zip = JsZip();
    blobs.forEach((blobItem) => {
        zip.file(`${blobItem.fileName}.pdf`, blobItem.blob);
    });
    zip.generateAsync({type: 'blob'}).then((zipFile) => {
        const currentDate = new Date().getTime();
        const fileName = `${zipFileName}-${currentDate}.zip`;
        if (callback) {
            callback();
        }
        return FileSaver.saveAs(zipFile, fileName);
    });
}; */
