import React from 'react';

const PdfDownloadSpinner = () => {
    return (
        <div className="pdf-download-spinner-container">
            <div id="pdf-download-spinner" className="pdf-download-spinner"></div>
        </div>
    );
};

export default PdfDownloadSpinner;
