import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withRouter} from 'react-router-dom';
import AuthService from '../../services/AuthService';
import DivContainer from '../DivContainer';

class FooterComponent extends Component {
    constructor(props) {
        super(props);
        this.authService = new AuthService();
    }

    render() {
        if (this.authService.loggedIn()) {
            return (
                <React.Fragment>
                    <DivContainer colClass='container-fluid footer mmmm'>
                        <DivContainer colClass='col-12'>
                            <div className='justify-content-center d-flex'>
                                <div style={{height: '30px'}} />
                                <img src='images/banner.png' className='justify-content-center' alt=''></img>
                            </div>
                        </DivContainer>
                    </DivContainer>
                </React.Fragment>
            );
        } else {
            return <React.Fragment></React.Fragment>;
        }
    }
}

export default withTranslation()(withRouter(FooterComponent));
