import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import {ActionButton} from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import InputTextComponent from '../components/inputs/InputTextComponent';
import SubcontractorService from '../services/SubcontractorService';
import ActionLink from '../components/ActionLink';
import InputAutocompleteComponent from '../components/inputs/InputAutocompleteComponent';
import ExternalSupervisorService from '../services/ExternalSupervisorService';
import { t } from 'i18next';

class ExternalSupervisorEditDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new ExternalSupervisorService());
        this.state = {
            elementId: props.elementId,
            element: {
                firstName: '',
                lastName: '',
                email: '',
                phoneNumber: '',
                roleType: undefined,
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            },
            subcontractors: [],
            viewMode: this.props.viewMode,
        };
        this.subcontractorService = new SubcontractorService();
        this.searchSubcontractors = this.searchSubcontractors.bind(this);
    }
    searchSubcontractors(query) {
        this.subcontractorService
            .getList({firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true, status: { enumValue: 'ACTIVE' }})
            .then((subcontractors) => {
                this.setState({subcontractors: subcontractors.content});
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy podwykonawców');
            });
    }

    initBeforeSetElement() {
        super.initBeforeSetElement();
        this.subcontractorService
            .getList({firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true})
            .then((subcontractors) => {
                this.setState({subcontractors: subcontractors.content});
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy podwykonawców');
            });
    }

    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide(undefined);
                    }}
                />
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('internaluser.addButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.viewMode !== 'DELETE'}
                />
            </div>
        );
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12 transaction-dialog-header'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('externalSupervisor.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('externalSupervisor.editHeader')}`
                            : this.state.viewMode === 'DELETE'
                            ? `${t('externalSupervisor.deleteHeader')}`
                            : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    updateElement(data) {
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    setElement() {
        if (this.props.element) {
            this.updateElement(this.props.element);
        }
    }
    getContainerListName() {
        return this.props.isFromProfile ? 'profile' : 'user-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('externalSupervisor.addUserSuccess');
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('externalSupervisor.updateUserSuccess');
    }
    createOrUpdate() {
        const {element} = this.state;
        this.scrollToTop();
        if (this.props._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getAddSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getUpdateSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            }
        }
    }

    renderDetails() {
        const {t} = this.props;
        return this.state.viewMode === 'DELETE' ? (
            <DivContainer>
                <label id={`delete-driver-label-id`} className='p-label' htmlFor={'delete-driver'} style={{width: '100%'}}>
                    {t('details.deleteConfirmation')}
                </label>
                <span
                    aria-label={t('details.deleteConfirmation')}
                    aria-labelledby={`delete-user--label-id`}
                    className={`p-inputtext-view icon_text element-to-remove`}
                >
                    {this.state.element.firstName} {this.state.element.lastName}
                </span>
            </DivContainer>
        ) : (
            <React.Fragment>
        
                <DivContainer colClass='row mt-4'>
                    <InputTextComponent
                        id='firstName'
                        name='firstName'
                        label={t('internaluser.dialog.firstName')}
                        colClass='col-md-6'
                        value={this.state.element.firstName}
                        validator={this.validator}
                        validators='required|alpha_space_dash|max:50'
                        onChange={this.handleChange}
                        showLabel={true}
                        keyfilter={/^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż\s-]*$/i}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='lastName'
                        name='lastName'
                        label={t('internaluser.dialog.lastName')}
                        colClass='col-md-6'
                        value={this.state.element.lastName}
                        validator={this.validator}
                        validators='required'
                        onChange={this.handleChange}
                        showLabel={true}
                        keyfilter={/^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż\s-]*$/i}
                        viewMode={this.props.viewMode}
                    />
                    <InputTextComponent
                        id='phoneNumber'
                        name='phoneNumber'
                        label={t('employee.details.phoneNumber')}
                        colClass='col-md-6'
                        value={this.state.element.phoneNumber}
                        validator={this.validator}
                        validators='required|phone|max:20'
                        onChange={this.handleChange}
                        showLabel={true}
                        //keyfilter={/^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż\s-]*$/i}
                        viewMode={this.props.viewMode}
                    />
                    <div className='col-md-5 mt-1'>
                        <InputAutocompleteComponent
                            id={`subcontractor`}
                            label='Firma'
                            name='subcontractor'
                            value={this.state.element.subcontractor}
                            dropdown
                            forceSelection
                            validator={this.validator}
                            validators='not_required'
                            onChange={this.handleChange}
                            insideTable
                            disabled={this.props.isFromProfile}
                            filteredList={this.state.subcontractors}
                            filterList={(query) => this.searchSubcontractors(query)}
                            field='name'
                            minLength={3}
                            placeholder={'Znajdź podwykonawce'}
                            showLabel={true}
                            viewMode={this.props.viewMode}
                        />
                    </div>
                    <div className='col-md-1 mt-5'>
                        <ActionLink
                            iconName='mdi-delete'
                            iconSide='left'
                            iconSize='icon-large'
                            rendered={this.props.viewMode}
                            handleClick={() => {
                                this.setState((prevState) => ({
                                    element: {
                                        ...prevState.element,
                                        subcontractor: undefined,
                                    },
                                }));
                            }}
                            variant='blue'
                            className='edit-blue-link'
                            key={'delete-button'}
                        />
                    </div>
                </DivContainer>
                {/* <DivContainer colClass='row'>
                    <div className='col-md-6 mt-3'>
                        <InputAutocompleteComponent
                            id={`subcontractor`}
                            label='Firma'
                            name='subcontractor'
                            value={this.state.element.subcontractor}
                            dropdown
                            forceSelection
                            viewMode={this.props.viewMode}
                            validator={this.validator}
                            validators='not_required'
                            onChange={this.handleChange}
                            insideTable
                            disabled={this.props.isFromProfile}
                            filteredList={this.state.subcontractors}
                            filterList={(query) => this.searchSubcontractors(query)}
                            field='name'
                            minLength={3}
                            placeholder={'Znajdź podwykonawce'}
                            showLabel={true}
                        />
                    </div>
                    <div className='col-md-2 mt-4'>
                        <ActionLink
                            iconName='mdi-delete'
                            iconSide='left'
                            iconSize='icon-large'
                            rendered={this.props.isFromProfile ? false : this.props.viewMode !== 'VIEW'}
                            handleClick={() => {
                                this.setState((prevState) => ({
                                    element: {
                                        ...prevState.element,
                                        subcontractor: undefined,
                                    },
                                }));
                            }}
                            variant='blue'
                            className='edit-blue-link'
                            key={'delete-button'}
                        />
                    </div>
                </DivContainer> */}
            </React.Fragment>
        );
    }
}

ExternalSupervisorEditDialog.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
    visible: false,
    isFromProfile: false,
    stationId: undefined,
};

ExternalSupervisorEditDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    isFromProfile: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
    roleTypes: PropTypes.object,
};

export default withTranslation()(ExternalSupervisorEditDialog);
