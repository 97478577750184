import {InputMask} from 'primereact/inputmask';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseInputComponent from './BaseInputComponent';

class InputMaskComponent extends BaseInputComponent {
    renderView() {
        const {colClass, id, insideTable, label, publicMode, showLabel, value, validateViewMode, validator, validators} = this.props;
        return publicMode ? (
            <div className='input_easy_label row pl-0'>
                {label !== undefined && showLabel ? (
                    <label id={`${id}-label-id`} className='easy_label col-lg-2 col-md-3' htmlFor={id}>
                        {label}
                    </label>
                ) : null}
                <div className='col-md-5'>
                    <span aria-label={label} aria-labelledby={`${id}-label-id`} className='p-inputtext-view'>
                        {value}
                    </span>
                    {validateViewMode && validator ? validator.message(id, label, value, validators) : null}
                </div>
            </div>
        ) : (
            <div className={insideTable ? '' : colClass}>
                <div className={insideTable ? '' : 'row'}>
                    <div className={insideTable ? '' : 'col-md-12 form-group'}>
                        {label !== undefined && showLabel ? (
                            <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                                {label}
                            </label>
                        ) : null}
                        <span aria-label={label} aria-labelledby={`${id}-label-id`} className='p-inputtext-view'>
                            {value}
                        </span>
                        {validateViewMode && validator ? validator.message(id, label, value, validators) : null}
                    </div>
                </div>
            </div>
        );
    }

    renderEdit() {
        return this.renderNew();
    }

    renderNew() {
        const {
            colClass,
            disabled,
            id,
            insideTable,
            label,
            name,
            onAfterStateChange,
            onChange,
            mask,
            placeholder,
            publicMode,
            showLabel,
            stateField,
            validator,
            validators,
            value,
            onBlur,
        } = this.props;
        const required = validators !== undefined && validators.includes('required') && !validators.includes('not_required');
        return publicMode ? (
            <div className='input_easy_label row pl-0'>
                {label !== undefined && showLabel ? (
                    <label id={`${id}-label-id`} className='easy_label col-lg-2 col-md-3' htmlFor={id}>
                        {label}
                    </label>
                ) : null}
                <div className='col-md-5'>
                    <InputMask
                        aria-describedby={`${id}-error`}
                        aria-label={label}
                        aria-labelledby={label === undefined && showLabel ? `${id}-label-id` : undefined}
                        key={id}
                        id={id}
                        name={name}
                        type='text'
                        mask={mask}
                        placeholder={placeholder}
                        style={{width: '100%'}}
                        value={value}
                        onChange={(e) => (onChange ? onChange('MASK', undefined, e, onAfterStateChange, stateField) : null)}
                        disabled={disabled}
                        required={required}
                        onBlur={onBlur}
                    />
                    <div aria-live='assertive'>{validator ? validator.message(id, label, value, validators) : null}</div>
                </div>
            </div>
        ) : (
            <div className={insideTable ? '' : colClass}>
                <div className={insideTable ? '' : 'row'}>
                    <div className={insideTable ? '' : 'col-md-12 form-group'}>
                        {label !== undefined && showLabel ? (
                            <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                                {label}
                            </label>
                        ) : null}
                        <InputMask
                            aria-describedby={`${id}-error`}
                            aria-label={label}
                            aria-labelledby={label === undefined && showLabel ? `${id}-label-id` : undefined}
                            key={id}
                            id={id}
                            name={name}
                            type='text'
                            mask={mask}
                            placeholder={placeholder}
                            style={{width: '100%'}}
                            value={value}
                            onChange={(e) => (onChange ? onChange('MASK', undefined, e, onAfterStateChange, stateField) : null)}
                            disabled={disabled}
                            required={required}
                            onBlur={onBlur}
                        />
                        <div aria-live='assertive'>{validator ? validator.message(id, label, value, validators) : null}</div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {rendered, viewMode} = this.props;
        if (rendered) {
            switch (viewMode) {
                case 'NEW':
                    return this.renderNew();
                case 'EDIT':
                    return this.renderEdit();
                case 'VIEW':
                default:
                    return this.renderView();
            }
        } else {
            return null;
        }
    }
}

InputMaskComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    disabled: false,
    insideTable: false,
    placeholder: '',
    publicMode: false,
    rendered: true,
    showLabel: true,
    stateField: 'element',
    validateViewMode: false,
    validators: 'required|alpha_num_dash_space',
    viewMode: 'VIEW',
};

InputMaskComponent.propTypes = {
    colClass: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.string.isRequired,
    insideTable: PropTypes.bool,
    label: PropTypes.string.isRequired,
    mask: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onAfterStateChange: PropTypes.func,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    publicMode: PropTypes.bool,
    rendered: PropTypes.bool,
    showLabel: PropTypes.bool,
    stateField: PropTypes.string,
    validateViewMode: PropTypes.bool,
    validator: PropTypes.object,
    validators: PropTypes.string,
    value: PropTypes.string,
    viewMode: PropTypes.string,
    onBlur: PropTypes.func,
};

export default withTranslation()(InputMaskComponent);
