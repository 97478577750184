import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import { t } from 'i18next';
import ActionLink from '../components/ActionLink';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Column } from 'primereact/column';
import NumberUtils from '../utils/NumberUtils';
import PayReportService from '../services/PayReportService';
import { DataTable } from 'primereact/datatable';
import { Ripple } from 'primereact/ripple';
import { classNames } from 'primereact/utils';
import { Dropdown } from 'react-bootstrap';
import DivContainer from '../components/DivContainer';
import { PrintButtonComponent } from '../report/ReportUtils';


class PayReportListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new PayReportService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dialogVisibile: false,
        };
    }

    updateSearchCriteria(criteria) {
        return {
            fullName: criteria.fullName,
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            exportType: criteria.exportType,
        };
    }

    getCleanSearchCriteria() {
        return {
            fullName: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'month_',
            sortAsc: false,
            exportType: null,
        };
    }

    getCriteriaName() {
        return 'payReport-list-sc';
    }

    getContainerListName() {
        return 'payReport-list-container';
    }
    getListTitle() {
        return 'payReport.listName';
    }
    footerGroup() {
        const { summary } = this.state;
        const formatter = new Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const formattedNumber = formatter.format(summary.sumOfAmount);

        return (
            <ColumnGroup>
                <Row>
                    <Column footer={t('list.summary')} />
                    <Column footer={null} footerStyle={{ textAlign: 'right', paddingRight: '0px' }} />
                    <Column footer={this.formatTimeIfPossible(summary.sumOfWorkTime)} footerStyle={{ textAlign: 'right', paddingRight: '16px' }} />
                    <Column footer={null} footerStyle={{ textAlign: 'right', paddingRight: '0px' }} />
                    <Column footer={this.formatTimeIfPossible(summary.sumOfEmpDriveTime)} footerStyle={{ textAlign: 'right', paddingRight: '16px' }} />
                    <Column footer={null} footerStyle={{ textAlign: 'right', paddingRight: '0px' }} />
                    <Column footer={this.formatTimeIfPossible(summary.sumLeadDriveTime)} footerStyle={{ textAlign: 'right', paddingRight: '16px' }} />
                    <Column footer={null} footerStyle={{ textAlign: 'right', paddingRight: '0px' }} />
                    <Column footer={this.formatTimeIfPossible(summary.sumExtraDriveTime)} footerStyle={{ textAlign: 'right', paddingRight: '16px' }} />
                    <Column footer={null} footerStyle={{ textAlign: 'right', paddingRight: '0px' }} />
                    <Column footer={formattedNumber} footerStyle={{ textAlign: 'right', paddingRight: '10px' }} />
                </Row>
            </ColumnGroup>
        );
    }

    renderDataTable(columns, list, size) {
        const { criteria, first, loading } = this.state;
        if (list === null || list === undefined) {
            list = this.props.list ? this.props.list : this.state.list;
        }
        if (size === null || size === undefined) {
            size = this.props.list ? this.props.size : this.state.size;
        }
        const dynamicColumns = columns.map((col) => {
            return (
                <Column
                    selectionMode={col.selectionMode}
                    key={col.key ? col.key : col.field}
                    field={col.field}
                    filter
                    filterField={col.field}
                    showFilterMenu={false}
                    header={col.header}
                    body={col.body}
                    bodyClassName={col.bodyClassName}
                    headerClassName={col.headerClassName}
                    className={col.className}
                    sortable={col.sortable}
                    sortField={col.sortField !== undefined ? col.sortField : col.field}
                    style={col.style}
                    headerStyle={col.headerStyle}
                    bodyStyle={
                        col.bodyStyle
                    }
                    align={col.align}
                />
            );
        });
        const rowsPerPageOptions = [5, 10, 20, 50, 100];
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <div className='datatable-responsive'>
                        <DataTable
                            id='dataTable'
                            key='data-table'
                            emptyMessage={t('list.empty')}
                            className='p-datatable-responsive'
                            breakpoint='10px'
                            value={list}
                            paginator
                            footerColumnGroup={this.generateCustomFooterIfPossible()}
                            globalFilterFields={this.globalFilterFields()}
                            filters={this.state.filters}
                            // filterDisplay='row'
                            rows={criteria.maxResults}
                            totalRecords={size}
                            first={first}
                            rowsPerPageOptions={rowsPerPageOptions}
                            sortField={criteria.sortField}
                            sortOrder={criteria.sortAsc ? 1 : -1}
                            loading={loading}
                            paginatorPosition='bottom'
                            currentPageReportTemplate={
                                size !== 0
                                    ? `Pozycje od ${first + 1} do ${first + criteria.maxResults > size ? size : first + criteria.maxResults
                                    } z ${size} łącznie`
                                    : '0 pozycji'
                            }
                            paginatorTemplate={{
                                layout: 'RowsPerPageDropdown PrevPageLink PageLinks NextPageLink CurrentPageReport',
                                PrevPageLink: (options) => {
                                    if (criteria.firstResult === 0) {
                                        return null;
                                    } else {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={options.onClick}
                                                disabled={options.disabled}
                                            >
                                                <span className='p-paginator-icon pi pi-angle-left'></span>
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                                NextPageLink: (options) => {
                                    if (criteria.firstResult + criteria.maxResults >= size) {
                                        return null;
                                    } else {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={options.onClick}
                                                disabled={options.disabled}
                                            >
                                                <span className='p-paginator-icon pi pi-angle-right'></span>
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                                RowsPerPageDropdown: (options) => {
                                    return this.renderPagination(rowsPerPageOptions, options);
                                },
                                CurrentPageReport: (options) => {
                                    return <div className='p-paginator-dummy' />;
                                },
                                PageLinks: (options) => {
                                    if (options.view.startPage === options.page && options.view.startPage !== 0) {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={() => {
                                                    this.handlePage({ first: 0, rows: this.state.criteria.maxResults });
                                                }}
                                            >
                                                {1}
                                                <Ripple />
                                            </button>
                                        );
                                    } else if (options.view.endPage === options.page && options.view.endPage !== options.totalPages - 1) {
                                        return (
                                            <button
                                                type='button'
                                                className={options.className}
                                                onClick={() => {
                                                    this.handlePage({
                                                        first: (options.totalPages - 1) * this.state.criteria.maxResults,
                                                        rows: this.state.criteria.maxResults,
                                                    });
                                                }}
                                            >
                                                {options.totalPages}
                                                <Ripple />
                                            </button>
                                        );
                                    } else if (
                                        (options.view.startPage + 1 === options.page && options.view.startPage !== 0) ||
                                        (options.view.endPage - 1 === options.page &&
                                            options.view.endPage !== options.totalPages - 1 &&
                                            options.page !== options.totalPages - 1)
                                    ) {
                                        const className = classNames(options.className, { 'p-disabled': true });
                                        return (
                                            <span className={className} style={{ userSelect: 'none' }}>
                                                ...
                                            </span>
                                        );
                                    } else {
                                        return (
                                            <button type='button' className={options.className} onClick={options.onClick}>
                                                {options.page + 1}
                                                <Ripple />
                                            </button>
                                        );
                                    }
                                },
                            }}
                            pageLinkSize={this.getPageLinkSize()}
                            lazy={this.props.lazy}
                            onPage={this.handlePage}
                            onSort={this.handleSort}
                            selectionMode={'checkbox'}
                            onSelectionChange={this.handleOnSelectionChange}
                            selection={this.state.selectedList}
                            selectAll={this.state.selectAll}
                            onSelectAllChange={this.handleOnSelectAllChange}
                            dataKey={this.dataKey}
                            expandedRows={this.state.expandedRows}
                            onRowToggle={this.handleOnRowToggle}
                            onRowExpand={this.handleOnRowExpand}
                            onRowCollapse={this.handleOnRowCollapse}
                            rowExpansionTemplate={this.rowExpansionTemplate}
                            rowClassName={this.props.rowClassName}
                            selectionPageOnly={false}
                        >
                            {this.props.rowExpansionTemplate ? <Column expander headerStyle={{ width: '3em' }}></Column> : null}
                            {this.props.disableSelection === true || !this.props.selectionMode ? null : (
                                <Column selectionMode={this.props.selectionMode} headerStyle={{ width: '3em' }}></Column>
                            )}
                            {dynamicColumns}
                        </DataTable>
                    </div>
                </DivContainer>
            </React.Fragment>
        );
    }



    renderCriteria() {
        return (
            <div className='row'>

                <CriteriaTextComponent
                    id='fullName-sc'
                    name='fullName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.fullName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('payReport.user')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

                <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    name='date'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    label={'Data'}
                    colClass='col-xl-6 col-lg-12 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    showButtonBar
                    sharedLabel
                    renderDash
                    dateFormat="mm/yy"
                    maxDateInfinite
                    placeholderFrom={'Data od'}
                    placeholderTo={'Data do'}
                    inputsClassName='col-6'
                    view="month"
                />
               <CriteriaTextComponent
                    id='subcontractor-sc'
                    name='subcontractor'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.subcontractor}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('payReport.subcontractor')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi-pencil'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => this.handleOpenEditDialog(e, rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                    rendered={false}
                />
            </span>
        );
    }

    handleOpenEditDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ dialogVisibile: true, element: this.cloneElement(rowData), dialogViewMode: 'EDIT' });
    }

    handleOpenDeleteDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ deleteConfirmDialogVisible: true, element: rowData, dialogViewMode: 'DELETE' });
    }

    formatTime(field, rowData) {
        const time = rowData[field];
        return this.formatTimeIfPossible(time);
    }
    formatTimeIfPossible(time) {
        if (NumberUtils.isNumber(time)) {
            const hours = Math.floor(time / 60);
            const mins = time % 60;
            return `${hours}:${mins.toString().padStart(2, '0')}`;
        } else {
            return '';
        }
    }
    formatValue(field, rowData) {
        // var currencyField = '';
        // switch (field) {
        //     case 'leadDriveRateVal':
        //         currencyField = 'leadDriveRateCurr'
        //         break;
        //     case 'empDriveRateVal':
        //         currencyField = 'empDriveRateCurr'
        //         break;
        //     case 'amount':
        //         currencyField = 'contractCurrency'
        //         break;
        //     case 'contractRate':
        //         currencyField = 'contractCurrency'
        // }
        if(rowData[field]==null){
            return '';
        }
        const formatter = new Intl.NumberFormat('pl-PL', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const formattedNumber = formatter.format(rowData[field]);
        return formattedNumber;
    }

    prepareColumns() {
        return [{
            field: 'month',
            header: 'Miesiąc',
            sortable: true,
            sortField: 'month_',
        },
        {
            field: 'userFullName',
            header: t('payReport.user'),
            sortable: true,
            sortField: 'lastname',
        },
        {
            field: 'workTime',
            header: t('payReport.worktime'),
            sortable: true,
            body: this.formatTime.bind(this, 'workTime'),
            sortField: 'emp_time',
            sortable: true,
            bodyStyle:{
                textAlign: 'right'
            },
            style: {
                width: '80px'
            }

        },
        {
            field: 'contractRate',
            header: t('payReport.rate'),
            sortField: 'contract_rate',
            sortable: true,
            body: this.formatValue.bind(this, 'contractRate'),
            bodyStyle:{
                textAlign: 'right'
            },
            style: {
                width: '80px'
            }
            
        },
        {
            field: 'empDriveTime',
            header: t('payReport.employeeDriveTime'),
            sortable: false,
            body: this.formatTime.bind(this, 'empDriveTime'),
            sortField: 'emp_drive_time',
            sortable: true,
            bodyStyle:{
                textAlign: 'right'
            },
            align: 'right'
        },
        {
            field: 'empDriveRateVal',
            header: t('payReport.employeeDriveTimeRate'),
            sortable: false,
            width: '80px',
            sortField: 'emp_drive_rate_val',
            sortable: true,
            body: this.formatValue.bind(this, 'empDriveRateVal'),       
            bodyStyle:{
                textAlign: 'right'
            },
            align: 'right'            
        },
        {
            field: 'leadDriveTime',
            header: t('payReport.leadDriveTime'),
            sortable: false,
            body: this.formatTime.bind(this, 'leadDriveTime'),
            sortField: 'lead_drive_time',
            sortable: true,
            bodyStyle:{
                textAlign: 'right'
            },
            align: 'right'
        },
        {
            field: 'leadDriveRateVal',
            header: t('payReport.leadDriveTimeRate'),
            sortField: 'lead_drive_rate_val',
            body: this.formatValue.bind(this, 'leadDriveRateVal'),
            sortable: true,
            bodyStyle:{
                textAlign: 'right'
            },
            align: 'right'
        },
        {
            field: 'extraDriveTime',
            header: t('payReport.extraDriveTime'),
            sortable: false,
            body: this.formatTime.bind(this, 'extraDriveTime'),
            sortField: 'driving_time',
            sortable: true,
            bodyStyle:{
                textAlign: 'right'
            },
            align: 'right'
        },
        {
            field: 'extraDriveRateVal',
            header: t('payReport.extraDriveRateVal'),
            sortField: 'extra_drive_rate_val',
            body: this.formatValue.bind(this, 'extraDriveRateVal'),
            sortable: true,
            bodyStyle:{
                textAlign: 'right'
            },
            style: {
                width: '80px'
            }
        },
        {
            field: 'amount',
            header: t('payReport.amount'),
            sortable: false,
            body: this.formatValue.bind(this, 'amount'),
            bodyStyle:{
                textAlign: 'right'
            },
        },

            // {
            //     key: 'actions',
            //     header: t('list.actions'),
            //     body: this.actionTemplate,
            //     className: 'table-column-action',
            // },
        ];
    }
    prepareHeaderItems() {
        return [

            {
                type: 'BUTTON',
                className: 'right-side',
                variant: 'white',
                disabled: !this.state.list || this.state.list.length < 1,
                customRenderFunction: this.renderPrintButton,
            },
        ];
    }

    renderPrintButton() {
        return (
            <PrintButtonComponent
                key={`print-button-pay-report`}
                reportFileNameBase={t('payReport.listName')}
                xml={false}
                pdf={false}
                csv={false}
                ods={false}
                xlsx={false}
                globalFormat='xlsx'
                noMenuFlag={true}
                generateReport={this.generateReport.bind(this)}
                disabled={!this.state.list.length > 0}
            />
        );
    }

    generateReport(format) {
        const { summary } = this.state;
        const criteria = this.state.criteria;
        const listSize = this.state.size;
        //const documentLimit = this.state.documentLimit;
        // if (listSize > documentLimit) {
        //     //return this.showErrorAboutLimitExceeded(documentLimit, listSize);
        // }
        switch (format) {
            case 'xlsx':
                return this.service.exportList(criteria, 'xlsx',summary);
            default:
                return Promise.reject('Nieznany format');
        }
    }

    handleOpenNewDialog(e) {
        let basicElement = this.initNewElement();
        if (e) {
            e.preventDefault();
        }
        this.setState({ dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW' });
    }

    handleDelete(e) {
        if (e) {
            e.preventDefault();
        }
        console.log('delete {}', this.state.element);
        this.service
            .remove(this.state.element)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('hourlyreport.deleteSuccess'));
            })
            .catch((err) => {
                this.persistMessage('error', '', t('hourlyreport.deleteError'));
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({ deleteConfirmDialogVisible: false });
            });
    }

    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    render() {
        return (
            <div className='container-fluid projectcategory-list'>
                <BreadcrumbsItem to='/pay-report-list'>{'Raport wypłat'}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }
}

PayReportListContainer.defaultProps = {
    detailUrl: '/#/hourly-report/details',
    newUrl: '/#/hourly-report/create',
    lazy: true,
};

PayReportListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default PayReportListContainer;
