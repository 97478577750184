import { t } from 'i18next';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import ActionLink from '../components/ActionLink';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import ClientService from '../services/ClientService';
import ProjectAddressService from '../services/ProjectAddressService';
import ReportedDayService from '../services/ReportedDayService';
import YesNoDialog from '../components/YesNoDialog';

class ReportedDayListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ReportedDayService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            statusLevelOptions: [],
            roleTypeOptions: [],
            dialogVisibile: false,
            deleteConfirmDialogVisible: false,
            elementToRemove: undefined,
            element: {                
                name: '',                
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            },
        };
        this.clientService = new ClientService();
        this.locationService = new ProjectAddressService();
        this.handleOpenRemoveReportedDayDialog = this.handleOpenRemoveReportedDayDialog.bind(this);
    }

    updateSearchCriteria(criteria) {
        return {
            name: criteria.name,
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        let now = new Date();
       // let twoMonthsAgo = moment(now).subtract(6, 'months').toDate();
        return {
            name: '',
            //status:  {enumValue: 'ACTIVE', label: 'Aktywny'},
            status:  {enumValue: 'TO_APPROVE', label: 'Utworzony'},
            id: null,
            dateFrom: '',
            dateTo: '',

            firstResult: 0,
            maxResults: 10,
            sortField: 'date',
            sortAsc: false,
        };
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('ProjectStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
        this.enumService
            .getEnumList('RoleType')
            .then((roleTypeOptions) =>
                this.setState({
                    roleTypeOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ról');
            });
            this.enumService
            .getEnumList('StatusLevel')
            .then((statusLevelOptions) =>
                this.setState({
                    statusLevelOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
    }

    getCriteriaName() {
        return 'reported-day-list-sc';
    }

    getContainerListName() {
        return 'reported-day-list-container';
    }

    
    makeBold(item, keyword) {
        let re = new RegExp(keyword, 'g')
        return (
            item.replace(re, '<b>'+keyword+ '</b>')
        )
    }
    

    searchLocations(query) {
        return this.locationService.list(query);
    }
    searchClients(query) {
        return this.clientService.list(query);
    }
    searchClientItemTemplate(item) {   
        let alreadySelected = this.state.criteria?.clients?.find(c => c.id === item.id) !== undefined;
        let address = item.address.postalCode +' '+ item.address.city +',' + item.address.streetName +', ' + item.address.country?.name;
        const style = alreadySelected ? {fontStyle: 'italic'} : {};
        console.log(this.state.criteria.clientsLike);
        return (
            <div className='row'>                                
                <div className='col-2' style={style}>Nazwa</div>
                <div className='col-10' style={style}> 
                    <span dangerouslySetInnerHTML={{__html: this.makeBold(item.name, this.state.criteria?.clientsLike)}}   />
                </div>
                <div className='col-2' style={style}>Adres</div>
                <div className='col-10' style={style}>
                <span dangerouslySetInnerHTML={{__html: this.makeBold(address, this.state.criteria?.clientsLike)}}   />
                </div>
            </div>
        );
    }

    searchLocationItemTemplate(item) {
        let alreadySelected = this.state.criteria?.locations?.find(l => l.id === item.id) !== undefined;
        const style = alreadySelected ? {fontStyle: 'italic'} : {};
        let address = item.postalCode +' '+ item.city +',' + item.streetName +', ' + item.country?.name;
        return (
            <div className='row'>                               
                <div className='col-12' style={style}>
                    <span dangerouslySetInnerHTML={{__html: this.makeBold(address, this.state.criteria?.locationsLike)}}   />
                </div>
            </div>
        );
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='name'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.name}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={"Nazwa projektu"}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                  <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    label={t('project.date')}
                    labelFrom={'od'}
                    labelTo={'do'}
                    name='date'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    colClass='col-xl-8 col-lg-8 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    showButtonBar
                    sharedLabel
                    maxDateInfinite
                    placeholderFrom={''}
                    placeholderTo={''}
                    inputsClassName='col-6'
                />
              
                 <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={t('project.status')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusLevelOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    dataKey='enumValue'
                    filterOptionValue='label'
                    refreshFromBackend
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    statusTemplate(field,rowData) {
        if(rowData.status === "CREATED"){
            return "Utworzona";
        }
        if(rowData.status === "TO_APPROVE"){
            return "Do akceptacji";
        }
        if(rowData.status === "APPROVED"){
            return "Zaakceptowana";
        }
        if(rowData.status === "REJECTED"){
            return "Odrzucona";
        }
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <div>
            <ActionLink
            iconName='mdi-eye'
            iconSide='left'
            iconSize='icon-large'
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
            <ActionLink
            iconName='mdi-trash-can'
            iconSide='left'
            iconSize='icon-large'
                handleClick={this.handleOpenRemoveReportedDayDialog.bind(this, rowData)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
            </div>
            
            
        );
    }
    handleOpenRemoveReportedDayDialog(rowData, e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({ deleteConfirmDialogVisible: true, elementToRemove: rowData, dialogViewMode: 'DELETE' });
    }

    handleDelete(e) {
        if (e) {
            e.preventDefault();
        }
        this.service
            .remove(this.state.elementToRemove)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('reportedDay.deleteSuccess'));
            })
            .catch((err) => {
                this.persistMessage('error', '', err.message);
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({deleteConfirmDialogVisible: false});
            });

    }
    afterObjectUpdate() {       
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    prepareColumns() {
        return [
            { field: 'date', header: t('reportedDay.date'), sortable: true },
            { field: 'clientName', header: t('reportedDay.client'), sortable: true },
            { field: 'projectName', header: t('reportedDay.project'), sortable: true },
            //{field: 'hours', header: t('reportedDay.hours'), sortable: true},
            { field: 'status', header: t('reportedDay.status'), sortable: true, body: this.statusTemplate.bind(this, 'status') },
            { field: 'levelName', header: t('reportedDay.level'), sortable: true },
            { field: 'reportedEmpQuantity', header: t('reportedDay.reportedEmp'), sortable: false },
            { field: 'empInProjectQuantity', header: t('reportedDay.empInProject'), sortable: false },
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    prepareHeaderItems() {
        return( this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'))
            ? [
                  {
                      label: t('reportedDay.newReportedDay'),
                      type: 'BUTTON',
                      className: 'float-right',
                      variant: 'blue',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                      iconColor: 'white',
                      onClick: this.handleGoToNew,   ///todo
                  },
              ]
            : [];
    }

    hideDeleteConfirmation() {
        this.setState({deleteConfirmDialogVisible: false});
    }
    

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/reported-day-list'>{'Karty pracy'}</BreadcrumbsItem>
                {this.renderView()} 
                {this.state.deleteConfirmDialogVisible === true ? 
                         <YesNoDialog
                         visible={this.state.deleteConfirmDialogVisible}
                         header='Usunięcie'
                         name='visible'
                         onChange={(type, x, target) => {
                             if (target.value) {
                                
                                 this.handleDelete();
                             } else {
                                 this.hideDeleteConfirmation();
                             }
                         }}
                         onHide={() => {
                             this.hideDeleteConfirmation();
                         }}
                         dialogViewMode = {this.state.dialogViewMode}
                     >
                         Czy na pewno chcesz usunąć kartę pracy?
                     </YesNoDialog> : null
                }               
            </div>
        );
    }
}

ReportedDayListContainer.defaultProps = {
    detailUrl: '/#/reported-day/details',
    newUrl: '/#/reported-day/create',
    //disableSelection: true,
    lazy: true,
};

ReportedDayListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default ReportedDayListContainer;
