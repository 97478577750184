import moment from 'moment';
import BaseUserService from './BaseUserService';
import Constants from '../utils/constants';

const path = 'employee/reserve';

export default class ReserveService extends BaseUserService {
    constructor() {
        super(path);
        this.path = path;
    }
    getCriteria(criteria) {
        let sortField = criteria.sortField === 'absences' ? 'absences.dateTo' : criteria.sortField;
        return this.objToQueryString({
            login: criteria.login,
            first_name: criteria.firstName,
            subcontractor_name: criteria.subcontractorName,
            last_name: criteria.lastName,
            email: criteria.email,
            status: criteria.status?.enumValue,
            active: criteria.active,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: sortField,
            sort_asc: criteria.sortAsc,
            empCategories: criteria.empCategories ? criteria.empCategories.map((category) => category.id).join(',') : null,
            projectCategories: criteria.projectCategories ? criteria.projectCategories.map((category) => category.id).join(',') : null,
            expirationAlarmStatus: criteria.expirationAlarmStatus?.enumValue
        });
    }

    getUnconfirmedList(criteria){
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/unconfirmed-return/list${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getConfirmedList(criteria){
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/confirmed-return/list${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });

    }
    getReadyList(criteria){
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/ready/list${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getSoonAbsentList(criteria){
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/soon-absent/list${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    getStandByList(criteria){
        const queryString = this.getCriteria(criteria);
        return this.fetch(`${this.domain}/${this.path}/standby/list${queryString}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    update(element) {
        return super.update(this.createObjectToSave(element));
    }
    add(element) {
        return super.add(this.createObjectToSave(element));
    }

    createObjectToSave(element) {
        const e = this.cloneElement(element);
        e.contracts.forEach((contract) => {
            if (contract.dateFrom) {
                contract.dateFrom = moment(contract.dateFrom).startOf('month').format(Constants.DATE_JSON_FORMAT);
            }
            if (contract.dateTo) {
                contract.dateTo = moment(contract.dateTo).startOf('month').format(Constants.DATE_JSON_FORMAT);
            }
        });
        return e;
    }

    search(query, loginAllowed, status) {
        const paramArrays = [];
        if (!!query) {
            paramArrays.push(`query=${query}`);
        }
        if (!!loginAllowed) {
            paramArrays.push(`loginAllowed=${loginAllowed}`);
        }
        if (!!status) {
            paramArrays.push(`status=${status}`);
        }
        const parameters = paramArrays.length > 0 ? '?' + paramArrays.join('&') : '';
        return this.fetch(`${this.domain}/${this.path}/search${parameters}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    } 



}
