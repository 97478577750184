import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import {t} from 'i18next';
import ActionLink from '../../components/ActionLink';
import BaseUserListContainer from '../../baseContainers/BaseUserListContainer';
import EmployeeModel from '../../model/EmployeeModel';
import ReserveService from '../../services/ReserveService';
import moment from 'moment/moment';
import EmployeeCategoryService from '../../services/EmployeeCategoryService';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import IconColorComponent from '../../components/inputs/IconColorComponent';
import Constants from '../../utils/constants';

class EmployeeReserveStandByList extends BaseUserListContainer {
    constructor(props) {
        super(props, new ReserveService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleTypeOptions: [],
            empCategories: [],
            dialogVisibile: false,
            element: new EmployeeModel(),
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
        this.empCategoryService = new EmployeeCategoryService();
    }

    updateSearchCriteria(criteria) {
        let baseUpdateSearchCriteria = super.updateSearchCriteria(criteria);
        baseUpdateSearchCriteria.empCategories = criteria.empCategories;
        baseUpdateSearchCriteria.subcontractorName = '';
        const {urlParams}=this.props;
        if(urlParams.first){
            baseUpdateSearchCriteria.firstResult=urlParams.first;
            baseUpdateSearchCriteria.maxResults=urlParams.maxs;
        }
        baseUpdateSearchCriteria.expirationAlarmStatus = criteria.expirationAlarmStatus;
        return baseUpdateSearchCriteria;
    }

    getCleanSearchCriteria() {
        let baseSearchCriteria = super.getCleanSearchCriteria();
        baseSearchCriteria.empCategories = [];
        baseSearchCriteria.subcontractorName = '';
        baseSearchCriteria.expirationAlarmStatus = null;
        return baseSearchCriteria;
    }

      initializeFromBackend() {
        super.initializeFromBackend();
        
        this.empCategoryService.getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true, status:{enumValue: 'ACTIVE' } })
        .then(categories => this.setState({ empCategories: categories.content }))
        .catch(() => {
            this.showErrorMessage('Nie udało się pobrać listy kategorii');
        });
        
        this.enumService
        .getEnumList('ExpirationAlarmStatus')
        .then((data)=>{
            this.setState({
                expirationAlarmStatusOptions: data.filter((x)=>x?.enumValue!=='HANDLED')
            })
        }).catch((ex)=>{
            this.showErrorMessage('Nie udało się pobrać listy statusów dokumentów');
        });
    }
    getCriteriaName() {
        return 'employee-list-sc';
    }

    getContainerListName() {
        return 'employee-list-container';
    }

    getList() {
        const {criteria} = this.state;
        const activeElement = document.activeElement;
        this.setState(
            {
                loading: true,
            },
            () => {
                this.service
                    .getStandByList(criteria)
                    .then((data) => {
                        if (this._isMounted) {
                            this.setState(
                                {
                                    list: this.updateListElements(data.content),
                                    loading: false,
                                    summary: data?.summary,
                                    size: data.totalElements,
                                },
                                () => {
                                    this.onAfterGetList(data);
                                    this.setTimeout(() => activeElement?.focus(), 50);
                                }
                            );
                        }
                    })
                    .catch((err) => {
                        if (this._isMounted) {
                            this.setState(
                                {
                                    list: [],
                                    loading: false,
                                    size: 0,
                                },
                                () => {
                                    this.afterGetListError();
                                }
                            );
                        }
                    });
            }
        );
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='email-sc'
                    name='email'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.email}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('employee.email')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='firstName-sc'
                    name='firstName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.firstName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('employee.firstName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='lastName-sc'
                    name='lastName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.lastName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('employee.lastName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={t('employee.status')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
             <CriteriaDropdownComponent
                    id='status-sc'
                    name='expirationAlarmStatus'
                    showClear
                    label={t('employee.documentStatus')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.expirationAlarmStatus}
                    options={this.state.expirationAlarmStatusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
                <CriteriaTextComponent
                    id='subcontractorName-sc'
                    name='subcontractorName'
                    showClear
                    label={t('employee.subcontractorName')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.subcontractorName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaMultiSelectComponent
                    id='categories'
                    name='empCategories'
                    label={t('reserve.employee.categories')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.empCategories}
                    optionLabel='name'
                    value={this.state.criteria.empCategories}                                            
                    dataKey='id' 
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
            </div>
        );
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        let detailHref = `${detailUrl}/${rowData?.id}`;
        if(this.props.urlParams){
            detailHref+='?backActiveIndex='+this.props.urlParams?.activeIndex;
        }
        if(detailHref.includes('?')){
            detailHref+='&first='+this.state.criteria.firstResult;
            detailHref+='&maxs='+this.state.criteria.maxResults;   
        }else{
            detailHref+='?first='+this.state.criteria.firstResult;
            detailHref+='&maxs='+this.state.criteria.maxResults;   
        }
        return (
            <div>
                <ActionLink
                    iconName='mdi-eye'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={this.handleGoToDetail.bind(this, detailHref)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                />
            </div>
        );
    }
    prepareHeaderItems() {
        // return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO')
        //     ? [
        //           {
        //               label: t('employee.addButton'),
        //               type: 'BUTTON',
        //               className: 'float-right',
        //               variant: 'blue',
        //               iconName: 'mdi-plus',
        //               iconSide: 'left',
        //               iconColor: 'white',
        //               onClick: this.handleGoToNew,
        //           },
        //       ]
        //     : [];
    }

    inputColorTemplate(rowData){
        if(rowData?.categories)
        return <div className="row" >
              {rowData.categories.map(category=>{
                  return <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={category.icon.value} color={category.color.value} tootltipText={category.name} />
           })}
          </div>
      }
    absencesTemplate(rowData) {
        if (rowData?.currentAbsence) {
            let absence=rowData?.currentAbsence;
            return (
                <span className='absence-container'>
                    <span>
                        {`Od:  ${moment(absence?.dateFrom).format('YYYY-MM-DD')}`}
                    </span>
                    <span style={{ whiteSpace: 'pre-wrap' }}>
                        {`Do:  ${moment(absence?.dateTo).format('YYYY-MM-DD')}`}
                   </span>
                </span>
                );
        } else {
            return '';
        }
    }
    getPreparedExpirationStatusIcon(documentStatus){
        switch(documentStatus.enumValue){
            case 'EXPIRED':
                return(
                    <div className='row' style={{ display: 'flex', justifyContent: 'center',width:'100px'}}>
                     {
                         <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={Constants.ALERT_ICON} color={Constants.RED} tootltipText={documentStatus.label} />
                     }
                 </div>
                )
            case 'SOON_EXPIRED':
                return(
                    <div className='row' style={{ display: 'flex', justifyContent: 'center',width:'100px'}}>
                     {
                         <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={Constants.ALERT_ICON} color={Constants.ORANGE} tootltipText={documentStatus.label} />
                     }
                 </div>
                )
            case 'HANDLED':
                return(
                    <div className='row' style={{ display: 'flex', justifyContent: 'center',width:'100px'}}>
                     {
                         <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={Constants.HANDLED_ICON} color={Constants.GREEN} tootltipText={documentStatus.label} />
                     }
                 </div>
                )
            default:
                return null;
        }
    }
    documentStatusTemplate(rowData){
        if(rowData?.expirationStatus){
        let documentStatus=rowData.expirationStatus;
        return documentStatus.enumValue ? (
                this.getPreparedExpirationStatusIcon(documentStatus)
             ) : null
         }
    }
    prepareColumns() {
        return [
            {field: 'firstName', header: t('employee.firstName'), sortable: true},
            {field: 'lastName', header: t('employee.lastName'), sortable: true},
            {field: 'absences', header: t('employee.absence.columnLabel'), sortable: true, body: this.absencesTemplate.bind(this)},
            {field: 'categories', header: t('reserve.employee.categories'), sortable: false, body: this.inputColorTemplate.bind(this)},
            {field: 'subcontractor.name', header: t('employee.subcontractorName'), sortable: true},
            {field: 'status', header: t('employee.status'), sortable: true, body: this.enumTemplate.bind(this, 'status')},
            {field: 'statusModel', header: t('employee.documentStatus'), sortable: false, body: this.documentStatusTemplate.bind(this)},
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    handleOpenNewDialog(e) {
        let basicElement = new EmployeeModel();
        let roleTypes = this.state.roleTypeOptions;
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW', enumRoleTypes: roleTypes});
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/reserve-ready-list'>{'Pracownicy gotowi do wyjazdu'}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }
}

EmployeeReserveStandByList.defaultProps = {
    detailUrl: '#/reserve/employee/details',
    lazy: true,
};

EmployeeReserveStandByList.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default EmployeeReserveStandByList;