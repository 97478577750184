import {FilterMatchMode} from 'primereact/api';
import moment from 'moment';

class HandleChangeUtils {
    static setValueInObjPath(obj, value, varName, path) {        
        let changed = true;
        let i;
        for (i = 1; i < path.length - 1; i++) {
            if (path[i].includes('[') && path[i].includes(']')) {
                const pathWithIndex = path[i].replace(']', '').split('[');
                obj = obj[pathWithIndex[0]][pathWithIndex[1]];
            } else {
                obj = obj[path[i]];
            }
        }
        if (path.length === 1) {
            changed = value !== obj[varName];
            obj[varName] = value;
        } else {

            if (path[i].includes('[') && path[i].includes(']')) {
                const pathWithIndex = path[i].replace(']', '').split('[');
                if (Array.isArray(obj[pathWithIndex[0]]) && varName == 'array') {
                    changed = obj[pathWithIndex[0]][pathWithIndex[1]] !== value
                    obj[pathWithIndex[0]][pathWithIndex[1]] = value;
                    return changed;
                }
                const oldValue = obj[pathWithIndex[0]][pathWithIndex[1]][varName];
                const newValue = value;
                const oldValueId = oldValue?.id;
                const newValueId = newValue?.id;
                if (oldValueId && newValueId) {
                    changed = oldValueId !== newValueId;
                } else {
                    changed = oldValue !== newValue;
                }                
                obj[pathWithIndex[0]][pathWithIndex[1]][varName] = value;
            } else {
                changed = obj[path[i]][varName] !== value;
                obj[path[i]][varName] = value;
            }
        }

        return changed;
    }

    static getValueInObjPath(stateField, state) {
        const path = stateField.split('.');
        let i;
        let obj = state;
        if (!!stateField && stateField !== '') {
            for (i = 0; i < path.length; i++) {
                if (path[i].includes('[') && path[i].includes(']')) {
                    const pathWithIndex = path[i].replace(']', '').split('[');
                    obj = obj[pathWithIndex[0]][pathWithIndex[1]];
                } else {
                    obj = obj[path[i]];
                }
            }
        }
        return obj;
    }

    static handleChange(inputType, parameters, event, onAfterStateChange, stateField, handleChangeSetState, state) {
        let stateFieldValue = undefined;
        if (stateField && stateField !== '') {
            ({[stateField]: stateFieldValue} = state);
            stateFieldValue = this.getValueInObjPath(stateField, state);
        }
        let varName;
        let varValue;
        let modifiedList;
        if (event !== undefined) {
            switch (inputType) {
                case 'YES_NO_DIALOG':
                    varName = event.name;
                    varValue = event.value;
                    handleChangeSetState(varName, varValue, onAfterStateChange, stateField);
                    break;
                case 'MULTI_FILE_CHOOSE':
                    varName = parameters[1];
                    if (stateFieldValue) {
                        modifiedList = stateFieldValue[varName];
                    } else {
                        ({[varName]: modifiedList} = state);
                    }
                    if (parameters[0] === 'ADD') {
                        varValue = event;
                        if (!modifiedList) {
                            modifiedList = [varValue];
                        } else {
                            modifiedList = modifiedList.concat(varValue);
                        }
                    } else if (modifiedList !== undefined && modifiedList.length >= parameters[2] - 1 && parameters[0] === 'REMOVE') {
                        modifiedList.splice(parameters[2], 1);
                    }
                    handleChangeSetState(varName, modifiedList, onAfterStateChange, stateField);
                    break;
                case 'SINGLE_FILE_CHOOSE':
                    varName = parameters[1];
                    if (parameters[0] === 'ADD') {
                        varValue = event[0];
                    } else if (parameters[0] === 'REMOVE') {
                        varValue = undefined;
                    }
                    handleChangeSetState(varName, varValue, onAfterStateChange, stateField);
                    break;
                case 'MULTI_FILE_UPLOAD':
                    varName = parameters[1];
                    if (stateFieldValue) {
                        modifiedList = stateFieldValue[varName];
                    } else {
                        ({[varName]: modifiedList} = state);
                    }
                    if (parameters[0] === 'ADD') {
                        varValue = JSON.parse(event.xhr.response);
                        if (!modifiedList) {
                            modifiedList = [varValue];
                        } else {
                            modifiedList = modifiedList.concat(varValue);
                        }
                    } else if (modifiedList !== undefined && modifiedList.length >= parameters[2] - 1 && parameters[0] === 'REMOVE') {
                        modifiedList.splice(parameters[2], 1);
                    }
                    handleChangeSetState(varName, modifiedList, onAfterStateChange, stateField);
                    break;
                case 'SINGLE_FILE_UPLOAD':
                    varName = parameters[1];
                    if (parameters[0] === 'ADD') {
                        varValue = JSON.parse(event.xhr.response)[0];
                    } else if (parameters[0] === 'REMOVE') {
                        varValue = undefined;
                    }
                    handleChangeSetState(varName, varValue, onAfterStateChange, stateField);
                    break;
                case 'SINGLE_FILE_CHOOSE_UPLOAD':
                    varName = parameters[1];
                    if (parameters[0] === 'ADD') {
                        varValue = event.files[0];
                    } else if (parameters[0] === 'REMOVE') {
                        varValue = undefined;
                    }
                    handleChangeSetState(varName, varValue, onAfterStateChange, stateField);
                    break;
                case 'MULTI_DROPDOWN':
                    varName = event.target.name;
                    varValue = event.target.value ? event.target.value : undefined;
                    if (stateFieldValue) {
                        modifiedList = stateFieldValue[varName];
                    } else {
                        ({[varName]: modifiedList} = state);
                    }
                    if (!modifiedList) {
                        modifiedList = [];
                    }
                    modifiedList[parameters[0]] = varValue;
                    handleChangeSetState(varName, modifiedList, onAfterStateChange, stateField);
                    break;
                case 'MULTI_CHECKBOX':
                    varName = event.target.name;
                    varValue = event.checked ? event.checked : false;
                    if (stateFieldValue) {
                        modifiedList = stateFieldValue[varName];
                    } else {
                        ({[varName]: modifiedList} = state);
                    }
                    if (!modifiedList) {
                        modifiedList = [];
                    }
                    if (varValue) {
                        if (!modifiedList) {
                            modifiedList = [parameters[1]];
                        } else {
                            modifiedList = [...modifiedList, parameters[1]];
                        }
                    } else {
                        modifiedList = modifiedList.filter((v) => {
                            return v[parameters[0]] !== parameters[1][parameters[0]] ? v : null;
                        });
                    }
                    handleChangeSetState(varName, modifiedList, onAfterStateChange, stateField);
                    break;
                case 'CHECKBOX':
                    let negated = parameters[0];
                    varName = event.target.name;
                    varValue = event.checked ? event.checked : false;
                    varValue = negated ? !varValue : varValue;
                    handleChangeSetState(varName, varValue, onAfterStateChange, stateField);
                    break;
                case 'INPUTSWITCH':
                    varName = parameters[0];
                    varValue = event.value ? event.value : false;
                    handleChangeSetState(varName, varValue, onAfterStateChange, stateField);
                    break;
                case 'TEXT_EDITOR':
                    varName = parameters[0];
                    varValue = event;
                    handleChangeSetState(varName, varValue, onAfterStateChange, stateField);
                    break;
                case 'EDITABLE_DATA_TABLE':
                    varName = event.name;
                    varValue = event.value;
                    if (stateFieldValue) {
                        modifiedList = stateFieldValue[varName];
                    } else {
                        ({[varName]: modifiedList} = state);
                    }
                    if (parameters[0] === 'ADD') {
                        if (!modifiedList) {
                            if (parameters.length >= 2) {
                                varValue.page = parameters[1];
                            }
                            modifiedList = [varValue];
                        } else {
                            if (parameters.length >= 2) {
                                varValue.page = parameters[1];
                            }
                            modifiedList = [...modifiedList, varValue];
                        }
                        handleChangeSetState(varName, modifiedList, onAfterStateChange, stateField);
                    } else if (parameters[0] === 'EDIT') {
                        const rowData = parameters[1];
                        const dataKey = parameters[2];
                        const eventObj = {};
                        eventObj.target = {};
                        if (parameters[3] === 'MULTI_FILE_UPLOAD' || parameters[3] === 'SINGLE_FILE_UPLOAD') {
                            eventObj.name = parameters[4][1];
                            eventObj.target.name = parameters[4][1];
                        } else if (parameters[3] === 'NUMBER') {
                            let numberValue = isNaN(parseFloat(parameters[5].value)) ? 0 : parseFloat(parameters[5].value);
                            eventObj.name = parameters[5].name;
                            eventObj.value = numberValue;
                            eventObj.target.name = parameters[5].name;
                            eventObj.target.value = numberValue;
                        } else {
                            eventObj.name = parameters[5].name;
                            eventObj.value = parameters[5].value;
                            eventObj.target.name = parameters[5].target.name;
                            eventObj.target.value = parameters[5].target.value;
                        }
                        let index = -1;
                        if (modifiedList !== undefined) {
                            modifiedList.forEach((el, i) => {
                                if (el[dataKey] === rowData[dataKey]) {
                                    index = i;
                                }
                            });
                            let computedStateField = '';
                            if (!!stateField && stateField !== '') {
                                computedStateField = `${stateField}.${event.name}[${index}]`;
                            } else {
                                computedStateField = `${event.name}[${index}]`;
                            }
                            if (parameters.length >= 7) {
                                modifiedList[index].page = parameters[6];
                                handleChangeSetState(
                                    varName,
                                    modifiedList,
                                    () => {
                                        this.handleChange(
                                            parameters[3],
                                            parameters[4] !== undefined && parameters[4].length > 0 ? parameters[4] : undefined,
                                            eventObj,
                                            onAfterStateChange,
                                            computedStateField
                                        );
                                    },
                                    stateField
                                );
                            } else {
                                this.handleChange(
                                    parameters[3],
                                    parameters[4],
                                    parameters[5],
                                    onAfterStateChange,
                                    computedStateField,
                                    handleChangeSetState,
                                    state
                                );
                            }
                        }
                    } else if (parameters[0] === 'EDIT_DB') {
                        let computetdStateField = stateField;
                        if (computetdStateField !== '') {
                            computetdStateField = `${computetdStateField}.`;
                        }
                        computetdStateField = `${computetdStateField}${event.name}`;
                        stateFieldValue = this.getValueInObjPath(computetdStateField, state);
                        const rowData = parameters[1];
                        const dataKey = parameters[2];
                        const eve = parameters[5];
                        const modifiedMap = stateFieldValue;
                        const object = rowData;
                        object[eve.target.name] = eve.target.value || eve.target.value === '' ? eve.target.value : undefined;
                        // object.deleted = deleted ? true : undefined;
                        if (parameters.length >= 7) {
                            object.page = parameters[6];
                        }
                        modifiedMap.set(rowData[dataKey], object);
                        if (modifiedMap !== undefined) {
                            handleChangeSetState(event.name, modifiedMap, onAfterStateChange, stateField);
                        }
                    } else if (parameters[0] === 'REMOVE_DB') {
                        let computetdStateField = stateField;
                        if (computetdStateField !== '') {
                            computetdStateField = `${computetdStateField}.`;
                        }
                        computetdStateField = `${computetdStateField}${event.name}`;
                        stateFieldValue = this.getValueInObjPath(computetdStateField, state);
                        const rowData = parameters[1];
                        const dataKey = parameters[2];
                        const deleted = parameters[3];
                        const modifiedMap = stateFieldValue;
                        const object = rowData;
                        object.deleted = deleted ? true : undefined;
                        modifiedMap.set(rowData[dataKey], object);
                        if (modifiedMap !== undefined) {
                            handleChangeSetState(event.name, modifiedMap, onAfterStateChange, stateField);
                        }
                    } else if (parameters[0] === 'REMOVE') {
                        const rowData = parameters[1];
                        const dataKey = parameters[2];
                        const deleted = parameters[3];
                        const permanent = parameters[4];
                        let index = -1;
                        modifiedList.forEach((el, i) => {
                            if (el[dataKey] === rowData[dataKey]) {
                                index = i;
                            }
                        });
                        if (permanent) {
                            if (modifiedList !== undefined && modifiedList.length >= index - 1) {
                                modifiedList.splice(index, 1);
                            }
                        } else {
                            modifiedList[index][deleted] = deleted ? true : undefined;
                        }
                        handleChangeSetState(varName, modifiedList, onAfterStateChange, stateField);
                    } else if (parameters[0] === 'REMOVE_ALL') {
                        const keys = parameters[1];
                        const dataKey = parameters[2];
                        const deleted = parameters[3];
                        const permanent = parameters[4];
                        if (keys !== undefined && keys !== null && keys.length > 0) {
                            if (permanent) {
                                let indexex = [];
                                modifiedList.forEach((el, i) => {
                                    if (keys.includes(el[dataKey])) {
                                        indexex.push(i);
                                    }
                                });
                                if (indexex.length > 0) {
                                    for (let i = indexex.length - 1; i >= 0; i--) {
                                        modifiedList.splice(indexex[i], 1);
                                    }
                                }
                            } else {
                                modifiedList.forEach((el, i) => {
                                    if (keys.includes(el[dataKey])) {
                                        el[deleted] = deleted ? true : undefined;
                                    }
                                });
                            }
                        }
                        handleChangeSetState(varName, modifiedList, onAfterStateChange, stateField);
                    }
                    break;
                case 'XML':
                    varName = event.name;
                    const errorName = event.errorName;
                    varValue = event !== undefined ? event.lastValidXml : undefined;
                    const error = event !== undefined ? event.error : undefined;
                    if (error === undefined || error === null) {
                        handleChangeSetState(varName, varValue, onAfterStateChange, stateField);
                    }
                    handleChangeSetState(errorName, error, undefined, stateField);
                    break;
                case 'AUTOCOMPLETE_FORCE':
                    if (event !== undefined) {
                        varName = parameters[0];
                        varValue = event.value || event.value === '' ? event.value : undefined;
                        if (varValue !== undefined && varValue.value !== undefined) {
                            varValue = varValue.value;
                        }
                        handleChangeSetState(varName, varValue, onAfterStateChange, stateField, undefined);
                    }
                    break;
                case 'AUTOCOMPLETE':
                    if (event !== undefined) {
                        varName = event.target.name;
                        varValue = event.target.value || event.target.value === '' ? event.target.value : undefined;
                        if (varValue !== undefined && varValue.value !== undefined) {
                            varValue = varValue.value;
                        }
                        handleChangeSetState(varName, varValue, onAfterStateChange, stateField, undefined);
                    }
                    break;
                case 'NUMBER':
                    varName = event.name;
                    varValue = isNaN(parseFloat(event.value)) ? 0 : parseFloat(event.value);
                    handleChangeSetState(varName, varValue, onAfterStateChange, stateField, parameters ? parameters[0] : undefined);
                    break;
                case 'TEXT':
                    varName = event.target.name;
                    varValue = event.target.value || event.target.value === '' ? event.target.value : undefined;
                    if (parameters && varValue) {
                        if (parameters[0]) {
                            varValue = varValue.toUpperCase();
                        }
                        if (parameters[1] && parameters[2] && parameters[3]) {
                            if (varValue.toString().match(parameters[1]) === null && varValue.toString().match(parameters[2]) === null) {
                                varValue = `${parameters[3]}${varValue}`;
                            }
                        }
                    }
                    handleChangeSetState(varName, varValue, onAfterStateChange, stateField);
                    break;
                case 'MULTI_SELECT_BUTTON':
                case 'DROPDOWN':
                case 'SELECT_BUTTON':
                case 'MULTI_SELECT':
                case 'CALENDAR_FROM':
                case 'CALENDAR':
                case 'TEXTAREA':
                case 'RADIOBUTTON':
                default:
                    varName = event.target.name;
                    varValue = event.target.value || event.target.value === '' ? event.target.value : undefined;
                    handleChangeSetState(
                        varName,
                        varValue,
                        onAfterStateChange,
                        stateField,
                        parameters ? parameters[0] : undefined,
                        parameters ? parameters[1] : undefined
                    );
                    break;
            }
        } else {
            console.log('handleChange implementation error');
        }
    }

    static handleChangeSc(
        inputType,
        parameters,
        event,
        matchMode,
        refreshFromBackend,
        onAfterStateChange,
        timeoutHandler,
        delay = 1000,
        handleChangeScSetState,
        state,
        isMounted
    ) {
        let varName;
        let filterVarName;
        let varValue;
        let filterVarValue;
        let criteria = state.criteria;
        let filters = state.filters;

        if (event !== undefined) {
            switch (inputType) {
                case 'CHECKBOX':
                    varName = event.target.name;
                    varValue = event.checked ? event.checked : false;
                    criteria[varName] = varValue;
                    if (!refreshFromBackend) {
                        filters[varName] = {value: varValue, matchMode: matchMode};
                    }
                    break;
                case 'NUMBER_RANGE':
                    varName = event.name;
                    varValue = isNaN(parseFloat(event.value)) ? (parameters[1] ? undefined : 0) : parseFloat(event.value);
                    criteria[varName] = varValue;
                    if (!refreshFromBackend) {
                        filterVarName = parameters ? parameters[0] : event.name;
                        if (filters[filterVarName] && filters[filterVarName].matchMode !== matchMode) {
                            let filterValueFrom;
                            let filterValueTo;
                            switch (filters[filterVarName].matchMode) {
                                case 'gte':
                                    filterValueFrom = filters[filterVarName].value;
                                    break;
                                case 'lte':
                                    filterValueTo = filters[filterVarName].value;
                                    break;
                                case 'between':
                                    filterValueFrom = filters[filterVarName].value[0];
                                    filterValueTo = filters[filterVarName].value[1];
                                    break;
                                default:
                                    break;
                            }
                            if (matchMode === 'gte') {
                                filters[filterVarName] = {value: [varValue, filterValueTo], matchMode: FilterMatchMode.BETWEEN};
                            } else if (matchMode === 'lte') {
                                filters[filterVarName] = {value: [filterValueFrom, varValue], matchMode: FilterMatchMode.BETWEEN};
                            }
                            if (matchMode === 'gte') {
                                if (!!varValue) {
                                    filters[filterVarName] = {value: [varValue, filterValueTo], matchMode: FilterMatchMode.BETWEEN};
                                } else {
                                    filters[filterVarName] = {value: filterValueTo, matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO};
                                }
                            } else if (matchMode === 'lte') {
                                if (!!varValue) {
                                    filters[filterVarName] = {value: [filterValueFrom, varValue], matchMode: FilterMatchMode.BETWEEN};
                                } else {
                                    filters[filterVarName] = {value: filterValueFrom, matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO};
                                }
                            }
                        } else {
                            if (!!varValue) {
                                filters[filterVarName] = {value: varValue, matchMode: matchMode};
                            } else {
                                delete filters[filterVarName];
                            }
                        }
                    }
                    break;
                case 'CALENDAR_RANGE':
                    varName = event.target.name;
                    varValue = event.target.value || event.target.value === '' ? event.target.value : undefined;
                    if (varValue && moment.isDate(varValue) && moment(varValue).isValid()) {
                        filterVarValue = new Date(varValue);
                    }
                    criteria[varName] = varValue;
                    if (!refreshFromBackend) {
                        filterVarName = parameters ? parameters[0] : event.name;
                        if (filters[filterVarName] && filters[filterVarName].matchMode !== matchMode) {
                            let filterValueFrom;
                            let filterValueTo;
                            switch (filters[filterVarName].matchMode) {
                                case 'dateAfter':
                                    filterValueFrom = filters[filterVarName].value;
                                    break;
                                case 'dateBefore':
                                    filterValueTo = filters[filterVarName].value;
                                    break;
                                case 'between':
                                    filterValueFrom = filters[filterVarName].value[0];
                                    filterValueTo = filters[filterVarName].value[1];
                                    break;
                                default:
                                    break;
                            }
                            if (varValue === undefined) {
                                filterVarValue = undefined;
                            } else if (matchMode === 'dateBefore' && !filterValueTo) {
                                filterVarValue = new Date(moment(varValue).endOf('day'));
                            } else if (matchMode === 'dateAfter' && !filterValueFrom) {
                                filterVarValue = new Date(moment(varValue).add(-1, 'hour'));
                            }
                            if (matchMode === 'dateAfter') {
                                if (!!filterVarValue) {
                                    filters[filterVarName] = {value: [filterVarValue, filterValueTo], matchMode: FilterMatchMode.BETWEEN};
                                } else {
                                    filters[filterVarName] = {value: filterValueTo, matchMode: FilterMatchMode.DATE_BEFORE};
                                }
                            } else if (matchMode === 'dateBefore') {
                                if (!!filterVarValue) {
                                    filters[filterVarName] = {value: [filterValueFrom, filterVarValue], matchMode: FilterMatchMode.BETWEEN};
                                } else {
                                    filters[filterVarName] = {value: filterValueFrom, matchMode: FilterMatchMode.DATE_AFTER};
                                }
                            }
                        } else {
                            if (filterVarValue) {
                                if (matchMode === 'dateBefore') {
                                    filterVarValue = new Date(moment(varValue).endOf('day'));
                                } else if (matchMode === 'dateAfter') {
                                    filterVarValue = new Date(moment(varValue).add(-1, 'hour'));
                                }
                                filters[filterVarName] = {value: filterVarValue, matchMode: matchMode};
                            } else {
                                delete filters[filterVarName];
                            }
                        }
                    }
                    break;
                case 'NUMBER':
                    varName = event.name;
                    varValue = isNaN(parseFloat(event.value)) ? 0 : parseFloat(event.value);
                    criteria[varName] = varValue;
                    if (!refreshFromBackend) {
                        filters[varName] = {value: varValue, matchMode: matchMode};
                    }
                    break;
                case 'DROPDOWN':
                    varName = event.target.name;
                    if (parameters && parameters.length > 0 && parameters[0]) {
                        varValue = event.target.value || event.target.value === '' ? event.target.value[parameters[0]] : undefined;
                    } else {
                        varValue = event.target.value || event.target.value === '' ? event.target.value : undefined;
                    }
                    criteria[varName] = varValue;
                    if (!refreshFromBackend) {
                        if (parameters && parameters[1] && varValue) {
                            filters[varName] = {value: varValue[parameters[1]], matchMode: FilterMatchMode.EQUALS};
                        } else {
                            filters[varName] = {value: varValue, matchMode: FilterMatchMode.EQUALS};
                        }
                    }
                    break;

                case 'RADIOBUTTON':
                    varName = event.target.name;
                    if (parameters && parameters !== '') {
                        varValue = event.target.value || event.target.value === '' ? event.target.value[parameters] : undefined;
                    } else {
                        varValue = event.target.value || event.target.value === '' ? event.target.value : undefined;
                    }
                    criteria[varName] = varValue;
                    if (!refreshFromBackend) {
                        filters[varName] = {value: varValue, matchMode: matchMode};
                    }
                    break;
                case 'AUTOCOMPLETE':
                    const multiple = parameters ? parameters[0] : true;
                    varName = event.target.name;
                    varValue = event.target.value || event.target.value === '' ? event.target.value : undefined;
                    if (Array.isArray(varValue)) {
                        criteria[varName] = varValue;
                        criteria[varName + 'Like'] = event.target.valueLike;
                    } else {
                        criteria[varName] = varValue;
                    }

                    break;
                case 'MULTI_SELECT':
                case 'CALENDAR_FROM':
                case 'CALENDAR':
                case 'TEXT':
                default:
                    varName = event.target.name;
                    varValue = event.target.value || event.target.value === '' ? event.target.value : undefined;
                    criteria[varName] = varValue;
                    if (!refreshFromBackend) {
                        if (parameters && parameters[0] && parameters[0] === 'global') {
                            filters['global'] = {value: varValue, matchMode: matchMode};
                        } else {
                            filters[varName] = {value: varValue, matchMode: matchMode};
                        }
                    }
                    break;
            }
        } else {
            console.log('handleChangeSC implementation error');
        }
        criteria[varName] = varValue;
        if (isMounted) {
            handleChangeScSetState(criteria, filters, refreshFromBackend, onAfterStateChange, timeoutHandler, delay);
        }
    }
}

export default HandleChangeUtils;
