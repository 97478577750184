import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class CriteriaTemplateService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'criteria-template';
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    getProjectCriteria(criteria) {
        return this.objToQueryString({
            name: criteria.name,
            clientName: criteria.clientName,
            dateFrom: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateTo: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateStartFrom: criteria.dateStartFrom ? moment(criteria.dateStartFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateStartTo: criteria.dateStartTo ? moment(criteria.dateStartTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateEndFrom: criteria.dateEndFrom ? moment(criteria.dateEndFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateEndTo: criteria.dateEndTo ? moment(criteria.dateEndTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            status: criteria.status?.enumValue,
            countryName: criteria.countryName,
            categories: criteria.categories ? criteria.categories.map((category) => category.id).join(',') : null,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    update(element) {
        return this.fetch(`${this.domain}/${this.path}/`, {
            method: 'PUT',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    add(element) {
        return this.fetch(`${this.domain}/${this.path}/`, {
            method: 'POST',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getCriteriaTemplateList(type) {
        return this.fetch(`${this.domain}/${this.path}/${type}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }


  
}
