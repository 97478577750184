import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import { t } from 'i18next';
import ActionLink from '../components/ActionLink';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import { Column } from 'primereact/column';
import NumberUtils from '../utils/NumberUtils';
import { Dropdown, Row } from 'react-bootstrap';
import { PrintButtonComponent } from '../report/ReportUtils';
import CriteriaMultiSelectComponent from '../components/criteria/CriteriaMultiSelectComponent';
import ProjectCategoryService from '../services/ProjectCategoryService';
import { Chip } from 'primereact/chip';
import { ColumnGroup } from 'primereact/columngroup'
import ProjectSettleReportService from '../services/ProjectSettleReportService';

class ProjectSettleReportListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ProjectSettleReportService());
        this.state = {
            list: [],
            loading: true,
            categories: [],
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dialogVisibile: false,
        };
        this.projectCategoryService=new ProjectCategoryService();
    }

    updateSearchCriteria(criteria) {
        return {
            projectName: criteria.projectName,
            clientName: criteria.clientName,
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            categories: criteria.categories,
            //exportType: criteria.exportType,
        };
    }

    getCleanSearchCriteria() {
        return {
            clientName: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'p_id',
            sortAsc: false,
            exportType: null,
            projectName: null,
            categories: [],
    
        };
    }

    getCriteriaName() {
        return 'projectSettleReport-list-sc';
    }

    getContainerListName() {
        return 'projectSettleReport-list-container';
    }
    getListTitle() {
        return 'projectSettleReport.listName';
    }

    initializeFromBackend(){
        this.projectCategoryService.getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
        .then(categories => this.setState({ categories: categories.content }))
        .catch(() => {
            this.showErrorMessage('Nie udało się pobrać listy kategorii');
        });
    }

    extraWorkSummaryTemplate(rowData) {
        const approvedHours = this.formatTimeIfPossible(rowData.extraApprovedHours);
        const totalHours = this.formatTimeIfPossible(rowData.extraTotalHours);
        return (
            <div style={{textAlign: 'right'}}>
                <div>{approvedHours}</div>
                <div>{totalHours}</div>
            </div>
        )
    }
    footerGroup() {
        const { summary } = this.state;
        return (
            <ColumnGroup>
                <Row>
                    <Column footer={t('list.summary')} />
                    <Column footer='' colSpan={5} footerStyle={{textAlign: 'right'}} />
                    <Column footer={this.formatTimeIfPossible(summary.totalTime)} footerStyle={{ textAlign: 'left', paddingRight: '16px' }} />
                    <Column footer={this.extraWorkSummaryTemplate.bind(this, summary)} colSpan={1} footerStyle={{textAlign: 'right'}} />
                    <Column footer='' colSpan={2} footerStyle={{textAlign: 'right'}} />
                    <Column footer={summary.totalPanelQuantity} footerStyle={{ textAlign: 'left', paddingRight: '16px' }} />
                    <Column footer={summary.totalKwp} footerStyle={{ textAlign: 'left', paddingRight: '16px' }} />
                    
                </Row>
            </ColumnGroup>
        );
    }


    renderCriteria() {
        return (
            <div className='row'>

                <CriteriaTextComponent
                    id='projectName-sc'
                    name='projectName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.projectName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('projectSettleReport.projectName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

                <CriteriaTextComponent
                    id='client-sc'
                    name='clientName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.clientName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('projectSettleReport.clientName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

                <CriteriaMultiSelectComponent
                    id='categories'
                    name='categories'
                    label={t('project.details.categories')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.categories}
                    optionLabel='name'
                    value={this.state.criteria.categories}
                    dataKey='id'
                    refreshFromBackend
                    timeoutHandler={this.refreshTable} />

                <CriteriaTextComponent
                    id='client-sc'
                    name='clientAddress'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.clientAddress}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('projectSettleReport.clientAddress')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    name='date'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    label={'Data'}
                    colClass='col-xl-6 col-lg-12 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    showButtonBar
                    sharedLabel
                    dateFormat="dd/mm/yy"
                    renderDash
                    maxDateInfinite
                    placeholderFrom={'Data od'}
                    placeholderTo={'Data do'}
                    inputsClassName='col-6'
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi-pencil'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => this.handleOpenEditDialog(e, rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                    rendered={false}
                />
            </span>
        );
    }


    formatTime(field, rowData) {
        const time = rowData[field];
        return this.formatTimeIfPossible(time);
    }
    formatTimeIfPossible(time) {
        if (NumberUtils.isNumber(time)) {
            const hours = Math.floor(time / 60);
            const mins = time % 60;
            return `${hours}:${mins.toString().padStart(2, '0')}`;
        } else {
            return '';
        }
    }
    extraWorkTemplate(rowData) {
        const approvedHours = this.formatTimeIfPossible(rowData.extraWorkApprovedHours);
        const totalHours = this.formatTimeIfPossible(rowData.extraWorkTotalHours);
        return (
            <div style={{textAlign: 'right'}}>
                <div>{approvedHours}</div>
                <div>{totalHours}</div>
            </div>
        )
    }

    prepareColumns() {
        return [
            {
                field: 'projectName',
                header: t('projectSettleReport.projectName'),
                sortable: true,
                sortField: 'project_name',
                width: '100px'
            },
            {
                field: 'clientName',
                header: t('projectSettleReport.clientName'),
                sortable: true,
                sortField: 'client_name',
            },
            {
                field: 'categories',
                header: t('projectSettleReport.category'),
                sortable: false,
                body: this.categoriesTemplate.bind(this),
            },
            {
                field: 'clientAddress',
                header: t('projectSettleReport.clientAddress'),
                sortable: true,
                sortField: 'city',
                body: this.addressTemplate.bind(this),
            },
            {
                field: 'dateStart',
                header: t('projectSettleReport.startDate'),
                sortable: true,
                sortField: 'date_start',
            },
            {
                field: 'dateEnd',
                header: t('projectSettleReport.endDate'),
                sortable: true,
                sortField: 'date_end',
            },
            {
                field: 'clientTime',
                header: t('projectSettleReport.clientTime'),
                sortable: true,
                sortField: 'client_time',      
                body: this.formatTime.bind(this, 'clientTime'),
            },
            {
                field: 'extraWork',
                header: t('projectSettleReport.extraWork'),
                sortable: false,
                body: this.extraWorkTemplate.bind(this),
            },            
            {
                field: 'panelRatio',
                header: t('projectSettleReport.panelRatio'),
                sortable: true,
                sortField: 'panel_ratio',      
            },
            {
                field: 'projectManager',
                header: t('projectSettleReport.projectManager'),
                sortable: true,
                sortField: 'lastname',      
            },
            {
                field: 'panelNumber',
                header: t('projectSettleReport.panelNumber'),
                sortable: true,
                sortField: 'panel_number',      
            },
            {
                field: 'kwpNumber',
                header: t('projectSettleReport.kwpNumber'),
                sortable: true,
                sortField: 'kwp_number',      
            },

       
        ];

    }
    addressTemplate(rowData) {
       const model = rowData.clientAddressModel;
       let street=model?.streetName+ ' ' + model?.streetNumber;

       if(model?.houseNumber!=null && model?.houseNumber.length>0){
         street+='/'+model?.houseNumber;
       }
   
        return (
        <span className='client-address-container'>
            <span className='client-address-header'>
                {model?.postalCode + ' ' + model?.city}
            </span>
            <span className='client-adress' style={{ whiteSpace: 'pre-wrap' }}>
                {street}
            </span>
            <span className='client-adress' style={{ whiteSpace: 'pre-wrap' }}>
                {model?.country}
            </span>
        </span>
        );
    }

    prepareHeaderItems() {
        return [

            {
                type: 'BUTTON',
                className: 'right-side',
                variant: 'white',
                disabled: !this.state.list || this.state.list.length < 1,
                customRenderFunction: this.renderPrintButton,
            },
        ];
    }

    renderPrintButton() {
        return (
            <PrintButtonComponent
                key={`print-button-pay-report`}
                reportFileNameBase={t('projectSettleReport.listName')}
                xml={false}
                pdf={false}
                csv={false}
                ods={false}
                xlsx={false}
                globalFormat='xlsx'
                noMenuFlag={true}
                generateReport={this.generateReport.bind(this)}
                disabled={!this.state.list.length > 0}
            />
        );
    }


    generateReport(format) {
        const { summary } = this.state;
        const criteria = this.state.criteria;
        const listSize = this.state.size;
        //const documentLimit = this.state.documentLimit;
        // if (listSize > documentLimit) {
        //     //return this.showErrorAboutLimitExceeded(documentLimit, listSize);
        // }
        switch (format) {
            case 'xlsx':
                return this.service.exportList(criteria, 'xlsx',summary);
            default:
                return Promise.reject('Nieznany format');
        }
    }

    handleOpenNewDialog(e) {
        let basicElement = this.initNewElement();
        if (e) {
            e.preventDefault();
        }
        this.setState({ dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW' });
    }

    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    categoriesTemplate(rowData) {
        if (rowData?.categories) {
            return (
                rowData.categories.map(c => {
                    return (
                        <Chip style={{backgroundColor:c?.color?.value, color:"black"}} label={c.name} />
                    )
                })
            )
        } else {
            return '';
        }
    }

    render() {
        return (
            <div className='container-fluid projectcategory-list'>
                <BreadcrumbsItem to='/project-settle-report'>{'Raport projekty rozliczenie'}</BreadcrumbsItem>
                {this.renderView()}
            </div>
        );
    }
}

ProjectSettleReportListContainer.defaultProps = {
    detailUrl: '/#/projectSettleReport/details',
    newUrl: '/#/projectSettleReport/create',
    lazy: true,
};

ProjectSettleReportListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default ProjectSettleReportListContainer;