import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import { ActionButton } from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import InputTextComponent from '../components/inputs/InputTextComponent';
import ToolsService from '../services/ToolsService';
import InputAutocompleteComponent from '../components/inputs/InputAutocompleteComponent';
import ActionLink from '../components/ActionLink';
import ToolService from '../services/ToolService';

class ToolsEditDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new ToolsService());
        this.state = {
            elementId: props.elementId,
            employees: [],
            filteredTools: [],
            element: {
                name: '',
                toolList: []
            },
            viewMode: this.props.viewMode,

        };
        this.toolService = new ToolService();
        this.searchTools = this.searchTools.bind(this);
    }
    translate(key) {
        const { t } = this.props;
        return t(key);
    }
    prepareFooter() {
        const { t } = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide(undefined);
                    }}
                />
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('tools.addButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.viewMode !== 'DELETE'}
                />
            </div>
        );
    }

    renderHeader() {
        const { t } = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('tools.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                                ? `${t('tools.editHeader')}`
                                : this.state.viewMode === 'DELETE'
                                    ? `${t('tools.deleteHeader')}`
                                    : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    updateElement(data) {
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    setElement() {
        if (this.props.element) {
            this.updateElement(this.props.element);
        }
    }
    getContainerListName() {
        return 'tools-list-container';
    }

    getAddSucces() {
        const { t } = this.props;
        return t('tools.addSuccess');
    }

    getUpdateSucces() {
        const { t } = this.props;
        return t('tools.updateSuccess');
    }
    createOrUpdate() {
        let { element } = this.state;
        this.scrollToTop();
        if (this.props._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getAddSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            } else {

                this.service
                    .update(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getUpdateSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            }
        }
    }



    renderDetails() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputTextComponent
                        id='name'
                        name='name'
                        label={'Nazwa'}
                        colClass='col-md-12'
                        value={this.state.element.name}
                        validator={this.validator}
                        validators='required|max:255'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                    />

                    <InputTextComponent
                        id='note'
                        name='note'
                        label={'Notatka'}
                        colClass='col-md-12'
                        value={this.state.element.note}
                        validator={this.validator}
                        validators='required|max:255'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                    />
                </DivContainer>
                {this.state.element.toolList !== undefined 
                && this.state.element.toolList.length > 0 ? <label className='p-label'> Narzędzia </label> : null}
                <DivContainer colClass='row'
                    rendered={true}
                >

                    {this.state.element.toolList !== undefined ?
                        this.state.element.toolList.map((tool, rowIndex) => {

                            return (

                                <div key={`tool-container-${rowIndex}`} className="mb-3">
                                    <div className="row">
                                    <div className="col-md-10">
                                        <InputAutocompleteComponent
                                            id={`tool${rowIndex}`}
                                            label={t('project.details.tool')}
                                            value={tool}
                                            name="array"
                                            dropdown
                                            forceSelection
                                            viewMode={this.props.viewMode}
                                            validator={this.validator}
                                            validators='required'
                                            showLabel={false}
                                            onChange={this.handleChange}
                                            insideTable
                                            filteredList={this.state.filteredTools}
                                            filterList={(query) => this.searchTools(query, tool?.id)}
                                            field="name"
                                            stateField={`element.toolList[${rowIndex}]`}
                                            minLength={3}
                                            placeholder={t('search.tool')}
                                        />
                                    </div>
                                    <div className="col-md-1">
                                        <ActionLink
                                            iconName='mdi-delete'
                                            iconSide='left'
                                            iconSize='icon-large'
                                            rendered={this.props.viewMode !== 'VIEW'}
                                            handleClick={(e) => {

                                                let tools = this.state.element.toolList.filter(t => t != tool);

                                                this.setState((prevState) => ({
                                                    element: {
                                                        ...prevState.element,
                                                        toolList: tools,
                                                    },
                                                }));
                                            }}
                                            variant='blue'
                                            className='edit-blue-link'
                                            key={'delete-button'}
                                        />
                                    </div>
                                </div>

                                </div>
                            );
                        }) : null
                    }
                </DivContainer>
                <DivContainer
                    rendered={this.props.viewMode !== 'VIEW'}
                >
                    <ActionLink
                        label="Dodaj narzędzie"
                        iconName='mdi-plus'
                        iconSide='left'
                        iconSize='icon-large'
                        variant='blue'
                        className='float-right'
                        key={'add-activity-button'}
                        handleClick={this.handleAddNewTools.bind(this)}
                    />
                </DivContainer>
            </React.Fragment>
        );
    }

    handleAddNewTools() {
        const tools = this.state.element.toolList == undefined ? [] : this.state.element.toolList;
        tools.push(null);
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                toolList: tools
            },
        }));
    }

    searchTools(query, toolId) {
        //removing already defined
        const tools = this.state.element.toolList == undefined ? [] : this.state.element.toolList;
        let toolIds = tools.filter(x => x != undefined).map(t => t.id).filter(id => toolId != id);
        this.toolService
            .searchTools(query)
            .then(filteredTools => this.setState({ filteredTools: filteredTools.filter(e => !toolIds.includes(e.id)) }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy narzędzi');
            });
    }
}

ToolsEditDialog.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
    visible: false,
};

ToolsEditDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    className: PropTypes.string,
};

export default withTranslation()(ToolsEditDialog);
