import BaseServiceCrud from "./BaseServiceCrud";
import moment from 'moment';
import Constants from '../utils/constants';

export default class LogEntryService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'logentry';
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            dateFrom: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT_TIME) : undefined,
            dateTo: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT_TIME) : undefined,
            code: criteria?.code?.enumValue,
            query: criteria.query,
            affectedObjectType: criteria.objectType?.enumValue,
            affectedObjectId: criteria.objectId,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }
    getLogEntryByAffectedObjectId(id){
        return this.fetch(`${this.domain}/${this.path}/affected-object/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    
}
