import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import ActionLink from '../../components/ActionLink';
import YesNoDialog from '../../components/YesNoDialog';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import ClientService from '../../services/ClientService';
import CountryService from '../../services/CountryService';

class ClientListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new ClientService());

        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            dialogVisibile: false,
            deleteConfirmDialogVisible: false,
        };
        this.countryService = new CountryService();
    }

    updateSearchCriteria(criteria) {
        return {
            login: criteria.login,
            firstName: criteria.firstName,
            lastName: criteria.lastName,
            roleType: criteria.roleType,
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            name: '',
            address: '',
            status: {
                enumValue: 'ACTIVE',
                label: t('ClientStatus.ACTIVE'),
            },
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        this.enumService
            .getEnumList('ClientStatus')
            .then((statusOptions) => {
                statusOptions.forEach((o) => (o.label = t('ClientStatus.' + o.enumValue)));
                this.setState({
                    statusOptions,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });

        this.countryService
            .getAll()
            .then((countries) => {
                this.setState({
                    countries,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy krajów');
            });
    }

    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_CTO','ROLE_USER')
            ? [
                  {
                      label: t('client.list.new'),
                      type: 'BUTTON',
                      className: 'float-right',
                      variant: 'blue',
                      iconName: 'mdi-plus',
                      iconSide: 'left',
                      iconColor: 'white',
                      onClick: this.handleGoToNew,
                  },
              ]
            : [];
    }

    getCriteriaName() {
        return 'client-list-sc';
    }

    getContainerListName() {
        return 'client-list-container';
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='name'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.name}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('client.list.criteria.clientName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='address-sc'
                    name='address'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.address}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('client.list.criteria.address')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={t('client.list.criteria.status')}
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
            </div>
        );
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <span>
                <ActionLink
                    iconName='mdi-eye'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={this.handleGoToDetail.bind(this, href)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                />
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => this.handleOpenDeleteDialog(e, rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                    rendered={rowData.status.enumValue === 'ACTIVE'}
                />
            </span>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    prepareColumns() {
        return [
            {field: 'name', header: t('client.list.clientName'), sortable: true},
            {field: 'address.country.name', header: t('client.list.countryName'), sortable: true},
            {field: 'address.postalCode', header: t('client.list.postalCode'), sortable: true},
            {field: 'address.city', header: t('client.list.city'), sortable: true},
            {field: 'address.streetName', header: t('client.list.streetName'), sortable: true},
            {field: 'status', header: 'Status', sortable: true, body: this.enumTemplate.bind(this, 'status')},
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }

    handleOpenDeleteDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({deleteConfirmDialogVisible: true, element: rowData, dialogViewMode: 'DELETE'});
    }

    handleDelete(e) {
        if (e) {
            e.preventDefault();
        }
        console.log('delete {}', this.state.element);
        this.service
            .remove(this.state.element)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('client.message.deleteSuccess'));
            })
            .catch((err) => {
                this.persistMessage('error', '', t('client.message.deleteError'));
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({deleteConfirmDialogVisible: false});
            });
    }

    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    render() {
        return (
            <div className='container-fluid'>
                <BreadcrumbsItem to='/client-list'>{t('client.listName')}</BreadcrumbsItem>
                {this.renderView()}
                {this.state.deleteConfirmDialogVisible ? (
                    <YesNoDialog
                    visible={this.state.deleteConfirmDialogVisible}
                    header={t('client.details.header.delete')}
                    name='visible'
                    onChange={(type, x, target) => {
                        if (target.value) {
                        
                            this.handleDelete();
                        } else {
                            this.setState({deleteConfirmDialogVisible: false})
                        }
                    }}
                    onHide={() => this.setState({deleteConfirmDialogVisible: false})}
                     >
                        {t('details.deleteConfirmation') + '\n' + this.state.element?.name}
                    </YesNoDialog>
                ) : null}
            </div>
        );
    }
}

ClientListContainer.defaultProps = {
    detailUrl: '/#/client/details',
    newUrl: '/#/client/create',
    lazy: true,
};

ClientListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default ClientListContainer;
