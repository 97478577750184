import BaseServiceCrud from './BaseServiceCrud';

export default class StartPageService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'start-page';
    }

    getNewsfeed() {
		return this.fetch(`${this.domain}/${this.path}/get-newsfeed`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}

    getOfferForYou() {
		return this.fetch(`${this.domain}/${this.path}/get-offer-for-you`, {
			method: 'GET',
		}).then((res) => {
			return Promise.resolve(res);
		});
	}
}
