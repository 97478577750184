import React, {Component} from 'react';

class BaseInputComponent extends Component {
    renderValidatorsMessages(validator, id, label, value, validators) {
        if (validator !== null && validator !== undefined) {
            if (Array.isArray(validator)) {
                return (
                    <div aria-live='assertive'>
                        {validator.map((val) => {
                            return val.message(id, label, value, validators);
                        })}
                    </div>
                );
            } else {
                return <div aria-live='assertive'>{validator.message(id, label, value, validators)}</div>;
            }
        } else {
            return null;
        }
    }

    baseRenderLabel(id, label, showLabel, titleElement, renderRequired, renderLabel) {
        if (renderLabel) {
            return renderLabel();
        } else if (label !== undefined && showLabel && !titleElement) {
            return (
                <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                    {label}
                    {renderRequired ? <span className='icon-svg left-label red-star '>*</span> : null}
                </label>
            );
        } else {
            return null;
        }
    }
}

export default BaseInputComponent;
