import React from 'react';
import PropTypes from 'prop-types';
import {Dialog} from 'primereact/dialog';
import {withTranslation} from 'react-i18next';

export const CustomDialog = (props) => {
    const {children, closeable, className, header, id, modal, name, onHide, rendered, renderHeader, resizable, visible} = props;
    return rendered && visible ? (
        <Dialog
        
            id={id ? id : name}
            key={`${id ? id : name}-dialog`}
            ariaCloseIconLabel='Zamknij okno dialogowe'
            header={renderHeader ? renderHeader : header}
            visible={visible}
            className={className}
            modal={visible && modal}
            onHide={() => (onHide ? onHide() : null)}
            closeable={closeable}
            resizable={resizable}
        >
            {children}
        </Dialog>
    ) : null;
};

CustomDialog.defaultProps = {
    modal: false,
    rendered: true,
    closeable: false,
    visible: false,
    resizable: false,
};

CustomDialog.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
    header: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    id: PropTypes.string,
    modal: PropTypes.bool,
    name: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    rendered: PropTypes.bool,
    visible: PropTypes.bool.isRequired,
    resizable: PropTypes.bool,
    closeable: PropTypes.bool,
};

export default withTranslation()(CustomDialog);
