import PropTypes from 'prop-types';
import React from 'react';
import InputTextComponent from './inputs/InputTextComponent';
import SimpleReactValidator from './validator';
import { t } from 'i18next';
import InputDropdownComponent from './inputs/InputDropdownComponent';

export const ProjectAddress = ({
    element,
    validator,
    handleChange,
    viewMode,
    stateField,
    countries
})=> {
    return  (
        <React.Fragment>
            <div className='row'>
                <InputDropdownComponent
                        id='country'
                        name='country'
                        label={t('address.country')}
                        colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                        value={element?.projectAddress?.address?.country}
                        validator={validator}
                        options={countries}
                        onChange={handleChange}
                        viewMode={viewMode}
                        optionLabel='name'
                        dataKey='id'
                        validators='required'
                        valueView={element?.projectAddress?.address?.country?.name}
                        stateField={stateField}
                        filter
                        filterBy='name'
                    />
                <InputTextComponent
                        id='postalCode'
                        name='postalCode'
                        label={t('address.postalCode')}
                        colClass='col-xl-2 col-lg-6 col-md-6 col-sm-12'
                        value={element?.projectAddress?.address?.postalCode}
                        validator={validator}
                        validators='not_required|max:10'
                        onChange={handleChange}
                        viewMode={viewMode}
                        stateField={stateField}
                />
                <InputTextComponent
                        id='city'
                        name='city'
                        label={t('address.city')}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={element?.projectAddress?.address?.city}
                        validator={validator}
                        validators='not_required|max:100'
                        onChange={handleChange}
                        viewMode={viewMode}
                        stateField={stateField}
                />
                <InputTextComponent
                        id='streetName'
                        name='streetName'
                        label={t('address.streetName')}
                        colClass='col-xl-6 col-lg-6 col-md-6 col-sm-12'
                        value={element?.projectAddress?.address?.streetName}
                        validator={validator}
                        validators='not_required|max:20'
                        onChange={handleChange}
                        viewMode={viewMode}
                        stateField={stateField}
                />
                <InputTextComponent
                        id='streetNumber'
                        name='streetNumber'
                        label={t('address.streetNumber')}
                        colClass='col-xl-3 col-lg-6 col-md-6 col-sm-12'
                        value={element?.projectAddress?.address.streetNumber}
                        validator={validator}
                        validators='not_required|max:100'
                        onChange={handleChange}
                        viewMode={viewMode}
                        stateField={stateField}
                />
                <InputTextComponent
                        id='houseNumber'
                        name='houseNumber'
                        label={t('address.houseNumber')}
                        colClass='col-xl-3 col-lg-6 col-md-6 col-sm-12'
                        value={element?.projectAddress?.address?.houseNumber}
                        validator={validator}
                        validators='not_required|max:20'
                        onChange={handleChange}
                        viewMode={viewMode}
                        stateField={stateField}
                />   
            </div>
        </React.Fragment>
    );
    
}

ProjectAddress.defaultProps = {
    element: null,
    viewMode: 'VIEW',
    stateField: 'element.projectAddress.address',
    colClass: 'col-md-4',
};

ProjectAddress.propTypes = {
    element: PropTypes.object,
    stateField: PropTypes.string,
    viewMode: PropTypes.string.isRequired,
    handleChange: PropTypes.func.isRequired,
    validator: PropTypes.instanceOf(SimpleReactValidator).isRequired,
    colClass: PropTypes.string,
};

