/*********************************************************************** 
Documentations = https://github.com/azouaoui-med/pro-sidebar-template
************************************************************************/
import React, {useState} from 'react';
import {withTranslation} from 'react-i18next';
import {FaChartLine, FaCog, FaCubes, FaIndustry, FaUser, FaAddressCard, FaUsersCog, FaGripVertical, FaBed} from 'react-icons/fa';
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarFooter, SidebarHeader, SubMenu} from 'react-pro-sidebar';
import {Link} from 'react-router-dom';
import i18n from '../../i18n';
import ActionButton from '../ActionButton';
import I18nUtils from '../utils/I18nUtils';
const Sidebar = ({
    t,
    collapsed,
    toggled,
    loggedUser,
    handleToggleSidebar,
    handleCollapsedChange,
    handleLogoutUser,
    authService,
    accountService,
    internalUserService,
    submenu,
}) => {
    // const userName = authService.getProfile().sub;
    // const role = authService.getProfile().role;
    const [showMenu, changeShowMenu] = useState(false);
    const [showFuntionsMenu, changeShowFunctionsMenu] = useState(false);
    const [showAgreements, changeShowAgreements] = useState(false);
    const [showLanguages, changeShowLanguages] = useState(false);

    function changeLanguage(language) {
        if (internalUserService !== null) {
            internalUserService.changeLanguage(language).then(() => {
                sessionStorage.setItem('i18nextLng', language);
                i18n.changeLanguage(language);
            });
        }
    }

    function changeRoleType(sapRoleId) {
        if (internalUserService !== null && authService !== null) {
            internalUserService.changeRoleType(sapRoleId).then((res) => {
                authService.setToken(res.token);
                window.location.href = '/#start';
                window.location.reload();
            });
        }
    }

    function renderOverlay() {
        return (
            <div
                className='show-menu-overlay'
                onClick={(e) => {
                    e.preventDefault();
                    if (showMenu || showFuntionsMenu) {
                        changeShowMenu(false);
                        changeShowFunctionsMenu(false);
                        changeShowLanguages(false);
                        changeShowAgreements(false);
                    }
                }}
            />
        );
    }

    function loader() {
        let label = 'Operacja w toku, proszę czekać.';
        return (
            <div id='cover-spin-container'>
                <div id='cover-spin' />
                <div id='cover-spin-text'>
                    <p>{label}</p>
                </div>
            </div>
        );
    }

    function renderHorizontalSidebar() {
        return !authService.loggedIn() && !submenu ? null : (
            <div style={{width: '100%'}}>
                <div className='sidebar-horizondtal-content'>
                    <ActionButton
                        label={t('sidebar.sidebarMenuButton')}
                        variant={showMenu ? 'sidebar-button menu-close' : 'sidebar-button menu'}
                        handleClick={(e) => {
                            e.preventDefault();
                            changeShowMenu(!showMenu);
                            changeShowFunctionsMenu(false);
                            changeShowLanguages(false);
                            changeShowAgreements(false);
                        }}
                        iconSvg={showMenu ? 'ico-sidebar-close' : 'ico_sidebar_menu'}
                        iconSide='left'
                        iconSize={showMenu ? 'menu-close' : 'menu'}
                    />
                    {/* <img className='sidebar-header-logo' alt='logo' src='/images/timehero-logo.png' /> */}
                    <span />
                </div>
                <div className={`sidebar ${showMenu ? 'show-menu' : ''}`}>{renderVerticalSidebar()}</div>
                <div className={`sidebar functions-menu ${showFuntionsMenu ? 'show-menu' : ''}`}>{renderFunctionsSidebar()}</div>
            </div>
        );
    }

    function renderVerticalSidebar() {
        const roleAdmin = authService.isUserInRole('ROLE_ADMIN');
        const rolePm = authService.isUserInRole('ROLE_USER');
        const roleSupervisor = authService.isUserInRole('ROLE_SUPERVISOR');
        const roleCto = authService.isUserInRole('ROLE_CTO');
        const roleFm = authService.isUserInRole('ROLE_FINANCIAL_MANAGER');
        const roleEm = authService.isUserInRole('ROLE_EXTERNAL_MANAGER');

        const demoMode = process.env.REACT_APP_DEMO_MODE === "true";


        return !authService.loggedIn() ? null : (
            <React.Fragment>
                <div className={`pro-sidebar-background ${showMenu ? 'show-menu' : ''}`} />
                {renderOverlay()}
                <ProSidebar collapsed={collapsed} toggled={toggled} onToggle={handleToggleSidebar} className='pro-sidebar-layout'>
                    {submenu ? null : (
                        <SidebarHeader>
                            <div className='sidebar-header-logo-div' />
                        </SidebarHeader>
                    )}
                    <SidebarContent>
                        <Menu>
                            <Menu>
                                <MenuItem icon={<FaUser />}>
                                    Profil
                                    <Link to='/profile' />
                                </MenuItem>
                            </Menu>

                            <Menu>
                                <SubMenu title='Projekty' icon={<FaCubes />}>
                                    <MenuItem>
                                        Lista projektów
                                        <Link to='/project-list' />
                                    </MenuItem>
                                    {roleAdmin || rolePm || roleCto || roleSupervisor || roleEm ? (
                                        <MenuItem>
                                            Lista kart pracy
                                            <Link to='/reported-day-list' />
                                        </MenuItem>
                                    ) : null}
                                </SubMenu>
                            </Menu>
                            {roleAdmin || rolePm || roleCto || roleSupervisor || roleEm ? (
                                <Menu>
                                    <SubMenu title='Planowanie' icon={<FaCubes />}>
                                        <MenuItem>
                                            Tablica
                                            <Link to='/project-dashboard' />
                                        </MenuItem>
                                        {roleAdmin || rolePm || roleCto || roleEm ? (
                                            <MenuItem>
                                                Planing
                                                <Link to='/project/plan/managers' />
                                            </MenuItem>
                                        ) : null}
                                        {roleAdmin || rolePm || roleCto ? (
                                            <MenuItem /*icon={<FaAddressCard />}*/>
                                                Rezerwa
                                                <Link to='/reserve' />
                                            </MenuItem>
                                        ) : null}
                                    </SubMenu>
                                </Menu>
                            ) : null}
                            {roleAdmin || rolePm || roleCto ? (
                            <Menu>
                                <SubMenu title='Noclegi' icon={<FaBed />}>
                                     <MenuItem>
                                        Lista
                                        <Link to='/accommodation-list' />
                                    </MenuItem>
                                    <MenuItem>
                                        Plan dla miejsca
                                        <Link to='/accommodation-timeline' />
                                    </MenuItem>
                                    <MenuItem>
                                        Plan dla pracownika
                                        <Link to='/accommodation-employee-timeline' />
                                    </MenuItem>
                                    <MenuItem>
                                        Kategorie noclegu
                                        <Link to='/accommodation-category'/>
                                    </MenuItem>
                                    <MenuItem>
                                         Tablica
                                        <Link to='/accommodation-dashboard'/>
                                    </MenuItem>
                                </SubMenu>
                                </Menu>
                            ) : null}
                            {roleAdmin || rolePm || roleCto ? (
                            <Menu>
                                <SubMenu title='Kontrahenci' icon={<FaIndustry />}>
                                    {roleAdmin || rolePm || roleCto ? (
                                        <MenuItem>
                                            Klienci
                                            <Link to='/client-list' />
                                        </MenuItem>
                                    ) : null}
                                    <MenuItem>
                                        {t('externalSupervisor.menu')}
                                        <Link to='/external-supervisor/list' />
                                    </MenuItem>
                                    <MenuItem>
                                        {t('subcontractor.menu')}
                                        <Link to='/subcontractor-list' />
                                    </MenuItem>
                                </SubMenu>
                            </Menu>
                            ) : null}
                            <Menu>
                                <SubMenu title='Raporty' icon={<FaChartLine />}>
                                    {roleAdmin || rolePm || roleCto || roleSupervisor || roleEm ? (
                                        <MenuItem>
                                            Raport godzinowy
                                            <Link to='/hourlyreport-list' />
                                        </MenuItem>
                                    ) : null}
                                    {roleAdmin || rolePm || roleCto ? (
                                        <MenuItem>
                                            Przejazdy po godzinach pracy
                                            <Link to='/after-hours-drive-alarm-list' />
                                        </MenuItem>
                                    ) : null}
                                    {roleAdmin || rolePm || roleCto || roleSupervisor || roleEm ? (
                                        <MenuItem>
                                            Projekty narastająco
                                            <Link to='/project-report-cumulative' />
                                        </MenuItem>
                                    ) : null}
                                    {roleAdmin || rolePm || roleCto || roleSupervisor || roleEm ? (
                                        <MenuItem>
                                            Projekty rozliczenie
                                            <Link to='/project-report-settle' />
                                        </MenuItem>
                                    ) : null}
                                    {roleFm || roleAdmin || roleCto || roleEm ? (
                                        <MenuItem>
                                            Wypłaty
                                            <Link to='/pay-report' />
                                        </MenuItem>
                                    ) : null}
                                </SubMenu>
                            </Menu>

                            {roleAdmin || rolePm || roleCto || roleEm ? (
                                <Menu>
                                    <SubMenu title='Zarządzanie pracownikami' icon={<FaUsersCog />}>
                                        {roleAdmin || rolePm || roleCto || roleEm ? (
                                        <MenuItem>
                                            Pracownicy
                                            <Link to='/employee-list' />
                                        </MenuItem>
                                        ) : null}
                                        {roleAdmin || rolePm || roleCto ? (
                                        <MenuItem>
                                            Użytkownicy wewnętrzni
                                            <Link to='/user-list' />
                                        </MenuItem>
                                        ) : null}
                                        {roleAdmin || rolePm || roleCto ? (
                                        <MenuItem>
                                            {t('employeeCategory.menu')}
                                            <Link to='/employeecategory-list' />
                                        </MenuItem>
                                        ) : null}
                                        {roleAdmin || rolePm || roleCto ? (
                                        <MenuItem>
                                            {t('documentType.menu')}
                                            <Link to='/document-types-list' />
                                        </MenuItem>
                                        ) : null}
                                        {roleAdmin || rolePm || roleCto ? (
                                        <MenuItem>
                                            {t('employee.absence.menu')}
                                            <Link to='/employee-absence-reason-list' />
                                        </MenuItem>
                                        ) : null}
                                        {roleAdmin || roleFm || roleCto ? (
                                        <MenuItem>
                                            {t('driver-rate.menu')}
                                            <Link to='/driver-rate-list' />
                                        </MenuItem>
                                        ) : null}
                                    </SubMenu>
                                </Menu>
                            ) : null}

                            {roleAdmin || rolePm || roleCto ? (
                                <Menu>
                                    <SubMenu title='Komponenty projektu' icon={<FaGripVertical />}>
                                        <MenuItem>
                                            {t('projectCategory.menu')}
                                            <Link to='/projectcategory-list' />
                                        </MenuItem>
                                        <MenuItem>
                                            {t('activity.menu')}
                                            <Link to='/activity-list' />
                                        </MenuItem>
                                        <MenuItem>
                                            {t('car.menu')}
                                            <Link to='/car-list' />
                                        </MenuItem>
                                        <MenuItem>
                                            Narzędzia
                                            <Link to='/tool-list' />
                                        </MenuItem>
                                        <MenuItem>
                                            Zestawy Narzędzi
                                            <Link to='/tools-list' />
                                        </MenuItem>
                                    </SubMenu>
                                </Menu>
                            ) : null}

                            {roleAdmin || rolePm || roleCto ? (
                                <Menu>
                                    <SubMenu title='Administracja' icon={<FaCog />}>
                                        {roleAdmin ? (
                                        <MenuItem>
                                            Kraje
                                            <Link to='/country-list' />
                                        </MenuItem>
                                        ) : null}
                                        {roleAdmin ? (
                                            <MenuItem>
                                                {t('email.menu')}
                                                <Link to='/email-list' />
                                            </MenuItem>
                                        ) : null}
                                        {roleAdmin && !demoMode ? (
                                            <MenuItem>
                                                Parametry aplikacji
                                                <Link to='/settings-list' />
                                            </MenuItem>
                                        ) : null}
                                        <MenuItem>
                                            {t('logEntry.list.menu')}
                                            <Link to='/eventlog-list' />
                                        </MenuItem>
                                    </SubMenu>
                                </Menu>
                            ) : null}
                        </Menu>
                        <SidebarFooter>
                            <span className='sidebar-version p-2'>
                                wersja: {process.env.REACT_APP_VERSION} ({process.env.REACT_APP_BUILD_NUMBER})
                            </span>
                        </SidebarFooter>
                    </SidebarContent>
                </ProSidebar>
            </React.Fragment>
        );
    }

    function renderFunctionsSidebar() {
        const supportedLanguages = I18nUtils.getSupportedLanguages(3);
        const languages = [];
        supportedLanguages.forEach((l) => {
            languages.push({value: l, label: t(`header.${l}`)});
        });
        return !authService.loggedIn() ? null : (
            <React.Fragment>
                {renderOverlay()}
                <ProSidebar
                    collapsed={collapsed}
                    toggled={toggled}
                    onToggle={handleToggleSidebar}
                    className='pro-sidebar-layout functions-menu'
                >
                    <SidebarContent>
                        <Menu>
                            {authService.getProfile().sapRoleIdsList?.length > 1 ? (
                                <MenuItem key='agreement' className='sidebar-submenu'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeShowAgreements(!showAgreements);
                                            changeShowLanguages(false);
                                        }}
                                    >
                                        {t('header.agreement')}
                                    </div>
                                    <Menu key='agreement-items' className={`sidebar-submenu-items ${showAgreements ? 'show' : ''}`}>
                                        {authService.getProfile().sapRoleIdsList.map((el) => {
                                            return (
                                                <MenuItem key={`agreement-${el}`} className='agreement-item'>
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            changeShowAgreements(false);
                                                            changeShowFunctionsMenu(false);
                                                            if (el !== authService.getProfile().agreementSapRoleId) {
                                                                changeRoleType(el);
                                                            }
                                                        }}
                                                    >
                                                        {t(`header.${el}`)}
                                                    </div>
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                </MenuItem>
                            ) : null}
                            {languages.length > 1 ? (
                                <MenuItem key='language' className='sidebar-submenu'>
                                    <div
                                        onClick={(e) => {
                                            e.preventDefault();
                                            changeShowLanguages(!showLanguages);
                                            changeShowAgreements(false);
                                        }}
                                    >
                                        {t('header.language')}
                                    </div>
                                    <Menu key='language-items' className={`sidebar-submenu-items ${showLanguages ? 'show' : ''}`}>
                                        {languages.map((el) => {
                                            return (
                                                <MenuItem key={`language-${el}`} className='language-item'>
                                                    <div
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            changeShowLanguages(false);
                                                            changeShowFunctionsMenu(false);
                                                            if (el.value !== sessionStorage.getItem('i18nextLng')) {
                                                                changeLanguage(el.value);
                                                            }
                                                        }}
                                                    >
                                                        {el.label}
                                                    </div>
                                                </MenuItem>
                                            );
                                        })}
                                    </Menu>
                                </MenuItem>
                            ) : null}
                        </Menu>
                    </SidebarContent>
                    <SidebarFooter>
                        <span className='sidebar-version'>
                            wersja: {process.env.REACT_APP_VERSION} ({process.env.REACT_APP_BUILD_NUMBER})
                        </span>
                    </SidebarFooter>
                </ProSidebar>
            </React.Fragment>
        );
    }

    function render() {
        return submenu ? renderHorizontalSidebar() : renderVerticalSidebar();
    }
    return render();
};

export default withTranslation()(Sidebar);
