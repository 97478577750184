import $, {contains} from 'jquery';
import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {Route, HashRouter as Router, Switch} from 'react-router-dom';
import CookieConsent from './components/CookieConsent';
import Sidebar from './components/layouts/Sidebar';
import CookiesContainer from './containers/CookiesContainer';
import HelpContainer from './containers/HelpContainer';
import Login from './containers/LoginContainer';
import StartContainer from './containers/StartContainer';
import VersionContainer from './containers/VersionContainer';
import AccountService from './services/AccountService';
import AuthService from './services/AuthService';
import SettingService from './services/SettingService';
import InternalUserService from './services/InternalUserService';
import withAuth from './utils/withAuth';
import InternalUserListContainer from './containers/InternalUserListContainer';
import InternalUserContainer from './containers/InternalUserContainer';
import SubcontratorListContainer from './containers/SubcontratorListContainer';
import ApplicationSettingsListContainer from './containers/ApplicationSettingsListContainer';
import ProjectCategoryListContainer from './containers/ProjectCategoryListContainer';
import ToolsListContainer from './containers/ToolsListContainer';
import ActivityListContainer from './containers/ActivityListContainer';
import CarListContainer from './containers/CarListContainer';
import EmployeeListContainer from './containers/employee/EmployeeListContainer';
import EmployeeContainer from './containers/employee/EmployeeContainer';
import ConfirmPasswordContainer from './containers/auth/ConfirmPasswordContainer';
import RemindPasswordContainer from './containers/auth/RemindPasswordContainer';
import ChangePasswordContainer from './containers/auth/ChangePasswordContainer';
import ClientListContainer from './containers/client/ClientListContainer';
import ProjectListContainer from './containers/project/ProjectListContainer';
import HourlyReportListContainer from './containers/HourlyReportListContainer';
import ToolListContainer from './containers/ToolListContainer';
import ProjectDetailsContainer from './containers/ProjectDetailsContainer';
import ToolsDetailsContainer from './containers/ToolsDetailsContainer';
import DriverRateListContainer from './containers/DriverRateListContainer';
import ClientContainer from './containers/client/ClientContainer';
import ProfileContainer from './containers/profile/ProfileContainer';
import ReportedDayListContainer from './containers/ReportedDayListContainer';
import ReportedDayDetailsContainer from './containers/ReportedDayDetailsContainer';
import PayReportListContainer from './containers/PayReportListContainer';
import LogEntryListContainer from './containers/LogEntryListContainer';
import ProjectCumulativeReportListContainer from './containers/ProjectCumulativeReportListContainer';
import ToolDetailsContainer from './containers/ToolDetailsContainer';
import EmailListContainer from './containers/EmailListContainer';
import EmailEditContainer from './containers/EmailDetailsContainer';
import ProjectSettleReportListContainer from './containers/ProjectSettleReportListContainer';
import DocumentTypeListContainer from './containers/DocumentTypeListContainer';
import EmployeeAbsenceReasonList from './containers/employee/EmployeeAbsenceReasonList';
import EmployeeCategoryListContainer from './containers/EmployeeCategoryListContainer';
import ManagerTimelineContainer from './containers/ManagerTimelineContainer';
import ReserveContainer from './containers/reserve/ReserveContainer';
import ProjectDashboardContainer from './containers/project/ProjectDashboardContainer';
import ProjectDashboardFullScreenContainer from './containers/project/ProjectDashboardFullScreenContainer';
import CountryListContainer from './containers/CountryListContainer';
import ExternalSupervisorList from './containers/ExternalSupervisorList';
import AfterHoursDriveAlarmListContainer from './containers/AfterHoursDriveListContainer';
import UpdateAppDialog from './containers/UpdateAppDialog.js';

import AccommodationListContainer from './containers/accommodation/AccommodationListContainer';
import AccommodationContainer from './containers/accommodation/AccommodationContainer';
import AccommodationTimelineContainer from './containers/accommodation/AccommodationTimelineContainer';
import EmployeeAccommodationTimelineContainer from './containers/accommodation/EmployeeAccommodationTimelineContainer';
import AccommodationCategoryContainer from './containers/accommodation/AccommodationCategoryContainer';
import AccommodationDashboardContainer from './containers/accommodation/AccommodationDashboardContainer';
import AcommodationDashboardFullScreenContainer from './containers/accommodation/AcommodationDashboardFullScreenContainer';


class App extends Component {
    constructor() {
        super();
        this.authService = new AuthService();
        this.internalUserService = new InternalUserService();
        this.settingService = new SettingService();
        this.accountService = new AccountService();
        this.state = {
            user: this.authService.getProfile(),
            collapsed: false,
            toggled: false,
            renderSidebar: true,
            appInfo: {
                version: process.env.REACT_APP_VERSION,
                buildNumber: process.env.REACT_APP_BUILD_NUMBER,
                lastVersionReleaseNote: process.env.REACT_APP_LAST_VERSION_RELEASE_NOTE,
            },
            updateAppDialogVisible: false,
        };
        this.handleLogoutUser = this.handleLogoutUser.bind(this);
        this.handleHideMenu = this.handleHideMenu.bind(this);
        this.handleShowMenu = this.handleShowMenu.bind(this);
        this.setActiveMenu = this.setActiveMenu.bind(this);
        this.handlePreLogoutUser = this.handlePreLogoutUser.bind(this);
        this.updateApp = this.updateApp.bind(this);
    }

    componentDidMount() {
        if (this.authService.getFromSessionToken('id_token') === null) {
            this.authService.setFromLocalStorageToSessionStorageToken();
        }
        window.addEventListener('beforeunload', function (event) {
            localStorage.removeItem('id_token');
        });
    }


    setActiveMenu(activeUrl) {
        if (activeUrl !== this.state.activeUrl) {
            this.setState({activeUrl});
        }
    }

    updateApp(oldVersion, newVersion) {
        if (this.state.updateAppDialogVisible !== true) {
            this.setState({updateAppDialogVisible: true, appOldVersion: oldVersion, appNewVersion: newVersion});
        }
    }

    handleHideMenu() {
        if (!!this.state.renderSidebar) {
            this.setState({renderSidebar: false});
        }
    }

    handleShowMenu() {
        if (!this.state.renderSidebar) {
            this.setState({renderSidebar: true});
        }
    }

    handleToggleSidebar() {
        this.setState((prevState) => ({toggled: !prevState.toggled}));
    }

    handleCollapsedChange() {
        this.setState(
            (prevState) => ({collapsed: !prevState.collapsed}),
            () => {
                if (this.state.collapsed) {
                    $('.pro-sidebar-inner').css('position', 'relative');
                } else {
                    $('.pro-sidebar-inner').css('position', 'fixed');
                }
            }
        );
    }

    handlePreLogoutUser(callBack) {
        this.authService.logout();
        if (this.state.user !== null) {
            this.setState({user: null}, () => this.afterPreLogoutUser());
            this.setState({user: null}, () => {
                if (callBack) callBack();
            });
        } else {
            if (callBack) callBack();
        }
    }

    handleLogoutUser(url) {
        this.authService.logout();
        if (!!url && typeof url === 'string') {
            window.location.href = '/#login?location=' + url;
        } else {
            window.location.href = '/#/login?force=true';
        }
        if (this.state.user) {
            this.setState({user: null});
        }
    }

    

    

    render() {
        const authService = this.authService;
        return (
            <React.Fragment>

                <UpdateAppDialog 
                    visible={this.state.updateAppDialogVisible} 
                    handleClose={() => {
                        this.setState({updateAppDialogVisible: false});
                    }}
                    oldVersion={this.state.appOldVersion}
                    newVersion={this.state.appNewVersion}
                />
                <CookieConsent
                    location='bottom'
                    buttonText='Akceptuję'
                    cookieName='cookiePolicy'
                    style={{background: 'rgba(0, 0, 0, 0.8)'}}
                    buttonStyle={{
                        background: '#1c3275',
                        color: 'white',
                        fontSize: '16px',
                        fontFamily: 'Ubuntu',
                        fontWeight: 500,
                        borderRadius: '3px',
                    }}
                    expires={150}
                >
                    Nasz serwis wykorzystuje pliki cookies. Możesz zrezygnować ze zbierania informacji poprzez pliki cookies, zmieniając
                    ustawienia Twojej przeglądarki - w takim przypadku nie gwarantujemy poprawnego działania serwisu.
                </CookieConsent>
                <Router>
                    <div className={!authService.loggedIn() && !!this.state.renderSidebar ? 'app' : 'app logged'}>
                        {!!authService.loggedIn() && !!this.state.renderSidebar ? (
                            <div>
                                <div className='sidebar-vertical'>
                                    <Sidebar
                                        collapsed={this.state.collapsed}
                                        toggled={this.state.toggled}
                                        loggedUser={this.state.user}
                                        handleToggleSidebar={this.handleToggleSidebar.bind(this)}
                                        handleCollapsedChange={this.handleCollapsedChange.bind(this)}
                                        handleLogoutUser={this.handleLogoutUser.bind(this)}
                                        authService={this.authService}
                                        accountService={this.accountService}
                                        submenu={undefined}
                                        activeUrl={this.state.activeUrl}
                                    />
                                </div>

                                <div className='sidebar-horizontal'>
                                    <Sidebar
                                        collapsed={this.state.collapsed}
                                        toggled={this.state.toggled}
                                        loggedUser={this.state.user}
                                        handleToggleSidebar={this.handleToggleSidebar.bind(this)}
                                        handleCollapsedChange={this.handleCollapsedChange.bind(this)}
                                        handleLogoutUser={this.handleLogoutUser.bind(this)}
                                        authService={this.authService}
                                        accountService={this.accountService}
                                        userService={this.internalUserService}
                                        submenu={true}
                                        activeUrl={this.state.activeUrl}
                                    />
                                </div>
                            </div>
                        ) : null}
                        <main>
                            {/* <div className={`${authService.loggedIn() ? 'container-fluid' : ''}`}> */}
                            <Switch>
                                <Route
                                    exact
                                    path='/'
                                    render={(props) => (
                                        <Login
                                            {...props}
                                            onAfterLogin={() => {
                                                this.setState({user: this.authService.getProfile().sub});
                                            }}
                                        />
                                    )}
                                />
                                <Route
                                    path='/login'
                                    render={(props) => (
                                        <Login
                                            {...props}
                                            onAfterLogin={() => {
                                                this.setState({user: this.authService.getProfile().sub});
                                            }}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/forgot-password'
                                    component={(prp) => (
                                        <RemindPasswordContainer
                                            {...prp}
                                            onAfterLogin={() => {
                                                this.setState({
                                                    user: this.authService.getProfile().sub,
                                                });
                                            }}
                                            type='REMIND'
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/set-password'
                                    component={(prp) => (
                                        <ChangePasswordContainer
                                            {...prp}
                                            onAfterLogin={() => {
                                                this.setState({
                                                    user: this.authService.getProfile().sub,
                                                });
                                            }}
                                            handlePreLogoutUser={this.handlePreLogoutUser}
                                            type='RECOVER'
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/user-register'
                                    component={(prp) => (
                                        <ConfirmPasswordContainer
                                            {...prp}
                                            onAfterLogin={() => {
                                                this.setState({
                                                    user: this.authService.getProfile().sub,
                                                });
                                            }}
                                            handlePreLogoutUser={this.handlePreLogoutUser}
                                            type='CREATE'
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/start'
                                    component={withAuth(
                                        StartContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO', 'ROLE_FINANCIAL_MANAGER'],
                                        this.handleLogoutUser,
                                        undefined,
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'start'
                                    )}
                                />

                                <Route
                                    exact
                                    path='/user-list'
                                    component={withAuth(
                                        InternalUserListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'internaluser.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'user'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/profile'
                                    component={withAuth(
                                        ProfileContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO','ROLE_FINANCIAL_MANAGER','ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'profile.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'profile'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/user/details/:id'
                                    component={withAuth(
                                        InternalUserContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'internaluser.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'user'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/user/edit/:id'
                                    component={withAuth(
                                        InternalUserContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'internaluser.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'user'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/user/create'
                                    component={withAuth(
                                        InternalUserContainer,
                                        'NEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'internaluser.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'user'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/employee-list'
                                    component={withAuth(
                                        EmployeeListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'employee.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'employee'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/employee/create'
                                    component={withAuth(
                                        EmployeeContainer,
                                        'NEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'employee.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'employee'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/employee/details/:id'
                                    component={withAuth(
                                        EmployeeContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'employee.details.header',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'employee'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/employee/edit/:id'
                                    component={withAuth(
                                        EmployeeContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'employee.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'employee'
                                    )}
                                />

                                <Route
                                    exact
                                    path='/help-page'
                                    component={withAuth(
                                        HelpContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser
                                    )}
                                />
                                <Route
                                    exact
                                    path='/client-list'
                                    component={withAuth(
                                        ClientListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'client.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'client'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/client/create'
                                    component={withAuth(
                                        ClientContainer,
                                        'NEW',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'client.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'client'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/client/details/:id'
                                    component={withAuth(
                                        ClientContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'client.details.header.details',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'client'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/client/edit/:id'
                                    component={withAuth(
                                        ClientContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'client.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'client'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/project/details/:id'
                                    component={withAuth(
                                        ProjectDetailsContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO', 'ROLE_FINANCIAL_MANAGER', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'project.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'projectDetails'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/project/edit/:id'
                                    component={withAuth(
                                        ProjectDetailsContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'project.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'projectDetails'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/project/create'
                                    component={withAuth(
                                        ProjectDetailsContainer,
                                        'NEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'project.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'projectDetails'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/settings-list'
                                    component={withAuth(
                                        ApplicationSettingsListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN'],
                                        this.handleLogoutUser,
                                        'settings.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'settings'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/eventlog-list'
                                    component={withAuth(
                                        LogEntryListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'logEntry.list.title',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'logEntries'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/subcontractor-list'
                                    component={withAuth(
                                        SubcontratorListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'subcontractor.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'subcontractor'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/projectcategory-list'
                                    component={withAuth(
                                        ProjectCategoryListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'projectCategory.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'projectCategory'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/after-hours-drive-alarm-list'
                                    component={withAuth(
                                        AfterHoursDriveAlarmListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'afterHoursDriveAlarm.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        'afterHoursDriveAlarm'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/hourlyreport-list'
                                    component={withAuth(
                                        HourlyReportListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'hourlyreport.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'hourlyReport'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/pay-report'
                                    component={withAuth(
                                        PayReportListContainer,
                                        'VIEW',
                                        ['ROLE_FINANCIAL_MANAGER', 'ROLE_ADMIN', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'payReport.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'payReport'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/project-report-cumulative'
                                    component={withAuth(
                                        ProjectCumulativeReportListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'projectCumulativeReport.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'projectCumulativeReport'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/activity-list'
                                    component={withAuth(
                                        ActivityListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'activity.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'activity'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/car-list'
                                    component={withAuth(
                                        CarListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'car.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'car'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/tools-list'
                                    component={withAuth(
                                        ToolsListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'tools.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'tools'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/tools/details/:id'
                                    component={withAuth(
                                        ToolsDetailsContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'tools.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'tools'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/tools/edit/:id'
                                    component={withAuth(
                                        ToolsDetailsContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'tools.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'tools'
                                    )}
                                />

                                <Route
                                    exact
                                    path='/tools/create'
                                    component={withAuth(
                                        ToolsDetailsContainer,
                                        'NEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'tools.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'tools'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/tool-list'
                                    component={withAuth(
                                        ToolListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'tool.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'tool'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/tool/details/:id'
                                    component={withAuth(
                                        ToolDetailsContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'tool.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'tool'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/tool/create'
                                    component={withAuth(
                                        ToolDetailsContainer,
                                        'NEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'tool.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'tool'
                                    )}
                                />

                                <Route
                                    exact
                                    path='/tool/delete/:id'
                                    component={withAuth(
                                        ToolDetailsContainer,
                                        'DELETE',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'tool.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'tool'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/tool/edit/:id'
                                    component={withAuth(
                                        ToolDetailsContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'tool.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'tool'
                                    )}
                                />

                                <Route
                                    path='/project-dashboard-fullscreen/:criteriaUrl'
                                    render={(props) => (
                                        <ProjectDashboardFullScreenContainer
                                            handleHideMenu={this.handleHideMenu}
                                            url={props.match.params.criteriaUrl}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path='/project-dashboard'
                                    component={withAuth(
                                        ProjectDashboardContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'project.dashboardName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'project-d'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/project-list'
                                    component={withAuth(
                                        ProjectListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO','ROLE_FINANCIAL_MANAGER','ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'project.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'project'
                                    )}
                                />

                                <Route
                                    exact
                                    path='/project/plan/managers'
                                    component={withAuth(
                                        ManagerTimelineContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'project.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'project'
                                    )}
                                />

                                <Route
                                    exact
                                    path='/driver-rate-list'
                                    component={withAuth(
                                        DriverRateListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'driver-rate.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'car'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/reported-day-list'
                                    component={withAuth(
                                        ReportedDayListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'reportedDay.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'reportedDay'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/reported-day/details/:id'
                                    component={withAuth(
                                        ReportedDayDetailsContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'reportedDay.details',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'reportedDayDetails'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/reported-day/edit/:id'
                                    component={withAuth(
                                        ReportedDayDetailsContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'reportedDay.details',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'reportedDayDetails'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/reported-day/create'
                                    component={withAuth(
                                        ReportedDayDetailsContainer,
                                        'NEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'reportedDay.new',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'reportedDayNew'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/email-list'
                                    component={withAuth(
                                        EmailListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN'],
                                        this.handleLogoutUser,
                                        'email.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'emailList'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/email/edit/:id'
                                    component={withAuth(
                                        EmailEditContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN'],
                                        this.handleLogoutUser,
                                        'email.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'emailEdit'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/email/details/:id'
                                    component={withAuth(
                                        EmailEditContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN'],
                                        this.handleLogoutUser,
                                        'email.detailsHeader',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'emailEdit'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/project-report-settle'
                                    component={withAuth(
                                        ProjectSettleReportListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER'],
                                        this.handleLogoutUser,
                                        'projectSettleReport.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'projectSettleReport'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/document-types-list'
                                    component={withAuth(
                                        DocumentTypeListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'documentType.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'documentTypes'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/employee-absence-reason-list'
                                    component={withAuth(
                                        EmployeeAbsenceReasonList,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'employee.absence.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'employeeAbsenceList'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/employeecategory-list'
                                    component={withAuth(
                                        EmployeeCategoryListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'employeeCategory.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'employeeCategory'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/reserve'
                                    component={withAuth(
                                        ReserveContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_SUPERVISOR', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'reserve.label',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'reserveEmployeesContainer'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/reserve/employee/edit/:id'
                                    component={withAuth(
                                        EmployeeContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'employee.details.header',
                                        undefined,
                                        '#/reserve',
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'employee',
                                        undefined,
                                        {cancelUrl: '#/reserve/employee/details'}
                                    )}
                                />
                                <Route
                                    exact
                                    path='/country-list'
                                    component={withAuth(
                                        CountryListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'country.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'country'
                                    )}
                                />
                                <Route
                                    exact
                                    path='/reserve/employee/details/:id'
                                    component={withAuth(
                                        EmployeeContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'employee.details.header',
                                        undefined,
                                        '#/reserve',
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'employee',
                                        undefined,
                                        {editUrl: '#/reserve/employee/edit'}
                                    )}
                                />
                                <Route
                                    exact
                                    path='/external-supervisor/list'
                                    component={withAuth(
                                        ExternalSupervisorList,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'externalSupervisor.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'external-supervisor'
                                    )}
                                />
                               <Route
                                    exact
                                    path='/accommodation-list'
                                    component={withAuth(
                                        AccommodationListContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO'],
                                        this.handleLogoutUser,
                                        'accommodation.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'accommodation'
                                    )}
                                />
                                  <Route
                                    exact
                                    path='/accommodation/create'
                                    component={withAuth(
                                        AccommodationContainer,
                                        'NEW',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'accommodation.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'accommodation'
                                    )}
                                />
                                 <Route
                                    exact
                                    path='/accommodation/details/:id'
                                    component={withAuth(
                                        AccommodationContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'accommodation.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'accommodation'
                                    )}
                                />
                                 <Route
                                    exact
                                    path='/accommodation/edit/:id'
                                    component={withAuth(
                                        AccommodationContainer,
                                        'EDIT',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'accommodation.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'accommodation'
                                    )}
                                />
                             <Route
                                    exact
                                    path='/accommodation-timeline'
                                    component={withAuth(
                                        AccommodationTimelineContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'timeline-accommodation.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'accommodation'
                                    )}
                                />
                                      <Route
                                    exact
                                    path='/accommodation-employee-timeline'
                                    component={withAuth(
                                        EmployeeAccommodationTimelineContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'timeline-employees.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'accommodation'
                                    )}
                                />
                                 <Route
                                    exact
                                    path='/accommodation-category'
                                    component={withAuth(
                                        AccommodationCategoryContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'accommodation.category.listName',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'accommodation'
                                    )}
                                />
                                  <Route
                                    exact
                                    path='/accommodation-dashboard'
                                    component={withAuth(
                                        AccommodationDashboardContainer,
                                        'VIEW',
                                        ['ROLE_ADMIN', 'ROLE_CTO', 'ROLE_USER'],
                                        this.handleLogoutUser,
                                        'accommodation.dashboard',
                                        undefined,
                                        undefined,
                                        false,
                                        this.handleShowMenu,
                                        this.setActiveMenu,
                                        this.updateApp,
                                        'accommodation'
                                    )}
                                />
                                <Route
                                    path='/acommodation-dashboard-fullscreen/:criteriaUrl'
                                    render={(props) => (
                                        <AcommodationDashboardFullScreenContainer
                                            handleHideMenu={this.handleHideMenu}
                                            url={props.match.params.criteriaUrl}
                                        />
                                    )}
                                />
                                <Route exact path='/cookies-page' component={CookiesContainer} />

                                <Route path='/version' render={(props) => <VersionContainer handleHideMenu={this.handleHideMenu} />} />
                            </Switch>
                        </main>
                    </div>
                </Router>
            </React.Fragment>
        );
    }
}

export default withTranslation()(App);
