import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import {ActionButton} from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import DriverRateService from '../services/DriverRateService';
import InputNumberComponent from '../components/inputs/InputNumberComponent';
import InputCalendarComponent from '../components/inputs/InputCalendarComponent';

class DriverRateEditDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new DriverRateService());
        this.state = {
            elementId: props.elementId,
            element: props.element,

            filteredEmployees: [],
            viewMode: this.props.viewMode,
        };
        this.dateSettings = {
            view: 'month',
            dateFormat: 'mm-yy',
            placeholder: 'mm-yyyy',
            dateFormatMoment: 'MM-YYYY',
            yearNavigator: true,
        };      
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide(undefined);
                    }}
                />
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('driver-rate.newButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.viewMode !== 'DELETE'}
                />
            </div>
        );
    }
    initBeforeSetElement() {
       
    }
    

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('driver-rate.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('driver-rate.editHeader')}`
                            : this.state.viewMode === 'DELETE'
                            ? `${t('driver-rate.deleteHeader')}`
                            : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    updateElement(data) {
         if (data.dateFrom) {
             data.dateFrom = new Date(data.dateFrom);
         }
         if (data.dateTo) {
             data.dateTo = new Date(data.dateTo);
         }
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    setElement() {
        if (this.props.element) {
            this.updateElement(this.props.element);
        }
    }
    getContainerListName() {
        return 'driver-rate-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('driver-rate.addSuccess');
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('driver-rate.updateSuccess');
    }
    createOrUpdate() {
        const {element} = this.state;
        this.scrollToTop();
        if (this.props._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getAddSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getUpdateSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            }
        }
    }

    renderDetails() {
        const {t} = this.props;

        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                  <InputCalendarComponent
                    id='dateFrom'
                    name='dateFrom'
                    label='Data obowiązywania od'
                    colClass='col-md-4'
                    value={this.state.element.dateFrom}                                        
                    onChange={this.handleChange}                    
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    showClear                    
                    dateFormatMoment={this.dateSettings.dateFormatMoment}
                    view={this.dateSettings.view}
                    dateFormat={this.dateSettings.dateFormat}
                    placeholder={this.dateSettings.placeholder}
                    yearNavigator={this.dateSettings.yearNavigator}
                />
                 <InputCalendarComponent
                    id='dateTo'
                    name='dateTo'
                    label='Data obowiązywania do'
                    colClass='col-md-4'
                    value={this.state.element.dateTo}   
                    onChange={this.handleChange}                    
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators={'required'}
                    showClear                    
                    dateFormatMoment={this.dateSettings.dateFormatMoment}
                    view={this.dateSettings.view}
                    dateFormat={this.dateSettings.dateFormat}
                    placeholder={this.dateSettings.placeholder}
                    yearNavigator={this.dateSettings.yearNavigator}
                    rendered={this.props.viewMode ==='EDIT' && this.state.element.dateTo}
                    
                />
                </DivContainer>
                <DivContainer colClass='row'>
                <InputNumberComponent
                    id='emplpyee-rate'
                    name='value'
                    label={t('driver-rate.employee-rate-per-hour')}
                    value={this.state.element.employeeRate.value}                                                                        
                    stateField='element.employeeRate'                                                                        
                    colClass='col-md-6'
                    onChange={this.handleChange}                
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    mode='currency'
                    currency='EUR'
                    locale='pl-PL'
            />
             <InputNumberComponent
                    id='leader-rate'
                    name='value'
                    label={t('driver-rate.leader-rate-per-hour')}
                    value={this.state.element.leaderRate.value}                                                                        
                    stateField='element.leaderRate'                                                                        
                    colClass='col-md-6'
                    onChange={this.handleChange}                
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    mode='currency'
                    currency='EUR'
                    locale='pl-PL'
            />

            <InputNumberComponent
                    id='leader-rate'
                    name='value'
                    label={t('driver-rate.extra-rate')}
                    value={this.state.element.extraRate.value}   
                    stateField='element.extraRate'                                                                        
                    colClass='col-md-6'
                    onChange={this.handleChange}                
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    mode='currency'
                    currency='EUR'
                    locale='pl-PL'
            />

                </DivContainer>
            </React.Fragment>
        );
    }
}

DriverRateEditDialog.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
    visible: false,
    stationId: undefined,
};

DriverRateEditDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
    roleTypes: PropTypes.object,
    className: PropTypes.string,
};

export default withTranslation()(DriverRateEditDialog);
