import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {ActionButton} from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import { Dialog } from 'primereact/dialog';
import { Card } from 'primereact/card';
import { t } from "i18next";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import NumberUtils from '../utils/NumberUtils';

class ProjectDriveCostAggregationDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closable: true,
        }
    }

    
    renderFooter() {
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide(undefined);
                    }}
                />
            </div>
        );
    }


    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {t('project.details.driveCostDialog.header')}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }

    
    render() {
        const {className, id, key, name, onHide, visible, style} = this.props;
        return (
            <Dialog
                ariaCloseIconLabel={t('dialog.closeDialog')}
                header={this.renderHeader()}
                visible={visible}
                resizable={false}
                className={className}
                style={style}
                key={`${key ? key : id ? id : name}-dialog`}
                onHide={() => (onHide ? onHide() : null)}
                closable={this.state.closable}
            >                
                <Card footer={this.renderFooter()}>
                    <form noValidate onKeyDown={(e) => this.onKeyDown(e)}>
                        {this.renderDetails()}
                    </form>
                </Card>
            </Dialog>
        );
    }



    renderDetails() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <DataTable value={this.props.costs} responsiveLayout="scroll">
                        <Column body={this.leaderTemplate.bind(this)} header={t('project.details.driveCostDialog.leader')} />
                        <Column body={this.priceTemplate.bind(this)} className="number" header={t('project.details.driveCostDialog.price')} 
                            style={{minWidth: '150px'}} 
                        />
                        <Column body={this.timeTemplate.bind(this)} className="number" header={t('project.details.driveCostDialog.time')} 
                            footer={this.timeFooterTemplate()}
                            style={{minWidth: '150px'}} 
                        />
                        <Column body={this.costTemplate.bind(this)} className="number" header={t('project.details.driveCostDialog.cost')} 
                            footer={this.costFooterTemplate()}
                            style={{minWidth: '150px'}} 
                        />                        
                    </DataTable>
                </DivContainer>
            </React.Fragment>
        );
    }


    extraActivityAggregationTotalHourFooter() {
        let total = 0;
        this.props.costs?.forEach(e => total += parseFloat(e.totalHour));
        return (
            <span className="extras-footer number">{NumberUtils.formatNumber(total)}</span>
        );
    }

    leaderTemplate(rowData) {
        if (rowData.leader) {
            return (
                <i className="pi pi-check"></i>
            )
        } else {
            return '-';
        }
    }

    priceTemplate(rowData) {
        if (rowData.price) {
            return NumberUtils.formatNumber(rowData.price);
        } else {
            return '';
        }
    }

    timeTemplate(rowData) {
        if (rowData.time) {
            return NumberUtils.formatTimeIfPossible(rowData.time);
        } else {
            return '-';
        }
    }

    costTemplate(rowData) {
        if (rowData.cost) {
            return NumberUtils.formatNumber(rowData.cost);
        } else {
            return '-';
        }
    }

    timeFooterTemplate() {
        let total = 0;
        this.props?.costs.forEach(e => total += parseFloat(e.time));
        return (
            <span className="extras-footer number">{NumberUtils.formatTimeIfPossible(total)}</span>
        );
    }

    costFooterTemplate() {
        let total = 0;
        this.props?.costs.forEach(e => total += parseFloat(e.cost));
        return (
            <span className="extras-footer number">{NumberUtils.formatNumber(total)}</span>
        );
    }
}

ProjectDriveCostAggregationDialog.defaultProps = {    
};

ProjectDriveCostAggregationDialog.propTypes = {
    costs: PropTypes.array.isRequired,
    onHide: PropTypes.func,    
};

export default withTranslation()(ProjectDriveCostAggregationDialog);
