import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import {ActionButton} from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import SettingService from '../services/SettingService';
class ApplicationSettingsEditDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new SettingService());
        this.state = {
            elementId: props.elementId,
            element: {
                id: '',
                login: '',
                firstName: '',
            },
            viewMode: this.props.viewMode,
        };
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide(undefined);
                    }}
                />
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('settings.addButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.viewMode !== 'DELETE'}
                />
            </div>
        );
    }

    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12 transaction-dialog-header'>
                <DivContainer colClass='col-12'>
                    <label>{this.state.viewMode === 'EDIT' ? `${t('settings.editHeader')}` : null}</label>
                </DivContainer>
            </DivContainer>
        );
    }
    updateElement(data) {
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    setElement() {
        if (this.props.element) {
            this.updateElement(this.props.element);
        }
    }
    getContainerListName() {
        return 'setting-list-container';
    }

    getUpdateSucces() {
        const {t} = this.props;
        return t('settings.updateSettingSuccess');
    }
    createOrUpdate() {
        const {element} = this.state;
        this.scrollToTop();
        if (this.props._isMounted) {
            this.service
                .update(element)
                .then((response) => {
                    this.blockUi();
                    this.persistMessage('success', '', this.getUpdateSucces(response));
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                })
                .catch((err) => {
                    this.persistMessage('error', '', err.message);
                    if (this.props.afterSave) {
                        this.props.afterSave();
                    }
                });
        }
    }

    renderDetails() {
        const {t} = this.props;

        return this.state.viewMode === 'EDIT' ? (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <InputDropdownComponent
                        id='type'
                        name='type'
                        label={'Nazwa parametru'}
                        colClass='col-md-6'
                        value={this.state.element.type}
                        validator={this.validator}
                        // options={this.state.statusOptions}
                        onChange={null}
                        viewMode={'VIEW'}
                        optionLabel='label'
                        dataKey='enumValue'
                        validators='not_required'
                        rendered={this.props.viewMode === 'EDIT'}
                        valueView={this.state.element.type ? this.state.element.type.label : ''}
                    />
                    <InputTextComponent
                        id='value'
                        name='value'
                        label={'Wartość'}
                        colClass='col-md-6'
                        value={this.state.element.value}
                        validator={this.validator}
                        validators='required|max:200'
                        onChange={this.handleChange}
                        viewMode={'EDIT'}
                    />
                </DivContainer>
            </React.Fragment>
        ) : null;
    }
}

ApplicationSettingsEditDialog.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
    visible: false,
    stationId: undefined,
};

ApplicationSettingsEditDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
    roleTypes: PropTypes.object,
};

export default withTranslation()(ApplicationSettingsEditDialog);
