import PropTypes from 'prop-types';
import AppPrefixUtils from '../utils/AppPrefixUtils';
import BaseDetailsContainer from './BaseDetailsContainer';

/** Component created for ePrawnik */
class BaseUserDetailsContainer extends BaseDetailsContainer {
    constructor(props, service) {
        super(props);
        this.service = service;
        this.blockAccount = this.blockAccount.bind(this);
        this.remindPassword = this.remindPassword.bind(this);
        this.unblockAccount = this.unblockAccount.bind(this);
        // this.removeAccount = this.removeAccount.bind(this);
        this.showBlockConfirmation = this.showBlockConfirmation.bind(this);
        this.hideBlockConfirmation = this.hideBlockConfirmation.bind(this);
        this.showUnblockConfirmation = this.showUnblockConfirmation.bind(this);
        this.showRemoveConfirmation = this.showRemoveConfirmation.bind(this);
        this.hideRemoveConfirmation = this.hideRemoveConfirmation.bind(this);
    }

    showBlockConfirmation() {
        if (this.demoMode) {
            alert('Funkcjonalność niedostępna w aplikacji DEMO!');
            return;
        } 
        this.setState({showConfirmBlockDialog: true});        
    }

    hideBlockConfirmation() {
        this.setState({showConfirmBlockDialog: false});
    }

    showUnblockConfirmation() {
        if (this.demoMode) {
            alert('Funkcjonalność niedostępna w aplikacji DEMO!');
            return;
        }            
        this.setState({showConfirmUnblockDialog: true});        
    }

    hideUnblockConfirmation() {
        this.setState({showConfirmUnblockDialog: false});
    }

    showRemoveConfirmation() {
        this.setState({showConfirmDeleteDialog: true});
    }

    hideRemoveConfirmation() {
        this.setState({showConfirmDeleteDialog: false});
    }

    blockAccount() {
        this.blockUi();
        this.service
            .blockAccount(this.state.element.id)
            .then((result) => {
                this.showSuccessMessage('Zablokowane konto użytkownika');
                this.updateElement(result);
                this.hideBlockConfirmation();
                this.unblockUi();
            })
            .catch((err) => {
                this.showErrorMessage('Nie udało się zablokować konta użytkownika');
                this.hideBlockConfirmation();
                this.unblockUi();
            });
    }

    unblockAccount() {
        this.blockUi();
        this.service
            .unblockAccount(this.state.element.id)
            .then((result) => {
                this.showSuccessMessage('Odblokowano konto użytkownika');
                this.updateElement(result);
                this.hideUnblockConfirmation();
                this.unblockUi();
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się odblokować konta użytkownika');
                this.hideUnblockConfirmation();
                this.unblockUi();
            });
    }

    removeAccount() {
        this.blockUi();
        this.service
            .removeAccount(this.state.element.id)
            .then((result) => {
                this.afterDeleteSuccess('success', '', 'Usunięto użytkownika');
                this.hideRemoveConfirmation();
                this.unblockUi();
            })
            .catch((ex) => {
                this.showErrorMessage(ex.message);
                this.hideRemoveConfirmation();
                this.unblockUi();
            });
    }

    remindPassword() {
        this.blockUi();
        this.service
            .remindPassword(this.state.element.id)
            .then(() => {
                this.showSuccessMessage('Wysłano link resteujący hasło');
                this.hideUnblockConfirmation();
                this.unblockUi();
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się wysłać linka resetującego hasło');
                this.hideUnblockConfirmation();
                this.unblockUi();
            });
    }

    detectSelfEdition() {
        const user = this.authService.getProfile();
        return !!(user.userId && this.state.element && this.state.element.id && parseInt(user.userId) === parseInt(this.state.element.id));
    }    
}

BaseUserDetailsContainer.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
    lazy: true,
};

BaseUserDetailsContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
};

export default BaseUserDetailsContainer;
