import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {FilterMatchMode} from 'primereact/api';
import {InputNumber} from 'primereact/inputnumber';

class CriteriaNumberRangeComponent extends React.Component {
    onChange(event, name) {
        event.target = [];
        event.target.name = name;
        event.target.value = '' + event.value;
        this.props.onChange(event);
    }

    render() {
        const {
            colClass,
            disabled,
            from,
            idFrom,
            idTo,
            keyfilterFrom,
            keyfilterTo,
            label,
            labelFrom,
            labelTo,
            name,
            nameFrom,
            nameTo,
            onChange,
            onAfterStateChange,
            renderDash,
            placeholderFrom,
            placeholderTo,
            requiredFrom,
            requiredTo,
            to,
            validator,
            validators,
            minFrom,
            maxFrom,
            minTo,
            maxTo,
            mode,
            locale,
            currency,
            minFractionDigits,
            maxFractionDigits,
            showButtons,
            showLabel,
            refreshFromBackend,
            inputsClassName,
            allowEmpty,
            timeoutHandler,
            delay,
        } = this.props;

        return (
            <div className={`${colClass} range`}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        <label id={`${idFrom}-label-id`} className='p-label' style={{width: '100%'}}>
                            {label}
                        </label>
                    </div>
                    <div className={`${inputsClassName}${renderDash ? ' range-left' : ''}`}>
                        <InputNumber
                            aria-describedby={`${idFrom}-error`}
                            aria-label={labelFrom}
                            aria-labelledby={labelFrom === undefined && showLabel ? `${idFrom}-label-id` : undefined}
                            key={idFrom}
                            id={idFrom}
                            name={nameFrom}
                            min={minFrom}
                            max={maxFrom}
                            keyfilter={keyfilterFrom}
                            placeholder={placeholderFrom}
                            style={{width: '100%'}}
                            inputStyle={{width: '100%'}}
                            value={from}
                            onChange={(e) => {
                                if (onChange) {
                                    e.name = nameFrom;
                                    onChange(
                                        'NUMBER_RANGE',
                                        [name, allowEmpty],
                                        e,
                                        FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
                                        refreshFromBackend,
                                        onAfterStateChange,
                                        timeoutHandler,
                                        delay
                                    );
                                }
                            }}
                            disabled={disabled}
                            required={requiredFrom}
                            showButtons={showButtons}
                            mode={mode}
                            locale={locale}
                            currency={currency}
                            minFractionDigits={minFractionDigits}
                            maxFractionDigits={maxFractionDigits}
                            allowEmpty={allowEmpty}
                        />
                        <div aria-live='assertive'>{validator ? validator.message(idFrom, labelFrom, from, validators) : null}</div>
                    </div>
                    {renderDash ? <div className='range-dash'></div> : null}
                    <div className={`${inputsClassName}${renderDash ? ' range-right' : ''}`}>
                        <InputNumber
                            aria-describedby={`${idTo}-error`}
                            aria-label={labelTo}
                            aria-labelledby={labelTo === undefined && showLabel ? `${idTo}-label-id` : undefined}
                            key={idTo}
                            id={idTo}
                            name={nameTo}
                            min={minTo}
                            max={maxTo}
                            keyfilter={keyfilterTo}
                            placeholder={placeholderTo}
                            style={{width: '100%'}}
                            inputStyle={{width: '100%'}}
                            value={to}
                            onChange={(e) => {
                                if (onChange) {
                                    e.name = nameTo;
                                    onChange(
                                        'NUMBER_RANGE',
                                        [name, allowEmpty],
                                        e,
                                        FilterMatchMode.LESS_THAN_OR_EQUAL_TO,
                                        refreshFromBackend,
                                        onAfterStateChange,
                                        timeoutHandler,
                                        delay
                                    );
                                }
                            }}
                            disabled={disabled}
                            required={requiredTo}
                            showButtons={showButtons}
                            mode={mode}
                            locale={locale}
                            currency={currency}
                            minFractionDigits={minFractionDigits}
                            maxFractionDigits={maxFractionDigits}
                            allowEmpty={allowEmpty}
                        />
                        <div aria-live='assertive'>{validator ? validator.message(idTo, labelTo, to, validators) : null}</div>
                    </div>
                </div>
            </div>
        );
    }
}

CriteriaNumberRangeComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    dateFormat: 'dd-mm-yy',
    dateFormatMoment: 'DD-MM-YYYY',
    disabled: false,
    labelFrom: 'od',
    labelTo: 'do',
    renderDash: false,
    readOnlyInput: false,
    showButtons: false,
    showLabel: true,
    validators: 'not_required',
    viewMode: 'EDIT',
    minFrom: undefined,
    maxFrom: undefined,
    minTo: undefined,
    maxTo: undefined,
    refreshFromBackend: false,
    inputsClassName: 'col-md-6 col-12',
    allowEmpty: false,
};

CriteriaNumberRangeComponent.propTypes = {
    colClass: PropTypes.string,
    disabled: PropTypes.bool,
    from: PropTypes.number,
    to: PropTypes.number,
    idFrom: PropTypes.string.isRequired,
    idTo: PropTypes.string.isRequired,
    keyfilterFrom: PropTypes.string,
    keyfilterTo: PropTypes.string,
    label: PropTypes.string,
    labelFrom: PropTypes.string,
    labelTo: PropTypes.string,
    nameFrom: PropTypes.string.isRequired,
    nameTo: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onAfterStateChange: PropTypes.func,
    renderDash: PropTypes.bool,
    showButtons: PropTypes.bool,
    placeholderFrom: PropTypes.string,
    placeholderTo: PropTypes.string,
    readOnlyInput: PropTypes.bool,
    validator: PropTypes.object,
    validators: PropTypes.string,
    viewMode: PropTypes.string,
    minFrom: PropTypes.number,
    maxFrom: PropTypes.number,
    minTo: PropTypes.number,
    maxTo: PropTypes.number,
    mode: PropTypes.string,
    locale: PropTypes.string,
    currency: PropTypes.string,
    minFractionDigits: PropTypes.number,
    maxFractionDigits: PropTypes.number,
    refreshFromBackend: PropTypes.bool,
    inputsClassName: PropTypes.string,
    allowEmpty: PropTypes.bool,
    delay: PropTypes.number,
    timeoutHandler: PropTypes.func,
};

export default withTranslation()(CriteriaNumberRangeComponent);
