import { Dialog } from 'primereact/dialog';
import { React, useState } from 'react';
import Compact from '@uiw/react-color-compact';



const ColorPickerButton = (props) => {
    const [showPicker, setShowPicker] = useState(false);

    const handleButtonClick = (e) => {
        e.preventDefault();
        setShowPicker(!showPicker);
    };

    const handleColorChange = (color) => {
        if (props.onChange) {
            props.onChange(color.hex);
        }
    };


    const colors = [
        "#FF0000", // Red
        "#FFA500", // Orange
        "#FFFF00", // Yellow
        "#00FF00", // Lime
        "#00FFFF", // Cyan
        "#0000FF", // Blue
        "#800080", // Purple
        "#8B008B", // Dark Magenta
        "#FF69B4", // Pink
        "#FF1493", // Deep Pink
        "#FF4500", // Orange Red
        "#8B0000", // Dark Red
        "#32CD32", // Lime Green
        "#00FF7F", // Spring Green
        "#ADFF2F", // Green Yellow
        "#9ACD32", // Yellow Green
        "#FFD700", // Gold
        "#FF8C00", // Dark Orange
        "#FF6347", // Tomato
        "#FF7F50", // Coral
        "#FA8072", // Salmon
        "#40E0D0", // Turquoise
        "#00CED1", // Dark Turquoise
        "#20B2AA", // Light Sea Green
        "#008B8B", // Dark Cyan
        "#4682B4", // Steel Blue
        "#6A5ACD", // Slate Blue
        "#9932CC", // Rebecca Purple
        "#8B4513", // Saddle Brown
        "#A52A2A", // Brown
        "#D2691E", // Chocolate
        "#B22222", // Firebrick
        "#DB7093", // Pale Violet Red
        "#CD5C5C", // Indian Red
        "#800000", // Maroon
        "#FFB6C1", // Light Pink
        "#669999", // Slate Gray
        "#FFDAB9", // Peach Puff
        "#E9A786", // Sandy Brown
        "#FFFFFF", // White
    ]
    const onHide = () => {
        setShowPicker(false)
    }

    
    
    return (

        

        <div className={`form-group ${props.label ? 'cpc-form-group' : ''}`}>
            {props.label ? (
                <div className='input_easy_label row pl-0'>
                {props.label !== '' ? (
                    <label id={`-label-id`} class='p-label  cpc'>
                        {props.label}
                    </label>
                ) : null}{' '}
            </div>) : null}
                
                <div className={props.componentClassName}>
                    <div className="color-container">
                        <button
                            className="color-button"
                            style={{
                                backgroundColor: props.color,
                                height: '40px',
                                width: '40px',
                                borderRadius: '30%'
                            }}
                            onClick={handleButtonClick}
                        />
                        {showPicker && (
                            <Dialog
                                header="Wybór koloru"
                                visible={true}
                                onHide={onHide}
                            >
                                <Compact
                                    color={props.color}
                                    onChange={(color) => {
                                        handleColorChange(color)
                                        onHide()
                                    }
                                    }
                                    colors={colors}
                                    style={{
                                        boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 0px 1px, rgb(0 0 0 / 15%) 0px 8px 16px',
                                    }}
                                />
                            </Dialog>
                        )}
                    </div>
                </div>
            </div>




        
    );
};

export default ColorPickerButton;
