import PropTypes from 'prop-types';

export const SquareColorComponent = ({value, sideSize, borderRadius}) => {
    return <div style={{
                backgroundColor:value, 
                width:sideSize, 
                height:sideSize, 
                borderRadius:borderRadius}}
        ></div> 
};

SquareColorComponent.defaultProps = {
    value: "#00000",
    sideSize: "30px",
    borderRadius: "5px",
};

SquareColorComponent.propTypes = {
    value:PropTypes.string.isRequired, 
    sideSize:PropTypes.string, 
    borderRadius:PropTypes.string, 
};

export default SquareColorComponent;
