import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import BaseDialogContainer from '../baseContainers/BaseDialogContainer';
import { ActionButton } from '../components/ActionButton';
import DivContainer from '../components/DivContainer';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import DocumentTypeService from '../services/DocumentTypeService';

class DocumentTypeEditDialog extends BaseDialogContainer {
    constructor(props) {
        super(props, new DocumentTypeService());
        this.state = {
            countries: props.countries,
            elementId: props.elementId,
            element: {
                name: '',                
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
                country: undefined,
            },
            viewMode: this.props.viewMode,
        };
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    prepareFooter() {
        const {t} = this.props;
        return (
            <div className='footer-dialog-button-container'>
                <div className='left-footer-dialog-button-container'></div>
                <ActionButton
                    label={t('details.cancel')}
                    variant={'button cancel-button white'}
                    handleClick={(e) => {
                        this.props.onHide(undefined);
                    }}
                />
                <ActionButton
                    label={this.state.viewMode === 'NEW' ? t('documentType.addButton') : t('details.saveChanges')}
                    variant='button save-button blue'
                    handleClick={this.handleFormSubmit}
                    rendered={this.state.viewMode !== 'DELETE'}
                />
            </div>
        );
    }
   
    renderHeader() {
        const {t} = this.props;
        return (
            <DivContainer colClass='col-12'>
                <DivContainer colClass='col-12'>
                    <label>
                        {this.state.viewMode === 'NEW'
                            ? `${t('documentType.addHeader')}`
                            : this.state.viewMode === 'EDIT'
                            ? `${t('documentType.editHeader')}`
                            : this.state.viewMode === 'DELETE'
                            ? `${t('documentType.deleteHeader')}`
                            : null}
                    </label>
                </DivContainer>
            </DivContainer>
        );
    }
    updateElement(data) {
        this.setState(
            {
                element: data,
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    setElement() {
        if (this.props.element) {
            this.updateElement(this.props.element);
        }
    }
    getContainerListName() {
        return 'document-types-list-container';
    }

    getAddSucces() {
        const {t} = this.props;
        return t('documentType.addSuccess');
    }
  
    getUpdateSucces() {
        const {t} = this.props;
        return t('documentType.updateSuccess');
    }
    createOrUpdate() {
        const {element} = this.state;
        this.scrollToTop();
        if (this.props._isMounted) {
            if (element.id === undefined || element.id === null) {
                this.service
                    .add(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getAddSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.blockUi();
                        this.persistMessage('success', '', this.getUpdateSucces(response));
                        if (this.props.afterSave) {
                            this.props.afterSave();
                        }
                    })
                    .catch((err) => {
                        this.showErrorMessage(err.message);
                        this.unblockUi();
                    });
            }
        }
    }

    renderDetails() {
        const { t } = this.props;
        return  (
            <React.Fragment>
                <DivContainer colClass='row'>
                <InputTextComponent
                    id='name'
                    name='name'
                    label={'Typ'}
                    colClass='col-md-12'
                    value={this.state.element.name}
                    validator={this.validator}
                    validators='required|max:255'
                    onChange={this.handleChange}
                    viewMode={'EDIT'}                        
                 />
                <InputDropdownComponent
                        id='country'
                        name='country'
                        label={t('address.country')}
                        colClass='col-xl-12 col-lg-12 col-md-12 col-sm-12'
                        value={this.state.element.country}
                        validator={this.validator}
                        validators='not_required'
                        options={this.state.countries}
                        onChange={this.handleChange}
                        viewMode={this.props.viewMode}
                        optionLabel='name'
                        dataKey='id'
                        valueView={this.state.element?.country?.name}
                        filter={true}
                        filterBy='name'
                        showClear
                    />
                </DivContainer>
            </React.Fragment>
        );
    }
}

DocumentTypeEditDialog.defaultProps = {
    currentUser: undefined,
    viewMode: 'VIEW',
    visible: false,
    stationId: undefined,
};

DocumentTypeEditDialog.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    visible: PropTypes.bool,
    onHide: PropTypes.func,
    afterSave: PropTypes.func,
    elementId: PropTypes.number,
    stationId: PropTypes.number,
    roleTypes: PropTypes.object,
    className: PropTypes.string
};

export default withTranslation()(DocumentTypeEditDialog);