import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import BaseDetailsContainer from '../../baseContainers/BaseDetailsContainer';
import DivContainer from '../../components/DivContainer';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import UserEditDialog from './../UserEditDialog';
import {t} from 'i18next';
import ProfileChangePasswordDialog from './ProfileChangePasswordDialog';
import AccountService from '../../services/AccountService';
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';

class ProfileContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new AccountService());
        this.state = {
            dialogEditAcountVisible: false,
            dialogPasswordEditVisible: false,
            elementId: this.props.elementId,
            element: {
                firstName: '',
                lastName: '',
                email: '',
                roleTypes: [],
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
                subcontractor: undefined,
            },
            editElement: null,
            loading: true,
            size: 0,
            first: 0,
            statusOptions: [],
            roleTypeOptions: [],
        };

        this.openEditAccountDialog = this.openEditAccountDialog.bind(this);
        this.closeEditAccountDialog = this.closeEditAccountDialog.bind(this);
        this.openEditPasswordDialog = this.openEditPasswordDialog.bind(this);
        this.closeEditPasswordDialog = this.closeEditPasswordDialog.bind(this);
        this.getMyAccount = this.getMyAccount.bind(this);
    }
    readMessage() {
        const messageFromStorage = this.readCookie(this.getContainerListName());
        if (messageFromStorage !== undefined && messageFromStorage != null) {
            const message = JSON.parse(messageFromStorage);
            this.removeCookie(this.getContainerListName());
            if (
                message.severity !== undefined &&
                message.severity != null &&
                message.summary !== undefined &&
                message.summary != null &&
                message.detail !== undefined &&
                message.detail != null
            ) {
                this.showMessage(message.severity, message.summary, message.detail);
            }
        }
    }
    getContainerListName() {
        return 'profile';
    }
    renderBackLink(){
        return false;
    }
    updateElement(data) {
        this.setState(
            {
                element: {
                    id: data.id,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    roleType: data.roleType,
                    status: data.status,
                    subcontractor: data.subcontractor,
                },
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }
    initBeforeSetElement() {
        this.getMyAccount();
    }

    getMyAccount() {
        this.service
            .getMyAccount()
            .then((element) =>
                this.setState({
                    element,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać profilu');
            });
    }

    prepareFooterItems() {
        const result = super.prepareFooterItems();
                    //TODO
        if (this.authService.isUserInAnyRole('ROLE_ADMIN','ROLE_USER', 'ROLE_SUPERVISOR', 'ROLE_CTO', 'ROLE_FINANCIAL_MANAGER', 'ROLE_EXTERNAL_MANAGER') && this.props.viewMode === 'VIEW') {
            result.push({label: 'Edytuj', className: 'float-right', onClick: this.openEditAccountDialog.bind(this)});
            result.push({label: 'Zmień hasło', className: 'float-right', onClick: this.openEditPasswordDialog.bind(this)});
        }
        return result;
    }

    openEditAccountDialog(e) {
        this.setState({dialogEditAcountVisible: true, editElement: this.cloneElement(this.state.element), dialogViewMode: 'EDIT'});
    }
    closeEditAccountDialog(callBack) {
        this.setState({dialogEditAcountVisible: false, dialogViewMode: undefined}, () => {
            if (callBack) {
                callBack();
            } else {
                this.unblockUi();
            }
        });
    }

    openEditPasswordDialog() {
        if (this.demoMode) {
            alert('Funkcjonalność niedostępna w aplikacji DEMO!');
            return;
        }
        this.setState({dialogPasswordEditVisible: true});
    }

    closeEditPasswordDialog(callBack) {
        this.setState({dialogPasswordEditVisible: false}, () => {
            if (callBack) {
                callBack();
            } else {
                this.unblockUi();
            }
        });
    }

    isPdfButtonRendered() {
        return false;
    }

    renderDetails() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <DivContainer colClass='col-12'>
                        <div className='row profile'>
                            <InputTextComponent
                                id='firstName'
                                name='firstName'
                                label={t('internaluser.details.firstName')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.firstName}
                                validator={this.validator}
                                validators='required|alpha|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />

                            <InputTextComponent
                                id='lastName'
                                name='lastName'
                                label={t('internaluser.details.lastName')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.lastName}
                                validator={this.validator}
                                validators='required|alpha|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                        </div>
                        <div className='row profile'>
                            <InputTextComponent
                                id='email'
                                name='email'
                                label={t('internaluser.details.email')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.email}
                                validator={this.validator}
                                validators='required|email|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                        <InputMultiSelectComponent
                                id='roleTypes'
                                name='roleTypes'
                                label={t('internaluser.details.role')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.roleTypes}
                                validator={this.validator}
                                options={this.state.roleTypeOptions}
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                                dataKey='enumValue' 
                                validators='required'
                                optionLabel='enumLabel'
                                selectedItemTemplate={this.selectedItemTemplate}
                            />
                        </div>
                        <div className='row profile'>
                            <InputDropdownComponent
                                id='subcontractor'
                                name='subcontractor'
                                label={t('internaluser.details.subcontractorName')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.subcontractor}
                                validator={this.validator}
                                options={this.state.subcontractors}
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                                dataKey='id'
                                filter={true}
                                showClear={true}
                                validators='not_required'
                                optionLabel='name'
                                valueView={this.state.element?.subcontractor?.name}
                            />
                            {this.props.viewMode !== 'NEW' ? (
                                <InputDropdownComponent
                                    id='status'
                                    name='status'
                                    label={t('internaluser.details.status')}
                                    colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                    value={this.state.element.status}
                                    validator={this.validator}
                                    options={this.state.statusOptions}
                                    onChange={null}
                                    viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode}
                                    optionLabel='label'
                                    dataKey='enumValue'
                                    validators='not_required'
                                    rendered={this.props.viewMode !== 'NEW'}
                                    valueView={this.state.element.status ? this.state.element.status.label : ''}
                                />
                            ) : null}
                        </div>
                    </DivContainer>
                </DivContainer>
                {this.state.dialogEditAcountVisible && (
                    <UserEditDialog
                        key={`user-dialog-${this.state.element?.id}`}
                        element={this.state.editElement}
                        onHide={(e) => this.setState({dialogEditAcountVisible: false})}
                        visible={this.state.dialogEditAcountVisible}
                        viewMode={this.state.dialogViewMode}
                        isFromProfile
                        roleTypes={this.state.roleTypeOptions}
                        afterSave={() => {
                            this.closeEditAccountDialog(() => {
                                this.readMessage();
                                this.unblockUi();
                                this.getMyAccount();
                            });
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                )}
                {this.state.dialogPasswordEditVisible && (
                    <ProfileChangePasswordDialog
                        userId={this.state.element.id}
                        onHide={() => {
                            this.closeEditPasswordDialog();
                        }}
                        visible={this.state.dialogPasswordEditVisible}
                        viewMode={this.state.dialogViewMode}
                        afterSave={() => {
                            this.showSuccessMessage('Hasło zostało zmienione');
                            this.closeEditPasswordDialog();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                )}
            </React.Fragment>
        );
    }
}
ProfileContainer.defaultProps = {
    cancelUrl: '/#/profile',
    editUrl: '/#/profile/edit',
    backUrl: '/#/profile',
};

ProfileContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};
export default withTranslation()(ProfileContainer);
