import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class EmailService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'email';
      //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        return this.objToQueryString({   
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            msgType: criteria.msgType==null ? null : criteria.msgType.enumValue,
            emailDesc: criteria.emailDesc
        });
    }
    getParameters(id){
        return this.fetch(`${this.domain}/${this.path}/parameters/${id}`, {
            method: 'GET',
        }).catch((err) => {
            throw err;
        });
    }
}
