export default class NumberUtils {
    static isNumber(value) {
        return /^-?\d*\.?\d+$/.test(value);
    }

    static isString(value) {
        return /^-?\d*\.?\d+$/.test(value);
    }
    static formatTimeIfPossible(time) {
        if (NumberUtils.isNumber(time)) {
            const hours = Math.floor(time / 60);
            const mins = time % 60;
            return `${hours}:${mins.toString().padStart(2, '0')}`;
        } else {
            return '';
        }
    }

    static formatMinutesToHoursAndMinutes(minutes) {
        const hours = Math.floor(minutes / 60);
        const restMinutesFromDivision = minutes % 60;
        const hoursStr = hours < 10 ? '0' + hours : hours;
        const minutesStr = restMinutesFromDivision < 10 ? '0' + restMinutesFromDivision : restMinutesFromDivision;
        return `${hoursStr}:${minutesStr}`;
    }

    static formatTimeIfPossibleToMaskComponent(time) {
        if (NumberUtils.isNumber(time)) {
            const hours = Math.floor(time / 60);
            const mins = time % 60;
            return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
        } else {
            return '';
        }
    }

    static formatTimeFromMaskToMinutes(time) {
        if (time === null || time === undefined) {
            return 0;
        } else if (!NumberUtils.isNumber(time)) {
            let hours = parseInt(time.slice(0, 2));
            let minutes = parseInt(time.slice(3, 5));
            let result = hours * 60 + minutes;
            return result;
        } else return 0;
    }

    static formatNumber(number, minimumFractionDigits = 2, maximumFractionDigits = 2) {
        if (!number) {
            return 0;
        }
        const formatter = new Intl.NumberFormat('pl-PL', {
            style: 'decimal',
            currency: 'EUR',
            minimumFractionDigits: minimumFractionDigits,
            maximumFractionDigits: maximumFractionDigits,
        });
        const formattedNumber = formatter.format(number);
        return formattedNumber;
    }

    static formatTimeAndMultiplyBy(time, s) {
        if (NumberUtils.isNumber(time)) {
            const hours = Math.floor(time / 60);
            const mins = time % 60;
            return hours * s + (mins / 60) * s;
        } else {
            return '';
        }
    }
}
