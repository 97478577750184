import { Card } from 'primereact/card';
import PropTypes from 'prop-types';
import React from 'react';
import { withTranslation } from 'react-i18next';
import ActionLink from '../../components/ActionLink';
import DivContainer from '../../components/DivContainer';
import InputDropdownComponent from '../../components/inputs/InputDropdownComponent';
import InputTextComponent from '../../components/inputs/InputTextComponent';
import YesNoDialog from '../../components/YesNoDialog';
import BaseUserDetailsContainer from '../../baseContainers/BaseUserDetailsContainer';
import EmployeeService from '../../services/EmployeeService';
import EmployeeModel from '../../model/EmployeeModel';
import { t } from 'i18next';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import InputCalendarComponent from '../../components/inputs/InputCalendarComponent';
import moment from 'moment/moment';
import InputNumberComponent from '../../components/inputs/InputNumberComponent';
import SubcontractorService from '../../services/SubcontractorService';
import InputCheckboxComponent from '../../components/inputs/InputCheckboxComponent';
import SettingService from '../../services/SettingService';
import { TabPanel, TabView } from 'primereact/tabview';
import EmployeeAbsenceReasonService from '../../services/EmployeeAbsenceReasonService';
import DocumentTypeService from '../../services/DocumentTypeService';
import EmployeeCategoryService from '../../services/EmployeeCategoryService'
import InputMultiSelectComponent from '../../components/inputs/InputMultiSelectComponent';
import IconColorComponent from '../../components/inputs/IconColorComponent';
import LogEntryService from '../../services/LogEntryService';
import DocumentExpirationStatusDialog from './DocumentExpirationStatusDialog';
import Constants from '../../utils/constants';
import LogEntryListContainer from '../LogEntryListContainer';
import AlarmStatusIconTemplateComponent from '../../components/AlarmStatusIconTemplateComponent';
import InternalUserService from '../../services/InternalUserService';


class EmployeeContainer extends BaseUserDetailsContainer {
    constructor(props) {
        super(props, new EmployeeService());        
        this.subcontractorService = new SubcontractorService();
        this.settingsService=new SettingService()
        this.absenseReasonService=new EmployeeAbsenceReasonService();
        this.documentService = new DocumentTypeService();
        this.categoryService = new EmployeeCategoryService();
        this.logEntryService=new LogEntryService();
        this.internalUserService = new InternalUserService();
        this.contractDayMode = {
            view: 'month',
            dateFormat: 'mm-yy',
            placeholder: 'mm-yy',
            dateFormatMoment: 'MM-YYYY',
            yearNavigator: true,
        };
       const queryParams = new URLSearchParams(this.props.history.location.search);
       let backActiveIndex= parseInt(queryParams.get('backActiveIndex'));
       let activeIndexParam = parseInt(queryParams.get('activeIndex'));
       let firstParam = parseInt(queryParams.get('first'));
       let maxsParam=parseInt(queryParams.get('maxs'));

        const roleAdmin = this.authService.isUserInRole('ROLE_ADMIN');
        const roleCto = this.authService.isUserInRole('ROLE_CTO');
        const rolePm = this.authService.isUserInRole('ROLE_USER');
        const roleEm = this.authService.isUserInRole('ROLE_EXTERNAL_MANAGER');
        const currentUser = this.authService.getProfile();

        const element = new EmployeeModel();
        element.externalManager = roleEm && !roleAdmin && !roleCto && !rolePm ? {id: currentUser.subId, fullName: currentUser.name} : undefined;

        this.state = {
            activeIndex: activeIndexParam ? activeIndexParam : 0,
            backActiveIndex: backActiveIndex ? backActiveIndex : 0,
            firstParam: firstParam ? firstParam : 0,
            maxsParam: maxsParam ? maxsParam : 10,
            dialogVisibile: false,
            showConfirmBlockDialog: false,
            showConfirmUnblockDialog: false,
            elementId: this.props.elementId,
            element: element,
            contractType: true,
            loading: false,
            statusOptions: [],
            contractTypes: [],
            subcontractors: [],
            categories: [],
            absenceReasons: [],
            documentTypes: [],
            alarms: [],
            docActionDialogVisible: false,
            externalManagers: [],
        };
        this.setContractsDatesTo = this.setContractsDatesTo.bind(this);
        this.calculateMinDateForDateFrom = this.calculateMinDateForDateFrom.bind(this);
        this.calculateMinDateForDateTo = this.calculateMinDateForDateTo.bind(this);
        this.calculateMaxDateForDateFrom = this.calculateMaxDateForDateFrom.bind(this);
        this.documentStatusAction=this.documentStatusAction.bind(this);
        this.getPreparedBackUrl=this.getPreparedBackUrl.bind(this);
        this.prepareSaveAndCancelButton=this.prepareSaveAndCancelButton.bind(this);
        this.hideDocStatusDialog=this.hideDocStatusDialog.bind(this);
        this.refreshCurrentElement=this.refreshCurrentElement.bind(this);

        this.validator.addCustomRule('contract_no_duplicates', t('employee.details.vaidation_contract_no_duplicates'), (value) => {
            const dates = value.map((c) => moment(c.dateFrom).format('yyyy-MM-DD'));
            const findDuplicatesFunction = (arry) => arry.filter((item, index) => dates.indexOf(item) !== index);
            const duplicates = findDuplicatesFunction(dates);
            return duplicates.length == 0;
        });
        this.validator.addCustomRule('no_overlapping_dates', t('employee.absence.validation.no_overlapping_dates'), (value) => {
            let flag = true;
            value.forEach((x, indexX) => {
                value.forEach((y, indexY) => {

                    if (indexX !== indexY) {
                        const startDateY = moment(y.dateFrom);
                        const endDateY = moment(y.dateTo);
                        const startDateX = moment(x.dateFrom);
                        const endDateX = moment(x.dateTo);

                        if (
                            startDateX.isBetween(startDateY, endDateY) ||
                            endDateX.isBetween(startDateY, endDateY) ||
                            endDateX.isSame(endDateY) && startDateX.isSame(startDateY) ||
                            endDateX.isSame(startDateY) || startDateX.isSame(endDateY)
                        ) {
                            flag = false;
                        }
                    }
                });
            });
            return flag;
        })

        this.validator.addCustomRule('date_from_less_than_date_to', 'Data od nie moze być po dacie powrotu', (value,index) => {
                const absence = this.state.element.absences[index];
                const dateTo = absence.dateTo;
                return moment(value).isSameOrBefore(moment(dateTo));
            }   ,
            (message, params) => message.replace(':date', params[0])
        )

         this.validator.addCustomRule('date_to_must_be_bigger_than_date_from', 'Data powrotu nie moze być przed datą od',
            (value,index) => {
                const absence = this.state.element.absences[index];
                const dateFrom = absence.dateFrom;
                return moment(value).isSameOrAfter(moment(dateFrom));
            },
            (message, params) => message.replace(':date', params[0])
        )
        this.validator.addCustomRule('contract_must_be_defined', 'Pracownik musi posiadać przynajmniej jeden kontrakt',
        (value) => {
            return value.length>0;
        }
    )
    }

    sortContracts(contracts) {
        contracts.sort((c1, c2) => (c1.dateFrom < c2.dateFrom ? 1 : -1));
    }

    sortDocuments(documents){
        documents.sort((c1, c2) => (c1.dateTo < c2.dateTo ? 1 : -1));
    }
    getContainerListName() {
        return 'employee-list-container';
    }

    getAddSucces() {
        const { t } = this.props;
        return t('employee.addUserSuccess');
    }

    getUpdateSucces() {
        const { t } = this.props;
        return t('employee.updateUserSuccess');
    }

    updateElement(data) {
        if (data && data.contracts) {
            this.sortContracts(data.contracts);
        }
        if(data && data.documents){
            this.sortDocuments(data.documents);
        }

        this.setState(
            {
                element: EmployeeModel.copy(data),
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    getPreparedBackUrl(){
        const {backActiveIndex}=this.state;

        let backUrl = this.props.backUrl;
        if(backActiveIndex){
            backUrl+='?activeIndex='+backActiveIndex;
        }
        if(this.state.firstParam){
            if(backUrl.includes('?')){
               backUrl+=`&first=${this.state.firstParam}`
            }else{
                backUrl+=`?first=${this.state.firstParam}`
            }
        }
        if(this.state.maxsParam){
            if(backUrl.includes('?')){
                backUrl+=`&maxs=${this.state.maxsParam}`
             }else{
                backUrl+=`?maxs=${this.state.maxsParam}`
            }
        }
        return backUrl;
    }

    afterUpdateSuccess(response) {
        let backUrl = this.getPreparedBackUrl();
        this.blockUi();
        this.persistMessage('success', '', this.getUpdateSucces(response));
        window.location.href = backUrl;
    }

    handleGoBack(e) {
        e.preventDefault();
        this.blockUi();
        let backUrl = this.getPreparedBackUrl();
        window.location.href = backUrl;
    }

    renderBackLink() {
        return (
            <ActionLink
                rendered={true}
                label={this.getBackLabel()}
                className='p-link float-right'
                handleClick={this.handleGoBack.bind(this)}
                variant='green'
                iconName='mdi-arrow-left'
                iconSide='left'
                iconSize='xs'
                iconColor='green'
            />
        );
    }
    
    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        this.setState({ activeIndex: 0 }, () => {
            if (this.validator.allValid()) {
                this.setState({ activeIndex: 1 }, () => {
                    if (this.validator.allValid()) {
                        this.setState({activeIndex: 2},()=>{
                            if(this.validator.allValid()){
                            this.blockUi(this.handleValidForm);
                        } else {
                            this.validator.showMessages();
                            this.scrollToError = true;
                            this.forceUpdate();
                        }
                        })
                    }
                })
            } else {
                this.validator.showMessages();
                this.scrollToError = true;
                this.forceUpdate();
            }

        });
    }

    initBeforeSetElement() {
        this.enumService
            .getEnumList('UserActivityStatus')
            .then((statusOptions) =>
                this.setState({
                    statusOptions,
                })
            )
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });

        this.enumService
            .getEnumList('EmployeeContractType')
            .then((contractTypes) => this.setState({ contractTypes }))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy typów kontraktu');
            });

        this.subcontractorService
            .getActiveContractors()
            .then((res) => {
                this.setState({
                    subcontractors: res,
                });
            })
            .catch((ex) => {
                this.showErrorMessage('Nie udało się pobrać listy aktywnych subkontraktorów');
            });

        this.settingsService
        .getValue('EMAIL_DOMAIN')
        .then((res)=>{
            this.setState({
                emailDomain: res.value
            })
        }).catch((ex) => {
            this.showErrorMessage('Nie udało się parametru domeny email');
        });

        this.absenseReasonService
        .getList({ firstResult: 0, maxResults: 1000, sortField: 'reason', sortAsc: true,
        status: {enumValue:'ACTIVE', label: "Aktywny"}
        })
        .then((data)=>{
            this.setState({
                absenceReasons: data.content
            })
        }).catch((ex) => {
            this.showErrorMessage('Nie udało się pobrać powodów nieobecności');
        });;

        this.documentService.getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true,
         status: {enumValue:'ACTIVE', label: "Aktywny"}
        }).then((data)=>{
            this.setState({
                documentTypes: data.content
            })
        }).catch((ex) => {
            this.showErrorMessage('Nie udało się pobrać typów dokumentów');
        });
        this.categoryService.getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
          .then(categories => this.setState({ categories: categories.content }))
           .catch(() => {
             this.showErrorMessage('Nie udało się pobrać listy kategorii');
           });
        this.internalUserService.getSimpleList(['ROLE_EXTERNAL_MANAGER'])
           .then(users => this.setState({ externalManagers: users }))
           .catch(() => {
               this.showErrorMessage('Nie udało się pobrać listy menedżerów zewnętrznych');
           });    
    }
    initAfterSetElement() {
        super.initAfterSetElement();
    }

    prepareSaveAndCancelButton(){
        const {backUrl, cancelUrl, viewMode} = this.props;
        const {element} = this.state;
        let goBackUrl;
        if (viewMode === 'NEW') {
            goBackUrl = backUrl;
        } else {
            goBackUrl = `${cancelUrl}/${element.id}${this.backUrlParams()}`;

            const {backActiveIndex}=this.state;
            const {firstParam}=this.state;
            const {maxsParam} =this.state;
            if(backActiveIndex){
                goBackUrl+='&backActiveIndex='+backActiveIndex;
            }
            if(firstParam){
                goBackUrl+='&first='+firstParam;
            }
            if(maxsParam){
                goBackUrl+='&maxs='+maxsParam
            }
        }
        switch (viewMode) {
            case 'EDIT':
            case 'NEW':
                return [
                    {label: 'Anuluj', className: 'cancel-button white', href: goBackUrl},
                    {label: 'Zapisz', className: 'float-right', onClick: this.handleFormSubmit},
                ];
            case 'VIEW':
            default:
                return [];
        }
    }
    prepareFooterItems() {
        const {editUrl, elementId } = this.props;
        const {element}=this.state;
        const result = this.prepareSaveAndCancelButton();

        if (this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER') && this.props.viewMode === 'VIEW') {
            result.push({
              label: t('details.editButton'),
              className: 'float-right',
              href: `${editUrl}/${element.id}?activeIndex=${this.state.activeIndex}&backActiveIndex=${this.state.backActiveIndex}`
            });
            if (this.state.element.status.enumValue === 'ACTIVE') {
                result.push({
                    label: t('employee.details.blockButton'),
                    className: 'fullred float-right',
                    onClick: this.showBlockConfirmation,
                });
            } else {
                result.push({
                    label: t('employee.details.unblockButton'),
                    className: 'fullred float-right',
                    onClick: this.showUnblockConfirmation,
                });
            }
        }

        return result;
    }

    calculateMinDateForDateFrom(rowIndex) {
        const contracts = this.state.element?.contracts;
        if (contracts.length > 0) {
            const currentContract = contracts[rowIndex];
            const prevContract = contracts[rowIndex + 1];
            if (prevContract?.dateTo) {
                return moment(prevContract.dateFrom).clone().add(1, 'months').toDate();
            } else {
                return moment(currentContract.dateFrom).toDate();
            }
        }
        return null;
    }
    calculateMaxDateForDateFrom(rowIndex) {
        const contracts = this.state.element?.contracts;
        if (contracts.length > 0) {
            const currentContract = contracts[rowIndex];
            if (currentContract.dateTo) return moment(currentContract.dateTo).toDate();
        }
        return null;
    }

    calculateMinDateForDateTo(rowIndex) {
        const contracts = this.state.element?.contracts;
        if (contracts.length > 0) {
            const currentContract = contracts[rowIndex];
            if (currentContract.dateFrom) {
                return moment(currentContract.dateFrom).toDate();
            }
        }
        return null;
    }

    contractDateFromTemplate(rowData, column) {
        const rowIndex = column.rowIndex;
        const dateFrom = rowData.dateFrom;
        const disabled = (moment(dateFrom).isSameOrBefore(moment(), this.contractDayMode.view) && rowData.id);
        const canEdit = this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_FINANCIAL_MANAGER', 'ROLE_CTO');
        return (
            <div style={{ minWidth: '120px', maxWidth: '200px' }}>
                <InputCalendarComponent
                    id={`contractDateFrom${rowIndex}`}
                    name='dateFrom'
                    value={rowData.dateFrom}
                    stateField={`element.contracts[${rowIndex}]`}
                    showLabel={false}
                    onChange={this.handleChange}
                    insideTable
                    viewMode={canEdit ? this.props.viewMode : 'VIEW'}
                    validator={this.validator}
                    validators='required'
                    disabled={disabled}
                    minDate={(disabled || this.state.element?.contracts.length === 1)
                    ? null : this.calculateMinDateForDateFrom(rowIndex)}
                    maxDate={this.calculateMaxDateForDateFrom(rowIndex)}
                    showClear
                    onAfterStateChange={() => {
                        this.setContractsDatesTo();
                    }}
                    dateFormatMoment={this.contractDayMode.dateFormatMoment}
                    view={this.contractDayMode.view}
                    dateFormat={this.contractDayMode.dateFormat}
                    placeholder={this.contractDayMode.placeholder}
                    yearNavigator={this.contractDayMode.yearNavigator}
                    showIcon
                />
            </div>
        );
    }

    contractDateToTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        const canEdit = this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_FINANCIAL_MANAGER', 'ROLE_CTO');
        return (
            <div style={{ minWidth: '120px', maxWidth: '180px' }}>
                <InputCalendarComponent
                    id={`contractDateTo${rowIndex}`}
                    name='dateTo'
                    value={rowData.dateTo}
                    stateField={`element.contracts[${rowIndex}]`}
                    showLabel={false}
                    onChange={this.handleChange}
                    insideTable
                    minDate={this.calculateMinDateForDateTo(rowIndex)}
                    viewMode={canEdit ? this.props.viewMode : 'VIEW'}
                    validator={this.validator}
                    validators='not_required'
                    disabled
                    dateFormatMoment={this.contractDayMode.dateFormatMoment}
                    view={this.contractDayMode.view}
                    dateFormat={this.contractDayMode.dateFormat}
                    placeholder={this.contractDayMode.placeholder}
                    yearNavigator={this.contractDayMode.yearNavigator}
                    showIcon
                />
            </div>
        );
    }


    absenceDateFromTemplate(rowData, column){
        const rowIndex = column.rowIndex;

        let validators=`required`;
        if(rowData.dateTo!=null){
            validators+=`|date_from_less_than_date_to:${rowIndex}`;
        }
        return (
            <div style={{minWidth: '120px', maxWidth: '200px'}}>
                <InputCalendarComponent
                    id={`absenceDateFrom${rowIndex}`}
                    label={t('employee.absence.dateFrom')}
                    name='dateFrom'
                    value={rowData.dateFrom}
                    stateField={`element.absences[${rowIndex}]`}
                    showLabel={false}
                    onChange={this.handleChange}
                    insideTable
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators={validators}
                    showClear
                    showIcon
                />
            </div>
        );
    }
    absenceDateToTemplate(rowData,props){
        const rowIndex = props.rowIndex;
        let validators=`required`;

        if(rowData.dateFrom!=null){
            validators+=`|date_to_must_be_bigger_than_date_from:${rowIndex}`;
        }
            return (
                <div style={{minWidth: '120px', maxWidth: '200px'}}>
                    <InputCalendarComponent
                        id={`absenceDateTo${rowIndex}`}
                        label={t('employee.absence.dateTo')}
                        name='dateTo'
                        value={rowData.dateTo}
                        stateField={`element.absences[${rowIndex}]`}
                        showLabel={false}
                        onChange={this.handleChange}
                        insideTable
                        viewMode={this.props.viewMode}
                        validator={this.validator}
                        validators={validators}
                        showIcon
                    />
                </div>
            );
    }

    contractTypeTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        const canEdit = this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_FINANCIAL_MANAGER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER');
        return (
            <div style={{ minWidth: '150px', maxWidth: '200px' }}>
                <InputDropdownComponent
                    id={`contractType${rowIndex}`}
                    name='contractType'
                    label={t('employee.details.contractType')}
                    value={rowData.contractType}
                    stateField={`element.contracts[${rowIndex}]`}
                    showLabel={false}
                    options={this.state.contractTypes}
                    optionLabel='label'
                    dataKey='enumValue'
                    onChange={this.handleChange}
                    insideTable
                    viewMode={canEdit ? this.props.viewMode : 'VIEW'}
                    validator={this.validator}
                    validators='required'
                    colClass='p-2'
                />
            </div>
        );
    }

    absenceReasonTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        const { element }= this.state;
        let options = this.state.absenceReasons;

        if (element && element.absences) {
            let absence = element.absences[rowIndex];
            if (absence.absenceReason) {
                let reason = absence.absenceReason;
                if (reason.status.enumValue==='REMOVED') {
                    let copy = [...this.state.absenceReasons];
                    copy.push(reason);
                    options = copy;
                }
            }
        }

        return (
            <div style={{minWidth: '150px', maxWidth: '200px'}}>
                <InputDropdownComponent
                    id={`absenceReason${rowIndex}`}
                    name='absenceReason'
                    label={t('employee.absence.absenceReason')}
                    value={rowData.absenceReason}
                    stateField={`element.absences[${rowIndex}]`}
                    showLabel={false}
                    options={options}
                    optionLabel='reason'
                    onChange={this.handleChange}
                    insideTable
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    colClass='p-2'
                />
            </div>
        );
    }
    contractPriceTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        return (
            <InputNumberComponent
                id={`contractPrice${rowIndex}`}
                name='value'
                label={t('employee.details.price')}
                value={rowData.price.value}
                stateField={`element.contracts[${rowIndex}].price`}
                showLabel={false}
                onChange={this.handleChange}
                insideTable
                viewMode={this.props.viewMode}
                validator={this.validator}
                validators='required'
                mode='currency'
                currency='EUR'
                locale='pl-PL'
            />
        );
    }

    contractCostTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        return (
            <InputNumberComponent
                id={`contractCost${rowIndex}`}
                name='value'
                label={t('employee.details.cost')}
                value={rowData.cost.value}
                stateField={`element.contracts[${rowIndex}].cost`}
                showLabel={false}
                onChange={this.handleChange}
                insideTable
                viewMode={this.props.viewMode}
                validator={this.validator}
                validators='required'
                mode='currency'
                currency='EUR'
                locale='pl-PL'
            />
        );
    }

    contractsActionsTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        const dateFrom = rowData.dateFrom;
        const disabled = moment(dateFrom).isSameOrBefore(moment(), this.contractDayMode.view) && rowData.id;
        return (
            <span>
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => {
                        let contracts = this.state.element.contracts.filter((c) => c !== rowData);
                        this.setState(
                            (prevState) => ({
                                element: {
                                    ...prevState.element,
                                    contracts,
                                },
                            }),
                            () => this.setContractsDatesTo(rowIndex)
                        );
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                    disabled={disabled}
                />
            </span>
        );
    }
    absencesActionsTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        const dateFrom = rowData.dateFrom;
        return (
            <span>
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => {
                        let absences = this.state.element.absences.filter((c) => c !== rowData);
                        this.setState(
                            (prevState) => ({
                                element: {
                                    ...prevState.element,
                                    absences,
                                },
                            }),
                            () => {}
                        );
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                />
            </span>
        );
    }
    documentTypeTemplate(rowData, props){
        const rowIndex = props.rowIndex;
        const { element }= this.state;
        let options = this.state.documentTypes;

        if (element && element.documents) {
            let document = element.documents[rowIndex];
            if (document.documentType) {
                let type = document.documentType;
                if (type.status.enumValue==='REMOVED') {
                    let copy = [...this.state.documentTypes];
                    copy.push(type);
                    options = copy;
                }
            }
        }
        return (
            <div style={{minWidth: '150px'}}>
                <InputDropdownComponent
                    id={`documentType${rowIndex}`}
                    name='documentType'
                    label={t('employee.document.documentType')}
                    value={rowData.documentType}
                    stateField={`element.documents[${rowIndex}]`}
                    showLabel={false}
                    options={options}
                    optionLabel='name'
                    onChange={this.handleChange}
                    insideTable
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    colClass='p-2'
                />
            </div>
        );
    }
    documentTypeNumberTemplate(rowData, props){
        const rowIndex = props.rowIndex;
        return (
        <div style={{minWidth: '120px'}}>
          <InputTextComponent
                id={`docNumber${rowIndex}`}
                name='docNumber'
                label={t('employee.document.number')}
                colClass='col-md-3'
                stateField={`element.documents[${rowIndex}]`}
                value={rowData.docNumber}
                showLabel={false}
                onChange={this.handleChange}
                viewMode={this.props.viewMode}
                insideTable
                validator={this.validator}
                validators="required|max:30"
            />
         </div>
     );
    }
    documentDateToTemplate(rowData, props){
        const rowIndex = props.rowIndex;
        return (
            <div style={{minWidth: '120px', maxWidth: '200px'}}>
                <InputCalendarComponent
                    id={`absenceDateTo${rowIndex}`}
                    label={t('employee.document.dateTo')}
                    name='dateTo'
                    value={rowData.dateTo}
                    stateField={`element.documents[${rowIndex}]`}
                    showLabel={false}
                    onChange={this.handleChange}
                    insideTable
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators="required"
                    showIcon
                />
            </div>
        );
    }
    documentActionsTemplate(rowData,props){
        const rowIndex = props.rowIndex;
        return (
            <span>
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => {
                        let documents = this.state.element.documents.filter((c) => c !== rowData);
                        this.setState(
                            (prevState) => ({
                                element: {
                                    ...prevState.element,
                                    documents,
                                },
                            }),
                        );
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                />
            </span>
        );
    }

    setContractsDatesTo() {
        const contracts = this.state.element.contracts;
        contracts.forEach((c) => {
            c.dateTo = null;
            const dates = contracts.map((c2) => c2.dateFrom).filter((d) => d > c.dateFrom);
            if (dates.length > 0) {
                const minDate = new Date(Math.min(...dates.map((date) => date.getTime())));
                c.dateTo = moment(minDate).subtract(1, this.contractDayMode.view).toDate();
            }
        });
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                contracts: contracts,
            },
        }));
    }

    backUrlParams() {
        return `?activeIndex=${this.state.activeIndex}`;
    }

    isPdfButtonRendered() {
        return false;
    }

    canAddNewContract() {
        if ((this.props.viewMode === 'NEW' && this.state.element?.contracts?.length === 0) || this.props.viewMode === 'EDIT') {
            return true;
        }
        return false;
    }
    createQueryString(queryObject = {}) {
        let queryString = Object.keys(queryObject)
            .filter((key) => queryObject[key] && !(Array.isArray(queryObject[key]) && !queryObject[key].length))
            .map((key) => {
                return Array.isArray(queryObject[key]) ? queryObject[key].map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&') : `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`;
            }).join('&');
        return queryString ? `?${queryString}` : "";
    };
    canAddNewAbsence(){
        if ((this.props.viewMode === 'NEW') || this.props.viewMode === 'EDIT') {
            return true;
        }
        return false;
    }
    canAddNewDocument(){
        if ((this.props.viewMode === 'NEW') || this.props.viewMode === 'EDIT') {
            return true;
        }
        return false;
    }
    absenceConfirmedTemplate(rowData,props){
        const {rowIndex}=props;
        return (
            <InputCheckboxComponent
             id={`absenceConfirmed`}
             name='absenceConfirmed'
             label={''}
             stateField={`element.absences[${rowIndex}]`}
             value={rowData.absenceConfirmed}
             validator={this.validator}
             validators='not_required'
             onChange={this.handleChange}
             viewMode={'EDIT'}
             insideTable={true}
             labelOnRight={true}
             disabled={this.props.viewMode === 'VIEW'}
            />
        );
    }
    inputColorComponent(){
        if(this.state?.element?.categories)
        return <div className="row mb-4" style={{marginTop:"7px", marginBottom:"-12px"}}>
                <div>
                    <label id={`-label-id`} className='p-label' style={{marginBottom: '-6px', marginTop:"12px"}}>
                        Kategorie
                    </label>
                </div>
              {this.state?.element?.categories.map(category=>{
                  return <IconColorComponent componentStyle={{marginLeft:"-50px"}} componentClassName={"col-1"} fontSize={"22px"} icon={category.icon.value} color={category.color.value} tootltipText={category.name} />
           })}
          </div>
      }
      getPreparedExpirationStatusIcon(documentStatus){
        switch(documentStatus.enumValue){
            case 'EXPIRED':
                return(
                    <div className='row' style={{ display: 'flex', justifyContent: 'center',width:'100px'}}>
                     {
                         <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={Constants.ALERT_ICON} color={Constants.RED} tootltipText={documentStatus.label} />
                     }
                 </div>
                )
            case 'SOON_EXPIRED':
                return(
                    <div className='row' style={{ display: 'flex', justifyContent: 'center',width:'100px'}}>
                     {
                         <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={Constants.ALERT_ICON} color={Constants.ORANGE} tootltipText={documentStatus.label} />
                     }
                 </div>
                )
            case 'HANDLED':
                return(
                    <div className='row' style={{ display: 'flex', justifyContent: 'center',width:'100px'}}>
                     {
                         <IconColorComponent componentClassName="col-1" fontSize={"22px"} icon={Constants.HANDLED_ICON} color={Constants.GREEN} tootltipText={documentStatus.label} />
                     }
                 </div>
                )
            default:
                return null;
        }
    }
    documentStatusTemplate(rowData){
        if(rowData?.expirationStatus){
        let documentStatus=rowData.expirationStatus;
        return documentStatus.enumValue ? (
                this.getPreparedExpirationStatusIcon(documentStatus)
             ) : null
         }
    }

    refreshCurrentElement(){
        this.setElement();
    }

    documentStatusAction(rowData) {
        let expirationStatus = rowData?.expirationStatus;
        return (
            <div style={{ alignItems: 'center' }}>
                {expirationStatus !=null && (
                    <ActionLink
                        iconName='mdi-eye'
                        iconSide='left'
                        iconSize='icon-large'
                        handleClick={(e) => {
                            this.setState({
                                docActionDialogVisible: true,
                                selectedItem: rowData
                            });
                        }}
                        variant='blue'
                        className='edit-blue-link'
                        key={'view-button'}
                    ></ActionLink>)
                }
                {(this.state.docActionDialogVisible && this.state.selectedItem === rowData) && (
                    <DocumentExpirationStatusDialog
                        visible={true}
                        className='car-dialog'
                        key={`car-dialog-${this.state.element?.id}`}
                        handled={rowData?.alarmHandled}
                        viewMode={this.state.dialogViewMode}
                        afterConfirmation={(e) => {
                            this.hideDocStatusDialog();
                            this.showSuccessMessage('Obsłużono alarm');
                            this.refreshCurrentElement();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                        onHide={() => {
                            this.hideDocStatusDialog();
                        }}
                        elementId={rowData?.id}
                        element={rowData}
                        employeeId={this.state.element?.id}
                        style={{
                            width: 'auto',
                            height: 'auto'
                        }}
                    />
                )}
            </div>
        )
    }

    hideDocStatusDialog(){
        this.setState({
            docActionDialogVisible: false
        });
    }


    renderDetails() {
        const emailRequired = this.state.element.loginAllowed ? 'required' : 'not_required';
        const emailDomain = this.state.emailDomain;
        if(this.state.element && this.state.element.categories){
            this.state.element.categories.forEach(c => {
               if (!this.state.categories.map((x) => x.id).includes(c.id)) {
                  this.state.categories.push(c);
                }
            });
        }
        const canViewPrices = this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_FINANCIAL_MANAGER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER');
        const roleAdmin = this.authService.isUserInRole('ROLE_ADMIN');
        const rolePm = this.authService.isUserInRole('ROLE_USER');        
        const roleCto = this.authService.isUserInRole('ROLE_CTO');
        const roleEm = this.authService.isUserInRole('ROLE_EXTERNAL_MANAGER');
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <DivContainer colClass='col-12'>
                        <div className='row profile'>
                            <InputCheckboxComponent
                                id={`loginAllowed`}
                                name='loginAllowed'
                                label={'Mozliwość logowania do aplikacji mobilnej'}
                                value={this.state.element.loginAllowed}
                                validator={this.validator}
                                validators='not_required'
                                labelOnRight
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                onChange={this.handleChange}
                                viewMode={'EDIT'}
                                disabled={this.props.viewMode === 'VIEW'}
                            />
                            <InputTextComponent
                                id='email'
                                name='email'
                                label={t('employee.details.email')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.email}
                                validator={this.validator}
                                validators={`${emailRequired}|email_domain:${emailDomain}|max:100`}
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                        </div>
                        <div className='row profile'>
                            <InputTextComponent
                                id='firstName'
                                name='firstName'
                                label={t('employee.details.firstName')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.firstName}
                                validator={this.validator}
                                validators='required|alpha_space_dash|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                            <InputTextComponent
                                id='lastName'
                                name='lastName'
                                label={t('employee.details.lastName')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.lastName}
                                validator={this.validator}
                                validators='required|alpha|max:100'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                            <InputTextComponent
                                id='phoneNumber'
                                name='phoneNumber'
                                label={t('employee.details.phoneNumber')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.phoneNumber}
                                validator={this.validator}
                                validators='not_required|phone|max:20'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                            />
                        </div>
                        <div className='row profile'>
                            <InputTextComponent
                                id='link'
                                name='link'
                                label={t('employee.details.link')}
                                colClass='col-xl-8 col-lg-12 col-md-12 col-sm-12'
                                value={this.state.element.link}
                                validator={this.validator}
                                validators='not_required|max:200'
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                                renderValue={(value) => {
                                    return (
                                        <a href={value} style={{ display: 'block', wordWrap: 'break-word',maxWidth:'300px' }}>{value}</a>
                                    )
                                }}
                            />
                            <InputDropdownComponent
                                id={`externalManager`}
                                name="externalManager"
                                label={t('employee.details.externalManager')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.externalManager}
                                valueView={this.state.element?.externalManager?.fullName}
                                dataKey='id'
                                options={this.state.externalManagers}
                                optionLabel='fullName'
                                viewMode={this.props.viewMode}
                                validator={this.validator}
                                validators='not_required'
                                onChange={this.handleChange}
                                filter
                                filterBy="fullName"
                                showClear
                                disabled={roleEm && !roleAdmin && !rolePm && !roleCto}
                            />
                        </div>
                        <div className='row profile'>
                            <InputDropdownComponent
                                id='subcontractor'
                                name='subcontractor'
                                label={t('employee.details.subcontractorName')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.subcontractor}
                                validator={this.validator}
                                options={this.state.subcontractors}
                                onChange={this.handleChange}
                                viewMode={this.props.viewMode}
                                dataKey='id'
                                filter={true}
                                showClear={true}
                                validators='not_required'
                                optionLabel='name'
                            />
                             <InputDropdownComponent
                                id='status'
                                name='status'
                                label={t('employee.details.status')}
                                colClass='col-xl-4 col-lg-6 col-md-6 col-sm-12'
                                value={this.state.element.status}
                                validator={this.validator}
                                options={this.state.statusOptions}
                                onChange={null}
                                viewMode={this.props.viewMode === 'EDIT' ? 'VIEW' : this.props.viewMode}
                                optionLabel='label'
                                dataKey='enumValue'
                                validators='not_required'
                                rendered={this.props.viewMode !== 'NEW'}
                                valueView={this.state.element.status ? this.state.element.status.label : ''}
                            />
                            {this.props.viewMode === "VIEW" ? 
                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12'>{this.inputColorComponent()}</div>  :
                            <InputMultiSelectComponent
                                id='categories'
                                name='categories'
                                label={t('project.details.categories')}
                                colClass='col-md-6'
                                onChange={this.handleChange}
                                options={this.state.categories}
                                optionLabel='name'
                                value={this.state.element.categories}
                                viewMode={this.props.viewMode}
                                dataKey='id'
                                selectedItemTemplate={this.selectedItemTemplate}
                            />  
                        }
                        

                        </div>
                                                <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => {
                                                    this.setState({ activeIndex: e.index }, () => {
                                                        this.props.history.push({
                                                            search: this.createQueryString({ activeIndex: e.index })
                                                        });
                                                    });
                                                }} >
                                                <TabPanel header={t('employee.absence.label')}>
                                                    <div className='row profile'>
                                                    <Card header='Nieobecności' style={{marginTop: '50px'}}>
                                                        <DivContainer colClass='col-12'>
                                                            <DataTable id='absences' value={this.state.element.absences} responsiveLayout='scroll' emptyMessage={t('list.empty')}>
                                                                <Column
                                                                    field='dateFrom'
                                                                    header={t('employee.absence.dateFrom')}
                                                                    body={this.absenceDateFromTemplate.bind(this)}
                                                                ></Column>
                                                                <Column
                                                                    field='dateTo'
                                                                    header={t('employee.absence.dateTo')}
                                                                    body={this.absenceDateToTemplate.bind(this)}
                                                                ></Column>
                                                                <Column
                                                                    field='reason'
                                                                    header={t('employee.absence.reason')}
                                                                    body={this.absenceReasonTemplate.bind(this)}
                                                                ></Column>
                                                                <Column
                                                                    field='absenceConfirmed'
                                                                    header={t('employee.absence.confirmed')}
                                                                    body={this.absenceConfirmedTemplate.bind(this)}
                                                                    align={'center'}
                                                                ></Column>
                                                                {this.props.viewMode !== 'VIEW' ? (
                                                                    <Column header='' body={this.absencesActionsTemplate.bind(this)}></Column>
                                                                ) : null}
                                                            </DataTable>
                                                            <div aria-live='assertive'>
                                                                {this.validator.message(
                                                                    'absences',
                                                                    'Nieobecności',
                                                                    this.state.element.absences,
                                                                    "no_overlapping_dates"
                                                                )}
                                                            </div>
                                                        </DivContainer>
                                                        <DivContainer>
                                                            <ActionLink
                                                                label={t('employee.absence.addAbsenceButton')}
                                                                iconName='mdi-plus'
                                                                iconSide='left'
                                                                iconSize='icon-large'
                                                                variant='blue'
                                                                className='float-right'
                                                                key={'add-activity-button'}
                                                                handleClick={() => {
                                                                    const absences = this.state.element.absences;
                                                                    absences.unshift({dateFrom: null, dateTo: null, absenceReason: null});
                                                                    this.setState(
                                                                        (prevState) => ({
                                                                            element: {
                                                                                ...prevState.element,
                                                                                absences,
                                                                            },
                                                                        }),
                                                                       // () => this.setContractsDatesTo()
                                                                    );
                                                                }}
                                                                rendered={this.canAddNewAbsence()}
                                                            />
                                                        </DivContainer>
                                                    </Card>
                                                    </div>
                                                    </TabPanel>
                                                    <TabPanel header={t('employee.document.label')}>
                                                    <div className='row profile'>
                                                    <Card header='Dokumenty' style={{marginTop: '50px'}}>
                                                        <DivContainer colClass='col-12'>
                                                            <DataTable id='documents' value={this.state.element.documents} responsiveLayout='scroll' emptyMessage={t('list.empty')}>
                                                                <Column
                                                                    field='documentType'
                                                                    header={t('employee.document.documentType')}
                                                                    body={this.documentTypeTemplate.bind(this)}
                                                                ></Column>
                                                                <Column
                                                                    field='docNumber'
                                                                    header={t('employee.document.number')}
                                                                    body={this.documentTypeNumberTemplate.bind(this)}
                                                                ></Column>

                                                                <Column
                                                                    field='dateTo'
                                                                    header={t('employee.document.dateTo')}
                                                                    body={this.documentDateToTemplate.bind(this)}
                                                                ></Column>
                                                               <Column
                                                                  field='expirationStatus'
                                                                  header={t('employee.document.status')}
                                                                  body={this.documentStatusTemplate.bind(this)}
                                                                  headerStyle={{
                                                                    width:'200px',
                                                                  }}
                                                                  bodyStyle={{
                                                                    width:'200px',
                                                                    textAlign: 'center'
                                                                  }}

                                                                ></Column>
                                                                 {/* //TODO */}
                                                               {this.props.viewMode !== 'EDIT' && (
                                                                <Column
                                                                  field=''
                                                                  header={t('employee.documentStatusAction')}
                                                                  body={this.documentStatusAction.bind(this)}
                                                                />
                                                )}
                                                {this.props.viewMode !== 'VIEW' ? (
                                                    <Column header='' body={this.documentActionsTemplate.bind(this)}></Column>
                                                ) : null}


                                            </DataTable>
                                           </DivContainer>
                                                        <DivContainer>
                                                            <ActionLink
                                                                label={t('employee.document.addDocumentButton')}
                                                                iconName='mdi-plus'
                                                                iconSide='left'
                                                                iconSize='icon-large'
                                                                variant='blue'
                                                                className='float-right'
                                                                key={'add-activity-button'}
                                                                handleClick={() => {
                                                                    const documents = this.state.element.documents;
                                                                    documents.unshift({documentType: null, docNumber: '', dateTo:null});
                                                                    this.setState(
                                                                        (prevState) => ({
                                                                            element: {
                                                                                ...prevState.element,
                                                                                documents,
                                                                            },
                                                                        }),

                                                                    );
                                                                }}
                                                                rendered={this.canAddNewDocument()}
                                                            />
                                                        </DivContainer>
                                                    </Card>
                                                    </div>
                                                    </TabPanel>
                                                    <TabPanel header={t('Kontrakty')}>
                                                    <div className='row profile'>
                                                    <Card header='Kontrakty' style={{marginTop: '50px'}}>
                                                        <DivContainer colClass='col-12'>
                                                            <DataTable id='contracts' value={this.state.element.contracts} responsiveLayout='scroll' emptyMessage={t('list.empty')}>
                                                                <Column
                                                                    field='dateFrom'
                                                                    header={t('employee.details.dateFrom')}
                                                                    body={this.contractDateFromTemplate.bind(this)}
                                                                ></Column>
                                                                <Column
                                                                    field='dateTo'
                                                                    header={t('employee.details.dateTo')}
                                                                    body={this.contractDateToTemplate.bind(this)}
                                                                ></Column>
                                                  
                                                                <Column
                                                                  field='contractType'
                                                                  header={t('employee.details.contractType')}
                                                                 body={this.contractTypeTemplate.bind(this)}
                                                                 ></Column>
                                                                 {canViewPrices? (
                                                                    <Column
                                                                     field='price.value'
                                                                     header={t('employee.details.price')}
                                                                     body={this.contractPriceTemplate.bind(this)}
                                                                    ></Column>
                                                                    )  : null
                                                                 }
                                                                  {canViewPrices ? (
                                                                    <Column
                                                                      field='cost.value'
                                                                      header={t('employee.details.cost')}
                                                                      body={this.contractCostTemplate.bind(this)}
                                                                      ></Column>
                                                                     ) : null
                                                                  }
                                                                  {(this.props.viewMode !== 'VIEW' && canViewPrices) ? (
                                                                   <Column header='' body={this.contractsActionsTemplate.bind(this)}></Column>
                                                                          ) : null}
                                                </DataTable>
                                                {
                                                    this.state.element.contracts.length !== 0 ?
                                                        <div aria-live='assertive'>
                                                                {this.validator.message(
                                                                    'contracts',
                                                                    'Kontrakty',
                                                                    this.state.element.contracts,
                                                                    `contract_from_create_date_exists:${this.state.element.createDate}
                                                                    |contract_no_duplicates`
                                                                )}
                                                            </div>
                                                            :
                                                            <div aria-live='assertive'>
                                                                {this.validator.message(
                                                                    'contracts',
                                                                    'Kontrakty',
                                                                    this.state.element,
                                                                    `contract_must_be_defined`
                                                                )}
                                                            </div>
                                                            }
                                
                                                        </DivContainer>
                                                        <DivContainer>
                                                            <ActionLink
                                                                label={t('employee.details.addContractButton')}
                                                                iconName='mdi-plus'
                                                                iconSide='left'
                                                                iconSize='icon-large'
                                                                variant='blue'
                                                                className='float-right'
                                                                key={'add-activity-button'}
                                                                handleClick={() => {
                                                                    const contracts = this.state.element.contracts;
                                                                    const maxDate = new Date(Math.max(...contracts.map((c) => c.dateFrom.getTime())));
                                                                    let newDateFrom = moment().toDate();
                                                                    if (maxDate && moment(maxDate).isSameOrAfter(moment(), this.contractDayMode.view)) {
                                                                        newDateFrom = moment(maxDate).add(1, this.contractDayMode.view).toDate();
                                                                    }
                                                                    contracts.unshift({dateFrom: newDateFrom, price: {}, cost: {}});

                                                                    this.setState(
                                                                        (prevState) => ({
                                                                            element: {
                                                                                ...prevState.element,
                                                                                contracts,
                                                                            },
                                                                        }),
                                                                        () => this.setContractsDatesTo()
                                                                    );
                                                                }}
                                                                rendered={canViewPrices && this.canAddNewContract()}
                                                            />
                                                        </DivContainer>
                                                    </Card>
                                                    </div>
                                                    </TabPanel>
                                                    {this.props.viewMode !== "NEW" && <TabPanel header={t('Dni w kraju')}>
                                                    <div className='row profile'>
                                                    <Card header='Dni w kraju' style={{marginTop: '50px'}}>
                                                        <DivContainer colClass='col-12'>
                                                            <DataTable id='alarms' paginator rows={5}  value={this.state.element?.alarms} rowsPerPageOptions={[5, 10, 25, 50]} responsiveLayout='scroll' emptyMessage={t('list.empty')}>
                                                                <Column field="alarmDay" 
                                                                    header="Rok" 
                                                                    body={(row)=>{ return row.alarmDay.slice(0, 4)}}
                                                                    ></Column>
                                                                <Column field="country.name" header="Kraj"></Column>
                                                                <Column field="employeeDaysInCountry" header="Liczba dni" ></Column>
                                                                <Column 
                                                                    field="" 
                                                                    style={{width:"250px"}}
                                                                    header="Status"
                                                                    body={(row)=>{return <div style={{fontSize:"14px"}}
                                                                    onClick={(e)=>{
                                                                        e.stopPropagation();
                                                                        e.preventDefault();
                                                                        if(this.props.viewMode === "EDIT" && row.status === "UNSERVED" ){
                                                                          const alarms =   this.state.element?.alarms;
                                                                            const alarm =  alarms.find(el=>el.id === row.id);
                                                                            alarm.status ="SERVED";
                                                                            this.setState((prevState)=>({
                                                                                element:{
                                                                                    ...prevState.element,
                                                                                    alarms
                                                                                }

                                                                            }))
                                                                        }
                                                                      
                                                                    }}> {<AlarmStatusIconTemplateComponent  row={row} viewMode={this.props.viewMode}/>}</div>}}
                                                                ></Column>
                                                            </DataTable>

                                                        </DivContainer>
                                                    </Card>
                                                    </div>
                                                    </TabPanel>}

                                                    {this.props.viewMode !== 'NEW' ? (
                                                        <TabPanel header={t('project.details.logsTabHeader')}>
                                                            <LogEntryListContainer
                                                                objectType='EMPLOYEE'
                                                                objectId={this.props.elementId}
                                                                expandFilters={false}
                                                                renderHeader={false}
                                                            />
                                                        </TabPanel>
                                                    ) : null}


                        </TabView>
                        {this.state.showConfirmBlockDialog && this.state.element.status.enumValue === 'ACTIVE' ? (
                            <YesNoDialog
                                visible={this.state.showConfirmBlockDialog}
                                header='Zablokowanie użytkownika'
                                name='visible'
                                onChange={(type, x, target) => {
                                    if (target.value) {
                                        this.blockAccount();
                                    } else {
                                        this.hideBlockConfirmation();
                                    }
                                }}
                                onHide={() => {
                                    this.hideBlockConfirmation();
                                }}
                            >
                                Czy na pewno chcesz zablokować użytkownika?
                            </YesNoDialog>
                        ) : this.state.showConfirmUnblockDialog && this.state.element.status.enumValue !== 'ACTIVE' ? (
                            <YesNoDialog
                                visible={this.state.showConfirmUnblockDialog}
                                header='Odblokowanie użytkownika'
                                name='visible'
                                onChange={(type, x, target) => {
                                    if (target.value) {
                                        this.unblockAccount();
                                    } else {
                                        this.hideUnblockConfirmation();
                                    }
                                }}
                                onHide={() => {
                                    this.hideUnblockConfirmation();
                                }}
                            >
                                Czy na pewno chcesz odblokować użytkownika?
                            </YesNoDialog>
                        ) : null}
                    </DivContainer>
                </DivContainer>
            </React.Fragment>
        );
    }
    
    selectedItemTemplate = (option) => {
        if (option != null) {
            return (
                <div className='p-multiselect-token' style={{ background: "transparent" }}>
                    <div className="p-multiselect-token-label" >
                        <IconColorComponent componentClassName={"mt-2"} icon={option.icon.value} color={option.color.value} tootltipText={option.name} />
                    </div>
                    <div className="p-multiselect-token-icon pi pi-times-circle" onClick={(el) => {
                        const categories = this.state.element.categories.filter(c => c.id !== option.id);
                        this.setState((prevState) => ({
                            element: {
                                ...prevState.element,
                                categories: categories
                            }
                        }));
                    }
                    }>
                    </div>
                </div>
            );
        }
        return;
    };
}
EmployeeContainer.defaultProps = {
    backUrl: '#/employee-list',
    cancelUrl: '/#/employee/details',
    editUrl: '/#/employee/edit',
    viewUrl: '/#/employee/details/',
    lazy: true,
};

EmployeeContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};
export default withTranslation()(EmployeeContainer);
