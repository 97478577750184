import { Card } from 'primereact/card';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { withTranslation } from 'react-i18next';
import BaseContainer from '../baseContainers/BaseContainer';
import ActionButton from '../components/ActionButton';
import CustomDialog from '../components/CustomDialog';
import { CustomMessages } from '../components/CustomMessages';
import DivContainer from '../components/DivContainer';
import MenuItemIcon from '../components/MenuItemIcon';
import InputNumberComponent from '../components/inputs/InputNumberComponent';
import BlockUi from '../components/waitPanel/BlockUi';
import AccountService from '../services/AccountService';
import StartPageService from '../services/StartPageService';


class StartContainer extends BaseContainer {
    constructor(props) {
        super(props);
        this.accountService = new AccountService();
        this.startPageService = new StartPageService();
        this.user = this.authService.getProfile();
        this.state = {
            balance: {},
            tooltipVisibile: false,
            lastInvoiceAmount: {},
            loading: false,
            loadingBalance: true,
            loadingNewsfeed: true,
            loadingNewsfeedError: false,
            loadingUnpaidInvoices: true,
            loadingLastInvoice: true,
            blocking: false,
            list: [],
            coordinate: 0,
            unpaidInvoices: {toPayAmount: 0, count: 0},
            showTopUpDialog: false,
            topUpDialogConfirmation: false,
            topUpAmount: undefined,
            orderCardVisibile: false,
            invoiceTraditionalDialog: false,
            paymentRecipient: 'Anwim S.A. ul. Stańczyka 3, 01-237 Warszawa',
        };
        this.handleHideTooltip = this.handleHideTooltip.bind(this);
        this.handleShowTooltip = this.handleShowTooltip.bind(this);
        this.handleShowTooltipMenuItem = this.handleShowTooltipMenuItem.bind(this);
        this.hideBanner = this.hideBanner.bind(this);
        this.renderTopUpDialog = this.renderTopUpDialog.bind(this);
        this.handleOrderCardDialogOpen = this.handleOrderCardDialogOpen.bind(this);
        this.handleOrderCardDialogClose = this.handleOrderCardDialogClose.bind(this);
    }

    componentDidMount() {
        super.componentDidMount();
        const {t} = this.props;
        
    }

    handleShowTooltip() {
        this.setState({tooltipVisibile: true});
    }

    handleHideTooltip() {
        this.setState({tooltipVisibile: false});
    }

    handleShowTooltipMenuItem() {
        this.setState({tooltipVisibile: true});
    }

    hideBanner() {
        this.setState({renderBanner: false});
    }

    handleOrderCardDialogOpen() {
        this.setState({orderCardVisibile: true});
    }

    handleOrderCardDialogClose(callback) {
        this.setState({orderCardVisibile: false}, () => {
            if (callback) {
                callback();
            }
        });
    }

    renderTopUpDialog() {
        const {t} = this.props;
        return (
            <div className='row center-all'>
                <div className='col-12'>
                    {this.state.topUpDialogConfirmation ? (
                        <span className='top-up-confirmed'>{t('balance.topUpTextConfirmed')}</span>
                    ) : (
                        <span>{t('balance.topUpText')}</span>
                    )}
                </div>
                <InputNumberComponent
                    id='topUpAmount'
                    name='topUpAmount'
                    label={t('balance.amount')}
                    value={this.state.topUpAmount}
                    validator={this.validator}
                    validators='required'
                    onChange={this.handleChange}
                    viewMode='EDIT'
                    min={0}
                    max={15000}
                    minFractionDigits={0}
                    maxFractionDigits={2}
                    inputStyle={{width: '100%'}}
                    colClass='col-md-6 col-sm-12'
                    rendered={this.state.topUpDialogConfirmation}
                    placeholder='0'
                    stateField=''
                />
                <div className='col-12'></div>
                <div className='col-width-auto'>
                    <ActionButton
                        label={this.state.topUpDialogConfirmation ? t('balance.topUpButtonConfirmed') : t('balance.topUpButton')}
                        handleClick={(e) => {
                            e.preventDefault();
                            if (this.state.topUpDialogConfirmation) {
                                this.topUpAccount(this.state.topUpAmount);
                            } else {
                                this.setState({
                                    topUpDialogConfirmation: true,
                                });
                            }
                        }}
                        variant='button blue'
                    />
                </div>
            </div>
        );
    }

    render() {
        const {t} = this.props;
        return (
            <DivContainer colClass='container-fluid start-page'>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader} key='block-ui'>
                    <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} key='custom-messages' />
           
                    <CustomDialog
                        name='invoice-traditional-dialog'
                        className='confirm-dialog-width'
                        visible={this.state.invoiceTraditionalDialog}
                        viewMode={'VIEW'}
                        onHide={() => {
                            this.setState({invoiceTraditionalDialog: false});
                        }}
                        modal
                        header={t('invoice.payTraditional')}
                    >
                        <Card
                            footer={() => (
                                <div className='footer-dialog-button-container'>
                                    <ActionButton
                                        label={t('details.close')}
                                        variant='button save-button'
                                        handleClick={(e) => {
                                            e.preventDefault();
                                            this.setState({invoiceTraditionalDialog: false});
                                        }}
                                        key={'traditional-dialog-close'}
                                    />
                                </div>
                            )}
                        >
                            <div>
                                <div className='invoice-property desc'>{t('invoice.invoiceTraditional.desc')}</div>
                                <div className='invoice-property'>
                                    <span className='bold'>{`${t('invoice.invoiceTraditional.recepient')}:`}</span>
                                    <div className='invoice-property-value'>{this.state.paymentRecipient}</div>
                                </div>
                                <div className='invoice-property bank-account'>
                                    <span className='bold'>{`${t('invoice.invoiceTraditional.bankAccount')}:`}</span>
                                    <div className='invoice-property-value'>
                                        {this.user.agreementBankAccountNumber}
                                        <CopyToClipboard
                                            text={
                                                !!this.user.agreementBankAccountNumber
                                                    ? this.user.agreementBankAccountNumber.replace(/^\D+/g, '')
                                                    : ''
                                            }
                                            onCopy={() => this.showSuccessMessage(t('balance.copyBankAccountSuccess'))}
                                        >
                                            <img src='/images/ico_copy.svg' className='card-icon' alt={t('balance.copyBankAccount')} />
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </CustomDialog>
            {/*         <OrderCardDialog
                        onHide={() => this.handleOrderCardDialogClose()}
                        visible={this.state.orderCardVisibile}
                        key='order-card-dialog'
                    /> */}
                    <CustomDialog
                        key='start-top-up-dialog'
                        name='start-top-up-dialog'
                        className='start-top-up-dialog'
                        visible={this.state.showTopUpDialog}
                        viewMode={this.props.viewMode}
                        afterSave={(e) =>
                            this.handleCloseDialog(e, () => {
                                this.readMessage();
                            })
                        }
                        onHide={() => {
                            this.setState({
                                showTopUpDialog: false,
                                topUpDialogConfirmation: false,
                            });
                        }}
                        modal
                        header={<label>{t('balance.topUpAccount')}</label>}
                    >
                        {this.renderTopUpDialog()}
                    </CustomDialog>
                    <DivContainer colClass='dashboard' key='render-detials-key'>
                        {this.state.loading ? null : (
                            <form onSubmit={this.handleFormSubmit} noValidate>
                                {this.renderDetails()}
                            </form>
                        )}
                    </DivContainer>
                </BlockUi>
            </DivContainer>
        );
    }

    renderBalanceHeader() {
        const {t} = this.props;
        const balanceLink =
            this.user && this.user.agreementType === 'PREPAID'
                ? '#/balance-prepaid'
                : this.user.agreementType === 'CASHLESS'
                ? '#/balance-cashless'
                : '#/balance';
        const balanceHeaderItems = [
            {
                label: t('balance.label'),
                type: 'LABEL',
                className: 'float-left',
                iconSvg: 'ico_wallet',
            },
            {
                type: 'BUTTON',
                className: 'right-side',
                variant: 'white',
                customRenderFunction: () => (
                    <div className='button-click-area' onClick={() => (window.location.href = balanceLink)}>
                        <MenuItemIcon
                            alt=''
                            key={'ico_to_balance_details_icon-key'}
                            id='ico_to_balance_details_icon'
                            className='icon-svg balance-details-icon'
                            iconSvg={'ico_balance_details'}
                        />
                    </div>
                ),
            },
        ];
        return this.prepareHeader(balanceHeaderItems);
    }

    renderProfileHeader() {
        const {t} = this.props;
        const headerItems = [
            {
                label: t('profile.label'),
                type: 'LABEL',
                className: '',
                iconSvg: 'ico_profile',
            },
            {
                type: 'BUTTON',
                className: 'right-side',
                variant: 'white',
                customRenderFunction: () => (
                    <div className='button-click-area' onClick={() => (window.location.href = '#/profile')}>
                        <MenuItemIcon
                            alt=''
                            key={'ico_to_profile_details_icon-key'}
                            id='ico_to_profile_details_icon'
                            className='icon-svg balance-details-icon'
                            iconSvg={'ico_balance_details'}
                        />
                    </div>
                ),
            },
        ];
        return this.prepareHeader(headerItems);
    }

    renderNewsHeader() {
        const {t} = this.props;
        const headerItems = [
            {
                label: t('news.label'),
                type: 'LABEL',
                className: 'float-left',
                iconSvg: 'ico_calendar',
            },
           
        ];

        return this.prepareHeader(headerItems);
    }

    translate(key) {
        const {t} = this.props;
        return t(key);
    }

    initPayment(invoice) {
        this.blockUi();
  

    }

    topUpAccount(amount) {
        this.blockUi();

       
        this.setState({
            showTopUpDialog: false,
            topUpAmount: undefined,
            topUpDialogConfirmation: false,
        });
    }



   

    renderDetails() {
        const {t} = this.props;
        return (
            <React.Fragment>
             
                 
            </React.Fragment>
        );
    }
}

export default withTranslation()(StartContainer);
