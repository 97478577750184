import React from 'react';
import PropTypes from 'prop-types';
import {Dialog} from 'primereact/dialog';
import ActionButton from './ActionButton';
import {withTranslation} from 'react-i18next';
import {Card} from 'primereact/card';

export const YesNoDialog = (props) => {
    const {
        children,
        childrenHide,
        className,
        closable,
        header,
        id,
        key,
        name,
        noButtonClassName,
        noButtonLabel,
        noButtonVariant,
        onAfterNoStateChange,
        onAfterYesStateChange,
        onChange,
        onHide,
        onShow,
        stateField,
        visible,
        yesButtonClassName,
        yesButtonLabel,
        yesButtonVariant,
        singleButton,
    } = props;
    return (
        <Dialog
            ariaCloseIconLabel='Zamknij okno dialogowe'
            header={header}
            visible={visible}
            className={className}
            resizable={false}
            style={{width: '30vw'}}
            key={`${key ? key : id ? id : name}-dialog`}
            onHide={() => (onHide ? onHide() : null)}
            onShow={() => (onShow ? onShow() : null)}
            closable={closable}

        >
            <Card
                footer={() => {
                    return (
                        <div className='footer-dialog-button-container'>
                            <ActionButton
                                label={noButtonLabel}
                                variant={noButtonVariant}
                                className={'ml-2 ' + noButtonClassName}
                                handleClick={() =>{
                                   return onChange
                                        ? onChange('YES_NO_DIALOG', undefined, {name, value: false}, onAfterNoStateChange, stateField)
                                        : null
                                }}
                                key={`${key ? key : id ? id : name}-no-button`}
                            />
                            {!singleButton ? (
                                <ActionButton
                                    label={yesButtonLabel}
                                    variant={yesButtonVariant}
                                    className={yesButtonClassName}
                                    handleClick={() =>
                                        onChange
                                            ? onChange('YES_NO_DIALOG', undefined, {name, value: true}, onAfterYesStateChange, stateField)
                                            : null
                                    }
                                    key={`${key ? key : id ? id : name}-yes-button`}
                                />
                            ) : null}
                        </div>
                    );
                }}
            >
                {!childrenHide && (
                    <div style={{marginBottom: '20px'}} className='row col-12'>
                        {children ? children : 'Czy jesteś pewien?'}
                    </div>
                )}
            </Card>
        </Dialog>
    );
};

YesNoDialog.defaultProps = {
    noButtonVariant: 'accent',
    noButtonLabel: 'Nie',
    stateField: 'element',
    yesButtonVariant: 'dark',
    yesButtonLabel: 'Tak',
    singleButton: false,
    childrenHide: false,
    closable: true,
};

YesNoDialog.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    childrenHide: PropTypes.bool,
    className: PropTypes.string,
    closable: PropTypes.bool,
    header: PropTypes.string.isRequired,
    id: PropTypes.string,
    key: PropTypes.string,
    name: PropTypes.string.isRequired,
    noButtonClassName: PropTypes.string,
    noButtonLabel: PropTypes.string,
    noButtonVariant: PropTypes.string,
    onAfterNoStateChange: PropTypes.func,
    onAfterYesStateChange: PropTypes.func,
    onChange: PropTypes.func,
    onHide: PropTypes.func,
    onShow: PropTypes.func,
    stateField: PropTypes.string,
    visible: PropTypes.bool.isRequired,
    yesButtonClassName: PropTypes.string,
    yesButtonLabel: PropTypes.string,
    yesButtonVariant: PropTypes.string,
    singleButton: PropTypes.bool,
};

export default withTranslation()(YesNoDialog);
