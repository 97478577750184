import {Card} from 'primereact/card';
import {Dialog} from 'primereact/dialog';
//import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React from 'react';
import BaseDetailsContainer from './BaseDetailsContainer';
import BlockUi from '../components/waitPanel/BlockUi';
import { CustomMessages } from '../components/CustomMessages';

class BaseDialogContainer extends BaseDetailsContainer {
    constructor(props, service) {
        super(props);
        this.service = service;
        this.state = {closable: true};
    }

    prepareFooterItems() {
        const {viewMode} = this.props;
        switch (viewMode) {
            case 'EDIT':
            case 'NEW':
                return [
                    {label: 'Anuluj', onClick: this.props.onHide},
                    {
                        label: 'Zapisz',
                        className: 'float-right',
                        onClick: this.handleFormSubmit,
                    },
                ];
            case 'VIEW':
            default:
                return [];
        }
    }

    renderDetails() {
        return;
    }

    prepareHeaderItems() {
        return [];
    }

    onKeyDown(e) {
        if (e.key === 'Enter') {
            e.preventDefault();
            this.handleFormSubmit();
        }
    }

    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        if (this.validator.allValid()) {
            this.blockUi(this.handleValidForm);
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.scrollToError = true;
            this.forceUpdate();
        }
    }

    // blockUi(callBack, waitPanelLabel) {
    //     //this.props.blockUi(callBack, waitPanelLabel);
    //     this.setState({blocking: true});
    // }

    // unblockUi(callBack) {
    //     this.props.unblockUi(callBack);
    // }

    render() {
        const {className, id, key, name, onHide, visible, style} = this.props;
        return (
            <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <Dialog
                    ariaCloseIconLabel={this.translate('dialog.closeDialog')}
                    header={this.renderHeader()}
                    visible={visible}
                    className={className}
                    style={style}
                    key={`${key ? key : id ? id : name}-dialog`}
                    onHide={() => (onHide ? onHide() : null)}
                    closable={this.state.closable}
                >
                    {/* <div style={{ marginBottom: '20px' }} className='row col-12'>
                        {this.renderHeader()}
                    </div>					 */}
                    {this.state.loading ? null : (
                        <Card footer={this.renderFooter()}>
                            <form onSubmit={this.handleFormSubmit} noValidate onKeyDown={(e) => this.onKeyDown(e)}>
                                {this.renderDetails()}
                            </form>
                        </Card>
                    )}
                </Dialog>
            </BlockUi>
        );
    }
}

BaseDialogContainer.defaultProps = {
    currentUser: undefined,
    //embedded: true,
    viewMode: 'VIEW',
    style: {width: '30vw'},
};

BaseDialogContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    currentUser: PropTypes.object,
    editUrl: PropTypes.string,
    viewMode: PropTypes.string,
    style: PropTypes.object,
};

export default BaseDialogContainer;
