import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import ActionLink from '../components/ActionLink';
import YesNoDialog from '../components/YesNoDialog';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import SquareColorComponent from '../components/inputs/SquareColorComponent';
import CountryService from '../services/CountryService';
import CountryEditDialog from './CountryEditDialog';

class CountryListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new CountryService());
        this.state = {
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            statusOptions: [],
            roleTypeOptions: [],
            dialogVisibile: false,
            deleteConfirmDialogVisible: false,
            element: this.initNewElement(),
        };
        this.handleOpenNewDialog = this.handleOpenNewDialog.bind(this);
    }

    initNewElement(){
        return {
            name: '',                
                status: {enumValue: 'ACTIVE', label: 'Aktywny'},
            color:'',    
        }
    }

    updateSearchCriteria(criteria) {
        return {
            name: criteria.login,          
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }


    getCleanSearchCriteria() {
        return {
            name: '',            
            id: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'id',
            sortAsc: true,
        };
    }

    initializeFromBackend() {
        
    }

    getCriteriaName() {
        return 'country-list-sc';
    }

    getContainerListName() {
        return 'country-list-container';
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='name-sc'
                    name='name'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.name}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={'Nazwa'}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />                
               
              
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        return (
            <span>
            <ActionLink
                iconName='mdi-pencil'
                iconSide='left'
                iconSize='icon-large'
                handleClick={(e) => this.handleOpenEditDialog(e, rowData)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
           </span>
        );
    }

    handleOpenEditDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: this.cloneElement(rowData), dialogViewMode: 'EDIT'});
    }

    handleOpenDeleteDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({deleteConfirmDialogVisible: true, element: rowData, dialogViewMode: 'DELETE'});
    }

    prepareColumns() {
        return [
            {field: 'name', header: 'Nazwa', sortable: true},                        
            {field: 'code', header: 'Kod kraju', sortable: true},   
            {field: 'color.value', header: 'Kolor',  body: this.squareComponent},                        
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];

    }
    squareComponent(rowData){
        return <SquareColorComponent value={rowData.color.value} />
    }

    prepareHeaderItems() {
        return this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO')
            ? [
                
              ]
            : [];
    }
    handleOpenNewDialog(e) {
        let basicElement = this.initNewElement();       
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW'});
    }

    handleDelete(e){
        if (e) {
            e.preventDefault();
        }
        console.log('delete {}', this.state.element)              
        this.service
            .remove(this.state.element)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('country.deleteSuccess'));
            })
            .catch((err) => {
                this.persistMessage('error', '', t('country.deleteError'));               
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({deleteConfirmDialogVisible: false});
            });
        
    }

    afterObjectUpdate() {       
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    render() {
        return (
            <div className='container-fluid country-list'>
                <BreadcrumbsItem to='/country-list'>{'Kraje'}</BreadcrumbsItem>
                {this.renderView()}
                {this.state.dialogVisibile ? (
                    <CountryEditDialog
                        className='country-dialog'
                        key={`country-dialog-${this.state.element?.id}`}
                        element={this.state.element}
                        onHide={(e) => this.setState({dialogVisibile: false})}
                        visible={this.state.dialogVisibile}
                        viewMode={this.state.dialogViewMode}                        
                        afterSave={(e) => {
                            this.setState({dialogVisibile: false});
                           this.afterObjectUpdate();
                        }}
                        blockUi={this.blockUi}
                        unblockUi={this.unblockUi}
                        _isMounted={this._isMounted}
                    />
                ) : null}
                {this.state.deleteConfirmDialogVisible ? (
                <YesNoDialog
                    visible={this.state.deleteConfirmDialogVisible}
                    header={t('country.deleteHeader')}
                    name='visible'
                    onChange={(type, x, target) => {
                        if (target.value) {
                        
                            this.handleDelete();
                        } else {
                            this.setState({deleteConfirmDialogVisible: false})
                        }
                    }}
                    onHide={() => this.setState({deleteConfirmDialogVisible: false})}
                     >
                        {t('details.deleteConfirmation')+'\n' + this.state.element.name}
                    </YesNoDialog> 
                ) : null}
            </div>
        );
    }
}

CountryListContainer.defaultProps = {
    detailUrl: '/#/country/details',
    newUrl: '/#/country/create',
    lazy: true,
};

CountryListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default CountryListContainer;
