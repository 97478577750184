import { t } from 'i18next';
import { Chip } from 'primereact/chip';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import PropTypes from 'prop-types';
import { default as React } from 'react';
import '../../assets/css/dataview-acom.scss';
import BaseListContainer from '../../baseContainers/BaseListContainer';
import ActionLink from '../../components/ActionLink';
import { CustomMessages } from '../../components/CustomMessages';
import DivContainer from '../../components/DivContainer';
import CriteriaMultiSelectComponent from '../../components/criteria/CriteriaMultiSelectComponent';
import CriteriaTextComponent from '../../components/criteria/CriteriaTextComponent';
import BlockUi from '../../components/waitPanel/BlockUi';
import ProjectService from '../../services/ProjectService';
import AccommodationDashboardTeilComponent from './AccommodationDashboardTeilComponent';
import CriteriaAutocompleteComponent from '../../components/criteria/CriteriaAutocompleteComponent';
import CountryService from '../../services/CountryService';
import AccommodationCategoryService from '../../services/AccommodationCategoryService';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import CriteriaDropdownComponent from '../../components/criteria/CriteriaDropdownComponent';
import { Dropdown } from 'primereact/dropdown';
import ActionButton from '../../components/ActionButton';
import AccommodationDashboardService from '../../services/AccommodationDashboardService';

class AccommodationDashboardContainer extends BaseListContainer {
    constructor(props) {
        super(props, new AccommodationDashboardService());
        this.state = {
            list: [],
            loading: false,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            criteriaTemplate: undefined,
            categories: [],
            layout: 'grid',
            employeesAssignedOptions: [
                {
                    label: 'Tak',
                    value: 'true'
                },
                {
                    label: 'Nie',
                    value: 'false'
                },
            ],
        };
        this.itemTemplate = this.itemTemplate.bind(this);
        this.accommodationCategoryService = new AccommodationCategoryService();
        this.projectService = new ProjectService();
        this.countryService = new CountryService();
    }

    updateSearchCriteria(criteria) {
        return {
            name: criteria.name,
            categories: criteria.categories,
            countries: criteria.countries,
            project: criteria.project,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
            employeesAssigned: criteria.employeesAssigned
        };
    }

    getCleanSearchCriteria() {
        return {
            id: null,
            name: '',
            categories: undefined,
            countries: undefined,
            project: undefined,
            firstResult: 0,
            maxResults: 10,
            sortField: 'name',
            sortAsc: false,
            employeesAssigned: undefined
        };
    }

    initBeforeSetElement() {
        this.service
            .getAccommodationDashboard()
            .then((accommodations) => {
                this.setState({
                    accommodations,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy noclegów dla tablicy');
            });

        this.service
            .getProjects()
            .then((projects) => {
                this.setState({
                    projects,
                });
            }).catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy projektów dla tablicy');
            });
    }

    renderBackLink() {
        return null;
    }

    renderFullscreenButton() {
        let {fullscreenUrl} = this.props;
        const href = `${fullscreenUrl}`;
        return (
            <ActionButton
                className='pdf-download-button'
                handleClick={this.handleGoToFullScreenView.bind(this, href)}
                iconName='mdi-fullscreen'
            />
        );
    }
    handleGoToFullScreenView(href, e) {
        const {criteria} = this.state;
        e.preventDefault();
        criteria.maxResults = 100;
        let criteriaToPath = this.service.getCriteriaPath(criteria);
        this.service.setCriteria(this.getCriteriaName(), JSON.stringify(criteria));
        window.location.href = href + criteriaToPath;
    }


    getList() {
        const { criteria } = this.state;    
        this.setState(
            {
                loading: true,
            },
            () => {
                this.service
                    .getAccommodationDashboard(criteria)
                    .then((data) => {
                        if (data.totalElements === 0){
                            this.showInfoMessage('Brak rekordów wyszukiwania');
                        }
                        if (this._isMounted) {
                            this.setState(
                                {
                                    projects: this.updateListElements(data.content),
                                    loading: false,
                                    size: data.totalElements,
                                },
                                () => {
                                    //this.onAfterGetList(data);
                                    //this.setTimeout(() => activeElement?.focus(), 50);
                                }
                            );
                        }
                    })
                    .catch((err) => {
                        if (this._isMounted) {
                            this.setState(
                                {
                                    list: [],
                                    loading: false,
                                    size: 0,
                                },
                                () => {
                                    //this.afterGetListError();
                                }
                            );
                        }
                    });
            }
        );
    }

    initializeFromBackend() {
        this.accommodationCategoryService
            .getList({ status: { enumValue: 'ACTIVE' }, firstResult: 0, maxResults: 1000, sortField: 'id', sortAsc: true })
            .then((data) => {
                this.setState({
                    categories: data.content
                });
            }).catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kategorii dla tablicy');
            });
        this.countryService.getAll()
            .then((countries) => {
                this.setState({
                    countries
                })
            }).catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy krajów dla tablicy');
            })
    }

    getCriteriaName() {
        return 'project-dashboard-sc';
    }

    getContainerListName() {
        return 'project-dashboard-list-container';
    }
    getPdfDownloadLabel() {}

    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        const {detailUrl} = this.props;
        const href = `${detailUrl}/${rowData?.id}`;
        return (
            <ActionLink
                iconName='mdi-eye'
                iconSide='left'
                iconSize='icon-large'
                handleClick={this.handleGoToDetail.bind(this, href)}
                variant='blue'
                className='edit-blue-link'
                key={'view-button'}
            />
        );
    }

    categoriesTemplate(rowData) {
        if (rowData?.categories) {
            return (
                rowData.categories.map(c => {
                    return (
                        <Chip style={{backgroundColor:c?.color?.value, color:"black", fontSize: '10px'}} label={c.name} />
                    )
                })
            )
        } else {
            return '';
        }
    }

    getHeader(data) {
        let headerText = data?.name;
        const addressModel = data.address;
        let city = addressModel?.postalCode + ' ' + addressModel?.city;
        let street = addressModel?.streetName + ' ' + addressModel?.streetNumber;
        if (addressModel?.houseNumber != null && addressModel?.houseNumber.length > 0) {
            street += '/' + addressModel?.houseNumber;
        }
        const country = data?.country;
        const accommodationAddress = data.name + '\n ' +
            street + '\n ' +
            city + '\n ' +
            country?.name + '\n'

        return (
            <div style={{ marginLeft: '20px' }}>
                <div className='row'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <i
                            className='mdi mdi-home-city product-category-icon'
                            data-pr-tooltip={accommodationAddress}
                            data-pr-position="top"
                            id="icon-tooltip"
                        ></i>
                        <span
                            style={{ marginRight: '20px', fontSize: '12px', fontWeight: '600' }}
                        >
                            {headerText}
                        </span>
                        <div>
                            <i
                                className='copy mdi mdi-content-copy'
                                onClick={() => {
                                    navigator.clipboard.writeText(accommodationAddress);
                                    this.showInfoMessage('Skopiowano adres i nazwę noclegu do schowka')
                                }}
                                                            ></i>
                        </div>
                        <Tooltip target=".copy" />
                        <span
                            style={{ marginLeft: 'auto', fontSize: '12px', fontWeight: '600' }}
                        >
                            {data?.dateEnd}
                        </span>
                        <Tooltip target="#icon-tooltip" />
                    </div>
                    {
                        <div>
                            {data?.phoneNumber ? (
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <i className='mdi mdi-phone phone-icon'></i>
                                    <span style={{ fontSize: '12px', fontWeight: '600', marginRight: '8px' }}>
                                        {data.phoneNumber}
                                    </span>
                                    <Tag
                                        value={data.country.code !== null && data.country.code !== "" ? data.country.code : data.country.name}
                                        style={{
                                            backgroundColor: data.country.color,
                                            fontWeight: '600',
                                            color: 'black',
                                            fontSize: 'small',
                                            marginLeft: 'auto'
                                        }}
                                    />
                                </div>
                            ) : null}
                            {data.categories.map(c => {
                                return (
                                    <Chip style={{ backgroundColor: c?.color?.value, color: "black", fontSize: '10px', marginLeft: '10px', marginRight: '0px' }} label={c.name} />
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
        );
    }

    renderGridItem(data) {
        return (
            <div className="'col-xl-4 col-lg-4 col-md-4 col-sm-4'">
                <div className='product-grid-item'>
                    {this.getHeader(data)}
                    <div className='product-grid-item-content'>
                        {/* LISTA  */}
                        <AccommodationDashboardTeilComponent
                            element={data}
                            projects={data.projects}
                            employees={data.employees}
                            viewMode={'VIEW'} rendered={true}
                            additional={this.state.absences?.filter(a => a.projectId === data.id)}>
                        </AccommodationDashboardTeilComponent>
                    </div>
                </div>
            </div>
        );
    }

    itemTemplate(product) {
        if (!product) {
            return;
        }
        return this.renderGridItem(product);
    }

    renderHeader() {
        return (
            <div className='grid grid-nogutter'>
                <div className='col-6' style={{textAlign: 'right'}}></div>
                <div className='row' style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                    {
                        <div className='col-12' style={{textAlign: 'right', fontWeight: '600'}}>
                            {this.renderFullscreenButton()}
                        </div>
                    }
                </div>
            </div>
        );
    }

    handlePage(event) {
        if (this._isMounted) {
            this.setState(
                (prevState) => ({
                    loading: this.props.lazy,
                    first: event.first,
                    criteria: {
                        ...prevState.criteria,
                        firstResult: event.first,
                        maxResults: event.rows,
                        maxResultsObj: {value: event.rows},
                    },
                }),
                () => (this.props.lazy ? this.refreshTable(false) : null)
            );
        }
    }

    render() {
        const header = this.renderHeader();
        return (
            <div className='container-fluid'>        
            {this.renderView()} 
            <div className='dataview-demo2'>
                <div className='card-m'>
                    <DataView
                        value={this.state.projects}
                        layout={this.state.layout}
                        header={header}
                        itemTemplate={this.itemTemplate}
                        paginator
                        emptyMessage={t('list.empty')}
                        lazy
                        totalRecords={this.state.size} 
                        first={this.state.criteria.firstResult}
                        rows={9}
                        sortOrder={this.state.sortOrder}
                        sortField={this.state.sortField}
                        onPage={this.handlePage}
                    />
                </div>
            </div>               
        </div>
        );
    }

    handleDelete(e){
        if (e) {
            e.preventDefault();
        }                 
    }
    searchProjects(query) {
        return this.projectService.search(query);
    }

    renderCriteria() {
        return (
            <div>
                <div className='row'>
                    <CriteriaMultiSelectComponent
                        id='categories'
                        name='categories'
                        label={t('project.categories')}
                        colClass='col-md-4'
                        onChange={this.handleChangeSc}
                        options={this.state.categories}
                        optionLabel='name'
                        value={this.state.criteria.categories}
                        dataKey='id'
                        refreshFromBackend
                        timeoutHandler={this.refreshTable}
                    />
                    <CriteriaTextComponent
                        id='name-sc'
                        name='name'
                        colClass='col-lg-3 col-sm-6'
                        value={this.state.criteria.name}
                        onChange={this.handleChangeSc}
                        validator={this.validator}
                        label={t('project.name')}
                        global={true}
                        refreshFromBackend
                        timeoutHandler={this.refreshTable}
                    />
                    <CriteriaMultiSelectComponent
                        id='countries'
                        name='countries'
                        label={t('project.country')}
                        colClass='col-md-4'
                        onChange={this.handleChangeSc}
                        options={this.state.countries}
                        optionLabel='name'
                        value={this.state.criteria.countries}
                        dataKey='id'
                        refreshFromBackend
                        timeoutHandler={this.refreshTable}
                        filter
                        filterBy='name'
                    />
                    <CriteriaAutocompleteComponent
                        id='project-sc'
                        name='project'
                        colClass='col-lg-4 col-sm-6'
                        label={'Projekt'}
                        value={this.state.criteria.project}
                        completeMethod={this.searchProjects.bind(this)}
                        onChange={this.handleChangeSc}
                        refreshFromBackend
                        timeoutHandler={(e) => {
                            //if (this.state.criteria.project.length >= 3) {
                                this.refreshTable();
                            //
                        }
                        }
                        
                    />

                 <CriteriaDropdownComponent
                    id='employeesAssigned-sc'
                    name='employeesAssigned'
                    showClear
                    label={'Zajęty'}
                    colClass='col-lg-2 col-sm-2'
                    value={this.state.criteria.employeesAssigned}
                    options={this.state.employeesAssignedOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    refreshFromBackend
                    timeoutHandler={(e)=>
                    this.refreshTable()
                    }
                />
                </div>
            </div>
        );
    }

    renderView() {
        return (
            <DivContainer colClass='details-main'>
                <CustomMessages id='custom-messages' ref={(el) => (this.messages = el)} />
                <BlockUi
                    tag='div'
                    blocking={this.state.blocking || this.state.loading}
                    loader={this.loader}
                >
                    <DivContainer colClass='row'> {this.renderFilter()}</DivContainer>
                    <DivContainer colClass='col-12 details-main-link-container'>
                        <DivContainer colClass='row'></DivContainer>
                    </DivContainer>
                </BlockUi>
            </DivContainer>
        );
    }
}

AccommodationDashboardContainer.defaultProps = {
    fullscreenUrl: '/#/acommodation-dashboard-fullscreen',
    lazy: true,
};

AccommodationDashboardContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
};

export default AccommodationDashboardContainer;
