import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import {t} from 'i18next';
import ActionLink from '../components/ActionLink';
import {PrintButtonComponent} from '../report/ReportUtils';
import AfterHoursDriveAlarmService from '../services/AfterHoursDriveAlarmService';
import CriteriaDropdownComponent from '../components/criteria/CriteriaDropdownComponent';
import {InputTextarea} from 'primereact/inputtextarea';
import YesNoDialog from '../components/YesNoDialog';
import AlarmStatusIconTemplateComponent from '../components/AlarmStatusIconTemplateComponent';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Dialog} from 'primereact/dialog';
import StringUtils from '../utils/StringUtils';

class AfterHoursDriveAlarmListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new AfterHoursDriveAlarmService());
        this.state = {
            list: [],
            trips: [],
            loading: true,
            size: 0,
            first: 0,
            alarmToChangeStatus: undefined,
            currentDescForAlarm: undefined,
            showAlarmDetails: false,
            showServedAlarmDialog: false,
            statusOptions: [],
            criteria: this.getCleanSearchCriteria(),
            dialogVisibile: false,
        };
    }

    updateSearchCriteria(criteria) {
        return {
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            dayReported: criteria.dayReported,
            carName: criteria.carName,
            day: criteria.day,
            status: criteria.status,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc,
        };
    }

    getCleanSearchCriteria() {
        return {
            dayReported: null,
            carName: null,
            day: new Date(),
            status: null,
            firstResult: 0,
            maxResults: 10,
            sortField: 'day',
            sortAsc: false,
            dateFrom: undefined,
            dateTo: undefined,
        };
    }

    getCriteriaName() {
        return 'afterHoursDriveAlarm-list-sc';
    }

    getContainerListName() {
        return 'afterHoursDriveAlarm-list-container';
    }

    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='projectName-sc'
                    name='carName'
                    colClass='col-lg-3 col-sm-6'
                    value={this.state.criteria.carName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('global.carFullName')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaDropdownComponent
                    id='status-sc'
                    name='status'
                    showClear
                    label={t('afterHoursDriveAlarm.status')}
                    colClass='col-lg-3 col-sm-6'
                    value={this.state.criteria.status}
                    options={this.state.statusOptions}
                    placeholder='Wszystkie'
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    optionLabel='label'
                    filterOptionValue='label'
                    refreshFromBackend
                />
                <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    label={t('project.date')}
                    labelFrom={'od'}
                    labelTo={'do'}
                    name='date'
                    sharedLabel={false}
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    colClass='col-xl-6 col-lg-6 col-sm-12'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    showButtonBar
                    maxDateInfinite
                    placeholderFrom={''}
                    placeholderTo={''}
                    inputsClassName='col-6'
                />
            </div>
        );
    }

    actionTemplate(rowData) {
        return (
            <span>
                {' '}
                <ActionLink
                    iconName='mdi-eye'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={() => {
                        this.service
                            .getTrips(rowData.id)
                            .then((res) => {
                                this.setState({
                                    trips: res,
                                    showAlarmDetails: true,
                                });
                            })
                            .catch(() => {
                                this.showErrorMessage('Nie można pobrać tras');
                            });
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                />
            </span>
        );
    }
    initializeFromBackend() {
        this.enumService
            .getEnumList('AlarmStatus')
            .then((statusOptions) => {
                statusOptions = statusOptions.filter((s) => s.enumValue !== 'OK');
                this.setState({
                    statusOptions,
                });
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy statusów');
            });
    }
    prepareColumns() {
        return [
            {field: 'day', header: t('global.day'), sortable: true, sortField: 'day'},
            {field: 'car.fullName', header: t('global.carFullName')},
            {
                field: 'dayReported',
                header: t('afterHoursDriveAlarm.dayReported'),
                width: '220px',
                sortable: true,
                body: (row) => {
                    if (row.dayReported) {
                        return <div>Tak</div>;
                    }
                    return <div>Nie</div>;
                },
            },
            {
                field: 'projects',
                width: '300px',
                header: t('global.projects'),
                body: (row) => {
                    let projects = '';
                    row.projects.forEach((project) => {
                        if (StringUtils.isEmpty(projects)) {
                            projects = project.name;
                        } else {
                            projects = ', ' + project.name;
                        }
                    });
                    return <span> {projects}</span>;
                },
            },
            {field: 'description', width: '300px', header: t('global.comment'), sortable: false},
            {
                field: 'status',
                header: t('global.status'),
                body: (row) => (
                    <div onClick={(e) => {}}>
                        {
                            <AlarmStatusIconTemplateComponent
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    this.setState({
                                        alarmToChangeStatus: structuredClone(row),
                                        showServedAlarmDialog: true,
                                    });
                                }}
                                row={row}
                                viewMode={'EDIT'}
                            />
                        }
                    </div>
                ),
                sortable: false,
            },
            {
                key: 'actions',
                header: t('list.actions'),
                body: this.actionTemplate,
                className: 'table-column-action',
            },
        ];
    }
    prepareHeaderItems() {
        return [
            {
                type: 'BUTTON',
                className: 'right-side',
                variant: 'white',
                disabled: !this.state.list || this.state.list.length < 1,
                customRenderFunction: this.renderPrintButton,
            },
        ];
    }

    renderPrintButton() {
        return (
            <PrintButtonComponent
                key={`print-button-after-hours-drive-report`}
                reportFileNameBase={t('afterHoursDriveAlarm.listName')}
                xml={false}
                pdf={false}
                csv={false}
                ods={false}
                xlsx={false}
                globalFormat='xlsx'
                noMenuFlag={true}
                generateReport={this.generateReport.bind(this)}
                disabled={!this.state.list.length > 0}
            />
        );
    }
    generateReport = (format) => {
        const criteria = this.state.criteria;
        switch (format) {
            case 'xlsx':
                return this.service.exportList(criteria, 'xlsx');
            default:
                return Promise.reject('Nieznany format');
        }
    };
    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

    render() {
        return (
            <div className='container-fluid afterHoursDriveAlarm-list'>
                <BreadcrumbsItem to='/afterHoursDriveAlarm-list'>{'Jazdy poza godzinami pracy'}</BreadcrumbsItem>
                {this.state.showServedAlarmDialog && (
                    <YesNoDialog
                        singleButton
                        header='Komentarz dla alarmu'
                        noButtonLabel={this.state.alarmToChangeStatus.status === 'UNSERVED' ? 'Obsłuż' : 'Oznacz jako nieobsłużony'}
                        onHide={() => {
                            this.setState({showServedAlarmDialog: false});
                        }}
                        onChange={() => {
                            this.blockUi();
                            this.service
                                .changeStatus(this.state.alarmToChangeStatus)
                                .then(() => {
                                    const successMessage =
                                        this.state.alarmToChangeStatus.status === 'UNSERVED'
                                            ? 'Obsłużono alarm'
                                            : 'Zatwierdzono zmiany alarmu';
                                    this.showSuccessMessage(successMessage);
                                    this.afterObjectUpdate();
                                    this.setState({showServedAlarmDialog: false});
                                })
                                .catch(() => {
                                    this.unblockUi();
                                    this.showErrorMessage('Nie udało się obsłużyć alarmu');
                                    this.afterObjectUpdate();
                                });
                        }}
                        visible={this.state.showServedAlarmDialog}
                    >
                        <div className='container'>
                            <InputTextarea
                                value={this.state.alarmToChangeStatus?.description}
                                className='col-12 ml-2 mt-2'
                                onChange={(e) => {
                                    const val = e.currentTarget.value;
                                    const alarmToChangeStatus = this.state.alarmToChangeStatus;
                                    alarmToChangeStatus.description = val;
                                    this.setState(() => ({
                                        alarmToChangeStatus,
                                    }));
                                }}
                            />
                        </div>
                    </YesNoDialog>
                )}
                {this.state.showAlarmDetails && (
                    <Dialog
                        singleButton
                        header='Szczegóły alarmu'
                        noButtonLabel={'Zamknij'}
                        onHide={() => {
                            this.setState({showAlarmDetails: false});
                        }}
                        onChange={() => {
                            this.setState({showAlarmDetails: false});
                        }}
                        visible={this.state.showAlarmDetails}
                    >
                        <div className='container'>
                            <DataTable
                                id='trips'
                                paginator
                                rows={5}
                                value={this.state.trips}
                                rowsPerPageOptions={[5, 10, 25]}
                                responsiveLayout='scroll'
                                emptyMessage={t('list.empty')}
                            >
                                <Column field='startTime' header='Godzina rozpoczęcia'></Column>
                                <Column field='endTime' header='Godzina zakończenia'></Column>
                                <Column field='drivingTime' header='Czas jazdy'></Column>
                                <Column
                                    field='distanceInKilometers'
                                    header='Dystans (km)'
                                    headerStyle={{textAlign: 'right'}}
                                    bodyStyle={{textAlign: 'right'}}
                                ></Column>
                                <Column field='driver' header='Kierowca'></Column>

                                <Column field='startLocation' style={{width: '200px'}} header='Miejsce rozpoczęcia'></Column>
                                <Column field='endLocation' style={{width: '200px'}} width='100px' header='Miejsce zakończenia'></Column>
                            </DataTable>
                        </div>
                    </Dialog>
                )}
                {this.renderView()}
            </div>
        );
    }
}

AfterHoursDriveAlarmListContainer.defaultProps = {
    detailUrl: '/#/hourly-report/details',
    newUrl: '/#/hourly-report/create',
    lazy: true,
    renderHeader: true,
};

AfterHoursDriveAlarmListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
    renderHeader: PropTypes.bool,
};

export default AfterHoursDriveAlarmListContainer;
