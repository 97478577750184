/* eslint-disable no-useless-escape */
/* eslint-disable max-len */
import moment from 'moment';
import React from 'react';

class SimpleReactValidator {

    addCustomRule(name, message, validationFunction, messageReplace,required) {
        this.rules[name] = {
            message: message,
            rule: validationFunction,
            messageReplace,
            required: required
        }
    }
    constructor(options = {}, t) {
        this.fields = {};
        this.errorMessages = {};
        this.messagesShown = false;
        this.translate = t;
        this.rules = {
            accepted: {
                message: t('validator.accepted'),
                rule: (val) => val === true,
                required: true,
            },
            not_valid: {
                message: t('c'),
                rule: (val) => false,
                
            },
            valid: {
                message: t('c'),
                rule: (val) => true,
                
            },
            after: {
                message: t('validator.after'),
                rule: (val, params) =>
                    this.helpers.momentInstalled() && moment.isMoment(moment(val)) && moment(val).isAfter(params[0], 'day'),
                messageReplace: (message, params) => message.replace(':date', moment(params[0]).format(params[1])),
            },
            after_or_equal: {
                message: t('validator.after_or_equal'),
                rule: (val, params) =>
                    this.helpers.momentInstalled() && moment.isMoment(moment(val)) && moment(val).isSameOrAfter(params[0], 'day'),
                messageReplace: (message, params) => message.replace(':date', moment(params[0]).format(params[1])),
            },
            after_or_equal_time: {
                message: t('validator.after_or_equal_time'),
                rule: (val, params) => {
                    const currentTime = moment(val, 'HH:mm');
                    const comparisonTime = moment(params[0], 'HH:mm');
                    return currentTime.isSameOrAfter(comparisonTime, 'minute');
                },
                messageReplace: (message, params) => message.replace(':date', params[0]),
            },                     
            before_or_equal_time: {
                message: t('validator.before_or_equal_time'),
                rule: (val, params) => {
                    const currentTime = moment(val, 'HH:mm:ss');
                    const comparisonTime = moment(params[0], 'HH:mm');
                    return currentTime.isSameOrBefore(comparisonTime, 'minute');
                },
                messageReplace: (message, params) => message.replace(':date', params[0]),
            },            
            alpha: {
                message: t('validator.alpha'),
                rule: (val) => this.helpers.testRegex(val, /^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż]*$/i),
            },
            alpha_space: {
                message: t('validator.alpha_space'),
                rule: (val) => this.helpers.testRegex(val, /^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż\s]*$/i),
            },
            alpha_space_dash: {
                message: t('validator.alpha_space_dash'),
                rule: (val) => this.helpers.testRegex(val, /^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż\s-]*$/i),
            },
            alpha_num: {
                message: t('validator.alpha_num'),
                rule: (val) => this.helpers.testRegex(val, /^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż0-9]*$/i),
            },
            alpha_num_space: {
                message: t('validator.alpha_num_space'),
                rule: (val) => this.helpers.testRegex(val, /^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż0-9\s]*$/i),
            },
            alpha_num_dash: {
                message: t('validator.alpha_num_dash'),
                rule: (val) => this.helpers.testRegex(val, /^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż0-9_-]*$/i),
            },
            alpha_num_dash_space: {
                message: t('validator.alpha_num_dash_space'),
                rule: (val) => this.helpers.testRegex(val, /^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż0-9\_\-\,\.\s]*$/i),
            },
            array: { message: t('validator.array'), rule: (val) => Array.isArray(val) },
            array_required: {
                message: t('validator.array_required'),
                rule: (val) => Array.isArray(val) && val.length > 0,
            },
            multicheckbox_one_element_required: {
                message: t('validator.multicheckbox_one_element_required'),
                rule: (val) => Array.isArray(val) && val.length > 0,
            },
            before: {
                message: t('validator.before'),
                rule: (val, params) =>
                    this.helpers.momentInstalled() && moment.isMoment(moment(val)) && moment(val).isBefore(params[0], 'day'),
                messageReplace: (message, params) => message.replace(':date', moment(params[0]).format(params[1])),
            },
            before_or_equal: {
                message: t('validator.before_or_equal'),
                rule: (val, params) =>
                    this.helpers.momentInstalled() && moment.isMoment(moment(val)) && moment(val).isSameOrBefore(params[0], 'day'),
                messageReplace: (message, params) => message.replace(':date', moment(params[0]).format(params[1])),
            },
            between: {
                message: t('validator.between'),
                rule: (val, params) =>
                    this.helpers.size(val, params[2]) >= parseFloat(params[0]) &&
                    this.helpers.size(val, params[2]) <= parseFloat(params[1]),
                messageReplace: (message, params) =>
                    message.replace(':min', params[0]).replace(':max', params[1]).replace(':type', this.helpers.sizeText(params[2])),
            },
            boolean: {
                message: t('validator.boolean'),
                rule: (val) => val === false || val === true,
            },
            card_exp: {
                message: t('validator.card_exp'),
                rule: (val) => this.helpers.testRegex(val, /^(([0]?[1-9]{1})|([1]{1}[0-2]{1}))\s?\/\s?(\d{2}|\d{4})$/),
            },
            card_num: {
                message: t('validator.card_num'),
                rule: (val) => this.helpers.testRegex(val, /^\d{4}\s?\d{4,6}\s?\d{4,5}\s?\d{0,8}$/),
            },
            currency: {
                message: t('validator.currency'),
                rule: (val) => this.helpers.testRegex(val, /^\$?(\d{1,3})(\,?\d{3})*\.?\d{0,2}$/),
            },
            date: {
                message: t('validator.date'),
                rule: (val) => this.helpers.momentInstalled() && moment(val).isValid() && moment.isMoment(moment(val)),
            },
            date_format: {
                message: t('validator.date_format'),
                rule: (val, params) =>
                    this.helpers.momentInstalled() && moment(val, params[0], true).isValid() && moment.isMoment(moment(val)),
                messageReplace: (message, params) => message.replace(':dateFormat', params[1]),
            },
            date_equals: {
                message: t('validator.date_equals'),
                rule: (val, params) =>
                    this.helpers.momentInstalled() && moment.isMoment(moment(val)) && moment(val).isSame(params[0], 'day'),
                messageReplace: (message, params) => message.replace(':date', moment(params[0]).format(params[1])),
            },
            email: {
                message: t('validator.email'),
                rule: (val) => this.helpers.testRegex(val, /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i),
            },
            email_domain: {
                message: t('validator.emailParam'),
                rule: (val, params) => {
                    if(!val.endsWith(params[0])){
                        return false;
                    }
                    this.helpers.testRegex(val, /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
                },
                messageReplace: (message, params) => message.replace(':domain', params[0]),
            },
            equals: {
                message: t('validator.equals'),
                rule: (val, params) => val === params[0],
                messageReplace: (message, params) => message.replace(':fieldName', params[1]),
            },
            in: {
                message: t('validator.in'),
                rule: (val, params) => params.indexOf(val) > -1,
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
            },
            integer: {
                message: t('validator.integer'),
                rule: (val) => this.helpers.testRegex(val, /^\d*$/),
            },
            equalsDigits: {
                message: t('validator.equalsDigits'),
                rule: (val, params) => this.helpers.sizeDigits(val) === parseFloat(params[0]),
                messageReplace: (message, params) => message.replace(':max', params[0]).replace(':type', this.helpers.sizeText(params[1])),
            },
            max: {
                message: t('validator.max'),
                rule: (val, params) => this.helpers.size(val, params[1]) <= parseFloat(params[0]),
                messageReplace: (message, params) => message.replace(':max', params[0]).replace(':type', this.helpers.sizeText(params[1])),
            },
            min: {
                message: t('validator.min'),
                rule: (val, params) => this.helpers.size(val, params[1]) >= parseFloat(params[0]),
                messageReplace: (message, params) => message.replace(':min', params[0]).replace(':type', this.helpers.sizeText(params[1])),
            },
            nip: {
                message: t('validator.nip'),
                rule: (val) => this.helpers.validateNip(val),
            },
            not_in: {
                message: t('validator.not_in'),
                rule: (val, params) => params.indexOf(val) === -1,
                messageReplace: (message, params) => message.replace(':values', this.helpers.toSentence(params)),
            },
            not_regex: {
                message: t('validator.not_regex'),
                rule: (val, params) =>
                    !this.helpers.testRegex(
                        val,
                        typeof params[0] === 'string' || params[0] instanceof String ? new RegExp(params[0]) : params[0]
                    ),
            },
            numeric: {
                message: t('validator.numeric'),
                rule: (val) => this.helpers.numeric(val),
            },
            pesel: {
                message: t('validator.pesel'),
                rule: (val) => this.helpers.isPeselValid(val),
            },
            pesel_or_initial_value: {
                message: t('validator.pesel_or_initial_value'),
                rule: (val, params) => this.helpers.isPeselValidOrInitialValue(val, params[0]),
            },
            phone: {
                message: t('validator.phone'),
                rule: (val) => this.helpers.testRegex(val, /^\+?[1-9][ 0-9]{7,14}$/),
            },
            password: {
                message: t('validator.password'),
                rule: (val, params) =>
                    this.helpers.testRegex(
                        val,
                        new RegExp(
                            `^(?=.*\\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*(){}[\\\]|:";'<>?,.\\\/])[a-zA-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż0-9!@#$%^&*(){}[\\\]|:";'<>?,.\\\/]{${params[0]},}$`
                        )
                    ),
                messageReplace: (message, params) => message.replace(':min', params[0]),
            },
            regex: {
                message: t('validator.regex'),
                rule: (val, params) =>
                    this.helpers.testRegex(
                        val,
                        typeof params[0] === 'string' || params[0] instanceof String ? new RegExp(params[0]) : params[0]
                    ),
            },
            street: {
                message: t('validator.street'),
                rule: (val, params) =>
                    this.helpers.testRegex(
                        val,
                        typeof params[0] === 'string' || params[0] instanceof String ? new RegExp(params[0]) : params[0]
                    ),
            },
            road: {
                message: t('validator.road'),
                rule: (val, params) =>
                    this.helpers.testRegex(
                        val,
                        typeof params[0] === 'string' || params[0] instanceof String ? new RegExp(params[0]) : params[0]
                    ),
            },
            regon: {
                message: t('validator.regon'),
                rule: (val) => this.helpers.validateRegon(val),
            },
            zipcode: {
                message: t('validator.zipcode'),
                rule: (val) => this.helpers.validateZipcode(val),
            },
            required: {
                message: t('validator.required'),
                rule: (val) => !this.helpers.isBlank(val),
                required: true,
            },            
            not_required: {
                message: t('validator.not_required'),
                rule: () => true,
                required: true,
            },
            size: {
                message: t('validator.size'),
                rule: (val, params) => this.helpers.size(val, params[1]) === parseFloat(params[0]),
                messageReplace: (message, params) => message.replace(':size', params[0]).replace(':type', this.helpers.sizeText(params[1])),
            },
            time: {
                message: t('validator.time'),
                rule: (val) => this.helpers.testRegex(val, /^(?:2[0-3]|[01][0-9]):[0-5][0-9]$/),
                required: true,
            },

            number_max: {
                message: t('validator.number_max'),
                rule: (val, params) => this.helpers.number_max(val, params),
                messageReplace: (message, params) => message.replace(':size', params[0]),
                required: true,
            },
            number_min: {
                message: t('validator.number_min'),
                rule: (val, params) => this.helpers.number_min(val, params),
                messageReplace: (message, params) => message.replace(':size', params[0]),
                required: true,
            },

            number_max_float: {
                message: t('validator.number_max'),
                rule: (val, params) => this.helpers.number_max_float(val, params),
                messageReplace: (message, params) => message.replace(':size', params[0]),
                required: true,
            },
            number_min_float: {
                message: t('validator.number_min'),
                rule: (val, params) => this.helpers.number_min_float(val, params),
                messageReplace: (message, params) => message.replace(':size', params[0]),
                required: true,
            },

            string: {
                message: t('validator.string'),
                rule: (val) => typeof val === typeof 'string',
            },
            typeof: {
                message: t('validator.typeof'),
                rule: (val, params) => typeof val === typeof params[0],
                messageReplace: (message, params) => message.replace(':type', typeof params[0]),
            },
            url: {
                message: t('validator.url'),
                rule: (val) => this.helpers.testRegex(val, /^(https?|ftp):\/\/(-\.)?([^\s/?\.#-]+\.?)+(\/[^\s]*)?$/i),
            },
            xml: {
                message: t('validator.xml'),
                rule: (val) => this.helpers.testXml(val),
            },
            xml_show_error: {
                message: t('validator.xml_show_error'),
                rule: (val) => this.helpers.testXml(val),
                messageReplace: (message, params) => message.replace(':error', params[0]),
            },
            max_date_period: {
                message: t('validator.max_date_period'),
                rule: (val, params) => {
                    return (
                        this.helpers.momentInstalled() &&
                        moment.isMoment(moment(val)) &&
                        moment(val).isBefore(moment(params[0]).add(params[1], params[2]), params[3])
                    );
                },
                messageReplace: (message, params) => message.replace(':maxDatePeriod', params[4]),
            },
            extended_password: {
                message: t('validator.extended_password'),
                rule: (val, params) => {
                    return this.validatePassword(val, params[0], params[1], params[2], params[3], params[4], params[5]);
                },

                messageReplace: (message, params) => {
                    message = message.replace(':min', params[0]);
                    message = message.replace(':max', params[1]);
                    message = message.replace(':lower', this.declination(params[2], 2));
                    message = message.replace(':upper', this.declination(params[3], 3));
                    message = message.replace(':number', this.declination(params[4], 4));
                    message = message.replace(':special', this.declination(params[5], 5));
                    return message;
                },
            },
            pin: {
                message: t('validator.pin'),
                rule: (val) => this.helpers.testRegex(val, /^[0-9]{4}$/i),
            },
            card_role_valid: {
                message: t('validator.card_role_valid'),
                rule: (val) => this.helpers.isCardRoleValid(val),
                required: true,
            },
            required_no_html_tags:{
                message: t('validator.required_no_html_tags'),
                rule: (val) =>  !(
                                 this.helpers.testRegex(val,  /(<([^>]+)>)/ig)
                               || val.includes('<')
                               || val.includes('>')
                               || val.includes('</')
                               || val.includes('/>')
                               ),
                required: true,
            },
            ...(options.validators || {}),
        };

        // apply default options
        this.messages = options.messages || {};
        this.className = options.className;

        // apply default element
        if (options.element === false) {
            this.element = (message) => message;
        } else if (options.hasOwnProperty('element')) {
            this.element = options.element;
        } else if (typeof navigator === 'object' && navigator.product === 'ReactNative') {
            this.element = (message) => message;
        } else {
            this.element = (message, className, id) =>
                React.createElement(
                    'div',
                    { className: className || this.className || 'srv-validation-message', id: `${id}-error` },
                    message
                );
        }
        this.helpers = {
            parent: this,

            passes(rule, value, params, rules) {
                if (rule === undefined || rule === null || rule === '') {
                    return true;
                }
                if (!rules.hasOwnProperty(rule)) {
                    console.error(`Rule Not Found: There is no rule with the name ${rule}.`);
                    return true;
                }
                if (!this.isRequired(rule, rules) && this.isBlank(value)) {
                    return true;
                }                
                return rules[rule].rule(value, params, this.parent) !== false;
            },

            isRequired(rule, rules) {
                return rules[rule].hasOwnProperty('required') && rules[rule].required;
            },

            isBlank(value) {
                let areSpacesOnly = false;
                if(Array.isArray(value)){
                    return value.length==0;
                }
                if (value !== undefined && value !== null && typeof value === 'string') {
                    if (value.trim().length === 0) {
                        areSpacesOnly = true;
                    }
                }
                return typeof value === 'undefined' || value === null || value === '' || areSpacesOnly;
            },

            normalizeValues(value, validation) {
                return [this.valueOrEmptyString(value), this.getValidation(validation), this.getOptions(validation)];
            },

            getValidation(validation) {
                if (validation === Object(validation) && !!Object.keys(validation).length) {
                    return Object.keys(validation)[0];
                } else {
                    if (validation.indexOf('~') !== -1) {
                        return validation.split('~')[0];
                    } else {
                        return validation.split(':')[0];
                    }
                }
            },

            getOptions(validation) {
                if (validation === Object(validation) && !!Object.values(validation).length) {
                    const params = Object.values(validation)[0];
                    return Array.isArray(params) ? params : [params];
                } else {
                    let params;
                    if (validation.indexOf('~') !== -1) {
                        params = validation.replace(/\~(?=([^~]*~[^~]*~)*[^~]*)/g, '`').split('`');
                    } else {
                        params = validation.replace(/:(?=([^~]*~[^~]*~)*[^~]*$)/g, '`').split('`'); // validation.split(':');
                    }
                    if (params.length > 1) {
                        switch (params[0]) {
                            case 'password':
                                const validationParams = params[1].split(',');
                                if (validationParams !== undefined && validationParams !== null && validationParams.length >= 1) {
                                    return validationParams;
                                } else {
                                    return [9];
                                }
                            case 'after':
                            case 'before':
                            case 'after_or_equal':
                            case 'before_or_equal':
                            case 'date_equals':
                                let dateFormat = 'DD-MM-YYYY';
                                if (params.length > 2) {
                                    dateFormat = params[2];
                                }
                                return [moment(moment.utc(params[1], dateFormat)), dateFormat];
                            case 'after_or_equal_time':
                            case 'before_or_equal_time':
                                let dateFormatTime = 'HH:mm:ss';

                                if (params.length > 2) {
                                    dateFormatTime = params[2];
                                }
                                return [params[1], dateFormatTime];

                            case 'regex':
                                return [params[1]];
                            case 'equals':
                                const validationParamsEquals = params[1].replace(/,(?=([^~]*~[^~]*~)*[^~]*$)/g, '`').split('`');
                                if (validationParamsEquals[0]) {
                                    return [validationParamsEquals[0].replace(/~/g, ''), validationParamsEquals[1]];
                                } else {
                                    return [undefined];
                                }
                            case 'equalsDigits':
                                return [params[1]];
                            case 'extended_password':
                                return params.slice(1);
                            default:
                                return params[1].split(',');
                        }
                    } else {
                        return [];
                    }
                }
            },

            valueOrEmptyString(value) {
                return typeof value === 'undefined' || value === null ? '' : value;
            },

            toSentence(arr) {
                return (
                    arr.slice(0, -2).join(', ') +
                    (arr.slice(0, -2).length ? ', ' : '') +
                    arr.slice(-2).join(arr.length > 2 ? ', or ' : ' or ')
                );
            },

            testRegex(value, regex) {
                return value.toString().match(regex) !== null;
            },

            testXml(value) {
                return value === undefined || value === null;
            },

            calculateChecksum(input, weights) {
                let result = 0;
                for (let i = 0; i < weights.length; i++) {
                    result += weights[i] * parseInt(input[i]);
                }
                return result;
            },

            validChecksum(input, weights, control) {
                const digits = `${input}`.split('');
                if (digits.length === weights.length + 1) {
                    const controlSum = this.calculateChecksum(digits, weights);
                    let controlNum = control(controlSum);
                    if (controlNum === 10) {
                        controlNum = 0;
                    }
                    return controlNum === parseInt(digits[weights.length]);
                }
                return false;
            },

            validateRegon(input) {
                let weights;
                switch (input.length) {
                    case 7:
                        weights = [2, 3, 4, 5, 6, 7];
                        break;
                    case 9:
                        weights = [8, 9, 2, 3, 4, 5, 6, 7];
                        break;
                    case 14:
                        weights = [2, 4, 8, 5, 0, 9, 7, 3, 6, 1, 2, 4, 8];
                        break;
                    default:
                        return false;
                }
                return this.validChecksum(input, weights, (controlSum) => controlSum % 11);
            },

            validateZipcode(input) {
                return this.testRegex(input, '^[0-9]{2}-[0-9]{3}$');
            },

            validateNip(input) {
                const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
                return this.validChecksum(input.split('-').join(''), weights, (controlSum) => controlSum % 11);
            },

            isPeselValid(pesel) {
                const reg = /^[0-9]{11}$/;
                if (reg.test(pesel) === false) {
                    return false;
                }
                // pobranie daty
                let rok = parseInt(pesel.substring(0, 2), 10);
                let miesiac = parseInt(pesel.substring(2, 4), 10) - 1;
                const dzien = parseInt(pesel.substring(4, 6), 10);
                if (miesiac >= 80) {
                    rok += 1800;
                    miesiac = miesiac - 80;
                } else if (miesiac >= 60) {
                    rok += 2200;
                    miesiac = miesiac - 60;
                } else if (miesiac >= 40) {
                    rok += 2100;
                    miesiac = miesiac - 40;
                } else if (miesiac >= 20) {
                    rok += 2000;
                    miesiac = miesiac - 20;
                } else {
                    rok += 1900;
                }
                const dataUrodzenia = new Date();
                dataUrodzenia.setFullYear(rok, miesiac, dzien);
                // Weryfikacja numery PESEL
                const wagi = [9, 7, 3, 1, 9, 7, 3, 1, 9, 7];
                let suma = 0;
                for (let i = 0; i < wagi.length; i++) {
                    suma += parseInt(pesel.substring(i, i + 1), 10) * wagi[i];
                }
                suma = suma % 10;
                const cyfraKontr = parseInt(pesel.substring(10, 11), 10);
                const poprawnosc = suma === cyfraKontr;
                // określenie płci
                let plec = 'k';
                if (parseInt(pesel.substring(9, 10), 10) % 2 === 1) {
                    plec = 'm';
                }
                const result = {
                    valid: poprawnosc,
                    sex: plec,
                    date: dataUrodzenia,
                };
                return result.valid;
            },

            isPeselValidOrInitialValue(pesel, initialValue) {
                if (initialValue !== undefined && initialValue === pesel) {
                    return true;
                }
                return this.isPeselValid(pesel);
            },

            message(rule, field, options, rules) {
                options.messages = options.messages || {};
                const message =
                    options.messages[rule] ||
                    options.messages.default ||
                    this.parent.messages[rule] ||
                    this.parent.messages.default ||
                    rules[rule].message;
                return message.replace(':attribute', field);
            },

            humanizeFieldName(field) {
                // supports snake_case or camelCase
                return field
                    .replace(/([A-Z])/g, ' $1')
                    .replace(/_/g, ' ')
                    .toLowerCase();
            },

            element(message, options, id) {
                const element = options.element || this.parent.element;
                return element(message, options.className, id);
            },

            numeric(val) {
                return this.testRegex(val, /^(\d+[.,]?\d*)?$/);
            },

            momentInstalled() {
                if (!moment) {
                    console.warn('Date validators require using momentjs https://momentjs.com and moment objects.');
                    return false;
                } else {
                    return true;
                }
            },

            size(val, type) {
                // if an array or string get Pole length, else return Pole value.
                if (type === 'string' || type === undefined || type === 'array') {
                    return val.length;
                } else if (type === 'num') {
                    return parseFloat(val);
                }
            },
            sizeDigits(val) {
                return val ? parseFloat(val.toString().length) : parseFloat(0);
            },

            sizeText(type) {
                if (type === 'string' || type === undefined) {
                    return ' znaków';
                } else if (type === 'array') {
                    return ' elementów';
                } else {
                    return '';
                }
            },

            number_max(value, params) {
                if (params === undefined) {
                    return false;
                }
                const max = parseInt(params[0]);
                const intValue = parseInt(value);

                if (isNaN(max) || isNaN(intValue)) {
                    return false;
                }
                return intValue <= max;
            },

            number_min(value, params) {
                if (params === undefined) {
                    return false;
                }
                const max = parseInt(params[0]);
                const intValue = parseInt(value);

                if (isNaN(max) || isNaN(intValue)) {
                    return false;
                }
                const result = intValue >= max;
                return result;
            },

            number_max_float(value, params) {
                if (params === undefined) {
                    return false;
                }
                const max = parseFloat(params[0]);
                const intValue = parseFloat(value);

                if (isNaN(max) || isNaN(intValue)) {
                    return false;
                }
                return intValue <= max;
            },

            number_min_float(value, params) {
                if (params === undefined) {
                    return false;
                }
                const max = parseFloat(params[0]);
                const intValue = parseFloat(value);

                if (isNaN(max) || isNaN(intValue)) {
                    return false;
                }
                const result = intValue >= max;
                return result;
            },

            currency_max(value, params) {
                if (params === undefined) {
                    return false;
                }
                const max = parseFloat(params[0]);
                const intValue = parseFloat(value);

                if (isNaN(max) || isNaN(intValue)) {
                    return false;
                }
                return intValue <= max;
            },

            currency_min(value, params) {
                if (params === undefined) {
                    return false;
                }
                const max = parseFloat(params[0]);
                const intValue = parseFloat(value);

                if (isNaN(max) || isNaN(intValue)) {
                    return false;
                }
                const result = intValue >= max;
                return result;
            },

            isCardRoleValid(val) {
                if (val === 'DRIVER' || val === 'ADMIN') {
                    return true;
                } else {
                    return false;
                }
            },
        };
    }

    declination(value, paramIndex) {
        // eslint-disable-next-line default-case
        switch (paramIndex) {
            case 2:
                switch (value) {
                    case '1':
                        return `${value} małą literę`;
                    case '2':
                    case '3':
                    case '4':
                    case '0':
                        return `${value} małych liter`;
                    default:
                        return `${value} małe litery`;
                }
            case 3:
                switch (value) {
                    case ' 1':
                        return `${value} wielką literę`;
                    case '2':
                    case '3':
                    case '4':
                        return `${value} wielkie litery`;
                    default:
                        return `${value} wielkich liter`;
                }
            case 4:
                switch (value) {
                    case '1':
                        return `${value} liczbę`;
                    case '2':
                    case '3':
                    case '4':
                        return `${value} liczby`;
                    default:
                        return `${value} liczb`;
                }
            case 5:
                switch (value) {
                    case '1':
                        return `${value} znak specjalny`;
                    case '2':
                    case '3':
                    case '4':
                        return `${value} znaki specjalne`;
                    default:
                        return `${value} znaków specjalnych`;
                }
        }
    }

    getErrorMessages() {
        return this.errorMessages;
    }

    getFields(){
        return this.fields; 
    }

    showMessages() {
        this.messagesShown = true;
    }

    hideMessages() {
        this.messagesShown = false;
    }
    validatePassword(text, minLength, maxLength, lowerCase, upperCase, digits, specialChar) {
        let numberOfDigitsInText = 0;
        let numberOfLowerInText = 0;
        let numberOfUpperCaseInText = 0;
        let numberOfSpecialCharacter = 0;

        const specialCharacter = `=!@#$%^&*(){}[]|:";\'<>?,./`;

        if (text.length > maxLength || text.length < minLength) {
            return false;
        }
        for (let i = 0; i < text.length; i++) {
            const character = text.charAt(i);
            if (/\d/.test(character)) {
                numberOfDigitsInText++;
            } else if (specialCharacter.indexOf(character) !== -1) {
                numberOfSpecialCharacter++;
            } else if (character === character.toLowerCase()) {
                numberOfLowerInText++;
            } else if (character === character.toUpperCase()) {
                numberOfUpperCaseInText++;
            }
        }
        if (numberOfDigitsInText < digits) {
            return false;
        } else if (numberOfLowerInText < lowerCase) {
            return false;
        } else if (numberOfUpperCaseInText < upperCase) {
            return false;
        } else if (numberOfSpecialCharacter < specialChar) {
            return false;
        }
        return true;
    }
    allValid() {
        const toDelete = [];
        for (const key in this.fields) {
            if (key && key !== '') {
                const el = document.getElementById(key);
                if (!el) {
                    toDelete.push(key);
                }
            }
        }
        for (const key of toDelete) {
            //console.log(`* removing from validator fields: ${key}`);
            delete this.fields[key];
        }
        for (const key in this.fields) {
            if (this.fieldValid(key) === false) {
                return false;
            }
        }
        return true;
    }
    setFieldValid(field, boolValue) {
        this.fields[field] = boolValue;
    }

    fieldValid(field) {
        return this.fields.hasOwnProperty(field) && this.fields[field] === true;
    }

    purgeFields() {
        this.fields = {};
        this.errorMessages = {};
    }

    messageAlways(field, message, options = {}) {
        if (message && this.messagesShown) {
            return this.helpers.element(message, options);
        }
    }

    removeValidation(fieldName) {
        delete this.errorMessages[fieldName];
        delete this.fields[fieldName];
    }

    message(id, field, inputValue, validations, options = {}) {
        this.errorMessages[id] = null;
        this.fields[id] = true;
        if (!Array.isArray(validations)) {
            validations = validations.replace(/\|(?=([^~]*~[^~]*~)*[^~]*)/g, '`').split('`');
        }
        const rules = options.validators ? { ...this.rules, ...options.validators } : this.rules;
        for (const validation of validations) {
            if (validation !== undefined) {
                const [value, rule, params] = this.helpers.normalizeValues(inputValue, validation);
                if (!this.helpers.passes(rule, value, params, rules)) {
                    this.fields[id] = false;
                    let message = this.helpers.message(rule, field, options, rules);
                    if (params.length > 0 && rules[rule].hasOwnProperty('messageReplace')) {
                        message = rules[rule].messageReplace(message, params);
                    }
                    this.errorMessages[id] = message;
                    if (this.messagesShown) {
                        return this.helpers.element(message, options, id);
                    }
                }
            }
        }
    }
    passwordFieldTranslation(value, paramIndex) {
        switch (paramIndex) {
            case 2:
                return `${value} ${this.translate('password.lowercaseLetter', { postProcess: 'interval', count: parseInt(value) })}`;
            case 3:
                return `${value} ${this.translate('password.uppercaseLetter', { postProcess: 'interval', count: parseInt(value) })}`;
            case 4:
                return `${value} ${this.translate('password.numbersCount', { postProcess: 'interval', count: parseInt(value) })}`;
            case 5:
                return `${value} ${this.translate('password.specialCharacterCount', { postProcess: 'interval', count: parseInt(value) })}`;
            default:
                return '';
        }
    }
}

export default SimpleReactValidator;
