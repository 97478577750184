import moment from 'moment/moment';
import BaseServiceCrud from './BaseServiceCrud';
import Constants from '../utils/constants';
import StringUtils from '../utils/StringUtils';

export default class AfterHoursDriveAlarmService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'after-hours-drive-alarm';
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            dayReported: criteria.dayReported,
            dateFrom: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            dateTo: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
            carName: criteria.carName,
            status: criteria.status?.enumValue,
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    changeStatus(element) {
        return this.fetch(`${this.domain}/${this.path}/change-status`, {
            method: 'PATCH',
            body: JSON.stringify(element),
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    getTrips(id) {
        return this.fetch(`${this.domain}/${this.path}/trips/${id}`, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    exportList(criteria, format) {
        const params = this.objToQueryString({
            dayReported: criteria.dayReported,
            day: StringUtils.isBlank(criteria.day) ? null : moment(criteria.day).format(Constants.DATE_JSON_FORMAT),
            carName: criteria.carName,
            status: criteria.status?.enumValue,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
            exportType: format,
        });
        return super.exportList(params);
    }
}
