class I18nUtils {
    static getSupportedLanguages(a) {
        let supportedLanguages = ['pl'];
        const supportedLanguagesFromStorage = localStorage.getItem('supportedLanguages');
        try {
            if (supportedLanguagesFromStorage !== undefined && supportedLanguagesFromStorage !== null) {
                const supportedLanguagesFromStorageArray = supportedLanguagesFromStorage.split(';');
                supportedLanguages = supportedLanguagesFromStorageArray;
            }
        } catch (err) {
            console.log('getSupportedLanguages error', err);
        }
        return supportedLanguages;
    }
}

export default I18nUtils;
