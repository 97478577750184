import { t } from "i18next";
import { Card } from 'primereact/card';
import { TabPanel, TabView } from 'primereact/tabview';
import PropTypes from 'prop-types';
import React from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import BaseDetailsContainer from "../../baseContainers/BaseDetailsContainer";
import DivContainer from "../../components/DivContainer";
import ProjectService from "../../services/ProjectService";
import EmployeeReserveConfirmedList from "./EmployeeReserveConfirmedList";
import EmployeeReserveUnconfirmedList from "./EmployeeReserveUnconfirmedList";
import EmployeeReserveReadyList from "./EmployeeReserveReadyList";
import SelectProjectDialog from "../SelectProjectDialog";
import EmployeeReserveSoonAbsentList from "./EmployeeReserveSoonAbsentList";
import EmployeeReserveStandByList from "./EmployeeReserveStandByList";



class ReserveContainer extends BaseDetailsContainer {

    constructor(props) {
        super(props, new ProjectService());

        const queryParams = new URLSearchParams(this.props.location.search);
        let activeIndexParam = parseInt(queryParams.get('activeIndex'));
        let firstParam = parseInt(queryParams.get('first'));
        let maxsParam=parseInt(queryParams.get('maxs'));

        this.state = {
            activeIndex: activeIndexParam ? activeIndexParam : 0,
            firstParam : firstParam ? firstParam : 0,
            maxsParam : maxsParam ? maxsParam : 10,
            loading: true,
            downloadingPdf: false
        };
    }


    renderBackLink() {
    }


    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        const initActiveIndex = this.state.activeIndex;
        this.setState({ activeIndex: 0 }, () => {
            if (this.validator.allValid()) {
                this.setState({ activeIndex: 1 }, () => {
                    if (this.validator.allValid()) {
                        this.setState({ activeIndex: 2 }, () => {
                            if (this.validator.allValid()) {
                                this.setState({ activeIndex: 5 }, () => {
                                    if (this.validator.allValid()) {
                                        this.setState({ activeIndex: initActiveIndex }, () => this.blockUi(this.handleValidForm));
                                    } else {
                                        this.validator.showMessages();
                                        this.scrollToError = true;
                                        this.forceUpdate();
                                    }
                                })
                            } else {
                                this.validator.showMessages();
                                this.scrollToError = true;
                                this.forceUpdate();
                            }
                        });
                    } else {
                        this.validator.showMessages();
                        this.scrollToError = true;
                        this.forceUpdate();
                    }
                })
            } else {
                this.validator.showMessages();
                this.scrollToError = true;
                this.forceUpdate();
            }

        });
    }

    backUrlParams() {
        return `?activeIndex=${this.state.activeIndex}`;
    }

    prepareFooterItems() {

    }


    isPdfButtonRendered() {
        return false;
    }

    getPdfDownloadLabel() {
        return null;
    }

    handleDownloadPdf() {
    }


    createQueryString(queryObject = {}) {
        let queryString = Object.keys(queryObject)
            .filter((key) => queryObject[key] && !(Array.isArray(queryObject[key]) && !queryObject[key].length))
            .map((key) => {
                return Array.isArray(queryObject[key]) ? queryObject[key].map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&') : `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`;
            }).join('&');
        return queryString ? `?${queryString}` : "";
    };
    renderAssignDialog() {
        return (
            <SelectProjectDialog 
            id="selectProjectDialog"
            key="selectProjectDialogKey"
            visible={this.state.selectProjectDialogVisible}
            onHide={() => this.setState({selectProjectDialogVisible: false})}
            onSelect={(project, dateStart, dateEnd) => {
                this.service.assignManagerFromProject(this.state?.selectedUserId, project.id, dateStart, dateEnd)
                .then(res => {
                    console.log('@', res);
                })
                .catch(err => {
                    this.showErrorMessage(err?.message);
                })
            }}
            userId={this.state?.selectedUserId}
            userName={this.state?.selectedUserName}
        />  
        );
    }

    renderDetails() {

        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <DivContainer colClass='col-md-12'>
                        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => {
                            this.setState({ activeIndex: e.index }, () => {
                                this.props.history.push({
                                    search: this.createQueryString({ activeIndex: e.index })
                                });
                            });
                        }} >
                            <TabPanel header={t('reserve.unconfirmed.listName')}>
                                <Card style={{ border: "none" }}>
                                    <DivContainer colClass='row'>
                                        <EmployeeReserveUnconfirmedList
                                            validator={this.validator}
                                            urlParams={{
                                               activeIndex: this.state.activeIndex,
                                               first: this.state.firstParam,
                                               maxs: this.state.maxsParam
                                            }}
                                        />

                                    </DivContainer>
                                </Card>
                            </TabPanel>
                            <TabPanel header={t('reserve.confirmed.listName')}>
                                <Card style={{ border: "none" }}>
                                    <DivContainer colClass='row'>
                                        <EmployeeReserveConfirmedList
                                            validator={this.validator}
                                            urlParams={{
                                               activeIndex: this.state.activeIndex,
                                               first: this.state.firstParam,
                                               maxs: this.state.maxsParam
                                            }}
                                        />

                                    </DivContainer>
                                </Card>
                            </TabPanel>
                            <TabPanel header={t('reserve.ready.listName')}>
                                <Card style={{ border: "none" }}>
                                    <DivContainer colClass='row'>
                                        <EmployeeReserveReadyList
                                            validator={this.validator}
                                            urlParams={{
                                               activeIndex: this.state.activeIndex,
                                               first: this.state.firstParam,
                                               maxs: this.state.maxsParam
                                            }}
                                        />

                                    </DivContainer>
                                </Card>
                            </TabPanel>
                            <TabPanel header={t('reserve.soonAbsent.listName')}>
                                <Card style={{ border: "none" }}>
                                    <DivContainer colClass='row'>
                                        <EmployeeReserveSoonAbsentList
                                            validator={this.validator}
                                            urlParams={{
                                               activeIndex: this.state.activeIndex,
                                               first: this.state.firstParam,
                                               maxs: this.state.maxsParam
                                            }}
                                        />

                                    </DivContainer>
                                </Card>
                            </TabPanel>
                            <TabPanel header={t('reserve.standBy.listName')}>
                                <Card style={{ border: "none" }}>
                                    <DivContainer colClass='row'>
                                        <EmployeeReserveStandByList
                                            validator={this.validator}
                                            urlParams={{
                                               activeIndex: this.state.activeIndex,
                                               first: this.state.firstParam,
                                               maxs: this.state.maxsParam
                                            }}
                                        />

                                    </DivContainer>
                                </Card>
                            </TabPanel>
                        </TabView>
                    </DivContainer>

                </DivContainer>
            </React.Fragment>
        )
    }
}



ReserveContainer.defaultProps = {
    backUrl: '/#/reserve'
};

ReserveContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withRouter(withTranslation()(ReserveContainer));

