import {Card} from 'primereact/card';
import {InputText} from 'primereact/inputtext';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import React from 'react';
import {withTranslation} from 'react-i18next';
import {Redirect} from 'react-router-dom';
import BaseContainer from '../../baseContainers/BaseContainer';
import ActionButton from '../../components/ActionButton';
import {CustomMessages} from '../../components/CustomMessages';
import SimpleReactValidator from '../../components/validator';
import AuthService from '../../services/AuthService';
import AccountService from '../../services/AccountService';
import AppPrefixUtils from '../../utils/AppPrefixUtils';
import BlockUi from '../../components/waitPanel/BlockUi';
import {Messages} from 'primereact/messages';
import InputTextComponent from '../../components/inputs/InputTextComponent';

class RemindPasswordContainer extends BaseContainer {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.authService = new AuthService();
        this.userService = new AccountService();
        this.state = {
            element: {
                email: '',
            },
            redirectToReferrer: true,
            authValid: true,
            showCaptcha: false,
            captchaPreventAction: false,
        };
        this.validator = new SimpleReactValidator({}, this.translate);
    }
    componentDidMount() {
        super.componentDidMount();
        const values = queryString.parse(this.props.location.search);
        this.targetLocation = values.location;
        //Messages.multiReadPersistMessage(['login-page'], this.messages);
    }
    persistMessage(severity, summary, detail) {
        this.saveCookie(
            'login-page',
            JSON.stringify({
                severity,
                summary,
                detail,
            })
        );
    }
    handleFormSubmit(e) {
        //e.preventDefault();
        const {t} = this.props;
        if (this.validator.allValid()) {
            this.blockUi();
            new AccountService()
                .sendLinkForGenerateNewPassword(this.state.element.email)
                .then(() => {
                    this.persistMessage('success', '', t('password.resetSuccessLabel'));
                    window.location.href = AppPrefixUtils.locationHrefUrl('/');
                })
                .catch((err) => {
                    this.showErrorMessage(err.message);
                    this.unblockUi();
                });
        } else {
            this.validator.showMessages();
            // rerender to show messages for the first time
            this.setState({
                valid: false,
            });
            this.forceUpdate();
        }
    }
    translate(key) {
        const {t} = this.props;
        return t(key);
    }
    render() {
        if (this.authService.loggedIn()) {
            return this.renderAfterAuth();
        } else {
            return this.renderBeforeAuth();
        }
    }

    renderAfterAuth() {
        const {redirectToReferrer} = this.state;
        if (redirectToReferrer === true) {
            return <Redirect to={this.targetLocation ? this.targetLocation : '/start'} />;
        }
        return <Redirect to={'/start'} />;
    }

    renderBeforeAuth() {
        const {t} = this.props;
        return (
            <React.Fragment>
                <BlockUi tag='div' blocking={this.state.blocking || this.state.loading} loader={this.loader}>
                    <div id='main-login'>
                        <Messages id='custom-messages' ref={(el) => (this.messages = el)}></Messages>

                        <form
                            className='login-pane'
                            onSubmit={(e) => {
                                this.handleFormSubmit(e);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                    this.handleFormSubmit();
                                }
                            }}
                            noValidate
                        >
                            <div className='row'>
                                <div className='col-md-6 login-pane-left'>
                                  <div className='logo' alt='logo' />
                                    <div className='welcome-text-header'></div>
                                    <div className='welcome-text '>
                                        <div>Zarządzanie projektami</div>
                                    </div>
                                </div>
                                <div className='col-md-6 form-div'>
                                    <div className='welcome-text'>
                                        <h6 className='card-title text-align-left'>
                                            {this.props.type && this.props.type === 'CREATE'
                                                ? this.translate('password.setPasswordInstruction')
                                                : this.translate('password.resetPasswordLabel')}
                                        </h6>
                                    </div>
                                    <div location='user_field'>
                                        <InputTextComponent
                                            id='email'
                                            name='email'
                                            label={'Email'}
                                            colClass='col-md-12'
                                            value={this.state.element.email}
                                            validator={this.validator}
                                            validators='required|email'
                                            onChange={this.handleChange}
                                            viewMode={'EDIT'}
                                            //keyfilter={/^[A-ZĄĆĘŁŃÓŚŹŻąćęłńóśźż\s-]*$/i}
                                        />
                                        <div class='col-12'>
                                            {/* <Button className='btn btn-lg btn-primary btn-block text-uppercase' label={this.translate('details.save')} /> */}
                                            <ActionButton
                                                label='Resetuj hasło'
                                                variant='login-button'
                                                handleClick={this.handleFormSubmit}
                                                disabled={this.state.captchaPreventAction}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </BlockUi>
            </React.Fragment>            
        );
    }
}
RemindPasswordContainer.propTypes = {
    onAfterLogin: PropTypes.func,
};
export default withTranslation()(RemindPasswordContainer);
