import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';
import Constants from '../utils/constants';

export default class DriverRateService extends BaseServiceCrud {
    // Initializing important variables
    constructor() {
        super();
        this.path = 'driver-rate';
      //  this.getValue = this.getValue.bind(this);
    }
    getCriteria(criteria) {
        return this.objToQueryString({
            date_from: criteria.dateFrom ? moment(criteria.dateFrom).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,
			date_to: criteria.dateTo ? moment(criteria.dateTo).format(Constants.DATE_GET_REQUEST_FORMAT) : undefined,    
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,
        });
    }

    add(element) {
        return super.add(this.createObjectToSave(element));
    }

    update(element) {
        return super.update(this.createObjectToSave(element));
    }

    createObjectToSave(element) {       
        const e = this.cloneElement(element);        
        if (e.dateFrom) {
            e.dateFrom = moment(element.dateFrom).startOf('month').format(Constants.DATE_JSON_FORMAT);
        }
        if (e.dateTo) {
            e.dateTo = moment(element.dateTo).endOf('month').format(Constants.DATE_JSON_FORMAT);
        }
        return e;
    }
}
