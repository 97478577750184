import PropTypes from 'prop-types';
import React from 'react';
import {BreadcrumbsItem} from 'react-breadcrumbs-dynamic';
import BaseListContainer from '../baseContainers/BaseListContainer';
import CriteriaTextComponent from '../components/criteria/CriteriaTextComponent';
import {t} from 'i18next';
import ActionLink from '../components/ActionLink';
import UserDayService from '../services/UserDayService';
import CriteriaCalendarRangeComponent from '../components/criteria/CriteriaCalendarRangeComponent';
import {ColumnGroup} from 'primereact/columngroup';
import {Row} from 'primereact/row';
import {Column} from 'primereact/column';
import NumberUtils from '../utils/NumberUtils';
import ProjectCategoryService from '../services/ProjectCategoryService';
import CriteriaMultiSelectComponent from '../components/criteria/CriteriaMultiSelectComponent';
import CountryService from '../services/CountryService';
import { PrintButtonComponent } from '../report/ReportUtils';
import YesNoDialog from '../components/YesNoDialog';
import ActionButton from '../components/ActionButton';
import FileSaver, { saveAs } from 'file-saver';
import moment from 'moment';

class HourlyReportListContainer extends BaseListContainer {
    constructor(props) {
        super(props, new UserDayService());
        this.projectCategoryService = new ProjectCategoryService();
        this.countryService=new CountryService();
        this.state = {
            categories: [],
            countries: [],
            list: [],
            loading: true,
            size: 0,
            first: 0,
            criteria: this.getCleanSearchCriteria(),
            dialogVisibile: false,
            visible: false,
        };
        this.onPrintButtonClick = this.onPrintButtonClick.bind(this)
        this.generateReport = this.generateReport.bind(this);
        this.handleExportClientTime = this.handleExportClientTime.bind(this);
    }

    updateSearchCriteria(criteria) {
        return {
            fullName: criteria.fullName,
            clientName: criteria.clientName,
            projectName: criteria.projectName,
            subcontractor: criteria.subcontractor,
            dateFrom: criteria.dateFrom,
            dateTo: criteria.dateTo,
            countries: criteria.countries,
            categories: criteria.categories,
            firstResult: criteria.firstResult,
            maxResults: criteria.maxResults,
            sortField: criteria.sortField,
            sortAsc: criteria.sortAsc
        };
    }

    getCleanSearchCriteria() {
        return {
            fullName: null,
            clientName: null,
            projectName: null,
            subcontractor: null,
            categories: [],
            countries: [],
            firstResult: 0,
            maxResults: 10,
            sortField: 'date',
            sortAsc: false,
        };
    }
 

    initializeFromBackend(){
        this.projectCategoryService.getList({ firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true })
        .then(categories => this.setState({ categories: categories.content }))
        .catch(() => {
            this.showErrorMessage('Nie udało się pobrać listy kategorii');
        });
        this.countryService.getAll()
        .then(countries=>{
            this.setState({countries: countries})
        })
        .catch(()=>{
            this.showErrorMessage('Nie udało się pobrać listy krajów');
        })
    }

    getCriteriaName() {
        return 'hourlyreport-list-sc';
    }

    getContainerListName() {
        return 'hourlyreport-list-container';
    }

    footerGroup() {
        const {summary} = this.state;
        return (
            <ColumnGroup>
                <Row>
                    <Column footer={t('list.summary')} />
                    <Column footer='' colSpan={5} footerStyle={{textAlign: 'right'}} />
                    <Column footer={NumberUtils.formatTimeIfPossible(summary.sumOfWorkTime)} footerStyle={{textAlign: 'left'}} />
                    <Column footer={NumberUtils.formatTimeIfPossible(summary.sumOfClientTime)} footerStyle={{textAlign: 'left'}} />
                    <Column footer={NumberUtils.formatTimeIfPossible(summary.sumOfEmpDriveTime)} footerStyle={{textAlign: 'left'}} />
                    <Column footer={NumberUtils.formatTimeIfPossible(summary.sumOfLeadDriveTime)} footerStyle={{textAlign: 'left'}} />
                    <Column footer={NumberUtils.formatTimeIfPossible(summary.sumExtraDriveTime)} footerStyle={{textAlign: 'left'}} />
                    <Column />
                </Row>
            </ColumnGroup>
        );
    }
    renderCriteria() {
        return (
            <div className='row'>
                <CriteriaTextComponent
                    id='projectName-sc'
                    name='projectName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.projectName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('hourlyreport.project.name')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='clientName-sc'
                    name='clientName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.clientName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('hourlyreport.project.client.name')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='subcontractor-sc'
                    name='subcontractor'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.subcontractor}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('hourlyreport.user.subcontractor')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />
                <CriteriaTextComponent
                    id='fullName-sc'
                    name='fullName'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.fullName}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('hourlyreport.user.name')}
                    global={true}
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                />

                <CriteriaMultiSelectComponent
                    id='categories'
                    name='categories'
                    label={t('project.details.categories')}
                    colClass='col-md-4'
                    onChange={this.handleChangeSc}
                    options={this.state.categories}
                    optionLabel='name'
                    value={this.state.criteria.categories}                                            
                    dataKey='id' 
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}/>

                <CriteriaCalendarRangeComponent
                    id='date-sc'
                    idFrom='dateFrom-sc'
                    idTo='dateTo-sc'
                    name='date'
                    nameFrom='dateFrom'
                    nameTo='dateTo'
                    label={'Data'}
                    colClass='col-xl-4 col-lg-8 col-sm-8'
                    from={this.state.criteria.dateFrom}
                    to={this.state.criteria.dateTo}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    refreshFromBackend
                    showButtonBar
                    sharedLabel
                    renderDash
                    maxDateInfinite
                    placeholderFrom={'Data od'}
                    placeholderTo={'Data do'}
                    inputsClassName='col-6'
                />
                 <CriteriaMultiSelectComponent
                    id='country-sc'
                    name='countries'
                    colClass='col-lg-4 col-sm-6'
                    value={this.state.criteria.countries}
                    options={this.state.countries}
                    onChange={this.handleChangeSc}
                    validator={this.validator}
                    label={t('hourlyreport.project.country')}
                    global={true}
                    optionLabel='name'
                    refreshFromBackend
                    timeoutHandler={this.refreshTable}
                    filter 
                    filterBy="name"
                    placeholder="Wybierz kraj" 
                />
            </div>
        );
    }
    enumTemplate(field, rowData) {
        if (rowData[field] && rowData[field].label) {
            return rowData[field].label;
        } else if (rowData[field]) {
            return rowData[field];
        }
        return '';
    }

    actionTemplate(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi-pencil'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => this.handleOpenEditDialog(e, rowData)}
                    variant='blue'
                    className='edit-blue-link'
                    key={'view-button'}
                    rendered={false}
                />
            </span>
        );
    }

    handleOpenEditDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: this.cloneElement(rowData), dialogViewMode: 'EDIT'});
    }

    handleOpenDeleteDialog(e, rowData) {
        if (e) {
            e.preventDefault();
        }
        this.setState({deleteConfirmDialogVisible: true, element: rowData, dialogViewMode: 'DELETE'});
    }

    formatTime(field, rowData) {
        const time = rowData[field];
        return NumberUtils.formatTimeIfPossible(time);
    }
    
    prepareColumns() {
        return [
            {field: 'project.name', header: t('hourlyreport.project.name'), sortable: true, sortField: 'project_name'},
            {field: 'project.client.name', header: t('hourlyreport.project.client.name'), sortable: true, sortField: 'client_name'},
            {field: 'country', header: t('hourlyreport.project.client.country'), sortable: true, sortField: 'country_name'},
            {field: 'user.subcontractor', header: t('hourlyreport.user.subcontractor'), sortable: true, sortField: 'sub_name'},
            {field: 'user.fullName', header: t('hourlyreport.user.name'), sortable: false},
            {
                field: 'reportedDay.date',
                header: 'Dzień',
                width: '180px',
                sortable: true,
                sortField: 'date',
            },
            {
                field: 'workTime',
                header: t('hourlyreport.worktime'),
                sortable: true,
                body: this.formatTime.bind(this, 'workTime'),
                sortField: 'emp_time',
            },
            {
                field: 'clientTime',
                header: t('hourlyreport.clienttime'),
                sortable: true,
                body: this.formatTime.bind(this, 'clientTime'),
                sortField: 'client_time',
            },
            {
                field: 'empDriveTime',
                header: t('hourlyreport.empDriveTime'),
                sortable: false,
                body: this.formatTime.bind(this, 'empDriveTime'),
            },
            {
                field: 'leadDriveTime',
                header: t('hourlyreport.leadDriveTime'),
                sortable: false,
                body: this.formatTime.bind(this, 'leadDriveTime'),
            },
            {
                field: 'extraDriveTime',
                header: t('hourlyreport.extraDriveTime'),
                sortable: false,
                body: this.formatTime.bind(this, 'extraDriveTime'),
            },
            {field: 'reportedDay.level.label', header: 'Stan raportu', sortable: false},
        ];
    }
    prepareHeaderItems() {
        return [

            {
                type: 'BUTTON',
                className: 'right-side',
                variant: 'white',
                disabled: !this.state.list || this.state.list.length < 1,
                customRenderFunction: this.renderPrintButton,
            },
        ];
    }
    
    onPrintButtonClick() {
        this.setState({
                visible: true
        });
    }
    renderPrintButton() {
        return (
            <PrintButtonComponent
                key={`print-button-pay-report`}
                reportFileNameBase={t('hourlyreport.listName')}
                xml={false}
                pdf={false}
                csv={false}
                ods={false}
                xlsx={false}
                globalFormat='xlsx'
                noMenuFlag={true}
                generateReport={(e) => this.generateReport(e)}
                disabled={!this.state.list.length > 0}
                onButtonClick={this.onPrintButtonClick}
            />
        );
    }
    handleExportClientTime(target) {
        if (target.value) {
            this.setState({
                visible: false
            });
            this.handlePrint('xlsx', null, true)
        } else {
            this.setState({
                visible: false
            });
            this.handlePrint('xlsx', null, false)
        }
    }
    handlePrint(format, event, clientTime) {
        if (event) {
            event.originalEvent.preventDefault();
        }
        let reportFileNameBase = t('hourlyreport.listName');
        this.generateReport(format, clientTime).then((blob) => {
            const now = moment().format('YYYYMMDD_HHmmss');
            const fileName = `${reportFileNameBase}_${now}.${format}`;
            saveAs(blob, fileName);
        });
    }
    generateReport(format, clientTime) {
        const { criteria } = this.state;
        switch (format) {
            case 'xlsx':
                return this.service.exportList(criteria, 'xlsx', clientTime);
            default:
                return Promise.reject('Nieznany format');
        }
    }
    handleOpenNewDialog(e) {
        let basicElement = this.initNewElement();
        if (e) {
            e.preventDefault();
        }
        this.setState({dialogVisibile: true, element: basicElement, dialogViewMode: 'NEW'});
    }

    handleDelete(e) {
        if (e) {
            e.preventDefault();
        }
        console.log('delete {}', this.state.element);
        this.service
            .remove(this.state.element)
            .then((response) => {
                this.blockUi();
                this.persistMessage('success', '', t('hourlyreport.deleteSuccess'));
            })
            .catch((err) => {
                this.persistMessage('error', '', t('hourlyreport.deleteError'));
            })
            .finally(() => {
                this.afterObjectUpdate();
                this.setState({deleteConfirmDialogVisible: false});
            });
    }

    afterObjectUpdate() {
        this.readMessage();
        this.refreshTable();
        this.unblockUi();
    }

   
    render() {
        return (
            <div className='container-fluid projectcategory-list'>
                <BreadcrumbsItem to='/hourlyreport-list'>{'Podwykonawcy'}</BreadcrumbsItem>
                {this.renderView()}
                {
                     
             <YesNoDialog
                visible={this.state.visible}
                header={'Wybór odbiorcy'}
                yesButtonLabel={'Czas dla klienta'}
                onChange={(type, x, target) => this.handleExportClientTime(target)}
                noButtonLabel={'Czas dla pracownika'}
                name='visible'
                childrenHide
                children
                resizable={true}
                onHide={() => this.setState({visible: false})}
            >
            {}
            </YesNoDialog>
            }
            </div>
        );
    }
}

HourlyReportListContainer.defaultProps = {
    detailUrl: '/#/hourly-report/details',
    newUrl: '/#/hourly-report/create',
    lazy: true,
    renderHeader: true
};

HourlyReportListContainer.propTypes = {
    detailUrl: PropTypes.string,
    newUrl: PropTypes.string,
    renderHeader: PropTypes.bool
};

export default HourlyReportListContainer;
