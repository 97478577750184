import {t} from 'i18next';
import moment from 'moment';
import {Card} from 'primereact/card';
import {Column} from 'primereact/column';
import {DataTable} from 'primereact/datatable';
import {TabPanel, TabView} from 'primereact/tabview';
import PropTypes from 'prop-types';
import React from 'react';
import {withTranslation} from 'react-i18next';
import NumberFormat from 'react-number-format';
import BaseDetailsContainer from '../baseContainers/BaseDetailsContainer';
import ActionLink from '../components/ActionLink';
import DivContainer from '../components/DivContainer';
import {ReportedDayCarsComponent} from '../components/ReportedDayCarsComponent';
import ReportedDayExtraEventsComponent from '../components/ReportedDayExtraEventsComponent';
import YesNoDialog from '../components/YesNoDialog';
import InputAutocompleteComponent from '../components/inputs/InputAutocompleteComponent';
import InputCalendarComponent from '../components/inputs/InputCalendarComponent';
import InputDropdownComponent from '../components/inputs/InputDropdownComponent';
import InputMaskComponent from '../components/inputs/InputMaskComponent';
import InputTextComponent from '../components/inputs/InputTextComponent';
import ActivityService from '../services/ActivityService';
import CarService from '../services/CarService';
import ClientService from '../services/ClientService';
import EmployeeService from '../services/EmployeeService';
import ProjectCategoryService from '../services/ProjectCategoryService';
import ProjectService from '../services/ProjectService';
import ReportedDayService from '../services/ReportedDayService';
import ToolsService from '../services/ToolsService';
import NumberUtils from '../utils/NumberUtils';
import Constants from '../utils/constants';
import RDModel from './RDModel';
import {withRouter} from 'react-router-dom';
import AuthService from '../services/AuthService';
import LogEntryListContainer from './LogEntryListContainer';
import StringUtils from '../utils/StringUtils';
import {InputTextarea} from 'primereact/inputtextarea';
import AlarmStatusIconTemplateComponent from '../components/AlarmStatusIconTemplateComponent';

class ReportedDayDetailsContainer extends BaseDetailsContainer {
    constructor(props) {
        super(props, new ReportedDayService());
        this.clientService = new ClientService();
        this.projectCategoryService = new ProjectCategoryService();
        this.activityService = new ActivityService();
        this.carService = new CarService();
        this.toolsService = new ToolsService();
        this.employeeService = new EmployeeService();
        this.projectService = new ProjectService();
        this.authService = new AuthService();

        const queryParams = new URLSearchParams(this.props.location.search);
        let activeIndexParam = parseInt(queryParams.get('activeIndex'));

        this.state = {
            activeIndex: activeIndexParam ? activeIndexParam : 0,
            loading: true,
            showFooterButtons: false,
            downloadingPdf: false,
            elementId: props.elementId,
            showConfirmRejectDialog: false,
            showConfirmReopenDialog: false,
            showApproveAlarmDialog: false,
            showConfirmAcceptDialog: false,
            currentDescForAlarm: '',
            changedAlarm: {},
            leaderRoles: [],
            clients: [],
            projects: [],
            activities: [],
            drivers: [],
            activitiesHidden: [],
            cars: [],
            users: [],
            filteredEmployees: [],
            userToUserDaySet: [],
            extraEv: [],
            filteredCars: [],
            initiatesEmployees: [],
            projectDateInitiate: false,
            copiedEl: new RDModel(),
            tools: [],
            priceTypes: [],
            expandedRows: [],
            dateXD: undefined,
            userDays: [],
            levelLabel: '',
            element: new RDModel(),
        };
        this.rejectReportedDay = this.rejectReportedDay.bind(this);
        this.acceptReportedDay = this.acceptReportedDay.bind(this);
        this.showRejectConfirmation = this.showRejectConfirmation.bind(this);
        this.hideRejectConfirmation = this.hideRejectConfirmation.bind(this);
        this.showAcceptConfirmation = this.showAcceptConfirmation.bind(this);
        this.hideAcceptConfirmation = this.hideAcceptConfirmation.bind(this);
        this.showReopenConfirmation = this.showReopenConfirmation.bind(this);
        this.hideReopenConfirmation = this.hideReopenConfirmation.bind(this);
    }
    translateLevel(l) {
        if (l === 'CREATED') {
            return 'Utworzona';
        }
        if (l === 'TO_APPROVE') {
            return 'Oczekuje na akceptację';
        }
        if (l === 'APPROVED') {
            return 'Zaakceptowana';
        }
    }
    formatNumber(number) {
        if (!number) {
            return 0;
        }
        const formatter = new Intl.NumberFormat('pl-PL', {
            style: 'decimal',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
        const formattedNumber = formatter.format(number);
        return formattedNumber;
    }

    updateElement(data) {
        let tempDate = data.date;
        if (data.date) {
            data.date = new Date(moment(data.date));
        }
        if (data.userDays) {
            data.userDays?.forEach((ud) => {
                let udDate;
                if (ud.date === null) {
                    udDate = tempDate;
                } else {
                    udDate = ud.date;
                }
                ud.timeFrom = ud.timeFrom ? new Date(moment(udDate + ' ' + ud.timeFrom)) : ud.timeFrom;
                ud.timeTo = ud.timeTo ? new Date(moment(udDate + ' ' + ud.timeTo)) : ud.timeTo;
                if (ud.events) {
                    ud.events?.forEach((ev) => {
                        ev.time = ev.time ? NumberUtils.formatTimeIfPossibleToMaskComponent(ev.time) : '00:00';
                    });
                }
            });
            data.userDays.sort((u1, u2) => {
                if (u1?.user?.fullName > u2?.user?.fullName) return 1;
                if (u1?.user?.fullName < u2?.user?.fullName) return -1;
                return 0;
            });
        }
        if (data.level) {
            data.level = data.level.label !== undefined ? data.level.label : data.level;
        }
        // if (data.status) {
        //     data.status = data.status.label !== undefined ? data.status.label : data.status;
        // }
        if (data.extraEvents) {
            data.extraEvents?.forEach((ee) => {
                ee.time = ee.time !== null ? NumberUtils.formatTimeIfPossibleToMaskComponent(ee.time) : '00:00';
                ee.totalHour = ee.totalHour !== null ? NumberUtils.formatTimeIfPossibleToMaskComponent(ee.totalHour) : '00:00';
            });
            data.extraEvents.sort((e1, e2) => {
                if (e1?.activityModel?.name > e2?.activityModel?.name) return 1;
                if (e1?.activityModel?.name < e2?.activityModel?.name) return -1;
                return 0;
            });
        }
        if (data.carEvents) {
            data.carEvents?.forEach((ee) => {
                ee.time = ee.time !== null ? NumberUtils.formatTimeIfPossibleToMaskComponent(ee.time) : '00:00';
            });

            data.carEvents.sort((e1, e2) => {
                if (e1?.car?.fullName > e2?.car?.fullName) return 1;
                if (e1?.car?.fullName < e2?.car?.fullName) return -1;
                if (e1.direction?.enumValue > e2.direction?.enumValue) return -1;
                if (e1.direction?.enumValue < e2.direction?.enumValue) return 1;
                return 0;
            });
        }

        this.setState(
            {
                element: RDModel.copy(data),
            },
            () => {
                this.initAfterSetElement();
            }
        );
    }

    initAfterSetElement() {
        super.initAfterSetElement();
        this.fetchDataForDetails();
    }
    fetchDataForDetails() {
        if (this.canViewCardDetails()) {
            this.getProjectCars();
            // this.getProjectDrivers();
            //powyzsze getProjectDrivers zakomentowano, a dodano getProjectDriversFromUD - aby wsrod kierowcow byli tylko tacy, którzy są dodani jako userzy na ten dzien
            this.getProjectDriversFromUD();
            this.getProjectActivities();
            this.getAllEmployees();
        }
    }
    getProjectDriversFromUD() {
        let drivers = this.state.element.userDays?.map((ud) => ud.user);
        this.setState({
            drivers: drivers,
        });
    }

    getProjectCars() {
        this.projectService
            .getCars(this.state.element.project.id)
            .then((cars) => this.setState({cars: cars}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy pojazdów');
            });
    }
    getProjectDrivers() {
        this.projectService
            .getDrivers(this.state.element.project.id, this.state.element.date)
            .then((drivers) => this.setState({drivers: drivers}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy kierowców');
            });
    }
    getAllEmployees(query, projectId) {
        this.employeeService
            .searchEmployeeContractOnDate(query, this.state.element.date, projectId)
            .then((employees) => this.setState({filteredEmployees: employees}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy pracowników');
            });
    }
    getProjectActivities() {
        this.projectService
            .getActivities(this.state.element.project.id)
            .then((activities) => this.setState({activities: activities}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy aktywności');
            });
    }
    getProjectList(query) {
        this.projectService
            .search(query, 'ACTIVE')
            .then((projects) => {
                this.setState({projects: projects});
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy dostępnych projektów');
            });
    }
    initBeforeSetElement() {
        this.getProjectList();
        this.enumService
            .getEnumList('Currency')
            .then((currencies) => this.setState({currencies}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy walut');
            });
        this.enumService
            .getEnumList('PriceType')
            .then((priceTypes) => {
                priceTypes.forEach((pt) => (pt.label = t(pt.label)));
                this.setState({priceTypes});
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy rodzajów rozliczenia');
            });
        //typy dodatkowych zajęć
        this.enumService
            .getEnumList('ActivityInternal')
            .then((extraEv) => this.setState({extraEv}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy walut');
            });
        this.clientService
            .getList({firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true})
            .then((clients) => this.setState({clients: clients.content}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy klientów');
            });
        this.activityService
            .getListWithInternalActivities({status: 'HIDDEN', firstResult: 0, maxResults: 1000, sortField: 'name', sortAsc: true})
            .then((activitiesHidden) => this.setState({activitiesHidden: activitiesHidden.content}))
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy aktywności');
            });
        this.enumService
            .getEnumList('ProjectManagerRoleType')
            .then((leaderRoles) => {
                this.setState({leaderRoles});
            })
            .catch(() => {
                this.showErrorMessage('Nie udało się pobrać listy ról liderów');
            });
        this.setState({showFooterButtons: true});
    }
    createOrUpdate() {
        const {element} = this.state;
        this.scrollToTop();
        if (this._isMounted) {
            if (element.id === undefined || element.id === null || element.id === '') {
                this.service
                    .add(element)
                    .then((response) => {
                        this.afterAddSuccess(response);
                    })
                    .catch((err) => {
                        this.updateElement(element);
                        this.displayError(err, element);
                    });
            } else {
                this.service
                    .update(element)
                    .then((response) => {
                        this.state.toolsToDelete = [];
                        this.afterUpdateSuccess(response);
                    })
                    .catch((err) => {
                        this.updateElement(element);
                        this.displayError(err, element);
                        //
                        this.unblockUi();
                    });
            }
        }
    }

    displayError(err, element) {
        let employeeData = null;
        let em = null;
        if (err.messages !== null && err.messages.length > 0 && err.messages[0] !== err.errorCode) {
            em = StringUtils.convert2Number(err.messages[0]);
            if (em !== null) {
                let employeeTempData = element.userDays?.filter((u) => u.user?.id === em)[0]?.user;
                employeeData = employeeTempData !== null ? ' - ' + employeeTempData.firstName + ' ' + employeeTempData.lastName : null;
            }
            return this.showErrorMessage(em !== null ? err.message + employeeData : err.message, 10000);
        } else {
            return this.showErrorMessage(err.message, 10000);
        }
    }

    handleFormSubmit(event) {
        if (event !== undefined) {
            event.preventDefault();
        }
        const {element} = this.state;

        this.setState({activeIndex: 0}, () => {
            if (this.validator.allValid()) {
                this.setState({activeIndex: 1}, () => {
                    if (this.validator.allValid()) {
                        this.setState({activeIndex: 2}, () => {
                            if (this.validator.allValid()) {
                                this.prepareTimesElementBeforeRequest(element);
                                this.setState({activeIndex: 3}, () => {
                                    if (this.validator.allValid()) {
                                        this.blockUi(this.handleValidForm);
                                    } else {
                                        this.validator.showMessages();
                                        this.scrollToError = true;
                                        this.forceUpdate();
                                    }
                                });
                            } else {
                                this.validator.showMessages();
                                this.scrollToError = true;
                                this.forceUpdate();
                            }
                        });
                    } else {
                        this.validator.showMessages();
                        this.scrollToError = true;
                        this.forceUpdate();
                    }
                });
            } else {
                this.validator.showMessages();
                this.scrollToError = true;
                this.forceUpdate();
            }
        });
    }

    prepareFooterItems() {
        const result = super.prepareFooterItems();
        const {viewMode, editUrl} = this.props;
        const {element} = this.state;

        switch (viewMode) {
            case 'EDIT':
            case 'NEW':
                break;
            case 'VIEW':
                if (this.authService.isUserInAnyRole('ROLE_ADMIN', 'ROLE_USER', 'ROLE_CTO', 'ROLE_EXTERNAL_MANAGER')) {
                    if (this.state.element.status?.enumValue !== 'APPROVED') {
                        result.push({
                            label: 'Edytuj',
                            className: 'float-right',
                            href: `${editUrl}/${element.id}?activeIndex=${this.state.activeIndex}`,
                        });
                        result.push({label: 'Zaakceptuj', className: 'float-right', onClick: this.showAcceptConfirmation});
                        if (element?.status?.enumValue !== 'REJECTED') {
                            result.push({label: 'Odrzuć', className: 'fullred float-right', onClick: this.showRejectConfirmation});
                        }
                    } else if (this.state.element.status?.enumValue === 'APPROVED') {
                        result.push({label: 'Odblokuj', className: 'fullred float-right', onClick: this.showReopenConfirmation});
                    }
                }
                    

                    
                break;
            default:
                break;
        }
        return result;
    }

    showReopenConfirmation() {
        this.setState({showConfirmReopenDialog: true});
    }

    hideReopenConfirmation() {
        this.setState({showConfirmReopenDialog: false});
    }

    showRejectConfirmation() {
        this.setState({showConfirmRejectDialog: true});
    }

    hideRejectConfirmation() {
        this.setState({showConfirmRejectDialog: false});
    }

    showAcceptConfirmation() {
        this.setState({showConfirmAcceptDialog: true});
    }

    hideAcceptConfirmation() {
        this.setState({showConfirmAcceptDialog: false});
    }

    rejectReportedDay() {
        this.blockUi();
        this.service
            .reject(this.state.element.id)
            .then((result) => {
                this.showSuccessMessage('Odrzucono kartę pracy');
                this.updateElement(result);
                this.hideRejectConfirmation();
                this.unblockUi();
            })
            .catch((err) => {
                this.showErrorMessage('Nie udało się odrzucić karty pracy');
                this.hideRejectConfirmation();
                this.unblockUi();
            });
    }

    reopenReportedDay() {
        this.blockUi();
        this.service
            .reopen(this.state.element.id)
            .then((result) => {
                this.showSuccessMessage('Karta pracy została odblokowana');
                this.updateElement(result);
                this.hideReopenConfirmation();
                this.unblockUi();
            })
            .catch((err) => {
                this.showErrorMessage('Nie udało się odblokować karty pracy!');
                this.hideReopenConfirmation();
                this.unblockUi();
            });
    }
    

    acceptReportedDay() {
        this.blockUi();
        this.service
            .approve(this.state.element.id)
            .then((result) => {
                this.showSuccessMessage('Zaakceptowano kartę pracy');
                this.updateElement(result);
                this.hideAcceptConfirmation();
                this.unblockUi();
            })
            .catch((err) => {
                this.showErrorMessage('Nie udało się zaakceptować karty pracy');
                this.hideAcceptConfirmation();
                this.unblockUi();
            });
    }

    isPdfButtonRendered() {
        return false;
    }

    getPdfDownloadLabel() {
        return null;
    }

    expandAll = () => {
        let expandedRowsToSet = {};

        this.state.element.userDays?.forEach((ud) => (expandedRowsToSet[`${ud.id}`] = true));
        this.setState({expandedRows: expandedRowsToSet});
    };

    collapseAll = () => {
        this.setState({expandedRows: []});
    };

    allowExpansion = (rowData) => {
        return rowData.orders.length > 0;
    };

    rowExpansionTemplate = (data, rowData) => {
        return (
            <DivContainer colClass='row'>
                <DivContainer colClass='row'>
                    <div style={{width: '5rem'}}> </div>
                    {data.events?.sort((a, b) => {
                        if (a?.activity?.name < b?.activity?.name) return -1;
                        if (a?.activity.name > b?.activity?.name) return 1;
                        return 0;
                    }).map((el, index) => {
                        if (el.activity?.name !== 'Standardowa praca') {
                            const name = el.activity?.name;
                            return (
                                <InputMaskComponent
                                    id={`ev${el}-${index}`}
                                    name='time'
                                    label={name === 'Jazda' ? 'Dodatek' : name}
                                    value={el.time}
                                    showLabel={true}
                                    onChange={this.handleChange}
                                    mask='99:99'
                                    stateField={`element.userDays[${rowData.index}].events[${index}]`}
                                    placeholder='00:00'
                                    colClass='col-md-3'
                                    viewMode={this.props.viewMode}
                                />
                            );
                        } else return '';
                    })}
                </DivContainer>{' '}
            </DivContainer>
        );
    };
    searchCars(query) {
        let list = [];

        this.carService.searchCars(query).then((data) => {
            this.setState({
                filteredCars: list.concat(data),
            });
        });
    }
    formatTime(field, rowData) {
        let time = rowData[field];
        if (time) {
            const hours = Math.floor(time / 60);
            const mins = time % 60;
            return `${hours}:${mins.toString().padStart(2, '0')}`;
        } else {
            return '';
        }
    }

    countMaxDay(projectEndDate) {
        let currentDate = new Date();
        return moment(projectEndDate).toDate() > currentDate ? currentDate : moment(projectEndDate).toDate();
    }
    canViewCardDetails() {
        return (
            this.state.element.project !== null &&
            this.state.element.project !== undefined &&
            this.state.element.date !== null &&
            this.state.element.date !== undefined
        );
    }
    createQueryString(queryObject = {}) {
        let queryString = Object.keys(queryObject)
            .filter((key) => queryObject[key] && !(Array.isArray(queryObject[key]) && !queryObject[key].length))
            .map((key) => {
                return Array.isArray(queryObject[key])
                    ? queryObject[key].map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&')
                    : `${encodeURIComponent(key)}=${encodeURIComponent(queryObject[key])}`;
            })
            .join('&');
        return queryString ? `?${queryString}` : '';
    }

    renderCard() {
        return (
            <div style={{minHeight: '900px'}}>
                <Card className='details-card' footer={this.state.showFooterButtons && this.renderFooter()} header={this.renderHeader()}>
                    <form onSubmit={this.handleFormSubmit} noValidate>
                        {this.renderDetails()}
                    </form>
                </Card>
            </div>
        );
    }
    getAlarmColor() {
        const haveAnyUnserved = this.state.element?.alarms.find((alarm) => alarm.status === 'UNSERVED');
        if (haveAnyUnserved) {
            return 'red';
        }
    }
    renderDetails() {
        return (
            <React.Fragment>
                <DivContainer colClass='row'>
                    <DivContainer colClass='col-md-12'>
                        <Card>
                            <DivContainer colClass='row'>
                                {this.state.showApproveAlarmDialog && (
                                    <YesNoDialog
                                        singleButton
                                        header='Komentarz dla alarmu'
                                        noButtonLabel='Zatwierdź komentarz'
                                        onHide={() => {
                                            this.setState({showApproveAlarmDialog: false, currentDescForAlarm: ''});
                                        }}
                                        onChange={() => {
                                            const alarms = this.state.element.alarms;
                                            alarms.forEach((a) => {
                                                if (a.id === this.state.changedAlarm.id) {
                                                    a.description = this.state.currentDescForAlarm;
                                                }
                                            });
                                            this.setState((prevState) => ({
                                                element: {
                                                    ...prevState.element,
                                                    alarms,
                                                },
                                                showApproveAlarmDialog: false,
                                            }));
                                        }}
                                        visible={this.state.showApproveAlarmDialog}
                                    >
                                        <div className='container'>
                                            <InputTextarea
                                                value={this.state.currentDescForAlarm}
                                                className='col-12 ml-2 mt-2'
                                                onChange={(e) => {
                                                    this.setState({
                                                        currentDescForAlarm: e.currentTarget.value,
                                                    });
                                                }}
                                            />
                                        </div>
                                    </YesNoDialog>
                                )}
                                <InputTextComponent
                                    id='name'
                                    name='name'
                                    label={'Nazwa projektu'}
                                    colClass='col-md-6'
                                    value={this.state.element.project?.name}
                                    validators='required|max:150'
                                    validator={this.validator}
                                    onChange={this.handleChange}
                                    viewMode={'VIEW'}
                                    rendered={this.props.viewMode === 'VIEW' || this.props.viewMode === 'EDIT'}
                                />
                                <InputAutocompleteComponent
                                    id={`project`}
                                    name='project'
                                    label='Projekt'
                                    value={this.state.element.project}
                                    dropdown
                                    colClass='col-md-6'
                                    forceSelection
                                    viewMode={this.props.viewMode}
                                    validator={this.validator}
                                    validators='required'
                                    showLabel={true}
                                    onChange={this.handleChange}
                                    insideTable
                                    filteredList={this.state.projects}
                                    filterList={(query) => this.getProjectList(query)}
                                    field='name'
                                    rendered={this.props.viewMode === 'NEW'}
                                    minLength={3}
                                    placeholder={t('search.project.placeholder')}
                                />

                                <DivContainer>
                                    <InputCalendarComponent
                                        id='date'
                                        name='date'
                                        label='Data '
                                        colClass='col-md-4'
                                        value={this.state.element.date}
                                        // valueView={this.state.element.date}
                                        onChange={this.handleChange}
                                        viewMode={this.props.viewMode}
                                        validator={this.validator}
                                        validators='required'
                                        onAfterStateChange={() => {
                                            if (!this.state.projectDateInitiate) {
                                                this.setState({projectDateInitiate: true}, this.fetchDataForDetails());
                                            }
                                            this.getInitiateEmployeesForNewMode();
                                        }}
                                        showIcon
                                        showClear
                                        showTime={false}
                                        minDate={
                                            this.state.element.project?.dateStart !== null
                                                ? moment(this.state.element.project?.dateStart).toDate()
                                                : undefined
                                        }
                                        maxDate={
                                            this.state.element.project?.dateEnd !== null
                                                ? this.countMaxDay(this.state.element.project?.dateEnd)
                                                : undefined
                                        }
                                        dateFormatMoment={Constants.DATE_FORMAT}
                                        propCustomValue={true}
                                    />
                                </DivContainer>
                            </DivContainer>

                            {this.props.viewMode !== 'NEW' ? (
                                <DivContainer colClass='row'>
                                    <InputTextComponent
                                        id='status'
                                        name='status'
                                        label={t('reportedDay.reportedEmp')}
                                        colClass='col-md-4'
                                        value={this.state.element.reportedEmpQuantity}
                                        //  valueView={this.state.element.status}
                                        validators='required|max:150'
                                        validator={this.validator}
                                        onChange={this.handleChange}
                                        viewMode={'VIEW'}
                                        rendered={this.props.viewMode !== 'NEW'}
                                    />
                                    <InputTextComponent
                                        id='level'
                                        name='level'
                                        label={t('reportedDay.empInProject')}
                                        colClass='col-md-4'
                                        value={this.state.element.empInProjectQuantity}
                                        validators='required|max:150'
                                        validator={this.validator}
                                        onChange={this.handleChange}
                                        viewMode={'VIEW'}
                                        rendered={this.props.viewMode !== 'NEW'}
                                    />
                                </DivContainer>
                            ) : null}
                            {this.props.viewMode !== 'NEW' ? (
                                <DivContainer colClass='row'>
                                    <InputTextComponent
                                        id='status'
                                        name='status'
                                        label={'Status karty pracy'}
                                        colClass='col-md-4'
                                        value={this.state.element.status?.label}
                                        //  valueView={this.state.element.status}
                                        validators='required|max:150'
                                        validator={this.validator}
                                        onChange={this.handleChange}
                                        viewMode={'VIEW'}
                                        rendered={this.props.viewMode !== 'NEW'}
                                    />
                                    <InputTextComponent
                                        id='level'
                                        name='level'
                                        label={'Osoba przypisana'}
                                        colClass='col-md-4'
                                        value={this.state.element.level}
                                        validators='required|max:150'
                                        validator={this.validator}
                                        onChange={this.handleChange}
                                        viewMode={'VIEW'}
                                        rendered={this.props.viewMode !== 'NEW'}
                                    />
                                </DivContainer>
                            ) : null}

                            <DivContainer colClass='row'>
                                {this.props.viewMode !== 'NEW' ? (
                                    <div>
                                        <InputDropdownComponent
                                            id='client'
                                            name='client'
                                            label='Klient'
                                            colClass='col-md-6'
                                            value={this.state.element.project?.client}
                                            valueView={this.state.element?.project?.client?.name}
                                            dataKey='id'
                                            options={this.state.clients}
                                            optionLabel='name'
                                            viewMode={'VIEW'}
                                            validator={this.validator}
                                            validators='required'
                                            onChange={this.handleChange}
                                            filter
                                            filterBy='name'
                                        />
                                        <div className='col-md-4'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <label
                                                        id='clientAddress-label-id'
                                                        className='p-label'
                                                        htmlFor='clientAddress'
                                                        style={{width: '100%'}}
                                                    >
                                                        Adres klienta
                                                    </label>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <span className='p-inputtext-view icon_text'>Kraj</span>
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <span className='p-inputtext-view icon_text'>
                                                                {this.state.element?.project?.client?.address?.country?.name}
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <span className='p-inputtext-view icon_text'>Miasto</span>
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <span className='p-inputtext-view icon_text'>
                                                                {this.state.element?.project?.client?.address?.postalCode}{' '}
                                                                {this.state.element?.project?.client?.address?.city}
                                                            </span>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <span className='p-inputtext-view icon_text'>Ulica</span>
                                                        </div>
                                                        <div className='col-md-8'>
                                                            <span className='p-inputtext-view icon_text'>
                                                                {this.state.element?.project?.client?.address?.streetName}{' '}
                                                                {this.state.element?.project?.client?.address?.streetNumber}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>{' '}
                                    </div>
                                ) : null}
                            </DivContainer>
                        </Card>
                    </DivContainer>
                    {this.canViewCardDetails() ? (
                        <DivContainer colClass='col-md-12'>
                            <TabView
                                activeIndex={this.state.activeIndex}
                                onTabChange={(e) => {
                                    this.setState({activeIndex: e.index}, () => {
                                        this.props.history.push({
                                            search: this.createQueryString({activeIndex: e.index}),
                                        });
                                    });
                                }}
                            >
                                <TabPanel header='Pracownicy'>
                                    <Card header='Pracownicy'>
                                        <DivContainer colClass='row'>
                                            <DataTable
                                                value={this.state.element.userDays}
                                                sortField='id'
                                                responsiveLayout='scroll'
                                                expandedRows={this.state.expandedRows}
                                                onRowToggle={(e) => {
                                                    this.setState({expandedRows: e.data});
                                                }}
                                                //onSort={this.handleSort}
                                                rowExpansionTemplate={this.rowExpansionTemplate}
                                            >
                                                <Column expander={this.allowExpansion} style={{width: '5rem'}} />
                                                <Column
                                                    field='user'
                                                    header='Imię i nazwisko'
                                                    body={this.employeesListTemplate.bind(this)}
                                                ></Column>
                                                <Column field='timeFrom' header='Praca od' body={this.testTimeTemplate.bind(this)}></Column>
                                                <Column field='timeTo' header='Praca do' body={this.testTimeTemplate.bind(this)}></Column>
                                                <Column
                                                    field='workTime'
                                                    header='Czas pracy'
                                                    body={this.formatTime.bind(this, 'workTime')}
                                                ></Column>
                                                <Column
                                                    field='extraDriveTime'
                                                    header='Dodatek'
                                                    body={this.formatTime.bind(this, 'extraDriveTime')}
                                                ></Column>
                                                {this.props.viewMode !== 'VIEW' && this.state.element.status?.enumValue !== 'APPROVED' ? (
                                                    <Column header='' body={this.handleDeleteEmployeeFromReportDay.bind(this)}></Column>
                                                ) : null}
                                            </DataTable>
                                        </DivContainer>
                                        <DivContainer>
                                            <ActionLink
                                                label='Dodaj pracownika'
                                                iconName='mdi-plus'
                                                iconSide='left'
                                                iconSize='icon-large'
                                                variant='blue'
                                                className='float-right'
                                                key={'add-activity-button'}
                                                rendered={this.props.viewMode === 'EDIT' || this.props.viewMode === 'NEW'}
                                                handleClick={this.handleAddEmployeeWithWorkTime.bind(this)}
                                            />
                                        </DivContainer>
                                    </Card>
                                </TabPanel>
                                <TabPanel header='Prace dodatkowe'>
                                    {this.state.element.extraEvents.length > 0 ? (
                                        <Card>
                                            {this.state.element.extraEvents?.map((el, index) => {
                                                return (
                                                    <ReportedDayExtraEventsComponent
                                                        element={el}
                                                        state={this.state}
                                                        index={index}
                                                        rendered={true}
                                                        viewMode={this.props.viewMode}
                                                        handleChange={this.handleChange}
                                                        activityTypes={this.state.activities}
                                                        stateField={`element.extraEvents[${index}]`}
                                                        handleDelete={(e) => this.handleDeleteExtraEventFromReportDay(e)}
                                                        validator={this.validator}
                                                    ></ReportedDayExtraEventsComponent>
                                                );
                                            })}
                                        </Card>
                                    ) : null}

                                    <DivContainer>
                                        <ActionLink
                                            label='Dodaj dodatkową pracę'
                                            iconName='mdi-plus'
                                            iconSide='left'
                                            iconSize='icon-large'
                                            variant='blue'
                                            className='float-right'
                                            key={'add-activity-button'}
                                            rendered={this.props.viewMode === 'EDIT' || this.props.viewMode === 'NEW'}
                                            handleClick={this.handleAddExtraEvent.bind(this)}
                                        />
                                    </DivContainer>
                                </TabPanel>

                                <TabPanel header='Samochody'>
                                    {this.state.element.carEvents.length > 0 ? (
                                        <Card>
                                            {this.state.element.carEvents?.map((el, index) => {
                                                return (
                                                    <ReportedDayCarsComponent
                                                        element={el}
                                                        rendered={true}
                                                        viewMode={this.props.viewMode}
                                                        handleChange={this.handleChange}
                                                        carEvents={this.state.element.carEvents}
                                                        activityTypes={this.state.extraEv}
                                                        stateField={`element.carEvents[${index}]`}
                                                        optionCars={this.state.cars}
                                                        optionDrivers={
                                                            this.state.cars?.length === 0 ||
                                                            this.state.cars === undefined ||
                                                            this.state.cars === null
                                                                ? []
                                                                : this.state.drivers
                                                        }
                                                        handleDelete={(e) => this.handleDeleteCarEventFromReportDay(e)}
                                                        validator={this.validator}
                                                    ></ReportedDayCarsComponent>
                                                );
                                            })}
                                        </Card>
                                    ) : null}
                                    <DivContainer>
                                        <ActionLink
                                            label='Dodaj przejazd samochodem'
                                            iconName='mdi-plus'
                                            iconSide='left'
                                            iconSize='icon-large'
                                            variant='blue'
                                            className='float-right'
                                            key={'add-activity-button'}
                                            rendered={this.props.viewMode === 'EDIT' || this.props.viewMode === 'NEW'}
                                            handleClick={this.handleAddCarEvent.bind(this)}
                                        />
                                    </DivContainer>
                                </TabPanel>
                                {this.props.viewMode !== 'NEW' ? (
                                    <TabPanel header='Dziennik zdarzeń'>
                                        <LogEntryListContainer
                                            objectType='REPORTED_DAY'
                                            objectId={this.props.elementId}
                                            expandFilters={false}
                                            renderHeader={false}
                                        />
                                    </TabPanel>
                                ) : null}
                                {this.props.viewMode !== 'NEW' && (
                                    <TabPanel
                                        header={
                                            <div>
                                                {t('Alarmy GPS')}{' '}
                                                <i
                                                    className='mdi mdi-alarm-light'
                                                    style={{marginLeft: '5px', fontSize: '18px', color: this.getAlarmColor()}}
                                                ></i>
                                            </div>
                                        }
                                    >
                                        <div className=''>
                                            <Card header='Alarmy GPS'>
                                                {this.state.element.verifiedGps ? (
                                                    <DivContainer colClass='col-12'>
                                                        <DataTable
                                                            id='alarms'
                                                            paginator
                                                            rows={5}
                                                            value={this.state.element?.alarms}
                                                            rowsPerPageOptions={[5, 10, 25, 50]}
                                                            responsiveLayout='scroll'
                                                            emptyMessage={t('list.empty')}
                                                        >
                                                            <Column field='startTime' header='Godzina rozpoczęcia'></Column>
                                                            <Column field='endTime' header='Godzina zakończenia'></Column>
                                                            <Column field='drivingTime' header='Czas jazdy'></Column>
                                                            <Column
                                                                field='distanceInKilometers'
                                                                header='Dystans (km)'
                                                                headerStyle={{textAlign: 'right'}}
                                                                bodyStyle={{textAlign: 'right'}}
                                                            ></Column>
                                                            <Column
                                                                field='employee.fullName'
                                                                style={{width: '200px'}}
                                                                header='Nazwa użytkownika'
                                                            ></Column>
                                                            <Column
                                                                field='startLocation'
                                                                style={{width: '200px'}}
                                                                header='Miejsce rozpoczęcia'
                                                            ></Column>
                                                            {/*  TODO: jesli zaraportował i zaczął jazde w trakcie pracy i skonczyl jazde poza godzinami pracy to bedzie null, moze wtedy powjien byc jakis napisa w stylu, Zakonczyl jazde poza godzinami pracy??? (to moze sie pojawić TYLKO jesli raportował nma dzisiaj) */}
                                                            <Column
                                                                field='endLocation'
                                                                style={{width: '200px'}}
                                                                width='100px'
                                                                header='Miejsce zakończenia'
                                                            ></Column>
                                                            <Column
                                                                field=''
                                                                style={{width: '200px'}}
                                                                header='Status'
                                                                body={(row) => {
                                                                    return (
                                                                        <div
                                                                            style={{fontSize: '14px'}}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                e.preventDefault();
                                                                                if (
                                                                                    this.props.viewMode === 'EDIT' &&
                                                                                    (row.status === 'UNSERVED' || row.status === 'SERVED')
                                                                                ) {
                                                                                    const alarms = this.state.element?.alarms;
                                                                                    const alarm = alarms.find((el) => el.id === row.id);
                                                                                    if (alarm.status === 'SERVED') {
                                                                                        alarm.status = 'UNSERVED';
                                                                                    } else {
                                                                                        alarm.status = 'SERVED';
                                                                                    }
                                                                                    this.setState((prevState) => ({
                                                                                        element: {
                                                                                            ...prevState.element,
                                                                                            alarms,
                                                                                        },
                                                                                    }));
                                                                                    this.setState({
                                                                                        showApproveAlarmDialog: true,
                                                                                        changedAlarm: structuredClone(row),
                                                                                        currentDescForAlarm: alarm.description,
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            {
                                                                                <AlarmStatusIconTemplateComponent
                                                                                    row={row}
                                                                                    viewMode={this.props.viewMode}
                                                                                />
                                                                            }
                                                                        </div>
                                                                    );
                                                                }}
                                                            ></Column>
                                                        </DataTable>
                                                    </DivContainer>
                                                ) : (
                                                    <div style={{color: 'red', fontSize: '14px'}}>
                                                        Trwa aktualizacja alarmów. Proszę czekać...{' '}
                                                    </div>
                                                )}
                                            </Card>
                                        </div>
                                    </TabPanel>
                                )}
                            </TabView>
                        </DivContainer>
                    ) : null}
                </DivContainer>

                {this.state.showConfirmRejectDialog ? ( //&& (this.state.element.status.enumValue === 'CREATED' || this.state.element.status.enumValue === 'TO_APPROVE'  )
                    <YesNoDialog
                        visible={this.state.showConfirmRejectDialog}
                        header='Odrzucenie karty pracy'
                        name='visible'
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.rejectReportedDay();
                            } else {
                                this.hideRejectConfirmation();
                            }
                        }}
                        onHide={() => {
                            this.hideRejectConfirmation();
                        }}
                    >
                        Czy na pewno chcesz odrzucić kartę pracy?
                    </YesNoDialog>
                ) : this.state.showConfirmAcceptDialog ? ( //&& (this.state.element.status.enumValue === 'CREATED' || this.state.element.status.enumValue === 'TO_APPROVE'  )
                    <YesNoDialog
                        visible={this.state.showConfirmAcceptDialog}
                        header='Akceptacja karty pracy'
                        name='visible'
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.acceptReportedDay();
                            } else {
                                this.hideAcceptConfirmation();
                            }
                        }}
                        onHide={() => {
                            this.hideAcceptConfirmation();
                        }}
                    >
                        Czy na pewno chcesz zaakceptować kartę pracy?
                    </YesNoDialog>
                ) : this.state.showConfirmReopenDialog ? (
                    <YesNoDialog
                        visible={this.state.showConfirmReopenDialog}
                        header='Odblokowanie karty pracy'
                        name='visible'
                        onChange={(type, x, target) => {
                            if (target.value) {
                                this.reopenReportedDay();
                            } else {
                                this.hideReopenConfirmation();
                            }
                        }}
                        onHide={() => {
                            this.hideReopenConfirmation();
                        }}
                    >
                        Czy na pewno chcesz odblokować kartę pracy?
                    </YesNoDialog>
                ): null}
            </React.Fragment>
        );
    }

    handleDeleteEmployeeFromReportDay(rowData) {
        return (
            <span>
                <ActionLink
                    iconName='mdi-delete'
                    iconSide='left'
                    iconSize='icon-large'
                    handleClick={(e) => {
                        let userDays = this.state.element.userDays.filter((ud) => ud !== rowData);
                        let drivers = userDays.map((ud) => ud.user);
                        this.setState((prevState) => ({
                            drivers: drivers,
                            element: {
                                ...prevState.element,
                                userDays,
                            },
                        }));
                    }}
                    variant='blue'
                    className='edit-blue-link'
                    key={'delete-button'}
                />
            </span>
        );
    }
    handleDeleteExtraEventFromReportDay(extraEvents) {
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                extraEvents,
            },
        }));
    }

    backUrlParams() {
        return `?activeIndex=${this.state.activeIndex}`;
    }

    handleDeleteCarEventFromReportDay(carEvents) {
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                carEvents,
            },
        }));
    }

    mappershelper(a) {
        // let activity = {id: id, time: time, activity:{name: a.name, activity :a}};
        let activity = {name: a.name, activity: a, time: '00:00'};
        return activity;
    }

    handleAddEmployeeWithWorkTime() {
        const userDays = this.state.element.userDays;
        this.addToUserDays([], userDays);
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                userDays,
            },
        }));
    }

    getInitiateEmployeesForNewMode() {
        if (this.props.viewMode === 'NEW' && this.canViewCardDetails()) {
            this.projectService
                .getEmployeesOnReportedDayDate(this.state.element.project.id, this.state.element.date)
                .then((employees) => {
                    const userDays = [];
                    employees.forEach((employee) => {
                        this.addToUserDays(employee, userDays);
                    });
                    let drivers = userDays.map((ud) => ud.user);
                    this.setState((prevState) => ({
                        drivers: drivers,
                        element: {
                            ...prevState.element,
                            userDays,
                        },
                    }));
                })
                .catch(() => {
                    this.showErrorMessage('Błąd przy pobierniu użytkowników startowych do dnia raportowanego');
                });
        }
    }

    addToUserDays(user, userDays) {
        const momentDate = moment(this.state.element.date).format(Constants.DATE_JSON_FORMAT);
        const dayDate = this.props.viewMode === 'EDIT' ? momentDate : null;
        const ac = [...this.state.activitiesHidden];
        const events = ac.map((a) => this.mappershelper(a));
        events.forEach((x) => {
            if (x.activity) {
                x.activity.internalType = x.activity.activityInternal;
                delete x.activity.activityInternal;
            }
        });
        userDays.push({date: dayDate, events: events, user: user});
    }

    handleAddCarEvent() {
        const carEvents = this.state.element.carEvents;
        carEvents.push({car: [], direction: [], driver: [], time: '00:00'});
        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                carEvents,
            },
        }));
    }

    handleAddExtraEvent() {
        const extraEvents = this.state.element.extraEvents;
        extraEvents.push({activityModel: [], time: '00:00'});

        this.setState((prevState) => ({
            element: {
                ...prevState.element,
                extraEvents,
            },
        }));
    }

    projectClientItemTemplate(item) {
        return (
            <div>
                <div>
                    {'Projekt: '} {item.name} {', Klient: '} {item.client.name} {', '} {item.client.address.city}
                </div>
            </div>
        );
    }

    optionsUserTemplate(item) {
        return (
            <div>
                <div>
                    {item.firstName} {item.lastName}
                </div>
            </div>
        );
    }

    carsItemTemplate(item) {
        return (
            <div>
                <div>
                    {item.brand} {item.licensePlate}
                </div>
            </div>
        );
    }

    prepareValidOptionsList(rowData) {
        if (rowData) {
            return this.state.userToUserDaySet?.filter((u) => u.id !== rowData.user.id);
        } else {
            return this.state.userToUserDaySet;
        }
    }

    employeesListTemplate(rowData, props) {
        const rowIndex = props.rowIndex;

        return (
            <div style={{minWidth: '150px'}}>
                <InputAutocompleteComponent
                    id={`user${rowIndex}`}
                    name='user'
                    label={t('project.details.user')}
                    value={rowData?.user}
                    dropdown
                    forceSelection
                    viewMode={this.props.viewMode}
                    validator={this.validator}
                    validators='required'
                    showLabel={false}
                    onChange={(a, b, c, d, e) => {
                        if (c.value)
                            c.value.hasContract
                                ? this.handleChange(a, b, c, d, e)
                                : this.showErrorMessage('Użytkownik ' + c.value.fullName + ' nie ma aktywnego kontraktu');
                    }}
                    onAfterStateChange={() => {
                        let newDriverList = this.state.element.userDays?.map((ud) => ud.user);
                        this.setState({drivers: newDriverList});
                    }}
                    insideTable
                    filteredList={this.state.filteredEmployees}
                    filterList={(query) => this.getAllEmployees(query, this.state.element?.project?.id)}
                    field='fullName'
                    stateField={`element.userDays[${rowIndex}]`}
                    minLength={3}
                    placeholder={t('search.users.placeholder')}
                />
            </div>
        );
    }
    prepareTimesElementBeforeRequest(element) {
        // element.date = element.date.toLocaleDateString();
        if (element.date) {
            element.date = moment(element.date).format(Constants.DATE_JSON_FORMAT);
        }
        element.extraEvents?.forEach((ee) => {
            ee.time = ee.time != null ? NumberUtils.formatTimeFromMaskToMinutes(ee.time) : 0;
            ee.totalHour = ee.totalHour != null ? NumberUtils.formatTimeFromMaskToMinutes(ee.totalHour) : 0;
        });
        element.carEvents?.forEach((ee) => {
            ee.time = ee.time != null ? NumberUtils.formatTimeFromMaskToMinutes(ee.time) : 0;
        });

        element.userDays?.forEach((u) => {
            if (u.timeFrom && u.timeFrom !== null) {
                if (String(u.timeFrom).length > 8) {
                    let temp = u.timeFrom.toLocaleTimeString();
                    u.timeFrom = temp.substring(0, temp.length - 3);
                }
            }
            if (u.timeTo && u.timeTo !== null) {
                if (String(u.timeTo).length > 8) {
                    let temp = u.timeTo.toLocaleTimeString();
                    u.timeTo = temp.substring(0, temp.length - 3);
                }
            }
            if (u.events) {
                u.events?.forEach((ev) => {
                    ev.time = ev.time !== null ? NumberUtils.formatTimeFromMaskToMinutes(ev.time) : 0;
                });
            }
        });

        return element;
    }

    dateChange(rowData, props) {
        return (
            <div style={{minWidth: '150px'}}>
                <InputCalendarComponent
                    id={`${props.field}-date`}
                    name={`${props.field}`}
                    value={this.state.element.date}
                    //valueView = {rowData.timeFrom }
                    stateField={`element.date`}
                    onChange={this.handleChange}
                    viewMode={'VIEW'}
                    showLabel={false}
                    insideTable
                    dateFormatMoment='DD-MM-YYYY'
                    showTime={false}
                    placeholder='HH:mm'
                    showSeconds={false}
                    propCustomValue={true}
                />
            </div>
        );
    }

    getMapped(value) {
        const hourMinuteRegex = /^\d{1,2}:\d{2}$/;
        if (hourMinuteRegex.test(value)) {
            return value;
        } else {
            let date = new Date(value);
            return date.getHours() + ':' + date.getMinutes();
        }
    }

    testTimeTemplate(rowData, props) {
        const rowIndex = props.rowIndex;
        const fieldName = props.field;

        let from = rowData?.['timeFrom'];
        let to = rowData?.['timeTo'];

        let includeValidationFlag = false;
        if (from != null && to != null) {
            let fromDate = new Date(from);
            let toDate = new Date(to);

            if (fromDate.getHours() !== 0 && toDate.getHours() !== 0) {
                if (fromDate.getHours() > toDate.getHours()) {
                    includeValidationFlag = true;
                } else if (fromDate.getHours() === toDate.getHours() && fromDate.getMinutes() >= toDate.getMinutes()) {
                    includeValidationFlag = true;
                }
            }
        }

        let validators =
            fieldName === 'timeFrom' ? 'before_or_equal_time:' + this.getMapped(to) : 'after_or_equal_time:' + this.getMapped(from);
        if (!includeValidationFlag) {
            this.validator.setFieldValid('timeTo-' + rowIndex, true);
            this.validator.setFieldValid('timeFrom-' + rowIndex, true);
        }
        return (
            <div style={{minWidth: '150px'}}>
                <InputCalendarComponent
                    id={`${props.field}-${rowIndex}`}
                    name={`${props.field}`}
                    value={rowData?.[fieldName]}
                    stateField={`element.userDays[${rowIndex}]`}
                    onChange={this.handleChange}
                    viewMode={this.props.viewMode}
                    showLabel={false}
                    insideTable
                    showTime
                    validators={to == null || from == null ? '' : validators}
                    validator={this.validator}
                    dateFormatMoment='HH:mm'
                    timeOnly
                    // validateViewMode={validFlag}
                    validateViewMode={this.state.element.id != null ? includeValidationFlag : true}
                    placeholder='HH:mm'
                    showSeconds={false}
                    showIcon
                    icon={() => <i className='clock' />}
                    customTime={rowData?.[fieldName] === null ? true : false}
                    customTimeValue={
                        rowData?.[fieldName] === null && fieldName === 'timeFrom'
                            ? '06:00'
                            : rowData?.[fieldName] === null && fieldName === 'timeTo'
                            ? '14:00'
                            : null
                    }
                    readOnlyInput={false}
                    label={fieldName === 'timeFrom' ? 'Praca od' : 'Praca do'}
                    propCustomValue={true}
                />
            </div>
        );
    }

    // employeesEventTemplate(fieldName, rowData, props) {
    //     const rowIndex = props.rowIndex;
    //     return (
    //         <div style={{minWidth: '150px'}}>
    //             <InputTextComponent
    //                 id={`event`}
    //                 name='event'
    //                 value={
    //                     rowData.events !== undefined && rowData.events.length > 0
    //                         ? rowData.events?.filter((e) => e.activity.internalType?.enumValue === fieldName)[0].time
    //                         : 0
    //                 }
    //                 valueView={
    //                     rowData.events !== undefined && rowData.events.length > 0
    //                         ? rowData.events?.filter((e) => e.activity.internalType?.enumValue === fieldName)[0].time
    //                         : 0
    //                 }
    //                 showLabel={false}
    //                 onChange={this.handleChange}
    //                 viewMode={this.props.viewMode}
    //                 validator={this.validator}
    //                 validators='required|currency'
    //                 stateField={`element.activities[${rowIndex}]`}
    //                 insideTable
    //             />
    //         </div>
    //     );
    // }
    // employeesEventTemplatea(fieldName, rowData) {
    //     return (
    //         <NumberFormat
    //             value={rowData.events.filter((e) => e.activity.name === fieldName)}
    //             displayType={'text'}
    //             thousandSeparator=' '
    //             decimalSeparator=','
    //             fixedDecimalScale
    //         />
    //     );
    // }
}

ReportedDayDetailsContainer.defaultProps = {
    backUrl: '/#/reported-day-list',
    cancelUrl: '/#/reported-day/details',
    editUrl: '/#/reported-day/edit',
};

ReportedDayDetailsContainer.propTypes = {
    backUrl: PropTypes.string,
    cancelUrl: PropTypes.string,
    editUrl: PropTypes.string,
};

export default withRouter(withTranslation()(ReportedDayDetailsContainer));
