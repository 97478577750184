import Constants from '../utils/constants';
import BaseServiceCrud from './BaseServiceCrud';
import moment from 'moment';

export default class ToolService extends BaseServiceCrud {
    constructor() {
        super();
        this.path = 'tool';
    }
    getCriteria(criteria) {
        let status = criteria.status;
        return this.objToQueryString({
            first_result: criteria.firstResult,
            max_result: criteria.maxResults,
            sort_field: criteria.sortField,
            sort_asc: criteria.sortAsc,

            producer: criteria.producer != null ? criteria.producer.name : null,
            model: criteria.model != null ? criteria.model.modelIdentifier : null,
            type: criteria.type != null ? criteria.type.typeName : null,
            status: status != null ? status.enumValue : null,
            serialNumber: criteria.serialNumber,
            isAssigned: criteria.isAssigned,
            graver: criteria.graver,
            notes: criteria.notes,
        });
    }

    remove(element, deleteReason) {
        return this.fetch(`${this.domain}/${this.path}/${element.id}?deleteReason=${deleteReason}`, {
            method: 'DELETE',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }
    searchTools(query) {
        let url = `${this.domain}/${this.path}/search`;
        if (query) {
            url += `?query=${query}`;
        }

        return this.fetch(url, {
            method: 'GET',
        }).then((res) => {
            return Promise.resolve(res);
        });
    }

    normalizeTool(e) {
        if (e.nextInsp) {
            e.nextInsp = moment(e.nextInsp).format(Constants.DATE_JSON_FORMAT);
        }
        return e;
    }

    add(element) {
        const e = this.normalizeTool(this.cloneElement(element));
        return super.add(e);
    }

    update(element) {
        const e = this.normalizeTool(this.cloneElement(element));
        return super.update(e);
    }
}