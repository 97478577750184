import React from 'react';
import PropTypes from 'prop-types';
import {MultiSelect} from 'primereact/multiselect';
import {AutoComplete} from 'primereact/autocomplete'

import {withTranslation} from 'react-i18next';
import {FilterMatchMode} from 'primereact/api';

class CriteriaAutocompleteComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            suggestions: []
        }
        this.ref = React.createRef();        
    }

    componentDidMount() {}
    

    render() {
        const {
            completeMethod,
            colClass,
            disabled,
            field,
            id,
            itemTemplate,
            label,
            name,
            maxSelections,
            minLength,
            multiple,
            onChange,
            placeholder,
            validator,
            validators,
            value,
            valueLike,
            refreshFromBackend,
            timeoutHandler,
            //delay,
        } = this.props;
        const required = validators !== undefined && validators.includes('required') && !validators.includes('not_required');
        
        const parameters = [multiple];
        
        return (
            <div className={colClass}>
                <div className='row'>
                    <div className='col-md-12 form-group'>
                        {label !== undefined ? (
                            // eslint-disable-next-line react/jsx-max-props-per-line
                            <label id={`${id}-label-id`} className='p-label' htmlFor={id} style={{width: '100%'}}>
                                {label}
                            </label>
                        ) : null}

                        
                        <AutoComplete 
                            aria-describedby={`${id}-error`}
                            aria-label={label}
                            aria-labelledby={label === undefined ? `${id}-label-id` : undefined}
                            key={id}
                            id={id}
                            name={name}
                            field={field}
                            value={value}
                            multiple={multiple}
                            dropdown
                            virtualScrollerOptions={{ itemSize: 50 }}
                            suggestions={this.state.suggestions} 
                            completeMethod={(e) => {
                                const query = e?.query;
                                const inputValue = this.ref?.current?.inputRef?.current?.value;
                                completeMethod(query).then((suggestions) => {
                                    this.setState({suggestions}, () => {
                                        if (suggestions.length == 0) {
                                            if (onChange) {
                                                const e2 = {target: { name: name + 'Like', value: inputValue }};
                                                onChange('AUTOCOMPLETE', parameters, e2, FilterMatchMode.EQUALS, refreshFromBackend, undefined, timeoutHandler, 0)
                                            }
                                        }
                                    })
                                })
                            }} 
                            onChange={(e) => {
                                if (onChange) {
                                    if (!value || !Array.isArray(value) || value.length < maxSelections) {                                        
                                        const inputValue = this.ref?.current?.inputRef?.current?.value;
                                        e.target.valueLike = inputValue;                                    
                                        onChange('AUTOCOMPLETE', parameters, e, FilterMatchMode.EQUALS, multiple ? refreshFromBackend : false, undefined, timeoutHandler, 0);
                                    }
                                }  
                            }} 
                            itemTemplate={itemTemplate}
                            ref={this.ref}
                            placeholder={placeholder}
                            minLength={minLength}
                            disabled={disabled}

                            onHide={(e) => {
                                const inputValue = this.ref?.current?.inputRef?.current?.value;
                                if (inputValue && inputValue.length >= 3) {
                                    if (onChange) {
                                        const e2 = {target: { name: name + 'Like', value: inputValue }};
                                        onChange('AUTOCOMPLETE', parameters, e2, FilterMatchMode.EQUALS, refreshFromBackend, undefined, timeoutHandler, 0)
                                    }
                                }
                            }}
                            onClear={(e) => {
                                const e2 = {target: { name: name + 'Like', value: undefined }};
                                onChange('AUTOCOMPLETE', parameters, e2, FilterMatchMode.EQUALS, refreshFromBackend, undefined, timeoutHandler, 0)
                            }}
                            onUnselect={() => {                                

                            }}                            
                            onBlur={(e) => {                                
                                let inputValue = this.ref?.current?.inputRef?.current?.value;                                
                                if (inputValue && inputValue.length < 3) {
                                    this.ref.current.inputRef.current.value = '';                                    
                                    const e2 = {target: { name: name + 'Like', value: undefined }};
                                    onChange('AUTOCOMPLETE', parameters, e2, FilterMatchMode.EQUALS, refreshFromBackend, undefined, timeoutHandler, 0)
                                }
                                // inputValue = this.ref?.current?.inputRef?.current?.value;                                
                                // if (inputValue != valueLike) {
                                //     if (onChange) {
                                //         const e2 = {target: { name: name + 'Like', value: inputValue }};
                                //         onChange('AUTOCOMPLETE', null, e2, FilterMatchMode.EQUALS, refreshFromBackend, undefined, timeoutHandler, 0)
                                //     }
                                // }
                            }}
                        />
         

                        <div aria-live='assertive'>{validator ? validator.message(id, label, value, 'integer') : null}</div>
                    </div>
                </div>
            </div>
        );
    }
}

CriteriaAutocompleteComponent.defaultProps = {
    colClass: 'col-xl-4 col-lg-6 col-md-6 col-sm-12',
    disabled: false,
    field: 'name',
    maxSelections: 10,
    minLength: 3,
    multiple: false,
    placeholder: 'Wpisz 3 znaki, aby wyszukać...',
    validators: 'not_required',
    refreshFromBackend: false,
};

CriteriaAutocompleteComponent.propTypes = {
    completeMethod: PropTypes.func.isRequired,
    colClass: PropTypes.string,
    disabled: PropTypes.bool,
    field: PropTypes.string,
    id: PropTypes.string.isRequired,
    itemTemplate: PropTypes.func,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    maxSelections: PropTypes.number,
    multiple: PropTypes.bool,    
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    validator: PropTypes.object,
    validators: PropTypes.string,
    value: PropTypes.any,
    orValue: PropTypes.string,
    minLength: PropTypes.number,
    refreshFromBackend: PropTypes.bool,
    timeoutHandler: PropTypes.func,
};

export default withTranslation()(CriteriaAutocompleteComponent);
